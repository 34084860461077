import { generateId } from '@freelancer/datastore/testing/helpers';
import { isDefined } from '@freelancer/utils';
import type {
  MultipleProjectSavedSearchAlert,
  RecentSavedSearchAlert,
  SingleProjectSavedSearchAlert,
} from './recent-saved-search-alerts.model';
import { SavedAlertType } from './recent-saved-search-alerts.model';

interface GenerateSingleProjectAlertOptions {
  readonly projectId: number;
  readonly projectSeoUrl: string;
  readonly projectTitle: string;
  readonly savedSearchName: string;
  readonly time?: number;
}

interface GenerateMultipleProjectAlertOptions {
  readonly savedSearchName: string;
  readonly time?: number;
}

interface GenerateRecentSavedSearchAlertOptions {
  readonly savedSearchName: string;
  readonly projectId?: number;
  readonly projectSeoUrl?: string;
  readonly projectTitle?: string;
  readonly time?: number;
}

function generateSingleProjectSavedSearchAlert({
  projectId,
  projectSeoUrl,
  projectTitle,
  savedSearchName,
  time = Date.now(),
}: GenerateSingleProjectAlertOptions): SingleProjectSavedSearchAlert {
  return {
    id: `${generateId()}-${projectId}`,
    projectId,
    projectSeoUrl,
    projectTitle,
    savedSearchName,
    time,
    type: SavedAlertType.SINGLE_PROJECT,
  };
}

function generateMultipleProjectSavedSearchAlert({
  savedSearchName,
  time = Date.now(),
}: GenerateMultipleProjectAlertOptions): MultipleProjectSavedSearchAlert {
  return {
    id: generateId(),
    savedSearchName,
    time,
    type: SavedAlertType.MULTIPLE_PROJECT,
  };
}

export function generateRecentSavedSearchAlert({
  projectId,
  projectSeoUrl,
  projectTitle,
  savedSearchName,
  time = Date.now(),
}: GenerateRecentSavedSearchAlertOptions): RecentSavedSearchAlert {
  if (
    isDefined(projectId) &&
    isDefined(projectSeoUrl) &&
    isDefined(projectTitle)
  ) {
    return generateSingleProjectSavedSearchAlert({
      projectId,
      projectSeoUrl,
      projectTitle,
      savedSearchName,
      time,
    });
  }

  return generateMultipleProjectSavedSearchAlert({
    savedSearchName,
    time,
  });
}
