import { generateId } from '@freelancer/datastore/testing/helpers';
import { FeedContextTypeApi } from 'api-typings/feed/feed';
import type { FeedMeta } from './feed-meta.model';

export interface GenerateFeedMetaOptions {
  readonly contextType: FeedContextTypeApi;
  readonly contextId: number;
  readonly createdTime?: number;
  readonly isRead?: boolean;
  readonly timeRead?: number;
}

export function generateFeedMetaObjects({
  contextType,
  contextId,
  createdTime,
  isRead = true,
  timeRead,
}: GenerateFeedMetaOptions): FeedMeta {
  const now = Date.now();

  return {
    id: generateId(),
    contextType,
    contextId,
    created: createdTime || now,
    isRead,
    timeReadInMs: timeRead || now,
  };
}

export function groupFeedMeta(
  groupId: number,
): Pick<GenerateFeedMetaOptions, 'contextType' | 'contextId'> {
  return {
    contextType: FeedContextTypeApi.GROUP,
    contextId: groupId,
  };
}
