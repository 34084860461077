import { generateId } from '@freelancer/datastore/testing/helpers';
import { DiscoverCollectionItemTypeApi } from 'api-typings/users/users';
import type {
  DiscoverCollectionItemSaveCount,
  DiscoverCollectionItemSaveCountPerCollectionType,
} from './discover-collection-item-save-count.model';

export interface GenerateDiscoverCollectionItemSaveCountOptions {
  readonly id?: number;
  readonly itemType?: DiscoverCollectionItemTypeApi;
  readonly countPerCollectionType?: DiscoverCollectionItemSaveCountPerCollectionType;
}

export function generateDiscoverCollectionItemSaveCountObject({
  id = generateId(),
  itemType = DiscoverCollectionItemTypeApi.PORTFOLIO,
  countPerCollectionType = {},
}: GenerateDiscoverCollectionItemSaveCountOptions = {}): DiscoverCollectionItemSaveCount {
  return {
    id,
    itemType,
    countPerCollectionType,
  };
}

export function generateDiscoverCollectionItemSaveCounts(
  discoverCollectionItemSaveCountOptions: readonly GenerateDiscoverCollectionItemSaveCountOptions[],
): readonly DiscoverCollectionItemSaveCount[] {
  return discoverCollectionItemSaveCountOptions.map(
    discoverCollectionItemSaveCountOption =>
      generateDiscoverCollectionItemSaveCountObject(
        discoverCollectionItemSaveCountOption,
      ),
  );
}
