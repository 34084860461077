import { generateId } from '@freelancer/datastore/testing/helpers';
import type { UserNotificationPreferences } from './user-notification-preferences.model';

// From `GenerateUserOptions` with extra UserSelf-specific fields
export type GenerateUserNotificationPreferencesOptions =
  Partial<UserNotificationPreferences>;

export function generateUserNotificationPreferencesObject({
  id = String(generateId()),
  announcement = true,
  bidPrivateMessage = true,
  bidUpdateFrequency = -2,
  bidUpdate = true,
  communityDigest = true,
  contactsRequest = true,
  contestEntry = true,
  contestPrivateMessage = true,
  contestTopContest = true,
  deals = true,
  emailFormat = 'text/plain',
  groupComment = true,
  groupPost = true,
  groupCommentMention = true,
  groupPostPush = true,
  groupCommentPush = true,
  groupCommentMentionPush = true,
  groupInvite = true,
  groupReactPush = true,
  latestProjectsContestsFrequency = '24',
  latestProjectsContests = true,
  localProjectTransactionals = true,
  localProjectsSms = true,
  marketing = true,
  marketingSms = true,
  marketplace = true,
  milestoneRelease = true,
  nearbyLocalProjects = true,
  newsletter = true,
  privateMessageFromContact = true,
  privateMessage = true,
  projectAwarded = true,
  projectContestMessage = true,
  projectUpdatesSms = true,
  receiveSms = true,
  recommendTopFreelancer = true,
  recruiterContact = true,
  referral = true,
  services = true,
  sites = true,
  staffMessage = true,
  tasklistInvite = true,
  tasklistPush = true,
}: GenerateUserNotificationPreferencesOptions = {}): UserNotificationPreferences {
  return {
    id,
    announcement,
    bidPrivateMessage,
    bidUpdateFrequency,
    bidUpdate,
    communityDigest,
    contactsRequest,
    contestEntry,
    contestPrivateMessage,
    contestTopContest,
    deals,
    emailFormat,
    groupComment,
    groupPost,
    groupCommentMention,
    groupPostPush,
    groupCommentPush,
    groupCommentMentionPush,
    groupInvite,
    groupReactPush,
    latestProjectsContestsFrequency,
    latestProjectsContests,
    localProjectTransactionals,
    localProjectsSms,
    marketing,
    marketingSms,
    marketplace,
    milestoneRelease,
    nearbyLocalProjects,
    newsletter,
    privateMessageFromContact,
    privateMessage,
    projectAwarded,
    projectContestMessage,
    projectUpdatesSms,
    receiveSms,
    recommendTopFreelancer,
    recruiterContact,
    referral,
    services,
    sites,
    staffMessage,
    tasklistInvite,
    tasklistPush,
  };
}
