import { Injectable } from '@angular/core';
import type { CalloutComponentInterface } from './callout.types';

@Injectable({ providedIn: 'root' })
export class CalloutService {
  private activeHoverCallout: CalloutComponentInterface;

  activateHover(callout: CalloutComponentInterface): void {
    if (this.activeHoverCallout) {
      this.activeHoverCallout.close();
    }

    this.activeHoverCallout = callout;
  }
}
