import { generateId } from '@freelancer/datastore/testing/helpers';
import { subDays } from 'date-fns';
import type { CustomFieldValue } from '../custom-field-info-configurations';
import {
  AUDIENCE_FILTER_TO_ES_FIELD_VALUES_MAP,
  MetricType,
  ProjectAudienceType,
} from './analytics.backend-model';
import type { Analytics, Metric } from './analytics.model';

export interface GenerateAnalyticsOptions {
  readonly metrics: readonly Metric[];
  readonly enterpriseId: number;
  readonly customFieldValues?: readonly CustomFieldValue[];
  readonly jobIds?: readonly number[];
  readonly poolIds?: readonly number[];
  readonly minDate?: Date;
  readonly maxDate?: Date;
  readonly audience?: readonly string[];
}

export function generateAnalyticsObjects({
  metrics,
  enterpriseId,
  customFieldValues = [],
  jobIds = [],
  poolIds = [],
  minDate = subDays(new Date(), 31),
  maxDate = new Date(),
  audience = AUDIENCE_FILTER_TO_ES_FIELD_VALUES_MAP[ProjectAudienceType.NONE],
}: GenerateAnalyticsOptions): readonly Analytics[] {
  return metrics.map(metric => ({
    ...metric,
    id: generateAnalyticsId({
      metricType: metric.metricType,
      jobIds,
      maxSubmitDate: maxDate,
      minSubmitDate: minDate,
      poolIds,
      enterpriseId,
      audience,
    }),
    enterpriseId,
    customFieldValues,
    jobIds,
    poolIds,
    minSubmitDate: minDate,
    maxSubmitDate: maxDate,
    audience,
  }));
}

// From analytics.transformers.ts
function generateAnalyticsId({
  metricType,
  enterpriseId,
  customFieldValues,
  jobIds,
  poolIds,
  maxSubmitDate,
  minSubmitDate,
  audience,
}: {
  readonly metricType: MetricType;
  readonly enterpriseId: number;
  readonly customFieldValues?: readonly CustomFieldValue[];
  readonly jobIds: readonly number[];
  readonly poolIds: readonly number[];
  readonly maxSubmitDate: Date;
  readonly minSubmitDate: Date;
  readonly audience: readonly string[];
}): string {
  return `${generateId()}-${metricType}-${enterpriseId}-${jobIds}-${poolIds}-${maxSubmitDate}-${minSubmitDate}-${JSON.stringify(
    customFieldValues,
  )}-${JSON.stringify(audience)}`;
}

export function projectsPostedMetric(value = 8888): Metric {
  return {
    metricType: MetricType.PROJECTS,
    result: value,
  };
}

export function projectsAwardedMetric(value = 55): Metric {
  return {
    metricType: MetricType.PROJECTS_AWARDED,
    result: value,
  };
}

export function externalProjectsAwardedMetric(value = 10): Metric {
  return {
    metricType: MetricType.EXTERNAL_PROJECTS_AWARDED,
    result: value,
  };
}

export function projectsUnawardedMetric(value = 88): Metric {
  return {
    metricType: MetricType.PROJECTS_UNAWARDED,
    result: value,
  };
}

export function medianBidCountMetric(value = 3): Metric {
  return {
    metricType: MetricType.MEDIAN_BID_COUNT,
    result: value,
  };
}

export function spendOverTimeMetric(): Metric {
  return {
    metricType: MetricType.SPEND,
    result: {
      totalHits: 143,
      buckets: {
        '2022/12/1': 12,
        '2022/11/1': 20,
        '2022/10/1': 19,
        '2022/09/1': 50,
        '2022/08/1': 42,
      },
    },
  };
}

export function spendOverTimeCumulativeSumMetric(): Metric {
  return {
    metricType: MetricType.SPEND_CUMULATIVE_SUM,
    result: {
      totalHits: 143,
      buckets: {
        '2022/12/1': 12,
        '2022/11/1': 20,
        '2022/10/1': 19,
        '2022/09/1': 50,
        '2022/08/1': 42,
      },
    },
  };
}

export function meanBidAmountMetric(value = 3): Metric {
  return {
    metricType: MetricType.MEAN_BID_AMOUNT,
    result: value,
  };
}

export function medianBidAmountMetric(value = 5): Metric {
  return {
    metricType: MetricType.MEDIAN_BID_AMOUNT,
    result: value,
  };
}

export function usersActiveMetric(): Metric {
  return {
    metricType: MetricType.USERS_ACTIVE,
    result: 3,
  };
}

export function hoursPostedMetric(): Metric {
  return {
    metricType: MetricType.HOURS_POSTED,
    result: {
      totalHits: 143,
      buckets: {
        '2022/12/1': 12,
        '2022/11/1': 20,
        '2022/10/1': 19,
        '2022/09/1': 50,
        '2022/08/1': 42,
      },
    },
  };
}

export function hoursPostedCumulativeSumMetric(): Metric {
  return {
    metricType: MetricType.SPEND,
    result: {
      totalHits: 143,
      buckets: {
        '2022/12/1': 12,
        '2022/11/1': 20,
        '2022/10/1': 19,
        '2022/09/1': 50,
        '2022/08/1': 42,
      },
    },
  };
}

export function averageTimeToAwardMetric(): Metric {
  return {
    metricType: MetricType.AVG_TIME_TO_AWARD,
    result: {
      totalHits: 143,
      buckets: {
        '2022/12/1': 12,
        '2022/11/1': 20,
        '2022/10/1': 19,
        '2022/09/1': 50,
        '2022/08/1': 42,
      },
    },
  };
}

export function talentSkillsMetric(): Metric {
  return {
    metricType: MetricType.TALENT_SKILLS,
    result: {
      totalHits: 143,
      buckets: {
        3: 12,
        17: 2,
        20: 1,
        32: 6,
        44: 9,
        69: 34,
        335: 45,
        417: 14,
        955: 8,
        1487: 12,
      },
    },
  };
}

export function internalUserSkillsMetric(): Metric {
  return {
    metricType: MetricType.INTERNAL_USER_SKILLS,
    result: {
      totalHits: 143,
      buckets: {
        3: 12,
        17: 2,
        20: 1,
        32: 6,
        44: 9,
        69: 34,
        335: 45,
        417: 14,
        955: 8,
        1487: 12,
      },
    },
  };
}

export function projectSkillsMetric(): Metric {
  return {
    metricType: MetricType.PROJECT_SKILLS,
    result: {
      totalHits: 143,
      buckets: {
        3: 12,
        17: 2,
        20: 1,
        32: 6,
        44: 9,
        69: 34,
        335: 45,
        417: 14,
        955: 8,
        1487: 12,
      },
    },
  };
}

export function billableMetric(
  billableValue = 9,
  nonBillableValue = 22,
): Metric {
  return {
    metricType: MetricType.BILLABLE,
    result: {
      totalHits: billableValue + nonBillableValue,
      buckets: {
        billable: billableValue,
        _other_: nonBillableValue,
      },
    },
  };
}

export function audienceInternalOnly(): Pick<
  GenerateAnalyticsOptions,
  'audience'
> {
  return {
    audience:
      AUDIENCE_FILTER_TO_ES_FIELD_VALUES_MAP[ProjectAudienceType.INTERNAL_ONLY],
  };
}

export function audienceExternal(): Pick<GenerateAnalyticsOptions, 'audience'> {
  return {
    audience:
      AUDIENCE_FILTER_TO_ES_FIELD_VALUES_MAP[
        ProjectAudienceType.INTERNAL_AND_EXTERNAL
      ],
  };
}

// END Filter mixins
export function npsiScoreMetric(value = 10): Metric {
  return {
    metricType: MetricType.NPS_SCORE,
    result: value,
  };
}
