import type {
  CollectionActions,
  CollectionStateSlice,
} from '@freelancer/datastore/core';
import {
  mergeDocuments,
  transformIntoDocuments,
} from '@freelancer/datastore/core';
import { transformCampaigns } from './campaigns.transformer';
import type { CampaignsCollection } from './campaigns.types';

export function campaignsReducer(
  state: CollectionStateSlice<CampaignsCollection> = {},
  action: CollectionActions<CampaignsCollection>,
): CollectionStateSlice<CampaignsCollection> {
  switch (action.type) {
    case 'API_FETCH_SUCCESS': {
      if (action.payload.type === 'campaigns') {
        const { ref, result, order } = action.payload;
        return mergeDocuments<CampaignsCollection>(
          state,
          transformIntoDocuments(result.campaigns, transformCampaigns),
          order,
          ref,
        );
      }
      return state;
    }
    default:
      return state;
  }
}
