import type { SiteStats } from './site-stats.model';

export interface GenerateSiteStatsOptions {
  readonly userId: number;
  readonly projectCount?: number;
  readonly projectCountPerDay?: number;
  readonly userCount?: number;
  readonly userCountPerDay?: number;
}

export function generateSiteStatsObject({
  userId,
  projectCount = 4_864_009,
  projectCountPerDay = 3021,
  userCount = 64_441_474,
  userCountPerDay = 1780,
}: GenerateSiteStatsOptions): SiteStats {
  return {
    id: userId.toString(),
    projectCount,
    projectCountPerDay,
    userCount,
    userCountPerDay,
  };
}
