import { generateId } from '@freelancer/datastore/testing/helpers';
import type { MarketingMobileNumber } from '@freelancer/users-shared';
import type { AddressApi } from 'api-typings/common/common';
import type {
  DateOfBirthApi,
  FreelancerVerifiedUserStatusApi,
} from 'api-typings/users/users';
import { MarketingMobileNumberStatusApi } from 'api-typings/users/users';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { UserStatus } from '../project-view-users';
import type { SecurePhone } from '../security-phone-requests';
import { verifiedUser } from '../users/users.seed';
import type {
  DirectoryAndFollowPreferences,
  UsersSelf,
} from './users-self.model';
import { getUsersSelfComputedFields } from './users-self.transformers';

// From `GenerateUserOptions` with extra UserSelf-specific fields
export interface GenerateUserSelfOptions {
  readonly userId?: number;
  readonly username?: string;
  readonly displayName?: string;
  readonly enterpriseIds?: readonly number[];
  readonly enterpriseInternalNames?: readonly string[];
  readonly currencyCode?: CurrencyCode;
  readonly userStatus?: UserStatus;
  readonly hasLinkedEscrowComAccount?: boolean;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly address?: AddressApi;
  readonly dateOfBirth?: DateOfBirthApi;
  readonly isLimitedAccount?: boolean;
  readonly email?: string;
  readonly directoryAndFollowPreferences?: DirectoryAndFollowPreferences;
  readonly securePhone?: SecurePhone;
  readonly isNominatedPaymentControlled?: boolean;
  readonly registrationDate?: number;
  readonly freelancerVerifiedStatus?: FreelancerVerifiedUserStatusApi;
  readonly marketingMobileNumber?: MarketingMobileNumber;
  readonly primaryLanguage?: string;
}

export function generateUserSelfObject({
  userId = generateId(),
  username = 'testUsername',
  displayName = 'Test Name',
  enterpriseIds = [],
  enterpriseInternalNames = [],
  currencyCode = CurrencyCode.USD,
  userStatus = verifiedUser().userStatus,
  hasLinkedEscrowComAccount = false,
  firstName = 'Test',
  lastName = 'Name',
  address = {
    address1: '525 W. Van Buren',
    address2: 'Suite 1100',
    city: 'Chicago',
    state_code: 'IL',
    zip: '60607',
    country: 'United States',
  },
  dateOfBirth,
  isLimitedAccount = false,
  email = 'testuser@freelancer.com',
  directoryAndFollowPreferences = {
    allowListing: true,
    allowWatch: true,
    allowSealPriceOnReview: true,
  },
  securePhone,
  marketingMobileNumber,
  isNominatedPaymentControlled = false,
  registrationDate = Date.now(),
  primaryLanguage = 'en',
}: GenerateUserSelfOptions = {}): UsersSelf {
  return {
    id: userId,
    username,
    displayName,
    enterpriseIds,
    enterpriseInternalNames,
    status: userStatus,
    hasLinkedEscrowComAccount,
    isLimitedAccount,

    // fields specific to UsersSelf
    primaryCurrency: generateCurrencyObject(currencyCode),
    firstName,
    lastName,
    address,
    dateOfBirth,
    email,
    directoryAndFollowPreferences,
    securePhone,
    marketingMobileNumber,
    isNominatedPaymentControlled,
    registrationDate,
    primaryLanguage,
    // Computed fields are `profileComplete` and `escrowProfileComplete`.
    // By default, the user is `profileComplete` but not `escrowProfileComplete`
    ...getUsersSelfComputedFields(firstName, lastName, address, dateOfBirth),
  };
}

export type GenerateMarketingMobileNumberOptions =
  Partial<MarketingMobileNumber>;
export function generateMarketingMobileNumber({
  phoneNumber = '12345678',
  countryCode = '61',
}: GenerateMarketingMobileNumberOptions = {}): Pick<
  GenerateUserSelfOptions,
  'marketingMobileNumber'
> {
  return {
    marketingMobileNumber: {
      phoneNumber,
      countryCode,
      status: MarketingMobileNumberStatusApi.VALID,
    },
  };
}

export function escrowProfileCompleteUserSelf({
  dateOfBirth = {
    day: 1,
    month: 1,
    year: 1917,
  },
}: {
  readonly dateOfBirth?: DateOfBirthApi;
} = {}): Pick<GenerateUserSelfOptions, 'dateOfBirth'> {
  return {
    dateOfBirth,
  };
}
