import type { ContestInterestedUser } from './contest-interested-users.model';

export interface GenerateContestInterestedUserObjectOptions {
  readonly userId: number;
  readonly contestId: number;
}

export function generateContestInterestedUserObject({
  userId,
  contestId,
}: GenerateContestInterestedUserObjectOptions): ContestInterestedUser {
  return {
    id: `${contestId}-${userId}`,
    userId,
    contestId,
    timeCreated: Date.now(),
  };
}
