import type { PayoutContext } from './payout-context.model';

export function generatePayoutContextObjects(
  config: GeneratePayoutContextOptions,
): readonly PayoutContext[] {
  return [
    {
      id: config.id ?? '1111',
      identityVerified: config.identityVerified ?? true,
      showMembershipBanner: config.showMembershipBanner ?? true,
      timestamp2faInMs: config.timestamp2faInMs ?? 123,
    },
  ];
}

export interface GeneratePayoutContextOptions {
  readonly id?: string;
  readonly identityVerified?: boolean;
  readonly showMembershipBanner?: boolean;
  readonly timestamp2faInMs?: number;
}
