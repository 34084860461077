import { generateId } from '@freelancer/datastore/testing/helpers';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { Skill } from '../skills';
import { generateAvatar } from '../users';
import type { CategoryPageViewUser } from './category-page-view-users.model';

export interface GenerateCategoryPageViewUserOptions {
  readonly id?: number;
  readonly currencyCode?: CurrencyCode;
  readonly hourlyRate?: number;
  readonly publicName?: string;
  readonly rating?: number;
  readonly skills?: readonly Skill[];
  readonly username?: string;
}

export function generateCategoryPageViewUserObject({
  id = generateId(),
  username = `testUsername${id}`,
  publicName = `Test Name ${id}`,
  rating = 3,
  hourlyRate = 10,
  skills = [],
  currencyCode = CurrencyCode.USD,
}: GenerateCategoryPageViewUserOptions = {}): CategoryPageViewUser {
  return {
    id,
    avatar: generateAvatar(id),
    avatarXLarge: generateAvatar(id),
    primaryCurrency: generateCurrencyObject(currencyCode),
    profileUrl: `/u/${username}`,
    username,
    publicName,
    hourlyRate,
    skills,
    closed: false,
    jobs: [],
    rating,
    reviews: 0,
  };
}
