import type { UserReviewRole } from './user-has-given-feedback.backend-model';
import type { UserHasGivenFeedback } from './user-has-given-feedback.model';

export interface GenerateUserHasGivenFeedbackOptions {
  readonly feedbackLeft: boolean;
  readonly projectId: number;
  readonly toUserId: number;
  readonly fromUserId: number;
  readonly reviewType: UserReviewRole;
}

export function generateUserHasGivenFeedbackObject({
  feedbackLeft,
  projectId,
  toUserId,
  fromUserId,
  reviewType,
}: GenerateUserHasGivenFeedbackOptions): UserHasGivenFeedback {
  if (toUserId === fromUserId) {
    throw new Error('Review giver and recipient should not be the same');
  }
  return {
    id: `${projectId}-${toUserId}`,
    feedbackLeft,
    projectId,
    toUserId,
    fromUserId,
    reviewType,
  };
}
