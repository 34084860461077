import type { MembershipBadgeType } from '@freelancer/ui/badge';

export enum UsernameSize {
  SMALL = 'small',
  MID = 'mid',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
  PROFILE_SUMMARY_MOBILE = 'profile_summary_mobile',
  PROFILE_SUMMARY = 'profile_summary',
}

export enum UsernameColor {
  DARK = 'dark',
  LIGHT = 'light',
  INHERIT = 'inherit',
  FOREGROUND = 'foreground',
}

export interface UsernameBadges {
  corporate?: boolean;
  do_gooder?: boolean;
  preferred_freelancer?: boolean;
  rising_star?: boolean;
  shareholder?: boolean;
  verified?: boolean;
  freelancer_recruiter?: boolean;
  freelancer_staff?: boolean;
}

export interface UsernameMembershipPackage {
  badgeType?: MembershipBadgeType;
  name: string;
}
