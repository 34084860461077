import { generateId } from '@freelancer/datastore/testing/helpers';
import type { AdminIrisOffsitingModel } from './admin-iris-offsiting.model';

export interface GenerateAdminIrisOffsiting {
  readonly id?: number;
  readonly usersId: number;
  readonly threadId: number;
  readonly contextId: number;
  readonly offenceType?: string;
  readonly offenceValue?: string;
  readonly minMessageId?: number;
  readonly maxMessageId?: number;
  readonly messageCount?: number;
  readonly threadMembers?: string;
  readonly contextType?: string;
  readonly createdTime?: number;
}

/**
 * Create an offsiting message object
 */
export function generateAdminIrisOffsitingObject({
  id = generateId(),
  usersId,
  threadId,
  contextId,
  offenceType,
  offenceValue,
  minMessageId,
  maxMessageId,
  messageCount,
  threadMembers,
  contextType,
  createdTime,
}: GenerateAdminIrisOffsiting): AdminIrisOffsitingModel {
  return {
    id,
    usersId,
    threadId,
    contextId,
    offenceType,
    offenceValue,
    minMessageId,
    maxMessageId,
    messageCount,
    threadMembers,
    contextType,
    createdTime,
  };
}
