import { generateId } from '@freelancer/datastore/testing/helpers';
import type { SupportTag } from './support-tags.model';

export interface GenerateSupportTagOptions {
  readonly id?: number;
  readonly internalName?: string;
  readonly label?: string;
  readonly isArchived?: boolean;
  readonly isEditable?: boolean;
  readonly createTime?: number;
}

export function generateSupportTagObject({
  id = generateId(),
  internalName = 'project_status_open',
  label = 'Open Project',
  isArchived = false,
  isEditable = false,
  createTime = 12_345,
}: GenerateSupportTagOptions = {}): SupportTag {
  return {
    id,
    internalName,
    label,
    isArchived,
    isEditable,
    createTime,
  };
}
