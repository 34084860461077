import { generateId } from '@freelancer/datastore/testing/helpers';
import type { RecommendedUsername } from './recommended-usernames.model';

export interface GenerateRecommendedUserNamesOptions {
  readonly id?: string;
  readonly email?: string;
  readonly username?: string;
  readonly baseName?: string;
}

export function generateRecommendedUserNamesObject({
  id = `${generateId()}`,
  email = 'test@email.com',
  username = 'test',
  baseName = 'Sample',
}: GenerateRecommendedUserNamesOptions = {}): RecommendedUsername {
  return {
    id,
    email,
    username,
    baseName,
  };
}
