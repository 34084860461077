import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontColor, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-brand-style-guide-principles',
  template: `
    <div class="BrandPrinciples">
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Brand principles
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        This document is your guide to our brand. We're passionate about
        nurturing it, and it thrives when we maintain consistency across all
        interactions. Use this guideline for everything related to our visual
        identity, and let's work together to make our brand truly remarkable.
      </fl-text>
      <div [flMarginBottom]="'xlarge'">
        <fl-picture
          [fullWidth]="true"
          [src]="'brand-guidelines/img-1.png'"
        ></fl-picture>
      </div>
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Mission
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        Empowering entrepreneurs globally through the world's largest
        marketplace for freelancing and crowdsourcing.
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Vision
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        To change a billion lives on this planet by giving them opportunity and
        income through a job.
      </fl-text>
      <div [flMarginBottom]="'xlarge'">
        <fl-picture
          class="ChangeLives"
          [fullWidth]="true"
          [src]="'brand-guidelines/change-lives.png'"
        ></fl-picture>
      </div>
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Brand values
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        Our brand values are rooted in our mission to empower entrepreneurs
        globally through the world's largest marketplace for freelancing and
        crowdsourcing and can be drawn upon to guide future design explorations.
      </fl-text>
      <div class="BrandValues">
        <div
          class="BrandValues-column"
          [flMarginBottom]="'mid'"
          [flMarginBottomTablet]="'none'"
        >
          <div [flMarginBottom]="'small'">
            <fl-picture
              [fullWidth]="true"
              [src]="'brand-guidelines/bv-1.png'"
            ></fl-picture>
          </div>
          <fl-text
            [color]="FontColor.LIGHT"
            [weight]="FontWeight.BOLD"
            [size]="TextSize.XLARGE"
            [sizeDesktop]="TextSize.XXLARGE"
            [flMarginBottom]="'mid'"
          >
            Change lives
          </fl-text>
          <fl-text
            [color]="FontColor.LIGHT"
            [size]="TextSize.SMALL"
            [sizeDesktop]="TextSize.LARGE"
          >
            Transforming lives and providing opportunities to connect,
            collaborate, and thrive in the ever-evolving digital landscape.
          </fl-text>
        </div>
        <div
          class="BrandValues-column"
          [flMarginBottom]="'mid'"
          [flMarginBottomTablet]="'none'"
        >
          <div [flMarginBottom]="'small'">
            <fl-picture
              [fullWidth]="true"
              [src]="'brand-guidelines/bv-2.png'"
            ></fl-picture>
          </div>
          <fl-text
            [color]="FontColor.LIGHT"
            [weight]="FontWeight.BOLD"
            [size]="TextSize.XLARGE"
            [sizeDesktop]="TextSize.XXLARGE"
            [flMarginBottom]="'mid'"
          >
            Innovation
          </fl-text>
          <fl-text
            [color]="FontColor.LIGHT"
            [size]="TextSize.SMALL"
            [sizeDesktop]="TextSize.LARGE"
          >
            Cutting-edge technologies opens doors to remote work, breaking down
            geographical barriers and expanding opportunities for professionals
            worldwide.
          </fl-text>
        </div>
        <div class="BrandValues-column">
          <div [flMarginBottom]="'small'">
            <fl-picture
              [fullWidth]="true"
              [src]="'brand-guidelines/bv-3.png'"
            ></fl-picture>
          </div>
          <fl-text
            [color]="FontColor.LIGHT"
            [weight]="FontWeight.BOLD"
            [size]="TextSize.XLARGE"
            [sizeDesktop]="TextSize.XXLARGE"
            [flMarginBottom]="'mid'"
          >
            Inclusivity
          </fl-text>
          <fl-text
            [color]="FontColor.LIGHT"
            [size]="TextSize.SMALL"
            [sizeDesktop]="TextSize.LARGE"
          >
            A vast talent pool covers diverse industries and offers equal
            opportunities for professionals to showcase their skills and connect
            with clients globally.
          </fl-text>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./../brand-style-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandStyleGuidePrinciplesComponent {
  FontColor = FontColor;
  FontWeight = FontWeight;
  TextSize = TextSize;
}
