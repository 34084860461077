import { generateId } from '@freelancer/datastore/testing/helpers';
import { PaymentShareEntityTypeApi } from 'api-typings/users/users';
import type { PaymentShareWhitelistItem } from './payment-share-whitelist-items.model';

export interface GeneratePaymentShareWhitelistItemOptions {
  readonly entityId: number;
  readonly entityType?: PaymentShareEntityTypeApi;
}

/** Returns a payment share team object. */
export function generatePaymentShareWhitelistItemObject({
  entityId,
  entityType = PaymentShareEntityTypeApi.USER,
}: GeneratePaymentShareWhitelistItemOptions): PaymentShareWhitelistItem {
  return {
    id: generateId(),
    entityId,
    entityType,
  };
}
