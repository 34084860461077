import {
  generateId,
  getRandomText,
} from '@freelancer/datastore/testing/helpers';
import { isArray as isAnArray, isDefined } from '@freelancer/utils';
import { ResourceTypeApi } from 'api-typings/resources/metadata';
import { Enterprise } from '../enterprise/enterprise.model';
import {
  HPAdditionalFailureCode,
  HPDelayCode,
  HPNMUClassBase,
  HPNMUSubclassBase,
  HPProjectCustomField,
  HPRepairClass,
  HPReturnOverrideReason,
  HPTimeLog,
  HPUsageCode,
  HP_TIME_LOG_LABOR_TYPE_VALUE_MAPPING,
} from '../hp/hp.model';
import type {
  CustomFieldInfo,
  CustomFieldInfoConfiguration,
  CustomFieldValue,
  FieldValue,
} from './custom-field-info-configurations.model';
import { FieldType } from './custom-field-info-configurations.model';

// Generate Options

interface GenerateCustomFieldInfoOptions {
  readonly name: string;
  readonly fieldType: FieldType;
  readonly customFieldInfoId?: number;
  readonly isArray?: boolean;
  readonly description?: string;
  readonly resourceType?: ResourceTypeApi;
  readonly timeCreated?: number;
  readonly parentId?: number;
}

export type GenerateCustomFieldInfoConfigurationOptions = {
  readonly configurationId?: number;
  readonly enterpriseId?: number;
  readonly defaultValue?: FieldValue;
  readonly searchable?: boolean;
  readonly timeEnabled?: number;
} & GenerateCustomFieldInfoOptions;

export type GenerateCustomFieldValueOptions = {
  readonly resourceId: number;
  readonly customFieldInfoConfigurationId?: number;
  readonly isDefaultValue?: boolean;
} & FieldValue;

/**
 * Provides options to seed customFieldValue and customFieldInfoConfigurations
 * The custom field is a field and value by nature thus this model. It serves to encapsulate
 * the field's options and value's options of the custom field.
 */
export type CustomFieldValueAndConfigurationOptions = {
  /**
   * Used to seed the customFieldInfoConfigurations
   */
  readonly fieldOptions: GenerateCustomFieldInfoConfigurationOptions;
} & (
  | {
      /**
       * Used to seed the CustomFieldValue of a resource
       */
      readonly valueOptions?: GenerateCustomFieldValueOptions;
      readonly isArray: false;
    }
  | {
      /**
       * Used to seed the CustomFieldValue with array value of a resource
       */
      readonly valueOptions?: readonly GenerateCustomFieldValueOptions[];
      readonly isArray: true;
    }
);

function generateCustomFieldInfo({
  name,
  fieldType,
  customFieldInfoId,
  isArray = false,
  description = 'test field description',
  resourceType = ResourceTypeApi.USER,
  timeCreated = Date.now(),
  parentId = undefined,
}: GenerateCustomFieldInfoOptions): CustomFieldInfo {
  const id = customFieldInfoId !== undefined ? customFieldInfoId : generateId();

  return {
    id,
    name,
    isArray,
    description,
    resourceType,
    fieldType,
    timeCreated,
    parentId,
  };
}

export function generateCustomFieldInfoConfiguration({
  name,
  description,
  fieldType,
  defaultValue,
  configurationId,
  isArray = false,
  enterpriseId = Enterprise.DELOITTE_DC,
  resourceType = ResourceTypeApi.USER,
  searchable = false,
  timeCreated = Date.now(),
  timeEnabled = Date.now(),
  parentId = undefined,
  customFieldInfoId = undefined,
}: GenerateCustomFieldInfoConfigurationOptions): CustomFieldInfoConfiguration {
  const id = configurationId !== undefined ? configurationId : generateId();

  return {
    id,
    enterpriseId,
    customFieldInfo: generateCustomFieldInfo({
      name,
      description,
      isArray,
      resourceType,
      fieldType,
      parentId,
      customFieldInfoId,
    }),
    defaultValue,
    searchable,
    resourceType,
    timeCreated,
    timeEnabled,
  };
}

export function generateCustomFieldValue({
  type,
  value,
  customFieldInfoConfigurationId = generateId(),
  resourceId = generateId(),
  isDefaultValue = false,
}: GenerateCustomFieldValueOptions): CustomFieldValue {
  return {
    customFieldInfoConfigurationId,
    resourceId,
    isDefaultValue,
    type,
    value,
  } as CustomFieldValue;
}

export function generateCustomFieldValues(
  options: readonly GenerateCustomFieldValueOptions[],
): readonly CustomFieldValue[] {
  return options.map(option => generateCustomFieldValue(option));
}

export function generateCustomFieldInfoConfigurationObjects(
  customFieldInfoConfigurationArray: readonly GenerateCustomFieldInfoConfigurationOptions[],
): readonly CustomFieldInfoConfiguration[] {
  return customFieldInfoConfigurationArray.map(customFieldInfoConfiguration =>
    generateCustomFieldInfoConfiguration(customFieldInfoConfiguration),
  );
}
/**
 * Used to generate a real model CustomFieldValues from field and value pair.
 * It will then be used by seed to intialize a resource such as user.
 * CustomFieldsValue is the value of the CustomFieldInfoConfiguration.
 */
export function getCustomFieldValues(
  fieldAndValueOptions: readonly CustomFieldValueAndConfigurationOptions[],
): readonly CustomFieldValue[] {
  return fieldAndValueOptions
    .map(options => {
      if (!options.valueOptions) {
        return undefined;
      }
      return options.isArray
        ? generateCustomFieldValues(options.valueOptions)
        : [generateCustomFieldValue(options.valueOptions)];
    })
    .filter(isDefined)
    .flat();
}

/**
 * Grabs the CustomFieldInfoConfigurationOption from
 * the combined field and value option or CustomFieldValueAndConfigurationOptions.
 * Will be used to seed the CustomFieldInfoConfiguration.
 */
export function getCustomFieldInfoConfigurationOptions(
  fieldAndValueOptions: readonly CustomFieldValueAndConfigurationOptions[],
): readonly GenerateCustomFieldInfoConfigurationOptions[] {
  return fieldAndValueOptions.map(options => options.fieldOptions);
}

// BEGIN - Mixins for CustomFieldValueAndConfigurationOptions

export function firstName(
  resourceId: number,
  value?: string,
  resourceType = ResourceTypeApi.USER,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'first_name',
      fieldType,
      resourceType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function lastName(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'last_name',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function level(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'level',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function legalEntityAlignment(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'legal_entity_alignment',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function practice(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'practice',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function offeringPortfolio(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'offering_portfolio',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function independenceRuleset(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'independence_ruleset',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function phoneNumberWorkCell(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'phone_number_work_cell',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function phoneNumberSkype(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'phone_number_skype',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function memberFirmAlignment(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'member_firm_alignment',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function countryAlignment(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'country_alignment',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function emailAddress(
  resourceId: number,
  value?: string,
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: false,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      name: 'email_address',
      fieldType,
    },
    valueOptions: value
      ? {
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }
      : undefined,
  };
}

export function certifications(
  resourceId: number,
  values?: readonly string[],
): CustomFieldValueAndConfigurationOptions {
  const fieldType = FieldType.STRING;
  const customFieldInfoConfigurationId = generateId();
  return {
    isArray: true,
    fieldOptions: {
      configurationId: customFieldInfoConfigurationId,
      isArray: true,
      name: 'certifications',
      fieldType,
    },
    valueOptions: values
      ? values.map(value => ({
          customFieldInfoConfigurationId,
          resourceId,
          value,
          type: fieldType,
        }))
      : undefined,
  };
}

// END - Mixins for CustomFieldValueAndConfigurationOptions

interface NameAndValue {
  readonly name: string;
  readonly value?: // The type list is not exahaustive. Please add as required.
  | string
    | boolean
    | number
    | readonly string[]
    | readonly boolean[]
    | readonly number[]
    | NameAndValue // for nested objects
    | readonly NameAndValue[];
  readonly fieldType: FieldType;
  readonly isArray?: boolean;
  readonly isObject?: boolean;
}

export function getHpisProjectCustomFieldsAndValues({
  resourceId,
  taskCompleted,
  workOrderId = 'WO-1234567',
  arrivalTime,
  caseSeverity,
  casePriority,
  caseId = 'casetest123',
  productLine = 'M5',
  progressBlocked = false,
  repairClass = HPRepairClass.AO,
  nmuClass = HPNMUClassBase.BIOS_RECOVERY,
  nmuSubclass = HPNMUSubclassBase.WIN_V,
  nmuVersionNumber = '1.2.3',
  delayCode = HPDelayCode.RESPONSE_MET,
  usageCode = HPUsageCode.SAUN_UNUSED,
  returnOverrideFlag = false,
  resolutionNotes = getRandomText(20, 20),
  priorityAlert = [],
  generatePartOrders = true,
  generatePartOrderLineItems = true,
  generateTimeLogItems = false,
  generateEngineerNotes = false,
  bookableResource = 'TEST_REGION',
  requestedDateTime,
  travelZone = '01',
  serviceStartDatetime = new Date('Jan 1 2023 10:11').getTime(),
  serviceEndDatetime = new Date('Jan 2 2023 01:11').getTime(),
  systemFixedDatetime = new Date('Jan 1 2023 22:11').getTime(),
}: {
  readonly resourceId: number;
  readonly taskCompleted: boolean;
  readonly workOrderId?: string;
  readonly arrivalTime?: number;
  readonly caseSeverity?: string;
  readonly casePriority?: string;
  readonly caseId?: string;
  readonly productLine?: string;
  readonly progressBlocked?: boolean;
  readonly repairClass?: string;
  readonly nmuClass?: string;
  readonly nmuSubclass?: string;
  readonly nmuVersionNumber?: string;
  readonly delayCode?: string;
  readonly usageCode?: string;
  readonly timeLogLaborType?: string;
  readonly laborMinutes?: number;
  readonly resolutionNotes?: string;
  readonly additionalFailureCode?: string;
  readonly returnOverrideFlag?: boolean;
  readonly priorityAlert?: readonly string[];
  readonly generatePartOrders?: boolean;
  readonly generatePartOrderLineItems?: boolean;
  readonly generateTimeLogItems?: boolean;
  readonly generateEngineerNotes?: boolean;
  readonly bookableResource?: string;
  readonly requestedDateTime?: number;
  readonly travelZone?: string;
  readonly serviceStartDatetime?: number;
  readonly serviceEndDatetime?: number;
  readonly systemFixedDatetime?: number;
}): {
  readonly values: readonly CustomFieldValue[];
  readonly fields: readonly GenerateCustomFieldInfoConfigurationOptions[];
} {
  const rawNamesAndValues: readonly NameAndValue[] = [
    {
      name: HPProjectCustomField.WORK_ORDER_ID,
      value: workOrderId,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.TASK_COMPLETED,
      value: taskCompleted,
      fieldType: FieldType.BOOLEAN,
    },
    {
      name: HPProjectCustomField.ARRIVAL_TIME,
      value: arrivalTime,
      fieldType: FieldType.TIMESTAMP,
    },
    {
      name: HPProjectCustomField.CASE_EXCHANGE_ID,
      value: caseId,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.PROGRESS_BLOCKED,
      value: progressBlocked,
      fieldType: FieldType.BOOLEAN,
    },
    {
      name: HPProjectCustomField.ASSET_PRODUCT_LINE_CD,
      value: productLine,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.PRIORITY_ALERT,
      value: priorityAlert,
      fieldType: FieldType.STRING,
      isArray: true,
    },
    {
      name: HPProjectCustomField.REPAIR_CLASS,
      value: repairClass,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.DELAY_CODE,
      value: delayCode,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.REPAIR_CLASS,
      value: repairClass,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.NMU_CLASS,
      value: nmuClass,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.NMU_SUBCLASS,
      value: nmuSubclass,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.VERSION_NUMBER,
      value: nmuVersionNumber,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.USAGE_CODE,
      value: usageCode,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.RESOLUTION_NOTES,
      value: resolutionNotes,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.BOOKING_DETAILS_RESOURCE_NAME,
      value: bookableResource,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.REQUESTED_DATE_TIME,
      value: requestedDateTime,
      fieldType: FieldType.TIMESTAMP,
    },
    {
      name: HPProjectCustomField.TRAVEL_ZONE,
      value: travelZone,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.SERVICE_START_DATE_TIME,
      value: serviceStartDatetime,
      fieldType: FieldType.TIMESTAMP,
    },
    {
      name: HPProjectCustomField.SERVICE_END_DATE_TIME,
      value: serviceEndDatetime,
      fieldType: FieldType.TIMESTAMP,
    },
    {
      name: HPProjectCustomField.SYSTEM_FIXED_TIME,
      value: systemFixedDatetime,
      fieldType: FieldType.TIMESTAMP,
    },
    {
      name: HPProjectCustomField.CASE_PRIORITY,
      value: casePriority,
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.CASE_SEVERITY,
      value: caseSeverity,
      fieldType: FieldType.STRING,
    },
  ];

  let { values, fields } = getCustomFieldsAndValues(
    rawNamesAndValues,
    resourceId,
  );

  const partOrderCustomFields = getHpisPartOrder({
    projectId: resourceId,
    returnOverrideFlag,
    generatePartOrders,
    generatePartOrderLineItems,
  });

  const timeLogCustomFields = getHpisTimeLog({
    projectId: resourceId,
    generateTimeLogItems,
  });

  const engineerNoteCustomFields = getHpisEngineerNote({
    projectId: resourceId,
    generateEngineerNotes,
  });

  values = [
    ...values,
    ...partOrderCustomFields.values,
    ...timeLogCustomFields.values,
    ...engineerNoteCustomFields.values,
  ];
  fields = [
    ...fields,
    ...partOrderCustomFields.fields,
    ...timeLogCustomFields.fields,
    ...engineerNoteCustomFields.fields,
  ];

  return { values, fields };
}

export function getHpisEngineerNote({
  projectId,
  generateEngineerNotes,
}: {
  readonly projectId: number;
  readonly generateEngineerNotes: boolean;
}): {
  readonly values: readonly CustomFieldValue[];
  readonly fields: readonly GenerateCustomFieldInfoConfigurationOptions[];
} {
  const note = [
    {
      name: HPProjectCustomField.ENGINEER_WORK_ORDER_NOTE,
      value: [
        {
          name: HPProjectCustomField.ENGINEER_WORK_ORDER_NOTE_NUMBER,
          value: 1,
          fieldType: FieldType.INTEGER,
        },
        {
          name: HPProjectCustomField.ENGINEER_WORK_ORDER_NOTE_USERNAME,
          value: 'testUser',
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.ENGINEER_WORK_ORDER_NOTE_DESCRIPTION,
          value: 'Test Note.',
          fieldType: FieldType.STRING,
        },
      ],
      fieldType: FieldType.OBJECT,
      isArray: true,
      isObject: true,
    },
  ];

  return createObjectCustomFields({
    resourceId: projectId,
    resourceType: ResourceTypeApi.PROJECT,
    customFields: generateEngineerNotes ? note : [],
  });
}

export function getHpisTimeLog({
  projectId,
  generateTimeLogItems,
}: {
  readonly projectId: number;
  readonly generateTimeLogItems: boolean;
}): {
  readonly values: readonly CustomFieldValue[];
  readonly fields: readonly GenerateCustomFieldInfoConfigurationOptions[];
} {
  const timeLog = [
    {
      name: HPProjectCustomField.TIME_LOG,
      value: [
        {
          name: HPProjectCustomField.TIME_LOG_LABOR_TYPE,
          value: HP_TIME_LOG_LABOR_TYPE_VALUE_MAPPING[HPTimeLog.LABOR_DURATION],
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.TIME_LOG_DURATION,
          value: 120,
          fieldType: FieldType.INTEGER,
        },
      ],
      fieldType: FieldType.OBJECT,
      isArray: true,
      isObject: true,
    },
  ];

  return createObjectCustomFields({
    resourceId: projectId,
    resourceType: ResourceTypeApi.PROJECT,
    customFields: generateTimeLogItems ? timeLog : [],
  });
}

export function getHpisPartOrder({
  projectId,
  returnOverrideFlag,
  generatePartOrders,
  generatePartOrderLineItems,
}: {
  readonly projectId: number;
  readonly returnOverrideFlag?: boolean;
  readonly generatePartOrders: boolean;
  readonly generatePartOrderLineItems: boolean;
}): {
  readonly values: readonly CustomFieldValue[];
  readonly fields: readonly GenerateCustomFieldInfoConfigurationOptions[];
} {
  const partOrderLineItems: NameAndValue[] = [
    {
      name: HPProjectCustomField.PART_ORDER_NUMBER,
      value: 'part order number',
      fieldType: FieldType.STRING,
    },
    {
      name: HPProjectCustomField.PART_ORDER_LINE_ITEM,
      value: [
        {
          name: HPProjectCustomField.USAGE_CODE,
          value: HPUsageCode.SAUN_UNUSED,
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.REMOVED_PART_NUMBER,
          value: '123',
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.REMOVED_PART_SERIAL_NUMBER,
          value: '123',
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.REMOVED_PART_DESCRIPTION,
          value: '123',
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.ADDITIONAL_FAILURE_CODE,
          value: HPAdditionalFailureCode.FAILED_WHEN_INSERTED,
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.RETURN_OVERRIDE_FLAG,
          value: returnOverrideFlag,
          fieldType: FieldType.BOOLEAN,
        },
        {
          name: HPProjectCustomField.RETURN_OVERRIDE_REASON,
          value: HPReturnOverrideReason.SPECIAL_HP_APPROVAL,
          fieldType: FieldType.STRING,
        },
      ],
      fieldType: FieldType.OBJECT,
      isArray: true,
      isObject: true,
    },
    {
      name: HPProjectCustomField.PART_ORDER_LINE_ITEM,
      value: [
        {
          name: HPProjectCustomField.USAGE_CODE,
          value: HPUsageCode.SAUN_UNUSED,
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.REMOVED_PART_NUMBER,
          value: '123',
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.REMOVED_PART_SERIAL_NUMBER,
          value: '123',
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.REMOVED_PART_DESCRIPTION,
          value: '123',
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.ADDITIONAL_FAILURE_CODE,
          value: HPAdditionalFailureCode.FAILED_WHEN_INSERTED,
          fieldType: FieldType.STRING,
        },
        {
          name: HPProjectCustomField.RETURN_OVERRIDE_FLAG,
          value: returnOverrideFlag,
          fieldType: FieldType.BOOLEAN,
        },
        {
          name: HPProjectCustomField.RETURN_OVERRIDE_REASON,
          value: HPReturnOverrideReason.SPECIAL_HP_APPROVAL,
          fieldType: FieldType.STRING,
        },
      ],
      fieldType: FieldType.OBJECT,
      isArray: true,
      isObject: true,
    },
  ];

  const partOrders: NameAndValue[] = [
    {
      name: HPProjectCustomField.PART_ORDER,
      value: generatePartOrderLineItems ? partOrderLineItems : [],
      fieldType: FieldType.OBJECT,
      isArray: true,
      isObject: true,
    },
  ];

  return createObjectCustomFields({
    resourceId: projectId,
    resourceType: ResourceTypeApi.PROJECT,
    customFields: generatePartOrders ? partOrders : [],
  });
}

export function createObjectCustomFields({
  resourceId,
  resourceType,
  customFields,
  parentConfigInfoId = undefined,
  parentValueId = undefined,
}: {
  readonly resourceId: number;
  readonly resourceType: ResourceTypeApi;
  readonly customFields: readonly NameAndValue[];
  readonly parentConfigInfoId?: number;
  readonly parentValueId?: number;
}): {
  readonly values: readonly CustomFieldValue[];
  readonly fields: readonly GenerateCustomFieldInfoConfigurationOptions[];
} {
  // Loop through parent object custom fields and append to the values and fields
  let values: CustomFieldValue[] = [];
  let fields: GenerateCustomFieldInfoConfigurationOptions[] = [];
  customFields.forEach(customField => {
    // Create Custom Field Config for Object
    const generatedConfigId = generateId();
    const generatedCustomFieldInfoId = generateId();

    const objectCustomFieldConfiguration: GenerateCustomFieldInfoConfigurationOptions =
      {
        name: customField.name,
        fieldType: customField.fieldType,
        resourceType,
        isArray: customField.isArray,
        configurationId: generatedConfigId,
        parentId: parentConfigInfoId,
        customFieldInfoId: generatedCustomFieldInfoId,
      };

    // Create Custom Field Config and Value for Object and Childrens
    let objectCustomFieldValue: CustomFieldValue;
    let childCustomFields;
    if (customField.isObject) {
      // If this custom field is a parent object, then recursively create the child properties
      const parentObjectId = generateId();
      const objectChildCustomField = customField.value as NameAndValue[];

      if (customField.fieldType !== FieldType.OBJECT) {
        throw new Error('Custom field type should be Object');
      }

      objectCustomFieldValue = {
        type: customField.fieldType,
        value: parentObjectId,
        customFieldInfoConfigurationId: generatedConfigId,
        resourceId,
        isDefaultValue: false,
        parentId: parentValueId,
      };

      childCustomFields = createObjectCustomFields({
        resourceId,
        resourceType,
        customFields: objectChildCustomField,
        parentConfigInfoId: generatedCustomFieldInfoId,
        parentValueId: parentObjectId,
      });
    } else {
      // If non-object custom field (i.e. A Leaf property), no need for recursion
      objectCustomFieldValue = {
        type: customField.fieldType,
        value: customField.value,
        customFieldInfoConfigurationId: generatedConfigId,
        resourceId,
        isDefaultValue: false,
        parentId: parentValueId,
      } as CustomFieldValue;
    }

    values = [
      ...values,
      ...(childCustomFields?.values ?? []),
      objectCustomFieldValue,
    ];
    fields = [
      ...fields,
      ...(childCustomFields?.fields ?? []),
      objectCustomFieldConfiguration,
    ];
  });

  return { values, fields };
}

/**
 * The function returns Deloitte project custom field configurations and values.
 *
 * Custom fields are split into the values of a field and metadata about these fields.
 * To keep the two in sync we generate both the fields and their metadata from a list of name/value pairs,
 * generating the other information like the customFieldInfoConfigurationId and type from this.
 * This keeps the two in perfect sync (as well as reduces the overhead from creating a new field).
 */
export function getDeloitteProjectCustomFieldsAndValues({
  resourceId,
  hasWbsCode = true,
  wbsCode = 'ABC00123-AB-01-01-1234',
}: {
  readonly resourceId: number;
  readonly hasWbsCode?: boolean;
  readonly wbsCode?: string;
}): {
  readonly values: readonly CustomFieldValue[];
  readonly fields: readonly GenerateCustomFieldInfoConfigurationOptions[];
} {
  const rawNamesAndValues: readonly NameAndValue[] = [
    {
      name: 'wbs_code',
      value: hasWbsCode ? wbsCode : undefined,
      fieldType: FieldType.STRING,
    },
    {
      name: 'utilization',
      value: 'adjusted_utilization',
      fieldType: FieldType.STRING,
    },
    { name: 'business_line', value: 'tax', fieldType: FieldType.STRING },
    {
      name: 'project_type',
      value: 'client_billable',
      fieldType: FieldType.STRING,
    },
    {
      name: 'practice',
      value: 'gps_operational_excellence',
      fieldType: FieldType.STRING,
    },
    {
      name: 'practice_group',
      value: 'commercial',
      fieldType: FieldType.STRING,
    },
    { name: 'industry_group', value: 'consumer', fieldType: FieldType.STRING },
    {
      name: 'industry_sector',
      value: 'automotive_transportation_hospitality_and_services',
      fieldType: FieldType.STRING,
    },
    {
      name: 'offering_portfolio',
      value: 'core_business_operations',
      fieldType: FieldType.STRING,
    },
    {
      name: 'market_offering',
      value: 'cbo_cross_consulting_group',
      fieldType: FieldType.STRING,
    },
    { name: 'itar', value: true, fieldType: FieldType.BOOLEAN },
    {
      name: 'limit_gig_worker_level',
      value: ['senior_manager', 'manager'],
      fieldType: FieldType.STRING,
    },
    {
      name: 'limit_offering_portfolio',
      value: ['mergers_and_acquisitions', 'core_business_operations'],
      fieldType: FieldType.STRING,
    },
    {
      name: 'limit_practices',
      value: ['gps_operational_excellence', 'commercial_core'],
      fieldType: FieldType.STRING,
    },
    {
      name: 'limit_certifications',
      value: ['Certification-1', 'Certification-2'],
      fieldType: FieldType.STRING,
    },
    {
      name: 'limit_group_members',
      value: true,
      fieldType: FieldType.BOOLEAN,
    },
  ];

  return getCustomFieldsAndValues(rawNamesAndValues, resourceId);
}

export function getCustomFieldsAndValues(
  rawNamesAndValues: readonly NameAndValue[],
  resourceId: number,
  resourceType = ResourceTypeApi.PROJECT,
): {
  readonly values: readonly CustomFieldValue[];
  readonly fields: readonly GenerateCustomFieldInfoConfigurationOptions[];
} {
  const fieldsAndValues: readonly ({
    readonly name: string;
    readonly customFieldInfoConfigurationId: number;
  } & (
    | {
        readonly type: FieldType.STRING;
        readonly isArray: true;
        readonly value: readonly string[];
      }
    | {
        readonly type: FieldType.STRING;
        readonly isArray: false;
        readonly value: string;
      }
    | {
        readonly type: FieldType.BOOLEAN;
        readonly isArray: false;
        readonly value: boolean;
      }
    | {
        readonly type: FieldType.BOOLEAN;
        readonly isArray: true;
        readonly value: readonly boolean[];
      }
    | {
        readonly type: FieldType.TIMESTAMP;
        readonly isArray: false;
        readonly value: number;
      }
    | {
        readonly type: FieldType.TIMESTAMP;
        readonly isArray: true;
        readonly value: readonly number[];
      }
  ))[] = rawNamesAndValues
    .map(({ name, value, fieldType }) => ({
      name,
      value,
      fieldType,
      customFieldInfoConfigurationId: generateId(),
    }))
    .map(({ name, value, fieldType, customFieldInfoConfigurationId }) => {
      if (isAnArray(value)) {
        switch (fieldType) {
          case FieldType.STRING:
            return {
              name,
              value: value as string[],
              customFieldInfoConfigurationId,
              type: fieldType,
              isArray: true,
            } as const;
          case FieldType.BOOLEAN:
            return {
              name,
              value: value as boolean[],
              customFieldInfoConfigurationId,
              type: fieldType,
              isArray: true,
            } as const;
          case FieldType.TIMESTAMP:
            return {
              name,
              value: value as number[],
              customFieldInfoConfigurationId,
              type: fieldType,
              isArray: true,
            } as const;

          // Implement as needed
          case FieldType.OBJECT:
          case FieldType.FLOAT:
          case FieldType.INTEGER:
          case FieldType.LOCATION:
          case FieldType.UNDEFINED:
          default: {
            return undefined;
          }
        }
      }

      switch (fieldType) {
        case FieldType.STRING:
          return {
            name,
            value: value as string,
            customFieldInfoConfigurationId,
            type: fieldType,
            isArray: false,
          } as const;
        case FieldType.BOOLEAN:
          return {
            name,
            value: value as boolean,
            customFieldInfoConfigurationId,
            type: fieldType,
            isArray: false,
          } as const;
        case FieldType.TIMESTAMP:
          return {
            name,
            value: value as number,
            customFieldInfoConfigurationId,
            type: fieldType,
            isArray: false,
          } as const;

        // Implement as needed
        case FieldType.OBJECT:
        case FieldType.FLOAT:
        case FieldType.INTEGER:
        case FieldType.LOCATION:
        case FieldType.UNDEFINED:
        default: {
          return undefined;
        }
      }
    })
    .filter(isDefined);

  const values: readonly CustomFieldValue[] = fieldsAndValues
    .flatMap(fieldAndValue => {
      // Filter out when the custom field has no value
      if (fieldAndValue.value === undefined) {
        return [];
      }

      if (fieldAndValue.isArray) {
        // We need 'as any[]' casting here. Please see https://github.com/microsoft/TypeScript/pull/31023 .
        return (fieldAndValue.value as any[]).map(value => ({
          customFieldInfoConfigurationId:
            fieldAndValue.customFieldInfoConfigurationId,
          value,
          type: fieldAndValue.type,
        }));
      }
      return [
        {
          customFieldInfoConfigurationId:
            fieldAndValue.customFieldInfoConfigurationId,
          value: fieldAndValue.value,
          type: fieldAndValue.type,
        },
      ];
    })
    .filter(isDefined)
    .map(value => ({
      ...value,
      isDefaultValue: false,
      resourceId,
    }));

  const fields: readonly GenerateCustomFieldInfoConfigurationOptions[] =
    fieldsAndValues.map(
      ({ name, type, isArray: isA, customFieldInfoConfigurationId }) => ({
        name,
        fieldType: type,
        resourceType,
        isArray: isA,
        configurationId: customFieldInfoConfigurationId,
      }),
    );

  return { values, fields };
}
