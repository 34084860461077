import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  DeloitteBusinessLineApi,
  DeloitteIndustryApi,
  DeloitteOfferingPortfolioApi,
} from 'api-typings/projects/deloitte';
import { RelationshipTypeApi } from 'api-typings/resources/custom_field_relationship';
import type { FieldValue } from '../custom-field-info-configurations/custom-field-info-configurations.model';
import { FieldType } from '../custom-field-info-configurations/custom-field-info-configurations.model';
import { DeloitteCustomFieldRelationshipName } from '../deloitte/deloitte.model';
import type { CustomFieldRelationship } from './custom-field-relationships.model';

export interface GenerateCustomFieldRelationshipOptions {
  readonly id?: number;
  readonly name: string;
  readonly relationshipType?: RelationshipTypeApi;
  readonly leftValue: FieldValue;
  readonly rightValues: readonly FieldValue[];
  readonly singleValue?: boolean;
}

export function generateCustomFieldRelationshipObject({
  id,
  name,
  relationshipType = RelationshipTypeApi.VALUE_TO_VALUE_LOOKUP,
  leftValue,
  rightValues,
  singleValue,
}: GenerateCustomFieldRelationshipOptions): CustomFieldRelationship {
  return {
    id: id || generateId(),
    name,
    relationshipType,
    leftValueType: leftValue.type,
    leftValue,
    rightValueType: rightValues[0].type,
    rightValues,
    singleValue,
  };
}

export function wbsCodeToBusinessRelationship(): GenerateCustomFieldRelationshipOptions {
  return {
    name: DeloitteCustomFieldRelationshipName.WBS_CODE_TO_BUSINESS_LINE,
    leftValue: {
      value: 'ABA30036-01-01-01-7000',
      type: FieldType.STRING,
    },
    rightValues: [
      {
        value: DeloitteBusinessLineApi.CONSULTING,
        type: FieldType.STRING,
      },
    ],
  };
}

export function wbsCodeToIndustryRelationship(): GenerateCustomFieldRelationshipOptions {
  return {
    name: DeloitteCustomFieldRelationshipName.WBS_CODE_TO_INDUSTRY_SECTOR,
    leftValue: {
      value: 'ABA30036-01-01-01-7000',
      type: FieldType.STRING,
    },
    rightValues: [
      {
        value: DeloitteIndustryApi.TECHNOLOGY,
        type: FieldType.STRING,
      },
    ],
  };
}

export function wbsCodeToOfferingPortfolioRelationship(): GenerateCustomFieldRelationshipOptions {
  return {
    name: DeloitteCustomFieldRelationshipName.WBS_CODE_TO_OFFERING_PORTFOLIO,
    leftValue: {
      value: 'ABA30036-01-01-01-7000',
      type: FieldType.STRING,
    },
    rightValues: [
      {
        value: DeloitteOfferingPortfolioApi.CORE_BUSINESS_OPERATIONS,
        type: FieldType.STRING,
      },
    ],
  };
}

export function wbsCodeToFSSValueRelationship(): GenerateCustomFieldRelationshipOptions {
  return {
    name: DeloitteCustomFieldRelationshipName.WBS_CODE_TO_FSS,
    leftValue: {
      value: 'ABA30036-01-01-01-7000',
      type: FieldType.STRING,
    },
    rightValues: [
      {
        value: 'client_and_market_growth',
        type: FieldType.STRING,
      },
    ],
  };
}
