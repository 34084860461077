import { generateId } from '@freelancer/datastore/testing/helpers';
import { ShowcaseSourceTypeApi } from 'api-typings/users/users';
import type { ShowcaseSource } from './showcase-source.model';

export function generateShowcaseSource(
  config: Config | undefined,
): readonly ShowcaseSource[] {
  return [getWebsiteBuildShowCase(config)];
}

export interface Config {
  readonly freelancerId?: number;
  readonly employerId?: number;
  readonly projectTitle?: string;
}

/* For converting seoUrls to snake-case */
export function getWebsiteBuildShowCase(
  config: Config | undefined,
): ShowcaseSource {
  return {
    description: 'I built a website for employer',
    employerId: config?.employerId ?? generateId(),
    freelancerId: config?.freelancerId ?? generateId(),
    isUsed: false,
    parentSourceId: generateId(),
    publishable: true,
    timestamp: Date.now(),
    title: config?.projectTitle ?? 'Test website build',
    type: ShowcaseSourceTypeApi.PROJECT,
    id: generateId().toString(),
  };
}
