import {
  generateId,
  generateNumbersInRangeWithDuplicates,
  getNovelLine,
  getRandomText,
  Paragraph,
} from '@freelancer/datastore/testing/helpers';
import type { EntryUpgradesApi } from 'api-typings/contests/contests';
import { EntryStatusApi } from 'api-typings/contests/contests';
import { generateFiles } from '../contest-view-entries';
import type { ContestEntry, ContestEntryFile } from './contest-entries.model';
import { mapStatusTextToNumber } from './contest-entries.transformers';

export interface GenerateContestEntriesOptions {
  readonly freelancerIds: readonly number[];
  readonly contestId: number;
  readonly contestOwnerId: number;
  readonly contestPrize?: number;

  // These are user IDs
  // They map one-to-one to entry.status, so only one will be applied.
  readonly activeIds?: readonly number[];
  readonly pendingIds?: readonly number[];
  readonly withdrawnIds?: readonly number[];
  readonly eliminatedIds?: readonly number[];
  readonly chosenIds?: readonly number[];
  readonly wonIds?: readonly number[];
  readonly boughtIds?: readonly number[];
  // These map one to one to upgrades, so only one will be applied.
  readonly sealedIds?: readonly number[];

  readonly minSellPrice?: number;
  readonly maxSellPrice?: number;
  readonly minLikeCount?: number;
  readonly maxLikeCount?: number;
  readonly minRating?: number;
  readonly maxRating?: number;
}

export interface GenerateContestEntryOptions {
  readonly freelancerId: number;
  readonly contestId: number;
  readonly contestOwnerId: number;
  readonly entryNumber?: number;
  readonly deleted?: boolean;
  readonly description?: string;
  readonly files?: readonly ContestEntryFile[];
  readonly likeCount?: number;
  readonly rating?: number;
  readonly sellPrice?: number;
  readonly status: EntryStatusApi;
  readonly title?: string;
  readonly timeEntered: number;
  readonly timeEliminated?: number;
  readonly timeWon?: number;
  readonly upgrades?: EntryUpgradesApi;
}

// TODO: T267853 - files
export function generateContestEntryObjects({
  freelancerIds,
  contestId,
  contestOwnerId,
  contestPrize = 100,
  activeIds = freelancerIds, // by default, entries are active
  pendingIds = [],
  withdrawnIds = [],
  eliminatedIds = [],
  chosenIds = [],
  wonIds = [],
  boughtIds = [],
  sealedIds = [],
  minSellPrice = 1,
  maxSellPrice = contestPrize,
  minLikeCount = 0,
  maxLikeCount = 20,
  minRating, // by default, entries are unrated
  maxRating,
}: GenerateContestEntriesOptions): readonly ContestEntry[] {
  const sellPrices = generateNumbersInRangeWithDuplicates(
    minSellPrice,
    maxSellPrice,
    freelancerIds.length,
    'sellPrices',
  );

  const likeCounts = generateNumbersInRangeWithDuplicates(
    minLikeCount,
    maxLikeCount,
    freelancerIds.length,
    'likeCounts',
  );

  let ratings: readonly number[] = [];
  if (minRating || maxRating) {
    ratings = generateNumbersInRangeWithDuplicates(
      minRating ?? 1,
      maxRating ?? 5,
      freelancerIds.length,
      'entryRatings',
    );
  }

  const now = Date.now();

  return freelancerIds.map((freelancerId, index) =>
    generateContestEntryObject({
      freelancerId,
      contestId,
      contestOwnerId,
      entryNumber: index + 1,
      rating: ratings.length ? ratings[index] : undefined,
      sellPrice: sellPrices[index],
      likeCount: likeCounts[index],

      timeEntered: now,
      timeEliminated: undefined, // TODO: T267853 -
      timeWon: undefined, // TODO: T267853 -

      ...(activeIds.includes(freelancerId)
        ? { status: EntryStatusApi.ACTIVE }
        : pendingIds.includes(freelancerId)
        ? { status: EntryStatusApi.PENDING }
        : withdrawnIds.includes(freelancerId)
        ? { status: EntryStatusApi.WITHDRAWN }
        : eliminatedIds.includes(freelancerId)
        ? { status: EntryStatusApi.ELIMINATED }
        : chosenIds.includes(freelancerId)
        ? { status: EntryStatusApi.CHOSEN }
        : wonIds.includes(freelancerId)
        ? { status: EntryStatusApi.WON }
        : boughtIds.includes(freelancerId)
        ? { status: EntryStatusApi.BOUGHT }
        : { status: EntryStatusApi.ACTIVE }),

      ...(sealedIds.includes(freelancerId)
        ? { upgrades: { sealed: true } }
        : {}),
    }),
  );
}

export function generateContestEntryObject({
  freelancerId,
  contestId,
  contestOwnerId,
  description,
  entryNumber = 1,
  files = generateFiles(),
  likeCount = 0,
  rating = 0,
  sellPrice = 10,
  status,
  timeEntered,
  timeEliminated,
  timeWon,
  title,
  upgrades = {},
}: GenerateContestEntryOptions): ContestEntry {
  return {
    id: generateId(),
    ownerId: freelancerId,
    contestId,
    contestOwnerId,
    number: entryNumber,
    title: title || getRandomText(0, 50),
    description:
      description || getNovelLine(Paragraph.prideAndPrejudice, entryNumber),
    rating,
    sellPrice,
    likeCount,
    status,
    timeEntered,
    timeEliminated,
    timeWon,
    upgrades: { sealed: false, highlight: false, ...upgrades },
    statusNumber: mapStatusTextToNumber(status),
    files,
  };
}

// --- Mixins ---

export function activeEntry(): Pick<
  GenerateContestEntryOptions,
  'status' | 'timeEntered'
> {
  return {
    status: EntryStatusApi.ACTIVE,
    timeEntered: Date.now(),
  };
}

export function awardedEntry(
  timeWon?: number,
): Pick<GenerateContestEntryOptions, 'status' | 'timeEntered' | 'timeWon'> {
  const now = Date.now();

  return {
    status: EntryStatusApi.WON,
    timeEntered: now - 60 * 60 * 1000 * 24, // 1 day before
    timeWon: timeWon ?? now,
  };
}

export function boughtEntry(): Pick<
  GenerateContestEntryOptions,
  'status' | 'timeEntered'
> {
  return {
    status: EntryStatusApi.BOUGHT,
    timeEntered: Date.now(),
  };
}

export function deletedEntry(): Pick<
  GenerateContestEntryOptions,
  'deleted' | 'status' | 'timeEntered'
> {
  return {
    deleted: true,
    status: EntryStatusApi.ACTIVE,
    timeEntered: Date.now(),
  };
}

export function draftEntry(): Pick<
  GenerateContestEntryOptions,
  'status' | 'timeEntered'
> {
  return {
    status: EntryStatusApi.DRAFT,
    timeEntered: Date.now(),
  };
}

export function rejectedEntry(): Pick<
  GenerateContestEntryOptions,
  'status' | 'timeEntered' | 'timeEliminated'
> {
  const now = Date.now();

  return {
    status: EntryStatusApi.ELIMINATED,
    timeEntered: now,
    timeEliminated: now + 1_800_000, // Plus 30 minutes
  };
}

export function withdrawnEntry(): Pick<
  GenerateContestEntryOptions,
  'status' | 'timeEntered'
> {
  return {
    status: EntryStatusApi.WITHDRAWN,
    timeEntered: Date.now(),
  };
}

export function withdrawnEliminatedEntry(): Pick<
  GenerateContestEntryOptions,
  'status' | 'timeEntered' | 'timeEliminated'
> {
  const now = Date.now();

  return {
    status: EntryStatusApi.WITHDRAWN_ELIMINATED,
    timeEntered: now,
    timeEliminated: now + 1_800_000, // Plus 30 minutes
  };
}

export function pendingEntry(): Pick<
  GenerateContestEntryOptions,
  'status' | 'timeEntered'
> {
  return {
    status: EntryStatusApi.PENDING,
    timeEntered: Date.now(),
  };
}

export function sealedEntry(): Pick<
  GenerateContestEntryOptions,
  'timeEntered' | 'upgrades'
> {
  return {
    timeEntered: Date.now(),
    upgrades: { sealed: true },
  };
}

export function highlightEntry(): Pick<
  GenerateContestEntryOptions,
  'upgrades'
> {
  return {
    upgrades: { highlight: true },
  };
}
