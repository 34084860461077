import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ServiceOfferingShopCategory } from './service-offering-shop-categories.model';

export interface GenerateServiceOfferingCategoriesOptions {
  readonly id?: number;
  readonly shopId?: number;
  readonly name?: string;
  readonly description?: string;
  readonly seoUrl?: string;
}

export function generateServiceOfferingCategoryObject({
  id = generateId(),
  shopId = generateId(),
  name,
  description,
  seoUrl,
}: GenerateServiceOfferingCategoriesOptions): ServiceOfferingShopCategory {
  return {
    id,
    shopId,
    name: name || `Test Category ${id}`,
    description: description || `Test Category Description ${id}`,
    seoUrl: seoUrl || `test-category-${id}`,
  };
}
