import { PaymentShareEntityTypeApi } from 'api-typings/users/users';
import type { PaymentShareMemberSpentAmount } from './payment-share-members-spent-amount.model';

export interface GeneratePaymentShareMemberSpentAmountOptions {
  readonly id: number;
  readonly entityId: number;
  readonly entityType?: PaymentShareEntityTypeApi;
  readonly currentSpentAmount?: number;
}

/** Returns a payment share member spent amount object. */
export function generatePaymentShareMemberSpentAmountObject({
  id,
  entityId,
  entityType = PaymentShareEntityTypeApi.USER,
  currentSpentAmount = 0,
}: GeneratePaymentShareMemberSpentAmountOptions): PaymentShareMemberSpentAmount {
  return {
    id,
    entityId,
    entityType,
    currentSpentAmount,
  };
}
