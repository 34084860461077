import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  PayoutMethodTypeEnumApi,
  PayoutAccountStatusEnumApi,
} from 'api-typings/payments/payments';
import type { PayoutAccount, WirePayoutAccount } from './payout-accounts.model';

export interface GeneratePayoutAccountOptions {
  readonly userId: number;
  readonly method: PayoutMethodTypeEnumApi;
  readonly currencyId?: number;
  readonly status?: PayoutAccountStatusEnumApi;
  readonly alias?: string;
  readonly fieldData?: WirePayoutAccount['fieldData'];
}

export function generatePayoutAccountObjects(
  options: GeneratePayoutAccountOptions,
): readonly PayoutAccount[] {
  return [generatePayoutAccount(options)];
}

export function generatePayoutAccount({
  userId,
  method,
  currencyId = 1,
  status = PayoutAccountStatusEnumApi.APPROVED,
  alias,
  fieldData,
}: GeneratePayoutAccountOptions): PayoutAccount {
  const basePayoutAccount = {
    id: generateId(),
    userId,
    currencyId,
    method,
    status,
    countryCode: 'AU',
    alias,
  };
  switch (method) {
    case PayoutMethodTypeEnumApi.WIRE:
      return {
        ...basePayoutAccount,
        method: PayoutMethodTypeEnumApi.WIRE,
        fieldData,
      };
    default:
      return basePayoutAccount;
  }
}

export function wirePayoutAccountFieldData(): Partial<GeneratePayoutAccountOptions> {
  return {
    method: PayoutMethodTypeEnumApi.WIRE,
    fieldData: {
      beneficiaryAccount: 'TEST ACCOUNT',
      beneficiaryAddress1: 'Test Address 1',
      beneficiaryAddress2: 'Test Address 2',
      beneficiaryBankName: 'Wire Account Test Bank',
      beneficiaryName: 'Test User',
      beneficiarySwiftCode: 'CTBAAU2S600',
      wireAdditionalInfo: 'Testing Purposes Only',
    },
  };
}
