import { generateId } from '@freelancer/datastore/testing/helpers';
import type { VerificationIdType } from './verification-id-types.model';

export interface GenerateVerificationIdTypesOptions {
  readonly countryCodes?: readonly string[];
  readonly hasExpiryDate?: boolean;
}

export function generateVerificationIdTypesObjects({
  countryCodes = ['SG'],
  hasExpiryDate = true,
}: GenerateVerificationIdTypesOptions = {}): readonly VerificationIdType[] {
  // Return the default ID types for the given countries. See KycRequirement::getDefaultIdNames()
  const idTypes: VerificationIdType[] = [];
  countryCodes.forEach(countryCode => {
    idTypes.push(...generateVerificationIdTypes(countryCode, hasExpiryDate));
  });

  return idTypes;
}

function generateVerificationIdTypes(
  countryCode: string,
  hasExpiryDate: boolean,
): readonly VerificationIdType[] {
  return [
    {
      id: generateId().toString(),
      backendId: 1,
      name: 'Passport',
      hasExpiryDate,
      countryCode,
    },
    {
      id: generateId().toString(),
      backendId: 2,
      name: 'Drivers License',
      hasExpiryDate,
      countryCode,
    },
    {
      id: generateId().toString(),
      backendId: 3,
      name: 'National ID Card',
      hasExpiryDate,
      countryCode,
    },
  ];
}
