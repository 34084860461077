import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { FontColor, FontType, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-illustrations',
  template: `
    <fl-bit class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Illustrations
        </fl-heading>

        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.PARAGRAPH"
        >
          We currently have the following illustrations in
          webapp/src/illustrations folder.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.PARAGRAPH"
        >
          If new illustrations will be added, they should be 128px width by
          128px height.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.PARAGRAPH"
          [flMarginBottom]="'small'"
        >
          To use this illustrations, they should be added with fl-picture
          component.
        </fl-text>
        <fl-text
          [color]="FontColor.ERROR"
          [flMarginBottom]="'small'"
          [weight]="FontWeight.BOLD"
        >
          Click Illustration to copy name to clipboard
        </fl-text>
        <app-static-illustrations-list
          titleText="Alerts"
          [sources]="alertPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Nav"
          [sources]="navPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Location"
          [sources]="locationPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Tech"
          [sources]="techPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Email"
          [sources]="emailPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Mobile"
          [sources]="mobilePictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Comms"
          [sources]="commsPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Membership"
          [sources]="membershipPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Payment"
          [sources]="paymentPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Verify"
          [sources]="verifyPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Project"
          [sources]="projectPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Job"
          [sources]="jobPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Contest"
          [sources]="contestPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Milestone"
          [sources]="milestonesPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Time"
          [sources]="timePictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Freelancer"
          [sources]="freelancerPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Employer"
          [sources]="employerPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Document"
          [sources]="documentsPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Bids"
          [sources]="bidsPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Portfolio"
          [sources]="portfolioPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Skills"
          [sources]="skillsPictureSrcs"
        ></app-static-illustrations-list>
        <app-static-illustrations-list
          titleText="Quality Rewards"
          [sources]="qualityRewardsPictureSrcs"
        ></app-static-illustrations-list>
      </fl-card>
    </fl-bit>
  `,
  styleUrls: ['../static-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IllustrationsComponent {
  FontColor = FontColor;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  TextSize = TextSize;

  alertPictureSrcs: readonly string[] = [
    'illustrations/alerts/empty-state.svg',
    'illustrations/alerts/nothing-found.svg',
    'illustrations/alerts/sad-face.svg',
    'illustrations/alerts/search.svg',
    'illustrations/alerts/warning-message-desktop.svg',
    'illustrations/alerts/warning-message.svg',
  ];

  navPictureSrcs: readonly string[] = [
    'illustrations/nav/how-it-works.svg',
    'illustrations/nav/introduction.svg',
    'illustrations/nav/publication.svg',
    'illustrations/nav/what-is-the-process.svg',
  ];

  locationPictureSrcs: readonly string[] = [
    'illustrations/location/global-fleet.svg',
    'illustrations/location/international-or-local.svg',
    'illustrations/location/language.svg',
    'illustrations/location/local-job.svg',
    'illustrations/location/location.svg',
    'illustrations/location/translation.svg',
  ];

  techPictureSrcs: readonly string[] = [
    'illustrations/tech/automate-workflow.svg',
    'illustrations/tech/components.svg',
    'illustrations/tech/development.svg',
    'illustrations/tech/gathering-data.svg',
    'illustrations/tech/internet-marketing.svg',
    'illustrations/tech/internet.svg',
    'illustrations/tech/on-demand-and-scalable.svg',
    'illustrations/tech/open-source.svg',
    'illustrations/tech/sophisticated-machine.svg',
    'illustrations/tech/web-and-mobile.svg',
    'illustrations/tech/web-development.svg',
  ];

  emailPictureSrcs: readonly string[] = [
    'illustrations/email/check-email.svg',
    'illustrations/email/email-verify.svg',
    'illustrations/email/mail.svg',
    'illustrations/email/no-email.svg',
    'illustrations/email/send.svg',
    'illustrations/email/warning-message.svg',
  ];

  mobilePictureSrcs: readonly string[] = [
    'illustrations/mobile/mobile-code.svg',
    'illustrations/mobile/mobile-verify.svg',
    'illustrations/mobile/pay-with-confidence.svg',
  ];

  commsPictureSrcs: readonly string[] = [
    'illustrations/comms/here-to-help.svg',
    'illustrations/comms/live-chat.svg',
    'illustrations/comms/message.svg',
    'illustrations/comms/notification.svg',
    'illustrations/comms/review.svg',
    'illustrations/comms/support.svg',
  ];

  membershipPictureSrcs: readonly string[] = [
    'illustrations/membership/expert-recruiter.svg',
    'illustrations/membership/free-trial-membership.svg',
    'illustrations/membership/membership.svg',
    'illustrations/membership/preferred-freelancer.svg',
    'illustrations/membership/premium-membership.svg',
    'illustrations/membership/reactivate-account.svg',
    'illustrations/membership/select-account-type.svg',
  ];

  paymentPictureSrcs: readonly string[] = [
    'illustrations/payment/credit-card.svg',
    'illustrations/payment/cut-cost.svg',
    'illustrations/payment/express-withdrawal.svg',
    'illustrations/payment/external-invoicing.svg',
    'illustrations/payment/get-paid.svg',
    'illustrations/payment/get-paid-faster.svg',
    'illustrations/payment/link-accounts.svg',
    'illustrations/payment/make-money.svg',
    'illustrations/payment/pay-safely.svg',
    'illustrations/payment/payment-successful-alt.svg',
    'illustrations/payment/payment-successful.svg',
    'illustrations/payment/security-alt.svg',
    'illustrations/payment/security.svg',
    'illustrations/payment/verify-payment-method.svg',
    'illustrations/payment/wire-transfer-alt.svg',
    'illustrations/payment/wire-transfer.svg',
  ];

  verifyPictureSrcs: readonly string[] = [
    'illustrations/verify/custom-cover-photo.svg',
    'illustrations/verify/higher-trust-score.svg',
    'illustrations/verify/identity-verified.svg',
    'illustrations/verify/keycode-verification.svg',
    'illustrations/verify/proof-of-address.svg',
    'illustrations/verify/proof-of-identity.svg',
    'illustrations/verify/tick-confirmation.svg',
    'illustrations/verify/upload-image.svg',
    'illustrations/verify/verify-your-identity.svg',
  ];

  projectPictureSrcs: readonly string[] = [
    'illustrations/project/aware-of-project.svg',
    'illustrations/project/describe-your-project-alt.svg',
    'illustrations/project/describe-your-project.svg',
    'illustrations/project/empty-project.svg',
    'illustrations/project/fixed-or-hourly-project.svg',
    'illustrations/project/fixed-project.svg',
    'illustrations/project/hourly-project.svg',
    'illustrations/project/post-a-project.svg',
    'illustrations/project/recruiter-project.svg',
    'illustrations/project/standard-project.svg',
  ];

  jobPictureSrcs: readonly string[] = [
    'illustrations/job/job-profeciency.svg',
    'illustrations/job/jobs.svg',
    'illustrations/job/post-a-job.svg',
    'illustrations/job/projects-contest.svg',
  ];

  contestPictureSrcs: readonly string[] = [
    'illustrations/contest/contest.svg',
    'illustrations/contest/get-awarded.svg',
    'illustrations/contest/post-a-contest.svg',
  ];

  milestonesPictureSrcs: readonly string[] = [
    'illustrations/milestones/bids-per-milestone.svg',
    'illustrations/milestones/create-milestone.svg',
    'illustrations/milestones/milestone-payment.svg',
    'illustrations/milestones/release-milestone-alt.svg',
    'illustrations/milestones/release-milestone.svg',
    'illustrations/milestones/secure-milestone.svg',
  ];

  timePictureSrcs: readonly string[] = [
    'illustrations/time/how-long-does-it-take.svg',
    'illustrations/time/pay-by-the-hour.svg',
    'illustrations/time/pending-approval.svg',
    'illustrations/time/time-tracking.svg',
    'illustrations/time/track-progress.svg',
  ];

  freelancerPictureSrcs: readonly string[] = [
    'illustrations/freelancer/about-me.svg',
    'illustrations/freelancer/browse-freelancers.svg',
    'illustrations/freelancer/choose-a-freelancer.svg',
    'illustrations/freelancer/choose-freelancer.svg',
    'illustrations/freelancer/freelancer-requirements.svg',
    'illustrations/freelancer/freelancers.svg',
    'illustrations/freelancer/hire-at-a-fraction-of-the-cost.svg',
    'illustrations/freelancer/overall-ranking.svg',
    'illustrations/freelancer/work.svg',
  ];

  employerPictureSrcs: readonly string[] = [
    'illustrations/employer/dont-do-it-yourself.svg',
    'illustrations/employer/employer-following.svg',
    'illustrations/employer/enterprises.svg',
    'illustrations/employer/hire.svg',
    'illustrations/employer/nasa.svg',
  ];

  documentsPictureSrcs: readonly string[] = [
    'illustrations/documents/agreement.svg',
    'illustrations/documents/content.svg',
    'illustrations/documents/design-guidelines.svg',
    'illustrations/documents/exams.svg',
    'illustrations/documents/folder.svg',
    'illustrations/documents/freelancer-agreement.svg',
    'illustrations/documents/guidelines.svg',
    'illustrations/documents/ip-agreement.svg',
    'illustrations/documents/nda-agreement.svg',
    'illustrations/documents/sign.svg',
  ];

  bidsPictureSrcs: readonly string[] = [
    'illustrations/bids/bids-alt.svg',
    'illustrations/bids/bids.svg',
    'illustrations/bids/compare-proposals.svg',
    'illustrations/bids/high-value-bidding.svg',
    'illustrations/bids/place-bids.svg',
    'illustrations/bids/view-bids.svg',
  ];

  portfolioPictureSrcs: readonly string[] = [
    'illustrations/portfolio/browse-portfolios.svg',
    'illustrations/portfolio/portfolio.svg',
  ];

  skillsPictureSrcs: readonly string[] = [
    'illustrations/skills/accounting.svg',
    'illustrations/skills/backlog.svg',
    'illustrations/skills/copywriting.svg',
    'illustrations/skills/data-entry.svg',
    'illustrations/skills/design.svg',
    'illustrations/skills/education.svg',
    'illustrations/skills/experience.svg',
    'illustrations/skills/graphic-design.svg',
    'illustrations/skills/high-demand-skills.svg',
    'illustrations/skills/illustration.svg',
    'illustrations/skills/local-job.svg',
    'illustrations/skills/motion-and-design.svg',
    'illustrations/skills/seo-marketing.svg',
    'illustrations/skills/skills-ranking.svg',
    'illustrations/skills/skills.svg',
    'illustrations/skills/trending-skills.svg',
    'illustrations/skills/videography-alt.svg',
    'illustrations/skills/videography.svg',
  ];

  qualityRewardsPictureSrcs: readonly string[] = [
    'illustrations/quality-rewards/bookmark.svg',
    'illustrations/quality-rewards/exclusive-invitation.svg',
    'illustrations/quality-rewards/flexibility.svg',
    'illustrations/quality-rewards/get-awarded.svg',
    'illustrations/quality-rewards/get-noticed.svg',
    'illustrations/quality-rewards/mockup.svg',
    'illustrations/quality-rewards/quality-work.svg',
    'illustrations/quality-rewards/quality.svg',
    'illustrations/quality-rewards/rank-higher.svg',
    'illustrations/quality-rewards/rewards.svg',
  ];
}
