import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-brand-style-guide-typography',
  template: `
    <div class="Typography">
      <fl-text
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Typography
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Typography is an essential element of our identity and a powerful tool
        for achieving consistent expression in our communication.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Roboto is a typeface that is accessible by most systems. It was designed
        entirely with digital products and screens in mind. The perfect font for
        the Freelancer brand.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        The font boasts friendly and open curves. Unlike some grotesque fonts
        that distort letterforms to impose a rigid structure, Roboto maintains
        the natural width of letters. This results in a more fluid reading
        experience, akin to what you'd typically find in humanist and serif
        typefaces.
      </fl-text>
      <fl-picture
        class="Roboto"
        [flMarginBottom]="'xlarge'"
        [fullWidth]="true"
        [src]="'brand-guidelines/roboto.png'"
      ></fl-picture>
      <fl-text
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Type weights
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Roboto exists with a variety of different weights and types, but for
        Freelancer branded designs we limit this to five key weights:
      </fl-text>
      <fl-list
        [type]="ListItemType.UNORDERED"
        [flMarginBottom]="'small'"
      >
        <fl-list-item>Black</fl-list-item>
        <fl-list-item>Bold</fl-list-item>
        <fl-list-item>Medium</fl-list-item>
        <fl-list-item>Regular</fl-list-item>
        <fl-list-item>Light</fl-list-item>
      </fl-list>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        These variations are more than enough to create any required hierarchy
        for text for any Freelancer branded communication.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Avoid using too many different font weights. Use only the minimum
        necessary to establish text hierarchy or emphasise key points.
      </fl-text>

      <div
        class="FontContainer"
        [flMarginBottom]="'xxsmall'"
      >
        <div
          [flMarginRightTablet]="'xlarge'"
          [flMarginBottom]="'small'"
          [flMarginBottomTablet]="'none'"
        >
          <fl-picture [src]="'brand-guidelines/bold.png'"></fl-picture>
        </div>
        <fl-text
          [size]="TextSize.SMALL"
          [sizeDesktop]="TextSize.LARGE"
          [weight]="FontWeight.BOLD"
        >
          A B C D E F G H I J K L M N O P Q R S T U V W X Y Z <br />
          a b c d e f g h i j k l m o n p q r s t u v w x y z<br />
          0 1 2 3 4 5 6 7 8 9 0 @ # & ? ! ~ $ % . ,
        </fl-text>
      </div>
      <div
        class="FontContainer"
        [flMarginBottom]="'xxsmall'"
      >
        <div
          [flMarginRightTablet]="'xlarge'"
          [flMarginBottom]="'small'"
          [flMarginBottomTablet]="'none'"
        >
          <fl-picture [src]="'brand-guidelines/medium.png'"></fl-picture>
        </div>
        <fl-text
          [size]="TextSize.SMALL"
          [sizeDesktop]="TextSize.LARGE"
          [weight]="FontWeight.MEDIUM"
        >
          A B C D E F G H I J K L M N O P Q R S T U V W X Y Z <br />
          a b c d e f g h i j k l m o n p q r s t u v w x y z<br />
          0 1 2 3 4 5 6 7 8 9 0 @ # & ? ! ~ $ % . ,
        </fl-text>
      </div>
      <div
        class="FontContainer"
        [flMarginBottom]="'xxsmall'"
      >
        <div
          [flMarginRightTablet]="'xlarge'"
          [flMarginBottom]="'small'"
          [flMarginBottomTablet]="'none'"
        >
          <fl-picture [src]="'brand-guidelines/regular.png'"></fl-picture>
        </div>
        <fl-text
          [sizeDesktop]="TextSize.LARGE"
          [size]="TextSize.SMALL"
        >
          A B C D E F G H I J K L M N O P Q R S T U V W X Y Z <br />
          a b c d e f g h i j k l m o n p q r s t u v w x y z<br />
          0 1 2 3 4 5 6 7 8 9 0 @ # & ? ! ~ $ % . ,
        </fl-text>
      </div>
      <div
        class="FontContainer"
        [flMarginBottom]="'xxsmall'"
      >
        <div
          [flMarginRightTablet]="'xlarge'"
          [flMarginBottom]="'small'"
          [flMarginBottomTablet]="'none'"
        >
          <fl-picture [src]="'brand-guidelines/light.png'"></fl-picture>
        </div>
        <fl-text
          [size]="TextSize.SMALL"
          [sizeDesktop]="TextSize.LARGE"
          [weight]="FontWeight.LIGHT"
        >
          A B C D E F G H I J K L M N O P Q R S T U V W X Y Z <br />
          a b c d e f g h i j k l m o n p q r s t u v w x y z<br />
          0 1 2 3 4 5 6 7 8 9 0 @ # & ? ! ~ $ % . ,
        </fl-text>
      </div>
    </div>
  `,
  styleUrls: ['./../brand-style-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandStyleGuideTypographyComponent {
  FontWeight = FontWeight;
  ListItemType = ListItemType;
  TextSize = TextSize;
}
