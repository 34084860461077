import { generateId } from '@freelancer/datastore/testing/helpers';
import { haversineDistance } from '@freelancer/utils';
import { DeliveryTypeApi } from 'api-typings/projects/projects';
import {
  nswSydneyRegion,
  nswWestRegion,
  qldBrisbaneRegion,
  saAdelaideRegion,
  tasmaniaRegion,
  vicMelbourneRegion,
} from '../operating-areas';
import type { OperatingArea } from '../operating-areas';
import type {
  DeliveryItem,
  ProjectLocalDetails,
} from '../project-view-projects/project-view-projects.model';
import type { Location } from '../project-view-users';
import type { Project } from '../projects/projects.model';
import type { GenerateProjectOptions } from '../projects/projects.seed';
import { generateProjectObject } from '../projects/projects.seed';
import type { Skill } from '../skills/skills.model';
import { generalFullLoadSkill } from '../skills/skills.seed';
import type { SearchActiveLoad } from './search-active-loads.model';

export type GenerateSearchActiveLoadsOptions = GenerateProjectOptions & {
  readonly skills?: readonly Skill[];
  readonly startLocation?: LoadLocation;
  readonly endLocation?: LoadLocation;
  readonly deliveryItems?: readonly DeliveryItem[];
  readonly pickupDate?: number;
  readonly displayStartLocation?: string;
  readonly localDetails?: ProjectLocalDetails;
  readonly deliveryType?: DeliveryTypeApi;
  readonly startLocationAdministrativeAreas?: readonly string[];
  readonly endLocationAdministrativeAreas?: readonly string[];
  readonly startOperatingAreaIds?: readonly number[];
  readonly endOperatingAreaIds?: readonly number[];
  readonly pickupDateInMs?: number;
  readonly timeUpdated?: number;
  readonly timeSubmitted?: number;
};

export function generateSearchActiveLoadsObject(
  loads: readonly GenerateSearchActiveLoadsOptions[],
): readonly SearchActiveLoad[] {
  return loads.map(generateSearchActiveLoadObject);
}

export function generateSearchActiveLoadObject(
  options: GenerateSearchActiveLoadsOptions,
): SearchActiveLoad {
  return {
    ...generateProjectObject(options),
    ...generateSearchActiveLoadDetails(options),
  };
}

function generateSearchActiveLoadDetails({
  skills = [generalFullLoadSkill()],
  startLocation = locationRegencyParkSA(),
  endLocation = locationDubboNSW(),
  deliveryItems = [mediumItem()],
  deliveryType = DeliveryTypeApi.GENERAL,
  pickupDate,
  timeSubmitted = Date.now(),
  timeUpdated,
}: GenerateSearchActiveLoadsOptions): Omit<SearchActiveLoad, keyof Project> {
  return {
    skills,
    skillIds: skills.map(skill => skill.id),
    displayStartLocation: startLocation?.fullAddress,
    localDetails: {
      displayEndLocation: endLocation?.fullAddress,
      workStartDate: pickupDate,
      deliveryType,
      deliveryItems,
      distance:
        startLocation?.mapCoordinates &&
        endLocation?.mapCoordinates &&
        haversineDistance(
          startLocation?.mapCoordinates,
          endLocation?.mapCoordinates,
        ) / 1000,
    },
    startOperatingAreaIds: startLocation.administrativeAreas.map(
      (administrativeArea: OperatingArea) => administrativeArea.id,
    ),
    endOperatingAreaIds: endLocation.administrativeAreas.map(
      (administrativeArea: OperatingArea) => administrativeArea.id,
    ),
    pickupDateInMs: pickupDate,
    timeUpdated: timeUpdated ?? timeSubmitted,
  };
}

export function mediumItem({
  id = generateId(),
  referenceNumber = 'ACF1003',
  description = 'Some pallets',
  quantity = 5,
  width = 1000,
  height = 1000,
  length = 1000,
  weight = 100,
  attachments = [],
} = {}): DeliveryItem {
  return {
    id,
    referenceNumber,
    description,
    quantity,
    width,
    height,
    length,
    weight,
    attachments,
  };
}

export function largeItem({
  id = generateId(),
  referenceNumber = 'BPQ4964',
  description = 'Some big machinery',
  quantity = 1,
  width = 10_000,
  height = 10_000,
  length = 10_000,
  weight = 500,
  attachments = [],
} = {}): DeliveryItem {
  return {
    id,
    referenceNumber,
    description,
    quantity,
    width,
    height,
    length,
    weight,
    attachments,
  };
}

type LoadLocation = Location & {
  readonly administrativeAreas: readonly OperatingArea[];
};

export function locationPortArthurTAS(): LoadLocation {
  return {
    mapCoordinates: { latitude: -43.086_000, longitude: 147.505_999 },
    administrativeAreas: [tasmaniaRegion()],
    fullAddress: 'Port Arthur, TAS 7182',
  };
}

export function locationWynyardNSW(): LoadLocation {
  return {
    mapCoordinates: { latitude: -40.9897, longitude: 145.7262 },
    administrativeAreas: [nswSydneyRegion()],
    fullAddress: 'Port Arthur, TAS 7182',
  };
}

export function locationDubboNSW(): LoadLocation {
  return {
    mapCoordinates: { latitude: -31.840_233, longitude: 151.2099 },
    administrativeAreas: [nswWestRegion()],
    fullAddress: 'Dubbo, NSW 2830',
  };
}

export function locationBondiNSW(): LoadLocation {
  return {
    mapCoordinates: { latitude: -33.8915, longitude: 151.2767 },
    administrativeAreas: [nswSydneyRegion()],
    fullAddress: 'Bondi, NSW 2026',
  };
}

export function locationRegencyParkSA(): LoadLocation {
  return {
    mapCoordinates: { latitude: -34.921_23, longitude: 138.599_503 },
    administrativeAreas: [saAdelaideRegion()],
    fullAddress: 'Regency Park, SA 5010',
  };
}

export function locationBrisbaneQLD(): LoadLocation {
  return {
    mapCoordinates: { latitude: -27.470_125, longitude: 153.021_072 },
    administrativeAreas: [qldBrisbaneRegion()],
    fullAddress: 'Brisbane City, QLD 4000',
  };
}

export function locationMelbourneVIC(): LoadLocation {
  return {
    mapCoordinates: { latitude: -37.840_935, longitude: 144.946_457 },
    administrativeAreas: [vicMelbourneRegion()],
    fullAddress: 'Melbourne, VIC 3000',
  };
}
