import {
  generateId,
  getRandomText,
} from '@freelancer/datastore/testing/helpers';
import type {
  CONTEST_UPGRADES,
  PROJECT_UPGRADES,
} from '@freelancer/search-filters';
import * as common from 'api-typings/common/common';
import type {
  SearchFiltersContestSortingOptionApi,
  SearchFiltersProjectSortingOptionApi,
  SearchFiltersProjectTypeApi,
} from 'api-typings/users/users';
import type {
  SearchSavedFilters,
  SearchSavedFiltersContest,
  SearchSavedFiltersLoad,
  SearchSavedFiltersProject,
  SearchSavedFiltersProjectLocation,
  SearchSavedFiltersUser,
} from './search-saved-filters.model';

export type GenerateSearchSavedFiltersOptions =
  GenerateSearchSavedProjectFiltersOptions &
    GenerateSearchSavedUserFiltersOptions &
    GenerateSearchSavedContestFiltersOptions;

export interface GenerateSearchSavedProjectFiltersOptions {
  readonly userId: number;
  readonly id?: number;
  readonly name?: string;
  readonly type?: common.SearchTypeApi;
  readonly clientCountryCodes?: readonly string[];
  readonly countryCode?: string;
  readonly keyword?: string;
  readonly languageCodes?: readonly string[];
  readonly location?: SearchSavedFiltersProjectLocation;
  readonly maxFixedPrice?: number;
  readonly maxHourlyRate?: number;
  readonly minFixedPrice?: number;
  readonly minHourlyRate?: number;
  readonly poolIds?: readonly number[];
  readonly projectTypes?: readonly SearchFiltersProjectTypeApi[];
  readonly skillIds?: readonly number[];
  readonly projectSortingOption?: SearchFiltersProjectSortingOptionApi;
  readonly projectUpgrades?: readonly PROJECT_UPGRADES[];
}

export interface GenerateSearchSavedUserFiltersOptions {
  readonly userId: number;
  readonly id?: number;
  readonly name?: string;
  readonly type?: common.SearchTypeApi;
  readonly countryCodes?: readonly string[];
  readonly insigniaIds?: readonly number[];
  readonly isOnline?: boolean;
  readonly keyword?: string;
  readonly maxHourlyRate?: number;
  readonly maxReviewCount?: number;
  readonly minHourlyRate?: number;
  readonly minReviewCount?: number;
  readonly minStarRating?: number;
  readonly poolIds?: readonly number[];
  readonly skillIds?: readonly number[];
}

export interface GenerateSearchSavedContestFiltersOptions {
  readonly userId: number;
  readonly id?: number;
  readonly name?: string;
  readonly type?: common.SearchTypeApi;
  readonly keyword?: string;
  readonly maxPrize?: number;
  readonly minPrize?: number;
  readonly skillIds?: readonly number[];
  readonly contestSortingOption?: SearchFiltersContestSortingOptionApi;
  readonly contestUpgrades?: readonly CONTEST_UPGRADES[];
}

export function generateSearchSavedFiltersObject({
  userId,
  id = generateId(),
  name = getRandomText(1, 50),
  type = common.SearchTypeApi.PROJECT,
  clientCountryCodes,
  countryCode,
  keyword,
  languageCodes,
  location,
  maxFixedPrice,
  maxHourlyRate,
  minFixedPrice,
  minHourlyRate,
  poolIds,
  projectTypes,
  skillIds,
  projectSortingOption,
  contestSortingOption,
  projectUpgrades,
  contestUpgrades,
  countryCodes,
  insigniaIds,
  isOnline,
  maxReviewCount,
  minReviewCount,
  minStarRating,
  minPrize,
  maxPrize,
}: GenerateSearchSavedFiltersOptions): SearchSavedFilters {
  let projectFilters: SearchSavedFiltersProject = {};
  let contestFilters: SearchSavedFiltersContest = {};

  const filters:
    | SearchSavedFiltersProject
    | SearchSavedFiltersUser
    | SearchSavedFiltersLoad
    | SearchSavedFiltersContest = {
    clientCountryCodes,
    countryCode,
    keyword,
    languageCodes,
    location,
    maxFixedPrice,
    maxHourlyRate,
    minFixedPrice,
    minHourlyRate,
    poolIds,
    projectTypes,
    skillIds,
    countryCodes,
    insigniaIds,
    isOnline,
    maxReviewCount,
    minReviewCount,
    minStarRating,
    minPrize,
    maxPrize,
  };

  if (type === common.SearchTypeApi.PROJECT) {
    projectFilters = {
      sortingOption: projectSortingOption,
      upgrades: projectUpgrades,
    };
  } else if (type === common.SearchTypeApi.CONTEST) {
    contestFilters = {
      sortingOption: contestSortingOption,
      upgrades: contestUpgrades,
    };
  }

  return {
    id,
    filters: {
      ...filters,
      ...projectFilters,
      ...contestFilters,
    },
    name,
    type,
    userId,
  };
}
