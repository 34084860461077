import { generateId } from '@freelancer/datastore/testing/helpers';
import { SupportTypeApi } from 'api-typings/support/support';
import { AiPromptContextTypeApi } from 'api-typings/users/users';
import type { AiPrompt } from './ai-prompts.model';

export interface GenerateAiPromptOptions {
  readonly id?: number;
  readonly name?: string;
  readonly template?: string;
  readonly isEnabled?: boolean;
  readonly isDefault?: boolean;
  readonly createDate?: number;
  readonly deleteDate?: number;
  readonly contextType?: AiPromptContextTypeApi;
  readonly contextReference?: string;
  readonly currentVersionId?: number;
}

export function generateAiPromptObject({
  id = generateId(),
  name = 'Large Project Prompt',
  template = 'You are an AI agent on Freelancer.com interacting with a client that has just posted a project with a budget of $1000. What would you say to the client?',
  isEnabled = true,
  isDefault = false,
  createDate = Date.now(),
  deleteDate,
  contextType = AiPromptContextTypeApi.SUPPORT,
  contextReference = SupportTypeApi.RECRUITER,
  currentVersionId = generateId(),
}: GenerateAiPromptOptions = {}): AiPrompt {
  return {
    id,
    name,
    template,
    isEnabled,
    isDefault,
    createDate,
    deleteDate,
    contextType,
    contextReference,
    currentVersionId,
  };
}

export function withFreightlancerConciergeContext(): Pick<
  AiPrompt,
  'contextReference' | 'contextType'
> {
  return {
    contextType: AiPromptContextTypeApi.SUPPORT,
    contextReference: SupportTypeApi.FREIGHTLANCER_CONCIERGE,
  };
}
