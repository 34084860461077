import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BannerAlertLevel, BannerAlertType } from '@freelancer/ui/banner-alert';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontType, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-developer-contribution',
  template: `
    <fl-banner-alert
      [type]="BannerAlertType.WARNING"
      [closeable]="false"
      [flMarginBottom]="'mid'"
      [level]="BannerAlertLevel.PRIMARY"
    >
      See
      <fl-text
        [fontType]="FontType.SPAN"
        [weight]="FontWeight.BOLD"
      >
        Contribution Checklist
      </fl-text>
      below before submitting your code changes for review.
    </fl-banner-alert>
    <fl-card
      class="SectionCard"
      [flMarginBottom]="'mid'"
      [edgeToEdge]="true"
    >
      <fl-heading
        [headingType]="HeadingType.H2"
        [size]="TextSize.SMALL"
        [flMarginBottom]="'xsmall'"
      >
        Creating new components
      </fl-heading>
      <fl-text
        [size]="TextSize.XSMALL"
        [fontType]="FontType.CONTAINER"
      >
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
        >
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">
                cd gaf/webapp/src/@freelancer/ui
              </span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Create a new folder to house your files
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              Create the
              <span class="InlineSnippet">component.ts</span> file, as well as
              the <span class="InlineSnippet">component.scss</span> file if
              needed
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Create a story (see below)
          </fl-list-item>
        </fl-list>
      </fl-text>
    </fl-card>
    <fl-card
      class="SectionCard"
      [flMarginBottom]="'mid'"
      [edgeToEdge]="true"
    >
      <fl-heading
        [headingType]="HeadingType.H2"
        [size]="TextSize.SMALL"
        [flMarginBottom]="'xsmall'"
      >
        Creating new directives
      </fl-heading>
      <fl-banner-alert
        [closeable]="false"
        [flMarginBottom]="'small'"
      >
        There are still directives under
        <span class="InlineSnippet">@freelancer/ui</span>
        but we're slowly moving them to
        <span class="InlineSnippet">@freelancer/directives</span>
      </fl-banner-alert>
      <fl-text
        [size]="TextSize.XSMALL"
        [fontType]="FontType.CONTAINER"
      >
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
        >
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">
                cd gaf/webapp/src/@freelancer/directives
              </span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Create a new folder to house your files
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              Create the
              <span class="InlineSnippet">directive.ts</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Create a story (see below)
          </fl-list-item>
        </fl-list>
      </fl-text>
    </fl-card>
    <fl-card
      class="SectionCard"
      [flMarginBottom]="'mid'"
      [edgeToEdge]="true"
    >
      <fl-heading
        [headingType]="HeadingType.H2"
        [size]="TextSize.SMALL"
        [flMarginBottom]="'xsmall'"
      >
        Creating new pipes
      </fl-heading>
      <fl-text
        [size]="TextSize.XSMALL"
        [fontType]="FontType.CONTAINER"
      >
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
        >
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">
                cd gaf/webapp/src/@freelancer/pipes
              </span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Create a new folder to house your files
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              Create the <span class="InlineSnippet">pipe.ts</span> file and
              <span class="InlineSnippet">pipe.spec.ts</span> for unit test
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Create a story (see below)
          </fl-list-item>
        </fl-list>
      </fl-text>
    </fl-card>
    <fl-card
      class="SectionCard"
      [flMarginBottom]="'mid'"
      [edgeToEdge]="true"
    >
      <fl-heading
        [headingType]="HeadingType.H2"
        [size]="TextSize.SMALL"
        [flMarginBottom]="'xsmall'"
      >
        Creating new stories
      </fl-heading>
      <fl-text
        [size]="TextSize.XSMALL"
        [fontType]="FontType.CONTAINER"
      >
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
        >
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">cd gaf/webapp</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">yarn generate-story</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Answer questions at prompt
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Edit the files output by prompt
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">yarn prepare:bits</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">yarn start:bits[:instant]</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">localhost:7766</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Confirm it works
          </fl-list-item>
        </fl-list>
      </fl-text>
    </fl-card>
    <fl-card
      class="SectionCard"
      [flMarginBottom]="'mid'"
      [edgeToEdge]="true"
    >
      <fl-heading
        [headingType]="HeadingType.H2"
        [size]="TextSize.SMALL"
        [flMarginBottom]="'xsmall'"
      >
        Updating Bits or their stories
      </fl-heading>
      <fl-text
        [size]="TextSize.XSMALL"
        [fontType]="FontType.CONTAINER"
      >
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          Local testing
        </fl-text>
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
          [flMarginBottom]="'small'"
        >
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">cd gaf/webapp</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">yarn prepare:bits</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">yarn start:bits[:instant]</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              <span class="InlineSnippet">localhost:7766</span>
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Changes made should be auto reflected on story page
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              Any changes to inputs, outputs, or readme will require rerunning
              <span class="InlineSnippet">yarn prepare:bits</span>
            </fl-bit>
          </fl-list-item>
        </fl-list>

        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          Screenshot testing
        </fl-text>
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
        >
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              Run <span class="InlineSnippet">yarn e2e:bits</span> to see if
              there are any screenshot differences
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              Run
              <span class="InlineSnippet">yarn start:bits</span>
              then run
              <span class="InlineSnippet">
                yarn e2e:bits:update-screenshots</span
              >
              in a separate terminal to update the base screenshots
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              You can specify
              <span class="InlineSnippet"
                >SPECS="components.[starting letter]"</span
              >
              before the e2e command to only run tests for components starting
              with a given letter, if you only need to update specific
              screenshots.
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              For patterns, you can specify
              <span class="InlineSnippet">SPECS="shared-components"</span>
              before the e2e command.
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Commit updated screenshots if changes are expected
          </fl-list-item>
        </fl-list>
      </fl-text>
    </fl-card>
    <fl-card
      class="SectionCard"
      [flMarginBottom]="'mid'"
      [edgeToEdge]="true"
    >
      <fl-heading
        [headingType]="HeadingType.H2"
        [size]="TextSize.SMALL"
        [flMarginBottom]="'xsmall'"
      >
        Contribution Checklist
      </fl-heading>
      <fl-text
        [size]="TextSize.XSMALL"
        [fontType]="FontType.CONTAINER"
      >
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
        >
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Add sensible description
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Add InVision link/s or mockup/s if you have any
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              Run <span class="InlineSnippet">yarn</span> to execute any
              <code>prepare</code> scripts needed
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            Run test suites if your change touches anything with unit, UI and/or
            E2E tests
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              Run <span class="InlineSnippet">yarn e2e:bits</span> to check UI
              differences
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              Run <span class="InlineSnippet">yarn build:bits</span> and
              <span class="InlineSnippet">yarn e2e-server:bits</span> if you
              have any build or SSR related changes
            </fl-bit>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxsmall'">
            <fl-bit>
              New Icons files should follow this naming convention:
              <span class="InlineSnippet">ui-name</span>.
            </fl-bit>
          </fl-list-item>
        </fl-list>
      </fl-text>
    </fl-card>
    <fl-card
      class="SectionCard"
      [flMarginBottom]="'mid'"
      [edgeToEdge]="true"
    >
      <fl-heading
        [headingType]="HeadingType.H3"
        [size]="TextSize.SMALL"
        [flMarginBottom]="'xxsmall'"
      >
        Adding and updating icons
      </fl-heading>
      <fl-bit [flMarginBottom]="'mid'">
        <fl-heading
          [headingType]="HeadingType.H4"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Step 1: Add icon to Bits
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                Add the new SVG file to
                <span class="InlineSnippet">webapp/src/assets/icons</span>.
              </fl-bit>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                New files should follow this naming convention:
                <span class="InlineSnippet">ui-name</span>.
              </fl-bit>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                To replace existing icons, add a version number to file name.
                E.g
                <span class="InlineSnippet">ui-name-v2</span>
                with the version number as a single digit starting with
                <code>2</code>. Replace all usage of the old icon and delete the
                old file.
              </fl-bit>
            </fl-list-item>
          </fl-list>
        </fl-text>
      </fl-bit>
      <fl-bit [flMarginBottom]="'mid'">
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Step 2: Update the Icon story
        </fl-heading>

        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                Add or rename icon on this list:
                <span class="InlineSnippet">
                  webapp/src/@freelancer/ui/icon/stories/icon-list.story.ts
                </span>
              </fl-bit>
            </fl-list-item>
          </fl-list>
        </fl-text>
      </fl-bit>
      <fl-bit [flMarginBottom]="'mid'">
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Step 3: Stress test the icon
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxxsmall'"
        >
          Verify that the icon's style can be modified via component inputs:
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                Replace the default icon in
                <span class="InlineSnippet">
                  webapp/src/@freelancer/ui/icon/stories/colors-and-sizes.story.ts
                </span>
              </fl-bit>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                Check if all variations are rendered properly. If you run into
                any issues, verify the icon's markup. There should be no
                hardcoded color values. Generally these are
                <span class="InlineSnippet">fill</span> and
                <span class="InlineSnippet">stroke</span> attributes.
              </fl-bit>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Do not commit changes in this file.
            </fl-list-item>
          </fl-list>
        </fl-text>
      </fl-bit>
    </fl-card>
  `,
  styleUrls: [
    '../static-pages.component.scss',
    './how-to-contribute.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeveloperContributionComponent {
  BannerAlertLevel = BannerAlertLevel;
  BannerAlertType = BannerAlertType;
  TextSize = TextSize;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  ListItemType = ListItemType;
}
