import { TimeUnitApi } from 'api-typings/common/common';
import type { MembershipPackagePrice } from '../recommended-membership';

export interface GenerateMembershipPackagePriceOptions {
  readonly packageId: number;
  readonly contractQuantity?: number;
  readonly durationType?: TimeUnitApi;
  readonly amount?: number;
}

export function generateMembershipPackagePriceObject({
  packageId,
  contractQuantity,
  durationType = TimeUnitApi.MONTH,
  amount = 1,
}: GenerateMembershipPackagePriceOptions): MembershipPackagePrice {
  return {
    id: 1,
    packageId,
    durationId: 2,
    durationType,
    durationCycle: 1,
    amount,
    currencyId: 1, // USD
    timeValidStart: Date.now(),
    contractQuantity,
  };
}
