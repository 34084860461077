import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import {
  FontColor,
  FontStyle,
  FontType,
  FontWeight,
  TextSize,
} from '@freelancer/ui/text';

interface TypeScale {
  enum: TextSize;
  value: string;
}

export const typeScales: readonly TypeScale[] = [
  {
    enum: TextSize.MARKETING_XMEGA,
    value: '91px',
  },
  {
    enum: TextSize.MARKETING_MEGA,
    value: '57px',
  },
  {
    enum: TextSize.XXXLARGE,
    value: '48px',
  },
  {
    enum: TextSize.XXLARGE,
    value: '41px',
  },
  {
    enum: TextSize.XLARGE,
    value: '32px',
  },
  {
    enum: TextSize.LARGE,
    value: '24px',
  },
  {
    enum: TextSize.MID,
    value: '20px',
  },
  {
    enum: TextSize.MARKETING_SMALL,
    value: '18px',
  },
  {
    enum: TextSize.SMALL,
    value: '16px',
  },
  {
    enum: TextSize.XSMALL,
    value: '14px',
  },
  {
    enum: TextSize.XXSMALL,
    value: '13px',
  },
  {
    enum: TextSize.XXXSMALL,
    value: '11px',
  },
  {
    enum: TextSize.INHERIT,
    value: 'Inherit from parent',
  },
];

@Component({
  selector: 'app-static-typography',
  template: `
    <fl-bit class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-bit>
          <fl-heading
            [headingType]="HeadingType.H2"
            [size]="TextSize.MID"
            [flMarginBottom]="'xsmall'"
          >
            Typography
          </fl-heading>
          <fl-text
            [fontType]="FontType.PARAGRAPH"
            [size]="TextSize.SMALL"
            [flMarginBottom]="'mid'"
          >
            Our typographic system has been crafted with the goal of providing
            maximum legibility in all devices and bring consistency to our user
            interface.
          </fl-text>

          <fl-text
            [fontType]="FontType.PARAGRAPH"
            [flMarginBottom]="'mid'"
          >
            Our typographic styles are tied to appropriate functions in the UI.
            By doing this we design a clear visual pattern for our Freelancers
            and Employers to follow while they're interacting with our website.
          </fl-text>

          <fl-heading
            [headingType]="HeadingType.H3"
            [size]="TextSize.SMALL"
            [flMarginBottom]="'xsmall'"
          >
            Font Family
          </fl-heading>
          <fl-text
            [fontType]="FontType.PARAGRAPH"
            [flMarginBottom]="'xsmall'"
          >
            We use Roboto Google Font. Google has described Roboto as "modern,
            yet approachable" and "emotional".
          </fl-text>
        </fl-bit>
      </fl-card>
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-bit>
          <fl-heading
            [headingType]="HeadingType.H2"
            [size]="TextSize.MID"
            [flMarginBottom]="'xsmall'"
          >
            Styled Typography Components
          </fl-heading>
          <fl-text
            [fontType]="FontType.PARAGRAPH"
            [flMarginBottom]="'small'"
          >
            We created two angular components to handle headings and generic
            text separately in our product.
          </fl-text>
          <fl-bit [flMarginBottom]="'mid'">
            <fl-heading
              [size]="TextSize.SMALL"
              [headingType]="HeadingType.H3"
              [flMarginBottom]="'xsmall'"
            >
              Headings

              <fl-link [link]="'/components/heading'">
                Heading Component
              </fl-link>
            </fl-heading>
            <fl-text [flMarginBottom]="'xsmall'">
              HTML defines six levels of headings. H1 to H6.
              <fl-text [fontType]="FontType.STRONG">
                The SIZE of each heading is NOT bound to its TYPE
              </fl-text>
              . As a rule of thumb a H1 heading should be visually bigger than
              an H2, an H2 should be bigger than an H3 and so forth, but this is
              not always true since it's at complete discretion of the design
              needs of a particular page or section. This means that engineers
              will need to keep into account the semantic weight of a heading
              and choose the proper HeadingType, while following the design
              specs for the correct TextSize.
            </fl-text>
            <fl-link [link]="'https://www.hobo-web.co.uk/headers/'">
              Read about the importance of a sensible use of H1 to H6 headings.
            </fl-link>
          </fl-bit>
          <fl-heading
            [size]="TextSize.SMALL"
            [headingType]="HeadingType.H3"
            [flMarginBottom]="'xsmall'"
          >
            Generic Text

            <fl-link [link]="'/components/text'"> Text Component </fl-link>
          </fl-heading>
          <fl-text [flMarginBottom]="'mid'">
            Text is normally provided with the use of the HTML tag p for block
            level text and span for inline text.
          </fl-text>
          <fl-text
            [fontType]="FontType.PARAGRAPH"
            [flMarginBottom]="'xsmall'"
          >
            With the idea in mind of keeping our CSS files as small as possible
            the HeadingComponent and TextComponent their own visual styles from
            their templates, with the use of predefined Inputs E.g.:
          </fl-text>
          <app-code-block
            [code]="codeSnippetA"
            [language]="'xml'"
          ></app-code-block>
        </fl-bit>
      </fl-card>
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-bit>
          <fl-heading
            [headingType]="HeadingType.H2"
            [size]="TextSize.MID"
            [flMarginBottom]="'xsmall'"
          >
            Type Scale
          </fl-heading>
          <ng-container
            *ngFor="let typeScale of typeScales; trackBy: trackByName"
          >
            <fl-bit
              class="TypeScale"
              [flMarginBottom]="'small'"
            >
              <fl-text [size]="TextSize.SMALL">
                Font size : {{ typeScale.value }}
              </fl-text>
              <fl-text
                [size]="typeScale.enum"
                [weight]="FontWeight.BOLD"
              >
                Hire expert freelancers for any job
              </fl-text>
              <fl-text
                [size]="typeScale.enum"
                [flMarginBottom]="'xsmall'"
              >
                Hire expert freelancers for any job
              </fl-text>
              <app-code-block
                class="TypeScale-code"
                [code]="'TextSize.' + (typeScale.enum | uppercase)"
                [language]="'xml'"
              ></app-code-block>
            </fl-bit>
          </ng-container>
        </fl-bit>
      </fl-card>
    </fl-bit>
  `,
  styleUrls: ['../static-pages.component.scss', './typography.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypographyComponent {
  TextSize = TextSize;
  FontStyle = FontStyle;
  FontColor = FontColor;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  ListItemType = ListItemType;
  typeScales = typeScales;

  codeSnippetA = `
<fl-text
  [fontType]="FontType.PARAGRAPH"
  [size]="TextSize.SMALL"
  [weight]="FontWeight.BOLD"
>
 This is my text
</fl-text>
  `;

  trackByName(_: number, scale: TypeScale): string {
    return scale.enum;
  }
}
