import { generateId } from '@freelancer/datastore/testing/helpers';
import type { SuperuserProjectNote } from './superuser-project-notes.model';

export function generateSuperuserProjectNoteObjects({
  adminNotes = [],
}: GenerateSuperuserProjectNoteOptions): readonly SuperuserProjectNote[] {
  return adminNotes;
}

export interface GenerateSuperuserProjectNoteOptions {
  readonly adminNotes: readonly SuperuserProjectNote[];
}

export function withNotes({
  noteCount = 1,
  noteTypes = ['custom'],
  projectId = generateId(),
}: {
  readonly noteCount?: number;
  readonly noteTypes?: readonly string[];
  readonly projectId?: number;
}): GenerateSuperuserProjectNoteOptions {
  const adminNotes: SuperuserProjectNote[] = [];
  const noteTypesCount = noteTypes.length;

  for (let i = 0; i < noteCount; i++) {
    adminNotes.push({
      id: generateId(),
      description: `Note ${i}`,
      projectId,
      noteType: noteTypes[i % noteTypesCount], // iterate through note types,
      adminName: 'mbarrie',
      submitDate: Date.now(),
    });
  }

  return {
    adminNotes,
  };
}
