import { generateId } from '@freelancer/datastore/testing/helpers';
import { ScheduledSubscriptionPaymentStatusApi } from 'api-typings/quotations/quotations';
import { addMonths } from 'date-fns';
import { type ScheduledSubscriptionPayment } from './scheduled-subscription-payments.model';

export interface GenerateScheduledSubscriptionPaymentObjectOptions {
  readonly subscriptionItemId?: number;
  readonly agreedBillingDate?: number;
  readonly milestoneId?: number;
  readonly status?: ScheduledSubscriptionPaymentStatusApi;
  readonly retryCount?: number;
  readonly isFreelancerReviewed?: boolean;
  readonly isEmployerReviewed?: boolean;
}

export function generateScheduledSubscriptionPaymentObject({
  subscriptionItemId = 1,
  agreedBillingDate = Date.now(),
  status = ScheduledSubscriptionPaymentStatusApi.SUCCESS,
  retryCount = 0,
  milestoneId = generateId(),
  isFreelancerReviewed = false,
  isEmployerReviewed = false,
} = {}): ScheduledSubscriptionPayment {
  return {
    id: generateId(),
    subscriptionItemId,
    agreedBillingDate,
    milestoneId,
    status,
    retryCount,
    reputationFreelancersSubscriptionId: isFreelancerReviewed
      ? generateId()
      : undefined,
    reputationEmployersSubscriptionId: isEmployerReviewed
      ? generateId()
      : undefined,
    nextBillingDate: agreedBillingDate
      ? addMonths(agreedBillingDate, 1).getTime()
      : undefined,
  };
}

export function generateScheduledSubscriptionPaymentObjects(
  scheduledSubscriptionPayments: readonly GenerateScheduledSubscriptionPaymentObjectOptions[],
): readonly ScheduledSubscriptionPayment[] {
  return scheduledSubscriptionPayments.map(
    generateScheduledSubscriptionPaymentObject,
  );
}
