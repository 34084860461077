import type { Location } from '../project-view-users/location.model';
import type { GenerateProjectOptions } from '../projects/projects.seed';
import { generateProjectObject } from '../projects/projects.seed';
import type { SuperUserAllProjects } from './superuser-project-view-all-projects.model';

export interface SuperUserAllProjectsExtraOptions {
  readonly location?: Location;
  readonly displayLocation?: string;
  // Optional filter from AllProjects collection
  readonly unlistedProjects?: boolean;
}

export interface GenerateSuperuserProjectViewAllProjectsOptions {
  readonly projectObject: GenerateProjectOptions;
  readonly extras?: SuperUserAllProjectsExtraOptions;
}

/** Returns a SuperUserAllProjects object. */
export function generateSuperuserProjectViewAllProjectObject({
  projectObject = {},
  extras,
}: GenerateSuperuserProjectViewAllProjectsOptions): SuperUserAllProjects {
  const project = generateProjectObject(projectObject);
  return {
    ...project,
    ...extras,
  };
}

export function unlistedProject(): Pick<
  SuperUserAllProjectsExtraOptions,
  'unlistedProjects'
> {
  return {
    unlistedProjects: true,
  };
}
