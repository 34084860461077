import type { PayoutProviderTypeEnumApi } from 'api-typings/payments/payments';
import {
  PayoutAccountFormComponentTypeEnumApi,
  PayoutAccountFormInputFieldTypeEnumApi,
  PayoutMethodTypeEnumApi,
} from 'api-typings/payments/payments';
import type { CountryCode } from '../countries';
import type {
  PayoutAccountForm,
  PayoutAccountFormComponent,
} from './payout-account-form.model';

export function generatePayoutAccountFormObjects({
  userId,
  countryCode,
  currencyId = 1,
  providerType,
  method = PayoutMethodTypeEnumApi.WIRE,
  formComponents = [],
}: GeneratePayoutAccountFormOptions): readonly PayoutAccountForm[] {
  return [
    {
      id: userId.toString(),
      countryCode,
      currencyId,
      providerType,
      method,
      formComponents,
    },
  ];
}

export interface GeneratePayoutAccountFormOptions {
  readonly userId: number;
  readonly countryCode: CountryCode;
  readonly currencyId?: number;
  readonly providerType: PayoutProviderTypeEnumApi;
  readonly method?: PayoutMethodTypeEnumApi;
  readonly formComponents?: readonly PayoutAccountFormComponent[];
}

export function wirePayoutAccountForm(): Partial<GeneratePayoutAccountFormOptions> {
  return {
    method: PayoutMethodTypeEnumApi.WIRE,
    formComponents: [
      {
        componentType: PayoutAccountFormComponentTypeEnumApi.INPUT_FIELD,
        inputField: {
          fieldType: PayoutAccountFormInputFieldTypeEnumApi.TEXT,
          fieldIdentifier: 'account_alias',
          label: 'Method Nickname',
          mandatory: false,
          validators: [
            {
              regexpRule: '/^.{0,255}$/',
              errorMsg: 'Must be at most 255 characters in length',
            },
          ],
        },
      },
      {
        componentType: PayoutAccountFormComponentTypeEnumApi.INPUT_FIELD,
        inputField: {
          fieldType: PayoutAccountFormInputFieldTypeEnumApi.TEXT,
          fieldIdentifier: 'beneficiary_account',
          label: 'IBAN/Account No',
          mandatory: true,
          validators: [
            {
              regexpRule: '/^[A-Za-z0-9]+$/',
              errorMsg: 'Account number, must be alphanumeric',
            },
          ],
        },
      },
      {
        componentType: PayoutAccountFormComponentTypeEnumApi.INPUT_FIELD,
        inputField: {
          fieldType: PayoutAccountFormInputFieldTypeEnumApi.TEXT,
          fieldIdentifier: 'beneficiary_name',
          label: 'Account Name',
          mandatory: true,
          validators: [
            {
              regexpRule: "/^[A-Za-z0-9 \\-'.,]+$/",
              errorMsg:
                'Account name must only contain letters, numbers, spaces, dashes, apostrophes, full stops and commas',
            },
          ],
        },
      },
      {
        componentType: PayoutAccountFormComponentTypeEnumApi.INPUT_FIELD,
        inputField: {
          fieldType: PayoutAccountFormInputFieldTypeEnumApi.TEXT,
          fieldIdentifier: 'beneficiary_address_1',
          label: 'Beneficiary Address 1',
          mandatory: true,
          validators: [
            {
              regexpRule: "/^[A-Za-z0-9 \\-\\/'.,]+$/",
              errorMsg:
                'Must only contain letters, numbers, spaces, dashes, forward slashes, apostrophes, full stops and commas',
            },
          ],
        },
      },
      {
        componentType: PayoutAccountFormComponentTypeEnumApi.INPUT_FIELD,
        inputField: {
          fieldType: PayoutAccountFormInputFieldTypeEnumApi.TEXT,
          fieldIdentifier: 'beneficiary_address_2',
          label: 'Beneficiary Address 2',
          mandatory: false,
          validators: [
            {
              regexpRule: "/(^$)|(^[A-Za-z0-9 \\-\\/'.,]+$)/",
              errorMsg:
                'Must only contain letters, numbers, spaces, dashes, forward slashes, apostrophes, full stops and commas',
            },
          ],
        },
      },
      {
        componentType: PayoutAccountFormComponentTypeEnumApi.INPUT_FIELD,
        inputField: {
          fieldType: PayoutAccountFormInputFieldTypeEnumApi.TEXT,
          fieldIdentifier: 'beneficiary_swift_code',
          label: 'Beneficiary SWIFT Code',
          mandatory: true,
          validators: [
            {
              regexpRule: '/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/',
              errorMsg: 'SWIFT code, either 8-digit or 11-digit code',
            },
          ],
        },
      },
      {
        componentType: PayoutAccountFormComponentTypeEnumApi.INPUT_FIELD,
        inputField: {
          fieldType: PayoutAccountFormInputFieldTypeEnumApi.TEXT,
          fieldIdentifier: 'wire_additional_info',
          label: 'Additional Information',
          mandatory: false,
          validators: [],
        },
      },
    ],
  };
}
