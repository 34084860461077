import { generateId } from '@freelancer/datastore/testing/helpers';
import type {
  DocumentType,
  VerificationProcessType,
} from './verification-process-types.model';
import { VERIFICATION_PROCESS_TYPES } from './verification-process-types.model';

export interface GenerateVerificationProcessTypeOptions {
  readonly name?: string;
  readonly description?: string;
  readonly subTitle?: string;
  readonly subDescription?: string;
  readonly url?: string;
  readonly documentTypes?: readonly DocumentType[];
}

// -- Mixins --

export function generateVerificationProcessTypeObject({
  name = 'Test Verification',
  description = 'Nulla porttitor accumsan tincidunt. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae',
  subTitle,
  subDescription,
  url = '/test-verification',
  documentTypes = [],
}: GenerateVerificationProcessTypeOptions): VerificationProcessType {
  return {
    id: generateId(),
    name,
    description,
    subTitle,
    subDescription,
    url,
    documentTypes,
  };
}

export function documentType({
  id,
  name = 'Test Document Type',
  description = '',
  instructions = '',
  hasExpiration = false,
  isRequired = false,
  documentTypeFields = [],
  documentTypeFiles: files = [],
}: Partial<DocumentType>): DocumentType {
  return {
    id: id ?? generateId(),
    name,
    description,
    instructions,
    hasExpiration,
    isRequired,
    documentTypeFields,
    documentTypeFiles: files,
  };
}

export function documentTypeFiles(
  ...names: readonly string[]
): Pick<DocumentType, 'documentTypeFiles'> {
  return {
    documentTypeFiles: names.map(name => ({
      id: generateId(),
      name,
    })),
  };
}

export interface GenerateVerificationProcessTypesOptions {
  readonly id?: number;
  readonly name?: string;
  readonly description?: string;
  readonly subTitle?: string;
  readonly subDescription?: string;
  readonly url?: string;
  readonly documentTypes?: readonly DocumentType[];
}

export function generateVerificationProcessType({
  id = generateId(),
  name = 'User Verification',
  description = 'The user verification process is designed to allow you to build trust in the community and provide users with more information regarding your professional qualifications.',
  subTitle = 'Why submit documents?',
  subDescription = 'Upload documents',
  url = 'user-verification',
  documentTypes = driversLicense(),
}: GenerateVerificationProcessTypesOptions): VerificationProcessType {
  return {
    id,
    name,
    description,
    subTitle,
    subDescription,
    url,
    documentTypes,
  };
}

export function generateVerificationProcessTypes(
  showcaseOptions: readonly GenerateVerificationProcessTypesOptions[],
): readonly VerificationProcessType[] {
  return showcaseOptions.map(showcaseOption =>
    generateVerificationProcessType(showcaseOption),
  );
}

export function driversLicense(): readonly DocumentType[] {
  return [
    {
      id: generateId(),
      name: `Australian Driver's License`,
      description: `Please upload your valid Australian Driver's License.`,
      instructions: `Please ensure that your driver's license file is legible and ensure that the following information is clear: Full Name, Driver's License Number and Expiry Date`,
      documentTypeFiles: [
        {
          id: generateId(),
          name: 'Front Page',
        },
      ],
      documentTypeFields: [],
    },
  ];
}

export function currencyForPublicLiability(): readonly DocumentType[] {
  return [
    {
      id: generateId(),
      name: `Certificate of Currency for Public Liability`,
      description: `Please upload your valid Certificate of Currency for Public Liability.`,
      instructions: `Please ensure that your Certificate of Currency for Public Liability file is legible and ensure that your Full Name is clear`,
      documentTypeFiles: [
        {
          id: generateId(),
          name: 'Front Page',
        },
      ],
      documentTypeFields: [],
    },
  ];
}

export function loadshiftCarrierVerification(): Pick<
  GenerateVerificationProcessTypesOptions,
  'url'
> {
  return {
    url: 'loadshift-carrier-verification',
  };
}

export function insuranceVerification(): Pick<
  GenerateVerificationProcessTypesOptions,
  'url'
> {
  return {
    url: VERIFICATION_PROCESS_TYPES.INSURANCES_AND_CERTIFICATES,
  };
}

export function freelancerVideoVerification(): Pick<
  GenerateVerificationProcessTypesOptions,
  'url'
> {
  return {
    url: VERIFICATION_PROCESS_TYPES.FREELANCER_VIDEO_VERIFICATION,
  };
}
