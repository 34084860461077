import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  blockInitialRenderAnimation,
  fadeInAnimation,
  fadeInOutAnimation,
  fadeOutAnimation,
} from '@freelancer/animations';
import { Assets } from '@freelancer/ui/assets';
import { HeadingType, HeadingWeight } from '@freelancer/ui/heading';
import { TagStatusColor } from '@freelancer/ui/tag-status';
import { FontColor, TextSize } from '@freelancer/ui/text';
import type { DynamicDemoConfig } from '../dynamic-demo/dynamic-demo.component';

@Component({
  selector: 'app-static-fade',
  template: `
    <fl-bit class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-bit [flMarginBottom]="'small'">
          <fl-tag-status [color]="TagStatusColor.BLUE">Beta</fl-tag-status>
        </fl-bit>

        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.XLARGE"
          [flMarginBottom]="'mid'"
        >
          Fade
        </fl-heading>

        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.LARGE"
          [flMarginBottom]="'xsmall'"
          [weight]="HeadingWeight.NORMAL"
        >
          Fade In, Out and In/Out
        </fl-heading>
        <fl-text
          [size]="TextSize.SMALL"
          [flMarginBottom]="'mid'"
        >
          Animate elements as they get added and/or removed from the view.
        </fl-text>

        <fl-text [flMarginBottom]="'xxxsmall'">Trigger</fl-text>
        <fl-select
          [control]="fadeTriggerControl"
          [options]="fadeTriggerOptions"
          [flMarginBottom]="'small'"
        ></fl-select>

        <app-dynamic-demo [config]="fadeConfig">
          <app-dynamic-demo-content @blockInitialRenderAnimation>
            <ng-container *ngIf="fadeDisplayControl?.value">
              <ng-container
                *ngIf="fadeTriggerControl?.value === '@fadeInOutAnimation'"
              >
                <app-test-user-card
                  [user]="defaultUser"
                  [@fadeInOutAnimation]="{
                    value: undefined,
                    params: {
                      duration: fadeConfig.duration?.value,
                      delay: fadeConfig.delay?.value
                    }
                  }"
                ></app-test-user-card>
              </ng-container>
              <ng-container
                *ngIf="fadeTriggerControl?.value === '@fadeInAnimation'"
              >
                <app-test-user-card
                  [user]="defaultUser"
                  [@fadeInAnimation]="{
                    value: undefined,
                    params: {
                      duration: fadeConfig.duration?.value,
                      delay: fadeConfig.delay?.value
                    }
                  }"
                ></app-test-user-card>
              </ng-container>
              <ng-container
                *ngIf="fadeTriggerControl?.value === '@fadeOutAnimation'"
              >
                <app-test-user-card
                  [user]="defaultUser"
                  [@fadeOutAnimation]="{
                    value: undefined,
                    params: {
                      duration: fadeConfig.duration?.value,
                      delay: fadeConfig.delay?.value
                    }
                  }"
                ></app-test-user-card>
              </ng-container>
            </ng-container>
          </app-dynamic-demo-content>
          <app-dynamic-demo-action>
            <fl-bit [flMarginBottom]="'xsmall'">
              <fl-text
                [size]="TextSize.XXSMALL"
                [flMarginBottom]="'xxxsmall'"
              >
                Toggle display
              </fl-text>
              <fl-toggle
                label="Toggle display"
                [control]="fadeDisplayControl"
              ></fl-toggle>
            </fl-bit>
          </app-dynamic-demo-action>
        </app-dynamic-demo>
      </fl-card>
    </fl-bit>
  `,
  styleUrls: ['../../static-pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    blockInitialRenderAnimation,
    fadeInAnimation,
    fadeInOutAnimation,
    fadeOutAnimation,
  ],
})
export class FadeComponent {
  FontColor = FontColor;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  TagStatusColor = TagStatusColor;
  TextSize = TextSize;

  defaultUser = {
    id: 1,
    avatar: this.assets.getUrl('bits/avatars/32-5.jpg'),
    username: 'user1',
  };

  fadeDisplayControl = new FormControl(true, { nonNullable: true });
  fadeConfig: DynamicDemoConfig = {
    duration: new FormControl(200, { nonNullable: true }),
    delay: new FormControl(0, { nonNullable: true }),
  };
  fadeTriggerControl = new FormControl('@fadeInOutAnimation', {
    nonNullable: true,
  });
  fadeTriggerOptions: readonly string[] = [
    '@fadeInAnimation',
    '@fadeOutAnimation',
    '@fadeInOutAnimation',
  ];

  constructor(private assets: Assets) {}
}
