import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  PaymentShareEntityTypeApi,
  PaymentShareMemberStatusApi,
  PaymentShareSpendingLimitDurationApi,
} from 'api-typings/users/users';
import type { PaymentShareMember } from './payment-share-members.model';

export interface GeneratePaymentShareMemberOptions {
  readonly paymentShareTeamId: number;
  readonly entityId: number;
  readonly entityType?: PaymentShareEntityTypeApi;
  readonly billingAgreementId?: number;
  readonly status?: PaymentShareMemberStatusApi;
  readonly useTeamSpendLimit?: boolean;
  readonly isSpendingLimitActive?: boolean;
  readonly spendingLimitDuration?: PaymentShareSpendingLimitDurationApi;
  readonly spendingLimitAmount?: number;
}

/** Returns a payment share member object. */
export function generatePaymentShareMemberObject({
  paymentShareTeamId,
  entityId,
  entityType = PaymentShareEntityTypeApi.USER,
  billingAgreementId = generateId(),
  status = PaymentShareMemberStatusApi.ADDED,
  useTeamSpendLimit = true,
  isSpendingLimitActive = true,
  spendingLimitDuration = PaymentShareSpendingLimitDurationApi.NO_TIME_LIMIT,
  spendingLimitAmount = 0,
}: GeneratePaymentShareMemberOptions): PaymentShareMember {
  return {
    id: generateId(),
    paymentShareTeamId,
    entityId,
    entityType,
    billingAgreementId,
    status,
    useTeamSpendLimit,
    isSpendingLimitActive,
    spendingLimitDuration,
    spendingLimitAmount,
  };
}
