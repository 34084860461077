import { uniq } from '@freelancer/datastore/core';
import { generateId } from '@freelancer/datastore/testing/helpers';
// TODO: T235847 This requires deep import in order to avoid transpiling
// html-renderer.module.ts from index.ts.
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type { RichTextElement } from '@freelancer/html-renderer/html-elements.model';
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { HtmlElementType } from '@freelancer/html-renderer/html-elements.model';
import {
  ContestStatusApi,
  ContestTypeApi,
} from 'api-typings/contests/contests';
import type { ContestEntry } from '../contest-entries/contest-entries.model';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { Skill } from '../skills/skills.model';
import { phpSkill } from '../skills/skills.seed';
import type { Contest, ContestUpgrade } from './contests.model';

export interface GenerateContestOptions {
  readonly ownerId: number;
  readonly contestId?: number;
  readonly type?: ContestTypeApi;
  readonly currencyCode?: CurrencyCode;
  readonly description?: string;
  readonly status?: ContestStatusApi;
  readonly skills?: readonly Skill[];
  readonly title?: string;
  readonly entryCount?: number;
  readonly duration?: number;
  readonly upgrades?: Partial<ContestUpgrade>;
  readonly prize?: number;
  readonly seoUrl?: string;
  readonly timeEnded?: number;
  readonly judgingPeriodDays?: number;
  readonly descriptionRichText?: readonly RichTextElement[];
  readonly enterpriseId?: number;
  readonly freelancerCount?: number;
}

/**
 * Returns a contest object. By default, this is a contest that is "Active".
 */
export function generateContestObject({
  ownerId,
  contestId,
  type = ContestTypeApi.REGULAR,
  currencyCode = CurrencyCode.USD,
  description = 'A contest description',
  status = ContestStatusApi.ACTIVE,
  skills = [phpSkill()],
  title = 'A contest title',
  seoUrl,
  entryCount = 0,
  duration = 7,
  upgrades = {},
  prize = 100,
  // Use 5 days instead of `CONTEST_DEFAULT_JUDGING_PERIOD_DAYS` because tests
  // can be flaky in some edge cases on different dates, see T287818
  judgingPeriodDays = 5,
  timeEnded,
  descriptionRichText = [],
  enterpriseId,
  freelancerCount,
}: GenerateContestOptions): Contest {
  const id = contestId !== undefined ? contestId : generateId();
  const now = Date.now();

  const contestUpgrades: ContestUpgrade = {
    guaranteed: false,
    featured: false,
    topContest: false,
    highlight: false,
    sealed: false,
    nda: false,
    private: false,
    urgent: false,
    customJudging: false,
    ...upgrades,
  };

  return {
    id,
    currency: generateCurrencyObject(currencyCode),
    description,
    skills,
    ownerId,
    seoUrl: seoUrl ?? `contest/${id}`,
    status,
    title,
    type,
    timeSubmitted: now,
    timePosted: now,
    timeEnded: timeEnded ?? now + duration * 24 * 60 * 60 * 1000,
    timeSigned: undefined,
    entryCount,
    duration,
    upgrades: contestUpgrades,
    prize,
    draft: status === ContestStatusApi.INACTIVE, // from transformer
    fileIds: undefined, // TODO: T267853 -
    enterpriseId,
    judgingPeriodDays,
    descriptionRichText,
    freelancerCount,
  };
}

// --- Mixins ---
/**
 * Contest with entries should have a non-zero entry count and a non-zero freelancer count.
 */
export function withEntries(
  entries: readonly ContestEntry[],
): Pick<GenerateContestOptions, 'entryCount' | 'freelancerCount'> {
  return {
    entryCount: entries.length,
    freelancerCount: uniq(entries.map(entry => entry.ownerId)).length,
  };
}

/**
 * A pending contest is an expired contest (past its end date) that is waiting for the handover process
 * to be completed.
 */
export function pendingContest(): Pick<
  GenerateContestOptions,
  'status' | 'timeEnded'
> {
  return {
    status: ContestStatusApi.PENDING,
    // Make the end time to a past time
    timeEnded: Date.now() - 100,
  };
}

/**
 * A closed contest is an expired contest (past its end date) that has completed the handover process.
 */
export function closedContest(): Pick<
  GenerateContestOptions,
  'status' | 'timeEnded'
> {
  return {
    status: ContestStatusApi.CLOSED,
    // Make the end time to a past time
    timeEnded: Date.now() - 100,
  };
}

export function richTextDescription(
  value: string,
): Pick<GenerateContestOptions, 'descriptionRichText'> {
  return {
    descriptionRichText: [
      {
        type: HtmlElementType.DIV,
        children: [
          {
            type: HtmlElementType.TEXT,
            value,
          },
        ],
      },
    ],
  };
}

/**
 * A soonExpiringContest is a contest with under a day remaining for entry submissions
 */
export function soonExpiringContest(): Pick<
  GenerateContestOptions,
  'status' | 'duration'
> {
  return {
    status: ContestStatusApi.ACTIVE,
    // set duration to slightly less than 1 day
    duration: 0.99,
  };
}

export function guaranteedUpgrade(): Pick<GenerateContestOptions, 'upgrades'> {
  return {
    upgrades: {
      guaranteed: true,
    },
  };
}

export function ndaUpgrade(): Pick<GenerateContestOptions, 'upgrades'> {
  return {
    upgrades: {
      nda: true,
    },
  };
}

export function sealedUpgrade(): Pick<GenerateContestOptions, 'upgrades'> {
  return {
    upgrades: {
      sealed: true,
    },
  };
}
