import type { BidScore } from './bid-scores.model';

export interface GenerateBidScoreOptions {
  readonly id: number; // projectId
  readonly bidderId: number;
  readonly score?: number;
}

export function generateBidScoreObject({
  id,
  bidderId,
  score = 1,
}: GenerateBidScoreOptions): BidScore {
  return {
    id,
    bidderId,
    score,
  };
}
