import {
  NotificationSettingDeliveryTypeApi,
  NotificationSettingTargetApi,
  NotificationSettingValueApi,
} from 'api-typings/users/users';
import type { NotificationSettingApi } from 'api-typings/users/users';
import type {
  UserNotificationSetting,
  UserNotificationSettingMetadata,
} from './notification-settings.model';
import { getUserNotificationSettingId } from './notification-settings.transforms';

export interface GenerateNotificationSettingsOptions {
  readonly target?: NotificationSettingTargetApi;
  readonly deliveryType?: NotificationSettingDeliveryTypeApi;
  readonly userId?: number;
  readonly value?: NotificationSettingValueApi;
  readonly targetId?: number;
  readonly parentId?: number;
  readonly metadata?: UserNotificationSettingMetadata;
}

export function generateNotificationSettingsObject({
  deliveryType = NotificationSettingDeliveryTypeApi.EMAIL,
  target = NotificationSettingTargetApi.GROUP,
  targetId = 123,
  parentId = undefined,
  userId = 456,
  value = NotificationSettingValueApi.COMMENTS_AND_REPLIES,
  metadata,
}: GenerateNotificationSettingsOptions = {}): UserNotificationSetting {
  const setting: NotificationSettingApi = {
    delivery_type: deliveryType,
    target,
    target_id: targetId,
    parent_id: parentId,
    value,
    user_id: userId,
  };

  const id = getUserNotificationSettingId(setting);

  return {
    id,
    value,
    parentId,
    userId,
    deliveryType,
    target,
    targetId,
    metadata,
  };
}
