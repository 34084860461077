import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  DriveFileContextTypeApi,
  DriveFilePermissionApi,
} from 'api-typings/drive/drive';
import type { DriveFilePermission } from './drive-file-permissions.model';

export interface GenerateDriveFilePermissionOptions {
  readonly id?: number;
  readonly contextType: DriveFileContextTypeApi;
  readonly contextId: number;
  readonly permission: DriveFilePermissionApi;
  readonly fileId: number;
  readonly active?: boolean;
}

export function generateDriveFilePermissionObject({
  id = generateId(),
  contextType,
  contextId,
  permission,
  fileId,
  active = true,
}: GenerateDriveFilePermissionOptions): DriveFilePermission {
  return {
    id,
    contextId,
    contextType,
    permission,
    fileId,
    active,
  };
}

export function userPermissionContext(
  userId: number,
): Pick<GenerateDriveFilePermissionOptions, 'contextId' | 'contextType'> {
  return {
    contextId: userId,
    contextType: DriveFileContextTypeApi.USER,
  };
}

export function readPermission(
  fileId: number,
): Pick<GenerateDriveFilePermissionOptions, 'fileId' | 'permission'> {
  return {
    fileId,
    permission: DriveFilePermissionApi.READ,
  };
}

export function deletePermission(
  fileId: number,
): Pick<GenerateDriveFilePermissionOptions, 'fileId' | 'permission'> {
  return {
    fileId,
    permission: DriveFilePermissionApi.DELETE,
  };
}
