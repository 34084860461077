import { generateId } from '@freelancer/datastore/testing/helpers';
import { v4 as uuid } from 'uuid';
import type { ServiceOfferingShopCategoryService } from './service-offering-shop-category-services.model';
import { ServiceOfferingShopCategoryServicePriceType } from './service-offering-shop-category-services.model';

const SECONDS_IN_A_DAY = 86_400;

export interface GenerateServiceOfferingShopCategoryServiceOptions {
  readonly id?: number;
  readonly serviceOfferingId?: number;
  readonly categoryId?: number;
  readonly shopId?: number;
  readonly title?: string;
  readonly seoUrl?: string;
  readonly heroImageUuid?: string;
  readonly heroImageUrl?: string;
  readonly duration?: number;
  readonly price?: number;
  readonly priceType?: ServiceOfferingShopCategoryServicePriceType;
}

export function generateServiceOfferingShopCategoryServiceObject({
  id = generateId(),
  serviceOfferingId = generateId(),
  categoryId = generateId(),
  shopId = generateId(),
  title = `Test Service Offering Shop Category Service ${id}`,
  seoUrl = `test-service-offering-shop-category-service-${id}`,
  heroImageUuid = uuid(),
  heroImageUrl = 'test-hero-image-url',
  duration = SECONDS_IN_A_DAY * 1000,
  price = 100,
  priceType = ServiceOfferingShopCategoryServicePriceType.HAS_FIXED_ITEMS,
}: GenerateServiceOfferingShopCategoryServiceOptions): ServiceOfferingShopCategoryService {
  return {
    id,
    serviceOfferingId,
    categoryId,
    shopId,
    title,
    seoUrl,
    heroImageUuid,
    heroImageUrl,
    duration,
    price,
    priceType,
  };
}
