import {
  generateId,
  getRandomText,
  getTitleText,
} from '@freelancer/datastore/testing/helpers';
import { ContentTypeApi } from 'api-typings/users/users';
import type { ProfileCategory } from '../profile-categories';
import { phpSkill } from '../skills/skills.seed';
import type {
  PortfolioFile,
  PortfolioFileThumbnail,
  PortfolioItem,
  PortolioArticleFile,
} from './portfolios.model';
import { transformPortfolioSeoTitle } from './portfolios.transformers';

export interface GeneratePortfoliosOptions {
  readonly userId: number;
  readonly numItems: number;
}

export function generatePortfolioObjects({
  userId,
  numItems = 5,
}: GeneratePortfoliosOptions): readonly PortfolioItem[] {
  return new Array(numItems).fill(undefined).map((_, i) => {
    const title = getTitleText(i);
    return {
      id: generateId(),
      userId,
      contentType: ContentTypeApi.PICTURE,
      description: getRandomText(),
      featured: false,
      files: [],
      articles: [],
      skillIds: [phpSkill().id],
      categories: [],
      title,
      seoTitle: transformPortfolioSeoTitle(userId, title),
      lastModifyDate: Date.now(),
      position: '',
    };
  });
}
export interface GeneratePortfolioOptions {
  readonly id?: number;
  readonly userId: number;
  readonly contentType?: ContentTypeApi;
  readonly description?: string;
  readonly featured?: boolean;
  readonly files?: readonly PortfolioFile[];
  readonly articles?: readonly PortolioArticleFile[];
  readonly skillIds?: readonly number[];
  readonly categories?: readonly number[];
  readonly title?: string;
  readonly seoTitle?: string;
  readonly lastModifyDate?: number;
  readonly position?: string;
}

export interface GenerateCategoryOptions {
  readonly id?: number;
  readonly userId: number;
  readonly name: string;
  readonly lastUpdated: number;
  readonly position: number;
  readonly portfolioItems?: readonly number[];
  readonly skills?: readonly number[];
}

export function generatePortfolioObject({
  id = generateId(),
  userId,
  contentType = ContentTypeApi.PICTURE,
  description = 'This is an example of my work.',
  featured = false,
  files = [generatePortfolioFile()],
  articles = [],
  skillIds = [phpSkill().id],
  categories = [],
  title = 'A portfolio item',
  seoTitle = transformPortfolioSeoTitle(userId, title),
  lastModifyDate = Date.now(),
  position = '',
}: GeneratePortfolioOptions): PortfolioItem {
  return {
    id,
    userId,
    contentType,
    description,
    featured,
    files,
    articles,
    skillIds,
    categories,
    title,
    seoTitle,
    lastModifyDate,
    position,
  };
}

export function generateCategoryObject({
  id = generateId(),
  userId,
  name = getTitleText(0),
  lastUpdated = Date.now(),
  position = 0,
  portfolioItems = [],
  skills = [],
}: GenerateCategoryOptions): Omit<ProfileCategory, 'portfolioItems'> & {
  readonly portfolioItems: readonly PortfolioItem[];
} {
  return {
    id,
    userId,
    name,
    lastUpdated,
    position,
    portfolioItems: portfolioItems.map(itemId =>
      generatePortfolioObject({ id: itemId, userId }),
    ),
    skills,
  };
}

export interface GeneratePortfolioFileOptions {
  readonly cdnUrl?: string;
  readonly description?: string;
  readonly filename?: string;
  readonly thumbnails?: readonly PortfolioFileThumbnail[];
}

export function generatePortfolioFile({
  cdnUrl = generatePortfolioFileUrl(0),
  description = 'Portfolio file 0',
  filename = 'portfolio-item-0.jpg',
  thumbnails,
}: GeneratePortfolioFileOptions = {}): PortfolioFile {
  const fileId = generateId();
  return {
    id: generateId(),
    cdnUrl,
    description,
    fileId,
    filename,
    thumbnails: thumbnails ?? [
      generatePortfolioFileThumbnail({ portfolioFileId: fileId }),
    ],
  };
}

export interface GeneratePortfolioFileThumbnailOptions {
  readonly portfolioFileId: number;
  readonly cdnUrl?: string;
  readonly height?: number;
  readonly width?: number;
}

function generatePortfolioFileThumbnail({
  portfolioFileId,
  cdnUrl = generatePortfolioFileUrl(0),
  width = 380,
  height = 285,
}: GeneratePortfolioFileThumbnailOptions): PortfolioFileThumbnail {
  return {
    portfolioFileId,
    cdnUrl,
    fileId: generateId(),
    filename: 'portfolio-item-thumb-0.jpg',
    height,
    width,
  };
}

export function generatePortfolioFileUrl(id: number, size = 100): string {
  return `https://cdn4.f-cdn.com/assets/portfolio/thumb-${id + 1}.jpg`;
}
