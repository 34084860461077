import { generateId } from '@freelancer/datastore/testing/helpers';
import type { Project } from '../projects';
import type { ManageViewOngoingBid } from './manage-view-ongoing-bids.model';

export interface GenerateManageViewOngoingBidOptions {
  readonly project: Project;
  readonly milestoneCount: number;
  readonly milestoneAmount: number;
  readonly deadline: number;
  readonly bidAmount: number;
}

export function generateManageViewOngoingBidObject({
  project,
  milestoneCount,
  milestoneAmount,
  deadline,
  bidAmount,
}: GenerateManageViewOngoingBidOptions): ManageViewOngoingBid {
  return {
    id: generateId(),
    projectId: project.id,
    employerId: project.ownerId,
    recruiter: project.upgrades.assisted,
    milestoneCount,
    milestoneAmount,
    deadline,
    bidAmount,
  };
}

export interface GenerateManageViewOngoingBidsOptions {
  readonly projects: readonly Project[];
}

export function generateManageViewOngoingBidObjects({
  projects = [],
}: GenerateManageViewOngoingBidsOptions): readonly ManageViewOngoingBid[] {
  return projects.map(project =>
    generateManageViewOngoingBidObject({
      project,
      milestoneCount: 0,
      milestoneAmount: 0,
      deadline: Date.now(),
      bidAmount: 10,
    }),
  );
}
