import { DOCUMENT } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

// /!\ DO NOT ADD ANYTHING IN THERE WITHOUT TALKING TO BITS TEAM FIRST /!\
// \!/                      hzhang || leo                         \!/

@Component({
  selector: 'app-root',
  template: `
    <fl-seo />
    <fl-location />
    <fl-container>
      <fl-toast-alert-container />
    </fl-container>
    <router-outlet />
    <fl-modal />
    <fl-toast-alert-global />
    <fl-theme />
  `,
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.document.getElementById('__pwa_loader_overlay__')?.remove();
  }
}
