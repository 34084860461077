import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TabsColor, TabsSize } from '@freelancer/ui/tabs';

@Component({
  selector: 'app-nav-tabs',
  template: `
    <fl-tabs
      [color]="TabsColor.LIGHT"
      [size]="size"
      [sizeTablet]="sizeTablet"
      [sizeDesktop]="sizeDesktop"
    >
      <fl-tab-item
        titleText="Introduction"
        link="/info"
        [routeHasChildren]="true"
      ></fl-tab-item>
      <fl-tab-item
        titleText="Foundations"
        link="/foundations"
        [routeHasChildren]="true"
      ></fl-tab-item>
      <fl-tab-item
        titleText="Components"
        link="/components"
        [routeHasChildren]="true"
      ></fl-tab-item>
      <fl-tab-item
        titleText="Patterns"
        link="/patterns"
        [routeHasChildren]="true"
      ></fl-tab-item>
      <fl-tab-item
        titleText="Directives"
        link="/directives"
        [routeHasChildren]="true"
      ></fl-tab-item>
      <fl-tab-item
        titleText="Pipes"
        link="/pipes"
        [routeHasChildren]="true"
      ></fl-tab-item>
      <fl-tab-item
        titleText="Motion"
        link="/motion"
        [routeHasChildren]="true"
      ></fl-tab-item>
      <fl-tab-item
        titleText="Brand"
        link="/brand"
        [flHide]="hideInternalPages"
        [routeHasChildren]="true"
      ></fl-tab-item>
    </fl-tabs>
  `,
  styleUrls: ['./nav-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavTabsComponent {
  TabsColor = TabsColor;
  TabsSize = TabsSize;

  @Input() size: TabsSize = TabsSize.XLARGE;
  @Input() sizeTablet: TabsSize;
  @Input() sizeDesktop: TabsSize;
  @Input() hideInternalPages: boolean;
}
