import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  ContractContextTypeApi,
  ContractTypeApi,
} from 'api-typings/users/users';
import type { Contract } from './contracts.model';

export interface GenerateContractOptions {
  readonly contractType: ContractTypeApi;
  readonly contextType: ContractContextTypeApi;
  readonly contextId: number;
  readonly displayName: string;
  readonly customContractId?: number;
  readonly userIdTimeSignedMap?: {
    readonly [k in number]?: number;
  };
}

export function generateContractObject({
  contractType,
  contextType,
  contextId,
  displayName,
  customContractId = generateId(),
  userIdTimeSignedMap = {},
}: GenerateContractOptions): Contract {
  return {
    // from transformer
    id: [customContractId, contextType, contextId].join('-'),
    contractType,
    contextType,
    contextId,
    displayName,
    customContractId,
    userIdTimeSignedMap,
  };
}

// Mixins

export function contestIpContract(
  contestEntryId: number,
): Pick<
  Required<GenerateContractOptions>,
  'contractType' | 'contextType' | 'contextId' | 'displayName'
> {
  return {
    contractType: ContractTypeApi.CONTEST_IP,
    contextType: ContractContextTypeApi.CONTEST_ENTRY,
    contextId: contestEntryId,
    displayName: 'Contest IP Agreement',
  };
}

export function signedBy(
  userIds: readonly number[],
): Pick<Required<GenerateContractOptions>, 'userIdTimeSignedMap'> {
  return {
    userIdTimeSignedMap: Object.fromEntries(
      userIds.map(userId => [userId, Date.now()]),
    ),
  };
}
