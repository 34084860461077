import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontColor, FontType, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-brand-style-guide-colours',
  template: `
    <div class="Colours">
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Primary colours
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        The primary colour palette is black, hummingbird blue and flamingo pink.
        The Freelancer brand celebrates a diverse and global culture, and is
        always bold and vibrant. However, we also remain grounded with these
        three primary colours as our core palette.
      </fl-text>

      <fl-grid>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <fl-picture
            class="ColourImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/black.png'"
          ></fl-picture>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <fl-picture
            class="ColourImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/h-bird.png'"
          ></fl-picture>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <fl-picture
            class="ColourImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/flamingo.png'"
          ></fl-picture>
        </fl-col>
      </fl-grid>

      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Secondary colours
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Our brand communications must also must also allow us to design with a
        bold and colourful expression. Aside from the three primary colours
        outlined above, we also use a varied and nuanced communications palette,
        with just enough options to keep things feeling fresh, yet coherent.
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        These colours have also been selected to be bold and vibrant. It is
        important that you don't use colours in a brand communication that is
        not in the colour palette. In order to maintain this bold, vibrant look
        to our design it is important not to use tints on branded
        communications.
      </fl-text>
      <fl-grid>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <fl-picture
            class="ColourImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/orange.png'"
          ></fl-picture>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <fl-picture
            class="ColourImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/cardinal.png'"
          ></fl-picture>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <fl-picture
            class="ColourImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/starling.png'"
          ></fl-picture>
        </fl-col>
      </fl-grid>
      <fl-grid>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <fl-picture
            class="ColourImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/king.png'"
          ></fl-picture>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <fl-picture
            class="ColourImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/canary.png'"
          ></fl-picture>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <fl-picture
            class="ColourImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/parrot.png'"
          ></fl-picture>
        </fl-col>
      </fl-grid>
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Applying colours
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        We have a diverse colour palette to suit various themes, but it's
        crucial to ensure that one specific colour serves as the dominant visual
        anchor on the page. Monotones (black and white) are not considered this
        dominant colour.
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Note that you may always use multiple colours in a branded
        communication, just ensure that there is one that is visually dominant.
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [fontType]="FontType.CONTAINER"
        [flMarginBottom]="'xlarge'"
      >
        <fl-list
          [type]="ListItemType.UNORDERED"
          [flMarginBottom]="'small'"
        >
          <fl-list-item>Text is usually black or white.</fl-list-item>
          <fl-list-item>Highlighted text can be pink or blue</fl-list-item>
          <fl-list-item
            >Backgrounds are either black, white or an image.
          </fl-list-item>
          <fl-list-item
            >Colour usually comes from imagery or illustration.
          </fl-list-item>
        </fl-list>
      </fl-text>
      <fl-picture
        [src]="'brand-guidelines/colour-bar.png'"
        [fullWidth]="true"
      ></fl-picture>
    </div>
  `,
  styleUrls: ['./../brand-style-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandStyleGuideColoursComponent {
  FontColor = FontColor;
  FontWeight = FontWeight;
  FontType = FontType;
  ListItemType = ListItemType;
  TextSize = TextSize;
}
