import { generateId } from '@freelancer/datastore/testing/helpers';
import type { RichTextElement } from '@freelancer/html-renderer';
import {
  AttachedByTypeApi,
  AttachmentAttachedToTypeApi,
  AttachmentTypeApi,
  TextEntityContextTypeApi,
  TextEntityReferenceTypeApi,
} from 'api-typings/common/common';
import type { FeedContextTypeApi } from 'api-typings/feed/feed';
import { FeedReferenceTypeApi } from 'api-typings/feed/feed';
import type { PostAttachmentApi } from 'api-typings/posts/posts';
import { PostOwnerTypeApi } from 'api-typings/posts/posts';
import {
  generateParagraphHtmlElementObject,
  generateTextHtmlElementObject,
} from '../content-customization';
import type { FeedItem } from '../feed/feed.model';
import type { AttachmentFile } from '../post-files/post-files.model';
import type { Post, TextEntity } from './feed-posts.model';

export interface GenerateFeedPostsOptions {
  readonly id?: number;
  readonly userId: number;
  readonly content?: string;
  readonly contentRichText?: readonly RichTextElement[];
  readonly commentingDisabled?: boolean;
  readonly attachments?: readonly PostAttachmentApi[];
  readonly attachmentFiles?: readonly AttachmentFile[];
  readonly textEntities?: readonly TextEntity[];
  readonly ownerType?: PostOwnerTypeApi;
  readonly ownerId?: number;
  readonly createdTime?: number;
  readonly commentFeedId?: number;
}

export function generateFeedPostObjects({
  id,
  userId,
  content,
  contentRichText,
  commentingDisabled = false,
  attachments = [],
  textEntities = [],
  ownerType,
  ownerId,
  createdTime = Date.now(),
  commentFeedId,
  attachmentFiles = [],
}: GenerateFeedPostsOptions): Post {
  const postId = id ?? generateId();

  return {
    id: postId,
    content: content || '',
    contentRichText:
      contentRichText ??
      (content
        ? [
            generateParagraphHtmlElementObject([
              generateTextHtmlElementObject(content),
            ]),
          ]
        : []),
    attachments: attachments.map(attachment => ({
      id: generateId(),
      attachedToType: AttachmentAttachedToTypeApi.POST,
      attachedToId: postId,
      attachedObjectType:
        attachment.attached_object_type || AttachmentTypeApi.FILE,
      attachedObjectId: attachment.attached_object_id,
      attachedByType: AttachedByTypeApi.USER,
      attachedById: userId,
      attachmentUuid: attachment.attachment_uuid,
      position: attachment.position ?? -1,
      created: createdTime,
    })),
    attachmentFiles: attachmentFiles.map(attachmentFile => ({
      id: attachmentFile.id ?? generateId().toString(),
      userId: attachmentFile.userId,
      created: attachmentFile.created,
      fileName: attachmentFile.fileName,
      fileSize: attachmentFile.fileSize,
      isTemp: attachmentFile.isTemp,
      updated: attachmentFile.updated,
      contentType: attachmentFile.contentType,
      s3Bucket: attachmentFile.s3Bucket,
      s3Obj: attachmentFile.s3Obj,
    })),
    textEntities: textEntities.map(textEntity => ({
      id: generateId(),
      offset: textEntity.offset,
      length: textEntity.length,
      referenceType: textEntity.referenceType,
      referenceId: textEntity.referenceId,
      contextType: TextEntityContextTypeApi.POST,
      contextId: postId,
    })),
    commentingDisabled,
    ownerType,
    ownerId,
    created: createdTime,
    updated: createdTime,
    deleted: undefined,
    commentFeedId,
  };
}

export function feedPostToFeedItemTransformer(
  post: Post,
  extraParams: {
    readonly feedContextType: FeedContextTypeApi;
    readonly feedContextId: number;
    readonly feedMetaId: number;
  },
): FeedItem {
  return {
    id: generateId(),
    contextType: extraParams.feedContextType,
    contextId: extraParams.feedContextId,
    feedMetaId: extraParams.feedMetaId,
    created: post.created,
    updated: post.updated,
    referenceType: FeedReferenceTypeApi.POST,
    referenceId: post.id,
  };
}

export function groupMemberFeedPost(
  groupMemberId: number,
): Pick<GenerateFeedPostsOptions, 'ownerType' | 'ownerId'> {
  return {
    ownerType: PostOwnerTypeApi.GROUP_MEMBER,
    ownerId: groupMemberId,
  };
}

export function textEntityUserPost(): Pick<
  TextEntity,
  'id' | 'referenceType' | 'contextType' | 'offset'
> {
  return {
    id: generateId(),
    referenceType: TextEntityReferenceTypeApi.USER,
    contextType: TextEntityContextTypeApi.POST,
    offset: 0,
  };
}

export function textEntityUserComment(): Pick<
  TextEntity,
  'id' | 'offset' | 'referenceType' | 'contextType'
> {
  return {
    id: generateId(),
    offset: 0,
    referenceType: TextEntityReferenceTypeApi.USER,
    contextType: TextEntityContextTypeApi.POST,
  };
}

export function mentionedUserTextEntityContent(
  content: string,
  mentionedUserIds: readonly number[],
): Pick<GenerateFeedPostsOptions, 'content' | 'textEntities'> {
  if (mentionedUserIds.length !== 1) {
    throw new Error('Only implemented for one user to be mentioned');
  }
  return {
    content,
    textEntities: [
      ...mentionedUserIds.map((mentionedUserId, offset) => ({
        ...textEntityUserPost(),
        offset,
        length: content.length,
        referenceId: mentionedUserId,
      })),
    ],
  };
}

export function mentionedProjectTextEntity(
  content: string,
  mentionedProjectId: number,
): Pick<GenerateFeedPostsOptions, 'textEntities'> {
  return {
    textEntities: [
      {
        id: generateId(),
        offset: 0,
        length: content.length,
        referenceId: mentionedProjectId,
        referenceType: TextEntityReferenceTypeApi.PROJECT,
        contextType: TextEntityContextTypeApi.POST,
      },
    ],
  };
}

export function feedPostFileAttachments(
  files: readonly AttachmentFile[],
  filesToFilesOrderMap?: { readonly [fileId: string]: number },
): {
  readonly attachments: readonly PostAttachmentApi[];
  readonly attachmentFiles: readonly AttachmentFile[];
} {
  // GenerateFeedPostsOptions uses PostAttachmentApi so return this instead of Attachment. Its probably better to
  // add fields like attachedToType in generateFeedPostObjects rather than here too
  const feedPostFiles = files.map((file, i) => {
    return {
      attached_object_type: AttachmentTypeApi.FILE,
      attached_object_id: file.id,
      attachment_uuid: file.id,
      position: filesToFilesOrderMap ? filesToFilesOrderMap[file.id] : i + 1,
    };
  });

  // Re-sort the files by position if we have indexes.
  if (filesToFilesOrderMap) {
    feedPostFiles.sort((a, b) => a.position - b.position);
  }

  return {
    attachments: feedPostFiles,
    attachmentFiles: files,
  };
}
