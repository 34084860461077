import { isPlatformBrowser } from '@angular/common';
import type { DatastoreFake } from './datastore';
// eslint-disable-next-line local-rules/no-enable-debug-mode
import { disableDebugMode, enableDebugMode } from './datastore.helpers';

/** Make datastore methods available in the browser */
export function publishGlobalUtils(datastore: DatastoreFake): void {
  if (isPlatformBrowser(datastore.platformId)) {
    putObjectsOnWindow({
      datastore: {
        // These types are a lie
        collection: (...args: [any]) => datastore.collection(...args),
        document: (...args: [any]) => datastore.document(...args),
        createDocument: (...args: [any, any]) =>
          datastore.createDocument(...args),
        printState: (...args: [any]) => datastore.printState(...args),
        printRawState: () => datastore.printRawState(),
        resetState: (...args: [any]) => datastore.resetState(...args),
        enableDebugMode: () => enableDebugMode(),
        disableDebugMode: () => disableDebugMode(),
      },
    });
  }
}

/** Make datastore collection factory methods available in the browser */
export function publishDatastoreCollectionFactoryFunctions(
  datastoreFunctions: Object,
  platformId: Object,
): void {
  if (isPlatformBrowser(platformId)) {
    putObjectsOnWindow(datastoreFunctions);
  }
}

function putObjectsOnWindow(object: object): void {
  Object.entries(object).forEach(([name, fn]) => {
    (window as any)[name] = fn;
  });
}
