import type { CampaignApi } from 'api-typings/users/users';
import type { Campaign } from './campaigns.model';

export function transformCampaigns(campaign: CampaignApi): Campaign {
  return {
    id: campaign.id,
    internalName: campaign.internal_name,
    description: campaign.description,
    startDate: campaign.start_date ? campaign.start_date * 1000 : undefined,
    endDate: campaign.end_date ? campaign.end_date * 1000 : undefined,
    created: campaign.created * 1000,
  };
}
