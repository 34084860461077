import { TimeUnitApi } from 'api-typings/common/common';
import type { MembershipTrial } from './membership-trials.model';

export function generateMembershipTrialsObjects(): readonly MembershipTrial[] {
  return [
    {
      id: 1,
      packageId: 5,
      duration: {
        id: 3,
        type: TimeUnitApi.MONTH,
        cycle: 1,
      },
      eligible: true,
      trialPackageId: 5,
      trialDuration: {
        id: 3,
        type: TimeUnitApi.MONTH,
        cycle: 1,
      },
      timeValidStart: Date.now(),
      isDefault: true,
      package: {
        id: 5,
        internalName: 'plan25',
        displayName: 'plus',
        enabled: true,
        categoryId: 1,
        order: 4000,
      },
      trialPackage: {
        id: 5,
        internalName: 'plan25',
        displayName: 'plus',
        enabled: true,
        categoryId: 1,
        order: 4000,
      },
    },
    {
      id: 3,
      packageId: 5,
      duration: {
        id: 9,
        type: TimeUnitApi.YEAR,
        cycle: 1,
      },
      eligible: true,
      trialPackageId: 5,
      trialDuration: {
        id: 3,
        type: TimeUnitApi.MONTH,
        cycle: 1,
      },
      timeValidStart: Date.now(),
      isDefault: true,
      package: {
        id: 5,
        internalName: 'plan25',
        displayName: 'plus',
        enabled: true,
        categoryId: 1,
        order: 4000,
      },
      trialPackage: {
        id: 5,
        internalName: 'plan25',
        displayName: 'plus',
        enabled: true,
        categoryId: 1,
        order: 4000,
      },
    },
    ...generateLoadshiftMembershipTrialsV1Objects(),
    ...generateProfessionalMembershipTrialsObjects(),
  ];
}

export function generateLoadshiftMembershipTrialsV1Objects(): readonly MembershipTrial[] {
  return [
    {
      id: 20,
      packageId: 117,
      duration: {
        id: 3,
        type: TimeUnitApi.MONTH,
        cycle: 1,
      },
      package: {
        id: 117,
        internalName: 'loadshift_membership_v1',
        displayName: 'Loadshift',
        enabled: true,
        categoryId: 17,
        order: 0,
      },
      trialPackage: {
        id: 118,
        internalName: 'loadshift_membership_free_trial_v1',
        displayName: 'Loadshift Membership Free Trial',
        enabled: true,
        categoryId: 17,
        order: 0,
      },
    },
  ];
}

export function generateProfessionalMembershipTrialsObjects(): readonly MembershipTrial[] {
  return [
    {
      id: 8,
      packageId: 8,
      duration: {
        id: 3,
        type: TimeUnitApi.MONTH,
        cycle: 1,
      },
      eligible: true,
      trialPackageId: 5,
      trialDuration: {
        id: 3,
        type: TimeUnitApi.MONTH,
        cycle: 1,
      },
      timeValidStart: Date.now(),
      isDefault: true,
      package: {
        id: 5,
        internalName: 'plan30_1',
        displayName: 'professional',
        enabled: true,
        categoryId: 1,
        order: 5001,
      },
      trialPackage: {
        id: 8,
        internalName: 'plan30_1',
        displayName: 'professional',
        enabled: true,
        categoryId: 1,
        order: 5001,
      },
    },
  ];
}
