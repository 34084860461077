export interface User {
  username?: string;
  avatar?: string;
  avatarLarge?: string;
  avatarXLarge?: string;
}

export enum AvatarSize {
  XXSMALL = 'xxsmall',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MID = 'mid',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
  MAX = 'max',
}
