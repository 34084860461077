import type { ProjectBookmarkType } from './project-bookmarks.backend-model';
import type { ProjectBookmark } from './project-bookmarks.model';

export interface GenerateProjectBookmarkOptions {
  readonly projectId: number;
  readonly type: ProjectBookmarkType;
  readonly subscribed?: boolean;
}

export function generateProjectBookmarkObject({
  projectId,
  type,
  subscribed = false,
}: GenerateProjectBookmarkOptions): ProjectBookmark {
  return {
    id: `${type}-${projectId}`, // see transformer
    projectId,
    type,
    subscribed,
  };
}

export interface GenerateProjectBookmarksOptions {
  readonly projectIds: readonly number[];
  readonly type: ProjectBookmarkType;
  readonly subscribed?: boolean;
}

export function generateProjectBookmarkObjects({
  projectIds,
  type,
  subscribed = false,
}: GenerateProjectBookmarksOptions): readonly ProjectBookmark[] {
  return projectIds.map(projectId => ({
    id: `${type}-${projectId}`, // see transformer
    projectId,
    type,
    subscribed,
  }));
}
