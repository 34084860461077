import { generateId } from '@freelancer/datastore/testing/helpers';
import { assertNever } from '@freelancer/utils';
import { DiscoverCollectionTypeApi } from 'api-typings/users/users';
import type { DiscoverCollection } from '../discover-collections/discover-collections.model';
import type { PredefinedDiscoverCollection } from './predefined-discover-collections.model';

/**
 * Seed functions for Predefined Discover Collections.
 * 'Predefined' collections include MY_HIRES_LIST, MY_CLIENTS_LIST, LISTS_RECENTLY_VIEWED_USERS, FAVORITES_LIST & LISTS_GROUP_FEED_ITEMS.
 * A mock DiscoverCollection document should be initialised concurrently with mock
 * DiscoverCollectionItem documents. Therefore, it is important that the `itemCount`
 * is consistent with the total number of collection items you wish to initially create.
 */
export type DiscoverCollectionDetails = Pick<
  DiscoverCollection,
  'name' | 'description' | 'seoUrl'
>;

export type PredefinedDiscoverCollectionType = Exclude<
  DiscoverCollectionTypeApi,
  | DiscoverCollectionTypeApi.CUSTOM
  | DiscoverCollectionTypeApi.FAVORITES
  | DiscoverCollectionTypeApi.LISTS_CUSTOM
>;

export interface GeneratePredefinedDiscoverCollectionOptions {
  readonly type: PredefinedDiscoverCollectionType;
  readonly itemCount: number;
  readonly dateCreated?: number;
  readonly lastUpdated?: number;
  readonly previewItems?: readonly string[];
  readonly ownerId?: number;
  readonly isPrivate?: boolean;
  readonly isDeleted?: boolean;
}

function getCollectionDetails(
  type: PredefinedDiscoverCollectionType,
): DiscoverCollectionDetails {
  switch (type) {
    case DiscoverCollectionTypeApi.LISTS_FAVORITES:
      return {
        name: 'Favorites',
        description: '',
        seoUrl: 'favorites',
      };
    case DiscoverCollectionTypeApi.LISTS_MY_HIRES:
      return {
        name: 'My Hires',
        description:
          "Here you can find all freelancers who you've worked with in the past.",
        seoUrl: 'my-hires',
      };
    case DiscoverCollectionTypeApi.LISTS_MY_CLIENTS:
      return {
        name: 'My Clients',
        description:
          "Here you can find all employers who you've worked with in the past.",
        seoUrl: 'my-clients',
      };
    case DiscoverCollectionTypeApi.LISTS_RECENTLY_VIEWED_USERS:
      return {
        name: 'Recently Viewed Users',
        description:
          'Here you can find users whose profiles you have recently viewed.',
        seoUrl: 'recently-viewed-users',
      };
    case DiscoverCollectionTypeApi.LISTS_GROUP_FEED_ITEMS:
      return {
        name: 'Bookmarked Group Posts',
        description:
          'Here you can find all group posts that you have bookmarked.',
        seoUrl: 'bookmarked-group-posts',
      };
    default:
      assertNever(
        type,
        `case statement missing for Discover Collection of type ${type}. Please update the switch statement to include your new type.`,
      );
  }
}

export function generatePredefinedDiscoverCollectionObject({
  type,
  itemCount,
  isDeleted = false,
  isPrivate = true,
  ownerId = generateId(),
  dateCreated = Date.now(),
  lastUpdated = Date.now(),
  previewItems = [],
}: GeneratePredefinedDiscoverCollectionOptions): PredefinedDiscoverCollection {
  const details: DiscoverCollectionDetails = getCollectionDetails(type);
  return {
    id: generateId(),
    type,
    itemCount,
    ownerId,
    ...details,
    dateCreated,
    lastUpdated,
    isDeleted,
    isPrivate,
    previewItems,
  };
}

export function listsFavoritesCollection(): Pick<
  GeneratePredefinedDiscoverCollectionOptions,
  'type'
> {
  return {
    type: DiscoverCollectionTypeApi.LISTS_FAVORITES,
  };
}

export function hiresCollection(): Pick<
  GeneratePredefinedDiscoverCollectionOptions,
  'type'
> {
  return {
    type: DiscoverCollectionTypeApi.LISTS_MY_HIRES,
  };
}

export function clientsCollection(): Pick<
  GeneratePredefinedDiscoverCollectionOptions,
  'type'
> {
  return { type: DiscoverCollectionTypeApi.LISTS_MY_CLIENTS };
}

export function recentlyViewedCollection(): Pick<
  GeneratePredefinedDiscoverCollectionOptions,
  'type'
> {
  return { type: DiscoverCollectionTypeApi.LISTS_RECENTLY_VIEWED_USERS };
}

export function groupFeedCollection(): Pick<
  GeneratePredefinedDiscoverCollectionOptions,
  'type'
> {
  return { type: DiscoverCollectionTypeApi.LISTS_GROUP_FEED_ITEMS };
}
