import { generateId } from '@freelancer/datastore/testing/helpers';
import { ReferralInvitationStatusApi } from 'api-typings/common/common';
import type { ReferralInvitation } from './referral-invitations.model';

export interface GenerateReferralInvitationOptions {
  readonly id?: string;
  readonly email?: string;
  readonly status?: ReferralInvitationStatusApi;
  readonly canResend?: boolean;
}

export function generateReferralInvitations(
  referralInvitationOptions: readonly GenerateReferralInvitationOptions[],
): readonly ReferralInvitation[] {
  return referralInvitationOptions.map(referralInvitationOption =>
    generateReferralInvitationObject(referralInvitationOption),
  );
}

export function generateReferralInvitationObject({
  id = String(generateId()),
  email = `test${id}@email.com`,
  status = ReferralInvitationStatusApi.BONUS_PENDING,
  canResend = false,
}: GenerateReferralInvitationOptions): ReferralInvitation {
  return {
    id,
    email,
    status,
    canResend,
  };
}
