import type { OperatingArea } from './operating-areas.model';

export function generateOperatingAreaObjects(): readonly OperatingArea[] {
  return regions;
}

const regions = [
  nswWestRegion(),
  nswSouthRegion(),
  nswSydneyRegion(),
  saAdelaideRegion(),
  saNorthRegion(),
  qldBrisbaneRegion(),
  vicMelbourneRegion(),
  vicEastRegion(),
  tasmaniaRegion(),
] as const;

export function nswWestRegion(): OperatingArea {
  return {
    id: 16,
    type: 'region',
    region: 'West',
    stateLongName: 'New South Wales',
    stateShortName: 'NSW',
    countryCode: 'AU',
  };
}

export function nswSydneyRegion(): OperatingArea {
  return {
    id: 13,
    type: 'region',
    region: 'Sydney Region',
    stateLongName: 'New South Wales',
    stateShortName: 'NSW',
    countryCode: 'AU',
  };
}

export function nswSouthRegion(): OperatingArea {
  return {
    id: 15,
    type: 'region',
    region: 'South',
    stateLongName: 'New South Wales',
    stateShortName: 'NSW',
    countryCode: 'AU',
  };
}

export function saAdelaideRegion(): OperatingArea {
  return {
    id: 27,
    type: 'region',
    region: 'Adelaide Region',
    stateLongName: 'South Australia',
    stateShortName: 'SA',
    countryCode: 'AU',
  };
}

export function saNorthRegion(): OperatingArea {
  return {
    id: 28,
    type: 'region',
    region: 'North',
    stateLongName: 'South Australia',
    stateShortName: 'SA',
    countryCode: 'AU',
  };
}

export function qldBrisbaneRegion(): OperatingArea {
  return {
    id: 10,
    type: 'region',
    region: 'Brisbane Region',
    stateLongName: 'Queensland',
    stateShortName: 'QLD',
    countryCode: 'AU',
  };
}

export function vicMelbourneRegion(): OperatingArea {
  return {
    id: 18,
    type: 'region',
    region: 'Melbourne',
    stateLongName: 'Victoria',
    stateShortName: 'VIC',
    countryCode: 'AU',
  };
}

export function vicEastRegion(): OperatingArea {
  return {
    id: 17,
    type: 'region',
    region: 'East',
    stateLongName: 'Victoria',
    stateShortName: 'VIC',
    countryCode: 'AU',
  };
}

export function tasmaniaRegion(): OperatingArea {
  return {
    id: 33,
    type: 'region',
    region: 'Tasmania',
    stateLongName: 'Tasmania',
    stateShortName: 'TAS',
    countryCode: 'AU',
  };
}
