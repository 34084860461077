import { generateId } from '@freelancer/datastore/testing/helpers';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { ContestFee } from './contest-fees.model';

export interface GenerateContestFeesOptions {
  readonly currencyCode?: CurrencyCode;
  readonly contestId?: number;
  readonly highlightedEntryPrice?: number;
  readonly sealedEntryPrice?: number;
  readonly freeTopFreelancerUpgrade?: boolean;
}

// TODO: T267853 - Other currencies than USD
export function generateContestFeesObject({
  currencyCode = CurrencyCode.USD,
  contestId = generateId(),
  highlightedEntryPrice = 0.55,
  sealedEntryPrice = 0.55,
  freeTopFreelancerUpgrade = false,
}: GenerateContestFeesOptions = {}): ContestFee {
  return {
    id: `${contestId}`,
    contestId,
    currencyId: generateCurrencyObject(currencyCode).id,
    featuredPrice: 33,
    topContestPrice: 33,
    highlightPrice: 16.5,
    sealedPrice: 33,
    ndaPrice: 22,
    privatePrice: 27.500_000_000_000_004,
    urgentPrice: 38.5,
    extendedPrice: {
      three_days: 29.68,
      five_days: 39.899_999_999_999_999,
      seven_days: 60.350_000_000_000_001,
      fourteen_days: 79.799_999_999_999_997,
      twenty_one_days: 100.25,
    },
    customJudgingPrice: 21,
    highlightedEntryPrice,
    sealedEntryPrice,
    freeTopFreelancerUpgrade,
  };
}

// Mixins

export function memberFee(): Pick<
  ContestFee,
  'highlightedEntryPrice' | 'sealedEntryPrice'
> {
  return {
    highlightedEntryPrice: 0,
    sealedEntryPrice: 0,
  };
}

export function topFreelancerFee(): Pick<
  ContestFee,
  'freeTopFreelancerUpgrade'
> {
  return {
    freeTopFreelancerUpgrade: true,
  };
}
