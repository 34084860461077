import { enumToArray } from '@freelancer/utils';
import type { Country } from './countries.model';
import { countries, CountryCode } from './countries.model';

export interface GenerateCountriesOptions {
  readonly countryCodes?: readonly CountryCode[];
}

export interface GenerateCountryOptions {
  readonly countryCode?: CountryCode;
}

/**
 * WARNING: Only use this for a Location object that is returned by the REST
 * `countries` endpoint. It is different when returned by a `users` endpoint.
 * @see project-view-users.seed.ts#generateProjectViewUserCountry
 */
export function generateCountryObjects({
  countryCodes = enumToArray(CountryCode),
}: GenerateCountriesOptions = {}): readonly Country[] {
  return countryCodes.map(code => countries[code]);
}

export function generateCountryObject({
  countryCode = CountryCode.US,
}: GenerateCountryOptions = {}): Country {
  return countries[countryCode];
}
