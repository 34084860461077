import { defaultPredictedSkills } from '../skills/skills.seed';
import type { SuggestedSkills } from './suggested-skills.model';

export interface GenerateSuggestedSkillsOptions {
  readonly skillIds: readonly number[];
  readonly suggestedSkillIds?: readonly number[];
}

export function generateSuggestedSkillsObject({
  skillIds,
  suggestedSkillIds = defaultPredictedSkills.map(skill => skill.id),
}: GenerateSuggestedSkillsOptions): SuggestedSkills {
  return {
    id: skillIds.join('-'),
    skills: skillIds,
    suggestedSkills: suggestedSkillIds,
  };
}
