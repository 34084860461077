import {
  ReactionContextTypeApi,
  ReactionTypeApi,
} from 'api-typings/common/common';
import type { FeedItem } from '../feed';
import type { ReactionTypeCount } from './reactions-type-count.model';
import { transformReactionTypeCountId } from './reactions-type-count.transformers';

export interface GenerateReactionTypeCountObject {
  readonly contextType: ReactionContextTypeApi;
  readonly contextId: number;
  readonly count?: number;
  readonly reactionType?: ReactionTypeApi;
  readonly hasUserReacted?: boolean;
}

export function generateReactionTypeCountObject({
  contextType,
  contextId,
  count = 0,
  reactionType = ReactionTypeApi.LIKE,
  hasUserReacted = false,
}: GenerateReactionTypeCountObject): ReactionTypeCount {
  return {
    id: transformReactionTypeCountId(contextType, contextId, reactionType),
    contextId,
    contextType,
    count,
    reactionType,
    hasUserReacted,
  };
}

export function generateReactionTypeCountObjects(
  options: readonly GenerateReactionTypeCountObject[],
): readonly ReactionTypeCount[] {
  return options.map(generateReactionTypeCountObject);
}

export function reactionForPost(
  feedItem: Pick<FeedItem, 'id'>,
): Pick<GenerateReactionTypeCountObject, 'contextId' | 'contextType'> {
  return {
    contextId: feedItem.id,
    contextType: ReactionContextTypeApi.POST,
  };
}
