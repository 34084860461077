import type { RichTextElement } from '@freelancer/html-renderer';
import type {
  ContestStatusApi,
  ContestTypeApi,
} from 'api-typings/contests/contests';
import { EntryFileFormatApi } from 'api-typings/contests/contests';
import type { Contest, ContestUpgrade } from '../contests/contests.model';
import type { GenerateContestOptions } from '../contests/contests.seed';
import { generateContestObject } from '../contests/contests.seed';
import type { CurrencyCode } from '../currencies';
import type { Skill } from '../skills/skills.model';
import type { ContestViewContest } from './contest-view-contests.model';

export interface GenerateContestViewContestOptions
  extends GenerateContestOptions {
  readonly ownerId: number;
  readonly contestId?: number;
  readonly type?: ContestTypeApi;
  readonly currencyCode?: CurrencyCode;
  readonly description?: string;
  readonly status?: ContestStatusApi;
  readonly skills?: readonly Skill[];
  readonly title?: string;
  readonly entryCount?: number;
  readonly duration?: number;
  readonly upgrades?: Partial<ContestUpgrade>;
  readonly prize?: number;
  readonly descriptionRichText?: readonly RichTextElement[];
  readonly enterpriseId?: number;
  readonly acceptedFileFormats?: readonly EntryFileFormatApi[];
  readonly freelancerCount?: number;
}

export function generateContestViewContestObject(
  options: GenerateContestViewContestOptions,
): ContestViewContest {
  return {
    ...generateContestObject(options),
    ...generateContestViewContestDetails(options),
  };
}

function generateContestViewContestDetails(
  options: GenerateContestViewContestOptions,
): Omit<ContestViewContest, keyof Contest> {
  return {
    usersInterested: 0,
    acceptedFileFormats: options.acceptedFileFormats ?? [
      EntryFileFormatApi.GIF,
      EntryFileFormatApi.JPEG,
      EntryFileFormatApi.JPG,
      EntryFileFormatApi.PNG,
    ],
    isPrizeAutodistributed: false,
  };
}

// Mixins

export function acceptDocumentFileFormats(): Pick<
  GenerateContestViewContestOptions,
  'acceptedFileFormats'
> {
  return {
    acceptedFileFormats: [EntryFileFormatApi.PDF],
  };
}
