export enum ToggleSize {
  SMALL = 'small',
  MID = 'mid',
  LARGE = 'large',
}

export enum ToggleColor {
  LIGHT = 'light',
  DARK = 'dark',
}
