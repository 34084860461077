import { RoleApi } from 'api-typings/common/common';
import type { User } from '../users/users.model';
import type { CanViewEmployerProfile } from './can-view-employer-profile.model';

export interface GenerateCanViewEmployerProfileOptions {
  readonly userId: number;
  readonly canViewEmployerProfile: boolean;
}

export function generateCanViewEmployerProfileObject({
  userId,
  canViewEmployerProfile,
}: GenerateCanViewEmployerProfileOptions): CanViewEmployerProfile {
  return {
    id: userId,
    canViewEmployerProfile,
  };
}

export function canViewEmployerProfileFromUser(
  user: User,
): Pick<
  GenerateCanViewEmployerProfileOptions,
  'userId' | 'canViewEmployerProfile'
> {
  return {
    userId: user.id,
    canViewEmployerProfile: user.role === RoleApi.EMPLOYER,
  };
}
