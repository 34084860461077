import { isPlatformBrowser } from '@angular/common';
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { TimeUtils } from '@freelancer/time-utils';
import type { IMediaElement } from '@videogular/ngx-videogular/core/';
import { Assets } from '../assets';
import { VideoObjectFit } from './video.types';

@Component({
  selector: 'fl-video',
  template: `
    <ng-container *ngIf="isBrowser">
      <vg-player
        *ngIf="
          !disableControls && !autoPlayOnHover && !forceNative;
          else nativeVideo
        "
        class="VgPlayer"
        [attr.data-bounded-height]="boundedHeight"
        [attr.data-bounded-width]="boundedWidth"
      >
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>
        <div
          class="Controls"
          [attr.data-show-controls]="showControls"
        >
          <vg-scrub-bar flHideInEndToEndTests>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>
          <vg-controls>
            <vg-play-pause></vg-play-pause>
            <vg-playback-button *ngIf="speedControls"></vg-playback-button>

            <vg-time-display
              *ngIf="timeDisplay"
              vgProperty="current"
              vgFormat="mm:ss"
            ></vg-time-display>

            <vg-scrub-bar class="EmptyScrubBar"></vg-scrub-bar>

            <vg-mute></vg-mute>
            <vg-volume></vg-volume>
            <vg-fullscreen></vg-fullscreen>
          </vg-controls>
        </div>

        <video
          #media
          class="Player"
          playsinline
          [attr.data-object-fit]="objectFit"
          [attr.data-bounded-height]="boundedHeight"
          [attr.data-bounded-width]="boundedWidth"
          [autoplay]="autoPlay"
          [loop]="loop"
          [muted]="disableControls || autoPlay"
          [class.Stretch]="stretch"
          [attr.preload]="'metadata'"
          [src]="videoSource"
          [vgMedia]="toMediaElement(media)"
          (canplay)="canPlay.emit($event)"
          (canplaythrough)="canPlayThroughHandler($event)"
          (ended)="handleEnded()"
          (error)="error.emit()"
          (play)="handlePlay()"
        ></video>
      </vg-player>
      <ng-template #nativeVideo>
        <video
          #media
          class="Player"
          playsinline
          [attr.data-object-fit]="objectFit"
          [attr.data-bounded-height]="boundedHeight"
          [attr.data-bounded-width]="boundedWidth"
          [autoplay]="autoPlay"
          [class.MirrorMode]="mirrorMode"
          [controls]="!disableControls && !autoPlayOnHover && !mirrorMode"
          [loop]="loop"
          [muted]="
            (disableControls || autoPlay || autoPlayOnHover) && !mirrorMode
          "
          [ngClass]="{ Stretch: stretch }"
          [preload]="'metadata'"
          [src]="videoSource"
          (canplay)="canPlay.emit($event)"
          (canplaythrough)="canPlayThroughHandler($event)"
          (ended)="handleEnded()"
          (error)="error.emit()"
          (play)="handlePlay()"
        ></video>
      </ng-template>
    </ng-container>
  `,
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements OnChanges, OnInit {
  @ViewChild('media', { read: ElementRef }) media: ElementRef<HTMLVideoElement>;

  @Input() src: string | SafeResourceUrl;

  /** Starts the video automatically as soon as it can */
  @Input() autoPlay = false;

  /** Starts the video only when the mouse hovers over the video */
  @Input() autoPlayOnHover = false;

  /** Hides controls by default, and are only visible on hover */
  @HostBinding('attr.data-auto-hide-controls')
  @Input()
  autoHideControls = false;

  /** Whether the src is from an external source */
  @Input() isExternal = true;

  /** Whether or not to show the playback speed controls */
  @Input() speedControls = false;

  /** Whether or not to show the current time, total time or time left for the current media */
  @Input() timeDisplay = true;

  /** Allows you to stretch the video to the full width and height of the container.  CSS for the container is needed to achieve this */
  @Input() stretch = false;

  /** Disable controls as well as mute the video */
  @Input() disableControls = false;

  /** Time in ms before the request to download is aborted*/
  @Input() loadTimeout?: number;

  /** Change the object-fit of the video element inside the component */
  @Input() objectFit?: VideoObjectFit;

  /** Loops the video */
  @Input() loop = false;

  /** Force to use the native video player */
  @Input() forceNative = false;

  /**
   *
   * This ensures that the image will not overflow to its parent container by
   * adding `max-width: 100%`. This is mostly useful if you want your image to
   * adjust accordingly on smaller screens while keeping its original width on
   * bigger screens.
   */
  @Input() boundedWidth?: boolean;

  /**
   * This ensures that the image will not overflow to its parent container's
   * height.
   */
  @Input() boundedHeight?: boolean;

  /**
   * This flips the video horizontally. Useful for video recording.
   * Also disables controls but not mute the video. Only in native.
   */
  @Input() mirrorMode = false;

  /** Emits an event when the video element is ready to play */
  @Output() canPlay = new EventEmitter<Event>();

  /** Emits an event when the video encountered an error */
  @Output() error = new EventEmitter<void>();

  /** Emits an event when the video element is estimated to be able to play from start to end*/
  @Output() canPlayThrough = new EventEmitter<void>();

  /** Emits an event when the video element finished playing */
  @Output() videoEnd = new EventEmitter<void>();

  /** Emits an event when the video element is played */
  @Output() videoPlay = new EventEmitter<void>();

  videoSource?: string | SafeResourceUrl;
  isBrowser: boolean;
  timeoutTimer?: NodeJS.Timeout;
  browserName?: string;
  showControls = true;

  constructor(
    private timeUtils: TimeUtils,
    private assets: Assets,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.showControls = !this.autoHideControls;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('src' in changes || 'isExternal' in changes) {
      this.updateSource();
    }
  }

  canPlayThroughHandler(event: Event): void {
    this.canPlayThrough.emit();
    if (this.timeoutTimer) {
      clearTimeout(this.timeoutTimer);
    }
  }

  updateSource(): void {
    if (this.src) {
      this.videoSource = this.isExternal
        ? this.src
        : this.assets.getUrl(this.src.toString());
      this.setTimerBeforeError();
      if (this.media && this.media.nativeElement) {
        this.media.nativeElement.load();
      }
    }
  }

  /*
   * Will create a setTimeout if loadTimeout is defined. Will trigger error
   * if the specified time was met.
   */
  setTimerBeforeError(): void {
    if (this.loadTimeout && isPlatformBrowser(this.platformId)) {
      if (this.timeoutTimer) {
        clearTimeout(this.timeoutTimer);
      }

      this.timeoutTimer = this.timeUtils.setTimeout(() => {
        this.videoSource = '';
        this.error.emit();
      }, this.loadTimeout);
    }
  }

  play(): Promise<{ status: 'success' | 'error' }> {
    const playPromise = this.media.nativeElement.play();
    // Older browsers may not return a value from play().
    if (playPromise === undefined) {
      return Promise.resolve({ status: 'success' as const });
    }
    return playPromise
      .then(() => ({ status: 'success' as const }))
      .catch(() => ({ status: 'error' as const }));
  }

  @HostListener('mouseenter')
  playOnHover(): void {
    if (this.autoPlayOnHover) {
      this.play();
    }
  }

  @HostListener('mouseleave')
  pauseOnMouseLeave(): void {
    if (this.autoPlayOnHover) {
      this.pause();
    }
  }

  pause(): void {
    if (this.isBrowser) {
      this.media.nativeElement.pause();
    }
  }

  handlePlay(): void {
    // On play, show controls then hide after 3 seconds
    this.showControls = true;
    this.timeUtils.setTimeout(() => {
      this.showControls = false;
    }, 3000);
    this.videoPlay.emit();
  }

  handleEnded(): void {
    this.showControls = !this.autoHideControls;
    this.videoEnd.emit();
  }

  // FIXME: T267853 - https://github.com/videogular/videogular2/issues/911
  // [vgMedia] expects IMediaElement but we have HTMLVideoElement
  toMediaElement(element: HTMLVideoElement): IMediaElement {
    return element as any as IMediaElement;
  }
}
