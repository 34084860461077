import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IconColor } from '@freelancer/ui/icon';
import { BackgroundColor, LogoSize } from '@freelancer/ui/logo';
import { TabsSize } from '@freelancer/ui/tabs';
import { FontColor, FontWeight, TextTransform } from '@freelancer/ui/text';
import { isDefined } from '@freelancer/utils';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  template: `
    <fl-bit
      class="Header"
      data-testing="sticky-header"
    >
      <fl-bit class="Header-inner">
        <fl-bit class="Header-primary">
          <fl-bit
            class="LogoContainer"
            [flMarginRight]="'xsmall'"
            [flMarginRightTablet]="'none'"
          >
            <fl-button
              class="MenuButton"
              [flMarginRight]="'xsmall'"
              [flHideDesktop]="true"
              (click)="handleMenuButtonClick()"
            >
              <fl-icon
                [name]="'ui-bars-v2'"
                [color]="IconColor.LIGHT"
              ></fl-icon>
            </fl-button>
            <fl-logo
              class="Logo"
              [backgroundColor]="BackgroundColor.DARK"
              [size]="LogoSize.ICON"
              [sizeDesktop]="LogoSize.SMALL"
              [flMarginRightDesktop]="'xxxsmall'"
              (click)="onClick()"
            ></fl-logo>
            <fl-text
              class="Logo-text"
              [color]="FontColor.LIGHT"
              [textTransform]="TextTransform.UPPERCASE"
              [weight]="FontWeight.BOLD"
            >
              Bits
            </fl-text>
          </fl-bit>

          <app-search
            class="Header-search"
            [flHideDesktop]="true"
            [isExpandable]="true"
            [hideInternalPages]="hideInternalPages"
            [expandableIconLight]="true"
          ></app-search>
        </fl-bit>
        <ng-container *ngIf="(storyOnly$ | flAsync) === false">
          <app-nav-tabs
            [size]="TabsSize.LARGE"
            [sizeDesktop]="TabsSize.XLARGE"
            [hideInternalPages]="hideInternalPages"
          ></app-nav-tabs>

          <app-theme-toggle />

          <app-search
            class="Header-search"
            [hideInternalPages]="hideInternalPages"
            [flShowDesktop]="true"
          ></app-search>
        </ng-container>
      </fl-bit>
    </fl-bit>
  `,
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit {
  FontColor = FontColor;
  FontWeight = FontWeight;
  IconColor = IconColor;
  LogoSize = LogoSize;
  BackgroundColor = BackgroundColor;
  TabsSize = TabsSize;
  TextTransform = TextTransform;

  storyOnly$: Observable<boolean>;

  @Output() menuButtonClicked = new EventEmitter<void>();
  @Input() hideInternalPages = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.storyOnly$ = this.activatedRoute.queryParamMap.pipe(
      map(params => isDefined(params.get('story_only'))),
    );
  }

  onClick(): void {
    this.router.navigate(['/']);
  }

  handleMenuButtonClick(): void {
    this.menuButtonClicked.emit();
  }
}
