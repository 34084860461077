import { transition, trigger } from '@angular/animations';

/**
 * Prevents initial load from animating. Usually for lists where we only want to
 * animate added or removed items.
 */
export const blockInitialRenderAnimation = trigger(
  'blockInitialRenderAnimation',
  [transition(':enter', [])],
);
