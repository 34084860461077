import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  NetPromoterScoreSourceApi,
  NetPromoterScoreTypeApi,
} from 'api-typings/users/users';
import type { Contest } from '../contests';
import type { Project } from '../projects';
import type { User } from '../users';
import type { UserNps } from './user-nps.model';

export interface GenerateUserNpsOptions {
  readonly id?: number;
  readonly toUserId?: number;
  readonly fromUserId?: number;
  readonly score?: number;
  readonly comment?: string;
  readonly sourceType?: NetPromoterScoreSourceApi;
  readonly sourceId?: number;
  readonly context?: NetPromoterScoreTypeApi;
  readonly timestamp?: number;
}

export function generateUserNpsObject({
  id = generateId(),
  toUserId = generateId(),
  fromUserId = generateId(),
  comment = 'This freelancer has performed commendably',
  context = NetPromoterScoreTypeApi.FREELANCER,
  score = 10,
  sourceId = generateId(),
  sourceType = NetPromoterScoreSourceApi.PROJECT_COMPLETE,
  timestamp = Date.now(),
}: GenerateUserNpsOptions): UserNps {
  if (score < 0 || score > 10) {
    throw new Error('Score must be between 0 and 5 inclusive');
  }
  return {
    id,
    toUserId,
    fromUserId,
    comment,
    context,
    score,
    sourceId,
    sourceType,
    timestamp,
  };
}

// --- Mixins ---

export function forContestNps(
  contest: Contest,
): Pick<
  GenerateUserNpsOptions,
  'sourceType' | 'sourceId' | 'toUserId' | 'context'
> {
  return {
    sourceId: contest.id,
    sourceType: NetPromoterScoreSourceApi.CONTEST_HANDOVER,
    // `sourceId` and `toUserId` are both `contest.id` to match legacy
    // behaviour for contest NPS. This is not consistent with Projects NPS.
    toUserId: contest.id,
    context: NetPromoterScoreTypeApi.CONTEST_PLATFORM,
  };
}

export function forProjectNps(
  project: Project,
): Pick<GenerateUserNpsOptions, 'sourceType' | 'sourceId'> {
  return {
    sourceId: project.id,
    sourceType: NetPromoterScoreSourceApi.PROJECT_COMPLETE,
  };
}

/* Defaults to a rating of 10 */
export function toFreelancerNps(
  user: User,
): Pick<GenerateUserNpsOptions, 'toUserId' | 'context'> {
  return {
    toUserId: user.id,
    context: NetPromoterScoreTypeApi.FREELANCER,
  };
}

export function fromEmployerNps(
  user: User,
): Pick<GenerateUserNpsOptions, 'fromUserId'> {
  return {
    fromUserId: user.id,
  };
}
