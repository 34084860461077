import { generateId } from '@freelancer/datastore/testing/helpers';
import { GroupMemberRoleApi } from 'api-typings/groups/groups';
import type { GroupMember } from './group-members.model';

export interface GenerateGroupMemberOptions {
  readonly groupId: number;
  readonly userId: number;
  readonly role?: GroupMemberRoleApi;
  readonly createdTime?: number;
  readonly isRemoved?: boolean;
  readonly updatedTime?: number;
}

function generateGroupMemberObject({
  groupId,
  userId,
  role = GroupMemberRoleApi.MEMBER,
  createdTime = Date.now(),
  isRemoved = false,
  updatedTime,
}: GenerateGroupMemberOptions): GroupMember {
  const id = generateId();
  return {
    id,
    memberId: id,
    groupId,
    userId,
    role,
    created: createdTime,
    isRemoved,
    updated: updatedTime,
  };
}

export function generateGroupMembersObject(
  groupId: number,
  members: readonly Pick<
    GenerateGroupMemberOptions,
    'userId' | 'createdTime' | 'isRemoved' | 'updatedTime'
  >[],
): readonly GroupMember[] {
  return members.map(member =>
    generateGroupMemberObject({ ...member, groupId }),
  );
}

export function groupAdmin(userId: number): userIdAndRole {
  return { userId, role: GroupMemberRoleApi.ADMIN };
}

export type userIdAndRole = Pick<GenerateGroupMemberOptions, 'userId' | 'role'>;

export function groupModerator(userId: number): userIdAndRole {
  return { userId, role: GroupMemberRoleApi.MODERATOR };
}

export function groupMember(userId: number): userIdAndRole {
  return { userId, role: GroupMemberRoleApi.MEMBER };
}

export function groupGuest(userId: number): userIdAndRole {
  return { userId, role: GroupMemberRoleApi.GUEST };
}

export function groupMuted(userId: number): userIdAndRole {
  return { userId, role: GroupMemberRoleApi.MUTED };
}

export function groupMembers(
  userIds: readonly number[],
): readonly userIdAndRole[] {
  return userIds.map(userId => groupMember(userId));
}

export function groupAdmins(
  userIds: readonly number[],
): readonly userIdAndRole[] {
  return userIds.map(userId => groupAdmin(userId));
}

export function groupModerators(
  userIds: readonly number[],
): readonly userIdAndRole[] {
  return userIds.map(userId => groupModerator(userId));
}

export function groupGuests(
  userIds: readonly number[],
): readonly userIdAndRole[] {
  return userIds.map(userId => groupGuest(userId));
}

export function groupMutedMembers(
  userIds: readonly number[],
): readonly userIdAndRole[] {
  return userIds.map(userId => groupMuted(userId));
}
