import { CommissionFeeTypeApi } from 'api-typings/common/common';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { Currency } from '../currencies/currencies.model';
import type {
  BidSalesTax,
  CommissionFee,
  CommissionFeeRange,
  ProjectBidInfo,
} from './project-bid-info.model';

export const projectBidInfoMixins = {
  generateCommissionFeeRangeObjects,
};

export interface GenerateProjectBidInfoOptions {
  readonly projectId: number;
  readonly minimumFee?: number;
  readonly rate?: number;
  commissionFeeCalculation?(
    options: GenerateCommissionFeeRangeOptions,
  ): readonly CommissionFeeRange[];
  readonly currencyCode?: CurrencyCode;
  readonly salesTax?: BidSalesTax;
  readonly minimumBid?: number;
  readonly fees?: readonly CommissionFeeRange[];
}

export function generateProjectBidInfoObject({
  projectId,
  minimumFee = 5,
  minimumBid = 100,
  rate = 0.1,
  salesTax,
  commissionFeeCalculation = generateCommissionFeeRangeObjects,
  currencyCode = CurrencyCode.USD,
  fees,
}: GenerateProjectBidInfoOptions): ProjectBidInfo {
  return {
    id: projectId,
    projectId,
    minimumFee,
    minimumBid,
    rate,
    minimumSponsorIncrement: 0,
    fees:
      fees ||
      commissionFeeCalculation({
        currency: generateCurrencyObject(currencyCode || CurrencyCode.USD),
      }),
    salesTax,
  };
}

interface GenerateCommissionFeeRangeOptions {
  readonly currency?: Currency;
  readonly rangeLowerBound?: number;
  readonly rangeUpperBound?: number;
  readonly fee?: CommissionFee;
}

// TODO: T267853 - Proper seed data for different types of projects, currencies and enterprises
export function generateCommissionFeeRangeObjects({
  currency,
}: GenerateCommissionFeeRangeOptions): readonly CommissionFeeRange[] {
  return [
    {
      rangeLowerBound: 0,
      rangeUpperBound: 200,
      currency,
      fee: {
        feeType: CommissionFeeTypeApi.FLAT,
        amount: 5,
      },
    },
    {
      rangeLowerBound: 200,
      currency,
      fee: {
        feeType: CommissionFeeTypeApi.RATE,
        amount: 0.1,
      },
    },
  ];
}

export function generateCommissionFeeRangeObjectsForLoadshift({
  currency,
}: GenerateCommissionFeeRangeOptions): readonly CommissionFeeRange[] {
  return [
    {
      rangeLowerBound: 0,
      rangeUpperBound: 55,
      currency,
      fee: {
        feeType: CommissionFeeTypeApi.FLAT,
        amount: 5.5,
      },
    },
    {
      rangeLowerBound: 55,
      currency,
      fee: {
        feeType: CommissionFeeTypeApi.RATE,
        amount: 0.1,
      },
    },
  ];
}

export function zeroFees(): Pick<GenerateProjectBidInfoOptions, 'fees'> {
  return {
    fees: [
      {
        rangeLowerBound: 0,
        rangeUpperBound: undefined,
        fee: {
          feeType: CommissionFeeTypeApi.FLAT,
          amount: 0,
        },
      },
    ],
  };
}

export function australianSalesTax({
  taxNumber = '123456789',
}: {
  readonly taxNumber?: string;
} = {}): Pick<GenerateProjectBidInfoOptions, 'salesTax'> {
  return {
    salesTax: {
      countryCode: 'AU',
      taxRate: 0.1,
      taxType: 'GST',
      taxNumber,
    },
  };
}
