import type { DateRange as MatDateRange } from '@angular/material/datepicker';

export enum InputType {
  CURRENCY = 'currency',
  DATE = 'date',
  DATERANGE = 'daterange',
  DATERANGE_INLINE = 'daterange-inline',
  EMAIL = 'email',
  NUMBER = 'number',
  NUMBER_INCREMENT = 'number-increment',
  NUMBER_TEXT = 'number-text',
  PASSWORD = 'password',
  TEXT = 'text',
  /** Internal use only. Use the {@link https://bits.freelancer.com/components/search/default | `fl-search`} component instead */
  SEARCH = 'search',
}

export enum InputSize {
  SMALL = 'small',
  MID = 'mid',
  LARGE = 'large',
  // Height of the box adjusts based on its content (ex. button). This also makes the size of the text inside the box inherit.
  AUTO = 'auto',
}

export enum InputTextAlign {
  LEFT = 'light',
  RIGHT = 'right',
}

export enum InputAlign {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum InputTextColor {
  DEFAULT = 'default',
  LIGHT = 'light',
}

export enum InputEditInlineType {
  DEFAULT = 'default',
  NON_BORDERED = 'non-bordered',
}

export type InputEditInlineTitleSize = 'large' | 'xlarge' | 'xxlarge';

export type AutoCompleteHint =
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'additional-name'
  | 'bday'
  | 'cc-additional-name'
  | 'cc-family-name'
  | 'cc-given-name'
  | 'cc-name'
  | 'cc-number'
  | 'country'
  | 'country-name'
  | 'current-password'
  | 'email'
  | 'family-name'
  | 'given-name'
  | 'honorific-prefix'
  | 'honorific-suffix'
  | 'name'
  | 'nickname'
  | 'new-password'
  | 'off'
  | 'one-time-code'
  | 'on'
  | 'organization'
  | 'organization-title'
  | 'page'
  | 'postal-code'
  | 'street-address';

/**
 * ALWAYS: enable autofocus on both desktop and mobile
 * DESKTOP: only enable autofocus on desktop
 * NONE: disable autofocus
 */
export enum AutoFocusOption {
  ALWAYS = 'always',
  DESKTOP = 'desktop',
  NONE = 'none',
}

export enum ControlType {
  DATE = 'date',
  DATERANGE = 'daterange',
  NUMBER = 'number',
  STRING = 'string',
}

export type DateRange = MatDateRange<Date>;

/** @see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode */
export type KeyboardType = 'none' | 'numeric' | 'decimal' | 'tel';
