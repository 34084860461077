import { assertNever } from '@freelancer/utils';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { ProjectUpgradeFees } from './project-upgrade-fees.model';

export interface GenerateProjectUpgradeFeesOptions {
  readonly projectId?: number;
  readonly currencyCodes?: readonly (CurrencyCode.USD | CurrencyCode.AUD)[];
  readonly freeUpgrades?: Partial<FreeUpgrades>;
}

interface FreeUpgrades {
  readonly freeIpContract: boolean;
  readonly freeNda: boolean;
}

const defaultFreeUpgrades: FreeUpgrades = {
  freeIpContract: false,
  freeNda: false,
};

export function generateProjectUpgradeFeeObjects({
  projectId,
  currencyCodes = [CurrencyCode.USD],
  freeUpgrades = {},
}: GenerateProjectUpgradeFeesOptions = {}): readonly ProjectUpgradeFees[] {
  return currencyCodes.map(code => {
    const currency = generateCurrencyObject(code);
    const id = projectId ? `${currency.id}_${projectId}` : `${currency.id}`;
    const projectFreeUpgrades: FreeUpgrades = {
      ...defaultFreeUpgrades,
      ...freeUpgrades,
    };
    switch (code) {
      case CurrencyCode.USD:
        return {
          id,
          assistedPrice: 9.5,
          assistedFullPrice: 19,
          deletePrice: 5,
          extendPrice: 9,
          featuredPrice: 9,
          freeAssisted: false,
          freeExtend: true,
          freeNda: projectFreeUpgrades.freeNda,
          freePfOnly: false,
          freeSealed: false,
          freePrivate: false,
          freeUrgent: false,
          freeFeatured: false,
          freeIpContract: projectFreeUpgrades.freeIpContract,
          fulltimeCommissionThreshold: 5000,
          fulltimePrice: 199,
          ipContractPrice: 19,
          isTaxIncluded: false,
          ndaPrice: 19,
          nonCompetePrice: 29,
          nonPublicPrice: 19,
          pfOnlyPrice: 9,
          projectManagementPrice: 25,
          sealedPrice: 9,
          urgentPrice: 9,
          currency,
          currencyId: currency.id,
          projectId,
        };

      case CurrencyCode.AUD:
        return {
          id,
          assistedPrice: 9.5,
          assistedFullPrice: 19,
          deletePrice: 5,
          extendPrice: 9,
          featuredPrice: 9,
          freeAssisted: false,
          freeExtend: false,
          freeNda: projectFreeUpgrades.freeNda,
          freePfOnly: false,
          freeSealed: false,
          freePrivate: false,
          freeUrgent: false,
          freeFeatured: false,
          freeIpContract: projectFreeUpgrades.freeIpContract,
          fulltimeCommissionThreshold: 4700,
          fulltimePrice: 199,
          ipContractPrice: 19,
          isTaxIncluded: false,
          ndaPrice: 19,
          nonCompetePrice: 40,
          nonPublicPrice: 19,
          pfOnlyPrice: 9,
          projectManagementPrice: 30,
          sealedPrice: 9,
          urgentPrice: 9,
          currency,
          currencyId: currency.id,
          projectId,
        };
      default:
        return assertNever(code);
    }
  });
}
