/** Corresponds to the fl-col widths in fl-grid */
export type TableColumnGridWidth =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

export enum TableColumnVerticalAlign {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
}

export enum TableColumnPaddingSize {
  NONE = 'none',
  SMALL = 'small',
  MID = 'mid',
}

export enum TableMobileStyle {
  SIMPLE = 'simple',
  SHADOW = 'shadow',
}

export enum TablePaginationPosition {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum TableExpandableBackgroundColor {
  LIGHT = 'light',
}

export enum ResponsiveColumnPlacement {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM_LEFT = 'bottom_left',
  BOTTOM_RIGHT = 'bottom_right',
  BOTTOM_ACTION = 'bottom_action',
  HIDE_COLUMN = 'hide_column',
  SHOW_EXPANDABLE = 'show_expandable',
}

export enum HeaderWidth {
  XXXSMALL = 'xxsmall',
  XXSMALL = 'xxsmall',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
  XXXLARGE = 'xxxlarge',
}

export interface TableRow<T> {
  item: T;
  highlight?: boolean;
  link?: string;
  newTab?: boolean;
}

export enum TableHeaderColor {
  DEFAULT = 'default',
  MID = 'mid',
  DARK = 'dark',
}
