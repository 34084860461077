import { generateId } from '@freelancer/datastore/testing/helpers';
import type { PayoutMethodTypeEnumApi } from 'api-typings/payments/payments';
import type { PayoutLimitExemption } from './payout-limit-exemptions.model';

export interface GeneratePayoutLimitExemptionOptions {
  readonly id?: number;
  readonly userId: number;
  readonly adminId: number;
  readonly payoutMethodType: PayoutMethodTypeEnumApi;
  readonly maxAmount: number;
  readonly expirationDate: number;
  readonly creationDate?: number;
  readonly isActive?: boolean;
}

export function generatePayoutLimitExemptionObject({
  id = generateId(),
  userId,
  adminId,
  payoutMethodType,
  maxAmount,
  expirationDate,
  creationDate = Date.now(),
  isActive = true,
}: GeneratePayoutLimitExemptionOptions): PayoutLimitExemption {
  return {
    id,
    userId,
    adminId,
    payoutMethodType,
    maxAmount,
    expirationDate,
    creationDate,
    isActive,
  };
}

export function generatePayoutLimitExemptionObjects(
  payoutLimitExemptionOptionsList: readonly GeneratePayoutLimitExemptionOptions[],
): readonly PayoutLimitExemption[] {
  return payoutLimitExemptionOptionsList.map(payoutLimitExemptionOption =>
    generatePayoutLimitExemptionObject(payoutLimitExemptionOption),
  );
}
