import type { BidBuyerProjectFee } from './bid-buyer-project-fees.model';

export interface GenerateBidBuyerProjectFeeOptions {
  readonly id: number;
  readonly amount?: number;
  readonly isTaxed?: boolean;
  readonly rate?: number;
  readonly submitDate?: number;
}

export function generateBidBuyerProjectFeeObject({
  id,
  amount = 10,
  isTaxed = true,
  submitDate = Date.now(),
}: GenerateBidBuyerProjectFeeOptions): BidBuyerProjectFee {
  return {
    id,
    amount,
    isTaxed,
    submitDate,
  };
}
