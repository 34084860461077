import { generateId } from '@freelancer/datastore/testing/helpers';
import type { GeneratePoolOptions } from '../pools';
import {
  anotherTalentNetworkPool,
  deloittePool,
  facebookPool,
  freelancerPool,
  talentNetworkPool,
} from '../pools';
import type { AdminPoolModel } from './admin-pools.model';

export interface GenerateAdminPoolOptions extends GeneratePoolOptions {
  readonly membersCount?: number;
}

export function generateAdminPoolObject({
  id,
  name,
  displayName,
  isTalentNetwork = false,
  isTestPool = false,
  dateCreated = Date.now(),
  membersCount = 0,
  isInsourceEnabled = false,
  isCurrencyEnabled = true,
  seoUrl,
}: GenerateAdminPoolOptions): AdminPoolModel {
  return {
    id: id ?? generateId(),
    name,
    displayName,
    isTalentNetwork,
    isTestPool,
    dateCreated,
    membersCount,
    isInsourceEnabled,
    isCurrencyEnabled,
    seoUrl,
  };
}

export function generateAdminPoolObjects(
  poolsOptionsList: readonly GenerateAdminPoolOptions[],
): readonly AdminPoolModel[] {
  return poolsOptionsList.map(generateAdminPoolObject);
}

export function freelancerAdminPool(): GenerateAdminPoolOptions {
  return {
    ...freelancerPool(),
    membersCount: 100,
  };
}

export function deloitteAdminPool(): GenerateAdminPoolOptions {
  return {
    ...deloittePool(),
    membersCount: 100,
  };
}

export function facebookAdminPool(): GenerateAdminPoolOptions {
  return {
    ...facebookPool(),
    membersCount: 50,
  };
}

export function talentNetworkAdminPool(): GenerateAdminPoolOptions {
  return {
    ...talentNetworkPool(),
    membersCount: 100,
  };
}

export function anotherTalentNetworkAdminPool(): GenerateAdminPoolOptions {
  return {
    ...anotherTalentNetworkPool(),
    membersCount: 10,
  };
}
