import { generateId } from '@freelancer/datastore/testing/helpers';
import type { PartialBy } from '@freelancer/types';
import { TimeUnitApi } from 'api-typings/common/common';
import { SubscriptionStatusApi } from 'api-typings/memberships/memberships_types';
import { generateMembershipPackagePriceObject } from '../membership-package-prices';
import { generatePlusMembershipPlan } from '../membership-packages/membership-packages.seed';
import type { MembershipSubscription } from '../membership-subscription/membership-subscription.model';
import type { MembershipTrial } from '../membership-trials';
import type { Package } from '../recommended-membership/recommended-membership.model';
import type { User } from '../users';
import type { MembershipSubscriptionHistory } from './membership-subscription-history.model';

const PLUS_DISPLAY_NAME = 'plus';
const PLUS_INTERNAL_NAME = 'plan25';
const PLUS_PACKAGE_ID = 5;
const LEGACY_PACKAGE_CATEGORY_ID = 1;
const THIRTY_DAYS_IN_MS = 1000 * 60 * 60 * 24 * 30;

export interface GenerateMembershipSubscriptionHistoryOptions {
  readonly user: User;
  readonly packageId?: number;
  readonly contractQuantity?: number;
  readonly isTrial?: boolean;
  readonly durationId?: number;
  readonly durationType?: TimeUnitApi;
  readonly coupon?: string;
  readonly amount?: number;
  readonly amountWithTax?: number;
  readonly periodStarted?: number;
  readonly timeEndedExpected?: number;
  readonly membershipPackage?: Package;
  readonly status?: SubscriptionStatusApi;
}

export function generateMembershipSubscriptionHistoryObjects({
  user,
  packageId = generateId(),
  contractQuantity = 1,
  isTrial = false,
  durationId = 1,
  durationType = TimeUnitApi.DAY,
  coupon,
  amount = 1,
  amountWithTax,
  periodStarted = Date.now(),
  timeEndedExpected = Date.now() + 86_400_000, // 1 day
  membershipPackage = generatePlusMembershipPlan(),
  status = SubscriptionStatusApi.ACTIVE,
}: GenerateMembershipSubscriptionHistoryOptions): readonly MembershipSubscriptionHistory[] {
  return [
    {
      id: generateId(),
      packageId,
      timeStarted: Date.now(),
      timeEndedExpected,
      priceId: 1,
      periodId: 1,
      status,
      userId: user.id,
      quantity: 1,
      lastPaidTransactionId: 1,
      isTrial,
      duration: {
        id: durationId,
        type: durationType,
        cycle: 1,
      },
      price: {
        id: 1,
        packageId,
        durationId,
        durationType,
        durationCycle: 1,
        coupon,
        amount,
        amountWithTax,
        currencyId: 1,
        timeValidStart: Date.now(),
        contractQuantity,
      },
      period: {
        id: 1,
        packageId,
        userId: user.id,
        timeStarted: periodStarted,
      },
      package: membershipPackage,
    },
  ];
}

/**
 * Transformer for UI tests that need to propagate pushes in `membershipSubscription` to
 * `membershipSubscriptionHistory`, e.g. in the Membership Upsell page
 */
export function subscriptionToSubscriptionHistoryTransformer(
  authUid: number,
  subscription: PartialBy<MembershipSubscription, 'id'>,
): MembershipSubscriptionHistory {
  return {
    id: generateId(),
    packageId: subscription.request?.packageId ?? PLUS_PACKAGE_ID,
    timeStarted: Date.now(),
    timeEndedExpected: Date.now() + THIRTY_DAYS_IN_MS,
    priceId: 1,
    periodId: 1,
    status: SubscriptionStatusApi.ACTIVE,
    userId: authUid,
    quantity: subscription.request?.quantity ?? 1,
    lastPaidTransactionId: 1,
    isTrial: subscription.request?.isTrial ?? false,
    duration: {
      id: 1,
      type: subscription.request?.durationType ?? TimeUnitApi.MONTH,
      cycle: 1,
    },
    autoRenew: subscription.request?.autoRenew ?? false,
    trial:
      subscription.request?.isTrial && subscription.request?.trialId
        ? ({
            id: subscription.request?.trialId,
            packageId: subscription.request?.packageId ?? PLUS_PACKAGE_ID,
            eligible: true,
            package: {
              id: subscription.request?.packageId ?? PLUS_PACKAGE_ID,
              internalName: PLUS_INTERNAL_NAME,
              displayName: PLUS_DISPLAY_NAME,
              enabled: true,
              categoryId: LEGACY_PACKAGE_CATEGORY_ID,
              order: 1,
            },
          } as MembershipTrial)
        : undefined,
    trialPrice: generateMembershipPackagePriceObject({
      packageId: subscription.request?.packageId ?? PLUS_PACKAGE_ID,
      contractQuantity: subscription.request?.quantity ?? 1,
      durationType: subscription.request?.durationType ?? TimeUnitApi.MONTH,
    }),
    price: generateMembershipPackagePriceObject({
      packageId: subscription.request?.packageId ?? PLUS_PACKAGE_ID,
      contractQuantity: subscription.request?.quantity ?? 1,
      durationType: subscription.request?.durationType ?? TimeUnitApi.MONTH,
    }),
    period: {
      id: 1,
      packageId: subscription.request?.packageId ?? PLUS_PACKAGE_ID,
      userId: authUid,
      timeStarted: Date.now(),
      timeEnded: Date.now() + THIRTY_DAYS_IN_MS,
    },
    package: {
      id: subscription.request?.packageId ?? PLUS_PACKAGE_ID,
      internalName: PLUS_INTERNAL_NAME,
      categoryId: LEGACY_PACKAGE_CATEGORY_ID,
      displayName: PLUS_DISPLAY_NAME,
      enabled: true,
      order: 1,
    },
  };
}
