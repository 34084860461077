import type { IsLookingForWork } from './is-looking-for-work.model';

export function generateIsLookingForWork({
  title = 'Test Ttile',
  isLookingForWork = false,
}): IsLookingForWork {
  return {
    id: title,
    isLookingForWork,
    title,
  };
}
