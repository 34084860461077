import { generateId } from '@freelancer/datastore/testing/helpers';
import { ProjectTextCleanStatusApi } from 'api-typings/projects/projects';
import { generateRandomCount } from '../user-quality-details/user-quality-details.seed';
import type {
  AdminNote,
  ProjectQualityDetails,
} from './project-quality-details.model';

export interface GenerateProjectQualityDetailsOptions {
  readonly projectIds: readonly number[];
}

export interface GenerateProjectQualityDetailOptions {
  readonly projectId?: number;
  readonly hasNonChargedFees?: boolean;
  readonly isEscalated?: boolean;
  readonly adminNotes?: readonly AdminNote[];
  readonly projectsMatchingDescription?: readonly number[];
  readonly isSpam?: boolean;
  readonly matchingProjectSellerSkillsCount?: number;
  readonly projectTextCleanStatus?: ProjectTextCleanStatusApi;
}

export function generateProjectQualityDetailsObjects({
  projectIds,
}: GenerateProjectQualityDetailsOptions): readonly ProjectQualityDetails[] {
  return projectIds.map(projectId =>
    generateProjectQualityDetailsObject({
      projectId,
    }),
  );
}

export function generateProjectQualityDetailsObject({
  projectId = generateId(),
  hasNonChargedFees = false,
  isEscalated = false,
  adminNotes = generateAdminNotes(),
  projectsMatchingDescription = [],
  isSpam = false,
  matchingProjectSellerSkillsCount = generateRandomCount(),
  projectTextCleanStatus = ProjectTextCleanStatusApi.CLEAN,
}: GenerateProjectQualityDetailOptions = {}): ProjectQualityDetails {
  return {
    id: projectId,
    hasNonChargedFees,
    isEscalated,
    adminNotes,
    projectsMatchingDescription,
    isSpam,
    matchingProjectSellerSkillsCount,
    projectTextCleanStatus,
  };
}

function generateAdminNotes(): readonly AdminNote[] {
  return [
    {
      id: generateId(),
      adminName: 'testadmin',
      description: 'Test admin notes',
      noteDescription: 'This is just a test note description',
      noteType: 'test_note_type',
      submitDate: Date.now(),
    },
  ];
}
