import { generateId } from '@freelancer/datastore/testing/helpers';
import type { TaskGroupMember } from './task-group-members.model';

export interface GenerateTaskGroupMemberObjectOptions {
  readonly taskGroupId: number;
  readonly userId: number;
}

export function generateTaskGroupMemberObject({
  taskGroupId = generateId(),
  userId = generateId(),
}: GenerateTaskGroupMemberObjectOptions): TaskGroupMember {
  return {
    id: generateId().toString(),
    taskGroupId,
    userId,
  };
}

export function generateTaskGroupMembersObject({
  taskGroupId = generateId(),
  userIds = [generateId()],
}): readonly TaskGroupMember[] {
  return userIds.map(userId =>
    generateTaskGroupMemberObject({ taskGroupId, userId }),
  );
}
