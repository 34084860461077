import type {
  BidsCollection,
  ContestReviewForEmployer,
  ContestReviewForFreelancer,
  ContestUserHasGivenFeedbackCollection,
  DeliveryContactsSensitiveDetailsCollection,
  EnterprisesCollection,
  FeedCollection,
  FeedMetaCollection,
  FeedPostsCollection,
  GrantsCollection,
  GroupFeedCollection,
  GroupPermissionsCollection,
  GroupsCollection,
  GroupsSelfCollection,
  MembershipSubscriptionCollection,
  MembershipSubscriptionHistoryCollection,
  ProjectTitleEditRequestCollection,
  ProjectViewBidsCollection,
  ProjectViewProjectsCollection,
  ReviewsCollection,
  UserHasGivenFeedbackCollection,
} from '@freelancer/datastore/collections';
import {
  ContestUserReviewRole,
  GrantRole,
  UserReviewRole,
  bidToProjectViewBidTransformer,
  getGroupOrderByFeedReadLatestTimeInMs,
  subscriptionToSubscriptionHistoryTransformer,
} from '@freelancer/datastore/collections';
import { deepSpread } from '@freelancer/datastore/core';
import { generateId } from '@freelancer/datastore/testing/helpers';
import { toNumber } from '@freelancer/utils';
import {
  EntityTypeApi,
  FrontendProjectStatusApi,
  RoleApi,
} from 'api-typings/common/common';
import {
  FeedContextTypeApi,
  FeedReferenceTypeApi,
} from 'api-typings/feed/feed';
import { GrantedPermissionApi } from 'api-typings/grants/grants';
import {
  GroupMemberRoleApi,
  GroupPermissionApi,
} from 'api-typings/groups/groups';
import {
  BidCompleteStatusApi,
  ProjectStatusApi,
  ProjectSubStatusApi,
  ProjectTitleEditRequestStatusApi,
} from 'api-typings/projects/projects';
import { addMutationPropagator } from './document-creators';

export function addMutationPropagators(): void {
  // The end project modal updates the `bids` collection, which in the real
  // datastore also updates the corresponding bid in `projectViewProject.selectedBids`.
  // Since the fake datastore has no reducer layer, we need to recreate this
  // behaviour with a mutation propagator.
  addMutationPropagator<BidsCollection, ProjectViewProjectsCollection>({
    from: 'bids',
    to: 'projectViewProjects',
    config: {
      update: {
        // The ID of the `projectViewProject` to update
        targetDocumentId: bid => bid.projectId,
        // A function that updates the ProjectViewProject appropriately, in this
        // case by merging in the delta for the selected bid
        transformer: (authUid, delta, bid, projectViewProject) => ({
          ...projectViewProject,
          selectedBids: [
            ...projectViewProject.selectedBids.map(selectedBid =>
              selectedBid.id === bid.id ? deepSpread(bid, delta) : selectedBid,
            ),
          ],
          frontendProjectStatus:
            delta.completeStatus === BidCompleteStatusApi.COMPLETE ||
            delta.completeStatus === BidCompleteStatusApi.INCOMPLETE
              ? FrontendProjectStatusApi.COMPLETE
              : projectViewProject.frontendProjectStatus,

          ...(delta.completeStatus === BidCompleteStatusApi.COMPLETE
            ? {
                status: ProjectStatusApi.CLOSED,
                subStatus: ProjectSubStatusApi.CLOSED_AWARDED,
              }
            : delta.completeStatus === BidCompleteStatusApi.INCOMPLETE
            ? {
                status: ProjectStatusApi.CLOSED,
                subStatus: ProjectSubStatusApi.CANCEL_SELLER,
              }
            : {}),
        }),
      },
    },
  });

  addMutationPropagator<FeedPostsCollection, GroupFeedCollection>({
    from: 'feedPosts',
    to: 'groupFeed',
    config: {
      push: (_authUid, feedPost, extra) => ({
        id: generateId(),
        updated: feedPost.updated,
        post: feedPost,
        groupId: toNumber(extra?.feedContextId) || generateId(),
        feedItem: {
          updated: feedPost.updated,
          id: generateId(),
          created: feedPost.created,
          feedMetaId: generateId(),
          contextId: toNumber(extra?.feedContextId),
          contextType:
            (extra?.feedContextType as FeedContextTypeApi) ||
            FeedContextTypeApi.GROUP,
          referenceId: feedPost.id,
          referenceType: FeedReferenceTypeApi.POST,
        },
        permissions: [GroupPermissionApi.FEED_GET],
        postOwnerGroupMember: {
          id: generateId(),
          userId: _authUid,
          groupId: toNumber(extra?.feedContextId) || generateId(),
          permissions: [GroupPermissionApi.FEED_GET],
          role: GroupMemberRoleApi.MEMBER,
          created: Date.now(),
          memberId: generateId(),
          isRemoved: false,
        },
        viewingUserGroupMember: {
          id: generateId(),
          userId: _authUid,
          groupId: toNumber(extra?.feedContextId) || generateId(),
          permissions: [GroupPermissionApi.FEED_GET],
          role: GroupMemberRoleApi.MEMBER,
          created: Date.now(),
          memberId: generateId(),
          isRemoved: false,
        },
        feedItemReferenceType: FeedReferenceTypeApi.POST,
        feedItemReferenceId: feedPost.id,
        isBookmarked: false,
      }),
    },
  });

  addMutationPropagator<FeedPostsCollection, FeedCollection>({
    from: 'feedPosts',
    to: 'feed',
    config: {
      push: (_authUid, feedPost, extra) => ({
        updated: feedPost.updated,
        created: feedPost.created,
        feedMetaId: generateId(),
        id: generateId(),
        contextType:
          (extra?.feedContextType as FeedContextTypeApi) ||
          FeedContextTypeApi.GROUP,
        contextId: toNumber(extra?.feedContextId) || generateId(),
        referenceType: FeedReferenceTypeApi.POST,
        referenceId: feedPost.id,
      }),
    },
  });

  // When a `membershipSubscription` is created, we also want to create a corresponding
  // `membershipSubscriptionHistory` entry
  addMutationPropagator<
    MembershipSubscriptionCollection,
    MembershipSubscriptionHistoryCollection
  >({
    from: 'membershipSubscription',
    to: 'membershipSubscriptionHistory',
    config: {
      push: subscriptionToSubscriptionHistoryTransformer,
    },
  });

  // When a review is submitted, the `userHasGivenFeedback` collection should be
  // updated with `feedbackLeft: true`. Logic copied from the reducer
  addMutationPropagator<ReviewsCollection, UserHasGivenFeedbackCollection>({
    from: 'reviews',
    to: 'userHasGivenFeedback',
    config: {
      push: (authUid, review) => ({
        id: `${review.context.id}-${review.toUserId}`,
        feedbackLeft: true,
        projectId: review.context.id,
        toUserId: review.toUserId,
        fromUserId: review.fromUserId,
        reviewType:
          review.role === RoleApi.FREELANCER
            ? UserReviewRole.SELLER
            : UserReviewRole.BUYER,
      }),
    },
  });

  // When a review is submitted, the `contestUserHasGivenFeedback` collection should be
  // updated with `feedbackLeft: true`. Logic copied from the reducer
  addMutationPropagator<
    ReviewsCollection,
    ContestUserHasGivenFeedbackCollection
  >({
    from: 'reviews',
    to: 'contestUserHasGivenFeedback',
    config: {
      push: (
        authUid,
        review: ContestReviewForFreelancer | ContestReviewForEmployer,
      ) => ({
        id: `${review.entryId}-${review.toUserId}`,
        feedbackLeft: true,
        entryId: review.entryId ?? generateId(),
        toUserId: review.toUserId,
        fromUserId: review.fromUserId,
        role:
          review.role === RoleApi.FREELANCER
            ? ContestUserReviewRole.FREELANCER
            : ContestUserReviewRole.EMPLOYER,
      }),
    },
  });

  // On a project title edit request, when the client accepts the change
  // it should update the title of the project in the datastore as well.
  addMutationPropagator<
    ProjectTitleEditRequestCollection,
    ProjectViewProjectsCollection
  >({
    from: 'projectTitleEditRequest',
    to: 'projectViewProjects',
    config: {
      update: {
        targetDocumentId: request => request.projectId,
        transformer: (authUid, delta, projectTitleEditRequest, project) => ({
          ...project,
          ...(delta.status === ProjectTitleEditRequestStatusApi.ACCEPTED
            ? { title: projectTitleEditRequest.newTitle }
            : {}),
        }),
      },
    },
  });

  // On editing project contact details, updating the project
  // should also update the sensitive details (name and phone number)
  addMutationPropagator<
    ProjectViewProjectsCollection,
    DeliveryContactsSensitiveDetailsCollection
  >({
    from: 'projectViewProjects',
    to: 'deliveryContactsSensitiveDetails',
    config: {
      update: {
        targetDocumentId: request => request.id,
        transformer: (authUid, delta, project, sensitiveDetails) => ({
          id: project.id,
          pickupDeliveryContactSensitiveDetails: {
            ...project.localDetails?.pickupDeliveryContact,
          },
          dropoffDeliveryContactSensitiveDetails: {
            ...project.localDetails?.dropoffDeliveryContact,
          },
        }),
      },
    },
  });

  // We need this because the bid form pushes to 'bids' but the bid list fetches
  // from 'projectViewBids'. In the real datastore this is solved by a Websocket
  // message being sent for the new bid, and the reducer for both collections
  // listening to it. However, since we use a faked backend in tests we have to
  // replicate the store-level mutations with custom transformers and by
  // specifying related collections.
  addMutationPropagator<BidsCollection, ProjectViewBidsCollection>({
    from: 'bids',
    to: 'projectViewBids',
    config: {
      push: bidToProjectViewBidTransformer,
      // Editing a bid should also edit the projectViewBid. Since no transformer
      // is given, this defaults to merging in the same delta to the projectViewBid
      update: {
        targetDocumentId: bid => bid.id,
      },
    },
  });

  // When a user views a post we updated the feedMeta collection. However, we also need to ensure this updates the group isFeedRead
  // property so that all unread groups content indicators are removed
  addMutationPropagator<FeedMetaCollection, GroupsSelfCollection>({
    from: 'feedMeta',
    to: 'groupsSelf',
    config: {
      update: {
        targetDocumentId: request => request.contextId,
        transformer: (authUid, delta, feedMetaDoc, groupDoc) => ({
          ...groupDoc,
          isFeedRead: true,
          orderByFeedReadLatestTimeInMs: getGroupOrderByFeedReadLatestTimeInMs(
            groupDoc.lastActiveTimeInMs,
          ),
        }),
      },
    },
  });

  // To ensure we can load the Group page after creation, we need to create the associated permission document. Without it,
  // the Feed page guard fails. This blocks the Feed page rendering, and because change detection runs from the root of the DOM tree upward,
  // it also blocks the page component.
  addMutationPropagator<GroupsCollection, GroupPermissionsCollection>({
    from: 'groups',
    to: 'groupPermissions',
    config: {
      push: (authUid, group, extra) => ({
        id: extra?.refDocumentId ? toNumber(extra.refDocumentId) : generateId(),
        permissions: [GroupPermissionApi.FEED_GET],
      }),
    },
  });

  // To ensure we can load the Partner Dashboard after enterprise creation, we need to add grants to the user
  addMutationPropagator<EnterprisesCollection, GrantsCollection>({
    from: 'enterprises',
    to: 'grants',
    config: {
      push: (authUid, enterprises, extra) => {
        return {
          id: generateId(),
          resource: {
            id: toNumber(extra?.refDocumentId) || generateId(),
            entityType: EntityTypeApi.ENTERPRISE,
          },
          grantingEntity: {
            id: generateId(),
            entityType: EntityTypeApi.ADMIN,
          },
          entity: {
            id: authUid,
            entityType: EntityTypeApi.USER,
          },
          roleId: GrantRole.USER_MANAGEMENT,
          grantedPermissions: [
            GrantedPermissionApi.VIEW_USER_PERSONAL_DETAILS,
            GrantedPermissionApi.CLOSE_USER,
            GrantedPermissionApi.MANAGE_INSOURCE_ROLES,
            GrantedPermissionApi.MANAGE_ENTERPRISE_PAYMENTS,
            GrantedPermissionApi.UPDATE_ENTERPRISE,
          ],
          roleName: 'User Management',
        };
      },
    },
  });
}
