import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  type FeedAuxiliaryReferenceTypeApi,
  FeedContextTypeApi,
  FeedReferenceTypeApi,
} from 'api-typings/feed/feed';
import type { Post } from '../feed-posts';
import type { FeedItem } from './feed.model';

export interface GenerateFeedOptions {
  readonly contextType: FeedContextTypeApi;
  readonly contextId: number;
  readonly feedMetaId: number;
  readonly createdTime?: number;
  readonly updatedTime?: number;
  readonly referenceType: FeedReferenceTypeApi;
  readonly referenceId: number;
  readonly auxiliaryReference?: {
    readonly [k in FeedAuxiliaryReferenceTypeApi]?: number;
  };
}

export function generateFeedObject({
  contextType,
  contextId,
  feedMetaId,
  createdTime,
  updatedTime,
  referenceType,
  referenceId,
  auxiliaryReference = undefined,
}: GenerateFeedOptions): FeedItem {
  const now = Date.now();

  return {
    id: generateId(),
    contextType,
    contextId,
    feedMetaId,
    created: createdTime || now,
    updated: updatedTime || now,
    referenceType,
    referenceId,
    auxiliaryReference,
  };
}

export function feedForGroup(
  groupId: number,
): Pick<GenerateFeedOptions, 'contextType' | 'contextId'> {
  return {
    contextType: FeedContextTypeApi.GROUP,
    contextId: groupId,
  };
}

export function feedForPost(
  feedPost: Post,
): Pick<
  GenerateFeedOptions,
  'referenceType' | 'referenceId' | 'createdTime' | 'updatedTime'
> {
  return {
    referenceType: FeedReferenceTypeApi.POST,
    referenceId: feedPost.id,
    createdTime: feedPost.created,
    updatedTime: feedPost.updated,
  };
}

export function feedForContestUpdates(
  contestId: number,
): Pick<GenerateFeedOptions, 'contextType' | 'contextId'> {
  return {
    contextId: contestId,
    contextType: FeedContextTypeApi.CONTEST,
  };
}
