import { generateId } from '@freelancer/datastore/testing/helpers';
import { DiscoverCollectionItemTypeApi } from 'api-typings/users/users';
import type { DiscoverCollectionItem } from './discover-collection-items.model';

/**
 * Seed functions for Discover Collection Items
 * Items must be attached to an existing Discover Collection by a collectionId, so a mock
 * DiscoverCollection should first be created before generating mock collection item documents.
 * Note: Due to the dependency between these document types, it is important that the initial
 * `itemCount`in the corresponding DiscoverCollection document is consistent with the initial
 * number of DiscoverCollectionItems you wish to initialise.
 */
export interface GenerateDiscoverCollectionItemOptions {
  // Need to specify the itemId, type and order for each item added to the collection
  readonly itemId: number;
  readonly type: DiscoverCollectionItemTypeApi;
  readonly collectionId: number;
  readonly order?: number;
  readonly name?: string;
  readonly description?: string;
  readonly url?: string;
  readonly thumbnail?: string;
  readonly isDeleted?: boolean;
}

export function generateDiscoverCollectionItemObject({
  itemId,
  type,
  collectionId,
  order = 0,
  name = '',
  description = '',
  thumbnail = '',
  url = '',
  isDeleted = false,
}: GenerateDiscoverCollectionItemOptions): DiscoverCollectionItem {
  return {
    id: generateId(),
    context: {
      itemId,
      type,
    },
    order,
    collectionId,
    thumbnail,
    name,
    description,
    url,
    isDeleted,
  };
}

export function userItem(
  userId: number,
): Pick<GenerateDiscoverCollectionItemOptions, 'itemId' | 'type'> {
  return {
    itemId: userId,
    type: DiscoverCollectionItemTypeApi.USER,
  };
}
