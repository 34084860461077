export enum CheckboxSize {
  SMALL = 'small',
  LARGE = 'large',
}

export enum CheckboxColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TRANSPARENT = 'transparent',
}
