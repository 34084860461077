import { generateId } from '@freelancer/datastore/testing/helpers';
import { isDefined } from '@freelancer/utils';
import type { CurrencyApi, JobApi } from 'api-typings/common/common';
import type { ShowcaseSourceApi } from 'api-typings/users/users';
import {
  ShowcaseSourceTypeApi,
  ShowcaseStatusApi,
} from 'api-typings/users/users';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import { transformCurrency } from '../currencies/currencies.transformers';
import { transformSkill } from '../skills/skills.transformers';
import type { Tag } from '../tags/tags.model';
import type { Showcase, ShowcaseFile, ShowcaseItem } from './showcase.model';
import {
  getPortfolioItemPageUrl,
  getThumbnailUrl,
} from './showcase.transformers';

export interface GenerateShowcaseOptions {
  readonly id?: number;
  readonly userId?: number;
  readonly budget?: number;
  readonly currency?: CurrencyApi;
  readonly customSkills?: readonly string[];
  readonly hiremeCount?: number;
  readonly jobs?: readonly JobApi[];
  readonly likes?: number;
  readonly qualityScore?: number;
  readonly seoUrl?: string;
  readonly showcaseItems?: readonly ShowcaseItem[];
  readonly source?: ShowcaseSourceApi;
  readonly status?: ShowcaseStatusApi;
  readonly tags?: readonly Tag[];
  readonly timeSubmitted?: number;
  readonly title?: string;
  readonly url?: string;
}

export interface GenerateShowcasesOptions {
  readonly showcasesOptions: readonly GenerateShowcaseOptions[];
  readonly count?: number;
  readonly tags?: readonly Tag[];
  titles?(index: number): string;
  readonly userId?: number;
}

export function generateShowcases(
  options: GenerateShowcasesOptions,
): readonly Showcase[] {
  if (options.count && options.titles) {
    const { count, tags, titles, userId } = options;
    return Array.from({ length: count }).map((_, index) =>
      generateShowcase({ tags, title: titles(index), userId }),
    );
  }
  return options.showcasesOptions.map(showcasesOption =>
    generateShowcase(showcasesOption),
  );
}

export function generateShowcase({
  id = generateId(),
  userId = generateId(),
  budget = 10.0,
  currency = generateCurrencyObject(CurrencyCode.USD),
  customSkills = [],
  hiremeCount = 0,
  jobs = [],
  likes = 0,
  qualityScore = 0,
  seoUrl = '',
  showcaseItems = [
    generateShowcaseItem({ isCoverItem: true }),
    generateShowcaseItem({}),
  ],
  source = generateShowcaseSourceApi({}),
  status = ShowcaseStatusApi.ACTIVE,
  tags = [],
  timeSubmitted = 12_345_000,
  title = `Portfolio item title ${id}`,
  url = '',
}: GenerateShowcaseOptions): Showcase {
  return {
    id,
    title,
    description: 'This is the description of the portfolio item.',
    sourceParentId: source.parent_source_id ? source.parent_source_id : 0,
    sourceType: source.source_type
      ? source.source_type
      : ShowcaseSourceTypeApi.EXTERNAL,
    budget,
    currency: transformCurrency(currency),
    userId,
    status,
    showcaseItems,
    tags,
    tagIds: tags.map(tag => tag.id),
    sourceUrl: url,
    showcaseUrl: seoUrl,
    likes,
    qualityScore,
    hiremeCount,
    skills: jobs.map(skill => transformSkill(skill)),
    timeSubmitted,
    customSkills,
    customTags: tags
      .filter(tag => !tag.id)
      .map(tag => ({ name: tag.name }))
      .filter(isDefined),
    portfolioItemPageSeoUrl: getPortfolioItemPageUrl(id, title),
    coverItem: showcaseItems[0],
  };
}

export interface GenerateShowcaseSourcesOptions {
  readonly sourceId?: number;
  readonly sourceType?: ShowcaseSourceTypeApi;
  readonly parentSourceId?: number;
}

export function generateShowcaseSourceApi({
  sourceId = -1,
  sourceType = ShowcaseSourceTypeApi.EXTERNAL,
  parentSourceId = 0,
}: GenerateShowcaseSourcesOptions): ShowcaseSourceApi {
  return {
    source_id: sourceId,
    source_type: sourceType,
    parent_source_id: parentSourceId,
  };
}

export interface GenerateShowcaseItemOptions {
  readonly itemFile?: ShowcaseFile;
  readonly fileOrder?: number;
  readonly isCoverItem?: boolean;
}

export function generateShowcaseItem({
  itemFile = generateShowcaseFile({}),
  fileOrder = 0,
  isCoverItem = false,
}: GenerateShowcaseItemOptions): ShowcaseItem {
  const id = generateId();
  return {
    id,
    description: 'Showcase Item description',
    itemFile,
    showcaseId: id,
    coverThumbnailUrl: getThumbnailUrl(itemFile),
    fileOrder,
    isCoverItem,
  };
}

export interface GenerateShowcaseFileOptions {
  readonly filename?: string;
  readonly url?: string;
  readonly height?: number;
  readonly width?: number;
}

function generateShowcaseFile({
  filename = 'freelancer-logo.png',
  width = 1200,
  height = 640,
  url = '/discover/freelancer-logo.png',
}: GenerateShowcaseFileOptions): ShowcaseFile {
  return {
    filename,
    fileserviceFileId: generateId(),
    url,
    thumbnails: {
      showcase: {
        fileserviceFileId: generateId(),
        filename,
        cdnUrl: url,
        height,
        width,
      },
    },
  };
}
