import { generateId } from '@freelancer/datastore/testing/helpers';
import { EntityTypeApi } from 'api-typings/common/common';
import { GrantedPermissionApi } from 'api-typings/grants/grants';
import type { Pool } from '../enterprise';
import { Enterprise } from '../enterprise';
import type { Grant, GrantEntity } from './grants.model';

/**
 * Enum for Different Roles and their associated permissions in the DB.
 * From `superuser_role` table in GAF
 */
export enum GrantRole {
  FREELANCER_STAFF = 4,
  USER_MANAGEMENT = 7,
  FREIGHTLANCER = 9,
  ANALYTICS_VIEWER = 10,
  HP_DISPATCHER = 12,
  EMAIL_CONTENT_MANAGER = 13,
  GROUPS_MANAGER = 14,
  BANNERS_MANAGER = 15,
}

export const allGrantedPermissions: readonly GrantedPermissionApi[] = [
  GrantedPermissionApi.VIEW_USER_PERSONAL_DETAILS,
  GrantedPermissionApi.VIEW_ALL_PROJECTS,
  GrantedPermissionApi.VIEW_PROJECT_MILESTONES,
  GrantedPermissionApi.VIEW_PROJECT_THREADS,
  GrantedPermissionApi.VIEW_PROJECT_ATTACHMENTS,
  GrantedPermissionApi.VIEW_PROJECT_NDA_DETAILS,
  GrantedPermissionApi.VIEW_MARKETPLACE_STATS,
  GrantedPermissionApi.VIEW_PRIVATE_BIDS,
  GrantedPermissionApi.CREATE_PROJECT_COLLABORATION,
  GrantedPermissionApi.VIEW_SUPPORT_SESSIONS,
];

export interface GenerateGrantObjectOptions {
  readonly resource: GrantEntity;
  readonly entity: GrantEntity;
  readonly grantingEntity?: GrantEntity;
  readonly roleId?: GrantRole;
  readonly grantedPermissions?: readonly GrantedPermissionApi[];
}

export function grantEnterprise(
  userId: number,
  enterpriseId: Enterprise,
): Pick<GenerateGrantObjectOptions, 'resource' | 'entity'> {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    resource: {
      id: enterpriseId,
      entityType: EntityTypeApi.ENTERPRISE,
    },
  };
}

export function grantPool(
  userId: number,
  poolId: Pool,
): Pick<GenerateGrantObjectOptions, 'entity' | 'resource'> {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    resource: {
      id: poolId,
      entityType: EntityTypeApi.POOL,
    },
  };
}

export function generateGrantObject({
  resource,
  entity,
  grantingEntity = { id: generateId(), entityType: EntityTypeApi.ADMIN },
  roleId = GrantRole.FREELANCER_STAFF,
  grantedPermissions = allGrantedPermissions,
}: GenerateGrantObjectOptions): Grant {
  const roleName = GrantRole[roleId];

  return {
    id: generateId(),
    resource,
    grantingEntity,
    entity,
    roleId,
    roleName,
    grantedPermissions,
  };
}

export function generateGrantsObjects(
  grantItemOptionsList: readonly GenerateGrantObjectOptions[],
): readonly Grant[] {
  return grantItemOptionsList.map(grantItemOptions =>
    generateGrantObject(grantItemOptions),
  );
}

export function hpDispatcherGrant({
  userId,
}: {
  readonly userId: number;
}): GenerateGrantObjectOptions {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    resource: {
      id: Enterprise.HP,
      entityType: EntityTypeApi.ENTERPRISE,
    },
    grantingEntity: {
      id: generateId(),
      entityType: EntityTypeApi.ADMIN,
    },
    roleId: GrantRole.HP_DISPATCHER,
    grantedPermissions: [
      GrantedPermissionApi.VIEW_ALL_PROJECTS,
      GrantedPermissionApi.VIEW_ALL_PROJECTS,
      GrantedPermissionApi.VIEW_PROJECT_MILESTONES,
      GrantedPermissionApi.VIEW_PROJECT_THREADS,
      GrantedPermissionApi.VIEW_PROJECT_NDA_DETAILS,
      GrantedPermissionApi.VIEW_PRIVATE_BIDS,
      GrantedPermissionApi.VIEW_SUPPORT_SESSIONS,
      GrantedPermissionApi.UPDATE_ENTERPRISE_PROJECT,
    ],
  };
}

export function UserManagementGrant({
  userId,
  enterpriseId,
}: {
  readonly userId: number;
  readonly enterpriseId: Enterprise;
}): GenerateGrantObjectOptions {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    resource: {
      id: enterpriseId,
      entityType: EntityTypeApi.ENTERPRISE,
    },
    grantingEntity: {
      id: generateId(),
      entityType: EntityTypeApi.ADMIN,
    },
    roleId: GrantRole.USER_MANAGEMENT,
    grantedPermissions: [
      GrantedPermissionApi.VIEW_USER_PERSONAL_DETAILS,
      GrantedPermissionApi.CLOSE_USER,
      GrantedPermissionApi.MANAGE_INSOURCE_ROLES,
      GrantedPermissionApi.UPDATE_ENTERPRISE,
      GrantedPermissionApi.MANAGE_ENTERPRISE_PAYMENTS,
    ],
  };
}

export function analyticsViewerGrant({
  userId,
  enterpriseId,
}: {
  readonly userId: number;
  readonly enterpriseId: number;
}): GenerateGrantObjectOptions {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    roleId: GrantRole.ANALYTICS_VIEWER,
    resource: {
      id: enterpriseId,
      entityType: EntityTypeApi.ENTERPRISE,
    },
    grantingEntity: {
      id: generateId(),
      entityType: EntityTypeApi.ADMIN,
    },
    grantedPermissions: [GrantedPermissionApi.VIEW_ANALYTICS],
  };
}

export function manageEmailContentGrant({
  userId,
  enterpriseId,
}: {
  readonly userId: number;
  readonly enterpriseId: Enterprise;
}): GenerateGrantObjectOptions {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    resource: {
      id: enterpriseId,
      entityType: EntityTypeApi.ENTERPRISE,
    },
    grantingEntity: {
      id: generateId(),
      entityType: EntityTypeApi.ADMIN,
    },
    roleId: GrantRole.EMAIL_CONTENT_MANAGER,
    // this mirrors superuser_role_permission
    grantedPermissions: [GrantedPermissionApi.MANAGE_EMAIL_CONTENT],
  };
}

/**
 * Grant to enable user to view the groups management page on the partner dashboard of an enterprise
 */
export function groupsManagementGrant({
  userId,
  enterpriseId,
}: {
  readonly userId: number;
  readonly enterpriseId: number;
}): GenerateGrantObjectOptions {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    resource: {
      id: enterpriseId,
      entityType: EntityTypeApi.ENTERPRISE,
    },
    grantingEntity: {
      id: generateId(),
      entityType: EntityTypeApi.ADMIN,
    },
    roleId: GrantRole.GROUPS_MANAGER,
    // this mirrors superuser_role_permission
    grantedPermissions: [GrantedPermissionApi.MANAGE_GROUPS],
  };
}

/**
 * Grant that the backend checks to determine whether the user has access to manage a group.
 * User is able to manage a group if they have this grant on all pools that the group is in.
 */
export function groupsManagementBackendGrant({
  userId,
  poolId,
}: {
  readonly userId: number;
  readonly poolId: number;
}): GenerateGrantObjectOptions {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    resource: {
      id: poolId,
      entityType: EntityTypeApi.POOL,
    },
    grantingEntity: {
      id: generateId(),
      entityType: EntityTypeApi.ADMIN,
    },
    roleId: GrantRole.GROUPS_MANAGER,
    // this mirrors superuser_role_permission
    grantedPermissions: [GrantedPermissionApi.MANAGE_GROUPS],
  };
}

export function loadshiftSupportGrant({
  userId,
  enterpriseId,
}: {
  readonly userId: number;
  readonly enterpriseId: number;
}): GenerateGrantObjectOptions {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    resource: {
      id: enterpriseId,
      entityType: EntityTypeApi.ENTERPRISE,
    },
    grantingEntity: {
      id: generateId(),
      entityType: EntityTypeApi.ADMIN,
    },
    roleId: GrantRole.FREIGHTLANCER,
    grantedPermissions: [
      GrantedPermissionApi.VIEW_ALL_PROJECTS,
      GrantedPermissionApi.VIEW_PROJECT_MILESTONES,
      GrantedPermissionApi.VIEW_PROJECT_THREADS,
      GrantedPermissionApi.VIEW_PRIVATE_BIDS,
      GrantedPermissionApi.VIEW_SUPPORT_SESSIONS,
      GrantedPermissionApi.VIEW_PROJECT_NDA_DETAILS,
    ],
  };
}

/**
 * Grant to enable user to view the dashboard banners page on the partner dashboard of an enterprise
 */
export function bannersManagementGrant({
  userId,
  enterpriseId,
}: {
  readonly userId: number;
  readonly enterpriseId: number;
}): GenerateGrantObjectOptions {
  return {
    entity: {
      id: userId,
      entityType: EntityTypeApi.USER,
    },
    resource: {
      id: enterpriseId,
      entityType: EntityTypeApi.ENTERPRISE,
    },
    grantingEntity: {
      id: generateId(),
      entityType: EntityTypeApi.ADMIN,
    },
    roleId: GrantRole.BANNERS_MANAGER,
    // this mirrors superuser_role_permission
    grantedPermissions: [GrantedPermissionApi.MANAGE_BANNERS],
  };
}
