import { generateId } from '@freelancer/datastore/testing/helpers';
import type { QuotationLogo } from './quotation-logos.model';

export interface GenerateQuotationLogoOptions {
  readonly id?: number;
  readonly creatorId?: number;
  readonly fileUuid?: string;
  readonly createDate?: number;
  readonly isDefault?: boolean;
  readonly url?: string;
}

export function generateQuotationLogoObject({
  id,
  creatorId,
  fileUuid = 'sampleFileUuid',
  createDate = Date.now(),
  isDefault = true,
  url = 'sampleUrl',
}: GenerateQuotationLogoOptions): QuotationLogo {
  return {
    id: id ?? generateId(),
    creatorId: creatorId ?? generateId(),
    fileUuid,
    createDate,
    isDefault,
    url,
  };
}
