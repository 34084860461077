import { generateId } from '@freelancer/datastore/testing/helpers';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { UserBalance } from './user-balances.model';

export type GenerateUserBalanceObjectOptions = Partial<UserBalance>;

export interface GenerateUserBalanceObjectsOptions {
  readonly balances?: readonly GenerateUserBalanceObjectOptions[];
}

export function generateUserBalanceObject({
  id = generateId(),
  currency = generateCurrencyObject(CurrencyCode.USD),
  amount = 567,
  bonusAmount = 234,
  primary = true,
}: GenerateUserBalanceObjectOptions = {}): UserBalance {
  return {
    id,
    currency,
    amount,
    bonusAmount,
    primary,
  };
}

export function generateUserBalanceObjects({
  balances = [
    {
      currency: generateCurrencyObject(CurrencyCode.USD),
      amount: 567,
      bonusAmount: 234,
      primary: true,
    },
  ],
}: GenerateUserBalanceObjectsOptions = {}): readonly UserBalance[] {
  return balances.map(generateUserBalanceObject);
}
