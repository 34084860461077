import type { MapCoordinates } from '@freelancer/datastore';
import type { Country } from '../countries/countries.model';

/**
 * A project's location details.
 * Used on the Project View Page and User Profiles
 */
export interface Location {
  readonly country?: Country;
  readonly city?: string;
  readonly mapCoordinates?: MapCoordinates;
  readonly vicinity?: string;
  readonly administrativeArea?: string;
  readonly administrativeAreaCode?: string;
  readonly fullAddress?: string;
  readonly postalCode?: string;
}

export enum LocationPreset {
  SYDNEY = 'Sydney',
  MELBOURNE = 'Melbourne',
  KUALA_LUMPUR = 'Kuala Lumpur',
  DHAKA = 'Dhaka',
  KARACHI = 'Karachi',
  MUMBAI = 'Mumbai',
  SEATTLE = 'Seattle',
  VATICAN_CITY = 'Vatican City',
  ROME = 'Rome',
}

// Subset of the codes from `countries.seed`
export enum LocationCountryCode {
  AU = 'AU',
  BD = 'BD',
  IN = 'IN',
  IT = 'IT',
  MY = 'MY',
  PK = 'PK',
  US = 'US',
  VA = 'VA',
}
