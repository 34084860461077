import { generateId } from '@freelancer/datastore/testing/helpers';
import { ContextTypeApi, ItemStatusApi } from 'api-typings/payments/payments';
import type { MilestoneDraft } from '../milestone-drafts';
import type { CartItem } from './cart-items.model';

export interface GenerateCartItemsOptions {
  readonly id?: number;
  readonly contextId: string;
  readonly contextType: ContextTypeApi;
  readonly description?: string;
  readonly currencyId: number;
  readonly amount: number;
  readonly status: ItemStatusApi;
  readonly cartId: number;
  readonly useBonus: boolean;
  readonly contextSubType?: number;
  readonly isEscrowRequired?: boolean;
  readonly isTaxIncluded?: boolean;
}

/**
 * Creates a CartItem object.
 */
export function generateCartItemObject({
  id = generateId(),
  contextId,
  contextType,
  description,
  currencyId,
  amount,
  status,
  cartId,
  useBonus,
  contextSubType,
  isEscrowRequired,
  isTaxIncluded,
}: GenerateCartItemsOptions): CartItem {
  return {
    id,
    contextId,
    contextType,
    description,
    currencyId,
    amount,
    status,
    cartId,
    useBonus,
    contextSubType,
    isEscrowRequired,
    isTaxIncluded,
  };
}

export function cartItemFromMilestoneDraft(
  milestoneDraft: MilestoneDraft,
): Pick<
  GenerateCartItemsOptions,
  'contextId' | 'contextType' | 'currencyId' | 'amount' | 'status'
> {
  return {
    contextId: milestoneDraft.id.toString(),
    contextType: ContextTypeApi.MILESTONE,
    currencyId: 1,
    amount: milestoneDraft.amount,
    status: ItemStatusApi.NOT_DONE,
  };
}
