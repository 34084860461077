import { NgModule } from '@angular/core';
import { BackendModule } from '@freelancer/datastore/core';
import { StoreModule } from '@ngrx/store';
import { campaignsBackend } from './campaigns.backend';
import { campaignsReducer } from './campaigns.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('campaigns', campaignsReducer),
    BackendModule.forFeature('campaigns', campaignsBackend),
  ],
})
export class DatastoreCampaignsModule {}
