import type {
  GroupCreatorTypeApi,
  GroupStatusApi,
  GroupTypeApi,
} from 'api-typings/groups/groups';

/**
 * It represents a group
 */
export interface Group {
  readonly id: number;
  readonly name: string;
  readonly creatorId: number;
  readonly creatorType: GroupCreatorTypeApi;
  readonly status: GroupStatusApi;
  readonly seoUrl: string;
  readonly groupType: GroupTypeApi;
  readonly description?: string;
  readonly created: number;
  readonly isDeleted: boolean;
  readonly updated: number;
  readonly isFeedRead?: boolean;
  // The webapp can only order on 1 value currently, so we
  // need to combine the two values into one integer to
  // represent the correct ordering.
  readonly orderByFeedReadLatestTimeInMs: number;
  readonly coverImageUrl?: string;
  readonly lastActiveTimeInMs?: number;
  readonly isPublic: boolean;
  readonly poolIds?: readonly number[];
}

// This is used to allow us to order by multiple values in the datastore.
// If the feed is unread we add this number to the latest_active_time to ensure
// the unread groups are at the top of the list.
// DO NOT CHANGE THIS VALUE: we need to ensure that the ordering value does not
// exceed the MAX_SAFE_INTEGER.
export const UNREAD_FEED_VALUE = 100_000_000_000;
