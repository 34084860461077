import { generateId } from '@freelancer/datastore/testing/helpers';
import { ValidationTypeApi } from 'api-typings/resources/custom_field_validation';
import { FieldType } from '../custom-field-info-configurations';
import type {
  CustomFieldValidationValue,
  CustomFieldValidation,
} from './custom-field-validations.model';

export interface CustomFieldValidationOptions {
  readonly id?: number;
  readonly collectionType: string;
  readonly collectionId: number;
  readonly customFieldId?: number;
  readonly validationType?: ValidationTypeApi;
  readonly validationValueType?: FieldType;
  readonly partialValues?: boolean;
  readonly validationValues?: readonly CustomFieldValidationValue[];
}

export function generateCustomFieldValidation({
  id = generateId(),
  customFieldId = generateId(),
  collectionType,
  collectionId,
  validationType = ValidationTypeApi.ONE_OF,
  validationValueType = FieldType.STRING,
  partialValues = false,
  validationValues = [],
}: CustomFieldValidationOptions): CustomFieldValidation {
  return {
    id,
    customFieldId,
    collectionType,
    collectionId,
    validationType,
    validationValueType,
    partialValues,
    validationValues,
  };
}

// --- Mixins ---
export function oneOfValidationValues(
  validationValueType: FieldType,
  ...values: readonly any[]
): Pick<
  CustomFieldValidationOptions,
  'validationType' | 'validationValueType' | 'validationValues'
> {
  return {
    validationType: ValidationTypeApi.ONE_OF,
    validationValueType,
    validationValues: values.map(value => ({
      id: generateId(),
      value: {
        type: validationValueType,
        value,
      },
    })),
  };
}
