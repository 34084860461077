import { generateId } from '@freelancer/datastore/testing/helpers';
import type { EntryUpgradesApi } from 'api-typings/contests/contests';
import type {
  ContestEntry,
  ContestEntryFile,
} from '../contest-entries/contest-entries.model';
import { mapStatusTextToNumber } from '../contest-entries/contest-entries.transformers';
import type { ContestViewEntry } from './contest-view-entries.model';
import { ContestEntryHasFeedbackStatus } from './contest-view-entries.model';

export interface GenerateContestViewEntriesOptions {
  readonly entries: readonly ContestEntry[];

  readonly isLiked?: boolean;
  readonly upgrades?: EntryUpgradesApi;
  readonly files?: readonly ContestEntryFile[];
  readonly hasFeedback?: ContestEntryHasFeedbackStatus;
  readonly commentCount?: number;
}

export function generateContestViewEntryObjects({
  entries,
  isLiked = false,
  files = [],
  hasFeedback = ContestEntryHasFeedbackStatus.NO,
  commentCount = 0,
}: GenerateContestViewEntriesOptions): readonly ContestViewEntry[] {
  return entries.map(entry => ({
    ...entry,
    isLiked,
    hasFeedback,
    commentCount,
    files: files.length ? files : generateFiles(),
    seoUrl: `contest/${entry.contestId}`, // FIXME: T267853 - real seoUrl has contest title
    statusNumber: mapStatusTextToNumber(entry.status),
    rating: entry.rating ?? 0, // default values from transformer
    likeCount: entry.likeCount ?? 0,
    freelancerRating: 0, // FIXME: T267853 -
  }));
}

export function generateFiles(): readonly ContestEntryFile[] {
  const id = generateId();
  return [
    {
      id,
      name: `Contest file ${id}`,
      fileUrl: 'assets/freelancer-logo-open-graph.png',
      thumbnailUrl: 'assets/freelancer-logo-open-graph.png',
      thumbnail420Url: 'assets/freelancer-logo-open-graph.png',
      thumbnail900Url: 'assets/freelancer-logo-open-graph.png',
      thumbnail80Url: 'assets/freelancer-logo-open-graph.png',
      thumbnail80FixUrl: 'assets/freelancer-logo-open-graph.png',
      thumbnailDigestUrl: 'assets/freelancer-logo-open-graph.png',
      thumbnailPreview: 'assets/freelancer-logo-open-graph.png',
    },
  ];
}

// --- Mixins ---
