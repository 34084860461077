import type { IconColor } from '@freelancer/ui/icon';

export enum RatingTicksColor {
  PRIMARY = 'primary',
  SUCCESS = 'success',
}

export type RatingColor =
  | IconColor.DARK
  | IconColor.INHERIT
  | IconColor.LIGHT
  | IconColor.WHITE;

export enum RatingType {
  STARS = 'stars',
  TICKS = 'ticks',
}
