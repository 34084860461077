import { generateId } from '@freelancer/datastore/testing/helpers';
import { RoleApi } from 'api-typings/common/common';
import { phpSkill } from '../skills/skills.seed';
import type { ReviewSkill, ReviewsSkills } from './reviews-skills.model';

export interface GenerateReviewSkillOptions {
  readonly skills: readonly ReviewSkill[];
  readonly toUser?: number;
  readonly role?: RoleApi;
}

export function generateReviewSkillObject({
  skills = [{ id: phpSkill().id, name: phpSkill().name }],
  toUser,
  role = RoleApi.FREELANCER,
}: GenerateReviewSkillOptions): ReviewsSkills {
  return {
    id: generateId().toString(),
    skills,
    toUser,
    role,
  };
}
