import { generateId } from '@freelancer/datastore/testing/helpers';
import type { FlareCheckpoint } from './flare-checkpoints.model';

export interface GenerateFlareCheckpoint {
  readonly id?: number;
  readonly name: string;
  readonly description: string;
}

/**
 * Create a Freelancer Automation Rule Engine (FLARE) checkpoint
 */
export function generateFlareCheckpointObject({
  id = generateId(),
  name,
  description,
}: GenerateFlareCheckpoint): FlareCheckpoint {
  return {
    id,
    name,
    description,
  };
}
