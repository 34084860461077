import { generateId } from '@freelancer/datastore/testing/helpers';
import type { TaxStatement } from './tax-statement.model';

export interface GenerateTaxStatementOptions {
  readonly documentDate?: string;
  readonly documentTitle?: string;
}

export function generateTaxStatementObject({
  documentDate = Date.now().toString(),
  documentTitle = 'Test Tax Statement',
}: GenerateTaxStatementOptions): TaxStatement {
  return {
    id: generateId(),
    documentDate,
    documentTitle,
  };
}

export function generateTaxStatementObjects(
  options: readonly GenerateTaxStatementOptions[],
): readonly TaxStatement[] {
  return options.map(option => generateTaxStatementObject(option));
}
