import { generateId } from '@freelancer/datastore/testing/helpers';
import type { Currency } from '../currencies';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type {
  VideoVerificationRequest,
  VideoVerificationRequestWithdrawalDetails,
} from './video-verification-request.model';

export interface GenerateVideoVerificationRequestsOptions {
  readonly userId: number;
  readonly userDocumentSubmissionId?: number;
  readonly contextId: number;
  readonly contextType: string;
  readonly isActive: boolean;
  readonly withdrawalDetails?: VideoVerificationRequestWithdrawalDetails;
}

export interface GenerateVideoVerificationRequestWithdrawalDetailsOptions {
  readonly amount?: number;
  readonly currency?: Currency;
}

export function generateVideoVerificationRequestWithdrawalDetailsObject({
  amount = 100,
  currency = generateCurrencyObject(CurrencyCode.USD),
}: GenerateVideoVerificationRequestWithdrawalDetailsOptions = {}): VideoVerificationRequestWithdrawalDetails {
  return {
    amount,
    currency,
  };
}

export function generateVideoVerificationRequestObject({
  userId,
  userDocumentSubmissionId = undefined,
  contextId,
  contextType,
  isActive = true,
  withdrawalDetails = undefined,
}: GenerateVideoVerificationRequestsOptions): VideoVerificationRequest {
  return {
    id: generateId(),
    userId,
    userDocumentSubmissionId,
    contextId,
    contextType,
    isActive,
    withdrawalDetails,
  };
}

export function withdrawalVideoVerificationRequest(
  amount: number = 100,
  currencyCode: CurrencyCode = CurrencyCode.USD,
): Pick<GenerateVideoVerificationRequestsOptions, 'withdrawalDetails'> {
  return {
    withdrawalDetails: generateVideoVerificationRequestWithdrawalDetailsObject({
      amount,
      currency: generateCurrencyObject(currencyCode),
    }),
  };
}
