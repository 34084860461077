import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  InvitationEmailActionApi,
  InvitationEntityTypeApi,
  InvitationStatusApi,
  InvitationTypeApi,
} from 'api-typings/users/users';
import type { Invitation } from './invitations.model';

export interface GenerateInvitationOptions {
  readonly action?: InvitationEmailActionApi;
  readonly contextId?: number;
  readonly invitationType?: InvitationTypeApi;
  readonly inviteeEntityId?: number;
  readonly inviteeEntityType: InvitationEntityTypeApi;
  readonly inviterUserId: number;
  readonly isInviteeRegistered?: boolean;
  readonly redirectUrl?: string;
  readonly status: InvitationStatusApi;
  readonly inviteeEmail?: string;
}

export type GenerateInvitationsEntityOptions = Omit<
  GenerateInvitationOptions,
  'inviterUserId'
>;

export function registeredUserInvitation(
  inviteeId: number,
  status = InvitationStatusApi.PENDING,
): GenerateInvitationsEntityOptions {
  return {
    inviteeEntityId: inviteeId,
    inviteeEntityType: InvitationEntityTypeApi.USER,
    isInviteeRegistered: true,
    status,
  };
}

export function unregisteredUserInvitation(
  inviteeId: number,
  status = InvitationStatusApi.PENDING,
): GenerateInvitationsEntityOptions {
  return {
    inviteeEntityId: inviteeId,
    inviteeEntityType: InvitationEntityTypeApi.USER,
    isInviteeRegistered: false,
    status,
  };
}

export function openLinkInvitation(
  status = InvitationStatusApi.LINK_OPEN,
): GenerateInvitationsEntityOptions {
  return {
    inviteeEntityType: InvitationEntityTypeApi.OPEN_LINK,
    status,
  };
}

export function restrictedLinkInvitation(
  status = InvitationStatusApi.LINK_OPEN,
): GenerateInvitationsEntityOptions {
  return {
    inviteeEntityType: InvitationEntityTypeApi.RESTRICTED_LINK,
    status,
  };
}

export function generateInvitationObject({
  action = InvitationEmailActionApi.ACCEPT_INVITATION,
  contextId = generateId(),
  invitationType = InvitationTypeApi.PAYMENT_SHARING,
  inviteeEntityId,
  inviteeEntityType,
  inviterUserId,
  isInviteeRegistered,
  redirectUrl,
  status,
  inviteeEmail,
}: GenerateInvitationOptions): Invitation {
  const id = generateId();
  // Only the token payload needs to be encoded for the tests
  const token = `a.${btoa(
    JSON.stringify({
      invitation_id: id,
      action,
      is_invitee_registered: isInviteeRegistered,
    }),
  )}.a`;
  return {
    id,
    contextId,
    invitationType,
    inviteeEntityId,
    inviteeEntityType,
    inviterUserId,
    status,
    timeCreated: Date.now(),
    token,
    redirectUrl,
    inviteeEmail,
  };
}
