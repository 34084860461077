// TODO: T294944 Cleanup datastore.
import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ServiceOfferingShopAttributeApi } from 'api-typings/service_offerings/service_offerings';
import { v4 as uuid } from 'uuid';
import type { ServiceOfferingShop } from './service-offering-shops.model';

export interface GenerateServiceOfferingShopOptions {
  readonly id?: number;
  readonly ownerId?: number;
  readonly name?: string;
  readonly description?: string;
  readonly bannerImageUuid?: string;
  readonly bannerImageUrl?: string;
  readonly attributes?: readonly ServiceOfferingShopAttributeApi[];
  readonly seoUrl?: string;
}

export function generateServiceOfferingShopObject({
  id = generateId(),
  ownerId = generateId(),
  name,
  description,
  bannerImageUuid,
  bannerImageUrl,
  attributes,
  seoUrl,
}: GenerateServiceOfferingShopOptions): ServiceOfferingShop {
  return {
    id,
    ownerId,
    name: name || `Test Shopfront ${id}`,
    description: description || `Test Shopfront Description ${id}`,
    bannerImageUuid: bannerImageUuid || uuid(),
    bannerImageUrl: bannerImageUrl || `freelancer.com`,
    attributes: attributes?.map(attribute => ({
      id: attribute.id,
      shop_id: attribute.shop_id,
      icon: attribute.icon,
      description: attribute.description,
    })),
    seoUrl: seoUrl || `test-shopfront-${id}`,
  };
}
