import { generateId } from '@freelancer/datastore/testing/helpers';
import type { RichTextElement } from '@freelancer/html-renderer';
import { GuidelineContextTypeApi } from 'api-typings/common/common';
import { generateTextHtmlElementObject } from '../content-customization';
import type { Guideline } from './guidelines.model';

export interface GenerateGuidelineOptions {
  readonly id?: number;
  readonly contextId: number;
  readonly contextType: GuidelineContextTypeApi;
  readonly content?: readonly RichTextElement[];
  readonly dateCreated?: number;
}

export function generateGuideline({
  id = generateId(),
  contextId,
  contextType,
  content = [generateTextHtmlElementObject('Guideline content')],
  dateCreated = Date.now(),
}: GenerateGuidelineOptions): Guideline {
  return {
    id,
    contextId,
    contextType,
    content,
    dateCreated,
  };
}

export function contestContext(
  contestId: number,
): Pick<GenerateGuidelineOptions, 'contextType' | 'contextId'> {
  return {
    contextType: GuidelineContextTypeApi.CONTEST,
    contextId: contestId,
  };
}
