import type { UserContestCollaborationWhitelist } from './user-contest-collaboration-whitelist.model';

export interface GenerateUserContestCollaborationWhitelistOption {
  readonly userId: string;
  readonly isWhitelist?: boolean;
}

export function generateUserContestCollaborationWhitelist({
  userId,
  isWhitelist = true,
}: GenerateUserContestCollaborationWhitelistOption): UserContestCollaborationWhitelist {
  return {
    id: userId,
    isWhitelist,
  };
}
