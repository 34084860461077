import { NgModule } from '@angular/core';
import {
  DatastoreCampaignsModule,
  DatastoreUserInteractionsModule,
} from '@freelancer/datastore/collections';
import { UserFeedback } from './user-feedback.service';

@NgModule({
  imports: [DatastoreCampaignsModule, DatastoreUserInteractionsModule],
  providers: [UserFeedback],
})
export class UserFeedbackModule {}
