import { generateId } from '@freelancer/datastore/testing/helpers';
import { ProjectTitleEditRequestStatusApi } from 'api-typings/projects/projects';
import type { ProjectTitleEditRequest } from './project-title-edit-request.model';

export interface GenerateProjectTitleEditRequestOptions {
  readonly id?: number;
  readonly projectId: number;
  readonly newTitle?: string;
  readonly requestedBy: number;
  readonly status?: ProjectTitleEditRequestStatusApi;
}

export function generateProjectTitleEditRequestObject({
  id = generateId(),
  projectId,
  newTitle = 'Default New Project Title',
  requestedBy,
  status = ProjectTitleEditRequestStatusApi.PENDING,
}: GenerateProjectTitleEditRequestOptions): ProjectTitleEditRequest {
  return { id, projectId, newTitle, requestedBy, status };
}
