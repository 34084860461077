import {
  animate,
  keyframes,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { fadeOut } from '../fade';

/**
 * Fades new item in with blue highlight that lingers for some time before
 * disappearing. On the otherhand, removed item will simply fade out.
 *
 * Use when it is important to let users know which items are newly added or
 * removed.
 *
 * Usage:
 *   <!-- Don't animate individual items on initial render -->
 *   <app-parent @blockInitialRenderAnimation>
 *     <app-child *ngFor="let item of items" @listAnimation>
 *       <!-- Template goes here -->
 *     </app-child>
 *   </app-parent>
 */
export const listAnimation = trigger('listAnimation', [
  transition(
    ':enter',
    [
      style({ backgroundColor: 'transparent', opacity: 0 }),
      animate(
        '{{ duration }}ms {{ delay }}ms {{ easing }}',
        keyframes([
          style({ offset: 0, backgroundColor: 'transparent', opacity: 0 }),
          style({
            offset: 0.3,
            backgroundColor: '{{ highlightColor }}',
            opacity: 1,
          }),
          style({ offset: 0.7, backgroundColor: '{{ highlightColor }}' }),
          style({ offset: 1, backgroundColor: 'transparent' }),
        ]),
      ),
    ],
    {
      params: {
        duration: '225',
        delay: '0',
        easing: 'ease-out',
        highlightColor: '#dbf4ff',
      },
    },
  ),
  transition(':leave', [
    useAnimation(fadeOut, {
      params: { duration: '195' },
    }),
  ]),
]);
