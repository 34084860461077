export enum TagStatusColor {
  DEFAULT = 'default',
  BLUE = 'blue',
  GREEN = 'green',
  ORANGE = 'orange',
}

export enum TagStatusSize {
  SMALL = 'small',
  LARGE = 'large',
}

export enum TagStatusBackgroundColor {
  DEFAULT = 'default',
  BLUE = 'blue',
}
