import { randomiseList } from '@freelancer/datastore/testing/helpers';
import { ProjectStatusFromBids } from '@freelancer/project-status';
import type { Bid } from '../bids';
import type { ManageViewOpenBid } from './manage-view-open-bids.model';
import { mapBidStatusOrdering } from './manage-view-open-bids.transformers';

export interface GenerateManageViewOpenBidsOptions {
  readonly bids: readonly Bid[];
  readonly projectStatus?:
    | ProjectStatusFromBids.AWAITING_ACCEPTANCE
    | ProjectStatusFromBids.OPEN
    | ProjectStatusFromBids.PENDING;
}

export function generateManageViewOpenBidsObjects({
  bids = [],
  projectStatus,
}: GenerateManageViewOpenBidsOptions): readonly ManageViewOpenBid[] {
  return bids.map(bid => {
    if (!bid.projectOwnerId) {
      throw new Error('Missing projectOwnerId');
    }

    const bidStatus =
      projectStatus ??
      pickOne<
        | ProjectStatusFromBids.AWAITING_ACCEPTANCE
        | ProjectStatusFromBids.OPEN
        | ProjectStatusFromBids.PENDING
      >(
        [
          ProjectStatusFromBids.AWAITING_ACCEPTANCE,
          ProjectStatusFromBids.OPEN,
          ProjectStatusFromBids.PENDING,
        ],
        bid.id.toString(),
      );

    return {
      id: bid.id,
      projectId: bid.projectId,
      employerId: bid.projectOwnerId,
      bidAmount: bid.amount,
      bidPlacedDate: bid.submitDate,
      recruiter: false,
      awardTime: bid.timeAwarded,
      bidStatus,
      bidStatusOrdering: mapBidStatusOrdering(bidStatus),
    };
  });
}

function pickOne<T>(list: readonly T[], seed: string): T {
  return randomiseList(list, seed)[0];
}
