import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-general-rules',
  template: `
    <fl-bit class="MainContainer">
      <fl-card>
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'large'"
        >
          General Rules
        </fl-heading>
        <fl-bit [flMarginBottom]="'large'">
          <fl-heading
            [headingType]="HeadingType.H2"
            [size]="TextSize.SMALL"
            [flMarginBottom]="'xsmall'"
          >
            No native HTML elements
          </fl-heading>
          <fl-text [flMarginBottom]="'mid'">
            When you're building your page / feature, it is important that you
            avoid using any native HTML elements. We highly encourage that you
            use components in bits to utilise any properties that is available
            (e.g margin inputs).
          </fl-text>
          <fl-heading
            [headingType]="HeadingType.H3"
            [size]="TextSize.SMALL"
            [flMarginBottom]="'small'"
          >
            Examples:
          </fl-heading>
          <fl-list [bordered]="false">
            <fl-list-item>
              <fl-bit class="Content">
                <fl-text>
                  For containers like div, use
                  <fl-link [link]="'/components/bit'">fl-bit</fl-link>
                </fl-text>
              </fl-bit>
            </fl-list-item>
            <fl-list-item>
              <fl-bit class="Content">
                <fl-text>
                  For links, use
                  <fl-link [link]="'/components/link'">fl-link</fl-link>
                </fl-text>
              </fl-bit>
            </fl-list-item>
            <fl-list-item>
              <fl-bit class="Content">
                <fl-text>
                  For Typography, use
                  <fl-link [link]="'/components/heading'">fl-heading</fl-link>
                  and
                  <fl-link [link]="'/components/text'">fl-text</fl-link>
                </fl-text>
              </fl-bit>
            </fl-list-item>
            <fl-list-item>
              <fl-bit class="Content">
                <fl-text>
                  For images use
                  <fl-link [link]="'/components/picture'">fl-picture</fl-link>
                </fl-text>
              </fl-bit>
            </fl-list-item>
          </fl-list>
        </fl-bit>
        <fl-bit
          class="Section"
          [flMarginBottom]="'large'"
        >
          <fl-heading
            [headingType]="HeadingType.H2"
            [size]="TextSize.SMALL"
            [flMarginBottom]="'xsmall'"
          >
            Layout
          </fl-heading>
          <fl-text [flMarginBottom]="'xsmall'">
            The fl-grid and fl-col component combination offers a 12 column grid
            system using Flexbox making it easier to build pages without writing
            your own CSS. It’s fully responsive using a mobile first approach.
            See
            <fl-link [link]="'/foundations/grid'">
              Grid System Documentation</fl-link
            >.
          </fl-text>
          <fl-text>
            With regards to SPACING, all components in bits extends the
            BaseComponent which will enable the components to accepts inputs for
            responsive margins. We are following the Single-Direction Margin
            approach (right and bottom for us) so we don’t have to worry when we
            remove or move components around the page. See
            <fl-link [link]="'/foundations/spacing'"
              >Spacing Documentation</fl-link
            >.
          </fl-text>
        </fl-bit>
        <fl-bit
          class="Section"
          [flMarginBottom]="'large'"
        >
          <fl-heading
            [headingType]="HeadingType.H2"
            [size]="TextSize.SMALL"
            [flMarginBottom]="'xsmall'"
          >
            Customising bit component’s UI
          </fl-heading>
          <fl-text>
            Everyone should only use components that is already available in
            bits and should not be customised without consulting the designers
            and ui-engineering teams first.
          </fl-text>
        </fl-bit>
      </fl-card>
    </fl-bit>
  `,
  styleUrls: [
    '../static-pages.component.scss',
    './general-rules.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralRulesComponent {
  TextSize = TextSize;
  HeadingType = HeadingType;
  ListItemType = ListItemType;
}
