import { generateId } from '@freelancer/datastore/testing/helpers';
import type { UserInteraction } from './user-interactions.model';

export interface GenerateUserInteractionsOptions {
  readonly eventName: string;
  readonly eventId?: number;
  readonly otherUserId?: number;
}

export function generateUserInteractions({
  eventName,
  eventId,
  otherUserId,
}: GenerateUserInteractionsOptions): UserInteraction {
  return {
    id: generateId(),
    eventName,
    eventId,
    otherUserId,
  };
}
