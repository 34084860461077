import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { UserGiveGetDetails } from './user-give-get-details.model';

export interface GenerateUserGiveGetDetailsOptions {
  readonly userId: number;
  readonly username: string;
  readonly currencyCode?: CurrencyCode;
  readonly isPaymentVerified?: boolean;
  readonly needsCCV?: boolean;
  readonly isGiveGetUser?: boolean;
  readonly is3dsVerified?: boolean;
  readonly localizedBonus?: number;
  readonly localizedUnusedBonus?: number;
}

export function generateUserGiveGetDetailsObject({
  userId,
  username,
  currencyCode = CurrencyCode.USD,
  isPaymentVerified = false,
  needsCCV = true,
  isGiveGetUser = true,
  is3dsVerified = false,
  localizedBonus = 20,
  localizedUnusedBonus,
}: GenerateUserGiveGetDetailsOptions): UserGiveGetDetails {
  return {
    id: userId.toString(),
    isEligible: true,
    isEmailVerified: true,
    isPhoneVerified: true,
    isChildPaymentVerified: true,
    localizeReferrerBonus: 20,
    localizedBonus,
    localizedBonusRequirement: 50,
    localizedUnusedBonus,
    referralLink: `/get/${username}`, // FIXME: T267853 - Does this need the hostname?
    userCurrency: generateCurrencyObject(currencyCode),
    isPaymentVerified,
    needsCCV,
    ggSuccessUrl: '/post-project',
    is3dsVerified,
    isGiveGetUser,
  };
}
