import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Seo } from '@freelancer/seo';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { VerticalAlignment } from '@freelancer/ui/grid';
import {
  HeadingColor,
  HeadingType,
  HeadingWeight,
} from '@freelancer/ui/heading';
import { IconColor } from '@freelancer/ui/icon';
import { BackgroundColor, LogoSize } from '@freelancer/ui/logo';
import { FontColor, FontType, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-home',
  template: `
    <fl-bit class="HomeContainer">
      <fl-container>
        <fl-bit
          class="Hero"
          [flMarginBottom]="'xxlarge'"
        >
          <fl-bit
            class="Logo"
            [flMarginBottom]="'xlarge'"
            [flMarginBottomTablet]="'mid'"
          >
            <fl-bit class="Logo-container">
              <fl-logo
                class="Logo-image"
                [backgroundColor]="BackgroundColor.DARK"
                [size]="LogoSize.MID"
              ></fl-logo>
              <span class="Logo-text">bits</span>
            </fl-bit>
          </fl-bit>
          <fl-heading
            [color]="HeadingColor.LIGHT"
            [headingType]="HeadingType.H1"
            [size]="TextSize.XLARGE"
            [sizeTablet]="TextSize.XXLARGE"
            [flMarginBottom]="'xsmall'"
          >
            Freelancer.com Bits
          </fl-heading>
          <fl-heading
            [color]="HeadingColor.LIGHT"
            [headingType]="HeadingType.H2"
            [flMarginBottom]="'xlarge'"
            [size]="TextSize.SMALL"
            [sizeTablet]="TextSize.MID"
            [weight]="HeadingWeight.NORMAL"
          >
            A live showcase of the UI components available in the Angular
            front-end stack
          </fl-heading>
          <fl-bit class="Hero-actions">
            <fl-button
              class="Hero-button"
              [color]="ButtonColor.TRANSPARENT_LIGHT"
              [size]="ButtonSize.LARGE"
              [link]="'/info/introduction'"
              [flMarginBottom]="'mid'"
              [flMarginBottomTablet]="'none'"
              [flMarginRightTablet]="'mid'"
            >
              Explore Bits
            </fl-button>
            <fl-button
              class="Hero-button"
              [color]="ButtonColor.TRANSPARENT_LIGHT"
              [size]="ButtonSize.LARGE"
              [link]="'/skits'"
              [flMarginBottom]="'mid'"
              [flMarginBottomTablet]="'none'"
            >
              Skits Playground
            </fl-button>
          </fl-bit>
        </fl-bit>

        <fl-video
          class="Hero-video"
          [src]="'https://www.f-cdn.com/assets/upload/skits-demo.mp4'"
          [flMarginBottom]="'xxlarge'"
        ></fl-video>

        <fl-bit>
          <fl-grid [vAlign]="VerticalAlignment.VERTICAL_STRETCH">
            <fl-col
              [col]="12"
              [colTablet]="6"
              [flMarginBottom]="'large'"
            >
              <fl-card
                [edgeToEdge]="true"
                class="Features-card"
              >
                <fl-bit class="Features-card-inner">
                  <fl-bit
                    class="Features-figure"
                    [flMarginRight]="'small'"
                    [flMarginRightTablet]="'large'"
                  >
                    <fl-picture
                      alt="Introduction illustration"
                      [src]="'bits/introduction.svg'"
                      [fullWidth]="true"
                    ></fl-picture>
                  </fl-bit>
                  <fl-bit>
                    <fl-heading
                      [size]="TextSize.MID"
                      [headingType]="HeadingType.H3"
                      [flMarginBottom]="'xxsmall'"
                    >
                      Introduction
                    </fl-heading>
                    <fl-text>
                      Bits includes the guides to help you create user
                      interfaces consistent with the Freelancer.com’s, design
                      principles, language, and best practices for working with
                      the front end stack.
                    </fl-text>
                  </fl-bit>
                </fl-bit>
              </fl-card>
            </fl-col>
            <fl-col
              [col]="12"
              [colTablet]="6"
              [flMarginBottom]="'large'"
            >
              <fl-card
                [edgeToEdge]="true"
                class="Features-card"
              >
                <fl-bit class="Features-card-inner">
                  <fl-bit
                    class="Features-figure"
                    [flMarginRight]="'small'"
                    [flMarginRightTablet]="'large'"
                  >
                    <fl-picture
                      alt="Guidelines illustration"
                      [src]="'bits/guidelines.svg'"
                      [fullWidth]="true"
                    ></fl-picture>
                  </fl-bit>
                  <fl-bit>
                    <fl-heading
                      [size]="TextSize.MID"
                      [headingType]="HeadingType.H3"
                      [flMarginBottom]="'xxsmall'"
                    >
                      Guidelines
                    </fl-heading>
                    <fl-text>
                      Constantly keep these design principles in mind when
                      making design decisions to provide a consistent look and
                      feel when designing products in Freelancer.com platform.
                    </fl-text>
                  </fl-bit>
                </fl-bit>
              </fl-card>
            </fl-col>
            <fl-col
              [col]="12"
              [colTablet]="6"
              [flMarginBottom]="'large'"
            >
              <fl-card
                [edgeToEdge]="true"
                class="Features-card"
              >
                <fl-bit class="Features-card-inner">
                  <fl-bit
                    class="Features-figure"
                    [flMarginRight]="'small'"
                    [flMarginRightTablet]="'large'"
                  >
                    <fl-picture
                      alt="Components illustration"
                      [src]="'bits/components.svg'"
                      [fullWidth]="true"
                    ></fl-picture>
                  </fl-bit>
                  <fl-bit>
                    <fl-heading
                      [size]="TextSize.MID"
                      [headingType]="HeadingType.H3"
                      [flMarginBottom]="'xxsmall'"
                    >
                      Components
                    </fl-heading>
                    <fl-text>
                      Bits components provide both visual and functional
                      consistency, helping to make your users feel at home. THey
                      are composed of code, usage and style guidelines.
                    </fl-text>
                  </fl-bit>
                </fl-bit>
              </fl-card>
            </fl-col>
            <fl-col
              [col]="12"
              [colTablet]="6"
              [flMarginBottom]="'large'"
            >
              <fl-card
                [edgeToEdge]="true"
                class="Features-card"
              >
                <fl-bit class="Features-card-inner">
                  <fl-bit
                    class="Features-figure"
                    [flMarginRight]="'small'"
                    [flMarginRightTablet]="'large'"
                  >
                    <fl-picture
                      alt="Patterns illustration"
                      [src]="'bits/patterns.svg'"
                      [fullWidth]="true"
                    ></fl-picture>
                  </fl-bit>
                  <fl-bit>
                    <fl-heading
                      [size]="TextSize.MID"
                      [headingType]="HeadingType.H3"
                      [flMarginBottom]="'xxsmall'"
                    >
                      Patterns
                    </fl-heading>
                    <fl-text>
                      Patterns provide a common language between designers and
                      developers. These are the best practices that help
                      designers as well as developers solve recurring problems
                      when designing products.
                    </fl-text>
                  </fl-bit>
                </fl-bit>
              </fl-card>
            </fl-col>
          </fl-grid>
        </fl-bit>

        <fl-bit class="Upsell">
          <fl-grid>
            <fl-col
              [col]="12"
              [colTablet]="5"
            >
              <fl-picture
                class="Upsell-img"
                alt="Building together"
                [src]="'bits/bits-home-upsell.svg'"
                [fullWidth]="true"
              ></fl-picture>
            </fl-col>
            <fl-col
              [col]="12"
              [colTablet]="6"
              class="Upsell-content"
            >
              <fl-heading
                [color]="HeadingColor.LIGHT"
                [headingType]="HeadingType.H1"
                [size]="TextSize.XLARGE"
                [sizeTablet]="TextSize.XXLARGE"
                [flMarginBottom]="'xsmall'"
              >
                Let's build together
              </fl-heading>
              <fl-heading
                [color]="HeadingColor.LIGHT"
                [headingType]="HeadingType.H2"
                [flMarginBottom]="'mid'"
                [size]="TextSize.SMALL"
                [sizeTablet]="TextSize.MID"
                [weight]="HeadingWeight.NORMAL"
              >
                We welcome all feedback for design, guidelines or implementation
                to improve our design system. If you are interested, feel free
                to contribute.
              </fl-heading>
              <fl-button
                class="Upsell-button"
                [color]="ButtonColor.TRANSPARENT_LIGHT"
                [size]="ButtonSize.LARGE"
                [link]="'/info/introduction'"
              >
                Learn How
              </fl-button>
            </fl-col>
          </fl-grid>
        </fl-bit>
        <fl-bit class="Footer">
          <fl-text
            class="Footer-text"
            [color]="FontColor.INHERIT"
            [size]="TextSize.XXSMALL"
            [sizeTablet]="TextSize.XSMALL"
          >
            <fl-text
              [size]="TextSize.INHERIT"
              [fontType]="FontType.SPAN"
              [color]="FontColor.INHERIT"
            >
              Made with
            </fl-text>
            <fl-icon
              [name]="'ui-heart-v2'"
              [color]="IconColor.ERROR"
            ></fl-icon>
            <fl-text
              [size]="TextSize.INHERIT"
              [fontType]="FontType.SPAN"
              [color]="FontColor.INHERIT"
            >
              by UI Engineering and Design team
            </fl-text>
          </fl-text>
        </fl-bit>
      </fl-container>
    </fl-bit>
  `,
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
  BackgroundColor = BackgroundColor;
  ButtonColor = ButtonColor;
  ButtonSize = ButtonSize;
  HeadingColor = HeadingColor;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  FontColor = FontColor;
  FontType = FontType;
  TextSize = TextSize;
  LogoSize = LogoSize;
  IconColor = IconColor;
  VerticalAlignment = VerticalAlignment;

  constructor(private seo: Seo) {}

  ngOnInit(): void {
    this.seo.setPageTags({
      title: 'Home',
      description: `A live showcase of Freelancer.com's official design system, Bits`,
    });
  }
}
