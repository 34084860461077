import { ChangeDetectionStrategy, Component } from '@angular/core';

// Contains template to animate
@Component({
  selector: 'app-dynamic-demo-content',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./dynamic-demo-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDemoContentComponent {}
