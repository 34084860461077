export enum SectionArticleAlignment {
  LEFT = 'left',
  CENTER = 'center',
}

export enum SectionArticleColor {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum SectionArticleSize {
  MEDIUM = 'medium',
  LARGE = 'large',
}
