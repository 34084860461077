import { generateId } from '@freelancer/datastore/testing/helpers';
import { UserEmailRequestStatusAjax } from './user-email-request.backend-model';
import type { UserEmailRequest } from './user-email-request.model';

export type GenerateUserEmailRequest = Partial<UserEmailRequest>;

export function generateUserEmailRequestObject({
  id = generateId(),
  email = 'test@freelancer.com',
  ipAddress = '18.141.109.231',
  status = UserEmailRequestStatusAjax.PENDING,
}: GenerateUserEmailRequest): UserEmailRequest {
  return {
    id,
    email,
    ipAddress,
    status,
  };
}
