import { generateId } from '@freelancer/datastore/testing/helpers';
import type { SupportTypeApi } from 'api-typings/support/support';
import type { PreferredSupportAgentAssignment } from './preferred-support-agent.model';

export interface GeneratePreferredSupportAgentAssignment {
  readonly id?: number;
  readonly userId: number;
  readonly agentId: number;
  readonly supportType: SupportTypeApi;
  readonly username?: string;
  readonly agentUsername?: string;
}

/**
 * Create a PreferredSupportAgentAssignment object
 */
export function generatePreferredSupportAgentAssignmentObject({
  id = generateId(),
  userId,
  agentId,
  supportType,
  username,
  agentUsername,
}: GeneratePreferredSupportAgentAssignment): PreferredSupportAgentAssignment {
  return {
    id,
    userId,
    agentId,
    supportType,
    username,
    agentUsername,
  };
}
