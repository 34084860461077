import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-brand-style-guide-logo',
  template: `
    <div class="LogoSection">
      <fl-text
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        The Logo
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        The logo is the core element of any Freelancer branded communication. It
        is an instantly recognisable symbol of the brand, and it is important
        that it is used as specified in these guidelines. The
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Freelancer logo is made of a combination of two key elements:
      </fl-text>
      <fl-text
        [weight]="FontWeight.BOLD"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xxsmall'"
      >
        1. The Freelancer bird
      </fl-text>
      <fl-text
        [weight]="FontWeight.BOLD"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        2. The wordmark
      </fl-text>
      <div [flMarginBottom]="'xlarge'">
        <fl-picture
          class="Logo"
          [fullWidth]="true"
          [src]="'brand-guidelines/logo.png'"
        ></fl-picture>
      </div>
      <div
        class="BrandTitle"
        [flMarginBottom]="'small'"
      >
        <fl-text
          [size]="TextSize.XLARGE"
          [sizeDesktop]="TextSize.XXLARGE"
          [weight]="FontWeight.BOLD"
          [flMarginRight]="'small'"
        >
          The freelancer bird
        </fl-text>
        <fl-picture [src]="'brand-guidelines/logo-small.png'"></fl-picture>
      </div>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        In certain circumstances we prefer to use the Freelancer bird icon on
        its own rather than the full logo. These may be physical situations
        where horizontal space may be an issue, or design driven situations
        where we want the logo to appear more recessive, acting more like a
        subtle sign-off.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        If the Freelancer bird is going to be used on its own, please ensure
        that the Freelancer brand is established through other means ie. use the
        full logo elsewhere, in imagery, in copy or tone of voice, as outlined
        throughout this document.
      </fl-text>
      <fl-text
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [weight]="FontWeight.BOLD"
        [flMarginBottom]="'small'"
      >
        Logo colours
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        The Freelancer logo, black and blue, is our primary logo colorway and
        should be used on white and light coloured backgrounds.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        The Freelancer logo, black and blue, is our primary logo colorway and
        should be used on white and light coloured backgrounds.The Freelancer
        logo, white and blue, should be used on black or dark coloured
        backgrounds.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
        [weight]="FontWeight.BOLD"
      >
        The logo is always black and blue or white and blue.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        Never use the logo all white, all black or all blue.
      </fl-text>

      <div
        class="LogoContainer"
        [flMarginBottom]="'xlarge'"
      >
        <fl-picture
          class="LogoImgSection"
          [fullWidth]="true"
          [src]="'brand-guidelines/logo-colours-b.png'"
        ></fl-picture>
        <fl-picture
          class="LogoImgSection"
          [fullWidth]="true"
          [src]="'brand-guidelines/logo-colours-w.png'"
        ></fl-picture>
      </div>
      <fl-text
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Exclusion zone
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        The Exclusion Zone ensures the legibility and impact of the Logo by
        isolating it from competing visual elements such as text and supporting
        graphics.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        This zone should be considered as the absolute minimum safe distance. In
        most cases the Logo should be given even more room to breath.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        The Exclusion Zone is equal to the horizontal distance of the letter 'a'
        from the Freelancer logo.
      </fl-text>
      <div
        class="LogoContainer"
        [flMarginBottom]="'xlarge'"
      >
        <div
          [flMarginRightTablet]="'mid'"
          [flMarginBottom]="'small'"
          [flMarginBottomTablet]="'none'"
        >
          <fl-picture
            class="LargeZoneImg"
            [src]="'brand-guidelines/zone-large.png'"
            [fullWidth]="true"
          ></fl-picture>
        </div>
        <fl-picture [src]="'brand-guidelines/zone-short.png'"></fl-picture>
      </div>
      <fl-text
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [weight]="FontWeight.BOLD"
        [flMarginBottom]="'small'"
      >
        Logo misuse
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        It is important that the appearance of the Logo remains consistent.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        The Logo should not be misinterpreted, modified, or added to. No attempt
        should be made to alter the Logo in any way. Its orientation, colour and
        composition should remain as indicated in this document — there are no
        exceptions.
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        To illustrate this point, some of the more likely mistakes are shown on
        this page.
      </fl-text>
      <fl-grid [flMarginBottom]="'small'">
        <fl-col
          [col]="6"
          [colTablet]="3"
        >
          <div class="MisuseColumnsContainer">
            <fl-picture
              class="MisuseColumns"
              [flMarginBottom]="'small'"
              [src]="'brand-guidelines/c1.png'"
            ></fl-picture>
            <fl-text
              [size]="TextSize.SMALL"
              [sizeDesktop]="TextSize.LARGE"
              [flMarginBottom]="'small'"
            >
              Do not in any way manipulate the relationship between the bird and
              the text. Do not reposition where the bird sits relative to the
              text.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="6"
          [colTablet]="3"
        >
          <div class="MisuseColumnsContainer">
            <fl-picture
              class="MisuseColumns"
              [src]="'brand-guidelines/c2.png'"
              [flMarginBottom]="'small'"
            ></fl-picture>
            <fl-text
              [size]="TextSize.SMALL"
              [sizeDesktop]="TextSize.LARGE"
              [flMarginBottom]="'small'"
            >
              Do not separate the bird from the text. The logo consists of the
              bird and text.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="6"
          [colTablet]="3"
        >
          <div class="MisuseColumnsContainer">
            <fl-picture
              class="MisuseColumns"
              [flMarginBottom]="'small'"
              [src]="'brand-guidelines/c5.png'"
            ></fl-picture>
            <fl-text
              [size]="TextSize.SMALL"
              [sizeDesktop]="TextSize.LARGE"
              [flMarginBottom]="'small'"
            >
              Do not make the logo one colour.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="6"
          [colTablet]="3"
        >
          <div class="MisuseColumnsContainer">
            <fl-picture
              class="MisuseColumns"
              [src]="'brand-guidelines/c6.png'"
              [flMarginBottom]="'small'"
            ></fl-picture>
            <fl-text
              [size]="TextSize.SMALL"
              [sizeDesktop]="TextSize.LARGE"
              [flMarginBottom]="'small'"
            >
              Do not outline or keyline around the logo.
            </fl-text>
          </div>
        </fl-col>
      </fl-grid>
      <fl-grid>
        <fl-col
          [col]="6"
          [colTablet]="3"
        >
          <div class="MisuseColumnsContainer">
            <fl-picture
              class="MisuseColumns"
              [flMarginBottom]="'small'"
              [src]="'brand-guidelines/c3.png'"
            ></fl-picture>
            <fl-text
              [size]="TextSize.SMALL"
              [sizeDesktop]="TextSize.LARGE"
              [flMarginBottom]="'small'"
            >
              Do not switch the colours of the bird or text.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="6"
          [colTablet]="3"
        >
          <div class="MisuseColumnsContainer">
            <fl-picture
              class="MisuseColumns"
              [src]="'brand-guidelines/c4.png'"
              [flMarginBottom]="'small'"
            ></fl-picture>
            <fl-text
              [size]="TextSize.SMALL"
              [sizeDesktop]="TextSize.LARGE"
              [flMarginBottom]="'small'"
            >
              Do not rotate the logo.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="6"
          [colTablet]="3"
        >
          <div class="MisuseColumnsContainer">
            <fl-picture
              class="MisuseColumns"
              [flMarginBottom]="'small'"
              [src]="'brand-guidelines/c7.png'"
            ></fl-picture>
            <fl-text
              [size]="TextSize.SMALL"
              [sizeDesktop]="TextSize.LARGE"
              [flMarginBottom]="'small'"
            >
              Do not apply a gradient to the bird or text.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="6"
          [colTablet]="3"
        >
          <div class="MisuseColumnsContainer">
            <fl-picture
              class="MisuseColumns"
              [src]="'brand-guidelines/c8.png'"
              [flMarginBottom]="'small'"
            ></fl-picture>
            <fl-text
              [size]="TextSize.SMALL"
              [sizeDesktop]="TextSize.LARGE"
              [flMarginBottom]="'small'"
            >
              Do not change the typeface or otherwise recreate the text. Use
              only the provided files.
            </fl-text>
          </div>
        </fl-col>
      </fl-grid>

      <fl-text
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [weight]="FontWeight.BOLD"
        [flMarginBottom]="'small'"
      >
        Logo lockups
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        We have sub brands of Freelancer.<br />Freelancer font is Neo Sans Std
        Black Italic.<br />Sub brand font is FF Clan Regular.
      </fl-text>
      <fl-picture
        class="Enterprise"
        [flMarginBottom]="'small'"
        [fullWidth]="true"
        [src]="'brand-guidelines/enterprise.png'"
      ></fl-picture>
      <fl-picture
        class="Global"
        [fullWidth]="true"
        [src]="'brand-guidelines/global-fleet.png'"
      ></fl-picture>
    </div>
  `,
  styleUrls: ['./../brand-style-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandStyleGuideLogoComponent {
  FontWeight = FontWeight;
  TextSize = TextSize;
}
