import type { GroupPermissionApi } from 'api-typings/groups/groups';
import { GROUP_DEFAULT_PERMISSIONSApi } from 'api-typings/groups/groups';
import type { GroupPermissions } from './group-permissions.model';

export interface GenerateGroupPermissionsOptions {
  readonly groupId: number;
  readonly permissions: readonly GroupPermissionApi[];
}

export function generateGroupPermissionsObject({
  groupId,
  permissions,
}: GenerateGroupPermissionsOptions): readonly GroupPermissions[] {
  return [
    {
      id: groupId,
      permissions,
    },
  ];
}

export function adminPermissions(): readonly GroupPermissionApi[] {
  return Object.keys({
    ...GROUP_DEFAULT_PERMISSIONSApi.admin,
  }).map(groupPermission => groupPermission as unknown as GroupPermissionApi);
}

export function moderatorPermissions(): readonly GroupPermissionApi[] {
  return Object.keys({ ...GROUP_DEFAULT_PERMISSIONSApi.moderator }).map(
    groupPermission => groupPermission as unknown as GroupPermissionApi,
  );
}

export function memberPermissions(): readonly GroupPermissionApi[] {
  return Object.keys({ ...GROUP_DEFAULT_PERMISSIONSApi.member }).map(
    groupPermission => groupPermission as unknown as GroupPermissionApi,
  );
}

export function guestPermissions(): readonly GroupPermissionApi[] {
  return Object.keys({ ...GROUP_DEFAULT_PERMISSIONSApi.guest }).map(
    groupPermission => groupPermission as unknown as GroupPermissionApi,
  );
}

export function mutedPermissions(): readonly GroupPermissionApi[] {
  return Object.keys({ ...GROUP_DEFAULT_PERMISSIONSApi.moderator }).map(
    groupPermission => groupPermission as unknown as GroupPermissionApi,
  );
}
