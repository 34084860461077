import { generateId } from '@freelancer/datastore/testing/helpers';
import { AttachmentAttachedToTypeApi } from 'api-typings/groups/groups';
import type { GroupAttachment } from './group-attachments.model';

export interface GroupAttachmentOptions {
  readonly id: number;
  readonly fileName?: string;
  readonly attachedToId?: number;
  readonly contentType?: string;
  readonly feedItemId?: number;
}
export interface GenerateGroupAttachmentsOptions {
  readonly groupId: number;
  readonly ownerId: number;
  readonly attachments: readonly GroupAttachmentOptions[];
}

export function generateGroupAttachmentsObjects(
  groupAttachmentsOptions: GenerateGroupAttachmentsOptions,
): readonly GroupAttachment[] {
  const now = Date.now();
  return groupAttachmentsOptions.attachments.map(fileOptions => {
    return {
      id: `${fileOptions.id}`,
      groupId: groupAttachmentsOptions.groupId,
      fileName: fileOptions.fileName ?? `test_file_${fileOptions.id}.txt`,
      fileSize: 40_000,
      attachedToType: AttachmentAttachedToTypeApi.POST,
      attachedToId: fileOptions.attachedToId ?? generateId(),
      userId: groupAttachmentsOptions.ownerId,
      created: now,
      contentType: fileOptions.contentType ?? 'text/plain',
      feedItemId: fileOptions.feedItemId ?? generateId(),
    };
  });
}
