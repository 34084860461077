import { generateId } from '@freelancer/datastore/testing/helpers';
import type { BidPitch } from './bid-pitch.model';

export interface GenerateBidPitchOptions {
  readonly bidId: number;
  readonly fileId?: string;
}

export function generateBidPitchObject({
  bidId,
  fileId = '1a190988-0784-4d3e-ba9d-64eb725a0ea9',
}: GenerateBidPitchOptions): BidPitch {
  return {
    id: generateId(),
    bidId,
    fileId,
  };
}
