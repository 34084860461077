import { Location } from '@angular/common';
import type { OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { QueryParams } from '@freelancer/ui/link';
import type { Observable, Subscription } from 'rxjs';
import { combineLatest, ReplaySubject } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { PictureObjectFit } from '../picture';
import {
  QueryParamsHandling,
  TabsBorder,
  TabsColor,
  TabsDirection,
  TabsSize,
} from './tab-item.types';

@Component({
  selector: 'fl-tab-item-action',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabItemActionComponent {}

@Component({
  selector: 'fl-tab-item',
  template: `
    <ng-container *ngIf="link || fragment; else listTab">
      <a
        class="TabItem"
        *ngIf="currentUrl$ | flAsync as currentUrl"
        [attr.data-border]="border"
        [attr.data-border-persist]="borderPersist"
        [attr.data-color]="color"
        [attr.data-selected]="isSelected"
        [attr.data-direction]="direction"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-full-width]="fullWidth"
        [attr.data-text-wrap]="textWrap"
        [attr.data-truncate]="truncate"
        [attr.data-has-tab-item-action]="!!tabItemActionComponent"
        [attr.data-subtitle-provided]="!!this.subtitleText"
        [routerLink]="link"
        [queryParams]="queryParams"
        [queryParamsHandling]="queryParamsHandling"
        [fragment]="fragment"
        [replaceUrl]="preserveBackButton"
      >
        <ng-container *ngTemplateOutlet="tabContent"></ng-container>
      </a>
    </ng-container>

    <ng-template #listTab>
      <button
        class="TabItem"
        [attr.data-border]="border"
        [attr.data-border-persist]="borderPersist"
        [attr.data-color]="color"
        [attr.data-direction]="direction"
        [attr.data-selected]="selected"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-full-width]="fullWidth"
        [attr.data-text-wrap]="textWrap"
        [attr.data-truncate]="truncate"
        [attr.data-has-tab-item-action]="!!tabItemActionComponent"
        [attr.data-subtitle-provided]="!!this.subtitleText"
      >
        <ng-container *ngTemplateOutlet="tabContent"></ng-container>
      </button>
    </ng-template>

    <ng-template #tabContent>
      <fl-icon
        class="Icon"
        *ngIf="iconName"
        [color]="
          iconColor
            ? iconColor
            : color === TabsColor.FOREGROUND
            ? IconColor.FOREGROUND
            : color === TabsColor.DARK
            ? IconColor.DARK
            : IconColor.LIGHT
        "
        [name]="iconName"
        [size]="iconSize"
        [useIconFont]="useIconFont"
      ></fl-icon>
      <fl-picture
        class="Picture"
        *ngIf="!iconName && imageSrc"
        alt="Tab Item Image"
        i18n-alt="Tab Item Image Placeholder"
        [src]="imageSrc"
        [objectFit]="PictureObjectFit.SCALE_DOWN"
      ></fl-picture>
      <ng-container *ngIf="tabItemActionComponent; else titleContent">
        <div
          class="Title"
          [attr.data-truncate]="truncate"
        >
          {{ titleText }}
          <div
            *ngIf="subtitleText"
            class="SubtitleText"
          >
            {{ subtitleText }}
          </div>
        </div>
        <div class="ActionOnHover">
          <ng-content select="fl-tab-item-action"></ng-content>
        </div>
      </ng-container>
      <ng-content></ng-content>
    </ng-template>

    <ng-template #titleContent>
      {{ titleText }}
      <div
        *ngIf="subtitleText"
        class="SubtitleText"
      >
        {{ subtitleText }}
      </div>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./tab-item.component.scss'],
})
export class TabItemComponent implements OnInit, OnChanges, OnDestroy {
  IconColor = IconColor;
  PictureObjectFit = PictureObjectFit;
  TabsColor = TabsColor;
  TabsDirection = TabsDirection;

  /*
    TAB PROPERTIES
    Handling selected tab will need to be done by the component using the tabs.
  */
  @Input() titleText: string;
  @Input() subtitleText?: string;
  @Input() selected = false;
  @Input() iconName?: string;
  /** Set to true when using font icon */
  @Input() useIconFont = false;

  /** Path of an image to be displayed. Will be iconsized */
  @Input() imageSrc?: string;
  @Input() fullWidth = false;
  /** note: the router link MUST be absolute. */
  @Input() link?: string;
  /** if the route has children, we do a startWith match instead of an exact match */
  @Input() routeHasChildren?: boolean;
  @Input() queryParams?: QueryParams;
  /** when a tab is clicked and has a router link, should it preserve the query parameters
   *  from the current route in the new route
   */
  @Input() queryParamsHandling?: QueryParamsHandling;
  /** Anchor Fragment */
  @Input() fragment?: string;
  /**
   * Whether to maintain the current back button location after switching tabs.
   * Only set this to `false` if the tabs conceptually represent different views/pages
   * and not just smaller parts of a top-level view.
   */
  @Input() preserveBackButton = true;
  @Input() borderPersist = false;
  @Input() textWrap = false;
  @Input() truncate = false;

  /**
   * Allows to override the icon colour and
   * let you to set up different colour for
   * the tab items.
   */
  @Input() iconColor?: IconColor;

  @Input() iconSize: IconSize = IconSize.MID;

  /*
    STYLE PROPERTIES
    These are set automatically by the parent `tabs` component by using @ContentChildren
  */
  color = TabsColor.FOREGROUND;
  @HostBinding('attr.data-direction') direction = TabsDirection.ROW;
  size = TabsSize.LARGE;
  sizeTablet: TabsSize;
  sizeDesktop: TabsSize;
  @HostBinding('attr.data-border') border = TabsBorder.NONE;

  @Output() onSelected = new EventEmitter<ElementRef>();
  @HostBinding('attr.role') role = 'tab';
  @HostBinding('attr.tabindex') tabIndex = -1;

  currentUrl$: Observable<string>;
  private currentUrlSubscription?: Subscription;
  public isSelected = false;

  private linkSubject$ = new ReplaySubject<string>(1);

  @HostBinding('attr.data-selected')
  @HostBinding('attr.aria-selected')
  get selectedState(): boolean {
    return this.isSelected;
  }

  @ContentChild(TabItemActionComponent)
  tabItemActionComponent: TabItemActionComponent;

  constructor(
    private location: Location,
    private router: Router,
    public element: ElementRef,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    this.isSelected = this.selected;

    if (this.selected) {
      this.onSelected.emit(this.element);
    }

    if (this.link) {
      this.linkSubject$.next(this.link);
    }
  }

  ngOnInit(): void {
    this.currentUrl$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.location.path()),
      startWith(this.location.path()),
      // we don't support query params and fragments so just ignore them in the router url
      map(url => url.split('?')[0].split('#')[0].split(';')[0]),
      // Decode special characters
      map(decodeURI),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.currentUrlSubscription = combineLatest([
      this.currentUrl$,
      this.linkSubject$.asObservable(),
    ]).subscribe(([currentUrl, _]) => {
      this.isSelected = this.routeMatch(currentUrl);

      if (this.isSelected) {
        this.onSelected.emit(this.element);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentUrlSubscription) {
      this.currentUrlSubscription.unsubscribe();
    }
  }

  routeMatch(currentUrl: string): boolean {
    if (!this.link) {
      return false;
    }

    return this.routeHasChildren
      ? currentUrl.startsWith(this.link)
      : currentUrl === this.link;
  }
}
