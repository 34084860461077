import type { ProfileViewUser } from '../profile-view-users';
import type { ProjectViewUser } from '../project-view-users';
import type { UserInsignias } from './user-insignias.model';

type BadgeInformation = Pick<
  UserInsignias,
  'insigniaId' | 'name' | 'description'
>;

export interface GenerateUserInsigniasOptions {
  readonly user: ProfileViewUser | ProjectViewUser;
  readonly badges: readonly BadgeInformation[];
}

export function doGooderBadge(): BadgeInformation {
  return {
    insigniaId: 747,
    name: 'do-gooder',
    description: 'Users participating in the Clean up the world challenge',
  };
}

export function foundationShareholderBadge(): BadgeInformation {
  return {
    insigniaId: 694,
    name: 'foundation_shareholder',
    description: 'Freelancer Foundation Shareholder',
  };
}

export function generateUserInsignias({
  user,
  badges,
}: GenerateUserInsigniasOptions): readonly UserInsignias[] {
  return badges.map(badge => ({
    id: `${badge.insigniaId}-${user.id}`,
    insigniaId: badge.insigniaId,
    userId: user.id,
    name: badge.name,
    description: badge.description,
  }));
}
