import { generateId } from '@freelancer/datastore/testing/helpers';
import type { CartApprovalStatusApi } from 'api-typings/payments/payments';
import type { CartApproval } from './cart-approvals.model';

export interface GenerateCartApprovalsOptions {
  readonly cartId: number;
  readonly status: CartApprovalStatusApi;
  readonly comment?: string;
  readonly billingAgreementId?: number;
}

export function generateCartApprovalObject({
  cartId,
  status,
  comment,
  billingAgreementId = generateId(),
}: GenerateCartApprovalsOptions): CartApproval {
  return {
    id: generateId(),
    cartId,
    status,
    comment,
    timeModified: Date.now(),
    billingAgreementId,
  };
}
