import { generateId } from '@freelancer/datastore/testing/helpers';
import type { AttachmentFile } from './post-files.model';

interface FileOptions {
  readonly id?: number; // so we can order the files
  readonly fileName: string;
  readonly contentType: string;
}
interface GeneratePostFilesOptions {
  readonly postOwnerId: number;
  readonly files: readonly FileOptions[];
}

export function generatePostFilesObjects(
  postFilesOptions: GeneratePostFilesOptions,
): readonly AttachmentFile[] {
  const now = Date.now();
  return postFilesOptions.files.map(fileOptions => {
    const id = `${fileOptions.id}` ?? `${generateId()}`;
    return {
      id,
      fileName: fileOptions.fileName,
      userId: postFilesOptions.postOwnerId,
      contentType: fileOptions.contentType,
      fileSize: 40_000,
      isTemp: false,
      s3Bucket: 'flndev-syd-post-files',
      s3Obj: `${postFilesOptions.postOwnerId}/${id}`,
      created: now,
      updated: now,
      downloadUrl: `https://www.syd1.fln-dev.net/api/posts/0.1/files/${id}`,
    };
  });
}
