import type {
  BackendPushResponse,
  DatastoreCollectionType,
  DatastoreDeleteCollectionType,
  DatastoreFetchCollectionType,
  DatastorePushCollectionType,
  DatastoreSetCollectionType,
  DatastoreUpdateCollectionType,
  Ordering,
} from '@freelancer/datastore/core';
import type { NonObservableQuery } from './non-observable-query';
import type {
  DeleteRequestErrorCode,
  FetchRequestErrorCode,
  MutationPropagator,
  PushRequestErrorCode,
  PushTransformer,
  SearchTransformer,
  SetRequestErrorCode,
  UpdateRequestErrorCode,
  UpdateTransformer,
} from './store.model';

export type IdOrIdsOrQuery<C extends DatastoreCollectionType> =
  | string
  | number
  | readonly string[]
  | readonly number[]
  | ((q: NonObservableQuery<C>) => NonObservableQuery<C>);

/** Methods on DatastoreFake to be used by UI tests. */
export abstract class DatastoreTestingController {
  /** Used to work out if we should put functions on the window */
  abstract platformId: Object;

  /**
   * Creates an object directly in the store, without transformations.
   */
  abstract createRawDocument<
    C extends DatastoreCollectionType & DatastorePushCollectionType,
  >(
    collectionName: C['Name'],
    document: C['DocumentType'],
  ): Promise<BackendPushResponse<any>>;

  /**
   * Removes data from an entire collection, or an object from a collection.
   * When called with no arguments, resets the whole datastore.
   */
  abstract resetState<C extends DatastoreCollectionType>(
    collectionName?: C['Name'],
  ): Promise<void>;

  /**
   * Output store state for debugging.
   */
  abstract printRawState(): Promise<void>;

  /**
  /**
   * Output store state for debugging.
   */
  abstract printState(collectionName?: string): Promise<void>;
  abstract printState(collectionNames: readonly string[]): Promise<void>;

  /**
   * Returns if the datastore has no state for that
   */
  abstract isStateEmpty(): Promise<boolean>;

  /**
   * Add a transformer that will be used when creating new documents of a given
   * collection. Used to specify computed fields that would be returned by a
   * real backend, e.g. object ID.
   */
  abstract addPushTransformer<
    C extends DatastoreCollectionType & DatastorePushCollectionType,
  >(collectionName: C['Name'], transformer: PushTransformer<C>): void;

  /**
   * Add a transformer that will be used when updating documents of a given
   * collection. Used to replicate reducers that use the backend response rather
   * than the delta.
   */
  abstract addUpdateTransformer<
    C extends DatastoreCollectionType & DatastoreUpdateCollectionType,
  >(collectionName: C['Name'], transformer: UpdateTransformer<C>): void;

  /**
   * Add a propagator that will be used when pushing or updating documents of a
   * given collection. Used to specify that mutations to the original collection
   * also apply to the related collection, e.g. bids and projectViewBids are
   * related, so pushing to the former should also push to the latter.
   */
  abstract addMutationPropagator<
    C1 extends DatastoreCollectionType & DatastorePushCollectionType,
    C2 extends DatastoreCollectionType & DatastorePushCollectionType,
  >(propagator: MutationPropagator<C1, C2>): void;

  /**
   * Add a transformer that will be used when search queries are made on a given
   * collection. The transformer allows returning custom search results from
   * existing documents in the store. If one is provided, the order of documents
   * will be preserved.
   */
  abstract addSearchTransformer<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
    transformer: SearchTransformer<C>,
    order?: Ordering<C>,
  ): void;

  /**
   * Make all fetch requests to a particular datastore collection fail.
   */
  abstract makeCollectionFailFetch<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(collectionName: C['Name'], errorCode: FetchRequestErrorCode<C>): void;

  /**
   * Clear fetch request fails set by makeCollectionFailFetch.
   */
  abstract clearCollectionFailFetch(): void;

  /**
   * Make all push requests to a particular datastore collection fail.
   * This includes calls to Datastore.createDocument().
   */
  abstract makeCollectionFailPush<
    C extends DatastoreCollectionType & DatastorePushCollectionType,
  >(collectionName: C['Name'], errorCode: PushRequestErrorCode<C>): void;

  /**
   * Clear push request fails set by makeCollectionFailPush.
   */
  abstract clearCollectionFailPush(): void;

  /**
   * Make all set requests to a particular datastore collection fail.
   */
  abstract makeCollectionFailSet<
    C extends DatastoreCollectionType & DatastoreSetCollectionType,
  >(collectionName: C['Name'], errorCode: SetRequestErrorCode<C>): void;

  /**
   * Clear set request fails set by makeCollectionFailSet.
   */
  abstract clearCollectionFailSet(): void;

  /**
   * Make all update requests to a particular datastore collection fail.
   */
  abstract makeCollectionFailUpdate<
    C extends DatastoreCollectionType & DatastoreUpdateCollectionType,
  >(collectionName: C['Name'], errorCode: UpdateRequestErrorCode<C>): void;

  /**
   * Clear update request fails set by markCollectionFailUpdate.
   */
  abstract clearCollectionFailUpdate(): void;

  /**
   * Make all delete requests to a particular datastore collection fail.
   */
  abstract makeCollectionFailDelete<
    C extends DatastoreCollectionType & DatastoreDeleteCollectionType,
  >(collectionName: C['Name'], errorCode: DeleteRequestErrorCode<C>): void;

  /**
   * Clear delete request fails set by makeCollectionFailDelete.
   */
  abstract clearCollectionFailDelete(): void;

  /**
   * Make a fetch request to a collection fail when the collection is empty.
   */
  abstract makeCollectionFailWhenEmpty<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(collectionName: C['Name']): void;

  /**
   * Clear fetch request fails set by makeCollectionFailWhenEmpty.
   */
  abstract clearCollectionFailWhenEmpty(): void;

  /**
   * Make requests to the given collection delayed by the given amount of milliseconds.
   */
  abstract makeCollectionDelayed<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
    delayMilliseconds: number,
    requestType: 'fetch' | 'push' | 'set' | 'update' | 'delete',
  ): void;

  /**
   * Clear request delays set by makeCollectionDelayed.
   */
  abstract clearCollectionDelayed(): void;

  /**
   * Make a specific fetch request to the datastore fail.
   */
  abstract makeRequestFail<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(
    collectionName: C['Name'],
    idOrIdsOrQuery: IdOrIdsOrQuery<C>,
    errorCode: FetchRequestErrorCode<C>,
  ): void;

  /**
   * Clear set fetch request fails set by makeRequestFail.
   */
  abstract clearRequestFail(): void;

  /**
   * Make all fetch requests to a particular datastore collection never return.
   */
  abstract makeCollectionPending<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(collectionName: C['Name']): void;

  /**
   * Clear fetch request fails set by makeCollectionPending.
   */
  abstract clearCollectionPending(): void;

  /**
   * Make a specific request to the datastore never return.
   */
  abstract makeRequestPending<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(collectionName: C['Name'], idOrIdsOrQuery: IdOrIdsOrQuery<C>): void;

  /**
   * Clear request fails set by makeRequestPending.
   */
  abstract clearRequestPending(): void;
}
