import type { OnChanges, SimpleChange } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { OnlineIndicatorSize } from './online-indicator.types';

@Component({
  selector: 'fl-online-indicator',
  template: ``,
  styleUrls: ['./online-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnlineIndicatorComponent implements OnChanges {
  @HostBinding('attr.aria-label')
  ariaLabel: string;

  @Input() username: string;

  @Input()
  @HostBinding('attr.data-is-online')
  isOnline: boolean;

  @Input()
  @HostBinding('attr.data-size')
  size = OnlineIndicatorSize.SMALL;

  @Input()
  @HostBinding('attr.data-size-tablet')
  sizeTablet?: OnlineIndicatorSize;

  @Input()
  @HostBinding('attr.data-size-desktop')
  sizeDesktop?: OnlineIndicatorSize;

  @Input()
  @HostBinding('attr.data-border')
  border = false;

  private updateAriaLabel(): void {
    this.ariaLabel = `${this.username || 'User'} is ${
      this.isOnline ? 'online' : 'offline'
    }.`;
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if ('username' in changes || 'isOnline' in changes) {
      this.updateAriaLabel();
    }
  }
}
