import { ProjectStatusFromBids } from '@freelancer/project-status';
import type { Bid } from '../bids';
import type { Project } from '../projects';
import type { ManageViewOpenProject } from './manage-view-open-projects.model';

export interface GenerateManageViewOpenProjectOptions {
  readonly project: Project;
  readonly projectStatus:
    | ProjectStatusFromBids.AWAITING_ACCEPTANCE
    | ProjectStatusFromBids.OPEN
    | ProjectStatusFromBids.PENDING;
  readonly bidCount: number;
  readonly bidAvg: number;
  readonly bidderId: number;
  readonly bidEndDate: number;
}

export interface GenerateManageViewOpenProjectsOptions {
  readonly projects: readonly Project[];
  readonly projectStatus:
    | ProjectStatusFromBids.AWAITING_ACCEPTANCE
    | ProjectStatusFromBids.OPEN
    | ProjectStatusFromBids.PENDING;
  readonly bids: readonly Bid[];
  readonly bidderId?: number;
  readonly bidEndDate?: number;
}

export function generateManageViewOpenProject(
  options: GenerateManageViewOpenProjectOptions,
): ManageViewOpenProject {
  return {
    id: options.project.id,
    title: options.project.title,
    seoUrl: options.project.seoUrl,
    projectType: options.project.type,
    recruiter: options.project.upgrades.assisted,
    bidCount: options.bidCount,
    bidAvg: options.bidAvg,
    budget: options.project.budget,
    currencyCode: options.project.currency.code,
    bidderId: options.bidderId,
    bidEndDate: options.bidEndDate,
    isHireMe: options.project.hireme,
    isInsourceProject: options.project.isInsourceProject,
    timeSubmitted: options.project.timeSubmitted,
    projectStatus: options.projectStatus,
  };
}

export function generateManageViewOpenProjects({
  projects,
  projectStatus = ProjectStatusFromBids.AWAITING_ACCEPTANCE,
  bids,
  bidderId,
  bidEndDate,
}: GenerateManageViewOpenProjectsOptions): readonly ManageViewOpenProject[] {
  const bidAvg =
    Math.round(
      (bids.map(bid => bid.amount).reduce((sum, amount) => sum + amount, 0) /
        bids.length) *
        100,
    ) / 100;
  return projects.map(project =>
    generateManageViewOpenProject({
      project,
      projectStatus,
      bidCount: bids.length,
      bidAvg,
      bidderId: bidderId ?? bids[0].id,
      bidEndDate:
        bidEndDate ??
        bids[0].awardExpireTime ??
        Date.now() + 2 * 24 * 60 * 60 * 1000,
    }),
  );
}
