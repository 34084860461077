import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import type { AbstractControl } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { CheckboxColor, CheckboxSize } from '@freelancer/ui/checkbox';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import type { Subscription } from 'rxjs';
import type { RadioOptionItem } from '../radio';
import { RadioSize } from '../radio';
import { FontColor } from '../text';
import {
  ListItemInputAlignment,
  ListItemPadding,
  ListItemSelectedType,
  ListItemType,
} from './list-item.types';

@Component({
  selector: 'fl-list-item-header',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemHeaderComponent {}

@Component({
  selector: 'fl-list-item-body',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemBodyComponent {}

@Component({
  selector: 'fl-list-item',
  template: `
    <div
      class="BitsListItem"
      [attr.data-full-height]="fullHeight"
      [attr.data-transparent]="transparent"
      [attr.data-type]="listItemType"
      [attr.data-color-font]="fontColor"
    >
      <ng-template #content>
        <ng-content></ng-content>
      </ng-template>
      <ng-container [ngSwitch]="listItemType">
        <ng-container *ngSwitchCase="ListItemType.ORDERED">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="ListItemType.UNORDERED">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div
            class="BitsListItemContainer"
            [ngClass]="{
              Bordered: bordered && (bottomBorder || !last),
              Selected:
                showAsSelected || ((selectable || selectByKeyboard) && active),
              Indent: indent,
              Card:
                ((selectable || selectByKeyboard) &&
                  radioValue === undefined) ||
                card
            }"
            [attr.data-full-height]="fullHeight"
            [attr.data-selected-type]="selectedType"
            [attr.data-type]="listItemType"
          >
            <div
              class="BitsListItemHeader"
              [ngClass]="{
                HasHoverState: (isFormInput || clickable) && control?.enabled,
                OuterPadding: outerPadding,
                First: first,
                Last: last
              }"
              [attr.data-full-height]="fullHeight"
              [attr.data-horizontal-padding-desktop]="horizontalPaddingDesktop"
              [attr.data-horizontal-padding]="horizontalPadding"
              [attr.data-padding-desktop]="paddingDesktop"
              [attr.data-padding]="padding"
              (click)="onClick($event)"
            >
              <div
                class="Left"
                *ngIf="isFormInput"
                [ngSwitch]="listItemType"
                [attr.data-input-alignment]="inputAlignment"
              >
                <div class="Mask"></div>

                <ng-container *ngSwitchCase="ListItemType.RADIO">
                  <fl-radio
                    *ngIf="isRadioControl(control)"
                    class="Radio"
                    [control]="control"
                    [options]="toRadioOptions(radioValue)"
                    [forListItem]="true"
                    [size]="radioSize"
                  ></fl-radio>
                </ng-container>

                <ng-container *ngSwitchCase="ListItemType.CHECKBOX">
                  <fl-checkbox
                    *ngIf="isCheckboxControl(control)"
                    class="Checkbox"
                    [control]="control"
                    [color]="checkboxColor"
                    [forListItem]="true"
                    [size]="checkboxSize"
                  ></fl-checkbox>
                </ng-container>

                <ng-container *ngSwitchCase="ListItemType.TOGGLE">
                  <fl-toggle
                    *ngIf="isCheckboxControl(control)"
                    class="Toggle"
                    [control]="control"
                    [forListItem]="true"
                  ></fl-toggle>
                </ng-container>
              </div>

              <div
                class="BitsListItemContent"
                *ngIf="!body && !header"
              >
                <ng-container *ngTemplateOutlet="content"></ng-container>
              </div>

              <div
                class="Header"
                [ngClass]="{ DefaultExpandable: !isFormInput }"
                *ngIf="header"
              >
                <ng-content select="fl-list-item-header"></ng-content>
              </div>

              <div class="Right">
                <fl-icon
                  *ngIf="listItemType === ListItemType.DISMISS"
                  [name]="'ui-close'"
                  [size]="IconSize.SMALL"
                  [title]="'Dismiss List Item Icon'"
                  (click)="onDismiss()"
                ></fl-icon>
                <fl-icon
                  class="ExpandIcon"
                  [ngClass]="{ IsActive: active }"
                  *ngIf="!isFormInput && expandable && body"
                  [name]="'ui-chevron-down'"
                  [size]="IconSize.SMALL"
                >
                </fl-icon>
              </div>
            </div>

            <div
              class="BitsListItemBody"
              [class.BodyEdgeToEdge]="bodyEdgeToEdge"
              [class.IsHidden]="!body || !(active || !expandable)"
              [attr.data-padding]="padding"
              [attr.data-padding-desktop]="paddingDesktop"
              [attr.data-horizontal-padding]="horizontalPadding"
              [attr.data-horizontal-padding-desktop]="horizontalPaddingDesktop"
            >
              <ng-content select="fl-list-item-body"></ng-content>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent<T> implements OnChanges, OnDestroy {
  // Imported types
  ListItemType = ListItemType;
  IconColor = IconColor;
  IconSize = IconSize;

  listItemType = ListItemType.DEFAULT;

  // FIXME: T300116 Enable hydration. Currently fails with NG0501
  // due (maybe) to the ListComponent assigning values to its children
  @HostBinding('attr.ngSkipHydration') ngSkipHydration = true;

  // Host bindings
  @HostBinding('attr.role')
  get ariaRole(): string {
    return this.selectable || this.isFormInput ? 'option' : 'listitem';
  }

  @HostBinding('attr.aria-selected')
  get ariaSelected(): boolean | undefined {
    return this.selectable || this.isFormInput ? this.active : undefined;
  }

  // Inputs
  /** Mandatory for Checkbox, Radio and Toggle; optional for any expandable or selectable item */
  @Input() control: FormControl<
    // Allow boolean only (checkbox) if radioValue is undefined
    [T] extends [undefined] ? boolean : T | undefined
    // eslint-disable-next-line local-rules/no-untyped-form
  > = new FormControl();
  /** If this is set, the control will be treated like a radio (set to value instead of `true`/`false`) */
  @Input() radioValue?: T;
  @Input() radioSize = RadioSize.SMALL;
  @Input() checkboxColor?: CheckboxColor = CheckboxColor.SECONDARY;
  @Input() checkboxSize = CheckboxSize.SMALL;

  // Inputs passed from `List`
  @Input()
  set type(value: ListItemType) {
    this.listItemType = value;
    this.isFormInput = [
      ListItemType.RADIO,
      ListItemType.CHECKBOX,
      ListItemType.TOGGLE,
    ].includes(value);
  }

  @Input() clickable = false;
  @Input() expandable = false;

  @Input() bodyEdgeToEdge = false;
  @Input() bordered = true;
  @Input() fullHeight = false;
  @Input() horizontalPadding?: ListItemPadding;
  @Input() horizontalPaddingDesktop?: ListItemPadding;
  @Input() outerPadding = true;
  @Input() padding = ListItemPadding.XSMALL;
  @Input() paddingDesktop?: ListItemPadding;
  @Input() selectByKeyboard = false;
  @Input() selectable = false;
  @Input() selectedType?: ListItemSelectedType;
  @Input() transparent = false;

  @Input() first = false;
  @Input() last = false;
  @Input() bottomBorder = false;
  @Input() indent = false;

  /**
   * Show item as selected,
   * use this when implementing customized select functions.
   */
  @Input() showAsSelected = false;

  /** Form Input (radio, checkbox, toggle) alignment */
  @Input() inputAlignment: ListItemInputAlignment =
    ListItemInputAlignment.CENTER;

  @Input() card = false;

  /**
   * Override the font color set from the parent list component.
   */
  @Input() fontColor: FontColor = FontColor.INHERIT;

  // Outputs
  @Output() dismiss = new EventEmitter<void>();
  @Output() headerClicked = new EventEmitter<Event>();
  @Output() selected = new EventEmitter<void>();

  @ContentChild(ListItemHeaderComponent)
  header: ListItemHeaderComponent;
  @ContentChild(ListItemBodyComponent)
  body: ListItemBodyComponent;

  private controlSubscription?: Subscription;
  private _active = false;
  isFormInput = false;

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.outerPadding && (this.isFormInput || this.clickable)) {
      throw new Error('Lists with a hover state must have outer padding!');
    }
    if ('control' in changes) {
      this.controlSubscription?.unsubscribe();
      this.controlSubscription = this.control?.valueChanges.subscribe(_ => {
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

  onClick(event: Event): void {
    // not a clickable thing: only emit headerClicked.
    if (!this.isFormInput && !this.clickable) {
      this.headerClicked.emit(event);
      return;
    }

    this.select();
  }

  get active(): boolean {
    // if we're given a radioValue, we treat the control like a radio
    if (this._active) return true;

    if (this.radioValue !== undefined && this.isRadioControl(this.control)) {
      return this.control.value === this.radioValue;
    }

    if (this.isCheckboxControl(this.control)) {
      return this.control.value;
    }

    return false;
  }

  onDismiss(): void {
    this.dismiss.emit();
  }

  select(): void {
    this.selected.emit();

    if (this.control.enabled) {
      // if we're given a radioValue, we treat the control like a radio
      if (this.radioValue !== undefined && this.isRadioControl(this.control)) {
        this.control.setValue(this.radioValue);
      } else if (this.isCheckboxControl(this.control)) {
        this.control.setValue(!this.control.value);
      }
      this.control.markAsDirty();
    }
  }

  highlight(): void {
    this._active = true;
  }

  deactivate(): void {
    this._active = false;
  }

  isCheckboxControl(control: AbstractControl): control is FormControl<boolean> {
    return this.radioValue === undefined;
  }

  isRadioControl(control: AbstractControl): control is FormControl<T> {
    return this.radioValue !== undefined;
  }

  toRadioOptions(radioValue?: T): RadioOptionItem<T>[] {
    return radioValue
      ? [
          {
            displayText: String(radioValue),
            value: radioValue,
          },
        ]
      : [];
  }
}
