import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
} from '@angular/core';
import { AnimationBehavior } from '@freelancer/directives';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { ContainerSize } from '@freelancer/ui/container';
import { HeadingColor, HeadingType } from '@freelancer/ui/heading';
import { PictureLoadMethod } from '@freelancer/ui/picture';
import { FontColor, TextSize } from '@freelancer/ui/text';
import {
  SectionArticleAlignment,
  SectionArticleColor,
  SectionArticleSize,
} from './section-article.types';

@Component({
  selector: 'fl-section-article-title',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionArticleTitleComponent {}

@Component({
  selector: 'fl-section-article-description',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionArticleDescriptionComponent {}

@Component({
  selector: 'fl-section-article-entry-heading',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionArticleEntryHeadingComponent {}

@Component({
  selector: 'fl-section-article-entry-detail',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionArticleEntryDetailComponent {}

@Component({
  selector: 'fl-section-article-button',
  template: `
    <fl-button
      class="SectionArticleButton"
      [color]="color"
      [size]="ButtonSize.LARGE"
      [sizeDesktop]="ButtonSize.XLARGE"
      [link]="link"
      [queryParams]="queryParams"
    >
      <ng-content></ng-content>
    </fl-button>
  `,
  styleUrls: ['./section-article-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionArticleButtonComponent {
  ButtonSize = ButtonSize;

  @Input() color: ButtonColor = ButtonColor.DEFAULT;
  @Input() link: string;
  @Input() queryParams?: { [k: string]: any };
}

@Component({
  selector: 'fl-section-article-entry',
  template: `
    <div
      [flAnimateOnScroll]="animateOnScroll"
      [flAnimateOnScrollBehavior]="animateOnScrollBehavior"
      [flMarginBottom]="'mid'"
      [flMarginBottomTablet]="'none'"
    >
      <div
        *ngIf="pictureSrc"
        [flMarginBottom]="'xxsmall'"
      >
        <fl-picture
          class="Illustration"
          [src]="pictureSrc"
          [alt]="pictureAlt"
          [loadMethod]="PictureLoadMethod.LAZY"
          [width]="pictureExplicitWidth"
          [height]="pictureExplicitHeight"
        ></fl-picture>
      </div>
      <fl-heading
        [color]="
          color === SectionArticleColor.DARK
            ? HeadingColor.FOREGROUND
            : HeadingColor.LIGHT
        "
        [headingType]="HeadingType.H3"
        [size]="TextSize.MARKETING_SMALL"
        [sizeDesktop]="TextSize.MID"
        [flMarginBottom]="'xxsmall'"
      >
        <ng-content select="fl-section-article-entry-heading"></ng-content>
      </fl-heading>
      <fl-text
        [color]="
          color === SectionArticleColor.DARK
            ? FontColor.FOREGROUND
            : FontColor.LIGHT
        "
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.MARKETING_SMALL"
      >
        <ng-content select="fl-section-article-entry-detail"></ng-content>
      </fl-text>
    </div>
  `,
  styleUrls: ['./section-article-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionArticleEntryComponent {
  AnimationBehavior = AnimationBehavior;
  FontColor = FontColor;
  HeadingColor = HeadingColor;
  HeadingType = HeadingType;
  TextSize = TextSize;
  SectionArticleColor = SectionArticleColor;
  PictureLoadMethod = PictureLoadMethod;

  @Input() color: SectionArticleColor = SectionArticleColor.DARK;
  @Input() pictureSrc: string;
  @Input() pictureExplicitWidth?: number;
  @Input() pictureExplicitHeight?: number;
  @Input() pictureAlt: string;
  @Input() animateOnScroll = false;
  @Input() animateOnScrollBehavior: AnimationBehavior =
    AnimationBehavior.FADE_FROM_BOTTOM;
}

@Component({
  selector: 'fl-section-article',
  template: `
    <fl-container [size]="containerSize">
      <fl-grid *ngIf="sectionArticleTitleComponent">
        <fl-col
          class="SectionArticle-heading"
          [col]="12"
        >
          <div
            [flAnimateOnScroll]="animateOnScroll"
            [flAnimateOnScrollBehavior]="animateOnScrollBehavior"
          >
            <fl-heading
              [color]="
                color === SectionArticleColor.DARK
                  ? HeadingColor.FOREGROUND
                  : HeadingColor.LIGHT
              "
              [headingType]="HeadingType.H2"
              [size]="TextSize.LARGE"
              [sizeTablet]="TextSize.XLARGE"
              [sizeDesktop]="TextSize.XXLARGE"
              [flMarginBottom]="
                sectionArticleDescriptionComponent ? 'xxsmall' : 'large'
              "
              [flMarginBottomTablet]="
                sectionArticleDescriptionComponent ? 'small' : 'large'
              "
            >
              <ng-content select="fl-section-article-title"></ng-content>
            </fl-heading>
            <fl-text
              *ngIf="sectionArticleDescriptionComponent"
              [color]="
                color === SectionArticleColor.DARK
                  ? FontColor.FOREGROUND
                  : FontColor.LIGHT
              "
              [size]="TextSize.MARKETING_SMALL"
              [sizeDesktop]="TextSize.MID"
              [flMarginBottom]="'large'"
              [displayLineBreaks]="true"
            >
              <ng-content select="fl-section-article-description"></ng-content>
            </fl-text>
          </div>
        </fl-col>
      </fl-grid>
      <fl-grid
        [attr.data-text-align]="alignment"
        [attr.data-text-align-tablet]="alignmentTablet"
        [attr.data-text-align-desktop-small]="alignmentDesktopSmall"
        [attr.data-alignment]="alignment"
        [flMarginBottom]="sectionArticleButtonComponent ? 'xlarge' : 'none'"
      >
        <ng-content></ng-content>
      </fl-grid>
      <fl-grid
        class="SectionArticle-button"
        [flAnimateOnScroll]="animateOnScroll"
        [flAnimateOnScrollBehavior]="animateOnScrollBehavior"
      >
        <fl-col [col]="12">
          <ng-content select="fl-section-article-button"></ng-content>
        </fl-col>
      </fl-grid>
    </fl-container>
  `,
  styleUrls: ['./section-article.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionArticleComponent {
  AnimationBehavior = AnimationBehavior;
  FontColor = FontColor;
  HeadingColor = HeadingColor;
  HeadingType = HeadingType;
  TextSize = TextSize;
  SectionArticleColor = SectionArticleColor;

  @HostBinding('attr.data-text-align')
  @Input()
  alignment: SectionArticleAlignment;
  @HostBinding('attr.data-text-align-tablet')
  @Input()
  alignmentTablet?: SectionArticleAlignment;
  @HostBinding('attr.data-text-align-desktop-small')
  @Input()
  alignmentDesktopSmall?: SectionArticleAlignment;
  @Input() containerSize: ContainerSize = ContainerSize.DESKTOP_LARGE;
  @Input() animateOnScroll = false;
  @Input() animateOnScrollBehavior: AnimationBehavior =
    AnimationBehavior.FADE_FROM_BOTTOM;

  @HostBinding('attr.data-size')
  @Input()
  size?: SectionArticleSize;

  @Input() color: SectionArticleColor = SectionArticleColor.DARK;

  @ContentChild(SectionArticleTitleComponent)
  sectionArticleTitleComponent: SectionArticleTitleComponent;
  @ContentChild(SectionArticleDescriptionComponent)
  sectionArticleDescriptionComponent: SectionArticleDescriptionComponent;
  @ContentChild(SectionArticleButtonComponent)
  sectionArticleButtonComponent: SectionArticleButtonComponent;
}
