import { generateId } from '@freelancer/datastore/testing/helpers';
import type { VerificationFile } from './verification-files.model';
import {
  VerificationFileSource,
  VerificationProofType,
} from './verification-files.model';

export interface GenerateVerificationFilesOptions {
  readonly verificationRequestId: number;
  readonly verificationProofTypes?: readonly VerificationProofType[];
}

export function generateVerificationFilesObject({
  verificationRequestId,
  verificationProofTypes = [
    VerificationProofType.TYPE_PROOF_ID,
    VerificationProofType.TYPE_PROOF_KEYCODE,
    VerificationProofType.TYPE_PROOF_ADDR,
  ],
}: GenerateVerificationFilesOptions): readonly VerificationFile[] {
  return [
    {
      id: generateId(),
      fileId: generateId(),
      verificationRequestId,
      name: 'test_file_name_front.png',
      proofType: VerificationProofType.TYPE_PROOF_ID,
      source: VerificationFileSource.DESKTOP_FILE,
      date: Date.now(),
    },
    {
      id: generateId(),
      fileId: generateId(),
      verificationRequestId,
      name: 'test_file_name_back.png',
      proofType: VerificationProofType.TYPE_PROOF_ID,
      source: VerificationFileSource.DESKTOP_FILE,
      date: Date.now(),
    },
    {
      id: generateId(),
      fileId: generateId(),
      verificationRequestId,
      name: 'test_address_file_name.png',
      proofType: VerificationProofType.TYPE_PROOF_ADDR,
      source: VerificationFileSource.DESKTOP_FILE,
      date: Date.now(),
    },
    {
      id: generateId(),
      fileId: generateId(),
      verificationRequestId,
      name: 'test_keycode_file_name.png',
      proofType: VerificationProofType.TYPE_PROOF_KEYCODE,
      source: VerificationFileSource.DESKTOP_FILE,
      date: Date.now(),
    },
  ].filter(verificationFilesObject =>
    verificationProofTypes.includes(verificationFilesObject.proofType),
  );
}
