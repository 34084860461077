import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  ProjectStatusApi,
  ProjectSubStatusApi,
  ProjectTypeApi,
} from 'api-typings/projects/projects';
import type { ProjectBidStats } from '../projects/projects.model';
import type {
  ProjectSeo,
  ProjectSeoBid,
  ProjectSeoLinkedJob,
  ProjectSeoLocation,
  ProjectSeoSimilarProjectWidget,
} from './projects-seo.model';

export interface GenerateProjectSeoOptions {
  readonly bids: readonly ProjectSeoBid[];
  readonly bidStats: ProjectBidStats;
  readonly isHourly: boolean;
  readonly isLocal: boolean;
  readonly location: ProjectSeoLocation;
  readonly projectId: number;
  readonly selectedBids: readonly ProjectSeoBid[];
  readonly seoUrl: string;
  readonly startTime: number;
  readonly status: ProjectStatusApi;
  readonly subStatus: ProjectSubStatusApi;
  readonly similarJobs: readonly ProjectSeoLinkedJob[];
  readonly title: string;
  readonly similarProjectWidget: ProjectSeoSimilarProjectWidget;
}

export function generateProjectSeo({
  bids,
  bidStats,
  isHourly,
  isLocal,
  location,
  projectId,
  selectedBids,
  seoUrl,
  startTime,
  status,
  subStatus,
  similarJobs,
  title,
  similarProjectWidget,
}: Partial<GenerateProjectSeoOptions>): ProjectSeo {
  return {
    id: seoUrl ?? 'website-design/website-for-store',
    projectId: projectId ?? generateId(),
    bids: bids ?? [
      {
        username: 'sferrerdevtest1',
        profileLogoUrl:
          'https://sferrer.syd1.fln-dev.net/ppic/4880740/logo/51633618/profile_logo_51633618.jpg',
        amount: 500,
        period: 7,
        sellerRating: {
          average: 0,
          reviewCount: 0,
        },
        description: 'Thank you for the opportunity to submit ...',
        address: {
          city: 'Singapore',
          country: 'Timor-Leste',
          countryCode: 'tl',
        },
        userEarningsPercentage: 0,
        completed: false,
      },
    ],
    bidStats: bidStats ?? {
      bidCount: 1,
      bidAvg: 500,
    },
    breadcrumb: {
      skill: 'eCommerce',
      url: 'ecommerce',
    },
    budget: {
      min: 250,
      max: 750,
    },
    client: {
      registrationTime: 1_666_244_218_000,
      address: {
        city: 'Tuy',
        country: 'Philippines',
        countryCode: 'ph',
      },
      rating: {
        average: 0,
        reviewCount: 0,
      },
      verification: {
        paymentVerified: true,
        emailVerified: true,
        profileComplete: false,
        phoneVerified: true,
        depositMade: false,
      },
    },
    currencyDetails: {
      id: 1,
      code: 'USD',
      sign: '$',
      name: 'US Dollar',
      exchangeRate: 1,
      country: 'US',
      isExternal: false,
    },
    daysLeft: -121,
    description:
      'I am looking for a custom-built website for my store with an e-commerce focus. Ideal candidates will have experience building custom websites from scratch. The website must have user account creation and management capabilities. Additionally, it needs to have a shopping cart and checkout feature. A product search and filter feature would also be beneficial. Please provide examples of previous custom-built websites you have created.',
    endTime: 1_686_537_597_000,
    formattedBudget: '$250-750 USD',
    hoursLeft: -2883,
    type: isHourly ? ProjectTypeApi.HOURLY : ProjectTypeApi.FIXED,
    isLocal: isLocal ?? false,
    latestActivityTime: 1_686_812_805_000,
    location: location ?? undefined,
    otherEmployerJobs: [
      {
        title: 'Create me a wonderful website - 1599711980152',
        seoUrl: '/projects/website-design/tell-what-you-need-done-19003013',
        formattedBudget: '$30-250 USD',
      },
    ],
    previousProject: {
      title:
        'fe2512f2ce74c5b64d3eb061766f76c0 API TEST 5f2c1310-69d8-488b-adb5-471e6531f249 ',
      seoUrl: 'php/api-test-adb-22460079',
    },
    nextProject: {
      title: 'Build a website for my coffee shop',
      seoUrl: 'php/build-website-for-coffee-shop-22460081',
    },
    selectedBids: selectedBids ?? [],
    similarJobs: similarJobs ?? [
      {
        title: 'Create me a wonderful website - 1597309638562',
        seoUrl: '/projects/website-design/tell-what-you-need-done-19003013',
        formattedBudget: '$30-250 USD',
      },
      {
        title: 'Websocket notification for the project search page',
        seoUrl: '/projects/php/websocket-notification-for-the-project-19601985',
        formattedBudget: '$20-50 USD',
      },
    ],
    skills: [
      {
        id: 137,
        name: 'eCommerce',
        category: {
          id: 1,
        },
        local: false,
        seoUrl: 'ecommerce',
        activeProjectCount: 8,
      },
      {
        id: 20,
        name: 'Graphic Design',
        category: {
          id: 3,
        },
        local: false,
        seoUrl: 'graphic-design',
        activeProjectCount: 116_107,
      },
    ],
    startTime: startTime ?? 1_685_932_797_000,
    status: status ?? ProjectStatusApi.CLOSED,
    subStatus: subStatus ?? ProjectSubStatusApi.CANCEL_ADMIN,
    title: title ?? 'A website for my store',
    seoUrl: seoUrl ?? 'website-design/website-for-store',
    similarProjectWidget,
  };
}
