import { assertNever } from '@freelancer/utils';
import type { Currency } from './currencies.model';
import { CurrencyCode } from './currencies.model';

export function generateCurrencyObjects(): readonly Currency[] {
  return [
    CurrencyCode.USD,
    CurrencyCode.NZD,
    CurrencyCode.AUD,
    CurrencyCode.GBP,
    CurrencyCode.HKD,
    CurrencyCode.SGD,
    CurrencyCode.EUR,
    CurrencyCode.CAD,
    CurrencyCode.INR,
    /**
     * The current backend will return TKN currency if user has
     * token currency enabled pools, even though TKN is an
     * external currency and this collection excludes external currencies
     */
    CurrencyCode.TKN,
  ].map(code => generateCurrencyObject(code));
}

export function generateCurrencyObject(currencyCode: CurrencyCode): Currency {
  switch (currencyCode) {
    case CurrencyCode.USD:
      return {
        code: 'USD',
        country: 'US',
        id: 1,
        name: 'US Dollar',
        sign: '$',
        isExternal: false,
        exchangeRate: 1,
      };
    case CurrencyCode.NZD:
      return {
        code: 'NZD',
        country: 'NZ',
        id: 2,
        name: 'New Zealand Dollar',
        sign: '$',
        isExternal: false,
        exchangeRate: 0.625_965,
      };
    case CurrencyCode.AUD:
      return {
        code: 'AUD',
        country: 'AU',
        id: 3,
        name: 'Australian Dollar',
        sign: '$',
        isExternal: false,
        exchangeRate: 0.652_512,
      };
    case CurrencyCode.GBP:
      return {
        code: 'GBP',
        country: 'UK',
        id: 4,
        name: 'British Pounds',
        sign: '£',
        isExternal: false,
        exchangeRate: 1.248_337,
      };
    case CurrencyCode.HKD:
      return {
        code: 'HKD',
        country: 'HK',
        id: 5,
        name: 'HongKong Dollar',
        sign: '$',
        isExternal: false,
        exchangeRate: 0.122_203,
      };
    case CurrencyCode.SGD:
      return {
        code: 'SGD',
        country: 'SG',
        id: 6,
        name: 'Singapore Dollar',
        sign: '$',
        isExternal: false,
        exchangeRate: 0.704_349,
      };
    case CurrencyCode.EUR:
      return {
        code: 'EUR',
        country: 'EU',
        id: 8,
        name: 'Euro',
        sign: '€',
        isExternal: false,
        exchangeRate: 1.0534,
      };
    case CurrencyCode.CAD:
      return {
        code: 'CAD',
        country: 'CA',
        id: 9,
        name: 'Canadian Dollar',
        sign: '$',
        isExternal: false,
        exchangeRate: 0.722_093,
      };
    case CurrencyCode.INR:
      return {
        code: 'INR',
        country: 'IN',
        id: 11,
        name: 'Indian Rupee',
        sign: '₹',
        isExternal: false,
        exchangeRate: 0.013_366,
      };
    case CurrencyCode.TKN:
      return {
        code: 'TKN',
        country: 'FL',
        id: 40,
        name: 'Token',
        sign: '⏱️',
        isExternal: true,
        exchangeRate: 0.0,
      };

    default:
      return assertNever(currencyCode, `Unknown currencyCode ${currencyCode}.`);
  }
}

export function usdCurrency(): { readonly currencyCode: CurrencyCode } {
  return {
    currencyCode: CurrencyCode.USD,
  };
}

export function tokenCurrency(): { readonly currencyCode: CurrencyCode } {
  return {
    currencyCode: CurrencyCode.TKN,
  };
}

export function currencyCodeFromId(id: number): CurrencyCode {
  switch (id) {
    case 1:
      return CurrencyCode.USD;
    case 2:
      return CurrencyCode.NZD;
    case 3:
      return CurrencyCode.AUD;
    case 4:
      return CurrencyCode.GBP;
    case 5:
      return CurrencyCode.HKD;
    case 6:
      return CurrencyCode.SGD;
    case 8:
      return CurrencyCode.EUR;
    case 9:
      return CurrencyCode.CAD;
    case 11:
      return CurrencyCode.INR;
    case 40:
      return CurrencyCode.TKN;
    default:
      throw new Error(`Unknown currency id ${id}.`);
  }
}
