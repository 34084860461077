import { generateId } from '@freelancer/datastore/testing/helpers';
import type { IpContract } from './ip-contracts.model';

export interface GenerateIpContractOptions {
  readonly signed?: boolean;
  readonly projectId: number;
  readonly userId: number;
}

export function generateIpContractObject({
  signed = true,
  projectId,
  userId,
}: GenerateIpContractOptions): IpContract {
  return {
    id: `${generateId()}`,
    signed,
    projectId,
    userId,
  };
}
