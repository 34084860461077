import type {
  AbstractControl,
  AsyncValidatorFn,
  ValidatorFn,
} from '@angular/forms';
import type {
  DocumentFileType,
  ImageFileType,
  VideoFileType,
} from '@freelancer/ui/helpers';
import { isFormArray, isFormGroup, toNumber } from '@freelancer/utils';
import type Quill from 'quill';
import type { Observable } from 'rxjs';
import { combineLatest, isObservable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

/**
 * Update the tools/eslint/src/rules/enforce-translated-strings.ts file if you add validators to this file.
 * If its a non async validator, add it to VALIDATORS only
 * If its an async validator, add it to ASYNC_VALIDATORS only
 */

export const validUsernameRegex = /^[a-z][a-z0-9]{2,15}$/i;

export const validEmailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validEmailForDomainsRegex = (domains: string[]): RegExp => {
  const domainRegex = `(${domains.join('|')})`;

  return new RegExp(
    `^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@${domainRegex}$`,
  );
};

export const containsEmailRegex = new RegExp(
  [
    /([\w\-+!#%*=?^{}|~]+(?:\.[\w\-+!#%*=?^{}|~]+)*)/,
    /@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,10}(?:\.[a-z]{2})?)/i,
  ]
    .map(r => r.source)
    .join(''),
  'i',
);

export const containsPhoneNumberRegex =
  /(\+\d{1,2}\s?)?\(?\d{3,4}\)?[\s-]?\d{3}[\s-]?\d{3,4}/;

export const validContactNumberRegex =
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export const validPasswordRegex = new RegExp(
  /([a-z].*[0-9A-Z])|([0-9A-Z].*[a-z])/,
);

/**
 * Only use this with datepicker inputs
 * This will pass validation if the value is undefined or null, etc.
 * If your control is required, please use the `required` validator.
 */
export const dateFormat =
  (errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (
      control.value === null ||
      control.value === '' ||
      control.value === undefined
    ) {
      return null;
    }

    if (typeof control.value === 'number') {
      // ignore timestamps because it can be confusing UX.
      return { DATE_FORMAT_ERROR: errorText };
    }

    // control.value should already be a date thanks to Material
    const date = new Date(control.value);
    return Number.isNaN(date.getTime())
      ? { DATE_FORMAT_ERROR: errorText }
      : null;
  };

export const dateRangeFormat =
  (errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'number' || control.value === null) {
      return { DATE_RANGE_FORMAT_ERROR: errorText };
    }
    const { start, end } = control.value;

    return Number.isNaN(start.getTime()) ||
      Number.isNaN(end.getTime()) ||
      start > end
      ? { DATE_RANGE_FORMAT_ERROR: errorText }
      : null;
  };

/**
 * Only use this with date range input
 * Checks that the beginning of a date range is a current or future date
 */
export const currentOrFutureDateRange =
  (errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'number' || control.value === null) {
      return { CURRENT_OR_FUTURE_DATE_RANGE_ERROR: errorText };
    }

    // Assuming that control.value is of a correct date range format
    const date = new Date(control.value.start);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate > date
      ? { CURRENT_OR_FUTURE_DATE_RANGE_ERROR: errorText }
      : null;
  };

/**
 * Only use this with datepicker inputs
 * Checks that the date is a current or future date
 * This will pass validation if the value is undefined or null, etc.
 * If your control is required, please use the `required` validator.
 */
export const currentOrFutureDate =
  (errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (
      control.value === null ||
      control.value === '' ||
      control.value === undefined
    ) {
      return null;
    }

    // Assuming that control.value is of a correct date format
    const date = new Date(control.value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate > date
      ? { CURRENT_OR_FUTURE_DATE_ERROR: errorText }
      : null;
  };

/**
 * Only use this with datepicker inputs
 * Checks that the date is before a given date
 */
export const maxDate =
  (endDate: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const date = new Date(control.value);
    return endDate < date.getTime() ? { MAX_DATE_ERROR: errorText } : null;
  };

/**
 * Only use this with datepicker inputs
 * Checks that the date is after a given date
 */
export const minDate =
  (startDate: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (
      control.value === null ||
      control.value === '' ||
      control.value === undefined
    ) {
      return null;
    }
    const date = new Date(control.value);
    return startDate > date.getTime() ? { MIN_DATE_ERROR: errorText } : null;
  };

/**
 * This will pass validation if the value is undefined or null, etc.
 * If your control is required, please use the `required` validator.
 */
export const minLength =
  (length: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value !== undefined &&
    control.value !== null &&
    typeof control.value.length === 'number' &&
    control.value.length < length
      ? { MIN_LENGTH_ERROR: errorText }
      : null;

/**
 * This will pass validation if the value is undefined or null, etc.
 * If your control is required, please use the `required` validator.
 */
export const maxLength =
  (length: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value !== undefined &&
    control.value !== null &&
    typeof control.value.length === 'number' &&
    control.value.length > length
      ? { MAX_LENGTH_ERROR: errorText }
      : null;

export const required =
  (errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value !== undefined &&
    control.value !== null &&
    !Number.isNaN(control.value) &&
    !(Array.isArray(control.value) && control.value.length === 0) &&
    control.value.toString().trim() !== ''
      ? null
      : { REQUIRED_ERROR: errorText };

export const someRequired =
  (errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    (isFormArray(control) || isFormGroup(control)) &&
    Object.values(control.controls).find(c => c.value)
      ? null
      : { SOME_REQUIRED_ERROR: errorText };

export const requiredTruthy =
  (errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    !control.value ? { REQUIRED_TRUTHY_ERROR: errorText } : null;

export const minValue =
  (value: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    toNumber(control.value) >= value ? null : { MIN_VALUE_ERROR: errorText };

/**
 * This will pass validation if the value is undefined or null, etc.
 * If your control is required, please use the `required` or `minValue` validator.
 */
export const minValueIfDefined =
  (value: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value !== undefined &&
    control.value !== null &&
    typeof control.value === 'number' &&
    control.value < value
      ? { MIN_VALUE_ERROR: errorText }
      : null;

/**
 * This will pass validation if the value is undefined or null, etc.
 * If your control is required, please use the `required` or `maxValue` validator.
 */
export const maxValueIfDefined =
  (value: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value !== undefined &&
    control.value !== null &&
    typeof control.value === 'number' &&
    control.value > value
      ? { MAX_VALUE_ERROR: errorText }
      : null;

/**
 * This will pass validation if the value is undefined or null, etc.
 * If your control is required, please use the `required` or `maxValueExclusive` validator.
 */
export const maxValueExclusiveIfDefined =
  (value: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value !== undefined &&
    control.value !== null &&
    typeof control.value === 'number' &&
    control.value >= value
      ? { MAX_VALUE_EXCLUSIVE_ERROR: errorText }
      : null;

export const maxValue =
  (value: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    toNumber(control.value) <= value ? null : { MAX_VALUE_ERROR: errorText };

export const minValueExclusive =
  (value: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    toNumber(control.value) > value
      ? null
      : { MIN_VALUE_EXCLUSIVE_ERROR: errorText };

export const maxValueExclusive =
  (value: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    toNumber(control.value) < value
      ? null
      : { MAX_VALUE_EXCLUSIVE_ERROR: errorText };

export const wholeNumber =
  (errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const error = { WHOLE_NUMBER_ERROR: errorText };

    if (!['string', 'number'].includes(typeof control.value)) {
      return error;
    }

    const wholeNumberRegex = new RegExp(/^[-+]?\d+\.?0*$/);

    return wholeNumberRegex.test(control.value.toString()) ? null : error;
  };

// Ensure the decimal accuracy of a number is within a limit.
export const maxDecimalPlaces =
  (accuracy: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const value = toNumber(control.value);
    if (value && Math.floor(value) !== value) {
      const decimalCount = value.toString().split('.')[1]?.length || 0;
      return decimalCount <= accuracy ? null : { PATTERN_ERROR: errorText };
    }

    return null;
  };

export const validABN =
  (errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const abnWeight = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    const abnArray: number[] = control.value
      ?.replace(/\s/g, '')
      .split('')
      .map((item: string) => parseInt(item, 10));

    if (
      abnArray?.some(number => Number.isNaN(number) || abnArray.length > 11)
    ) {
      return { ABN_VALIDATION_ERROR: errorText };
    }

    const abnNumberArray = abnArray?.map(
      (number: number, index: number) =>
        (index === 0 ? number - 1 : number) * abnWeight[index],
    );

    const totalSum = abnNumberArray?.reduce(
      (partialSum, a) => partialSum + a,
      0,
    );
    return totalSum && totalSum % 89 === 0 && totalSum !== 0
      ? null
      : { ABN_VALIDATION_ERROR: errorText };
  };

export const pattern =
  (regExp: RegExp, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    regExp.test(control.value) ? null : { PATTERN_ERROR: errorText };

export const notPattern =
  (regExp: RegExp | undefined, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    !regExp || !regExp.test(control.value)
      ? null
      : { PATTERN_ERROR: errorText };

// This is for multiple regex patterns with OR statements between them
export const somePatterns =
  (regexArray: RegExp[], errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    regexArray.some(regex => regex.test(control.value))
      ? null
      : { PATTERNS_ERROR: errorText };

/**
 * This is to make sure that the user doesn't upload a file with the same name
 * since we use the filename as an identifier for contest files. :'(
 *
 * Also, note that the control in filenamesUnique validator is only compared to
 * a static list of files. In order to compare against a running  list of files,
 * use filenamesUniqueAsync instead.
 */
export const filenamesUnique =
  (uploadedFiles: readonly File[], errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value &&
    control.value.some((newFile: File) =>
      uploadedFiles.find(uploadedFile => uploadedFile.name === newFile.name),
    )
      ? { FILENAMES_UNIQUE_ERROR: errorText }
      : null;

/**
 * File upload validators. Note that the controls for filesUnique, maxFileSize,
 * and minFileSize expect an array of File objects, not a single file.
 *
 * Also, note that the control in filesUnique validator is only compared to a
 * static list of files. In order to compare against a running  list of files,
 * use filesUniqueAsync instead.
 */
export const filesUnique =
  (uploadedFiles: readonly File[], errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value &&
    control.value.some((newFile: File) =>
      uploadedFiles.find(
        uploadedFile =>
          uploadedFile.name === newFile.name &&
          uploadedFile.size === newFile.size,
      ),
    )
      ? { FILES_UNIQUE_ERROR: errorText }
      : null;

/**
 * This expects an array of File objects.
 * NOTE: This validates the file's MIME type rather than extenstion.
 */
export const allowedFileTypes =
  (
    fileTypes: readonly (
      | ImageFileType
      | DocumentFileType
      | VideoFileType
      | string
    )[],
    errorText: string,
  ): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value
      ? control.value.some(
          (file: File) => !fileTypes.includes(file.type.split('/')[1] as any),
        )
        ? { ALLOWED_FILE_TYPE_ERROR: errorText }
        : null
      : null;

// This expects an array of File objects.
export const maxFileSize =
  (size: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value
      ? control.value.some((file: File) => size < file.size)
        ? { MAX_FILE_SIZE_ERROR: errorText }
        : null
      : null;

// This expects an array of File objects.
export const minFileSize =
  (size: number, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value
      ? control.value.some((file: File) => size > file.size)
        ? { MIN_FILE_SIZE_ERROR: errorText }
        : null
      : null;

/**
 * This validator errors if the subject text matches at least one term from ALL of the blacklists given.
 * For example, if two blacklists are given, but the text only matches a term in one of them, the validation passes.
 * However, if the text matches a term in both lists, the validation errors.
 * The terms of each blacklist can either be a string literal ('foo') or a regular expression ('foo.*bar').
 */
export const noBlacklistTerms =
  (
    blacklists: readonly (readonly string[])[],
    // allows a customised error key to distinguish between different blacklists
    errorKey: string,
    errorText: string,
  ): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (blacklists.length === 0 || control.value === undefined) {
      return null;
    }

    const subjectText = control.value.toLowerCase();

    const hasBlacklistTerm = blacklists
      .map(blacklist =>
        blacklist.some(term => {
          const matches = subjectText.match(new RegExp(`\\b${term}\\b`));
          return matches && matches.length > 0;
        }),
      )
      .every(hasTerm => hasTerm);

    return hasBlacklistTerm ? { [errorKey]: errorText } : null;
  };

export const notEqualOtherControl =
  (otherControl: AbstractControl, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control.value
      ? control.value === otherControl.value
        ? { NOT_EQUAL_OTHER_CONTROL_ERROR: errorText }
        : null
      : null;

export const equalOtherControl =
  (otherControl: AbstractControl, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const ret = control.value
      ? control.value !== otherControl.value
        ? { EQUAL_OTHER_CONTROL_ERROR: errorText }
        : null
      : null;
    return ret;
  };

/**
 * Checks the given parameter is not equal to control's value.
 * @param other
 * @param errorText
 */
export const notEqual =
  <T>(other: T, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control?.value === other ? { EQUALS_TO_OTHER_ERROR: errorText } : null;

/**
 * Checks the given list does not contain the control's value.
 * @param list
 * @param errorText
 */
export const notIncludes =
  (list: readonly string[], errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control && list.includes(control.value)
      ? { INCLUDED_IN_LIST_ERROR: errorText }
      : null;

/**
 * Recursively validates the control and all its children
 * @param control AbstractControl
 */
export function dirtyAndValidate(control: AbstractControl): void {
  control.markAsDirty();
  control.updateValueAndValidity();
  if (isFormArray(control) || isFormGroup(control)) {
    Object.values(control.controls).forEach(c => {
      if (control.enabled) {
        dirtyAndValidate(c);
      }
    });
  }
}

/**
 * This validator checks if a form group has the minimum required number of form controls that are TRUE.
 * Example: Given a list of checkbox each having its own form control and belonging to one form group,
 * we can validate if at least one check box has been ticked/checked using this validator.
 */
export const minNumOfTrue =
  (minTrue: number = 1, errorText: string): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    let trueControls: AbstractControl[] = [];
    if (isFormArray(control) || isFormGroup(control)) {
      trueControls = Object.values(control.controls).filter(
        c => c.value === true,
      );
    }

    return trueControls.length < minTrue
      ? { MIN_NUM_OF_TRUE_ERROR: errorText }
      : null;
  };

/**
 * This validator checks if all controls passed to the validator
 * are valid agains specified validator.
 * Example usecase - if either attachment or comment is required.
 */
export const atLeastOne =
  (validator: ValidatorFn, controls: readonly string[], errorText: string) =>
  (control: AbstractControl): { [key: string]: any } | null =>
    control &&
    (isFormArray(control) || isFormGroup(control)) &&
    (controls || Object.keys(control.controls)).some(
      k =>
        !validator(
          isFormArray(control)
            ? control.controls[toNumber(k)]
            : control.controls[k],
        ),
    )
      ? null
      : { AT_LEAST_ONE_ERROR: errorText };

/**
 * Checks that either none or all of the controls in the form are provided.
 *
 * Example: Given a form to submit a photo and alt text, providing only one is invalid.
 */
export const allOrNone =
  (errorText: string) =>
  (control: AbstractControl): { [key: string]: any } | null =>
    (isFormArray(control) || isFormGroup(control)) &&
    (Object.values(control.controls).every(k => !required('')(k)) ||
      Object.values(control.controls).every(k => required('')(k)))
      ? null
      : { ALL_OR_NONE_ERROR: errorText };

/**
 * This validator takes controls from two inputs from the same form group
 * and compares the two. Start value should be less than or equal to end value.
 */
export const validRange =
  (start: string, end: string, errorText: string) =>
  (control: AbstractControl) => {
    const startValue = control.get(start)?.value;
    const endValue = control.get(end)?.value;
    return startValue && endValue
      ? startValue <= endValue
        ? null
        : { RANGE_VALIDATOR_ERROR: errorText }
      : null;
  };

/**
 * This validator takes two date type input controls and two corresponding string input controls
 * for time. This checks if the two dates provided are equal and compares their corresponding
 * time inputs to validate proper time range.
 */
export const validTimeRange =
  (
    startDateKey: string,
    endDateKey: string,
    startTimeKey: string,
    endTimeKey: string,
    errorText: string,
  ) =>
  (control: AbstractControl) => {
    const startDate: string = control.get(startDateKey)?.value?.toDateString();
    const endDate: string = control.get(endDateKey)?.value?.toDateString();
    const startTime: string = control.get(startTimeKey)?.value;
    const endTime: string = control.get(endTimeKey)?.value;
    if (startDate && endDate && startTime && endTime && startDate === endDate) {
      const startTimeArr = startTime.split(':');
      const endTimeArr = endTime.split(':');
      const start =
        toNumber(startTimeArr[0]) * 3600 + toNumber(startTimeArr[1]) * 60;
      const end = toNumber(endTimeArr[0]) * 3600 + toNumber(endTimeArr[1]) * 60;
      return start < end ? null : { TIME_VALIDATOR_ERROR: errorText };
    }
    return null;
  };

/**
 * This validator takes two parameters, seperators and errorText.
 * Using the control's value, it will verify using a regex whether
 * it is valid.
 */
export const validEmailCSV =
  (separators: readonly string[], errorText: string) =>
  (control: AbstractControl) => {
    const validCSVRegexes = separators.map(
      separator =>
        new RegExp(
          `^((([^<>()[\\].,;:\\s@"]+(\\.[^<>()[\\].,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))(${separator})*)+$`,
        ),
    );
    if (validCSVRegexes.some(regex => regex.test(control.value))) {
      return null;
    }
    return { EMAIL_CSV_ERROR: errorText };
  };

/**
 * Utility type for a CSVParser
 */
type CSVParser = (
  separators: readonly string[],
  csvString: string,
) => readonly unknown[];

/**
 * This validator takes four parameters. Max is to denote the max number of values we want
 * to allow. Parser is a function that will take the csv and then return an array of parsed values.
 * Using this, we check whether we have crossed the max and if so we invalidate the control.
 */
export const maxNumberOfCSV =
  (
    max: number,
    parser: CSVParser,
    separators: readonly string[],
    errorText: string,
  ) =>
  (control: AbstractControl) => {
    const csvString: string = control.value;
    const values = parser(separators, csvString);
    if (values.length <= max) {
      return null;
    }
    return { MAX_CSV_VALUES_ERROR: errorText };
  };

/**
 * This is specifically used to validate input in the quill editor without the HTML tags.
 * This will pass validation if the value is undefined or null, etc.
 * If your control is required, please use the `required` validator.
 */
export const minQuillTextLength =
  (length: number, errorText: string, quillEditor: Quill): ValidatorFn =>
  (_control: AbstractControl): { [key: string]: any } | null => {
    const currentLength = quillEditor.getText().trim().length;
    return typeof currentLength === 'number' &&
      currentLength > 0 &&
      currentLength < length
      ? { MIN_QUILL_LENGTH_ERROR: errorText }
      : null;
  };

/**
 * This is specifically used to validate input in the quill editor without the HTML tags.
 * This will pass validation if the value is undefined or null, etc.
 * If your control is required, please use the `required` validator.
 */
export const maxQuillTextLength =
  (length: number, errorText: string, quillEditor: Quill): ValidatorFn =>
  (_control: AbstractControl): { [key: string]: any } | null => {
    const currentLength = quillEditor.getText().trim().length;
    return typeof currentLength === 'number' && currentLength > length
      ? { MAX_QUILL_LENGTH_ERROR: errorText }
      : null;
  };

/**
 * A function that takes in another validator generator,
 * and creates an async validator generator called in the same way.
 * Use this to allow a validator with an extra argument to be called as an AsyncValidator
 *
 * Note: observables passed into this must complete!
 * Treat these async validators like toPromise()
 */
export function asyncValidatorOf<T>(
  baseValidator: (value: T, errorText: string) => ValidatorFn,
): (
  value$: Observable<T>,
  errorText$: string | Observable<string>,
) => AsyncValidatorFn {
  return (value$, errorText$) => control => {
    const _errorTextSubject$ = isObservable(errorText$)
      ? errorText$
      : of(errorText$);
    return combineLatest([value$, _errorTextSubject$]).pipe(
      take(1),
      map(([value, errorTextString]) =>
        baseValidator(value, errorTextString)(control),
      ),
    );
  };
}

/**
 * Async version of filenamesUnique.
 * Note: observable argument must complete, similar to toPromise()
 */
export const filenamesUniqueAsync = asyncValidatorOf(filenamesUnique);
/**
 * Async version of filesUnique.
 * Note: observable argument must complete, similar to toPromise()
 */
export const filesUniqueAsync = asyncValidatorOf(filesUnique);
/**
 * Async version of minLength.
 * Note: observable argument must complete, similar to toPromise()
 */
export const minLengthAsync = asyncValidatorOf(minLength);
/**
 * Async version of maxLength.
 * Note: observable argument must complete, similar to toPromise()
 */
export const maxLengthAsync = asyncValidatorOf(maxLength);
/**
 * Async version of minValue.
 * Note: observable argument must complete, similar to toPromise()
 */
export const minValueAsync = asyncValidatorOf(minValue);
/**
 * Async version of maxValue.
 * Note: observable argument must complete, similar to toPromise()
 */
export const maxValueAsync = asyncValidatorOf(maxValue);
/**
 * Async version of minValueExclusive.
 * Note: observable argument must complete, similar to toPromise()
 */
export const minValueExclusiveAsync = asyncValidatorOf(minValueExclusive);
/**
 * Async version of maxValueExclusive.
 * Note: observable argument must complete, similar to toPromise()
 */
export const maxValueExclusiveAsync = asyncValidatorOf(maxValueExclusive);
/**
 * Async version of pattern.
 * Note: observable argument must complete, similar to toPromise()
 */
export const patternAsync = asyncValidatorOf(pattern);
/**
 * Async version of notPatter.
 * Note: observable argument must complete, similar to toPromise()
 */
export const notPatternAsync = asyncValidatorOf(notPattern);
/**
 * Async version of somePatterns.
 * Note: observable argument must complete, similar to toPromise()
 */
export const somePatternsAsync = asyncValidatorOf(somePatterns);
/**
 * Async version of minDate.
 * Note: observable argument must complete, similar to toPromise()
 */
export const minDateAsync = asyncValidatorOf(minDate);
/**
 * Async version of maxDate.
 * Note: observable argument must complete, similar to toPromise()
 */
export const maxDateAsync = asyncValidatorOf(maxDate);
/**
 * Async version of notEqual.
 * Note: observable argument must complete, similar to toPromise()
 */
export const notEqualAsync = asyncValidatorOf(notEqual);
/**
 * Async version of valueNotIn.
 * Note: observable argument must complete, similar to toPromise()
 */
export const notIncludesAsync = asyncValidatorOf(notIncludes);
