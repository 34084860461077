import { generateId } from '@freelancer/datastore/testing/helpers';
import { ProjectStatusFromBids } from '@freelancer/project-status';
import type { Bid } from '../bids';
import type { Project } from '../projects';
import type { ManageViewPastProject } from './manage-view-past-projects.model';

export interface GenerateManageViewPastProjectOptions {
  readonly project: Project;
  readonly projectStatus:
    | ProjectStatusFromBids.COMPLETE
    | ProjectStatusFromBids.INCOMPLETE
    | ProjectStatusFromBids.REJECTED
    | ProjectStatusFromBids.REVOKED
    | ProjectStatusFromBids.IN_PROGRESS
    | ProjectStatusFromBids.EXPIRED;
  readonly bidCount: number;
  readonly bidAvg: number;
  readonly bidderId: number;
  readonly bidEndDate: number;
  readonly bidderName?: string;
}

export interface GenerateManageViewPastProjectsOptions {
  readonly projects: readonly Project[];
  readonly projectStatus:
    | ProjectStatusFromBids.COMPLETE
    | ProjectStatusFromBids.INCOMPLETE
    | ProjectStatusFromBids.REJECTED
    | ProjectStatusFromBids.REVOKED
    | ProjectStatusFromBids.IN_PROGRESS
    | ProjectStatusFromBids.EXPIRED;
  readonly bids: readonly Bid[];
  readonly bidderId?: number;
  readonly bidEndDate?: number;
}

export function generateManageViewPastProject(
  options: GenerateManageViewPastProjectOptions,
): ManageViewPastProject {
  return {
    id: options.project.id.toString(),
    title: options.project.title,
    seoUrl: options.project.seoUrl,
    projectType: options.project.type,
    recruiter: options.project.upgrades.assisted,
    currencyCode: options.project.currency.code,
    isInsourceProject: options.project.isInsourceProject,
    timeSubmitted: options.project.timeSubmitted,
    projectStatus: options.projectStatus,
    milestoneAmount: 0,
    projectId: options.project.id,
    milestoneCount: 0,
    ...(options.bidderName
      ? {
          bid: {
            bidId: generateId(),
            bidderId: generateId(),
            bidAmount: 10,
            bidderUsername: options.bidderName,
          },
        }
      : {}),
  };
}

export function generateManageViewPastProjects({
  projects,
  projectStatus = ProjectStatusFromBids.COMPLETE,
  bids,
  bidderId,
  bidEndDate,
}: GenerateManageViewPastProjectsOptions): readonly ManageViewPastProject[] {
  const bidAvg =
    Math.round(
      (bids.map(bid => bid.amount).reduce((sum, amount) => sum + amount, 0) /
        bids.length) *
        100,
    ) / 100;
  return projects.map(project =>
    generateManageViewPastProject({
      project,
      projectStatus,
      bidCount: bids.length,
      bidAvg,
      bidderId: bidderId ?? bids[0].id,
      bidEndDate:
        bidEndDate ??
        bids[0].awardExpireTime ??
        Date.now() + 2 * 24 * 60 * 60 * 1000,
    }),
  );
}
