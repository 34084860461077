import type { FreeBidReasons, IsFreeBid } from './is-free-bids.model';

export interface GenerateIsFreeBidsOptions {
  readonly projectId: number;
  readonly isFreeBids?: boolean;
  readonly reasons?: readonly FreeBidReasons[];
}

export function generateIsFreeBidsObject({
  projectId,
  isFreeBids = true,
  reasons = [],
}: GenerateIsFreeBidsOptions): IsFreeBid {
  return {
    id: projectId,
    projectId,
    isFreeBid: isFreeBids,
    reasons,
  };
}
