export enum CalloutPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'start',
  RIGHT = 'end',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  RIGHT_BOTTOM = 'end-bottom',
  RIGHT_TOP = 'end-top',
}
