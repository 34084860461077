import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  QuotationItemBillingFrequencyApi,
  QuotationItemStatusApi,
  QuotationItemUnitTypeApi,
} from 'api-typings/quotations/quotations';
import type { QuotationRevision } from '../quotation-revisions/quotation-revisions.model';
import {
  QuotationItemType,
  type QuotationItemRevision,
} from './quotation-item-revisions.model';

export interface GenerateQuotationItemRevisionOptions {
  readonly itemVersionId?: number;
  readonly itemId?: number;
  readonly quotationId?: number;
  readonly name?: string;
  readonly createDate?: number;
  readonly order?: string;
  readonly currencyId?: number;
  readonly paidAmount?: number;
  readonly fundedAmount?: number;
  readonly unitPrice?: number;
  readonly quantity?: number;
  readonly description?: string;
  readonly message?: string;
  readonly invalidDate?: number;
  readonly versionInvalidDate?: number;
  readonly completeDate?: number;
  readonly quotationVersionId?: number;
  readonly unitType?: QuotationItemUnitTypeApi;
  readonly unitLimit?: number;
  readonly originalQuotationVersionId?: number;
  readonly status?: QuotationItemStatusApi;
  readonly billingFrequency?: QuotationItemBillingFrequencyApi;
  readonly quotationItemType?: QuotationItemType;
}

export function generateQuotationItemRevisionObject({
  itemVersionId,
  itemId,
  quotationId,
  quotationVersionId,
  originalQuotationVersionId,
  name = 'item name',
  createDate = Date.now(),
  order = 'aaa',
  currencyId = 1,
  paidAmount = 0,
  fundedAmount = 0,
  unitPrice = 1,
  unitLimit = 1,
  quantity = 1,
  unitType = undefined,
  description = 'item description',
  message,
  invalidDate,
  versionInvalidDate,
  completeDate,
  status = QuotationItemStatusApi.PENDING,
  billingFrequency,
  quotationItemType = QuotationItemType.FIXED,
}: GenerateQuotationItemRevisionOptions = {}): QuotationItemRevision {
  const quoteVersionId = generateId();
  return {
    id: itemVersionId ?? generateId(),
    quotationItemId: itemId ?? generateId(),
    quotationId: quotationId ?? generateId(),
    quotationVersionId: quotationVersionId ?? quoteVersionId,
    originalQuotationVersionId: originalQuotationVersionId ?? quoteVersionId,
    name,
    createDate,
    order,
    currencyId,
    paidAmount,
    fundedAmount,
    unitPrice,
    quantity,
    description,
    message,
    invalidDate,
    versionInvalidDate,
    completeDate,
    status,
    totalPrice: quantity * unitPrice,
    unitLimit,
    unitType,
    quotationItemType,
    billingFrequency,
  };
}

export interface GenerateQuotationItemRevisionsOptions {
  readonly numberOfItems: number;
  readonly quotationVersionId: number;
  readonly quotationId: number;
}

export function generateQuotationItemRevisionObjects({
  numberOfItems,
  quotationVersionId,
  quotationId,
}: GenerateQuotationItemRevisionsOptions): readonly QuotationItemRevision[] {
  return new Array(numberOfItems).fill(undefined).map(() =>
    generateQuotationItemRevisionObject({
      ...validItem(),
      quotationVersionId,
      quotationId,
    }),
  );
}

// Attach item to a quotation revision and optionally add an original version id.
// An original quotation version id is used to track an item that was copied
// from a previous quotation revision, but hasn't been modified. By providing
// originalQuotationVersionId, you specify that the item was copied from that
// version and has not since been modified.
export function attachedToQuotation({
  quotation,
  originalQuotationVersionId,
}: {
  readonly quotation: QuotationRevision;
  readonly originalQuotationVersionId?: number;
}): Pick<
  GenerateQuotationItemRevisionOptions,
  | 'quotationId'
  | 'quotationVersionId'
  | 'originalQuotationVersionId'
  | 'createDate'
> {
  return {
    quotationId: quotation.quotationId,
    quotationVersionId: quotation.id,
    originalQuotationVersionId: originalQuotationVersionId ?? quotation.id,
    createDate: quotation.createDate,
  };
}

export function validItem({
  name,
  unitPrice,
  quantity,
}: Pick<
  GenerateQuotationItemRevisionOptions,
  'name' | 'unitPrice' | 'quantity'
> = {}): Pick<
  GenerateQuotationItemRevisionOptions,
  'name' | 'unitPrice' | 'quantity' | 'status'
> {
  return {
    status: QuotationItemStatusApi.PENDING,
    name: name ?? 'item name',
    unitPrice: unitPrice ?? 1,
    quantity: quantity ?? 1,
  };
}

export function validHourlyItem({
  name,
  unitPrice,
  quantity,
}: Pick<
  GenerateQuotationItemRevisionOptions,
  | 'name'
  | 'unitPrice'
  | 'quantity'
  | 'unitLimit'
  | 'unitType'
  | 'quotationItemType'
> = {}): Pick<
  GenerateQuotationItemRevisionOptions,
  | 'name'
  | 'unitPrice'
  | 'quantity'
  | 'status'
  | 'unitLimit'
  | 'unitType'
  | 'quotationItemType'
> {
  return {
    status: QuotationItemStatusApi.PENDING,
    name: name ?? 'item name',
    unitPrice: unitPrice ?? 1,
    quantity: quantity ?? 1,
    unitLimit: 1,
    unitType: QuotationItemUnitTypeApi.HOUR,
    quotationItemType: QuotationItemType.HOURLY,
  };
}

export function validSubscriptionItem({
  name,
  unitPrice,
  quantity,
  billingFrequency,
}: Pick<
  GenerateQuotationItemRevisionOptions,
  'name' | 'unitPrice' | 'quantity' | 'billingFrequency' | 'quotationItemType'
> = {}): Pick<
  GenerateQuotationItemRevisionOptions,
  | 'name'
  | 'unitPrice'
  | 'quantity'
  | 'status'
  | 'billingFrequency'
  | 'quotationItemType'
> {
  return {
    status: QuotationItemStatusApi.PENDING,
    name: name ?? 'item name',
    unitPrice: unitPrice ?? 1,
    quantity: quantity ?? 1,
    billingFrequency:
      billingFrequency ?? QuotationItemBillingFrequencyApi.MONTHLY,
    quotationItemType: QuotationItemType.SUBSCRIPTION,
  };
}

export function invalidItem(): Pick<
  GenerateQuotationItemRevisionOptions,
  'status' | 'versionInvalidDate'
> {
  return {
    status: QuotationItemStatusApi.INVALID,
    versionInvalidDate: Date.now(),
  };
}

export function deletedItem(): Pick<
  GenerateQuotationItemRevisionOptions,
  'status' | 'invalidDate'
> {
  return { status: QuotationItemStatusApi.INVALID, invalidDate: Date.now() };
}

export function completeItem(): Pick<
  GenerateQuotationItemRevisionOptions,
  'status' | 'completeDate'
> {
  return { status: QuotationItemStatusApi.COMPLETE };
}
