import type {
  GenerateProfileViewUserOptions,
  User,
} from '@freelancer/datastore/collections';
import {
  acceptedBid,
  awardedProject,
  canViewEmployerProfileFromUser,
  CurrencyCode,
  employerUser,
  encodedUrlForUserProfile,
  fixedUsdProject,
  frozenMilestone,
  getProjectViewUserLocations,
  LocationPreset,
  mobileAppDevelopmentSkill,
  usdCurrency,
  userFromUsersSelf,
  userInfoFromUser,
  verifiedUser,
  websiteDesignProject,
} from '@freelancer/datastore/collections';
import { generateId } from '@freelancer/datastore/testing/helpers';
import { oneLine } from 'common-tags';
import {
  createBanners,
  createBids,
  createCanViewEmployerProfile,
  createCertification,
  createEncodedUrl,
  createFreelancerReputation,
  createFreelancerReputationsRehireRate,
  createMembershipBenefit,
  createMilestone,
  createNotificationsPreferences,
  createOnlineOffline,
  createProfileViewUser,
  createProjectViewProject,
  createReferralInvitationCheck,
  createSimilarFreelancers,
  createSimilarShowcases,
  createTopSkills,
  createUser,
  createUserBalances,
  createUserCalifornianStatuses,
  createUserGiveGetDetail,
  createUserInfo,
  createUsersSelf,
} from '../document-creators';

export async function createAppStoreProfileUser(
  authUid: number,
): Promise<User> {
  const userSelf = await createUsersSelf({
    userId: authUid,
    username: 'johnS',
    displayName: 'John S.',
  });

  const user = await createUser({
    ...userFromUsersSelf(userSelf),
    ...employerUser(),
  });

  return user;
}

/**
 * Used for App Store and Play Store screenshots.
 *
 * Only change this when you want to change those screenshots.
 */
export async function constructAppStoreProfileState(user: User): Promise<void> {
  await createUserInfo(userInfoFromUser(user));
  await createUserGiveGetDetail({ userId: user.id, username: user.username });
  await createUserCalifornianStatuses({ userIds: [user.id] });
  await createUserBalances();
  await createBanners();
  await createReferralInvitationCheck({ userId: user.id });
  await createNotificationsPreferences({});
  await createMembershipBenefit({
    benefitName: 'employer_following',
    enabled: true,
  });
}

export async function constructFreelancerProfilePageState({
  employer,
  freelancer,
}: {
  readonly employer: User;
  readonly freelancer: User;
}): Promise<void> {
  await createProfileViewUser({
    user: freelancer,
    ...experiencedSydneyDeveloper(),
  });
  await createEncodedUrl({
    userId: employer.id,
    ...encodedUrlForUserProfile(freelancer),
  });

  await createTopSkills({
    userId: freelancer.id,
    skills: [mobileAppDevelopmentSkill()],
  });
  await createCertification({ userId: freelancer.id });
  await createFreelancerReputation({ freelancerId: freelancer.id });

  await createOnlineOffline({ userIds: [freelancer.id] });
  await createFreelancerReputationsRehireRate({
    freelancerId: freelancer.id,
    reputationOptions: {
      total: 100,
    },
  });
  await createCanViewEmployerProfile(canViewEmployerProfileFromUser(employer));
  await createSimilarFreelancers({ userId: freelancer.id });
  await createSimilarShowcases({ userId: freelancer.id });
}

/**
 * Used by the App Store screenshots.
 *
 * Only change if you want to change the screenshots.
 */
export function experiencedSydneyDeveloper(): Pick<
  Required<GenerateProfileViewUserOptions>,
  | 'currencyCode'
  | 'skills'
  | 'location'
  | 'preferredFreelancer'
  | 'registrationDate'
  | 'status'
  | 'profileDescription'
  | 'tagLine'
  | 'hourlyRate'
> {
  return {
    skills: [mobileAppDevelopmentSkill()],
    currencyCode: CurrencyCode.AUD,
    location: getProjectViewUserLocations(LocationPreset.SYDNEY),
    preferredFreelancer: true,
    registrationDate: new Date().setFullYear(new Date().getFullYear() - 6),
    status: verifiedUser().userStatus,
    profileDescription: oneLine`
      Hello! I'm Alexander, a Sydney-based software engineer.
      I specialise in clean communication with a focus on simplicity.
    `,
    tagLine: `Sydney-based Software Engineer`,
    hourlyRate: 40,
  };
}

export async function constructAppStoreProjectViewPagePaymentTabState(
  employerId: number,
  freelancer: User,
): Promise<void> {
  const projectId = generateId();

  const acceptedBids = await createBids({
    ...acceptedBid({ bidderIds: [freelancer.id] }),
    projectId,
    projectOwnerId: employerId,
  });

  await createProjectViewProject({
    projectId,
    ownerId: employerId,
    title: 'Website design for my coffee shop',
    seoUrl: 'app-store-screenshot-coffee-shop-website-design-project',
    ...websiteDesignProject(),
    ...fixedUsdProject(),
    ...awardedProject({ selectedBids: acceptedBids }),
  });

  await createMilestone({
    bidId: acceptedBids[0].id,
    bidderId: freelancer.id,
    projectId,
    projectOwnerId: employerId,
    description: 'Wireframe for the website',
    ...usdCurrency(),
    ...frozenMilestone(),
  });
}
