import type { GenerateProjectOptions } from '../projects';
import { generateProjectObject } from '../projects';
import type { ThreadProject } from './thread-projects.model';

export type GenerateThreadProjectOptions = GenerateProjectOptions;

/**
 * Returns a thread project object.
 */
export function generateThreadProjectObject(
  args: GenerateThreadProjectOptions,
): ThreadProject {
  return {
    ...generateProjectObject(args),
    skills: [],
  };
}
