import type { ActiveLanguage } from './active-languages.model';

export function generateActiveLanguagesObject(): ActiveLanguage {
  return getActiveLanguages('en');
}

export function generateActiveLanguagesObjects(): readonly ActiveLanguage[] {
  const supportedCountryCodes = ['en', 'es', 'fil'];
  return supportedCountryCodes.map(getActiveLanguages);
}

export function getActiveLanguages(countryCode: string): ActiveLanguage {
  if (countryCode === 'en') {
    return {
      id: 'en',
      code: 'en',
      name: 'English',
      englishName: 'English',
    };
  }

  if (countryCode === 'es') {
    return {
      id: 'es',
      code: 'es',
      name: 'Español',
      englishName: 'Spanish',
    };
  }

  if (countryCode === 'fil') {
    return {
      id: 'fil',
      code: 'fil',
      name: 'Filipino',
      englishName: 'Filipino',
    };
  }

  throw new Error(`Unhandled country code: '${countryCode}'`);
}
