import { generateId } from '@freelancer/datastore/testing/helpers';
import { TaxTypeApi, UsTaxTypeApi } from 'api-typings/common/common';
import type { UserTaxInfo } from './user-tax-info.model';

export type GenerateUserTaxInfoOptions = Omit<UserTaxInfo, 'id'>;

export function generateUserTaxInfoObject({
  countryCode = 'US',
  type = TaxTypeApi.US1099K,
  usTaxSubtype = UsTaxTypeApi.EIN,
  number = 'XX-XXX6ABC',
}: GenerateUserTaxInfoOptions): UserTaxInfo {
  return {
    id: generateId(),
    countryCode,
    type,
    usTaxSubtype,
    number,
  };
}
