import type { Project } from '../projects/projects.model';
import type { UserRecentProjectsAndContestsEntry } from './user-recent-projects-and-contests.model';

export interface GenerateRecentProjectsAndContestsOptions {
  readonly projects: readonly Project[];
}

export function generateRecentProjectsAndContestsObjects({
  projects,
}: GenerateRecentProjectsAndContestsOptions): readonly UserRecentProjectsAndContestsEntry[] {
  return projects.map(({ id, title, seoUrl, timeSubmitted }) => ({
    id: `project-${id}`,
    type: 'project',
    latest: timeSubmitted,
    name: title,
    projectOrContestId: id,
    urlPart: `/projects/${seoUrl}`,
  }));
}
