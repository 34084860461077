import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerWebappNavigation } from '@freelancer/utils';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppModule } from './app.module';

if (environment.production) {
  enableProdMode();
}

// FIXME: T267853 - https://github.com/angular/angular/issues/20484
fromEvent(document, 'DOMContentLoaded')
  .pipe(take(1))
  // eslint-disable-next-line local-rules/no-ignored-subscription
  .subscribe(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule, {
        preserveWhitespaces: false,
      })
      .then(ref => registerWebappNavigation(ref));
  });
