export enum RibbonPlacement {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum RibbonColor {
  PRIMARY = 'primary',
  PRIMARY_LIGHT = 'primary-light',
  SECONDARY = 'secondary',
  SECONDARY_DARK = 'secondary-dark',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
  PINK = 'pink',
}

export enum RibbonOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}
