import { generateId } from '@freelancer/datastore/testing/helpers';
import { ServiceOfferingStatusApi } from 'api-typings/service_offerings/service_offerings';
import { v4 as uuid } from 'uuid';
import type { CorporateDetails } from '../profile-view-users/profile-view-users.model';
import type { UserStatus } from '../project-view-users/user-status.model';
import type { GenerateServiceOfferingOptions } from '../service-offerings/service-offerings.seed';
import type { ServiceOfferingWithOwnerDetails } from './service-offerings-with-owner-details.model';

interface GenerateOwnerDetailsOptions {
  readonly username: string;
  readonly displayName: string;
  readonly avatar?: string;
  readonly status: UserStatus;
  readonly isRisingStar?: boolean;
  readonly corporate?: CorporateDetails;
  readonly preferredFreelancer?: boolean;
}

export interface GenerateServiceOfferingWithOwnerDetailsOptions
  extends GenerateServiceOfferingOptions {
  readonly owner: GenerateOwnerDetailsOptions;
}

export function generateServiceOfferingWithOwnerDetailsObject({
  id = generateId(),
  creatorId = generateId(),
  createDate,
  title,
  description,
  jobIds,
  status = ServiceOfferingStatusApi.DRAFT,
  seoUrl,
  attachments,
  owner,
}: GenerateServiceOfferingWithOwnerDetailsOptions): ServiceOfferingWithOwnerDetails {
  return {
    id,
    creatorId,
    createDate: createDate || Date.now(),
    jobIds,
    title: title || `Test Service Offering ${id}`,
    description: description || `Test Service Offering Description ${id}`,
    status,
    seoUrl:
      status === ServiceOfferingStatusApi.DRAFT
        ? undefined
        : seoUrl || `test-service-offering-${id}`,
    attachments: attachments?.map(attachment => ({
      id: generateId(),
      service_offering_id: id,
      attachment_uuid: attachment.attachment_uuid || uuid(),
      create_date: attachment.create_date || Date.now(),
      delete_date: attachment.delete_date,
      downloadable_url: attachment.downloadable_url,
    })),
    owner,
  };
}
