import { generateId } from '@freelancer/datastore/testing/helpers';
import type { TeamMember } from '../team-members';
import type { Team } from './teams.model';

export interface GenerateTeamsOptions {
  readonly id?: number;
  readonly ownerUserId: number;
  readonly members: readonly TeamMember[];
  readonly name: string;
}

export function generateTeamObject({
  id = generateId(),
  ownerUserId,
  members,
  name,
}: GenerateTeamsOptions): Team {
  return {
    id,
    ownerUserId,
    members,
    name,
  };
}
