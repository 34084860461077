import { generateId } from '@freelancer/datastore/testing/helpers';
import type { Bid } from '../bids';
import type { MilestoneDraft } from './milestone-drafts.model';

export interface GenerateMilestoneDraftsOptions {
  readonly amount: number;
  readonly bidderId: number;
  readonly bidId: number;
  readonly currencyId: number;
  readonly description: string;
  readonly id?: number;
  readonly milestoneRequestId?: number;
  readonly projectId: number;
  readonly projectOwnerId: number;
  readonly transactionId?: number;
  readonly invoiceId?: number;
}

/**
 * Creates a Milestone Draft object.
 */
export function generateMilestoneDraftObject({
  id = generateId(),
  amount,
  bidderId,
  bidId,
  currencyId,
  description,
  milestoneRequestId,
  projectId,
  projectOwnerId,
  transactionId,
  invoiceId,
}: GenerateMilestoneDraftsOptions): MilestoneDraft {
  return {
    id,
    amount,
    bidderId,
    bidId,
    currencyId,
    description,
    milestoneRequestId,
    projectId,
    projectOwnerId,
    timeCreated: Date.now(),
    transactionId,
    invoiceId,
  };
}

export function milestoneDraftFromBid(
  bid: Bid,
): Pick<
  GenerateMilestoneDraftsOptions,
  | 'amount'
  | 'bidderId'
  | 'bidId'
  | 'currencyId'
  | 'projectId'
  | 'projectOwnerId'
> {
  return {
    amount: bid.amount,
    bidderId: bid.bidderId,
    bidId: bid.id,
    currencyId: 1,
    projectId: bid.projectId,
    projectOwnerId: bid.projectOwnerId ?? generateId(),
  };
}
