import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  AgentSessionStateApi,
  SourceTypeApi,
  SupportTypeApi,
} from 'api-typings/support/support';
import { generateSupportType } from '../agents';
import type { GenerateContestViewContestOptions } from '../contest-view-contests';
import type { GenerateProjectViewProjectOptions } from '../project-view-projects';
import type { AgentSession, ResolvedReason } from './agent-sessions.model';

export interface GenerateAgentSessionsOptions {
  readonly options: GenerateAgentSessionOptions;
  readonly count?: number;
}
export interface GenerateAgentSessionOptions {
  readonly id?: number;
  readonly type?: SupportTypeApi;
  readonly state?: AgentSessionStateApi;
  readonly latest?: boolean;
  readonly createTime?: number;
  readonly resolvedTime?: number;
  readonly resolvedReason?: ResolvedReason;
  readonly agentId?: number;
  readonly starred?: boolean;
  readonly sessionId?: number;
  readonly sessionType?: SupportTypeApi;
  readonly sessionOwnerId?: number;
  readonly sessionCreateTime?: number;
  readonly sessionSourceType?: SourceTypeApi;
  readonly sessionSourceId?: number;
  readonly tagIds?: readonly number[];
}

// For project management support type where source can be a contest or project. Source options must match the correct source type.
export type ProjectManagementSourceTypeAndOptions =
  | {
      readonly sourceType: SourceTypeApi.CONTEST;
      readonly sourceOptions: GenerateContestViewContestOptions;
    }
  | {
      readonly sourceType: SourceTypeApi.PROJECT;
      readonly sourceOptions: GenerateProjectViewProjectOptions;
    };

export function generateAgentSessionObjects({
  options = {},
  count = 5,
}: GenerateAgentSessionsOptions): readonly AgentSession[] {
  let agentSessions: AgentSession[] = [];
  let counter = count;
  while (counter > 0) {
    agentSessions = agentSessions.concat(generateAgentSessionObject(options));
    counter -= 1;
  }

  return agentSessions;
}

export function generateAgentSessionObject({
  id = generateId(),
  type = generateSupportType(),
  state = generateAgentSessionState(),
  latest = true,
  createTime = Date.now(),
  resolvedTime,
  resolvedReason,
  agentId = generateId(),
  starred = false,
  sessionId = generateId(),
  sessionType = generateSupportType(),
  sessionOwnerId = generateId(),
  sessionCreateTime = Date.now(),
  sessionSourceType = generateSessionSourceType(),
  sessionSourceId = generateId(),
  tagIds = [],
}: GenerateAgentSessionOptions = {}): AgentSession {
  return {
    id,
    type,
    state,
    latest,
    createTime,
    resolvedTime,
    resolvedReason,
    agentId,
    starred,
    sessionId,
    sessionType,
    sessionOwnerId,
    sessionCreateTime,
    sessionSourceType,
    sessionSourceId,
    tagIds,
  };
}

function generateAgentSessionState(
  state = AgentSessionStateApi.ASSIGNED,
): AgentSessionStateApi {
  return state;
}

function generateSessionSourceType(
  type = SourceTypeApi.PROJECT,
): SourceTypeApi {
  return type;
}

type SupportSource = Pick<
  GenerateAgentSessionOptions,
  'sessionSourceId' | 'sessionSourceType'
>;
type SupportTypeAndSource = Pick<GenerateAgentSessionOptions, 'type'> &
  Pick<GenerateAgentSessionOptions, 'sessionType'> &
  SupportSource;

// Ref: QUEUE_TO_SOURCE_TYPE_MAP in QueueFactory.php
// Ref: SOURCE_TYPE_MAP in SupportDBUtil.php

export function withUserSourceType(userId: number): SupportSource {
  return {
    sessionSourceType: SourceTypeApi.USER,
    sessionSourceId: userId,
  };
}

export function withProjectSourceType(projectId: number): SupportSource {
  return {
    sessionSourceType: SourceTypeApi.PROJECT,
    sessionSourceId: projectId,
  };
}

export function withContestSourceType(contestId: number): SupportSource {
  return {
    sessionSourceType: SourceTypeApi.CONTEST,
    sessionSourceId: contestId,
  };
}

export function withInvoiceSourceType(invoiceId: number): SupportSource {
  return {
    sessionSourceType: SourceTypeApi.INVOICE,
    sessionSourceId: invoiceId,
  };
}

export function withOffsiteSourceType(threadId: number): SupportSource {
  return {
    sessionSourceType: SourceTypeApi.OFFSITE,
    sessionSourceId: threadId,
  };
}

export function withTransactionSourceType(
  transactionId: number,
): SupportSource {
  return {
    sessionSourceType: SourceTypeApi.TRANSACTION,
    sessionSourceId: transactionId,
  };
}

export function withTicketSourceType(ticketId: number): SupportSource {
  return {
    sessionSourceType: SourceTypeApi.TICKET,
    sessionSourceId: ticketId,
  };
}

export function withLocalJobSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.LOCAL,
    ...withProjectSourceType(projectId),
  };
}

export function withRecruiterSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.RECRUITER,
    ...withProjectSourceType(projectId),
  };
}

export function withFreightlancerSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.FREIGHTLANCER_CONCIERGE,
    ...withProjectSourceType(projectId),
  };
}

export function withLoadshiftMarketplaceSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.LOADSHIFT_MARKETPLACE_QUEUE,
    ...withProjectSourceType(projectId),
  };
}

export function withEqaSupportType(projectId: number): SupportTypeAndSource {
  return {
    type: SupportTypeApi.EQA,
    ...withProjectSourceType(projectId),
  };
}

export function withProjectQualityAuditSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.PROJECT_QUALITY_AUDIT,
    ...withProjectSourceType(projectId),
  };
}

export function withProjectManagementSupportType(
  sourceType: SourceTypeApi.PROJECT | SourceTypeApi.CONTEST,
  sourceId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.PROJECT_MANAGEMENT,
    ...(sourceType === SourceTypeApi.PROJECT
      ? withProjectSourceType(sourceId)
      : withContestSourceType(sourceId)),
  };
}

export function withSuccessManagerSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.SUCCESS_MANAGER,
    ...withProjectSourceType(projectId),
  };
}

export function withFreelancerVerifiedSupportType(
  userId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.FREELANCER_VERIFIED,
    ...withUserSourceType(userId),
  };
}

export function withPjpAssistantSupportType(
  userId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.PJP_ASSISTANT,
    ...withUserSourceType(userId),
  };
}

export function withDiscoverSupportType(userId: number): SupportTypeAndSource {
  return {
    type: SupportTypeApi.DISCOVER,
    ...withUserSourceType(userId),
  };
}

export function withExperFreelancerScreeningSupportType(
  userId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.EXPERT_FREELANCER_SCREENING,
    ...withUserSourceType(userId),
  };
}

export function withGeneralSupportType(userId: number): SupportTypeAndSource {
  return {
    type: SupportTypeApi.GENERAL,
    ...withUserSourceType(userId),
  };
}

export function withMembershipCurationSupportType(
  userId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.MEMBERSHIP_CURATION,
    ...withUserSourceType(userId),
  };
}

export function withProfilePictureCurationSupportType(
  userId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.PROFILE_PICTURE_CURATION,
    ...withUserSourceType(userId),
  };
}

export function withDepositFailureSupportType(
  invoiceId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.DEPOSIT_FAILURE,
    ...withInvoiceSourceType(invoiceId),
  };
}

export function withOffsiteSupportType(threadId: number): SupportTypeAndSource {
  return {
    type: SupportTypeApi.OFFSITE_MESSAGING,
    ...withOffsiteSourceType(threadId),
  };
}

export function withPaymentSatisfactionSupportType(
  transactionId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.PAYMENT_SATISFACTION,
    ...withTransactionSourceType(transactionId),
  };
}

export function withPfpTicketsSupportType(
  ticketId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.PFP_TICKETS,
    ...withTicketSourceType(ticketId),
  };
}

export function withHpIncomingProjectSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.HP_INCOMING_PROJECT,
    sessionType: SupportTypeApi.HP_INCOMING_PROJECT,
    ...withProjectSourceType(projectId),
  };
}

export function withHpAwaitingDeliverySupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.HP_AWAITING_DELIVERY,
    sessionType: SupportTypeApi.HP_AWAITING_DELIVERY,
    ...withProjectSourceType(projectId),
  };
}

export function withHpReviewSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.HP_REVIEW,
    sessionType: SupportTypeApi.HP_REVIEW,
    ...withProjectSourceType(projectId),
  };
}

export function withHpProgressBlockedSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.HP_PROGRESS_BLOCKED,
    sessionType: SupportTypeApi.HP_PROGRESS_BLOCKED,
    ...withProjectSourceType(projectId),
  };
}

export function withHpReviewFailuresSupportType(
  projectId: number,
): SupportTypeAndSource {
  return {
    type: SupportTypeApi.HP_REVIEW_FAILURES,
    sessionType: SupportTypeApi.HP_REVIEW_FAILURES,
    ...withProjectSourceType(projectId),
  };
}
