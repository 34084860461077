import type { ProjectSpamControls } from './project-spam-controls.model';

export interface GenerateProjectSpamControlsOptions {
  readonly projectId: number;
  readonly isSpam: boolean;
}

export function generateProjectSpamControlsObject({
  projectId,
  isSpam,
}: GenerateProjectSpamControlsOptions): ProjectSpamControls {
  return {
    id: projectId,
    isSpam,
  };
}
