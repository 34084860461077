import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HorizontalAlignment, VerticalAlignment } from '@freelancer/ui/grid';
import { HeadingType } from '@freelancer/ui/heading';
import { FontStyle, FontType, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-grid',
  template: `
    <fl-bit class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Grid
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          The grid system in Freelancer is a 12 column based Flexbox grid
          system. It’s fully responsive using a mobile first approach.
        </fl-text>
        <fl-grid class="SampleGrid">
          <fl-col
            [col]="12"
            [colTablet]="6"
            [colDesktopSmall]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colTablet]="6" [colDesktopSmall]="3"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colTablet]="6"
            [colDesktopSmall]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colTablet]="6" [colDesktopSmall]="3"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colTablet]="6"
            [colDesktopSmall]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colTablet]="6" [colDesktopSmall]="3"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colTablet]="6"
            [colDesktopSmall]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colTablet]="6" [colDesktopSmall]="3"
            </fl-bit>
          </fl-col>
          <fl-col [col]="6">
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="6"
            </fl-bit>
          </fl-col>
          <fl-col [col]="6">
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="6"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="6"
            [colDesktopSmall]="8"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="6" [colDesktopSmall]="8"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="6"
            [colDesktopSmall]="4"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="6" [colDesktopSmall]="4"
            </fl-bit>
          </fl-col>
        </fl-grid>
        <app-code-block
          [code]="codeSnippetIntro"
          [flMarginBottom]="'large'"
          [language]="'xml'"
        ></app-code-block>
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Here's how it works:
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          All fl-col should be wrapped inside fl-grid. Components or contents
          must be placed inside the columns and only columns may be the
          immediate children of fl-grid.
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          fl-col properties' value indicate the number of columns you'd like to
          use out of the possible 12 columns per row. So if you want four
          equal-width columns accross, you may pass a value of "4".
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'large'"
        >
          To make it responsive, you can make use of fl-col's four breakpoints
          (<fl-text [fontType]="FontType.STRONG">[col]</fl-text>,
          <fl-text [fontType]="FontType.STRONG">[colTablet]</fl-text>,
          <fl-text [fontType]="FontType.STRONG">[colDesktopSmall]</fl-text>, and
          <fl-text [fontType]="FontType.STRONG">[colDesktopLarge]</fl-text>)
          which is based on minimum media queries width applying to that
          breakpoint and all those above it. For example, Setting
          <fl-text [fontType]="FontType.STRONG">[col]="12"</fl-text> will make
          the fl-col's width to 100% of the grid on mobile and adding
          <fl-text [fontType]="FontType.STRONG">[colTablet]="6"</fl-text> with
          it will make the width 50% of the grid from tablet(768px) viewport and
          above.
        </fl-text>

        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Breakpoints:
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          <fl-text [fontType]="FontType.STRONG">[colTablet]</fl-text>: 768px;
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          <fl-text [fontType]="FontType.STRONG">[colDesktopSmall]</fl-text>:
          960px;
        </fl-text>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xsmall'"
        >
          <fl-text [fontType]="FontType.STRONG">[colDesktopLarge]</fl-text>:
          1200px;
        </fl-text>
      </fl-card>

      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Alignment
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          Use fl-grid alignment properties to vertically and horizontally align
          columns.
        </fl-text>

        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'xsmall'"
        >
          Horizontal alignment using [hAlign] property
        </fl-heading>
        <fl-bit [flMarginBottom]="'small'">
          <fl-grid
            class="SampleGrid"
            [hAlign]="HorizontalAlignment.HORIZONTAL_CENTER"
          >
            <fl-col
              [col]="12"
              [colTablet]="6"
              [colDesktopSmall]="3"
            >
              <fl-bit
                class="SampleComponent"
                [flMarginBottom]="'small'"
              >
                Column
              </fl-bit>
            </fl-col>
            <fl-col
              [col]="12"
              [colTablet]="6"
              [colDesktopSmall]="3"
            >
              <fl-bit
                class="SampleComponent"
                [flMarginBottom]="'small'"
              >
                Column
              </fl-bit>
            </fl-col>
          </fl-grid>
          <fl-grid
            class="SampleGrid"
            [hAlign]="HorizontalAlignment.HORIZONTAL_RIGHT"
          >
            <fl-col
              [col]="12"
              [colTablet]="6"
              [colDesktopSmall]="3"
            >
              <fl-bit
                class="SampleComponent"
                [flMarginBottom]="'small'"
              >
                Column
              </fl-bit>
            </fl-col>
            <fl-col
              [col]="12"
              [colTablet]="6"
              [colDesktopSmall]="3"
            >
              <fl-bit
                class="SampleComponent"
                [flMarginBottom]="'small'"
              >
                Column
              </fl-bit>
            </fl-col>
          </fl-grid>
        </fl-bit>
        <app-code-block
          [code]="codeSnippetHorizontalAlign"
          [language]="'xml'"
          [flMarginBottom]="'large'"
        ></app-code-block>

        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'xsmall'"
        >
          Vertical alignment using [vAlign] property
        </fl-heading>
        <fl-bit [flMarginBottom]="'small'">
          <fl-grid
            class="SampleGrid"
            [vAlign]="VerticalAlignment.VERTICAL_CENTER"
          >
            <fl-col
              [col]="12"
              [colTablet]="6"
              [colDesktopSmall]="3"
            >
              <fl-bit
                class="SampleComponent"
                [flMarginBottom]="'small'"
              >
                Column
              </fl-bit>
            </fl-col>
            <fl-col
              [col]="12"
              [colTablet]="6"
              [colDesktopSmall]="3"
            >
              <fl-bit
                class="SampleComponent SampleComponent--large"
                [flMarginBottom]="'small'"
              >
                Column
              </fl-bit>
            </fl-col>
          </fl-grid>
        </fl-bit>

        <app-code-block
          [code]="codeSnippetVerticalAlignA"
          [language]="'xml'"
          [flMarginBottom]="'large'"
        ></app-code-block>
        <fl-bit [flMarginBottom]="'small'">
          <fl-grid
            class="SampleGrid"
            [vAlign]="VerticalAlignment.VERTICAL_BOTTOM"
          >
            <fl-col
              [col]="12"
              [colTablet]="6"
              [colDesktopSmall]="3"
            >
              <fl-bit
                class="SampleComponent SampleComponent--large"
                [flMarginBottom]="'small'"
              >
                Column
              </fl-bit>
            </fl-col>
            <fl-col
              [col]="12"
              [colTablet]="6"
              [colDesktopSmall]="3"
            >
              <fl-bit
                class="SampleComponent"
                [flMarginBottom]="'small'"
              >
                Column
              </fl-bit>
            </fl-col>
          </fl-grid>
        </fl-bit>
        <app-code-block
          [code]="codeSnippetVerticalAlignB"
          [language]="'xml'"
          [flMarginBottom]="'large'"
        ></app-code-block>
      </fl-card>

      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Reordering
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          Use [colOrder], [colTabletOrder], [colDesktopSmallOrder],
          [colDesktopLargeOrder] property for controlling visual order depending
          on the viewport.
        </fl-text>
        <fl-grid>
          <fl-col
            [col]="12"
            [colOrder]="1"
            [colTabletOrder]="2"
            [colDesktopSmallOrder]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              1st in mobile, 2nd in tablet, 3rd in desktop
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colOrder]="2"
            [colTabletOrder]="3"
            [colDesktopSmallOrder]="1"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              2nd in mobile, 3rd in tablet, 1st in desktop
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colOrder]="3"
            [colTabletOrder]="1"
            [colDesktopSmallOrder]="2"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              3rd in mobile, 1st in tablet, 2nd in desktop
            </fl-bit>
          </fl-col>
        </fl-grid>
        <app-code-block
          [code]="codeSnippetOrder"
          [language]="'xml'"
          [flMarginBottom]="'large'"
        ></app-code-block>
      </fl-card>

      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'xsmall'"
        >
          Offsetting columns
        </fl-heading>
        <fl-text
          [fontType]="FontType.PARAGRAPH"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'mid'"
        >
          Move columns to the right or left using [pull] properties.
        </fl-text>
        <fl-grid>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
            [pull]="'right'"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3" [pull]="'right'"
            </fl-bit>
          </fl-col>
        </fl-grid>
        <fl-grid>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
            [pull]="'left'"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3" [pull]="'left'"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3"
            </fl-bit>
          </fl-col>
        </fl-grid>
        <fl-grid>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
            [pull]="'right'"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3" [pull]="'right'"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3"
            </fl-bit>
          </fl-col>
        </fl-grid>
        <fl-grid>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
            [pull]="'right'"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3" [pull]="'right'"
            </fl-bit>
          </fl-col>
          <fl-col
            [col]="12"
            [colDesktopSmall]="3"
            [pull]="'right'"
          >
            <fl-bit
              class="SampleComponent"
              [flMarginBottom]="'small'"
            >
              [col]="12" [colDesktopSmall]="3" [pull]="'right'"
            </fl-bit>
          </fl-col>
        </fl-grid>
        <app-code-block
          [code]="codeSnippetOffsetting"
          [language]="'xml'"
          [flMarginBottom]="'large'"
        ></app-code-block>
      </fl-card>
    </fl-bit>
  `,
  styleUrls: ['../static-pages.component.scss', './grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent {
  HeadingType = HeadingType;
  TextSize = TextSize;
  FontStyle = FontStyle;
  FontType = FontType;
  FontWeight = FontWeight;
  HorizontalAlignment = HorizontalAlignment;
  VerticalAlignment = VerticalAlignment;

  codeSnippetIntro = `
<fl-grid>
  <!-- Full width on mobile, half-width on tablet, a quarter on desktop -->
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colTablet]="6" [colDesktopSmall]="3"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colTablet]="6" [colDesktopSmall]="3"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colTablet]="6" [colDesktopSmall]="3"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colTablet]="6" [colDesktopSmall]="3"
    </fl-bit>
  </fl-col>

  <!-- Columns are always half-width on mobile and desktop-->
  <fl-col
    [col]="6"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="6"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="6"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="6"
    </fl-bit>
  </fl-col>

  <!-- Half width on mobile, Uneven widths on desktop -->
  <fl-col
    [col]="6"
    [colDesktopSmall]="8"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="6" [colDesktopSmall]="8"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="6"
    [colDesktopSmall]="4"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="6" [colDesktopSmall]="4"
    </fl-bit>
  </fl-col>
</fl-grid>
  `;

  codeSnippetHorizontalAlign = `
<fl-grid [hAlign]="HorizontalAlignment.HORIZONTAL_CENTER">
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      Column
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      Column
    </fl-bit>
  </fl-col>
</fl-grid>
<fl-grid [hAlign]="HorizontalAlignment.HORIZONTAL_RIGHT">
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      Column
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      Column
    </fl-bit>
  </fl-col>
</fl-grid>
  `;

  codeSnippetVerticalAlignA = `
<fl-grid [vAlign]="VerticalAlignment.VERTICAL_CENTER">
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      Column
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      Column
    </fl-bit>
  </fl-col>
</fl-grid>
  `;

  codeSnippetVerticalAlignB = `
<fl-grid [vAlign]="VerticalAlignment.VERTICAL_BOTTOM">
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      Column
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colTablet]="6"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      Column
    </fl-bit>
  </fl-col>
</fl-grid>
  `;

  codeSnippetOrder = `
<fl-grid>
  <fl-col
    [col]="12"
    [colOrder]="1"
    [colTabletOrder]="2"
    [colDesktopSmallOrder]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      1st in mobile, 2nd in tablet, 3rd in desktop
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colOrder]="2"
    [colTabletOrder]="3"
    [colDesktopSmallOrder]="1"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      2nd in mobile, 3rd in tablet, 1st in desktop
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colOrder]="3"
    [colTabletOrder]="1"
    [colDesktopSmallOrder]="2"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      3rd in mobile, 1st in tablet, 2nd in desktop
    </fl-bit>
  </fl-col>
</fl-grid>
  `;

  codeSnippetOffsetting = `
<fl-grid>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
    [pull]="'right'"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3" [pull]="'right'"
    </fl-bit>
  </fl-col>
  </fl-grid>
<fl-grid>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
    [pull]="'left'"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3" [pull]="'left'"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3"
    </fl-bit>
  </fl-col>
</fl-grid>
<fl-grid>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
    [pull]="'right'"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3" [pull]="'right'"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3"
    </fl-bit>
  </fl-col>
</fl-grid>
<fl-grid>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
    [pull]="'right'"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3" [pull]="'right'"
    </fl-bit>
  </fl-col>
  <fl-col
    [col]="12"
    [colDesktopSmall]="3"
    [pull]="'right'"
  >
    <fl-bit class="SampleComponent" [flMarginBottom]="'small'">
      [col]="12" [colDesktopSmall]="3" [pull]="'right'"
    </fl-bit>
  </fl-col>
</fl-grid>
  `;
}
