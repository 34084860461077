import { assertNever } from '@freelancer/utils';
import { CurrencyCode } from '../currencies';
import type { ExchangeRate } from './exchange-rates.model';

export interface GenerateExchangeRatesOptions {
  readonly currencyCodes: readonly CurrencyCode[];
}

export function generateExchangeRatesObject(
  options: GenerateExchangeRatesOptions,
): readonly ExchangeRate[] {
  return options.currencyCodes.map(generateExchangeRateObject);
}

export function generateExchangeRateObject(
  currencyCode: CurrencyCode,
): ExchangeRate {
  switch (currencyCode) {
    case CurrencyCode.USD:
      return {
        id: 'USD',
        from: {
          COP: 0.000_283,
          USD: 1,
          QAR: 0.260_918,
          IDR: 0.000_069,
          AED: 0.258_63,
          GBP: 1.248_337,
          DKK: 0.140_885,
          CAD: 0.722_093,
          PKR: 0.006_147,
          MXN: 0.050_81,
          HUF: 0.003_131,
          VND: 0.000_041,
          RON: 0.220_091,
          TKN: 0,
          MYR: 0.233_558,
          SAR: 0.253_232,
          SEK: 0.099_892,
          SGD: 0.704_349,
          JMD: 0.006_902,
          AUD: 0.652_512,
          CHF: 0.981_09,
          KRW: 0.000_814,
          CNY: 0.137_546,
          TRY: 0.160_463,
          BDT: 0.011_207,
          NZD: 0.625_965,
          CLP: 0.001_083,
          THB: 0.031_27,
          EUR: 1.0534,
          ARS: 0.021_005,
          NOK: 0.105_777,
          RUB: 0.015_346,
          INR: 0.013_366,
          JPY: 0.008_661,
          BRL: 0.227_132,
          HKD: 0.122_203,
          PLN: 0.248_408,
          PHP: 0.018_644,
          ZAR: 0.066_348,
          ILS: 0.274_431,
        },
      };
    case CurrencyCode.NZD:
      return {
        id: 'NZD',
        from: {
          COP: 0.000_429,
          USD: 1.441_773,
          QAR: 0.395_726,
          IDR: 0.000_105,
          AED: 0.392_257,
          GBP: 1.894_546,
          DKK: 0.213_676,
          CAD: 1.095_89,
          PKR: 0.009_322,
          MXN: 0.077_062,
          HUF: 0.004_749,
          VND: 0.000_062,
          RON: 0.333_805,
          TKN: 0,
          MYR: 0.354_232,
          SAR: 0.384_071,
          SEK: 0.151_503,
          SGD: 1.068_959,
          JMD: 0.010_467,
          AUD: 0.990_29,
          CHF: 1.487_992,
          KRW: 0.001_235,
          CNY: 0.208_611,
          TRY: 0.243_368,
          BDT: 0.016_997,
          NZD: 1,
          CLP: 0.001_666,
          THB: 0.047_427,
          EUR: 1.598_699,
          ARS: 0.032_575,
          NOK: 0.160_428,
          RUB: 0.023_275,
          INR: 0.020_284,
          JPY: 0.013_136,
          BRL: 0.344_484,
          HKD: 0.185_463,
          PLN: 0.376_754,
          PHP: 0.028_277,
          ZAR: 0.100_628,
          ILS: 0.416_223,
        },
      };
    case CurrencyCode.AUD:
      return {
        id: 'AUD',
        from: {
          COP: 0.000_412,
          USD: 1.383_115,
          QAR: 0.379_724,
          IDR: 0.000_102,
          AED: 0.376_395,
          GBP: 1.817_468,
          DKK: 0.205_036,
          CAD: 1.051_303,
          PKR: 0.008_945,
          MXN: 0.073_945,
          HUF: 0.004_557,
          VND: 0.000_06,
          RON: 0.320_307,
          TKN: 0,
          MYR: 0.339_907,
          SAR: 0.368_538,
          SEK: 0.145_377,
          SGD: 1.025_469,
          JMD: 0.010_044,
          AUD: 1,
          CHF: 1.427_818,
          KRW: 0.001_185,
          CNY: 0.200_175,
          TRY: 0.233_527,
          BDT: 0.016_31,
          NZD: 0.911_349,
          CLP: 0.001_589,
          THB: 0.045_51,
          EUR: 1.533_657,
          ARS: 0.031_095,
          NOK: 0.153_941,
          RUB: 0.022_335,
          INR: 0.019_459,
          JPY: 0.012_605,
          BRL: 0.330_553,
          HKD: 0.177_917,
          PLN: 0.361_518,
          PHP: 0.027_133,
          ZAR: 0.096_559,
          ILS: 0.399_391,
        },
      };
    case CurrencyCode.GBP:
      return {
        id: 'GBP',
        from: {
          COP: 0.000_216,
          USD: 0.722_962,
          QAR: 0.198_495,
          IDR: 0.000_053,
          AED: 0.196_755,
          GBP: 1,
          DKK: 0.107_179,
          CAD: 0.549_522,
          PKR: 0.004_676,
          MXN: 0.038_654,
          HUF: 0.002_383,
          VND: 0.000_031,
          RON: 0.167_436,
          TKN: 0,
          MYR: 0.177_681,
          SAR: 0.192_649,
          SEK: 0.075_993,
          SGD: 0.536_019,
          JMD: 0.005_251,
          AUD: 0.496_57,
          CHF: 0.746_372,
          KRW: 0.000_619,
          CNY: 0.104_639,
          TRY: 0.122_073,
          BDT: 0.008_525,
          NZD: 0.476_367,
          CLP: 0.000_838,
          THB: 0.023_789,
          EUR: 0.801_651,
          ARS: 0.017_391,
          NOK: 0.080_471,
          RUB: 0.011_675,
          INR: 0.010_171,
          JPY: 0.006_589,
          BRL: 0.172_792,
          HKD: 0.092_998,
          PLN: 0.188_979,
          PHP: 0.014_184,
          ZAR: 0.050_474,
          ILS: 0.208_776,
        },
      };
    case CurrencyCode.HKD:
      return {
        id: 'HKD',
        from: {
          COP: 0.002_202,
          USD: 7.385_224,
          QAR: 2.028_37,
          IDR: 0.000_542,
          AED: 2.010_589,
          GBP: 9.704_47,
          DKK: 1.095_239,
          CAD: 5.613_493,
          PKR: 0.047_781,
          MXN: 0.394_996,
          HUF: 0.024_344,
          VND: 0.000_319,
          RON: 1.710_985,
          TKN: 0,
          MYR: 1.815_685,
          SAR: 1.968_625,
          SEK: 0.776_557,
          SGD: 5.475_551,
          JMD: 0.053_651,
          AUD: 5.072_578,
          CHF: 7.626_987,
          KRW: 0.006_328,
          CNY: 1.069_279,
          TRY: 1.247_432,
          BDT: 0.087_123,
          NZD: 4.866_203,
          CLP: 0.008_481,
          THB: 0.243_097,
          EUR: 8.189_047,
          ARS: 0.164_806,
          NOK: 0.822_309,
          RUB: 0.119_302,
          INR: 0.103_901,
          JPY: 0.067_33,
          BRL: 1.765_718,
          HKD: 1,
          PLN: 1.931_122,
          PHP: 0.144_936,
          ZAR: 0.515_789,
          ILS: 2.133_43,
        },
      };
    case CurrencyCode.SGD:
      return {
        id: 'SGD',
        from: {
          COP: 0.000_382,
          USD: 1.281_326,
          QAR: 0.351_934,
          IDR: 0.000_094,
          AED: 0.348_849,
          GBP: 1.683_712,
          DKK: 0.190_03,
          CAD: 0.973_932,
          PKR: 0.008_291,
          MXN: 0.068_534,
          HUF: 0.004_224,
          VND: 0.000_055,
          RON: 0.296_866,
          TKN: 0,
          MYR: 0.315_032,
          SAR: 0.341_568,
          SEK: 0.134_738,
          SGD: 1,
          JMD: 0.009_309,
          AUD: 0.880_085,
          CHF: 1.323_327,
          KRW: 0.001_098,
          CNY: 0.185_526,
          TRY: 0.216_437,
          BDT: 0.015_116,
          NZD: 0.844_279,
          CLP: 0.001_48,
          THB: 0.042_179,
          EUR: 1.420_788,
          ARS: 0.029_127,
          NOK: 0.142_676,
          RUB: 0.0207,
          INR: 0.018_026,
          JPY: 0.011_682,
          BRL: 0.306_363,
          HKD: 0.164_824,
          PLN: 0.335_061,
          PHP: 0.025_147,
          ZAR: 0.089_493,
          ILS: 0.370_163,
        },
      };
    case CurrencyCode.EUR:
      return {
        id: 'EUR',
        from: {
          COP: 0.000_256,
          USD: 0.856_75,
          QAR: 0.235_44,
          IDR: 0.000_063,
          AED: 0.233_377,
          GBP: 1.125_802,
          DKK: 0.127_129,
          CAD: 0.651_214,
          PKR: 0.005_546,
          MXN: 0.045_849,
          HUF: 0.002_825,
          VND: 0.000_037,
          RON: 0.1986,
          TKN: 0,
          MYR: 0.210_754,
          SAR: 0.228_506,
          SEK: 0.090_138,
          SGD: 0.635_211,
          JMD: 0.006_227,
          AUD: 0.588_463,
          CHF: 0.885_295,
          KRW: 0.000_734,
          CNY: 0.124_116,
          TRY: 0.144_794,
          BDT: 0.010_113,
          NZD: 0.564_521,
          CLP: 0.000_979,
          THB: 0.028_217,
          EUR: 1,
          ARS: 0.018_732,
          NOK: 0.095_448,
          RUB: 0.013_848,
          INR: 0.012_054,
          JPY: 0.007_816,
          BRL: 0.204_954,
          HKD: 0.110_209,
          PLN: 0.224_153,
          PHP: 0.016_824,
          ZAR: 0.059_87,
          ILS: 0.247_636,
        },
      };
    case CurrencyCode.CAD:
      return {
        id: 'CAD',
        from: {
          COP: 0.000_372,
          USD: 1.249_839,
          QAR: 0.343_216,
          IDR: 0.000_091,
          AED: 0.340_207,
          GBP: 1.642_337,
          DKK: 0.185_323,
          CAD: 1,
          PKR: 0.008_085,
          MXN: 0.066_836,
          HUF: 0.004_119,
          VND: 0.000_054,
          RON: 0.289_513,
          TKN: 0,
          MYR: 0.307_228,
          SAR: 0.333_107,
          SEK: 0.131_399,
          SGD: 0.926_656,
          JMD: 0.009_078,
          AUD: 0.858_458,
          CHF: 1.290_547,
          KRW: 0.001_071,
          CNY: 0.180_93,
          TRY: 0.211_076,
          BDT: 0.014_742,
          NZD: 0.823_532,
          CLP: 0.001_441,
          THB: 0.041_134,
          EUR: 1.385_874,
          ARS: 0.027_792,
          NOK: 0.139_141,
          RUB: 0.020_187,
          INR: 0.017_584,
          JPY: 0.011_392,
          BRL: 0.298_773,
          HKD: 0.160_773,
          PLN: 0.326_761,
          PHP: 0.024_524,
          ZAR: 0.087_276,
          ILS: 0.360_993,
        },
      };
    case CurrencyCode.INR:
      return {
        id: 'INR',
        from: {
          COP: 0.020_133,
          USD: 67.525_573,
          QAR: 18.545_887,
          IDR: 0.004_95,
          AED: 18.383_309,
          GBP: 88.731_222,
          DKK: 10.014_036,
          CAD: 51.326_046,
          PKR: 0.436_876,
          MXN: 3.611_539,
          HUF: 0.222_579,
          VND: 0.002_916,
          RON: 15.643_956,
          TKN: 0,
          MYR: 16.601_25,
          SAR: 17.999_626,
          SEK: 7.100_251,
          SGD: 50.064_793,
          JMD: 0.490_551,
          AUD: 46.380_277,
          CHF: 69.735_412,
          KRW: 0.057_862,
          CNY: 9.776_68,
          TRY: 11.405_576,
          BDT: 0.796_581,
          NZD: 44.493_317,
          CLP: 0.077_659,
          THB: 2.222_698,
          EUR: 74.875_191,
          ARS: 1.495_887,
          NOK: 7.518_567,
          RUB: 1.090_809,
          INR: 1,
          JPY: 0.615_613,
          BRL: 16.144_401,
          HKD: 8.686_167,
          PLN: 17.656_722,
          PHP: 1.325_187,
          ZAR: 4.715_989,
          ILS: 19.506_477,
        },
      };
    case CurrencyCode.TKN:
      return {
        id: 'TKN',
        from: {
          COP: 0,
          USD: 0,
          QAR: 0,
          IDR: 0,
          AED: 0,
          GBP: 0,
          DKK: 0,
          CAD: 0,
          PKR: 0,
          MXN: 0,
          HUF: 0,
          VND: 0,
          RON: 0,
          TKN: 0,
          MYR: 0,
          SAR: 0,
          SEK: 0,
          SGD: 0,
          JMD: 0,
          AUD: 0,
          CHF: 0,
          KRW: 0,
          CNY: 0,
          TRY: 0,
          BDT: 0,
          NZD: 0,
          CLP: 0,
          THB: 0,
          EUR: 0,
          ARS: 0,
          NOK: 0,
          RUB: 0,
          INR: 0,
          JPY: 0,
          BRL: 0,
          HKD: 0,
          PLN: 0,
          PHP: 0,
          ZAR: 0,
          ILS: 0,
        },
      };

    default:
      return assertNever(currencyCode);
  }
}
