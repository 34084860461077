import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiModule } from '@freelancer/ui';
import { HighlightModule } from 'ngx-highlightjs';
import { CodeBlockComponent } from './code-block.component';

@NgModule({
  imports: [CommonModule, UiModule, HighlightModule],
  declarations: [CodeBlockComponent],
  exports: [CodeBlockComponent],
})
export class CodeBlockModule {}
