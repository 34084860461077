import type { Currency } from '../currencies';
import { generateCurrencyObject } from '../currencies';

enum CurrencyCode {
  USD = 'USD',
  NZD = 'NZD',
  AUD = 'AUD',
  GBP = 'GBP',
  HKD = 'HKD',
  SGD = 'SGD',
  EUR = 'EUR',
  CAD = 'CAD',
  INR = 'INR',
  TKN = 'TKN',
}

export function generateCurrencyIncludingExternalObjects(): readonly Currency[] {
  return [
    CurrencyCode.USD,
    CurrencyCode.NZD,
    CurrencyCode.AUD,
    CurrencyCode.GBP,
    CurrencyCode.HKD,
    CurrencyCode.SGD,
    CurrencyCode.EUR,
    CurrencyCode.CAD,
    CurrencyCode.INR,
    CurrencyCode.TKN,
  ].map(code => generateCurrencyIncludingExternalObject(code));
}

export function generateCurrencyIncludingExternalObject(
  currencyCode: CurrencyCode,
): Currency {
  switch (currencyCode) {
    default:
      return generateCurrencyObject(currencyCode);
  }
}
