import { animate, animation, keyframes, style } from '@angular/animations';

// See https://cubic-bezier.com/#.17,.67,.83,.67
const ease = 'ease';

export const shake = animation(
  [
    animate(
      '{{ duration }}ms {{ delay }}ms',
      keyframes([
        style({
          transform: 'translate3d(0, 0, 0)',
          easing: ease,
          offset: 0,
        }),
        style({
          transform: 'translate3d(-{{ translateX }}, 0, 0)',
          easing: ease,
          offset: 0.1,
        }),
        style({
          transform: 'translate3d({{ translateX }}, 0, 0)',
          easing: ease,
          offset: 0.2,
        }),
        style({
          transform: 'translate3d(-{{ translateX }}, 0, 0)',
          easing: ease,
          offset: 0.3,
        }),
        style({
          transform: 'translate3d({{ translateX }}, 0, 0)',
          easing: ease,
          offset: 0.4,
        }),
        style({
          transform: 'translate3d(-{{ translateX }}, 0, 0)',
          easing: ease,
          offset: 0.5,
        }),
        style({
          transform: 'translate3d({{ translateX }}, 0, 0)',
          easing: 'ease',
          offset: 0.6,
        }),
        style({
          transform: 'translate3d(-{{ translateX }}, 0, 0)',
          easing: ease,
          offset: 0.7,
        }),
        style({
          transform: 'translate3d({{ translateX }}, 0, 0)',
          easing: ease,
          offset: 0.8,
        }),
        style({
          transform: 'translate3d(-{{ translateX }}, 0, 0)',
          easing: ease,
          offset: 0.9,
        }),
        style({ transform: 'translate3d(0, 0, 0)', easing: ease, offset: 1 }),
      ]),
    ),
  ],
  {
    params: {
      duration: '700',
      delay: '0',
      translateX: '6px', // can be any unit
    },
  },
);
