import type { UserPhoneVerificationBackoff } from './user-phone-verification-backoff.model';

export interface GenerateUserPhoneVerificationBackoffOptions {
  readonly userId: string;
  readonly backoffTimeLeft: number;
  readonly timestamp?: number;
}

export function generateUserPhoneVerificationBackoffObject({
  userId,
  backoffTimeLeft,
  timestamp = Date.now(),
}: GenerateUserPhoneVerificationBackoffOptions): UserPhoneVerificationBackoff {
  return {
    id: userId,
    backoffTimeLeft,
    timestamp,
  };
}
