import { generateId } from '@freelancer/datastore/testing/helpers';
import type {
  ExternalReference,
  ExternalReferenceContextTypeEnum,
} from './external-reference.model';

export interface GenerateExternalReferenceOptions {
  readonly contextId: number;
  readonly contextType: ExternalReferenceContextTypeEnum;
  readonly authorUserId: number;
  readonly relationship: string;
  readonly content: string;
  readonly deletedDate?: number | null;
}

export function generateExternalReferenceObject({
  contextId,
  contextType,
  authorUserId,
  relationship,
  content,
  deletedDate,
}: GenerateExternalReferenceOptions): ExternalReference {
  return {
    id: generateId(),
    contextId,
    contextType,
    authorUserId,
    relationship,
    content,
    deletedDate: deletedDate ?? undefined,
  };
}
