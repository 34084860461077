import { generateId } from '@freelancer/datastore/testing/helpers';
import { DepositMethodApi } from 'api-typings/common/common';
import {
  PaymentShareEntityTypeApi,
  PaymentShareSpendingLimitDurationApi,
  PaymentShareTeamStatusApi,
} from 'api-typings/users/users';
import type {
  BillingAgreementInfo,
  PaymentShareTeam,
} from './payment-share-teams.model';

export interface GeneratePaymentShareTeamOptions {
  readonly id?: number;
  readonly ownerEntityId: number;
  readonly ownerEntityType?: PaymentShareEntityTypeApi;
  readonly paymentShareTeamName?: string;
  readonly status?: PaymentShareTeamStatusApi;
  readonly memberSpendingLimitDuration?: PaymentShareSpendingLimitDurationApi;
  readonly memberSpendingLimitAmount?: number;
  readonly isSpendingLimitActive?: boolean;
  readonly isTeamOwner?: boolean;
  readonly billingAgreementId?: number;
  readonly depositMethod?: DepositMethodApi;
}

/** Returns a payment share team object. */
export function generatePaymentShareTeamObject({
  id = generateId(),
  ownerEntityId,
  ownerEntityType = PaymentShareEntityTypeApi.USER,
  paymentShareTeamName = 'The Payment Share Team Name',
  status = PaymentShareTeamStatusApi.ENABLED,
  memberSpendingLimitDuration = PaymentShareSpendingLimitDurationApi.NO_TIME_LIMIT,
  memberSpendingLimitAmount = 0,
  isSpendingLimitActive = false,
  isTeamOwner = true,
  billingAgreementId = 1,
  depositMethod = DepositMethodApi.DEV_CREDITS,
}: GeneratePaymentShareTeamOptions): PaymentShareTeam {
  let billingAgreementInfo: BillingAgreementInfo;
  if (isTeamOwner) {
    billingAgreementInfo = {
      isTeamOwner,
      billingAgreementId,
      depositMethod,
    };
  } else {
    billingAgreementInfo = {
      isTeamOwner: false,
      billingAgreementId: undefined,
      depositMethod: undefined,
    };
  }

  return {
    id,
    ownerEntityId,
    ownerEntityType,
    paymentShareTeamName,
    status,
    memberSpendingLimitDuration,
    memberSpendingLimitAmount,
    isSpendingLimitActive,
    ...billingAgreementInfo,
  };
}
