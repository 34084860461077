import { generateId } from '@freelancer/datastore/testing/helpers';
import { ProfileStatusApi } from 'api-typings/users/users';
import { v4 as uuid } from 'uuid';
import type { Profile } from './profiles.model';

export interface GenerateProfileObjectOptions {
  readonly userId?: number;
  readonly profileName?: string;
  readonly hourlyRate?: number;
  readonly tagline?: string;
  readonly description?: string;
  readonly isDefault?: boolean;
  readonly seoUrl?: string;
  readonly createDate?: number;
  readonly lastUpdatedDate?: number;
  readonly skillIds?: readonly number[];
}

export function generateProfileObject({
  userId = generateId(),
  profileName = 'Test Profile',
  hourlyRate = 4,
  tagline = 'Test Tagline',
  description = 'Test Description',
  isDefault = false,
  status = ProfileStatusApi.ACTIVE,
  seoUrl = uuid(),
  createDate = Date.now(),
  lastUpdatedDate = Date.now(),
  skillIds = [] as readonly number[],
} = {}): Profile {
  return {
    id: generateId(),
    userId,
    profileName,
    hourlyRate,
    tagline,
    description,
    isDefault,
    seoUrl,
    status,
    createDate,
    lastUpdatedDate,
    skillIds,
  };
}

export function generateProfileObjects(
  profiles: readonly GenerateProfileObjectOptions[],
): readonly Profile[] {
  return profiles.map(generateProfileObject);
}

/** Returns a default profile */
export function defaultProfile({
  userId,
}: {
  readonly userId: number;
}): Pick<GenerateProfileObjectOptions, 'profileName' | 'isDefault' | 'userId'> {
  return {
    profileName: 'General',
    userId,
    isDefault: true,
  };
}

export function webDesignProfile({
  userId,
}: {
  readonly userId: number;
}): Pick<
  GenerateProfileObjectOptions,
  | 'profileName'
  | 'tagline'
  | 'description'
  | 'hourlyRate'
  | 'userId'
  | 'skillIds'
> {
  return {
    userId,
    profileName: 'Web Design',
    tagline: 'Web Design Tagline',
    description: 'Web Design Description',
    hourlyRate: 100,
    skillIds: [17], // Web Design skill
  };
}

export function graphicDesignProfile({
  userId,
}: {
  readonly userId: number;
}): Pick<
  GenerateProfileObjectOptions,
  | 'profileName'
  | 'tagline'
  | 'description'
  | 'hourlyRate'
  | 'userId'
  | 'skillIds'
> {
  return {
    userId,
    profileName: 'Graphic Design',
    tagline: 'Graphic Design Tagline',
    description: 'Graphic Design Description',
    hourlyRate: 200,
    skillIds: [20], // Graphic Design skill
  };
}

export function webDevelopmentProfile({
  userId,
}: {
  readonly userId: number;
}): Pick<
  GenerateProfileObjectOptions,
  | 'profileName'
  | 'tagline'
  | 'description'
  | 'hourlyRate'
  | 'userId'
  | 'skillIds'
> {
  return {
    userId,
    profileName: 'Web Development',
    tagline: 'Web Development Tagline',
    description: 'Web Development Description',
    hourlyRate: 300,
    skillIds: [13], // Python skill
  };
}

export function mobileDevelopmentProfile({
  userId,
}: {
  readonly userId: number;
}): Pick<
  GenerateProfileObjectOptions,
  | 'profileName'
  | 'tagline'
  | 'description'
  | 'hourlyRate'
  | 'userId'
  | 'skillIds'
> {
  return {
    userId,
    profileName: 'Mobile Development',
    tagline: 'Mobile Development Tagline',
    description: 'Mobile Development Description',
    hourlyRate: 400,
    skillIds: [716], // Mobile App Testing skill
  };
}

export function dataEntryProfile({
  userId,
}: {
  readonly userId: number;
}): Pick<
  GenerateProfileObjectOptions,
  | 'profileName'
  | 'tagline'
  | 'description'
  | 'hourlyRate'
  | 'userId'
  | 'skillIds'
> {
  return {
    userId,
    profileName: 'Data Entry',
    tagline: 'Data Entry Tagline',
    description: 'Data Entry Description',
    hourlyRate: 500,
    skillIds: [39], // Data Entry skill
  };
}
