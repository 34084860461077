import { NgModule } from '@angular/core';
import { MODALS } from '@freelancer/ui/modal';
import { bitsModals } from './bits-modal-routes';

@NgModule({
  providers: [
    {
      provide: MODALS,
      useValue: bitsModals,
      multi: true,
    },
  ],
})
export class AppModalsModule {}
