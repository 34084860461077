import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { RepetitiveSubscription } from '@freelancer/decorators';
import { FontColor, TextSize } from '@freelancer/ui/text';
import { Subscription } from 'rxjs';
import { CheckboxColor, CheckboxSize } from './checkbox.types';

@Component({
  selector: 'fl-checkbox',
  template: `
    <div
      class="CheckboxContainer"
      [ngClass]="{
        IsInvalid: control.invalid && control.dirty,
        IsDisabled: control.disabled || disabled
      }"
      [attr.data-font-color]="fontColor"
      [attr.disabled]="control.disabled || disabled ? true : null"
      [flMarginBottom]="
        !forListItem && control.invalid && control.dirty
          ? 'xxxsmall'
          : undefined
      "
    >
      <input
        class="NativeElement"
        type="checkbox"
        [id]="id"
        [checked]="control.value"
        [formControl]="control"
        [attr.data-color]="color"
        [attr.disabled]="control.disabled || disabled ? true : null"
        [attr.aria-invalid]="control.invalid && control.dirty"
        [attr.aria-errormessage]="errorId"
        [attr.aria-label]="ariaLabel"
      />
      <label
        class="CheckboxLabel"
        [attr.data-size]="size"
        [attr.data-color]="color"
        [attr.tabindex]="0"
        [class.InputOnly]="forListItem"
        [class.CheckboxLabel-error]="control.invalid && control.dirty"
        [for]="id"
        (click)="$event.stopPropagation()"
      >
        <span
          class="CheckboxEffect"
          [attr.data-color]="color"
        ></span>
        <ng-container *ngIf="!forListItem"> {{ label }} </ng-container>
      </label>
    </div>
    <fl-validation-error
      *ngIf="!forListItem"
      [id]="id"
      [control]="control"
      (click)="$event.stopPropagation()"
    ></fl-validation-error>
  `,
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnChanges, OnDestroy, OnInit {
  TextSize = TextSize;
  FontColor = FontColor;

  @RepetitiveSubscription()
  private statusChangeSubscription?: Subscription;

  checkboxGroupId: string;
  errorId: string;

  @Input() forListItem = false;
  @Input() control: FormControl<boolean>;
  @Input() disabled = false;
  @Input() label = '';
  @Input() size = CheckboxSize.SMALL;
  @Input() color?: CheckboxColor = CheckboxColor.SECONDARY;
  @Input() fontColor?: FontColor = FontColor.INHERIT;
  /** id for the checkbox element */
  @Input() id = `${this.generateRandomId()}--${this.stripSpaces(this.label)}`;
  /* needed for when there is no label on the checkbox */
  @Input() ariaLabel = '';

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.errorId = `errorId-${this.generateRandomId()}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('control' in changes) {
      if (this.statusChangeSubscription) {
        this.statusChangeSubscription.unsubscribe();
      }
      this.statusChangeSubscription = this.control.statusChanges.subscribe(
        () => {
          // we still need this for the CheckboxContainer directives
          this.cd.markForCheck();
        },
      );
    }
  }

  ngOnDestroy(): void {
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
  }

  private stripSpaces(key: string): string {
    return key.split(' ').join('');
  }

  private generateRandomId(): string {
    let text = '';
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    for (let i = 0; i < 15; i++) {
      text += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    }
    return text;
  }
}
