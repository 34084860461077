import type { TimeMilliseconds } from '@freelancer/datastore/core';
import { GroupNotificationContextType } from '@freelancer/datastore/core';
import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  TaskListApi,
  TaskSourceTypeApi,
  TaskStatusApi,
} from 'api-typings/tasklist/tasklist';
import type {
  NotificationEarningTaxCollected,
  NotificationGroupNotification,
  NotificationGroupReactNotification,
  NotificationGroupReplyNotification,
  NotificationProjectCommentCreate,
  NotificationTaskCommentCreate,
  NotificationTaskCreate,
  NotificationTaskUpdate,
  NotificationTaskGroupNewUser,
} from '../newsfeed';

export interface GenerateNotificationGroupNotificationOptions {
  readonly content?: string;
  readonly contentId?: number;
  readonly contentType: string;
  readonly creatorUserId?: number;
  readonly creatorUsername?: string;
  readonly groupId?: number;
  readonly groupName: string;
  readonly imgUrl?: string;
  readonly isMentionedUser?: boolean;
  readonly linkUrl: string;
  readonly time?: number;
  readonly projectTitle?: string;
}

export function generateNotificationGroupNotificationObject({
  content = 'content',
  contentId = generateId(),
  contentType,
  creatorUserId = generateId(),
  creatorUsername,
  groupId = generateId(),
  groupName,
  imgUrl,
  isMentionedUser = true,
  linkUrl,
  time = Date.now(),
  projectTitle = undefined,
}: GenerateNotificationGroupNotificationOptions): TimeMilliseconds &
  NotificationGroupNotification {
  return {
    type: 'groupNotification',
    parent_type: 'notifications',
    id: generateId().toString(),
    time,
    data: {
      content,
      contentId,
      contentType,
      creatorUserId,
      creatorUsername,
      groupId,
      groupName,
      imgUrl,
      isMentionedUser,
      linkUrl,
      projectTitle,
    },
  };
}

export interface GenerateNotificationProjectCommentCreateOptions {
  readonly commentCreatorId?: number;
  readonly commentCreatorUsername?: string;
  readonly commentId?: number;
  readonly contextId?: number;
  readonly imgUrl?: string;
  readonly isFromProjectOwner: boolean;
  readonly linkUrl: string;
  readonly projectTitle: string;
}

export function generateNotificationProjectCommentCreateObject({
  commentCreatorId,
  commentCreatorUsername,
  commentId = generateId(),
  contextId = generateId(),
  imgUrl,
  isFromProjectOwner,
  linkUrl,
  projectTitle,
}: GenerateNotificationProjectCommentCreateOptions): TimeMilliseconds &
  NotificationProjectCommentCreate {
  return {
    type: 'projectCommentCreate',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      commentCreatorId,
      commentCreatorUsername,
      commentId,
      contextId,
      imgUrl,
      isFromProjectOwner,
      linkUrl,
      projectTitle,
    },
  };
}

export interface GenerateNotificationTaskCommentCreateOptions {
  readonly commentCreatorId?: number;
  readonly commentCreatorUsername?: string;
  readonly commentId?: number;
  readonly contextId?: number;
  readonly imgUrl?: string;
  readonly linkUrl: string;
  readonly taskTitle: string;
}

export function generateNotificationTaskCommentCreateObject({
  commentCreatorId,
  commentCreatorUsername,
  commentId = generateId(),
  contextId = generateId(),
  imgUrl,
  linkUrl,
  taskTitle,
}: GenerateNotificationTaskCommentCreateOptions): TimeMilliseconds &
  NotificationTaskCommentCreate {
  return {
    type: 'taskCommentCreate',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      commentCreatorId,
      commentCreatorUsername,
      commentId,
      contextId,
      imgUrl,
      linkUrl,
      taskTitle,
    },
  };
}

export interface GenerateNotificationTaskCreateEditOptions {
  readonly isTaskCreate?: boolean;
  readonly imgUrl?: string;
  readonly isAssignee?: boolean;
  readonly linkUrl: string;
  readonly taskEditorId?: number;
  readonly taskEditorUsername?: string;
  readonly taskTitle?: string;
  readonly time?: number;
}

export function generateNotificationTaskCreateEditObject({
  isTaskCreate = true,
  imgUrl = '',
  isAssignee = true,
  linkUrl,
  taskEditorId = generateId(),
  taskEditorUsername,
  taskTitle = 'Test Task',
  time = Date.now(),
}: GenerateNotificationTaskCreateEditOptions):
  | (TimeMilliseconds & NotificationTaskCreate)
  | (TimeMilliseconds & NotificationTaskUpdate) {
  return {
    type: isTaskCreate ? 'tasklistCreateV1' : 'tasklistUpdateV1',
    parent_type: 'notifications',
    id: generateId().toString(),
    time,
    data: {
      id: generateId(),
      owner_id: generateId(),
      create_time: Date.now(),
      source: {
        id: generateId(),
        type: TaskSourceTypeApi.TASK_GROUP_SECTION,
      },
      status: TaskStatusApi.IN_PROGRESS,
      task_list: TaskListApi.DEFAULT_LIST,
      title: taskTitle,
      imgUrl,
      isAssignee,
      linkUrl,
      taskEditorId,
      taskEditorUsername,
    },
  };
}

export interface GenerateNotificationTaskGroupNewUserOptions {
  readonly imgUrl?: string;
  readonly linkUrl: string;
  readonly taskGroupEditorPublicName?: string;
  readonly taskGroupEditorUsername?: string;
  readonly taskGroupName?: string;
  readonly time?: number;
}

export function generateNotificationTaskGroupNewUserObject({
  imgUrl = '',
  linkUrl,
  taskGroupEditorPublicName = 'defaultName',
  taskGroupEditorUsername = 'defaultUsername',
  taskGroupName = 'Test Group',
  time = Date.now(),
}: GenerateNotificationTaskGroupNewUserOptions):
  | TimeMilliseconds & NotificationTaskGroupNewUser {
  return {
    type: 'tasklistNewUser',
    parent_type: 'notifications',
    id: generateId().toString(),
    time,
    data: {
      imgUrl,
      linkUrl,
      taskGroupEditorPublicName,
      taskGroupEditorUsername,
      taskGroupName,
    },
  };
}

export interface Commenter {
  readonly userId: number;
  readonly username: string;
  readonly imgUrl?: string;
}
export interface GenerateNotificationGroupReplyNotificationOptions {
  readonly parentType?: GroupNotificationContextType;
  readonly parentContent?: string;
  readonly parentId?: string;
  readonly groupId?: number;
  readonly groupName?: string;
  readonly linkUrl: string;
  readonly commenters: readonly Commenter[];
}

export function generateNotificationGroupReplyNotificationObject({
  parentType = GroupNotificationContextType.POST,
  parentContent = 'content',
  parentId = `post_${generateId()}`,
  groupId = generateId(),
  groupName = 'Test Group',
  linkUrl,
  commenters,
}: GenerateNotificationGroupReplyNotificationOptions): TimeMilliseconds &
  NotificationGroupReplyNotification {
  return {
    type: 'groupReplyNotification',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      parentType,
      parentContent,
      parentId,
      groupId,
      groupName,
      linkUrl,
      commenters,
    },
  };
}

export interface GenerateNotificationGroupReactNotificationOptions {
  readonly contextType?: GroupNotificationContextType;
  readonly contextId?: number;
  readonly contextContent?: string;
  readonly resourceId?: string;
  readonly groupId?: number;
  readonly groupName?: string;
  readonly linkUrl: string;
  readonly commenters: readonly Commenter[];
  readonly reactionsCount: number;
}

export function generateNotificationGroupReactNotificationObject({
  contextType = GroupNotificationContextType.POST,
  contextId = generateId(),
  contextContent = 'content',
  resourceId = `post_${generateId()}`,
  groupId = generateId(),
  groupName = 'Test Group',
  linkUrl,
  commenters,
  reactionsCount,
}: GenerateNotificationGroupReactNotificationOptions): TimeMilliseconds &
  NotificationGroupReactNotification {
  return {
    type: 'groupReactNotification',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      contextType,
      contextId,
      contextContent,
      resourceId,
      groupId,
      groupName,
      linkUrl,
      commenters,
      reactionsCount,
    },
  };
}

export interface GenerateNotificationEarningTaxCollectedOptions {
  readonly imgUrl?: string;
  readonly linkUrl?: string;
  readonly taxType?: string;
  readonly transactionId: number;
  readonly taxAmount: number;
  readonly currency: string;
}

export function generateNotificationEarningTaxCollectedObject({
  transactionId,
  imgUrl = '',
  linkUrl = '/user/settings/financials',
  taxType = 'UST24P',
  taxAmount,
  currency,
}: GenerateNotificationEarningTaxCollectedOptions): TimeMilliseconds &
  NotificationEarningTaxCollected {
  return {
    type: 'earningTaxCollected',
    parent_type: 'notifications',
    id: generateId().toString(),
    time: Date.now(),
    data: {
      transactionId,
      imgUrl,
      linkUrl,
      taxType,
      taxAmount,
      currency,
    },
  };
}
