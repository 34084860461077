import type { AccountProgress } from './account-progress.model';
import {
  AccountProgressKey,
  AccountProgressKeyPrefix,
} from './account-progress.model';

export type AccountProgressOptions = Readonly<Partial<AccountProgress>>;

export function generateAccountProgressObject({
  id = AccountProgressKey.PROFILE_COMPLETED,
  prefix = AccountProgressKeyPrefix.FREELANCER,
  verificationCode,
  currentProgress = 100,
  progressGain = 0,
  profileCompleted = true,
}: AccountProgressOptions = {}): AccountProgress {
  return {
    id,
    prefix,
    verificationCode,
    currentProgress,
    progressGain,
    profileCompleted,
  };
}

// ----- Mixins -----

export function accountProgressEmailVerify(): Required<
  Pick<AccountProgress, 'id' | 'verificationCode'>
> {
  return {
    id: AccountProgressKey.EMAIL_VERIFIED,
    verificationCode: 'verificationCode',
  };
}

export function accountProgressPaymentVerify(): Required<
  Pick<AccountProgress, 'id' | 'verificationCode'>
> {
  return {
    id: AccountProgressKey.PAYMENT_VERIFIED,
    verificationCode: 'verificationCode',
  };
}
