import { generateId } from '@freelancer/datastore/testing/helpers';
import { UserFollowStatusApi } from 'api-typings/users/users';
import type { UserFollow } from './users-follow.model';

export interface GenerateUsersFollowOptions {
  readonly followedUserId?: number;
  readonly followedByUserId?: number;
  readonly id?: number;
  readonly status?: UserFollowStatusApi;
}

export function generateUserFollowObject({
  followedUserId = generateId(),
  followedByUserId = generateId(),
  id = generateId(),
  status = UserFollowStatusApi.FOLLOWING,
}: GenerateUsersFollowOptions): UserFollow {
  return {
    followedUserId,
    followedByUserId,
    id,
    status,
  };
}

export function generateUserFollowObjects({
  followedByUserId,
  followedUserIds,
  status = UserFollowStatusApi.FOLLOWING,
}: {
  readonly followedByUserId?: number;
  readonly followedUserIds: readonly number[];
  readonly status?: UserFollowStatusApi;
}): readonly UserFollow[] {
  return followedUserIds.map(followedUserId =>
    generateUserFollowObject({ followedByUserId, followedUserId, status }),
  );
}
