import { generateId } from '@freelancer/datastore/testing/helpers';
import type { SlaTaskTimeTrackingTaskNameApi } from 'api-typings/sla/sla';
import {
  LinkedEntityTypeApi,
  SlaTaskTimeTrackingEventTypeApi,
} from 'api-typings/sla/sla';
import type {
  SlaLinkedEntity,
  SlaTaskTimeTrackingEvent,
  SlaTaskTimeTrackingTask,
} from './sla-tasks.model';

export interface GenerateSlaTaskOptions {
  readonly linkedEntity: SlaLinkedEntity;
  readonly name: SlaTaskTimeTrackingTaskNameApi;
  readonly events?: readonly SlaTaskTimeTrackingEvent[];
  readonly cumulativeTime?: number;
}

export function generateSlaTaskObject({
  linkedEntity,
  name,
  events,
  cumulativeTime = 6000,
}: GenerateSlaTaskOptions): SlaTaskTimeTrackingTask {
  const isRunning =
    events !== undefined &&
    events.length !== 0 &&
    events[events.length - 1].eventType ===
      SlaTaskTimeTrackingEventTypeApi.START;
  const slaTimeTrackingTask: SlaTaskTimeTrackingTask = {
    id: generateId(),
    name,
    cumulativeTime,
    isRunning,
    updatedAt: Date.now(),
    linkedEntity,
    events: events ?? [],
  };
  return slaTimeTrackingTask;
}

export function linkedToProject(
  projectId: number,
): Pick<GenerateSlaTaskOptions, 'linkedEntity'> {
  return { linkedEntity: { id: projectId, type: LinkedEntityTypeApi.PROJECT } };
}
