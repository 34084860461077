import { generateId } from '@freelancer/datastore/testing/helpers';
import { ServiceOfferingStatusApi } from 'api-typings/service_offerings/service_offerings';
import { v4 as uuid } from 'uuid';
import { ServiceOfferingShopCategoryServicePriceType } from '../service-offering-shop-category-services';
import type { ServiceOffering } from './service-offerings.model';

const SECONDS_IN_A_MONTH = 60 * 60 * 24 * 30;

interface GenerateServiceOfferingAttachmentOptions {
  readonly attachment_uuid?: string;
  readonly create_date?: number;
  readonly delete_date?: number;
  readonly downloadable_url?: string;
}

export interface GenerateServiceOfferingOptions {
  readonly id?: number;
  readonly createDate?: number;
  readonly creatorId?: number;
  readonly description?: string;
  readonly richDescription?: string;
  readonly jobIds?: readonly number[];
  readonly title?: string;
  readonly status?: ServiceOfferingStatusApi;
  readonly seoUrl?: string;
  readonly attachments?: readonly GenerateServiceOfferingAttachmentOptions[];
  readonly duration?: number;
  readonly price?: number;
  readonly priceType?: ServiceOfferingShopCategoryServicePriceType;
}

export function generateServiceOfferingObject({
  id = generateId(),
  creatorId = generateId(),
  createDate,
  title,
  description,
  jobIds,
  status = ServiceOfferingStatusApi.DRAFT,
  seoUrl,
  attachments,
  duration = SECONDS_IN_A_MONTH,
  price = 100,
  priceType = ServiceOfferingShopCategoryServicePriceType.HAS_FIXED_ITEMS,
}: GenerateServiceOfferingOptions): ServiceOffering {
  return {
    id,
    creatorId,
    createDate: createDate || Date.now(),
    jobIds,
    title: title || `Test Service Offering ${id}`,
    description: description || `Test Service Offering Description ${id}`,
    status,
    seoUrl:
      status === ServiceOfferingStatusApi.DRAFT
        ? undefined
        : seoUrl || `test-service-offering-${id}`,
    attachments: attachments?.map(attachment => ({
      id: generateId(),
      service_offering_id: id,
      attachment_uuid: attachment.attachment_uuid || uuid(),
      create_date: attachment.create_date || Date.now(),
      delete_date: attachment.delete_date,
      downloadable_url: attachment.downloadable_url,
    })),
    duration,
    price,
    priceType,
  };
}

export function activeServiceOffering(): Pick<
  GenerateServiceOfferingOptions,
  'createDate' | 'status'
> {
  return { createDate: Date.now(), status: ServiceOfferingStatusApi.ACTIVE };
}

export function hiddenServiceOffering(): Pick<
  GenerateServiceOfferingOptions,
  'createDate' | 'status'
> {
  return { createDate: Date.now(), status: ServiceOfferingStatusApi.HIDDEN };
}

export function draftServiceOffering(): Pick<
  GenerateServiceOfferingOptions,
  'createDate' | 'status'
> {
  return { createDate: Date.now(), status: ServiceOfferingStatusApi.DRAFT };
}
