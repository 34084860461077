import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NotFound } from '@freelancer/404';
import { HeadingType } from '@freelancer/ui/heading';
import { TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-not-found',
  template: `
    <app-nav></app-nav>
    <fl-bit class="MainContainer">
      <fl-card>
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'large'"
        >
          Error: Page not found
        </fl-heading>
      </fl-card>
    </fl-bit>
  `,
  styleUrls: [
    '../static-pages/static-pages.component.scss',
    './not-found.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  TextSize = TextSize;
  HeadingType = HeadingType;

  constructor(private notFound: NotFound) {}

  ngOnInit(): void {
    this.notFound._onRender();
  }
}
