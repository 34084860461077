import type { ReferralInvitationCheck } from './referral-invitation-check.model';

export interface GenerateReferralInvitationCheckOptions {
  readonly userId: number;
  readonly isEligible?: boolean;
}

export function generateReferralInvitationCheckObject({
  userId,
  isEligible = true,
}: GenerateReferralInvitationCheckOptions): ReferralInvitationCheck {
  return {
    id: userId.toString(),
    isEligible,
  };
}
