import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnchorScroll } from '@freelancer/ui';
import { IconColor } from '@freelancer/ui/icon';
import { LinkColor, LinkUnderline, LinkWeight } from '@freelancer/ui/link';
import type { PictureBackgroundImageStyles } from '@freelancer/ui/picture';
import { PictureBgImageObjectFit } from '@freelancer/ui/picture';
import { FontColor, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-brand-style-guide',
  template: `
    <fl-anchor-scroll
      [name]="'Top'"
      [offset]="0"
    ></fl-anchor-scroll>
    <div class="MainContainer">
      <div class="Hero">
        <fl-picture
          [src]="'brand-guidelines/hero.jpg'"
          [isBackgroundImage]="true"
          [backgroundStyles]="backgroundStyles"
        ></fl-picture>
        <div
          class="Nav"
          [flShowDesktop]="true"
        >
          <div class="NavContainer">
            <div class="BrandTitle">
              <fl-picture
                [src]="'brand-guidelines/logo-small.png'"
              ></fl-picture>

              <fl-text
                [color]="FontColor.LIGHT"
                [flMarginRight]="'small'"
                [size]="TextSize.XLARGE"
                [sizeDesktop]="TextSize.XXLARGE"
                [weight]="FontWeight.BOLD"
              >
                .brand
              </fl-text>
            </div>
            <fl-link
              [newTab]="false"
              [color]="LinkColor.LIGHT"
              [flMarginRight]="'small'"
              [size]="TextSize.SMALL"
              [underline]="LinkUnderline.NEVER"
              [weight]="LinkWeight.BOLD"
              (click)="scrollToBrandPrinciples()"
            >
              Brand principles
            </fl-link>
            <fl-link
              [newTab]="false"
              [isAbsoluteUrl]="true"
              [color]="LinkColor.LIGHT"
              [flMarginRight]="'small'"
              [underline]="LinkUnderline.NEVER"
              [size]="TextSize.SMALL"
              [weight]="LinkWeight.BOLD"
              (click)="scrollToLogo()"
            >
              Logo
            </fl-link>
            <fl-link
              [newTab]="false"
              [color]="LinkColor.LIGHT"
              [flMarginRight]="'small'"
              [size]="TextSize.SMALL"
              [underline]="LinkUnderline.NEVER"
              [weight]="LinkWeight.BOLD"
              (click)="scrollToColours()"
            >
              Colours
            </fl-link>
            <fl-link
              [newTab]="false"
              [color]="LinkColor.LIGHT"
              [flMarginRight]="'small'"
              [size]="TextSize.SMALL"
              [underline]="LinkUnderline.NEVER"
              [weight]="LinkWeight.BOLD"
              (click)="scrollToTypography()"
            >
              Typography
            </fl-link>
            <fl-link
              [newTab]="false"
              [color]="LinkColor.LIGHT"
              [flMarginRight]="'small'"
              [size]="TextSize.SMALL"
              [underline]="LinkUnderline.NEVER"
              [weight]="LinkWeight.BOLD"
              (click)="scrollToVoice()"
            >
              Voice
            </fl-link>
            <fl-link
              [newTab]="false"
              [color]="LinkColor.LIGHT"
              [flMarginRight]="'small'"
              [size]="TextSize.SMALL"
              [underline]="LinkUnderline.NEVER"
              [weight]="LinkWeight.BOLD"
              (click)="scrollToTagline()"
            >
              Tagline
            </fl-link>
            <fl-link
              [newTab]="false"
              [color]="LinkColor.LIGHT"
              [flMarginRight]="'small'"
              [size]="TextSize.SMALL"
              [underline]="LinkUnderline.NEVER"
              [weight]="LinkWeight.BOLD"
              (click)="scrollToImagery()"
            >
              Imagery
            </fl-link>
          </div>
          <div class="Top">
            <fl-icon
              [name]="'ui-chevron-up'"
              [clickable]="true"
              [color]="IconColor.LIGHT"
              (click)="scrollToTop()"
            ></fl-icon>
          </div>
        </div>
      </div>
      <fl-anchor-scroll
        [name]="'BrandPrinciples'"
        [offset]="148"
      ></fl-anchor-scroll>
      <app-brand-style-guide-principles> </app-brand-style-guide-principles>
      <fl-anchor-scroll
        [name]="'Logo'"
        [offset]="148"
      ></fl-anchor-scroll>
      <app-brand-style-guide-logo> </app-brand-style-guide-logo>

      <fl-anchor-scroll
        [name]="'Colours'"
        [offset]="148"
      ></fl-anchor-scroll>
      <app-brand-style-guide-colours> </app-brand-style-guide-colours>
      <fl-anchor-scroll
        [name]="'Typography'"
        [offset]="148"
      ></fl-anchor-scroll>
      <app-brand-style-guide-typography> </app-brand-style-guide-typography>

      <fl-anchor-scroll
        [name]="'Voice'"
        [offset]="148"
      ></fl-anchor-scroll>
      <app-brand-style-guide-voice> </app-brand-style-guide-voice>
      <fl-anchor-scroll
        [name]="'Tagline'"
        [offset]="148"
      ></fl-anchor-scroll>
      <app-brand-style-guide-tagline> </app-brand-style-guide-tagline>
      <fl-anchor-scroll
        [name]="'Imagery'"
        [offset]="148"
      ></fl-anchor-scroll>
      <app-brand-style-guide-imagery> </app-brand-style-guide-imagery>
    </div>
  `,
  styleUrls: ['./brand-style-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandStyleGuideComponent {
  FontColor = FontColor;
  FontWeight = FontWeight;
  IconColor = IconColor;
  LinkColor = LinkColor;
  LinkUnderline = LinkUnderline;
  LinkWeight = LinkWeight;
  TextSize = TextSize;

  readonly backgroundStyles: PictureBackgroundImageStyles = {
    position: 'center top',
    positionDesktop: 'center',
    objectFit: PictureBgImageObjectFit.COVER,
  };

  constructor(private anchorScroll: AnchorScroll) {}

  scrollToBrandPrinciples(): void {
    this.anchorScroll.scrollTo('BrandPrinciples');
  }
  scrollToLogo(): void {
    this.anchorScroll.scrollTo('Logo');
  }
  scrollToColours(): void {
    this.anchorScroll.scrollTo('Colours');
  }
  scrollToTypography(): void {
    this.anchorScroll.scrollTo('Typography');
  }
  scrollToVoice(): void {
    this.anchorScroll.scrollTo('Voice');
  }
  scrollToTagline(): void {
    this.anchorScroll.scrollTo('Tagline');
  }

  scrollToImagery(): void {
    this.anchorScroll.scrollTo('Imagery');
  }

  scrollToTop(): void {
    this.anchorScroll.scrollTo('Top');
  }
}
