import { generateId } from '@freelancer/datastore/testing/helpers';
import type { TaskSourceApi } from 'api-typings/tasklist/tasklist';
import {
  TaskSourceTypeApi,
  TaskStatusApi,
} from 'api-typings/tasklist/tasklist';
import type { Task } from './tasks.model';

export interface GenerateTaskObjectOptions {
  readonly ownerId?: number;
  readonly createTime?: number;
  readonly source: TaskSourceApi;
  readonly status?: TaskStatusApi;
  readonly assigneeId?: number;
  readonly startDate?: number;
  readonly dueDate?: number;
  readonly title?: string;
  readonly description?: string;
  readonly taskSectionId: number;
  readonly position?: number;
  readonly previousTaskId?: number;
  readonly nextTaskId?: number;
  readonly taskGroupId?: number;
}

export function generateTaskObject({
  source,
  taskSectionId,
  ownerId = generateId(),
  createTime = Date.now(),
  status = TaskStatusApi.IN_PROGRESS,
  assigneeId = generateId(),
  startDate = Date.now(),
  dueDate = Date.now() + 60 * 1000,
  title = 'Generated Task',
  description = 'Task Description',
  position = 0.0,
  previousTaskId = undefined,
  nextTaskId = undefined,
  taskGroupId = undefined,
}: GenerateTaskObjectOptions): Task {
  return {
    id: generateId(),
    ownerId,
    createTime,
    source,
    status,
    assigneeId,
    startDate,
    dueDate,
    title,
    description,
    taskSectionId,
    position,
    previousTaskId:
      previousTaskId ||
      (source.type === TaskSourceTypeApi.TASK_GROUP_SECTION
        ? source.id
        : undefined),
    nextTaskId,
    taskGroupId,
  };
}
