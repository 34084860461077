import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  ContextTypeApi,
  ThreadTypeApi,
} from 'api-typings/messages/messages_types';
import type { CommentFeed } from './comment-feeds.model';

export interface GenerateCommentFeedOptions {
  readonly contextId: number;
  readonly contextType?:
    | ContextTypeApi.POST
    | ContextTypeApi.PROJECT
    | ContextTypeApi.TASK;
}

export function generateCommentFeedObject({
  contextId,
  contextType = ContextTypeApi.POST,
}: GenerateCommentFeedOptions): CommentFeed {
  const now = Date.now();
  return {
    id: generateId(),
    contextType,
    contextId,
    members: [],
    owner: 0,
    threadType: ThreadTypeApi.COMMENT_FEED,
    timeCreated: now,
    timeUpdated: now,
    timeRead: now,
  };
}
