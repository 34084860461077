import { generateId } from '@freelancer/datastore/testing/helpers';
import { UserVerificationProcessStatusApi } from 'api-typings/users/users';
import type { UserVerificationProcess } from './user-verification-processes.model';

export interface GenerateUserVerificationProcessesOptions {
  readonly id?: number;
  readonly verificationProcessTypeId?: number;
  readonly ownerId?: number;
  readonly status?: UserVerificationProcessStatusApi;
  readonly dateCreated?: number;
}

export function generateUserVerificationProcesses({
  id = generateId(),
  verificationProcessTypeId = generateId(),
  ownerId = generateId(),
  status = UserVerificationProcessStatusApi.ACTIVE,
  dateCreated = Date.now(),
}: GenerateUserVerificationProcessesOptions = {}): UserVerificationProcess {
  return {
    id,
    verificationProcessTypeId,
    ownerId,
    status,
    dateCreated,
  };
}
