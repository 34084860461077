import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-code-block',
  template: `
    <pre
      class="Wrapper"
    ><code class="CodeBlock" [highlight]="formattedCode" [languages]="[language]"></code></pre>
  `,
  styleUrls: ['./code-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeBlockComponent {
  @Input() set code(code: string) {
    this.formattedCode = code.trim();
  }

  @Input() language: string;

  formattedCode: string;
}
