import type { IconColor, IconSize } from '@freelancer/ui/icon';

export type MoreOptionsIconColor =
  | IconColor.LIGHT
  | IconColor.FOREGROUND
  | IconColor.INHERIT;

export type MoreOptionsIconSize = IconSize.MID | IconSize.SMALL;

export enum MoreOptionsIconType {
  DEFAULT = 'default',
  COG = 'cog',
  MORE_VERT = 'more-vert',
}

export enum MoreOptionsSize {
  XXXSMALL = 'xxxsmall',
  XXSMALL = 'xxsmall',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MID = 'mid',
}
