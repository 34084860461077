import type { LoadshiftSiteStats } from './loadshift-site-stats.model';

export interface GenerateLoadshiftSiteStatsOptions {
  readonly empty?: boolean;
  readonly props?: Partial<LoadshiftSiteStats>;
}

export function generateLoadshiftSiteStatsObject({
  empty = false,
  props = {},
}: GenerateLoadshiftSiteStatsOptions): LoadshiftSiteStats {
  if (empty) {
    return {
      id: '1',
      totalFreightKmInLast24Hrs: 0,
      totalActiveJobsValue: 0,
      totalJobsPostedCount: 0,
      totalShippersCount: 0,
      totalCarriersCount: 0,
    };
  }
  return {
    id: props.id !== undefined ? props.id : '1',
    totalFreightKmInLast24Hrs:
      props.totalFreightKmInLast24Hrs !== undefined
        ? props.totalFreightKmInLast24Hrs
        : 12_345,
    totalActiveJobsValue:
      props.totalActiveJobsValue !== undefined
        ? props.totalActiveJobsValue
        : 7_233_345,
    totalJobsPostedCount:
      props.totalJobsPostedCount !== undefined
        ? props.totalJobsPostedCount
        : 400_000,
    totalShippersCount:
      props.totalShippersCount !== undefined
        ? props.totalShippersCount
        : 500_432,
    totalCarriersCount:
      props.totalCarriersCount !== undefined
        ? props.totalCarriersCount
        : 300_231,
  };
}
