import { generateId } from '@freelancer/datastore/testing/helpers';
import type { FlareRule } from './flare-rules.model';

export interface GenerateFlareRule {
  readonly id?: number;
  readonly flareRulesetId: number;
  readonly ruleType: string;
  readonly ruleValue: string;
  readonly ruleComparison: string;
  readonly expectedValueType: string;
  readonly priority: number;
  readonly reason: string;
}

/**
 * Create a Freelancer Automation Rule Engine (FLARE) rule object.
 */
export function generateFlareRuleObject({
  id = generateId(),
  flareRulesetId,
  ruleType,
  ruleValue,
  ruleComparison,
  expectedValueType,
  priority,
  reason,
}: GenerateFlareRule): FlareRule {
  return {
    id,
    flareRulesetId,
    ruleType,
    ruleValue,
    ruleComparison,
    expectedValueType,
    priority,
    reason,
  };
}
