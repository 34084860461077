import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import type {
  BaseServerData,
  BaseServerMessage,
  ConnectionStatusDetail,
  ObservableWebSocket,
  WebSocketResponse,
  WebSocketService,
  WebsocketChannel,
  WebsocketMessage,
  WebsocketServerEvent,
  WebsocketServerEventTParam,
} from '@freelancer/datastore/core';
import type { Interface } from '@freelancer/types';
import type { BehaviorSubject, Observable } from 'rxjs';
import { ReplaySubject, Subject, of } from 'rxjs';
import { generateId } from './helpers';

interface GenerateWebsocketMessageOptions {
  readonly type: string;
  readonly parent_type: string;
  readonly timestamp: number;
}

@Injectable()
export class WebSocketServiceFake
  implements OnDestroy, Interface<WebSocketService>
{
  connectRetryCount: number;
  currentReconnectionSource: 'disconnect' | 'appActive' | 'unsubscription';
  reconnectedSource: 'disconnect' | 'appActive' | 'unsubscription';
  reconnectFromDisconnectCount: number;

  private fromServerStreamSubject$ = new Subject<
    WebsocketServerEvent<WebsocketServerEventTParam>
  >();
  fromServerStream$ = this.fromServerStreamSubject$.asObservable();

  private reconnectedSubject$ = new Subject<void>();
  reconnected$ = this.reconnectedSubject$.asObservable();

  private websocketConnectStatusForUserSubject$ =
    new ReplaySubject<ConnectionStatusDetail>(1);

  readonly TESTING_WEBSOCKET_CONNECTION_USER_IDS = [];

  setChannels(channels: readonly WebsocketChannel[]): void {
    // not implemented
  }

  /**
   * Send a websocket message.
   */
  sendMessage(
    event: BaseServerData<BaseServerMessage>,
    options: GenerateWebsocketMessageOptions,
  ): void {
    const {
      type = 'private',
      parent_type = 'messages',
      timestamp = Math.floor(Date.now() / 1000),
    } = options;
    const id = generateId().toString();

    const message: WebsocketMessage<BaseServerMessage> = {
      channel: 'user',
      timestamp, // seconds
      id,
      type,
      parent_type,
      body: {
        ...event,
        aggregated: null,
        display: true,
        id,
        is_important: true,
        is_notification: false,
        is_project: false,
        message: 'mock',
        news_feed: false,
        no_persist: true,
        popup: true,
        targets: [],
        timestamp,
      },
    };

    this.fromServerStreamSubject$.next(message);
  }

  emitReconnectedSignal(): void {
    this.reconnectedSubject$.next(undefined);
  }

  getConnectStatusForUser(): Observable<ConnectionStatusDetail> {
    return this.websocketConnectStatusForUserSubject$;
  }

  hasReconnected(connectionStatus$: Observable<number>): Observable<void> {
    return of(undefined);
  }

  subscribeToIds(
    collectionName: string,
    ids$: Observable<readonly string[]>,
  ): Observable<unknown> {
    return of(undefined);
  }

  ngOnDestroy(): void {
    // Empty
  }

  processServerResponse(
    event: WebsocketServerEvent<WebsocketServerEventTParam>,
    userId: string,
  ): void {
    throw new Error('Method not implemented.');
  }
  enqueue(message: unknown): void {
    throw new Error('Method not implemented.');
  }
  enqueueResourceSub(message: unknown): void {
    throw new Error('Method not implemented.');
  }
  enqueueResourceUnsub(message: unknown): void {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line rxjs/no-exposed-subjects
  get requestReconnectionSubject$(): BehaviorSubject<void> {
    throw new Error('Method not implemented.');
  }
  get serverResponse$(): Observable<WebSocketResponse> {
    throw new Error('Method not implemented.');
  }

  get websocket$(): ObservableWebSocket {
    throw new Error('Method not implemented.');
  }

  get onlineEvent$(): Observable<Event> {
    throw new Error('Method not implemented.');
  }

  get retryDelay(): number {
    throw new Error('Method not implemented.');
  }

  notifyUser(retryDelay: number): void {
    throw new Error('Method not implemented.');
  }

  processServerMessage(
    event: WebsocketMessage<BaseServerMessage>,
    toUserId: string,
  ): void {
    throw new Error('Method not implemented.');
  }
}
