import type { TaskGroupSectionStatusApi } from 'api-typings/tasklist/tasklist';

/**
 * A task group section in a task group.
 */
export interface TaskGroupSection {
  readonly id: number;
  readonly taskGroupId: number;
  readonly name: string;
  readonly status: TaskGroupSectionStatusApi;
  readonly createdTime: number;
  readonly isArchiveSection: boolean;
}

export enum TaskSectionPreset {
  DEFAULT = 'default',
  HP = 'hp',
}
