import { inject } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { ENVIRONMENT_NAME } from '@freelancer/config';
import { Location } from '@freelancer/location';

export const BitsGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): ReturnType<CanActivateFn> => {
  const environment = inject(ENVIRONMENT_NAME);
  const location = inject(Location);

  if (environment === 'prod') {
    return location.createUrlTree(['/']);
  }

  return true;
};
