import type { AnimationEvent } from '@angular/animations';
import { transition, trigger, useAnimation } from '@angular/animations';
import type {
  ConnectedPosition,
  ConnectionPositionPair,
  GlobalPositionStrategy,
  OverlayRef,
  PositionStrategy,
} from '@angular/cdk/overlay';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  ScrollDispatcher,
} from '@angular/cdk/overlay';
import { CdkPortal, Portal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { slideInHorizontal, slideOutHorizontal } from '@freelancer/animations';
import { RepetitiveSubscription } from '@freelancer/decorators';
import { TimeUtils } from '@freelancer/time-utils';
import { FreelancerBreakpointValues } from '@freelancer/ui/breakpoints';
import { assertNever, toNumber } from '@freelancer/utils';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TESTING_CLOSE_ANIMATION_DISABLED } from '../ui.config';
import { CalloutColor } from './callout-color';
import {
  bottomCenter,
  bottomLeft,
  bottomRight,
  leftBottom,
  leftCenter,
  leftTop,
  rightBottom,
  rightCenter,
  rightTop,
  topCenter,
  topLeft,
  topRight,
} from './callout-connected-positions';
import { CalloutContentComponent } from './callout-content.component';
import { CalloutPlacement } from './callout-placement';
import { CalloutSize } from './callout-size';
import { CalloutTriggerComponent } from './callout-trigger.component';
import { CalloutService } from './callout.service';
import type {
  CalloutComponentInterface,
  CalloutPosition,
  CalloutTriggerOpenInterface,
} from './callout.types';

@Component({
  selector: 'fl-callout',
  template: `
    <ng-content select="fl-callout-trigger"></ng-content>
    <ng-template cdk-portal>
      <div
        class="ContentContainer"
        [flHideMobile]="hideMobile"
        @paneAnimation
        (@paneAnimation.done)="animationDone($event)"
        [@.disabled]="!isFullscreenMode"
        [attr.data-mobile-fullscreen]="mobileFullscreen"
        [attr.data-placement]="placement"
      >
        <ng-container *ngIf="!crawlable || calloutIsOpen">
          <ng-container *ngTemplateOutlet="calloutContent"></ng-container>
        </ng-container>
      </div>
    </ng-template>
    <div
      *ngIf="crawlable && !calloutIsOpen"
      [hidden]="true"
    >
      <ng-container *ngTemplateOutlet="calloutContent"></ng-container>
    </div>

    <ng-template #calloutContent>
      <ng-content select="fl-callout-content"></ng-content>
    </ng-template>
  `,
  styleUrls: ['./callout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('paneAnimation', [
      transition(':enter', [useAnimation(slideInHorizontal)]),
      transition(':leave', [useAnimation(slideOutHorizontal)]),
    ]),
  ],
})
export class CalloutComponent
  implements CalloutComponentInterface, OnInit, OnChanges, OnDestroy
{
  @Input() color: CalloutColor = CalloutColor.LIGHT;
  @Input() edgeToEdge = false;
  @Input() hideArrow = false;
  @Input() hideCloseButton = false;
  @Input() hover = false;
  @Input() placement = CalloutPlacement.BOTTOM;
  @Input() size?: CalloutSize;
  @Input() mobileCloseButton = true;
  @Input() delay = 0;

  /* Allows use overflow visible if is need it */
  @Input() overflowContent = false;

  /**
   * Will embed the callout content in the DOM (but hidden), when the callout is close.
   * Use if you want the content to crawlable for SEO purposes.
   */
  @Input() crawlable = false;

  /**
   * Show a backdrop, only applicable in tablet view and bigger.
   * Backdrop is always displayed in mobile view unless the callout is hidden for mobile.
   */
  @Input() backdrop = false;

  /** Use when content is scrollable for a better mobile experience. */
  @Input() mobileFullscreen = false;

  /**
   * Use when callout is hoverable and clicking on the trigger performs an action.
   * This allow mobile devices to trigger callout after pressing for 500ms.
   * It is important to note that callout will not be triggered on desktop with
   * mobile viewport as opening the dialog will break the flow of the trigger's
   * original action.
   */
  @Input() mobileLongPress = false;

  /**
   * Forces callout to open on tap/click. Useful when callout
   * trigger is a clickable element but is disabled. Use `mobileLongPress`
   * whenever possible.
   */
  @Input() mobileForceClick = false;

  /**
   * View header's title that shows up for mobileFullscreen
   */
  @Input() mobileHeaderTitle: string;

  @Input() mobileDropdown = false;

  @Input() hideMobile = false;
  /**
   * Used to set the offset of the callout from the trigger.
   * This is useful when the trigger is inline with other elements.
   */
  @Input() calloutOffsetInPixel = 4;

  @Output() calloutClose = new EventEmitter<void>();
  @Output() calloutOpen = new EventEmitter<void>();

  @ContentChild(CalloutTriggerComponent, { static: true })
  triggerComponent: CalloutTriggerComponent;
  @ContentChild(CalloutContentComponent, { static: true })
  contentComponent: CalloutContentComponent;
  @ViewChild(CdkPortal, { static: true }) portal: Portal<any>;

  private triggerComponentTrigger?: Subscription;
  private triggerComponentOpen?: Subscription;
  private triggerComponentClose?: Subscription;
  @RepetitiveSubscription()
  private contentComponentClose?: Subscription;
  @RepetitiveSubscription()
  private contentComponentBodyClick?: Subscription;
  @RepetitiveSubscription()
  private contentComponentBodyMouseenter?: Subscription;
  @RepetitiveSubscription()
  private contentComponentBodyMouseleave?: Subscription;
  private routeChangeSubscription?: Subscription;
  @RepetitiveSubscription()
  private positionChangeSubscription?: Subscription;

  private calloutNeedsToBeClosed = true;
  private documentClickEvent: () => void;
  private overlayRef: OverlayRef;

  private currentPlacement: CalloutPosition;
  private canRemoveOverflow = false;

  /**
   * Whether the callout has been opened by clicking.
   * Hover callouts can be forced to stay open by
   * clicking on the trigger
   */
  private isOpenedOnClick = false;
  isFullscreenMode = false;
  calloutIsOpen = false;

  constructor(
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private overlay: Overlay,
    private scrollDispatcher: ScrollDispatcher,
    private timeUtils: TimeUtils,
    private router: Router,
    private calloutService: CalloutService,
    @Inject(DOCUMENT) private document: Document,
    /** This should only be injected in UI tests */
    @Optional()
    @Inject(TESTING_CLOSE_ANIMATION_DISABLED)
    public readonly testingCloseAnimationDisabled?: boolean,
  ) {}

  // TODO: T267853 - The way we handle states of when a callout needs to be
  // closed or opened during a hover transition from the trigger
  // to the callout content is pretty all over the place. When
  // somebody has time (and clarity to think) redo the logic again.
  // Ref: T37940.

  ngOnInit(): void {
    if (this.hideMobile) {
      this.backdrop = false;
    }

    this.triggerComponent.setOptions({
      hover: this.hover,
      mobileForceClick: this.mobileForceClick,
      mobileLongPress: this.mobileLongPress,
    });

    this.documentClickEvent = this.renderer.listen('document', 'click', () => {
      if (this.calloutNeedsToBeClosed) {
        this.closeCallout();
      }

      this.calloutNeedsToBeClosed = true;
    });

    this.triggerComponentTrigger = this.triggerComponent.trigger.subscribe(
      () => {
        this.toggleCallout();
      },
    );

    if (this.hover) {
      this.triggerComponentOpen = this.triggerComponent.open.subscribe(
        (event: CalloutTriggerOpenInterface) => {
          // Don't re-run if it's already pinned open
          if (this.calloutIsOpen && this.isOpenedOnClick) {
            return;
          }

          this.calloutNeedsToBeClosed = false;
          this.isOpenedOnClick = !!event?.isPinnedOnClick;
          this.delayedOpenCallout();
        },
      );

      this.triggerComponentClose = this.triggerComponent.close.subscribe(() => {
        if (!this.isOpenedOnClick) {
          this.calloutNeedsToBeClosed = true;
          this.checkIfCalloutNeedsClosing();
        }
      });
    }

    this.routeChangeSubscription = this.router.events
      .pipe(
        filter(event => {
          // only close on page changes, and replacing the URL usually is not a full navigation
          const isReplaceUrl =
            this.router.getCurrentNavigation()?.extras?.replaceUrl ?? false;
          return event instanceof NavigationStart && !isReplaceUrl;
        }),
      )
      .subscribe(() => {
        this.close();
      });

    // default placement is center
    this.currentPlacement =
      this.placement === 'top' || this.placement === 'bottom'
        ? {
            overlayX: 'center',
            // flip it because the `currentPlacement` is for the overlay
            // ie. if the callout is on top of the trigger,
            // the overlay connection is at the bottom of the overlay
            overlayY: this.placement === 'top' ? 'bottom' : 'top',
            anchor: 'overlayY',
          }
        : {
            overlayX: this.placement === 'start' ? 'end' : 'start',
            overlayY: 'center',
            anchor: 'overlayX',
          };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (('hideMobile' in changes || 'backdrop' in changes) && this.hideMobile) {
      this.backdrop = false;
    }
  }

  animationDone(event: AnimationEvent): void {
    if (event.toState === 'void') {
      this.overlayRef.dispose();
    }
  }

  open(): void {
    if (!this.checkIfTriggerIsInView()) {
      this.triggerComponent.containerRef.element.nativeElement.scrollIntoView();
    }
    this.delayedOpenCallout();
  }

  close(): void {
    this.closeCallout();
  }

  toggle(): void {
    this.toggleCallout();
  }

  /**
   * Use this only if you move the callout trigger around
   */
  updatePosition(): void {
    this.overlayRef.updatePosition();
  }

  private checkIfTriggerIsInView(): boolean {
    const triggerBoundingRect = this.triggerComponent.getElementBoundingRect();
    return (
      triggerBoundingRect.top >= 0 &&
      triggerBoundingRect.left >= 0 &&
      triggerBoundingRect.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      triggerBoundingRect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  private toggleCallout(): void {
    if (this.calloutIsOpen) {
      this.closeCallout();
    } else {
      this.delayedOpenCallout();
    }
  }

  private openCallout(): void {
    if (this.hover) {
      this.calloutService.activateHover(this);
    }

    this.isFullscreenMode = this.isMobileView() && this.mobileFullscreen;

    this.calloutIsOpen = true;
    this.overlayRef = this.createOverlay();
    this.overlayRef.attach(this.portal);

    this.contentComponent.setOptions({
      color: this.color,
      edgeToEdge: this.edgeToEdge,
      hideArrow: this.hideArrow,
      hideCloseButton: this.hideCloseButton,
      hover: this.hover,
      overflowContent: this.overflowContent,
      mobileFullscreen: this.mobileFullscreen,
      mobileHeaderTitle: this.mobileHeaderTitle,
      size: this.size,
      mobileCloseButton: this.mobileCloseButton,
    });
    this.contentComponent.setPlacement(
      this.currentPlacement,
      this.triggerComponent.containerRef.element.nativeElement,
    );

    this.setCalloutZIndex();

    this.calloutNeedsToBeClosed = false;
    this.contentComponentClose = this.contentComponent.close.subscribe(() =>
      this.closeCallout(),
    );
    this.contentComponentBodyClick = this.contentComponent.bodyClick.subscribe(
      () => {
        this.calloutNeedsToBeClosed = false;
      },
    );

    if (this.hover) {
      this.contentComponentBodyMouseenter =
        this.contentComponent.bodyMouseenter.subscribe(() => {
          if (!this.isOpenedOnClick) {
            this.calloutNeedsToBeClosed = false;
          }
        });
      this.contentComponentBodyMouseleave =
        this.contentComponent.bodyMouseleave.subscribe(() => {
          if (!this.isOpenedOnClick) {
            this.calloutNeedsToBeClosed = true;
            this.checkIfCalloutNeedsClosing();
          }
        });
    }

    this.togglePageScrollState(this.calloutIsOpen);
    this.calloutOpen.emit();
  }

  private delayedOpenCallout(): void {
    if (this.calloutIsOpen) {
      return;
    }

    if (!this.delay) {
      this.openCallout();
    } else {
      this.timeUtils.setTimeout(() => this.openCallout(), this.delay);
    }
  }

  private checkIfCalloutNeedsClosing(): void {
    this.timeUtils.setTimeout(() => {
      if (this.calloutNeedsToBeClosed) {
        this.closeCallout();
      }
    }, 100);
  }

  private closeCallout(): void {
    if (this.calloutIsOpen) {
      this.detachOverlay();
      // don't dispose yet when fullscreen, to give animation time to play
      if (!this.isFullscreenMode || this.testingCloseAnimationDisabled) {
        // Also detach immediately if the animation flag is provided as the `animationDone`
        // callback timing is later than expected in in consecutive UI tests.
        this.overlayRef.dispose();
      }

      this.calloutIsOpen = false;
      this.calloutNeedsToBeClosed = true;
      if (this.contentComponentClose) {
        this.contentComponentClose.unsubscribe();
      }
      if (this.contentComponentBodyClick) {
        this.contentComponentBodyClick.unsubscribe();
      }

      if (this.hover) {
        if (this.contentComponentBodyMouseenter) {
          this.contentComponentBodyMouseenter.unsubscribe();
        }
        if (this.contentComponentBodyMouseleave) {
          this.contentComponentBodyMouseleave.unsubscribe();
        }
      }

      this.togglePageScrollState(this.calloutIsOpen);
      this.calloutClose.emit();
    }
  }

  private detachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      return;
    }

    // clean up zIndex hacking on the global callout parent
    // the `hostElement` itself is destroyed after detaching
    // so we don't need to worry about it
    if (this.overlayRef.hostElement.parentElement) {
      this.overlayRef.hostElement.parentElement.style.zIndex = '9001';
    }
    this.overlayRef.detach();

    if (this.positionChangeSubscription) {
      this.positionChangeSubscription.unsubscribe();
    }
  }

  /**
   * Calculates the effective z-index of the trigger component,
   * then sets the z-index of the content to be just above.
   */
  private setCalloutZIndex(): void {
    // don't do any z-index trickery for mobile dialogs or if backdrop is set
    // as both dialogs and fullscreen callouts should always be on top
    if (this.isMobileDialog() || this.backdrop) {
      return;
    }

    // loop through the parent elements until we find one with a defined z-index
    // it's a bit gross but there's no easy one-call function to get applied z-index
    let currElement: HTMLElement =
      this.triggerComponent.containerRef.element.nativeElement;
    let currZIndex: string | null = window.getComputedStyle(currElement).zIndex;
    let effectiveZIndex = toNumber(currZIndex) || 0;

    while (currElement.parentElement) {
      currElement = currElement.parentElement;
      currZIndex = window.getComputedStyle(currElement).zIndex;
      effectiveZIndex = Math.max(effectiveZIndex, toNumber(currZIndex) || 0);
    }

    this.contentComponent.zIndex = effectiveZIndex + 11;

    // set the parent zIndexes as well to override the Material styles
    // which by default would cause it to sit above nav/messaging/etc.
    if (this.overlayRef.hostElement.parentElement) {
      this.overlayRef.hostElement.style.zIndex = `${effectiveZIndex + 11}`;
      this.overlayRef.hostElement.parentElement.style.zIndex = `${
        effectiveZIndex + 11
      }`;
      this.overlayRef.overlayElement.style.zIndex = `${effectiveZIndex + 11}`;
    }
  }

  /**
   * Prevents body from scrolling on mobile if
   * it becomes a dialog with overlay
   */
  togglePageScrollState(isCalloutOpen: boolean): void {
    const target = this.document.body;
    const currentOverflow = target.style.overflow;

    // We only want to remove overflow when callout component is the
    // one that set it. For instance, when callout is placed within a modal
    // overflow is already set and we don't want to remove it
    // once callout is closed
    if (isCalloutOpen && !currentOverflow) {
      this.canRemoveOverflow = true;
    }

    if (isCalloutOpen && this.isMobileDialog()) {
      this.renderer.setStyle(target, 'overflow', 'hidden');
    } else if (!isCalloutOpen && this.canRemoveOverflow) {
      this.renderer.removeStyle(target, 'overflow');
      this.canRemoveOverflow = false;
    }
  }

  private isMobileView(): boolean {
    return window.innerWidth < parseInt(FreelancerBreakpointValues.TABLET, 10);
  }

  /**
   * Whether this modal pops up on mobile as a dialog,
   * with a backdrop + not tethered to the element.
   *
   * Also includes fullscreen ones.
   */
  private isMobileDialog(): boolean {
    return this.isMobileView() && !this.hideMobile && !this.mobileDropdown;
  }

  ngOnDestroy(): void {
    this.closeCallout();
    if (this.overlayRef) {
      this.detachOverlay();
      this.overlayRef.dispose();
    }

    if (this.documentClickEvent) {
      this.documentClickEvent();
    }

    if (this.triggerComponentTrigger) {
      this.triggerComponentTrigger.unsubscribe();
    }

    if (this.hover) {
      if (this.triggerComponentOpen) {
        this.triggerComponentOpen.unsubscribe();
      }
      if (this.triggerComponentClose) {
        this.triggerComponentClose.unsubscribe();
      }
    }

    if (this.routeChangeSubscription) {
      this.routeChangeSubscription.unsubscribe();
    }

    if (this.positionChangeSubscription) {
      this.positionChangeSubscription.unsubscribe();
    }
  }

  /**
   * Returns the connection offset for the callout.
   * We add a little bit of an offset to put a gap between the arrow and trigger.
   *
   * TODO: remove - I'm only adding this now to mantain legacy UI and make sure
   * that the screenshot tests pass properly.
   *
   * FIXME: not setting the offset causes the close button to be mispositioned
   * Even a `translate(0px)` somehow fixes it, but passing 0 does not set that,
   * so we have to have some offset gap.
   */
  private getOffset(): { offsetX: number; offsetY: number } {
    const anchor = this.currentPlacement[this.currentPlacement.anchor];

    return {
      offsetX:
        anchor === 'start'
          ? this.calloutOffsetInPixel
          : anchor === 'end'
          ? -this.calloutOffsetInPixel
          : 0,
      offsetY:
        anchor === 'top'
          ? this.calloutOffsetInPixel
          : anchor === 'bottom'
          ? -this.calloutOffsetInPixel
          : 0,
    };
  }

  private getPositions(): ConnectedPosition[] {
    const offset = this.getOffset();

    const topPositions: ConnectedPosition[] = [
      { ...topCenter },
      { ...topLeft },
      { ...topRight },
      { ...bottomCenter },
      { ...bottomLeft },
      { ...bottomRight },
    ];

    const bottomPositions: ConnectedPosition[] = [
      { ...bottomCenter },
      { ...bottomLeft },
      { ...bottomRight },
      { ...topCenter },
      { ...topLeft },
      { ...topRight },
    ];

    const leftPositions: ConnectedPosition[] = [
      { ...leftCenter },
      { ...leftBottom },
      { ...leftTop },
    ];

    const rightPositions: ConnectedPosition[] = [
      { ...rightCenter },
      { ...rightBottom },
      { ...rightTop },
    ];

    const rightBottomPositions: ConnectedPosition[] = [
      { ...rightBottom },
      { ...rightTop },
    ];

    const rightTopPositions: ConnectedPosition[] = [
      { ...rightTop },
      { ...rightBottom },
    ];

    const bottomLeftPositions: ConnectedPosition[] = [
      { ...bottomLeft },
      { ...topLeft },
    ];

    const bottomRightPositions: ConnectedPosition[] = [
      { ...bottomRight },
      { ...topRight },
    ];

    switch (this.placement) {
      case CalloutPlacement.TOP:
        return [...topPositions, ...bottomPositions].map(pos => ({
          ...pos,
          offsetY: offset.offsetY,
        }));
      case CalloutPlacement.BOTTOM:
        return [...bottomPositions, ...topPositions].map(pos => ({
          ...pos,
          offsetY: offset.offsetY,
        }));
      case CalloutPlacement.LEFT:
        return [...leftPositions, ...rightPositions].map(pos => ({
          ...pos,
          offsetX: offset.offsetX,
        }));
      case CalloutPlacement.RIGHT:
        return [...rightPositions, ...leftPositions].map(pos => ({
          ...pos,
          offsetX: offset.offsetX,
        }));
      case CalloutPlacement.BOTTOM_LEFT:
        return [...bottomLeftPositions, ...topPositions].map(pos => ({
          ...pos,
          offsetY: offset.offsetY,
        }));
      case CalloutPlacement.BOTTOM_RIGHT:
        return [...bottomRightPositions, ...topPositions].map(pos => ({
          ...pos,
          offsetY: offset.offsetY,
        }));
      case CalloutPlacement.RIGHT_BOTTOM:
        return [...rightBottomPositions, ...leftPositions].map(pos => ({
          ...pos,
          offsetX: offset.offsetX,
        }));
      case CalloutPlacement.RIGHT_TOP:
        return [...rightTopPositions, ...leftPositions].map(pos => ({
          ...pos,
          offsetX: offset.offsetX,
        }));
      default:
        assertNever(this.placement);
    }
  }

  private updateCalloutArrowPosition(
    connectionPair: ConnectionPositionPair,
  ): void {
    const prevAnchorPosition = this.currentPlacement.anchor;
    this.currentPlacement = { ...connectionPair, anchor: prevAnchorPosition };
    this.contentComponent.setPlacement(
      this.currentPlacement,
      this.triggerComponent.containerRef.element.nativeElement,
    );
    this.changeDetectorRef.markForCheck();
  }

  getSize(): number | undefined {
    switch (this.size) {
      case CalloutSize.MEDIUM:
        return 400;
      case CalloutSize.SMALL:
        return 270;
      default:
        // default: undefined (determined by size of content)
        return undefined;
    }
  }

  private createOverlay(): OverlayRef {
    const positions = this.getPositions();
    this.currentPlacement = {
      ...positions[1],
      anchor: ['top', 'bottom', 'bottom-left', 'bottom-right'].includes(
        this.placement,
      )
        ? 'overlayY'
        : 'overlayX',
    };

    // Reposition callout when scrolled
    const scrollStrategy = this.overlay.scrollStrategies.reposition({
      scrollThrottle: 5,
    });

    let positionStrategy: PositionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.triggerComponent.containerRef.element)
      .withPositions(positions)
      // prevents callout from being pushed around when it doesn't fit on-screen
      // note: it will still take the best position from among the assigned `positions`.
      // if we let this be true, the callout can be pushed away from its trigger.
      .withPush(false)
      // prevents callout from being squished when it doesn't fit on-screen
      .withFlexibleDimensions(false)
      .withScrollableContainers(
        this.scrollDispatcher.getAncestorScrollContainers(
          this.triggerComponent.containerRef.element,
        ),
      );

    if (this.isMobileDialog()) {
      positionStrategy = this.overlay.position().global();
      if (this.mobileFullscreen) {
        positionStrategy = (positionStrategy as GlobalPositionStrategy)
          .left('0')
          .top('0');
      } else {
        positionStrategy = (positionStrategy as GlobalPositionStrategy)
          .centerHorizontally()
          .centerVertically();
      }
    }

    const width = this.isMobileView()
      ? this.mobileFullscreen
        ? // mobile and mobileFullscreen: fullscreen width
          '100%'
        : // mobile but not mobileFullscreen: dialog filling most of the screen
        this.mobileDropdown
        ? this.getSize()
        : '90%'
      : // non-mobile: set size if CalloutSize is set
        this.getSize();

    const height =
      this.isMobileView() && this.mobileFullscreen ? '100%' : undefined;

    const state = new OverlayConfig({
      hasBackdrop: this.isMobileDialog() || this.backdrop,
      height,
      width,
      positionStrategy,
      scrollStrategy,
    });

    if (positionStrategy instanceof FlexibleConnectedPositionStrategy) {
      this.positionChangeSubscription =
        positionStrategy.positionChanges.subscribe(change => {
          this.updateCalloutArrowPosition(change.connectionPair);
        });
    }

    return this.overlay.create(state);
  }
}
