import { generateId } from '@freelancer/datastore/testing/helpers';
import { generateSecurePhone } from '../security-phone-requests';
import type { ProjectDeliveryContactSensitiveDetails } from './delivery-contacts-sensitive-details.model';

export interface GenerateSensitiveDetailsObjectOptions {
  readonly id?: number;
  readonly pickupId?: number;
  readonly pickupFullname?: string;
  readonly pickupPhoneCode?: string;
  readonly pickupPhoneNumber?: string;
  readonly dropoffId?: number;
  readonly dropoffFullname?: string;
  readonly dropoffPhoneCode?: string;
  readonly dropoffPhoneNumber?: string;
}

export function generateSensitiveDetailsObject({
  id = generateId(),
  pickupId = generateId(),
  pickupFullname = 'Generated Pickup Contact',
  pickupPhoneCode = generateSecurePhone().countryCode,
  pickupPhoneNumber = '477777777',
  dropoffId = generateId(),
  dropoffFullname = 'Generated Dropoff Contact',
  dropoffPhoneCode = generateSecurePhone().countryCode,
  dropoffPhoneNumber = '488888888',
}: GenerateSensitiveDetailsObjectOptions): ProjectDeliveryContactSensitiveDetails {
  return {
    id,
    pickupDeliveryContactSensitiveDetails: {
      id: pickupId,
      fullname: pickupFullname,
      phoneCode: pickupPhoneCode,
      phoneNumber: pickupPhoneNumber,
    },
    dropoffDeliveryContactSensitiveDetails: {
      id: dropoffId,
      fullname: dropoffFullname,
      phoneCode: dropoffPhoneCode,
      phoneNumber: dropoffPhoneNumber,
    },
  };
}
