import { CountryCode } from '../countries';
import type { University } from './universities.model';

export interface GenerateUniversityOptions {
  readonly countryCodes?: readonly CountryCode[];
}

export function generateUniversityObjects({
  countryCodes = [CountryCode.US],
}: GenerateUniversityOptions = {}): readonly University[] {
  return countryCodes.flatMap(code => universities[code] ?? []);
}

// TODO: T267853 - Generate university objects for countries other than US
export const universities: {
  readonly [C in CountryCode]?: readonly University[];
} = {
  [CountryCode.US]: [
    {
      id: 11_173,
      name: 'Brown University',
      countryCode: 'US',
      stateCode: 'RI',
    },
    {
      id: 10_095,
      name: 'Harvard University',
      countryCode: 'US',
      stateCode: 'MA',
    },
    {
      id: 10_690,
      name: 'Cornell University',
      countryCode: 'US',
      stateCode: 'NY',
    },
    {
      id: 10_591,
      name: 'Princeton University',
      countryCode: 'US',
      stateCode: 'NJ',
    },
    {
      id: 10_549,
      name: 'Dartmouth College',
      countryCode: 'US',
      stateCode: 'NH',
    },
    {
      id: 10_677,
      name: 'Columbia College',
      countryCode: 'US',
      stateCode: 'NY',
    },
    {
      id: 11_148,
      name: 'University of Pennsylvania',
      countryCode: 'US',
      stateCode: 'PA',
    },
    {
      id: 9526,
      name: 'Yale University',
      countryCode: 'US',
      stateCode: 'CT',
    },
  ],
};
