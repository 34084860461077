import { generateId } from '@freelancer/datastore/testing/helpers';
import { UserVerificationProcessStatusApi } from 'api-typings/users/users';
import type { User } from '../users';
import type { VerificationProcessType } from '../verification-process-types';
import type { AdminUserVerificationProcess } from './admin-user-verification-processes.model';

export interface GenerateAdminUserVerificationProcessOptions {
  readonly user: User;
  readonly status?: UserVerificationProcessStatusApi;
  readonly dateCreated?: number;
  readonly verificationProcessType?: VerificationProcessType;
}

export function generateAdminUserVerificationProcessObject({
  user,
  status = UserVerificationProcessStatusApi.PENDING_REVIEW,
  dateCreated = Date.now(),
  verificationProcessType,
}: GenerateAdminUserVerificationProcessOptions): AdminUserVerificationProcess {
  return {
    id: generateId(),
    verificationProcessTypeId: generateId(),
    ownerId: user.id,
    status,
    dateCreated,
    owner: {
      id: user.id,
      displayName: user.displayName,
      username: user.username,
    },
    verificationProcessType,
  };
}
