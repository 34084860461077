import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-brand-style-guide-tagline',
  template: `
    <div class="Tagline">
      <fl-text
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Brand tagline
      </fl-text>
      <fl-text
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        Our tagline can be expressed in any font, colour or styling just like
        the MTV logo appeared in countless mediums, styles and iterations.
      </fl-text>
      <div [flMarginBottom]="'xlarge'">
        <fl-picture
          [fullWidth]="true"
          [src]="'brand-guidelines/make-it-real.png'"
        ></fl-picture>
      </div>
      <fl-grid>
        <fl-col
          [col]="12"
          [colTablet]="6"
          [flMarginBottom]="'xsmall'"
        >
          <fl-picture
            class="TaglineImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/tagline-1.jpg'"
          ></fl-picture>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="6"
          [flMarginBottom]="'xsmall'"
        >
          <fl-picture
            class="TaglineImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/tagline-2.jpg'"
          ></fl-picture>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="6"
          [flMarginBottom]="'xsmall'"
        >
          <fl-picture
            class="TaglineImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/tagline-3.jpg'"
          ></fl-picture>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="6"
          [flMarginBottom]="'xsmall'"
        >
          <fl-picture
            class="TaglineImg"
            [fullWidth]="true"
            [src]="'brand-guidelines/tagline-4.jpg'"
          ></fl-picture>
        </fl-col>
      </fl-grid>
    </div>
  `,
  styleUrls: ['./../brand-style-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandStyleGuideTaglineComponent {
  FontWeight = FontWeight;
  TextSize = TextSize;
}
