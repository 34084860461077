import type {
  SkillsSubNavigation,
  SkillsSubNavigationItem,
} from './skills-sub-navigation-model';

export function generateSkillsSubNavigationObject({
  id,
  links,
}: SkillsSubNavigation): SkillsSubNavigation {
  return {
    id,
    links,
  };
}

// Mixins

export function phpSkillSubNavigationItem(): SkillsSubNavigationItem {
  return {
    mainLink: {
      text: 'PHP',
      url: '/jobs/php',
    },
    links: [
      {
        text: 'Browse Top PHP Developers',
        url: '/freelancers/skills/php/',
      },
      { text: 'Hire a PHP Developer', url: '/hire/php' },
      { text: 'Browse PHP Jobs', url: '/jobs/php/' },
      {
        text: 'Post a PHP Project',
        url: '/post-project/?job_id=3',
      },
      {
        text: 'Learn more about PHP',
        url: '/community/articles/project-ashe',
      },
    ],
  };
}
