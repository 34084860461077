import { TimeUnitApi } from 'api-typings/common/common';
import { BenefitDuplicateRuleApi } from 'api-typings/memberships/memberships_types';
import type { Package } from '../recommended-membership/recommended-membership.model';

export interface GenerateMembershipPackagesObjectsOptions {
  readonly includeGrandfathered?: boolean;
  readonly includeTax?: boolean;
}

export function generateMembershipPackagesObjects({
  includeGrandfathered = false,
  includeTax = false,
}: GenerateMembershipPackagesObjectsOptions = {}): readonly Package[] {
  return [
    generateBasicMembershipPlan(includeTax),
    generatePlusMembershipPlan(includeTax),
    generateProfessionalMembershipPlan(includeTax),
    generatePremierMembershipPlan(includeTax),
    generateLoadshiftMembershipPlan(),
    ...(includeGrandfathered
      ? generateGrandfatheredMembershipPackageObjects()
      : []),
  ];
}

export function generateBasicMembershipPlan(includeTax = false): Package {
  return {
    id: 2,
    internalName: 'plan20',
    displayName: 'basic',
    enabled: true,
    categoryId: 1,
    order: 3000,
    benefits: [
      {
        id: 5,
        benefit: {
          id: 22,
          value: 6,
          displayName: 'Bids Per Month',
          internalName: 'bids_limit',
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 50,
        packageId: 2,
      },
    ],
    prices: [
      {
        id: 2,
        amount: 4.95,
        amountWithTax: includeTax ? 5.69 : undefined,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 2,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 2,
        timeValidStart: Date.now(),
      },
      {
        id: 3475,
        amount: 4.45,
        amountWithTax: includeTax ? 5.12 : undefined,
        contractQuantity: 12,
        currencyId: 1,
        durationId: 2,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 2,
        timeValidStart: Date.now(),
      },
      {
        id: 169,
        amount: 53.4,
        amountWithTax: includeTax ? 61.41 : undefined,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 8,
        durationType: TimeUnitApi.YEAR,
        durationCycle: 1,
        packageId: 2,
        timeValidStart: Date.now(),
      },
    ],
  };
}

export function generatePlusMembershipPlan(includeTax = false): Package {
  return {
    id: 5,
    internalName: 'plan25',
    displayName: 'plus',
    enabled: true,
    categoryId: 1,
    order: 4000,
    benefits: [
      {
        id: 91,
        benefit: {
          id: 22,
          value: 6,
          displayName: 'Bids Per Month',
          internalName: 'bids_limit',
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 100,
        packageId: 5,
      },
    ],
    prices: [
      {
        id: 3,
        amount: 9.95,
        amountWithTax: includeTax ? 11.44 : undefined,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 3,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 5,
        timeValidStart: Date.now(),
      },
      {
        id: 3498,
        amount: 8.95,
        amountWithTax: includeTax ? 10.29 : undefined,
        contractQuantity: 12,
        currencyId: 1,
        durationId: 3,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 5,
        timeValidStart: Date.now(),
      },
      {
        id: 190,
        amount: 107.4,
        amountWithTax: includeTax ? 123.51 : undefined,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 9,
        durationType: TimeUnitApi.YEAR,
        durationCycle: 1,
        packageId: 5,
        timeValidStart: Date.now(),
      },
    ],
  };
}

export function generateProfessionalMembershipPlan(
  includeTax = false,
): Package {
  return {
    id: 8,
    internalName: 'plan30_1',
    displayName: 'professional',
    enabled: true,
    categoryId: 1,
    order: 5001,
    benefits: [
      {
        id: 154,
        benefit: {
          id: 22,
          value: 6,
          displayName: 'Bids Per Month',
          internalName: 'bids_limit',
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 300,
        packageId: 8,
      },
    ],
    prices: [
      {
        id: 106,
        amount: 29.95,
        amountWithTax: includeTax ? 34.44 : undefined,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 6,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 8,
        timeValidStart: Date.now(),
      },
      {
        id: 3521,
        amount: 24.95,
        amountWithTax: includeTax ? 28.69 : undefined,
        contractQuantity: 12,
        currencyId: 1,
        durationId: 6,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 8,
        timeValidStart: Date.now(),
      },
      {
        id: 211,
        amount: 299.4,
        amountWithTax: includeTax ? 344.31 : undefined,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 11,
        durationType: TimeUnitApi.YEAR,
        durationCycle: 1,
        packageId: 8,
        timeValidStart: Date.now(),
      },
    ],
  };
}

export function generatePremierMembershipPlan(includeTax = false): Package {
  return {
    id: 9,
    internalName: 'plan50_1',
    displayName: 'premier',
    enabled: true,
    categoryId: 1,
    order: 6001,
    benefits: [
      {
        id: 958,
        benefit: {
          id: 22,
          value: 6,
          displayName: 'Bids Per Month',
          internalName: 'bids_limit',
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 700,
        packageId: 9,
      },
    ],
    prices: [
      {
        id: 5039,
        amount: 69.95,
        amountWithTax: includeTax ? 80.44 : undefined,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 7,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 9,
        timeValidStart: Date.now(),
      },
      {
        id: 5078,
        amount: 59.95,
        amountWithTax: includeTax ? 68.94 : undefined,
        contractQuantity: 12,
        currencyId: 1,
        durationId: 7,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 9,
        timeValidStart: Date.now(),
      },
    ],
  };
}

export function generateCorporateMembershipPlan(): Package {
  return {
    id: 22,
    internalName: 'corporate',
    displayName: 'corporate',
    enabled: true,
    categoryId: 3,
    order: 1000,
  };
}

export function generate15MemberTeamMembershipPlan(): Package {
  return {
    id: 69,
    internalName: 'team15',
    displayName: '15 member team',
    enabled: true,
    categoryId: 4,
    order: 1500,
  };
}

export function generateLoadshiftMembershipPlan({
  internalName = 'loadshift_v3', // LOADSHIFT_MEMBERSHIP_PACKAGE_NAME_V3
}: { readonly internalName?: string } = {}): Package {
  return {
    id: 117,
    internalName,
    displayName: 'Loadshift',
    enabled: true,
    categoryId: 7,
    order: 0,
    prices: [
      {
        id: 7716,
        amount: 62.73,
        amountWithTax: 69,
        contractQuantity: 1,
        currencyId: 3,
        durationId: 125,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 117,
        timeValidStart: Date.now(),
      },
    ],
  };
}

// SECTION: Grandfathered membership plan seeders.

/**
 * plan15 a.k.a. Intro
 */
export function generateIntroMembershipPlan(): Package {
  return {
    id: 7,
    internalName: 'plan15',
    displayName: 'intro',
    enabled: false,
    categoryId: 1,
    order: 2000,
    benefits: [
      {
        id: 119,
        benefit: {
          id: 22,
          value: 6,
          displayName: 'Bids Per Month',
          internalName: 'bids_limit',
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 15,
        packageId: 7,
      },
    ],
    prices: [
      {
        id: 1,
        amount: 0.99,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 1,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 7,
        timeValidStart: Date.now(),
      },
      {
        id: 3602,
        amount: 0.99,
        contractQuantity: 12,
        currencyId: 1,
        durationId: 1,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 7,
        timeValidStart: Date.now(),
      },
      {
        id: 148,
        amount: 11.88,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 10,
        durationType: TimeUnitApi.YEAR,
        durationCycle: 1,
        packageId: 7,
        timeValidStart: Date.now(),
      },
    ],
  };
}

/**
 * plan15_1 a.k.a. Starter
 */
export function generateStarterMembershipPlan(): Package {
  return {
    id: 17,
    internalName: 'plan15_1',
    displayName: 'starter',
    enabled: true,
    categoryId: 1,
    benefits: [
      {
        id: 212,
        benefit: {
          id: 22,
          internalName: 'bids_limit',
          displayName: 'Bids Per Month',
          value: 6.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 20.0,
        packageId: 17,
      },
    ],
    prices: [
      {
        id: 1144,
        amount: 17.88,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 25,
        durationType: TimeUnitApi.YEAR,
        durationCycle: 1,
        packageId: 17,
        timeValidStart: 1_473_401_414_000,
      },
    ],
    order: 2001,
  };
}

/**
 * plan20 a.k.a. Basic with Free Exam
 */
export function generateBasicWithExamMembershipPlan(): Package {
  return {
    id: 21,
    internalName: 'plan20_1',
    displayName: 'basic',
    enabled: true,
    categoryId: 1,
    benefits: [
      {
        id: 212,
        benefit: {
          id: 22,
          internalName: 'bids_limit',
          displayName: 'Bids Per Month',
          value: 50.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 50.0,
        packageId: 21,
      },
      {
        id: 340,
        benefit: {
          id: 36,
          internalName: 'free_exams',
          displayName: 'Free Exams',
          value: 0.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 1.0,
        packageId: 21,
      },
    ],
    prices: [
      {
        id: 947,
        amount: 4.95,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 34,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 21,
        timeValidStart: 1_468_988_145_000,
      },
      {
        id: 978,
        amount: 53.4,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 35,
        durationType: TimeUnitApi.YEAR,
        durationCycle: 1,
        packageId: 21,
        timeValidStart: 1_468_988_148_000,
      },
    ],
    order: 3001,
  };
}

/**
 * plan25_1 a.k.a. Plus with Free Exam
 */
export function generatePlusWithExamMembershipPlan(): Package {
  return {
    id: 18,
    internalName: 'plan25_1',
    displayName: 'plus',
    enabled: true,
    categoryId: 1,
    benefits: [
      {
        id: 212,
        benefit: {
          id: 22,
          internalName: 'bids_limit',
          displayName: 'Bids Per Month',
          value: 6.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 100.0,
        packageId: 18,
      },
      {
        id: 340,
        benefit: {
          id: 36,
          internalName: 'free_exams',
          displayName: 'Free Exams',
          value: 0.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 3.0,
        packageId: 18,
      },
    ],
    prices: [
      {
        id: 677,
        amount: 9.95,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 27,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 18,
        timeValidStart: 1_468_988_145_000,
      },
      {
        id: 708,
        amount: 107.4,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 28,
        durationType: TimeUnitApi.YEAR,
        durationCycle: 1,
        packageId: 18,
        timeValidStart: 1_468_988_148_000,
      },
    ],
    order: 4001,
  };
}

/**
 * plan30 a.k.a. Standard
 */
export function generateStandardMembershipPlan(): Package {
  return {
    id: 3,
    internalName: 'plan30',
    displayName: 'standard',
    enabled: false,
    categoryId: 1,
    benefits: [
      {
        id: 212,
        benefit: {
          id: 22,
          internalName: 'bids_limit',
          displayName: 'Bids Per Month',
          value: 6.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 20.0,
        packageId: 17,
      },
    ],
    prices: [
      {
        id: 4,
        amount: 49.95,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 4,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 3,
        timeValidStart: 1_456_969_801_000,
      },
    ],
    order: 5000,
  };
}

/**
 * plan30_2 a.k.a. Professional with Free Exam
 */
export function generateProfessionalWithFreeExamMembershipPlan(): Package {
  return {
    id: 19,
    internalName: 'plan30_2',
    displayName: 'professional',
    enabled: true,
    categoryId: 1,
    benefits: [
      {
        id: 212,
        benefit: {
          id: 22,
          internalName: 'bids_limit',
          displayName: 'Bids Per Month',
          value: 6.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 300.0,
        packageId: 19,
      },
      {
        id: 340,
        benefit: {
          id: 36,
          internalName: 'free_exams',
          displayName: 'Free Exams',
          value: 0.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 5.0,
        packageId: 19,
      },
    ],
    prices: [
      {
        id: 739,
        amount: 29.95,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 29,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 19,
        timeValidStart: 67_607_305_000,
      },
      {
        id: 770,
        amount: 299.4,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 30,
        durationType: TimeUnitApi.YEAR,
        durationCycle: 1,
        packageId: 19,
        timeValidStart: 1_467_607_308_000,
      },
    ],
    order: 5002,
  };
}

/**
 * plan50 a.k.a. Premium
 */
export function generatePremiumMembershipPlan(): Package {
  return {
    id: 4,
    internalName: 'plan50',
    displayName: 'premium',
    enabled: false,
    categoryId: 1,
    benefits: [
      {
        id: 212,
        benefit: {
          id: 22,
          internalName: 'bids_limit',
          displayName: 'Bids Per Month',
          value: 6.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 20.0,
        packageId: 4,
      },
    ],
    prices: [
      {
        id: 5,
        amount: 199.95,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 5,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 4,
        timeValidStart: 1_456_969_801_000,
      },
    ],
    order: 6000,
  };
}

/**
 * plan50_2 a.k.a. Premier with Free Exam
 */
export function generatePremierWithFreeExamMembershipPlan(): Package {
  return {
    id: 20,
    internalName: 'plan50_2',
    displayName: 'premier',
    enabled: true,
    categoryId: 1,
    benefits: [
      {
        id: 212,
        benefit: {
          id: 22,
          internalName: 'bids_limit',
          displayName: 'Bids Per Month',
          value: 6.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 1500.0,
        packageId: 20,
      },
      {
        id: 340,
        benefit: {
          id: 36,
          internalName: 'free_exams',
          displayName: 'Free Exams',
          value: 0.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 5.0,
        packageId: 20,
      },
    ],
    prices: [
      {
        id: 801,
        amount: 59.95,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 31,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 20,
        timeValidStart: 1_467_607_311_000,
      },
      {
        id: 832,
        amount: 599.4,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 32,
        durationType: TimeUnitApi.YEAR,
        durationCycle: 1,
        packageId: 20,
        timeValidStart: 1_467_607_314_000,
      },
    ],
    order: 6002,
  };
}

/**
 * plan50_3 a.k.a. Premier with 1500 Bids
 */
export function generatePremierWith1500BidsMembershipPlan(): Package {
  return {
    id: 23,
    internalName: 'plan50_3',
    /**
     * Change name to simplify UI test, since the non-grandfathered premier plan
     * is also displayed as 'Premier', see memberships-page.spec.ts for more info.
     */
    displayName: 'premier3',
    enabled: false,
    categoryId: 1,
    benefits: [
      {
        id: 212,
        benefit: {
          id: 22,
          internalName: 'bids_limit',
          displayName: 'Bids Per Month',
          value: 6.0,
          duplicateRule: BenefitDuplicateRuleApi.SUM,
        },
        benefitValue: 1500.0,
        packageId: 23,
      },
    ],
    prices: [
      {
        id: 1746,
        amount: 59.95,
        contractQuantity: 1,
        currencyId: 1,
        durationId: 41,
        durationType: TimeUnitApi.MONTH,
        durationCycle: 1,
        packageId: 23,
        timeValidStart: 1_490_154_848_000,
      },
    ],
    order: 6003,
  };
}

export function generateGrandfatheredMembershipPackageObjects(): readonly Package[] {
  return [
    generateIntroMembershipPlan(),
    generateStarterMembershipPlan(),
    generateBasicWithExamMembershipPlan(),
    generatePlusWithExamMembershipPlan(),
    generateStandardMembershipPlan(),
    generateProfessionalWithFreeExamMembershipPlan(),
    generatePremiumMembershipPlan(),
    generatePremierWithFreeExamMembershipPlan(),
    generatePremierWith1500BidsMembershipPlan(),
  ];
}
