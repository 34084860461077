export enum QueryParamsHandling {
  MERGE = 'merge',
  PRESERVE = 'preserve',
  NONE = '',
}

export interface Tab {
  title: string;
  selected?: boolean;
  iconName?: string;
  /** note: the router link MUST be absolute. */
  link?: string;
  /** if the route has children, we do a startWith match instead of an exact match */
  routeHasChildren?: boolean;
  /** when a tab is clicked and has a router link, how should it handle the query parameters
   *  from the current route in the new route
   */
  queryParamsHandling?: QueryParamsHandling;
  fragment?: string;
}

export enum TabsBorder {
  NONE = 'none',
  INSIDE = 'inside',
  ALL = 'all',
}

export enum TabsColor {
  FOREGROUND = 'foreground',
  DARK = 'dark',
  LIGHT = 'light',
  NEUTRAL = 'neutral',
  PINK = 'pink',
}

export enum TabsSize {
  XSMALL = 'xsmall',
  SMALL = 'small',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

export enum TabsDirection {
  ROW = 'row',
  COLUMN_LEFT = 'columnLeft',
  COLUMN_RIGHT = 'columnRight',
}
