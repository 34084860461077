import { generateId } from '@freelancer/datastore/testing/helpers';
import { TotpMethodsApi } from 'api-typings/auth/auth';
import type { UserMfaDetails, UsersMfa } from './users-mfa.model';

export type GenerateUsersMfaOptions = Partial<UsersMfa>;

/**
 * Generate MFA for a user where email is the only available method and MFA is disabled
 */
export function generateUsersMfaObject({
  id = generateId(),
  mfa = mfaEmailOnly({ mfaEnabled: false }),
}: GenerateUsersMfaOptions = {}): UsersMfa {
  return { id, mfa };
}

/**
 * Generate MFA for a user where they have successfully added their phone number.
 * The MFA is enabled if the preferred method is set, else it is disabled.
 */
export function mfaEmailAndPhoneDetails({
  preferredMethod = undefined,
}: {
  readonly preferredMethod?: TotpMethodsApi;
}): UserMfaDetails {
  return {
    ...generateUsersMfaDetails(
      [TotpMethodsApi.EMAIL, TotpMethodsApi.SMS],
      preferredMethod,
    ),
  };
}

/**
 * Generate MFA for a user with email as the only available method.
 */
export function mfaEmailOnly({
  mfaEnabled = false,
}: {
  readonly mfaEnabled: boolean;
}): UserMfaDetails {
  return {
    ...generateUsersMfaDetails(
      [TotpMethodsApi.EMAIL],
      mfaEnabled ? TotpMethodsApi.EMAIL : undefined,
    ),
  };
}

/**
 * Create a user MFA details based on the passed value.
 *
 * User's MFA is disabled if the preferred method is not set, else it is enabled with the preferred method.
 */
export function generateUsersMfaDetails(
  availableMethods: readonly TotpMethodsApi[],
  preferredMethod?: TotpMethodsApi,
): UserMfaDetails {
  return {
    preferredMethod,
    availableMethods,
  };
}
