import { APP_BASE_HREF, isPlatformBrowser } from '@angular/common';
import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Location } from '@freelancer/location';
import { Pwa } from '@freelancer/pwa';
import { FlexAlign, FlexDirection, FlexJustify } from '@freelancer/ui/flex';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { HighlightColor, TextSize, TextTransform } from '@freelancer/ui/text';
import { UserAgent } from '@freelancer/user-agent';
import { isLinkWhitelisted } from '../helpers/helpers';
import {
  LinkColor,
  LinkHoverColor,
  LinkIconPosition,
  LinkOutline,
  LinkType,
  LinkUnderline,
  LinkWeight,
  QueryParams,
  QueryParamsHandling,
} from './link.types';

@Component({
  selector: 'fl-link',
  template: `
    <a
      *ngIf="link && this.linkType === LinkType.INTERNAL && !isPassive"
      class="LinkElement"
      [attr.aria-label]="label"
      [attr.disabled]="disabled ? true : null"
      [routerLink]="link"
      [queryParams]="queryParams"
      [queryParamsHandling]="queryParamsHandling"
      [fragment]="fragment"
      [tabIndex]="tabIndex"
      [target]="attrTarget"
      [attr.itemprop]="itemprop"
      [attr.itemtype]="itemtype"
      [rel]="allRel"
      [attr.data-underline]="underline"
      [attr.data-text-transform]="textTransform"
      [attr.data-display]="display"
      [attr.data-icon]="iconName ? true : false"
      [attr.data-icon-position]="iconPosition"
      [attr.data-outline]="outline"
      [attr.data-flex-direction]="flexDirection"
      [attr.data-flex-align]="flexAlign"
      [attr.data-flex-justify]="flexJustify"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
    <a
      *ngIf="link && this.linkType === LinkType.INTERNAL && isPassive"
      class="LinkElement"
      [attr.aria-label]="label"
      [href]="link"
      [tabIndex]="tabIndex"
      [target]="attrTarget"
      [attr.itemprop]="itemprop"
      [attr.itemtype]="itemtype"
      [rel]="allRel"
      [attr.data-underline]="underline"
      [attr.data-text-transform]="textTransform"
      [attr.data-display]="display"
      [attr.data-icon]="iconName ? true : false"
      [attr.data-icon-position]="iconPosition"
      [attr.data-outline]="outline"
      [attr.data-flex-direction]="flexDirection"
      [attr.data-flex-align]="flexAlign"
      [attr.data-flex-justify]="flexJustify"
      (click)="$event.preventDefault()"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
    <a
      *ngIf="link && this.linkType === LinkType.EXTERNAL"
      class="LinkElement"
      [attr.aria-label]="label"
      [href]="link"
      [tabIndex]="tabIndex"
      [target]="attrTarget"
      [rel]="allRel"
      [attr.itemprop]="itemprop"
      [attr.itemtype]="itemtype"
      [attr.data-underline]="underline"
      [attr.data-text-transform]="textTransform"
      [attr.data-display]="display"
      [attr.data-icon]="iconName ? true : false"
      [attr.data-icon-position]="iconPosition"
      [attr.data-outline]="outline"
      [attr.data-flex-direction]="flexDirection"
      [attr.data-flex-align]="flexAlign"
      [attr.data-flex-justify]="flexJustify"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
    <a
      *ngIf="link && this.linkType === LinkType.MAIL"
      class="LinkElement"
      [attr.aria-label]="label"
      [href]="link"
      [tabIndex]="tabIndex"
      [target]="'_blank'"
      [rel]="this.linkType === LinkType.MAIL"
      [attr.itemprop]="itemprop"
      [attr.itemtype]="itemtype"
      [attr.data-underline]="underline"
      [attr.data-icon]="iconName ? true : false"
      [attr.data-icon-position]="iconPosition"
      [attr.data-outline]="outline"
      [attr.data-flex-direction]="flexDirection"
      [attr.data-flex-align]="flexAlign"
      [attr.data-flex-justify]="flexJustify"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
    <a
      *ngIf="link && this.linkType === LinkType.TEL"
      class="LinkElement"
      [attr.aria-label]="label"
      [href]="link"
      [tabIndex]="tabIndex"
      [rel]="this.linkType === LinkType.TEL"
      [attr.itemprop]="itemprop"
      [attr.itemtype]="itemtype"
      [attr.data-underline]="underline"
      [attr.data-text-transform]="textTransform"
      [attr.data-display]="display"
      [attr.data-icon]="iconName ? true : false"
      [attr.data-icon-position]="iconPosition"
      [attr.data-outline]="outline"
      [attr.data-flex-direction]="flexDirection"
      [attr.data-flex-align]="flexAlign"
      [attr.data-flex-justify]="flexJustify"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
    <button
      *ngIf="!link"
      class="LinkElement"
      type="button"
      [tabIndex]="tabIndex"
      [attr.aria-label]="label"
      [attr.disabled]="disabled ? true : null"
      [attr.data-underline]="underline"
      [attr.data-text-transform]="textTransform"
      [attr.data-display]="display"
      [attr.data-icon]="iconName ? true : false"
      [attr.data-icon-position]="iconPosition"
      [attr.data-outline]="outline"
      [attr.data-flex-direction]="flexDirection"
      [attr.data-flex-align]="flexAlign"
      [attr.data-flex-justify]="flexJustify"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>

    <ng-template #content>
      <div
        *ngIf="iconName"
        class="LinkIcon"
        [class.IconAnimation]="iconAnimation"
        [class.IconAnimationRight]="
          iconAnimation && iconPosition === LinkIconPosition.RIGHT
        "
        [attr.data-icon-position]="iconPosition"
      >
        <fl-icon
          [name]="iconName"
          [color]="iconColor"
          [label]="iconLabel"
          [size]="iconSize"
        ></fl-icon>
      </div>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent implements OnChanges {
  TextSize = TextSize;
  IconSize = IconSize;
  IconColor = IconColor;
  LinkIconPosition = LinkIconPosition;
  LinkType = LinkType;
  TextTransform = TextTransform;

  /** Microdata | For search engines to identify the property of the element */
  @Input() itemprop: string;

  /** Microdata | Specifies the URL of the vocabulary that will be used to define itemprop */
  @Input() itemtype: string;

  @Input() link?: string;
  @Input() queryParams?: QueryParams;
  /** when a link is clicked, should it preserve the query parameters
   *  from the current route in the new route
   */
  @Input() queryParamsHandling?: QueryParamsHandling;
  /** Anchor Fragment */
  @Input() fragment?: string;

  @Input() rel = '';

  @Input() flexDirection: FlexDirection = 'row';
  @Input() flexAlign: FlexAlign;
  @Input() flexJustify: FlexJustify;

  /**
   * Whether to open the link in a new tab in desktop.
   */
  @Input() newTab?: boolean;

  @Input() underline = LinkUnderline.HOVER;

  @HostBinding('attr.data-color')
  @Input()
  color = LinkColor.DEFAULT;
  /**
   * Text selection color
   */
  @HostBinding('attr.data-highlight-color')
  @Input()
  highlightColor?: HighlightColor;
  @HostBinding('attr.data-hover-color')
  @Input()
  hoverColor = LinkHoverColor.DEFAULT;
  @HostBinding('attr.data-weight')
  @Input()
  weight = LinkWeight.INHERIT;
  @Input() textTransform?: TextTransform;

  @HostBinding('attr.data-size')
  @Input()
  size: TextSize = TextSize.XSMALL;
  @HostBinding('attr.data-size-tablet')
  @Input()
  sizeTablet?: TextSize;
  @HostBinding('attr.data-size-desktop')
  @Input()
  sizeDesktop?: TextSize;
  @HostBinding('attr.data-size-desktop-large')
  @Input()
  sizeDesktopLarge?: TextSize;
  @HostBinding('attr.data-size-desktop-xlarge')
  @Input()
  sizeDesktopXLarge?: TextSize;
  @HostBinding('attr.data-size-desktop-xxlarge')
  @Input()
  sizeDesktopXXLarge?: TextSize;

  @Input() iconName?: string;
  @Input() iconColor: IconColor = IconColor.SECONDARY;
  @Input() iconPosition = LinkIconPosition.LEFT;
  @Input() iconSize: IconSize = IconSize.SMALL;
  @Input() iconLabel?: string;
  @Input() disabled?: boolean;

  @Input() iconAnimation = false;

  @HostBinding('attr.data-display')
  @Input()
  display: 'block' | 'inline' | 'flex' = 'inline';

  @Input() flNativeCopy?: boolean;

  @Input() tabIndex?: number;

  @Input() outline?: LinkOutline;
  /**
   * Strip the base HREF - used to allow admin routes to click into target app.
   */
  @Input() isAbsoluteUrl?: boolean;

  /**
   * Use only when necessary. This adds in aria-label for links / buttons that doesn't have a descriptive text. For example, a link that only has an image inside.
   */
  @Input() label?: string;

  /**
   * TODO: T278739 Clean up (along with the isPassive link in the template) when we get https://github.com/angular/angular/issues/29099 fixed.
   * This is needed because preventDefault() doesn't work to prevent routerLink navigation (https://github.com/angular/angular/issues/21457)
   * and we needed to a link to use a custom function on its event handler.
   *
   * To be used when we want to preventDefault an <a> tag and handle it with custom function
   */
  @Input() isPassive?: boolean;

  allRel = '';
  attrTarget: string;
  linkType: LinkType;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private location: Location,
    private pwa: Pwa,
    private userAgent: UserAgent,
    @Optional() @Inject(APP_BASE_HREF) private baseHref?: string,
  ) {}

  parseLink(link?: string): LinkType {
    // Check if the link is an email or protocol relative.
    if (!!link && (link.startsWith('mailto') || link.startsWith(':'))) {
      return LinkType.MAIL;
    }
    // Check if the link is a telephone link.
    if (!!link && link.startsWith('tel')) {
      return LinkType.TEL;
    }
    // Check if the absolute URL was requested.
    if (
      !!link &&
      this.isAbsoluteUrl &&
      this.baseHref &&
      this.baseHref !== '/'
    ) {
      // Strip the base HREF - used to allow admin routes to click into target app.
      this.link = `${this.location.protocol}//${this.location.host}${link}`;
      return LinkType.EXTERNAL;
    }
    // Check if the link is http(s) or protocol relative.
    if (!!link && (link.startsWith('http') || link.startsWith('//'))) {
      return LinkType.EXTERNAL;
    }
    return LinkType.INTERNAL;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('link' in changes) {
      this.linkType = this.parseLink(this.link);
      if (this.linkType === LinkType.EXTERNAL) {
        // Add queryParams to the href if not already added
        if (this.link && this.queryParams && !this.link.includes('?')) {
          const queryParamsString = Object.entries(this.queryParams)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
          this.link = `${this.link}?${queryParamsString}`;
        }

        this.attrTarget = this.getExternalTarget();
      }

      if (this.linkType === LinkType.INTERNAL) {
        this.attrTarget = this.getInternalTarget();
      }
    }

    if ('link' in changes || 'rel' in changes) {
      // Add noopener to rel if external link, due to security exploits
      // Ref: https://mathiasbynens.github.io/rel-noopener/
      if (
        (this.linkType === LinkType.EXTERNAL &&
          !this.rel.includes('noopener')) ||
        (this.linkType === LinkType.MAIL && !this.rel.includes('noopener'))
      ) {
        this.allRel = `${this.rel} noopener`;
      } else {
        this.allRel = this.rel;
      }
    }
  }

  private getExternalTarget(): '_self' | '_blank' {
    // Never open in new tab on native as this bypasses Capacitor's own logic
    if (this.pwa.isNative()) {
      return '_self';
    }

    // Open in new tab on mobile unless whitelisted
    if (isPlatformBrowser(this.platformId) && this.userAgent.isMobileDevice()) {
      return this.isWhitelisted() ? '_self' : '_blank';
    }

    // Open on new tab by default on desktop
    if (this.newTab === undefined) {
      return '_blank';
    }

    return this.newTab ? '_blank' : '_self';
  }

  private getInternalTarget(): string {
    // Always open links inside the Capacitor app
    if (this.pwa.isNative()) {
      return '_self';
    }

    // Open in current tab on mobile browser unless whitelisted
    if (isPlatformBrowser(this.platformId) && this.userAgent.isMobileDevice()) {
      return this.isWhitelisted() ? '_blank' : '_self';
    }

    // Open on current tab by default on desktop
    if (this.newTab === undefined) {
      return '_self';
    }

    return this.newTab ? '_blank' : '_self';
  }

  private isWhitelisted(): boolean {
    if (!this.link) {
      return false;
    }

    return isLinkWhitelisted(
      {
        source: this.location.pathname,
        destination: this.link,
      },
      this.linkType === LinkType.EXTERNAL,
    );
  }
}
