import { generateId } from '@freelancer/datastore/testing/helpers';
import { CountryCode } from '../countries';
import type { SalesTax } from './sales-tax.model';
import { TaxType } from './sales-tax.model';

interface GenerateSalesTaxOptions {
  readonly id?: number;
  readonly countryCode?: string;
  readonly taxType?: string;
  readonly taxNumber?: string;
  readonly taxRate?: number;
}

export function generateSalesTaxObject({
  id = generateId(),
  countryCode = CountryCode.AU,
  taxType = TaxType.GST,
  taxNumber = '76861743447',
  taxRate = 0.1,
}: GenerateSalesTaxOptions = {}): SalesTax {
  return {
    id,
    countryCode,
    taxType,
    taxNumber,
    taxRate,
  };
}
