import {
  GROUP_DEFAULT_PERMISSIONSApi,
  GroupMemberRoleApi,
} from 'api-typings/groups/groups';
import type { GroupPermissionApi } from 'api-typings/groups/groups';
import type {
  GroupRolePermissions,
  SuperuserGroupPermissions,
  EditablePermissions,
} from './superuser-group-permissions.model';
import { ALL_EDITABLE_PERMISSIONS } from './superuser-group-permissions.model';

export interface GenerateSuperuserGroupPermissionsOptions {
  readonly id: number;
  readonly permissions: GroupRolePermissions;
}

export function generateSuperuserGroupPermissionsObject({
  id,
  permissions,
}: GenerateSuperuserGroupPermissionsOptions): SuperuserGroupPermissions {
  return {
    id,
    permissions,
  };
}

export function generateGroupPermissions({
  enabledPermissions = {},
}: {
  readonly enabledPermissions?: {
    [k in GroupMemberRoleApi]?: readonly EditablePermissions[];
  };
} = {}): GroupRolePermissions {
  const groupPermissions = defaultGroupPermissions();

  Object.entries(enabledPermissions).forEach(([role, permissions]) => {
    permissions.forEach(permission => {
      groupPermissions[role as GroupMemberRoleApi][permission] = true;
    });
  });

  return groupPermissions;
}

function defaultGroupPermissions(): Record<
  GroupMemberRoleApi,
  Record<EditablePermissions, boolean>
> {
  return {
    [GroupMemberRoleApi.ADMIN]: getDefaultGroupPermissions(
      GroupMemberRoleApi.ADMIN,
    ),
    [GroupMemberRoleApi.MODERATOR]: getDefaultGroupPermissions(
      GroupMemberRoleApi.MODERATOR,
    ),
    [GroupMemberRoleApi.MEMBER]: getDefaultGroupPermissions(
      GroupMemberRoleApi.MEMBER,
    ),
    [GroupMemberRoleApi.GUEST]: getDefaultGroupPermissions(
      GroupMemberRoleApi.GUEST,
    ),
    [GroupMemberRoleApi.MUTED]: getDefaultGroupPermissions(
      GroupMemberRoleApi.MUTED,
    ),
  };
}

/* Reference taken from groups-helpers.ts from ui-tests-common/src/groups/ */
export function getDefaultGroupPermissions(
  groupMemberRole: GroupMemberRoleApi,
): Record<EditablePermissions, boolean> {
  // Get all default permissions for the each group member
  //  role and filter out the ones that are not allowed
  const allowedPermissions = Object.entries(
    GROUP_DEFAULT_PERMISSIONSApi[groupMemberRole] ?? {},
  )
    .filter(([_, value]) => value)
    .map(([name, _]) => name) as GroupPermissionApi[];
  const permissions: {
    [k in EditablePermissions]?: boolean;
  } = {};

  ALL_EDITABLE_PERMISSIONS.forEach(permission =>
    allowedPermissions.includes(permission)
      ? (permissions[permission] = true)
      : (permissions[permission] = false),
  );

  return permissions as Record<EditablePermissions, boolean>;
}
