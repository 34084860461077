import { isPlatformBrowser } from '@angular/common';
import type {
  AfterViewInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { isDefined } from '@freelancer/utils';

export enum AnimationBehavior {
  FADE_FROM_BOTTOM = 'fade_from_bottom',
  FADE_FROM_LEFT = 'fade_from_left',
  FADE_FROM_RIGHT = 'fade_from_right',
  FADE_FROM_TOP = 'fade_from_top',
  FADE = 'fade',
  ZOOM = 'zoom',
  GROW = 'grow',
}

@Directive({
  selector: `
    [flAnimateOnScroll],
  `,
})
export class AnimateOnScrollDirective
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input() flAnimateOnScroll = true;
  @Input() flAnimateOnScrollBehavior = AnimationBehavior.FADE;

  /** Whether elements should animate out when scrolling past them */
  @Input() flAnimateOnScrollReset = false;

  /** Defines how long before the animation will play in seconds */
  @Input() flAnimateOnScrollDelay = 0.2;

  /** Defines how long the animation will play in seconds */
  @Input() flAnimateOnScrollDuration = 0.5;

  /** Class name to add as a CSS hook */
  @Input() flAnimateOnScrollClass = 'IsVisible';

  /**
   * Defines how far the animation will start from.
   * This gets converted to percentage for FADE_FROM_* animation behavior
   */
  @Input() flAnimateOnScrollDistance = 0.2;

  private intersectionObserver: IntersectionObserver;
  private threshold = 0.5;
  private initialized = false;
  private animationFrameRequestId: number | undefined = undefined;

  private get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('flAnimateOnScroll' in changes) {
      if (this.flAnimateOnScroll) {
        this.initialize();
      } else {
        this.deactivate();
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.flAnimateOnScroll) {
      this.initialize();
    }
  }

  private setElStyle(property: string, value: string): void {
    this.renderer.setStyle(this.element, property, value);
  }

  private removeElStyle(property: string): void {
    this.renderer.removeStyle(this.element, property);
  }

  private setInitialSyling(): void {
    this.hideElement();
    this.setElStyle(
      'transition',
      `all ${this.flAnimateOnScrollDuration}s ease-in-out ${this.flAnimateOnScrollDelay}s`,
    );
  }

  private initialize(): void {
    if (this.initialized || !isPlatformBrowser(this.platformId)) {
      return;
    }

    this.initialized = true;
    this.setInitialSyling();

    if (!isDefined(this.intersectionObserver)) {
      const options = {
        root: null,
        threshold: this.threshold,
        rootMargin: '0px',
      };

      const observer: IntersectionObserver = new IntersectionObserver(
        (entries, _) => {
          this.animationFrameRequestId = requestAnimationFrame(() => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                this.showElement();
              }

              if (!entry.isIntersecting && this.flAnimateOnScrollReset) {
                this.hideElement();
              }
            });
          });
        },
        options,
      );

      observer.observe(this.element);
    }
  }

  private showElement(): void {
    this.renderer.addClass(this.element, this.flAnimateOnScrollClass);
    this.removeElStyle('opacity');
    this.removeElStyle('transform');
  }

  private hideElement(): void {
    const distancePercentage = `${this.flAnimateOnScrollDistance * 100}%`;

    this.renderer.removeClass(this.element, this.flAnimateOnScrollClass);
    this.setElStyle('opacity', '0');

    switch (this.flAnimateOnScrollBehavior) {
      case AnimationBehavior.FADE_FROM_BOTTOM:
        this.setElStyle('transform', `translateY(${distancePercentage})`);
        break;
      case AnimationBehavior.FADE_FROM_TOP:
        this.setElStyle('transform', `translateY(-${distancePercentage})`);
        break;
      case AnimationBehavior.FADE_FROM_LEFT:
        this.setElStyle('transform', `translateX(-${distancePercentage})`);
        break;
      case AnimationBehavior.FADE_FROM_RIGHT:
        this.setElStyle('transform', `translateX(${distancePercentage})`);
        break;
      case AnimationBehavior.ZOOM:
        this.setElStyle(
          'transform',
          `scale(${this.flAnimateOnScrollDistance})`,
        );
        break;
      case AnimationBehavior.GROW:
        this.setElStyle('opacity', '1');
        this.setElStyle('transform', 'scaleY(0)');
        this.setElStyle('transform-origin', '0 0');
        break;
      default:
    }
  }

  private deactivate(): void {
    this.initialized = false;
    this.intersectionObserver?.disconnect();
    if (this.animationFrameRequestId) {
      cancelAnimationFrame(this.animationFrameRequestId);
    }
  }

  ngOnDestroy(): void {
    this.deactivate();
  }
}
