import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ProjectGroup } from './project-groups.model';

export interface GenerateProjectGroupOptions {
  readonly projectId: number;
  readonly groupId: number;
  readonly userId: number;
}

export function generateProjectGroupObject({
  projectId,
  groupId,
  userId,
}: GenerateProjectGroupOptions): ProjectGroup {
  return {
    id: generateId(),
    projectId,
    groupId,
    userId,
    created: Date.now(),
  };
}

export function generateProjectGroupObjects(
  projectGroups: readonly GenerateProjectGroupOptions[],
): readonly ProjectGroup[] {
  return projectGroups.map(generateProjectGroupObject);
}
