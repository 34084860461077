import { generateId } from '@freelancer/datastore/testing/helpers';
import { GroupMemberRoleApi } from 'api-typings/groups/groups';
import type { SuperuserGroupMember } from './superuser-group-members.model';

export interface GenerateSuperuserGroupMembersOptions {
  readonly id?: number;
  readonly groupId?: number;
  readonly userId?: number;
  readonly role?: GroupMemberRoleApi;
  readonly created?: number;
}

export function generateSuperuserGroupMemberObject({
  id = generateId(),
  groupId = generateId(),
  userId = generateId(),
  role = GroupMemberRoleApi.ADMIN,
  created = Date.now(),
}): SuperuserGroupMember {
  return {
    id,
    groupId,
    userId,
    role,
    created,
  };
}
