import { PayoutMethodTypeEnumApi } from 'api-typings/payments/payments';
import type { CountryCode } from '../countries';
import type { PayoutMethod } from './payout-methods.model';

export function generatePayoutMethodObjects({
  ids,
  countryCodes,
  currencyId = 1,
}: GeneratePayoutMethodOptions): readonly PayoutMethod[] {
  return ids.map(id => ({
    id,
    countryConfigs: countryCodes.map(countryCode => ({
      countryCode,
      currencyId,
    })),
  }));
}

export function wirePayoutMethod(
  countryCodes: readonly CountryCode[],
  currencyId: number = 1,
): GeneratePayoutMethodOptions {
  return {
    ids: [PayoutMethodTypeEnumApi.WIRE],
    countryCodes,
    currencyId,
  };
}

export interface GeneratePayoutMethodOptions {
  readonly ids: readonly PayoutMethodTypeEnumApi[];
  readonly countryCodes: readonly CountryCode[];
  readonly currencyId?: number;
}
