import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  UserDocumentApproverAcountTypeApi,
  UserDocumentSubmissionNoteLevelApi,
  UserDocumentSubmissionStatusApi,
} from 'api-typings/users/users';
import type {
  UserDocumentSubmission,
  UserDocumentSubmissionField,
  UserDocumentSubmissionFile,
  UserDocumentSubmissionNote,
} from './user-document-submissions.model';

export interface GenerateUserDocumentSubmissionOptions {
  readonly id?: number;
  readonly documentTypeId?: number;
  readonly ownerId?: number;
  readonly approverId?: number;
  readonly status?: UserDocumentSubmissionStatusApi;
  readonly userDocumentFiles?: readonly UserDocumentSubmissionFile[];
  readonly userDocumentFields?: readonly UserDocumentSubmissionField[];
  readonly notes?: readonly UserDocumentSubmissionNote[];
  readonly expiryDate?: number;
  readonly dateCreated?: number;
  readonly approverAccountType?: UserDocumentApproverAcountTypeApi;
}

export type GenerateUserDocumentSubmissionNoteOptions = Partial<
  Pick<UserDocumentSubmissionNote, 'note' | 'approverId'>
>;

export type UserDocumentSubmissionNoteMixin = Pick<
  UserDocumentSubmissionNote,
  'approverAccountType' | 'approverId' | 'id' | 'level' | 'note'
>;

export function generateUserDocumentSubmission({
  id = generateId(),
  documentTypeId = generateId(),
  ownerId = generateId(),
  status = UserDocumentSubmissionStatusApi.ACTIVE,
  userDocumentFiles = [],
  userDocumentFields = [],
  notes = [],
  expiryDate,
  dateCreated = Date.now(),
  approverAccountType = UserDocumentApproverAcountTypeApi.ADMIN,
  approverId = generateId(),
}: GenerateUserDocumentSubmissionOptions = {}): UserDocumentSubmission {
  return {
    id,
    documentTypeId,
    ownerId,
    status,
    userDocumentFiles,
    userDocumentFields,
    notes,
    expiryDate,
    dateCreated,
    approverAccountType,
    approverId,
  };
}

export function generateUserDocumentSubmissions(
  options: readonly GenerateUserDocumentSubmissionOptions[],
): readonly UserDocumentSubmission[] {
  return options.map(generateUserDocumentSubmission);
}

// -- Mixins --

export function inReview(): Pick<
  GenerateUserDocumentSubmissionOptions,
  'status'
> {
  return {
    status: UserDocumentSubmissionStatusApi.IN_REVIEW,
  };
}

// NOTE: downloadUrl obviously doesn't work
export function userDocumentSubmissionFiles(
  documentTypeFileIds: readonly number[],
): Pick<GenerateUserDocumentSubmissionOptions, 'userDocumentFiles'> {
  return {
    userDocumentFiles: documentTypeFileIds.map((documentTypeFileId, index) => ({
      documentTypeFileId,
      fileId: generateId().toString(),
      fileName: `test_document_file_${index}`,
      downloadUrl: '/test',
    })),
  };
}

export function infoDocumentSubmissionNote({
  note = 'This is an Info Submission note',
  approverId,
}: GenerateUserDocumentSubmissionNoteOptions = {}): UserDocumentSubmissionNoteMixin {
  return {
    id: generateId(),
    level: UserDocumentSubmissionNoteLevelApi.INFO,
    approverAccountType: UserDocumentApproverAcountTypeApi.ADMIN,
    note,
    approverId,
  };
}

export function warningDocumentSubmissionNote({
  note = 'This is a Warning Submission Note',
  approverId,
}: GenerateUserDocumentSubmissionNoteOptions = {}): UserDocumentSubmissionNoteMixin {
  return {
    id: generateId(),
    level: UserDocumentSubmissionNoteLevelApi.WARNING,
    approverAccountType: UserDocumentApproverAcountTypeApi.ADMIN,
    note,
    approverId,
  };
}

export function errorDocumentSubmissionNote({
  note = 'This is an Error Submission Note',
  approverId,
}: GenerateUserDocumentSubmissionNoteOptions = {}): UserDocumentSubmissionNoteMixin {
  return {
    id: generateId(),
    level: UserDocumentSubmissionNoteLevelApi.ERROR,
    approverAccountType: UserDocumentApproverAcountTypeApi.ADMIN,
    note,
    approverId,
  };
}
