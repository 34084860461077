import { generateId } from '@freelancer/datastore/testing/helpers';
import { Enterprise } from '../enterprise/enterprise.model';
import type { AdminEnterpriseModel } from './admin-enterprises.model';

export interface GenerateAdminEnterpriseOptions {
  readonly id?: number;
  readonly internalName: string;
  readonly name: string;
  readonly seoUrl: string;
  readonly isTestEnterprise?: boolean;
  readonly kycVerified?: boolean;
  readonly dateCreated?: number;
  readonly membersCount: number;
  readonly description?: string;
  readonly landingPageUrl?: string;
}

export function generateAdminEnterpriseObject({
  id,
  internalName,
  name,
  seoUrl,
  isTestEnterprise = false,
  kycVerified = false,
  dateCreated = Date.now(),
  membersCount,
  description,
  landingPageUrl,
}: GenerateAdminEnterpriseOptions): AdminEnterpriseModel {
  return {
    id: id || generateId(),
    internalName,
    name,
    seoUrl,
    isTestEnterprise,
    kycVerified,
    dateCreated,
    membersCount,
    description,
    landingPageUrl,
  };
}

export function generateAdminEnterpriseObjects(
  enterpriseOptionsList: readonly GenerateAdminEnterpriseOptions[],
): readonly AdminEnterpriseModel[] {
  return enterpriseOptionsList.map(enterpriseOptions =>
    generateAdminEnterpriseObject(enterpriseOptions),
  );
}

export function facebookAdminEnterprise(): GenerateAdminEnterpriseOptions {
  return {
    id: Enterprise.FACEBOOK,
    internalName: 'facebook',
    seoUrl: 'facebook',
    name: 'Facebook, Inc',
    membersCount: 10,
    dateCreated: new Date(2019, 3, 15).getTime(),
    description:
      'Facebook, Inc., is an American multinational conglomerate based in Menlo Park, California.',
    landingPageUrl: 'www.facebook.com',
  };
}

export function freightlancerAdminEnterprise(): GenerateAdminEnterpriseOptions {
  return {
    id: Enterprise.FREIGHTLANCER,
    internalName: 'freightlancer',
    seoUrl: 'freightlancer',
    name: 'Freightlancer',
    membersCount: 10,
    dateCreated: new Date(2021, 2, 16).getTime(),
    description: 'Freightlancer description',
    landingPageUrl: 'https://freightlancer.example',
  };
}
