import type { ContestViewEntry } from '../contest-view-entries/contest-view-entries.model';
import type {
  ContestEntryStockItem,
  ContestQuickviewEntry,
} from './contest-quickview-entries.model';

export interface GenerateContestQuickviewEntriesOptions {
  readonly contestViewEntries: readonly ContestViewEntry[];
  readonly stockItems?: readonly ContestEntryStockItem[];
}

export function generateContestQuickviewEntryObjects({
  contestViewEntries,
  stockItems = [],
}: GenerateContestQuickviewEntriesOptions): readonly ContestQuickviewEntry[] {
  return contestViewEntries.map(contestViewEntry => ({
    ...contestViewEntry,
    stockItems,
  }));
}

// --- Mixins ---
