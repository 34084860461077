import {
  generateNumbersInRange,
  randomiseList,
} from '@freelancer/datastore/testing/helpers';
import { RoleApi } from 'api-typings/common/common';
import { type RetentionRateDataApi } from 'api-typings/users/users';
import { defaultReputations } from '../freelancer-reputations/freelancer-reputations.seed';
import type {
  GenerateFreelancerReputationDataWithRehireOption,
  GenerateFreelancerReputationDataWithRehireOptions,
} from '../reputation/reputation-data.seed';
import {
  generateFreelancerReputationDataWithRehireObjects,
  generateReputationDataWithRehireObject,
} from '../reputation/reputation-data.seed';
import type { FreelancerReputationWithRehire } from './freelancer-reputations-rehire-rates.model';

interface Range {
  readonly min: number;
  readonly max: number;
}

export interface GenerateFreelancerReputationsWithRehireOptions {
  readonly freelancerIds: readonly number[];
  readonly reputationOptions?: GenerateFreelancerReputationDataWithRehireOptions;
  readonly earningsScore?: Range;
  readonly acceptRate?: number;
  readonly retentionRate?: RetentionRateDataApi;
}

/** Keep in sync with generateFreelancerReputationsObjects */
export function generateFreelancerReputationsRehireRatesObjects({
  freelancerIds,
  reputationOptions = {},
  earningsScore = { min: 3, max: 10 },
  acceptRate,
  retentionRate,
}: GenerateFreelancerReputationsWithRehireOptions): readonly FreelancerReputationWithRehire[] {
  const entireHistories = generateFreelancerReputationDataWithRehireObjects({
    ...reputationOptions,
    seed: 'entireHistory',
    numberToMake: freelancerIds.length,
  });

  const earningsScores = randomiseList(
    generateNumbersInRange(
      earningsScore.min,
      earningsScore.max,
      freelancerIds.length,
    ),
    'earningsScore',
  );

  return freelancerIds.map((freelancerId, i) => ({
    id: freelancerId,
    role: RoleApi.FREELANCER,
    // Set value to 0 if you've not done any work.
    earningsScore: entireHistories[i].all === 0 ? 0 : earningsScores[i],
    entireHistory: entireHistories[i],
    acceptRate,
    retentionRate,
  }));
}

export interface GenerateFreelancerReputationWithRehireOptions {
  readonly freelancerId: number;
  readonly reputationOptions?: Partial<GenerateFreelancerReputationDataWithRehireOption>;
  readonly earningsScore?: number;
}

/** Keep in sync with generateFreelancerReputationsObject */
export function generateFreelancerReputationsRehireRatesObject({
  freelancerId,
  reputationOptions = {},
  earningsScore = 7,
}: GenerateFreelancerReputationWithRehireOptions): FreelancerReputationWithRehire {
  return {
    id: freelancerId,
    earningsScore,
    role: RoleApi.FREELANCER,
    entireHistory: generateReputationDataWithRehireObject({
      ...defaultReputations,
      rehirePercentage: 0.9,
      ...reputationOptions,
    }),
  };
}
