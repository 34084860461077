import type { RadioOptionItem } from './radio.types';

export function generateRandomString(): string {
  let text = '';
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  for (let i = 0; i < 15; i++) {
    text += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
  }
  return text;
}

export function isRadioOptionItem<T>(
  value: T | RadioOptionItem<T>,
): value is RadioOptionItem<T> {
  return typeof value === 'object' && value && 'displayText' in value;
}
