import { generateId } from '@freelancer/datastore/testing/helpers';
import type { UpgradeType } from '@freelancer/ui/upgrade-tag';
import {
  ContestStatusApi,
  ContestTypeApi,
} from 'api-typings/contests/contests';
import type { GenerateContestOptions } from '../contests/contests.seed';
import { generateContestObject } from '../contests/contests.seed';
import { CurrencyCode } from '../currencies';
import type { Skill } from '../skills/skills.model';
import {
  generateSkillObjects,
  phpSkill,
  pythonSkill,
} from '../skills/skills.seed';
import type { SearchActiveContest } from './search-active-contests.model';

export interface GenerateSearchActiveContestOptions
  extends GenerateContestOptions {
  readonly prizeInUsd?: number;
  readonly skillCount?: number;
  readonly skills?: readonly Skill[];
  readonly timePosted?: number;
  readonly upgradeNames?: readonly UpgradeType[];
  readonly clientReputation?: {
    readonly rating: number;
    readonly reviews: number;
  };
}

export type GenerateSearchActiveContestsOptions =
  GenerateSearchActiveContestOptions & {
    readonly titles: readonly string[];
    readonly prizeInUsd?: number;
    readonly skillCount?: number;
    readonly skills?: readonly Skill[];
    readonly timePosted?: number;
    readonly upgradeNames?: readonly UpgradeType[];
    readonly clientReputation?: {
      readonly rating: number;
      readonly reviews: number;
    };
  };

export function generateSearchActiveContestObjects(
  options: GenerateSearchActiveContestsOptions,
): readonly SearchActiveContest[] {
  return options.titles.map(title => ({
    ...generateSearchActiveContestObject({ ...options, title }),
  }));
}

export function generateSearchActiveContestObject({
  clientReputation,
  contestId,
  currencyCode = CurrencyCode.USD,
  description = 'A contest description',
  duration = 7,
  entryCount = 0,
  ownerId = 0,
  prize = 100,
  prizeInUsd = 100,
  seoUrl,
  skillCount = 1,
  skills = [phpSkill(), pythonSkill()],
  status = ContestStatusApi.ACTIVE,
  timePosted = Date.now(),
  title = 'A sample contest title',
  type = ContestTypeApi.REGULAR,
  upgradeNames = [],
  upgrades = {},
}: GenerateSearchActiveContestOptions): SearchActiveContest {
  const id = contestId ?? generateId();
  const generatedSkills =
    skills.length > 1
      ? skills
      : generateSkillObjects().slice(0, skillCount > 0 ? skillCount : 1);
  const generatedEntryCount = Math.floor(Math.random() * 20);
  const generatedContestOptions = generateContestObject({
    ownerId,
    contestId: id,
    type,
    currencyCode,
    description,
    status,
    skills: generatedSkills,
    title,
    entryCount: generatedEntryCount,
    duration,
    upgrades,
    prize,
    seoUrl,
  });

  return {
    ...generatedContestOptions,
    clientReputation,
    skills,
    skillIds: skills.map(job => job.id),
    entryCount,
    prize,
    prizeInUsd,
    timePosted,
    upgradeNames,
  };
}
