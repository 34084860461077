import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import {
  FontColor,
  FontType,
  FontWeight,
  TextSize,
  TextTransform,
} from '@freelancer/ui/text';
import {
  TagStatusBackgroundColor,
  TagStatusColor,
  TagStatusSize,
} from './tag-status.types';

@Component({
  selector: 'fl-tag-status',
  template: `
    <fl-icon
      *ngIf="iconName"
      class="TagStatusIcon"
      [color]="IconColor.INHERIT"
      [flMarginRight]="'xxsmall'"
      [name]="iconName"
      [size]="IconSize.SMALL"
    ></fl-icon>
    <fl-text
      [color]="FontColor.INHERIT"
      [fontType]="FontType.SPAN"
      [size]="textSize"
      [sizeTablet]="textSizeTablet"
      [sizeDesktop]="textSizeDesktop"
      [textTransform]="textTransform"
      [weight]="FontWeight.BOLD"
    >
      <ng-content></ng-content>
    </fl-text>
  `,
  styleUrls: ['./tag-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagStatusComponent implements OnChanges {
  FontColor = FontColor;
  FontType = FontType;
  FontWeight = FontWeight;
  IconColor = IconColor;
  IconSize = IconSize;
  TextSize = TextSize;
  TextTransform = TextTransform;
  TagStatusSize = TagStatusSize;

  @HostBinding('attr.data-color')
  @Input()
  color: TagStatusColor = TagStatusColor.DEFAULT;

  @Input() iconName?: string;
  @Input() textTransform = TextTransform.CAPITALIZE;

  @HostBinding('attr.data-size')
  @Input()
  size: TagStatusSize = TagStatusSize.LARGE;
  @HostBinding('attr.data-size-tablet')
  @Input()
  sizeTablet?: TagStatusSize;
  @HostBinding('attr.data-size-desktop')
  @Input()
  sizeDesktop?: TagStatusSize;
  @HostBinding('attr.data-background-color')
  @Input()
  backgroundColor = TagStatusBackgroundColor.DEFAULT;

  textSize = TextSize.XSMALL;
  textSizeTablet?: TextSize;
  textSizeDesktop?: TextSize;

  ngOnChanges(changes: SimpleChanges): void {
    if ('size' in changes && this.size !== undefined) {
      this.textSize = this.getTextSizeFromTagSize(this.size);
    }

    if ('sizeTablet' in changes && this.sizeTablet !== undefined) {
      this.textSizeTablet = this.getTextSizeFromTagSize(this.sizeTablet);
    }

    if ('sizeDesktop' in changes && this.sizeDesktop !== undefined) {
      this.textSizeDesktop = this.getTextSizeFromTagSize(this.sizeDesktop);
    }
  }

  private getTextSizeFromTagSize(tagSize: TagStatusSize): TextSize {
    switch (tagSize) {
      case TagStatusSize.SMALL:
        return TextSize.XXSMALL;
      case TagStatusSize.LARGE:
        return TextSize.XSMALL;
      default:
        return TextSize.XSMALL;
    }
  }
}
