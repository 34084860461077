import { generateId } from '@freelancer/datastore/testing/helpers';
import { SourceTypeApi, SupportTypeApi } from 'api-typings/support/support';
import type { ResolveReason } from './session-resolve-reasons.model';

export interface GenerateSessionResolveReasonsObjectOptions {
  readonly id?: number;
  readonly supportType?: SupportTypeApi;
  readonly sourceTypes?: readonly SourceTypeApi[];
  readonly internalName?: string;
  readonly isArchived?: boolean;
  readonly reason?: string;
}

export function generateSessionResolveReasonObject({
  id = generateId(),
  supportType = SupportTypeApi.EQA,
  sourceTypes = [SourceTypeApi.PROJECT],
  internalName = 'a_test_resolve_reason',
  isArchived = false,
  reason = 'A test resolve reason',
}: GenerateSessionResolveReasonsObjectOptions): ResolveReason {
  return {
    id,
    supportType,
    sourceTypes,
    internalName,
    isArchived,
    reason,
  };
}
