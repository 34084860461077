import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { FormControl } from '@angular/forms';
import { ButtonColor } from '@freelancer/ui/button';
import { trackByValue } from '@freelancer/ui/helpers';
import { InputType } from '@freelancer/ui/input';
import { TextSize } from '@freelancer/ui/text';

export interface DynamicDemoConfig {
  duration?: FormControl<number>; // in ms
  delay?: FormControl<number>; // in ms
  easing?: FormControl<string>;
  translateX?: FormControl<string>; // with unit
  custom?: {
    [key: string]: FormControl<string>;
  };
}

@Component({
  selector: 'app-dynamic-demo',
  template: `
    <fl-bit class="DynamicDemo">
      <fl-bit
        class="DynamicDemo-content"
        [attr.data-overflow]="overflow"
      >
        <ng-content select="app-dynamic-demo-content"></ng-content>
      </fl-bit>

      <fl-bit class="DynamicDemo-controls">
        <fl-bit
          *ngFor="let configKey of configKeys; trackBy: trackByValue"
          [flMarginBottom]="'xsmall'"
        >
          <ng-container [ngSwitch]="configKey">
            <ng-container *ngSwitchCase="'duration'">
              <fl-text
                [flMarginBottom]="'xxxsmall'"
                [size]="TextSize.XXSMALL"
              >
                Duration
              </fl-text>
              <fl-input
                *ngIf="config.duration"
                [afterLabel]="'ms'"
                [control]="config.duration"
                [type]="InputType.NUMBER"
              ></fl-input>
            </ng-container>

            <ng-container *ngSwitchCase="'delay'">
              <fl-text
                [flMarginBottom]="'xxxsmall'"
                [size]="TextSize.XXSMALL"
              >
                Delay
              </fl-text>
              <fl-input
                *ngIf="config.delay"
                [afterLabel]="'ms'"
                [control]="config.delay"
                [type]="InputType.NUMBER"
              ></fl-input>
            </ng-container>

            <ng-container *ngSwitchCase="'easing'">
              <fl-text
                [flMarginBottom]="'xxxsmall'"
                [size]="TextSize.XXSMALL"
              >
                Easing
              </fl-text>
              <fl-select
                *ngIf="config.easing"
                [control]="config.easing"
                [options]="easingTypes"
              ></fl-select>
            </ng-container>

            <ng-container *ngSwitchCase="'translateX'">
              <fl-text
                [flMarginBottom]="'xxxsmall'"
                [size]="TextSize.XXSMALL"
              >
                Translate X
              </fl-text>
              <fl-input
                *ngIf="config.translateX"
                [control]="config.translateX"
                [type]="InputType.TEXT"
              ></fl-input>
            </ng-container>

            <ng-container *ngSwitchCase="'custom'">
              <fl-bit
                *ngFor="let customKey of customKeys; trackBy: trackByValue"
                [flMarginBottom]="'xsmall'"
              >
                <fl-text
                  [flMarginBottom]="'xxxsmall'"
                  [size]="TextSize.XXSMALL"
                >
                  {{ customKey | titlecase }}
                </fl-text>
                <fl-input
                  *ngIf="config.custom"
                  [control]="config.custom[customKey]"
                  [type]="InputType.TEXT"
                ></fl-input
              ></fl-bit>
            </ng-container>
          </ng-container>
        </fl-bit>
        <ng-content select="app-dynamic-demo-action"></ng-content>
      </fl-bit>
    </fl-bit>
  `,
  styleUrls: ['./dynamic-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDemoComponent implements OnChanges {
  ButtonColor = ButtonColor;
  InputType = InputType;
  TextSize = TextSize;
  trackByValue = trackByValue;

  @Input() config: DynamicDemoConfig;
  @Input() overflow = true;

  configKeys: readonly string[];
  customKeys: readonly string[];
  easingTypes: readonly string[] = ['ease-in', 'ease-out', 'ease-in-out'];

  ngOnChanges(changes: SimpleChanges): void {
    if ('config' in changes) {
      this.configKeys = Object.keys(this.config);
      this.customKeys = [];

      if (this.config.custom) {
        this.customKeys = Object.keys(this.config.custom);
      }
    }
  }
}
