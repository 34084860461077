import type { Group } from '../groups/groups.model';

export interface GenerateGroupSelfOptions {
  readonly groups: readonly Group[];
}

export function generateGroupsSelf({
  groups,
}: GenerateGroupSelfOptions): readonly Group[] {
  return groups;
}
