import type { UserHasDuplicateIp } from './user-has-duplicate-ip.model';

export interface GenerateUserHasDuplicateIpOptions {
  readonly userId: string;
  readonly userHasDuplicateIp: boolean;
}

export function generateUserHasDuplicateIpObject({
  userId,
  userHasDuplicateIp,
}: GenerateUserHasDuplicateIpOptions): UserHasDuplicateIp {
  return {
    id: userId,
    userHasDuplicateIp,
  };
}
