import type { GroupPermissionApi } from 'api-typings/groups/groups';
import type { FeedItem } from '../feed';
import type { Post } from '../feed-posts';
import type { GroupMember } from '../group-members';
import type { Group } from '../groups';
import type { GroupFeedItem } from './group-feed.model';

export interface GenerateGroupFeedOptions {
  readonly groupId: number;
  readonly post: Post;
  readonly feedItem: FeedItem;
  readonly permissions: readonly GroupPermissionApi[];
  readonly viewingUserGroupMember?: GroupMember;
  readonly postOwnerGroupMember?: GroupMember;
  readonly isBookmarked?: boolean;
  readonly group?: Group;
}

export function generateGroupFeedObject({
  groupId,
  feedItem,
  post,
  permissions,
  viewingUserGroupMember,
  postOwnerGroupMember,
  isBookmarked,
  group,
}: GenerateGroupFeedOptions): GroupFeedItem {
  return {
    id: feedItem.id,
    groupId,
    feedItem,
    post,
    permissions,
    viewingUserGroupMember,
    postOwnerGroupMember,
    updated: feedItem.updated ?? Date.now(),
    feedItemReferenceType: feedItem.referenceType,
    feedItemReferenceId: feedItem.referenceId,
    isBookmarked: isBookmarked ?? false,
    group,
  };
}
