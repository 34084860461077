import { generateId } from '@freelancer/datastore/testing/helpers';
import { ContestCommentContextTypeApi } from 'api-typings/contests/contests';
import type {
  ParentContestComment,
  ReplyContestComment,
} from './contest-comments.model';
import { ContestCommentType } from './contest-comments.model';

export interface GenerateContestCommentOptions {
  readonly id?: number;
  readonly contextType: ContestCommentContextTypeApi;
  readonly contextId: number;
  readonly comment: string;
  readonly fromUserId: number;
}

export interface GenerateReplyContestCommentOptions
  extends GenerateContestCommentOptions {
  readonly parentId: number;
  readonly toUserId: number;
}

export interface GenerateParentContestCommentOptions
  extends GenerateContestCommentOptions {
  readonly repliesCount: number;
  readonly annotationDetails?: {
    readonly annotationId: number;
    readonly coordinates: { readonly x: number; readonly y: number };
    readonly fileId: number;
  };
}

export function generateReplyContestCommentObject({
  id = generateId(),
  contextType = ContestCommentContextTypeApi.CONTEST,
  contextId,
  comment,
  fromUserId,
  toUserId,
  parentId,
}: GenerateReplyContestCommentOptions): ReplyContestComment {
  return {
    id,
    contextType,
    contextId,
    comment,
    isPrivate: false,
    fromUserId,
    timestamp: Date.now(),
    dateLastComment: Date.now(),
    isDeleted: false,
    type: ContestCommentType.REPLY,
    parentId,
    toUserId,
  };
}

export function generateParentContestCommentObject({
  id = generateId(),
  contextType = ContestCommentContextTypeApi.CONTEST,
  contextId,
  comment,
  fromUserId,
  repliesCount = 0,
  annotationDetails,
}: GenerateParentContestCommentOptions): ParentContestComment {
  return {
    id,
    contextType,
    contextId,
    comment,
    isPrivate: false,
    fromUserId,
    timestamp: Date.now(),
    dateLastComment: Date.now(),
    isDeleted: false,
    type: ContestCommentType.PARENT,
    parentId: undefined,
    repliesCount,
    annotationDetails,
  };
}

// --- Mixins ---

export function annotationContestComment({
  fileId,
  fromUserId,
  comment = 'Annotation Comment',
}: {
  readonly fileId: number;
  readonly fromUserId: number;
  readonly comment?: string;
}): GenerateParentContestCommentOptions {
  return {
    contextType: ContestCommentContextTypeApi.ANNOTATION,
    contextId: fileId,
    comment,
    fromUserId,
    repliesCount: 0,
    annotationDetails: {
      annotationId: generateId(),
      fileId,
      coordinates: {
        x: 1,
        y: 1,
      },
    },
  };
}
