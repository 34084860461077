import { generateId } from '@freelancer/datastore/testing/helpers';
import type { EnterpriseBillingAgreementBalance } from './enterprise-billing-agreements-balance.model';

export interface GenerateEnterpriseBillingAgreementsBalanceOptions {
  readonly enterpriseBillingAgreementName: string;
}

export function generateEnterpriseBillingAgreementsBalance({
  enterpriseBillingAgreementName,
}: GenerateEnterpriseBillingAgreementsBalanceOptions): EnterpriseBillingAgreementBalance {
  return {
    id: generateId(),
    name: enterpriseBillingAgreementName,
    currencyId: 1,
    unusedPurchaseOrderBalance: 100,
    creditBalance: 50,
    billingAgreementId: generateId(),
  };
}
