import type { GrantApi } from 'api-typings/grants/grants';
import type { GenerateProjectViewUserOptions } from '../project-view-users';
import { generateProjectViewUserObject } from '../project-view-users';
import type { GenerateUserOptions } from '../users';
import { generateUserObject } from '../users';
import type { SuperuserProjectViewUser } from './superuser-project-view-users.model';

export interface GenerateSuperuserProjectViewUserExtrasOptions {
  readonly email?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly closed?: boolean;
  readonly grants?: readonly GrantApi[];
  readonly suspended?: boolean;
  readonly limitedAccount?: boolean;
}

export interface GenerateSuperuserProjectViewUserOptions {
  readonly projectViewUserObject?: GenerateProjectViewUserOptions;
  readonly userObject?: GenerateUserOptions;
  readonly extras?: GenerateSuperuserProjectViewUserExtrasOptions;
}

export function generateSuperuserProjectViewUserObject({
  projectViewUserObject,
  userObject,
  extras = generateSuperuserProjectViewUserExtras(),
}: GenerateSuperuserProjectViewUserOptions = {}): SuperuserProjectViewUser {
  const projectViewUser = generateProjectViewUserObject({
    ...projectViewUserObject,
    user: generateUserObject(userObject), // required `user` field
  });

  return {
    ...projectViewUser,
    ...extras,
    closed: extras.closed ?? false,
  };
}

function generateSuperuserProjectViewUserExtras(
  extras: GenerateSuperuserProjectViewUserExtrasOptions = { closed: false },
): GenerateSuperuserProjectViewUserExtrasOptions {
  return {
    email: extras.email ?? 'testemail@gmail.com',
    firstName: extras.firstName ?? 'TestFirstName',
    lastName: extras.lastName ?? 'TestLastName',
    closed: extras.closed,
    grants: extras.grants, // Just add mock default values as needed
    suspended: extras.suspended ?? false,
    limitedAccount: extras.limitedAccount ?? false,
  };
}
