import { generateId } from '@freelancer/datastore/testing/helpers';
import type { InvoiceStatusApi } from 'api-typings/payments/payments';
import { ProjectInvoiceMilestoneLinkedStatusesApi } from 'api-typings/payments/payments';
import type { Invoice } from './invoices.model';

export interface GenerateInvoiceOptions {
  readonly employerId: number;
  readonly freelancerId: number;
  readonly projectId: number;
  readonly bidId?: number;
  readonly milestoneId?: number;
  readonly milestoneLinkedStatus?: ProjectInvoiceMilestoneLinkedStatusesApi;
  readonly status?: InvoiceStatusApi;
  readonly totalHours?: number;
  readonly totalHoursAmount?: number;
  readonly totalResultAmount?: number;
  readonly totalOtherAmount?: number;
  readonly totalMilestoneAmount?: number;
  readonly latestUnreviewedByEmployer?: boolean;
}

export function generateInvoiceObject({
  employerId,
  freelancerId,
  projectId,
  bidId,
  milestoneId,
  milestoneLinkedStatus = ProjectInvoiceMilestoneLinkedStatusesApi.UNLINKED,
  status,
  totalHours = 1,
  totalHoursAmount = 20,
  totalResultAmount = 20,
  totalOtherAmount = 0,
  totalMilestoneAmount = 20,
  latestUnreviewedByEmployer = false,
}: GenerateInvoiceOptions): Invoice {
  return {
    id: generateId(),
    employerId,
    freelancerId,
    projectId,
    bidId,
    milestoneId,
    milestoneLinkedStatus,
    status,
    totalHours,
    totalHoursAmount,
    totalResultAmount,
    totalOtherAmount,
    totalMilestoneAmount,
    latestUnreviewedByEmployer,
  };
}
