import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FontColor, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-brand-style-guide-imagery',
  template: `
    <div class="Imagery">
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Imagery
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        The Freelancer brand is bold, vibrant, dynamic and global. The imagery
        used at Freelancer should reflect this, whilst still also being relevant
        for the product or promotion where it is used.
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        Our brand style is continually evolving and expanding. To maintain some
        consistency lean towards making your images dark but vibrant with high
        contrast, saturated colours, with a neon or synthwave style. Images need
        to look highly realistic rather than any particular style.
      </fl-text>
      <div [flMarginBottom]="'xlarge'">
        <fl-picture
          [fullWidth]="true"
          [src]="'brand-guidelines/imagery-1.png'"
        ></fl-picture>
      </div>
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Best of Freelancer
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        Show the best freelancers' work in the best way. Make the visuals
        compelling. We want to tell the story behind the work, and what led to a
        dream becoming real.
      </fl-text>
      <fl-picture
        [fullWidth]="true"
        [src]="'brand-guidelines/imagery-2.png'"
      ></fl-picture>
    </div>
  `,
  styleUrls: ['./../brand-style-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandStyleGuideImageryComponent {
  FontColor = FontColor;
  FontWeight = FontWeight;
  TextSize = TextSize;
}
