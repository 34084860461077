import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  CheckInContextTypeApi,
  CheckInScheduleTypeApi,
} from 'api-typings/check_in/check_in';
import type { CheckInSchedule } from './check-in-schedule.model';

export interface GenerateCheckInScheduleOption {
  readonly checkInScheduleId?: number;
  readonly contextType?: CheckInContextTypeApi;
  readonly contextId: number;
  readonly contextOwnerUsersId: number;
  readonly scheduleUsersId: number;
  readonly scheduleType?: CheckInScheduleTypeApi;
  readonly daysOfWeek?: readonly number[];
  readonly daysOfMonth?: readonly number[];
  readonly lastRemindTime?: number;
  readonly nextRemindTime?: number;
  readonly lastUpdateTime?: number;
  readonly statusTime?: number;
  readonly remindCount?: number;
  readonly preferredSchedule?: CheckInScheduleTypeApi;
}

export function generateCheckInScheduleObject({
  checkInScheduleId = generateId(),
  contextType = CheckInContextTypeApi.PROJECT,
  contextId,
  contextOwnerUsersId,
  scheduleUsersId,
  scheduleType = CheckInScheduleTypeApi.DAILY,
  daysOfWeek = undefined,
  daysOfMonth = undefined,
  lastRemindTime = undefined,
  nextRemindTime = undefined,
  lastUpdateTime = undefined,
  statusTime = undefined,
  remindCount = 0,
  preferredSchedule = undefined,
}: GenerateCheckInScheduleOption): CheckInSchedule {
  return {
    id: checkInScheduleId,
    contextType,
    contextId,
    contextOwnerUsersId,
    scheduleUsersId,
    scheduleType,
    daysOfWeek,
    daysOfMonth,
    lastRemindTime,
    nextRemindTime,
    lastUpdateTime,
    statusTime,
    remindCount,
    preferredSchedule,
  };
}
