import { generateId } from '@freelancer/datastore/testing/helpers';
// TODO: T235847 This requires deep import in order to avoid transpiling
// html-renderer.module.ts from index.ts.
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type {
  RichTextElement,
  Text,
  Paragraph,
  PhrasingElement,
  Image,
  Heading,
} from '@freelancer/html-renderer/html-elements.model';
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { HtmlElementType } from '@freelancer/html-renderer/html-elements.model';
import {
  ContentCustomizationEntityTypeApi,
  ContentCustomizationInjectionPointApi,
} from 'api-typings/common/common';
import type { EnterpriseModel } from '../enterprises';
import type { ContentCustomization } from './content-customization.model';

export interface GenerateContentCustomizationOptions {
  readonly id?: number;
  readonly entityId: number;
  readonly entityType?: ContentCustomizationEntityTypeApi;
  readonly injectionPoint: ContentCustomizationInjectionPointApi;
  readonly elements?: readonly RichTextElement[];
  readonly languageCode?: string;
}

export interface GenerateContentCustomizationsOptions {
  readonly entityId: number;
  readonly entityType?: ContentCustomizationEntityTypeApi;
  readonly customizations: readonly Pick<
    GenerateContentCustomizationOptions,
    'injectionPoint' | 'elements'
  >[];
}

export function generateContentCustomizationObject({
  id = generateId(),
  entityId,
  entityType = ContentCustomizationEntityTypeApi.ENTERPRISE,
  injectionPoint,
  elements = [generateTextHtmlElementObject('test')],
  languageCode = 'en',
}: GenerateContentCustomizationOptions): ContentCustomization {
  return {
    id,
    entityId,
    entityType,
    injectionPoint,
    elements,
    languageCode,
  };
}

export function generateContentCustomizationObjects({
  entityId,
  entityType,
  customizations,
}: GenerateContentCustomizationsOptions): readonly ContentCustomization[] {
  return customizations.map(customization =>
    generateContentCustomizationObject({
      ...customization,
      entityId,
      entityType,
    }),
  );
}

export function generateTextHtmlElementObject(value: string): Text {
  return {
    type: HtmlElementType.TEXT,
    value,
  };
}

export function generateHeadingHtmlElementObject(
  values: readonly PhrasingElement[],
  headingType: Heading['type'] = HtmlElementType.H1,
): Heading {
  return {
    type: headingType,
    children: values,
  };
}

export function generateParagraphHtmlElementObject(
  values: readonly (PhrasingElement | Image)[],
): Paragraph {
  return {
    type: HtmlElementType.PARAGRAPH,
    children: values,
  };
}

// MIXINS

export function forEnterprise(
  enterprise: EnterpriseModel,
): Pick<GenerateContentCustomizationOptions, 'entityId' | 'entityType'> {
  return {
    entityId: enterprise.id,
    entityType: ContentCustomizationEntityTypeApi.ENTERPRISE,
  };
}

export function bidsDigestEmailSubject(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.BIDS_DIGEST_DUAL_POSTED_EMAIL_SUBJECT,
    elements: [
      generateTextHtmlElementObject('Editable bids digest email subject'),
    ],
  };
}

export function bidsDigestEmailBody(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.BIDS_DIGEST_DUAL_POSTED_EMAIL_BODY,
    elements: [
      generateTextHtmlElementObject('Editable bids digest email body'),
    ],
  };
}

export function wonEmailSubject(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint: ContentCustomizationInjectionPointApi.WON_EMAIL_SUBJECT,
    elements: [generateTextHtmlElementObject('Editable won email subject')],
  };
}

export function wonEmailBody(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint: ContentCustomizationInjectionPointApi.WON_EMAIL_BODY,
    elements: [generateTextHtmlElementObject('Editable won email body')],
  };
}

export function collaborationInvitationEmailSubject(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.PROJECT_COLLABORATION_GENERAL_INVITATION_NEW_USER_EMAIL_SUBJECT,
    elements: [
      generateTextHtmlElementObject(
        'Editable project collaboration general invitation email subject',
      ),
    ],
  };
}

export function collaborationInvitationEmailBody(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.PROJECT_COLLABORATION_GENERAL_INVITATION_NEW_USER_EMAIL_BODY,
    elements: [
      generateTextHtmlElementObject(
        'Editable project collaboration general invitation email body',
      ),
    ],
  };
}

export function reminderUserToComeBackEmailSubject(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.REMIND_USER_TO_COME_BACK_EMAIL_SUBJECT,
    elements: [
      generateTextHtmlElementObject(
        'Editable remind user to come back email subject',
      ),
    ],
  };
}

export function reminderUserToComeBackEmailBody(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.REMIND_USER_TO_COME_BACK_EMAIL_BODY,
    elements: [
      generateTextHtmlElementObject(
        'Editable remind user to come back email body',
      ),
    ],
  };
}

export function fundingRequiredEmailSubject(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.FUNDING_REQUIRED_EMAIL_SUBJECT,
    elements: [
      generateTextHtmlElementObject(
        'FYA: {{user_first_name}} {{user_last_name}} has requested funding for {{project_name}}',
      ),
    ],
  };
}

export function fundingRequiredEmailBody(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.FUNDING_REQUIRED_EMAIL_BODY,
    elements: [
      generateTextHtmlElementObject(
        '{{user_first_name}} {{user_last_name}} has requested funding for {{project_name}}',
      ),
    ],
  };
}

export function ppmdEmailSubject(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.PROJECT_POSTER_TO_PPMD_EMAIL_SUBJECT,
    elements: [
      generateTextHtmlElementObject(
        'FYA: Pending MyGigs External Project from {{user_first_name}} {{user_last_name}}',
      ),
    ],
  };
}

export function ppmdEmailBody(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.PROJECT_POSTER_TO_PPMD_EMAIL_BODY,
    elements: [generateTextHtmlElementObject('hello world')],
  };
}

export function complianceQueueAgentDigestEmailSubject(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.COMPLIANCE_QUEUE_AGENT_DIGEST_EMAIL_SUBJECT,
    elements: [
      generateTextHtmlElementObject('Editable agent digest email subject'),
    ],
  };
}

export function complianceQueueAgentDigestEmailBody(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.COMPLIANCE_QUEUE_AGENT_DIGEST_EMAIL_BODY,
    elements: [
      generateTextHtmlElementObject('Editable agent digest email body'),
    ],
  };
}

export function colleagueAwardedProjectEmailSubject(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.COLLEAGUE_AWARDED_PROJECT_EMAIL_SUBJECT,
    elements: [
      generateTextHtmlElementObject(
        'Editable colleague awarded project email subject',
      ),
    ],
  };
}

export function colleagueAwardedProjectEmailBody(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.COLLEAGUE_AWARDED_PROJECT_EMAIL_BODY,
    elements: [
      generateTextHtmlElementObject(
        'Editable colleague awarded project email body',
      ),
    ],
  };
}

export function pjpSubheader(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint: ContentCustomizationInjectionPointApi.PJP_SUBHEADER,
    elements: [
      generateParagraphHtmlElementObject([
        generateTextHtmlElementObject('Editable PJP subheader'),
      ]),
    ],
  };
}

export function preapprovedRepostPjpSubheader(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.PREAPPROVED_REPOST_PJP_SUBHEADER,
    elements: [
      generateParagraphHtmlElementObject([
        generateTextHtmlElementObject(
          'Editable preapproved repost PJP subheader',
        ),
      ]),
    ],
  };
}

export function rejectedRepostPjpSubheader(): Pick<
  GenerateContentCustomizationOptions,
  'injectionPoint' | 'elements'
> {
  return {
    injectionPoint:
      ContentCustomizationInjectionPointApi.REJECTED_REPOST_PJP_SUBHEADER,
    elements: [
      generateParagraphHtmlElementObject([
        generateTextHtmlElementObject('Editable rejected repost PJP subheader'),
      ]),
    ],
  };
}
