import type { CurrencyCode } from '../currencies';
import type { ExchangeRate } from '../exchange-rates';
import { generateExchangeRateObject } from '../exchange-rates';

export interface GenerateNativeExchangeRatesOptions {
  readonly currencyCodes: readonly CurrencyCode[];
}

export function generateNativeExchangeRatesObject(
  options: GenerateNativeExchangeRatesOptions,
): readonly ExchangeRate[] {
  return options.currencyCodes.map(generateNativeExchangeRateObject);
}

export function generateNativeExchangeRateObject(
  currencyCode: CurrencyCode,
): ExchangeRate {
  return generateExchangeRateObject(currencyCode);
}
