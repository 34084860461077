import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import type { Routes } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HeadingType, HeadingWeight } from '@freelancer/ui/heading';
import { IconSize } from '@freelancer/ui/icon';
import type { Tab } from '@freelancer/ui/tabs';
import { TabsDirection, TabsSize } from '@freelancer/ui/tabs';
import { TextSize } from '@freelancer/ui/text';
import { isDefined } from '@freelancer/utils';
import { componentStoryMap } from '../../generated/component-story-map';
import { prettyPrint } from '../../helpers';

interface SidebarCategoryMap {
  [k: string]: readonly string[];
}

interface SidebarStoryMap {
  [name: string]: {
    path: string;
    internalOnly: boolean;
    tags: readonly string[];
    stories: readonly string[];
  };
}

interface SidebarItem {
  title: string;
  tabs: readonly Tab[];
}

@Component({
  selector: 'app-sidebar',
  template: `
    <fl-bit
      class="SidebarHeader"
      [flMarginBottom]="'small'"
    >
      <fl-heading
        [headingType]="HeadingType.H2"
        [weight]="HeadingWeight.NORMAL"
        [size]="TextSize.MARKETING_SMALL"
      >
        {{ title }}
      </fl-heading>
      <fl-icon
        class="SidebarHeader-action"
        [name]="'ui-sidebar-left'"
        [size]="IconSize.SMALL"
        [flShowMobile]="true"
        [clickable]="true"
        (click)="handleCloseSidebar()"
      ></fl-icon>
    </fl-bit>
    <fl-scrollable-content class="SidebarContent">
      <ng-container *ngFor="let item of sidebarItems; trackBy: trackByTabTitle">
        <fl-heading
          class="CategoryTitle"
          [headingType]="HeadingType.H3"
          [weight]="HeadingWeight.MEDIUM"
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxsmall'"
        >
          {{ item.title }}
        </fl-heading>
        <fl-tabs
          [direction]="TabsDirection.COLUMN_RIGHT"
          [size]="TabsSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          <fl-tab-item
            *ngFor="let tab of item.tabs; trackBy: trackByTabTitle"
            [titleText]="tab.title"
            [link]="tab.link"
            [routeHasChildren]="true"
          ></fl-tab-item>
        </fl-tabs>
      </ng-container>
    </fl-scrollable-content>
  `,
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  IconSize = IconSize;
  TabsDirection = TabsDirection;
  TabsSize = TabsSize;
  TextSize = TextSize;

  sidebarItems: readonly SidebarItem[];
  title: string;

  @Output() closeSidebar = new EventEmitter<void>();
  @Input() hideInternalPages = true;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.generateSidebarItems();
  }

  generateSidebarItems(): void {
    const { routeConfig } = this.activatedRoute.snapshot;

    if (!routeConfig) {
      return;
    }

    const { path, children } = routeConfig;

    if (!isDefined(path)) {
      return;
    }

    this.title = prettyPrint(path);

    /** For now, we're only categorizing the components */
    if (path === 'components') {
      this.sidebarItems = this.getCategorizedTabs(path, componentStoryMap);
      return;
    }

    if (isDefined(children)) {
      this.sidebarItems = this.getUnCategorizedTabs(path, children);
    }
  }

  /**
   * Categorized stories based on their tag
   * defined in their respective readme.md
   */
  private getCategorizedTabs(
    route: string,
    storyMap: SidebarStoryMap,
  ): readonly SidebarItem[] {
    const groupedStories = Object.keys(storyMap).reduce(
      (all: SidebarCategoryMap, category) => {
        if (!storyMap[category]) {
          return all;
        }

        // use a first tag as its category
        const tag = storyMap[category].tags[0];

        return {
          ...all,
          [tag]: all[tag] ? [...all[tag], category] : [category],
        };
      },
      {},
    );

    return Object.entries(groupedStories)
      .sort()
      .map(([category, children]) => ({
        title: prettyPrint(category || ''),
        tabs: children
          .filter(item => {
            if (!this.hideInternalPages) {
              return true;
            }

            return !storyMap[item].internalOnly;
          })
          .map(item => ({
            link: `/${route}/${item}`,
            title: prettyPrint(item || ''),
          })),
      }))
      .filter(story => story.tabs.length > 0);
  }

  /**
   * Uncategorized version of the tabs
   */
  private getUnCategorizedTabs(
    path: string,
    items: Routes,
  ): readonly SidebarItem[] {
    return [
      {
        title: '',
        tabs: items
          .filter(item => isDefined(item.path) && item.path !== '')
          .filter(item => {
            if (!this.hideInternalPages || !isDefined(item.data)) {
              return true;
            }

            return !item.data.internalOnly;
          })
          .map(item => ({
            link: `/${path}/${item.path}`,
            title: prettyPrint(item.path || ''),
          })),
      },
    ];
  }

  handleCloseSidebar(): void {
    this.closeSidebar.emit();
  }

  trackByTabTitle(_: number, tab: Tab | SidebarItem): string {
    return tab.title;
  }
}
