import { generateId } from '@freelancer/datastore/testing/helpers';
import { ContestStatusApi } from 'api-typings/contests/contests';
import type { ManageViewContest } from './manage-view-contests.model';

export interface GenerateManageViewContestOptions {
  readonly id?: number;
  readonly title?: string;
  readonly seoUrl?: string;
  readonly ownerId?: number;
  readonly entryCount?: number;
  readonly prize?: number;
  readonly currencyCode?: string;
  readonly timeEnded?: number;
  readonly status?: ContestStatusApi;
}

export function generateManageViewContestObject({
  id = generateId(),
  title = 'A contest title',
  seoUrl = `contest/${id}`,
  ownerId = generateId(),
  entryCount = 0,
  prize = 100,
  currencyCode = 'USD',
  timeEnded,
  status = ContestStatusApi.ACTIVE,
}: GenerateManageViewContestOptions): ManageViewContest {
  return {
    id,
    title,
    seoUrl,
    ownerId,
    entryCount,
    prize,
    currencyCode,
    timeEnded,
    status,
  };
}
