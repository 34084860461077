import { generateId } from '@freelancer/datastore/testing/helpers';
import { assertNever } from '@freelancer/utils';
import {
  TaskGroupSourceTypeApi,
  TaskGroupStatusApi,
  WorkflowTypeApi,
} from 'api-typings/tasklist/tasklist';
import type { Bid } from '../bids';
import type { Group } from '../groups';
import type { Project } from '../projects';
import type { User } from '../users';
import type {
  TaskGroup,
  TaskGroupLinkedSourceObjectType,
} from './task-group.model';

export interface GenerateTaskGroupObjectOptions {
  readonly taskGroupId?: number;
  readonly ownerId?: number;
  readonly sourcesToLink?: readonly TaskGroupLinkedSourceObjectType[];
  readonly status?: TaskGroupStatusApi;
  readonly name?: string;
  readonly description?: string;
  readonly createdTime?: number;
  readonly userId?: number;
  readonly workflowType?: WorkflowTypeApi;
}

export function generateTaskGroupObject({
  taskGroupId = generateId(),
  ownerId = generateId(),
  sourcesToLink = [],
  status = TaskGroupStatusApi.OPEN,
  name = 'Daily To Do List',
  description = 'A list of things to do today',
  createdTime,
  userId = generateId(),
  workflowType = WorkflowTypeApi.NONE,
}: GenerateTaskGroupObjectOptions): TaskGroup {
  const currentTime = Date.now();
  const linkedSources = sourcesToLink.map(source => ({
    id: generateId(),
    taskGroupId,
    sourceId: source.id,
    sourceType: getSourceType(source),
    createdTime: createdTime ?? currentTime,
  }));

  const linkedSourcesMinimal = linkedSources.map(
    ({ sourceId, sourceType }) => ({ sourceId, sourceType }),
  );

  return {
    id: taskGroupId,
    ownerId,
    linkedSources,
    status,
    name,
    description,
    createdTime: createdTime ?? currentTime,
    linkedSourcesMinimal,
    userId,
    workflowType,
  };
}

function getSourceType(
  taskGroupSource: TaskGroupLinkedSourceObjectType,
): TaskGroupSourceTypeApi {
  if (isProject(taskGroupSource)) {
    return TaskGroupSourceTypeApi.PROJECT;
  }
  if (isGroup(taskGroupSource)) {
    return TaskGroupSourceTypeApi.GROUP;
  }
  if (isUser(taskGroupSource)) {
    return TaskGroupSourceTypeApi.USER;
  }
  if (isBid(taskGroupSource)) {
    return TaskGroupSourceTypeApi.BID;
  }
  assertNever(
    taskGroupSource,
    'Unexpected TaskGroup source received. Please only use one of the source types' +
      ' defined in TaskGroupSourceTypeApi, or update getSourceType if you have created a new one',
  );
}

function isProject(source: TaskGroupLinkedSourceObjectType): source is Project {
  return 'budget' in source;
}

function isGroup(source: TaskGroupLinkedSourceObjectType): source is Group {
  return 'groupType' in source;
}

function isUser(source: TaskGroupLinkedSourceObjectType): source is User {
  return 'username' in source;
}

function isBid(source: TaskGroupLinkedSourceObjectType): source is Bid {
  return 'bidderId' in source;
}
