import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  DriveFileEntityContextTypeApi,
  DriveFilePublicPermissionApi,
  DriveFileTypeApi,
} from 'api-typings/drive/drive';
import type { DriveFile } from './drive-files.model';

export interface GenerateDriveFileOptions {
  readonly contextId: number;
  readonly contextType: DriveFileEntityContextTypeApi;
  readonly displayName?: string;
  readonly fileSize?: number;
  readonly fileType?: DriveFileTypeApi;
  readonly id?: number;
  readonly ownerId: number;
  readonly publicPermission?: DriveFilePublicPermissionApi;
  readonly timeCreated?: number;
}

export function generateDriveFileObject({
  id = generateId(),
  contextId,
  contextType,
  displayName = 'test file',
  fileSize = 100,
  fileType = DriveFileTypeApi.IMAGE,
  ownerId,
  publicPermission = DriveFilePublicPermissionApi.PUBLIC_READ,
  timeCreated = Date.now(),
}: GenerateDriveFileOptions): DriveFile {
  return {
    id,
    contextId,
    contextType,
    displayName,
    fileSize,
    fileType,
    ownerId,
    publicPermission,
    timeCreated,
  };
}

export function projectViewPageFileContext(
  projectId: number,
): Pick<GenerateDriveFileOptions, 'contextId' | 'contextType'> {
  return {
    contextId: projectId,
    contextType: DriveFileEntityContextTypeApi.PROJECT,
  };
}
