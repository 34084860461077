import type { ContestUserReviewRole } from './contest-user-has-given-feedback.backend-model';
import type { ContestUserHasGivenFeedback } from './contest-user-has-given-feedback.model';

export interface GenerateContestUserHasGivenFeedbackOptions {
  readonly feedbackLeft: boolean;
  readonly entryId: number;
  readonly toUserId: number;
  readonly fromUserId: number;
  readonly role: ContestUserReviewRole;
}

export function generateContestUserHasGivenFeedbackObject({
  feedbackLeft,
  entryId,
  toUserId,
  fromUserId,
  role,
}: GenerateContestUserHasGivenFeedbackOptions): ContestUserHasGivenFeedback {
  if (toUserId === fromUserId) {
    throw new Error('Review giver and recipient should not be the same');
  }
  return {
    id: `${entryId}-${toUserId}`,
    feedbackLeft,
    entryId,
    toUserId,
    fromUserId,
    role,
  };
}
