import { Injectable } from '@angular/core';
import type { PermissionState } from '@capacitor/core';
import type { Interface } from '@freelancer/types';
import type { Observable } from 'rxjs';
import { of, ReplaySubject } from 'rxjs';
import type {
  BackgroundGeolocationConfig,
  BackgroundGeolocationStatus,
  BackgroundGeolocationTask,
  Geolocation,
  GeolocationOptions,
  GeolocationPosition,
  GeolocationResponse,
} from '../geolocation.service';

/*
 * A service wrapping the web platform Geolocation API, and providing
 * background geolocation capabilities in native contexts (i.e. when the app is
 * installed as a native app).
 */
@Injectable({
  providedIn: 'root',
})
export class GeolocationTesting implements Interface<Geolocation> {
  /**
   * Get the user current position
   *
   * /!\ Always request access to location on a user gesture, Never call that
   * from a lifecycle hook at the request access window won't pop up.
   */
  getCurrentPosition(
    options: GeolocationOptions = {},
  ): Promise<GeolocationResponse> {
    return Promise.resolve({
      status: 'success',
      position: {
        coords: {
          latitude: 52.520_007,
          longitude: 13.404_954,
        },
        timestamp: 1_608_030_812_651,
      },
    });
  }

  /**
   * Watch the user location
   *
   * Location tracking will be paused when the app goes into background. Use
   * watchPositionBackground if you want to keep tracking the user position
   * while the app is in background.
   *
   * /!\ Always request access to location on a user gesture, Never call that
   * from a lifecycle hook at the request access window won't pop up.
   */
  watchPosition(
    options: GeolocationOptions = {},
  ): Observable<GeolocationResponse> {
    return of({
      status: 'success',
      position: {
        coords: {
          latitude: 52.520_007,
          longitude: 13.404_954,
        },
        timestamp: 1_608_030_812_651,
      },
    });
  }

  /**
   * Checks if geolocation is available
   */
  isGeolocationAvailable(): boolean {
    return true;
  }

  /**
   * Checks if background geolocation is available
   */
  canWatchPositionBackground(): boolean {
    return true;
  }

  /**
   * Watch the user location while the app is in background
   *
   * /!\  Only currently supported through Capacitor, i.e. the app must be
   * installed. Call BackgroundGeolocation::canWatchPositionBackground() must
   * to ensure this is background geotracking is supported on the device.
   */
  async watchPositionBackground(
    callback: (p: GeolocationPosition) => Promise<any>,
    config: BackgroundGeolocationConfig,
  ): Promise<BackgroundGeolocationTask> {
    const statusSubject$ = new ReplaySubject<BackgroundGeolocationStatus>(1);

    setTimeout(() => {
      callback({
        coords: {
          latitude: 52.520_007,
          longitude: 13.404_954,
        },
        timestamp: 1_608_030_812_651,
      });
    });

    statusSubject$.next({
      status: 'running',
    });

    return {
      status$: statusSubject$.asObservable(),
      stop: () => {
        statusSubject$.next({
          status: 'stopped',
        });
      },
    };
  }

  getPermissions(): Promise<PermissionState> {
    return Promise.resolve('granted');
  }
}
