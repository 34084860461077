import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  blockInitialRenderAnimation,
  slideInHorizontalAnimation,
  slideInOutHorizontalAnimation,
  slideOutHorizontalAnimation,
} from '@freelancer/animations';
import { ButtonColor } from '@freelancer/ui/button';
import { HeadingType, HeadingWeight } from '@freelancer/ui/heading';
import { TagStatusColor } from '@freelancer/ui/tag-status';
import { FontColor, FontWeight, TextSize } from '@freelancer/ui/text';
import type { DynamicDemoConfig } from '../dynamic-demo/dynamic-demo.component';

enum SlidingTab {
  ONE,
  TWO,
  THREE,
  FOUR,
}

@Component({
  selector: 'app-static-slide',
  template: `
    <fl-bit class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-bit [flMarginBottom]="'small'">
          <fl-tag-status [color]="TagStatusColor.BLUE">Beta</fl-tag-status>
        </fl-bit>

        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.XLARGE"
          [flMarginBottom]="'mid'"
        >
          Slide
        </fl-heading>

        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.LARGE"
          [flMarginBottom]="'xsmall'"
          [weight]="HeadingWeight.NORMAL"
        >
          Slide In, Out and In/Out
        </fl-heading>
        <fl-text
          [size]="TextSize.SMALL"
          [flMarginBottom]="'mid'"
        >
          Gives an impression content has changed in response to some action.
        </fl-text>

        <fl-text [flMarginBottom]="'xxxsmall'">Trigger</fl-text>
        <fl-select
          [control]="slideTriggerControl"
          [options]="slideTriggerOptions"
          [flMarginBottom]="'small'"
        ></fl-select>

        <app-dynamic-demo
          [config]="slidingConfig"
          [overflow]="false"
        >
          <app-dynamic-demo-content @blockInitialRenderAnimation>
            <fl-tabs class="TabsDemo">
              <fl-tab-item
                titleText="Slide One"
                [selected]="selectedTab === SlidingTab.ONE"
                (click)="handleTabSelect(SlidingTab.ONE)"
              ></fl-tab-item>
              <fl-tab-item
                titleText="Slide Two"
                [selected]="selectedTab === SlidingTab.TWO"
                (click)="handleTabSelect(SlidingTab.TWO)"
              ></fl-tab-item>
              <fl-tab-item
                titleText="No Animation Three"
                [selected]="selectedTab === SlidingTab.THREE"
                (click)="handleTabSelect(SlidingTab.THREE)"
              ></fl-tab-item>
              <fl-tab-item
                titleText="No Animation Four"
                [selected]="selectedTab === SlidingTab.FOUR"
                (click)="handleTabSelect(SlidingTab.FOUR)"
              ></fl-tab-item>
            </fl-tabs>
            <ng-container
              *ngIf="
                slideTriggerControl?.value === '@slideInHorizontalAnimation'
              "
            >
              <fl-bit
                class="TabContent"
                *ngIf="selectedTab === SlidingTab.ONE"
                [@slideInHorizontalAnimation]="{
                  value: undefined,
                  params: {
                    duration: slidingConfig.duration?.value,
                    delay: slidingConfig.delay?.value,
                    easing: slidingConfig.easing?.value,
                    translateX: slidingConfig.translateX?.value
                  }
                }"
              >
                <fl-text [weight]="FontWeight.BOLD">Tab One</fl-text>
                <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
              </fl-bit>
              <fl-bit
                class="TabContent"
                *ngIf="selectedTab === SlidingTab.TWO"
                [@slideInHorizontalAnimation]="{
                  value: undefined,
                  params: {
                    duration: slidingConfig.duration?.value,
                    delay: slidingConfig.delay?.value,
                    easing: slidingConfig.easing?.value,
                    translateX: slidingConfig.translateX?.value
                  }
                }"
              >
                <fl-text [weight]="FontWeight.BOLD">Tab Two</fl-text>
                <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
              </fl-bit>
            </ng-container>
            <ng-container
              *ngIf="
                slideTriggerControl?.value === '@slideOutHorizontalAnimation'
              "
            >
              <fl-bit
                class="TabContent"
                *ngIf="selectedTab === SlidingTab.ONE"
                [@slideOutHorizontalAnimation]="{
                  value: undefined,
                  params: {
                    duration: slidingConfig.duration?.value,
                    delay: slidingConfig.delay?.value,
                    easing: slidingConfig.easing?.value,
                    translateX: slidingConfig.translateX?.value
                  }
                }"
              >
                <fl-text [weight]="FontWeight.BOLD">Tab One</fl-text>
                <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
              </fl-bit>
              <fl-bit
                class="TabContent"
                *ngIf="selectedTab === SlidingTab.TWO"
                [@slideOutHorizontalAnimation]="{
                  value: undefined,
                  params: {
                    duration: slidingConfig.duration?.value,
                    delay: slidingConfig.delay?.value,
                    easing: slidingConfig.easing?.value,
                    translateX: slidingConfig.translateX?.value
                  }
                }"
              >
                <fl-text [weight]="FontWeight.BOLD">Tab Two</fl-text>
                <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
              </fl-bit>
            </ng-container>
            <ng-container
              *ngIf="
                slideTriggerControl?.value === '@slideInOutHorizontalAnimation'
              "
            >
              <fl-bit
                class="TabContent"
                *ngIf="selectedTab === SlidingTab.ONE"
                [@slideInOutHorizontalAnimation]="{
                  value: undefined,
                  params: {
                    duration: slidingConfig.duration?.value,
                    delay: slidingConfig.delay?.value,
                    easing: slidingConfig.easing?.value,
                    translateX: slidingConfig.translateX?.value
                  }
                }"
              >
                <fl-text [weight]="FontWeight.BOLD">Tab One</fl-text>
                <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
              </fl-bit>
              <fl-bit
                class="TabContent"
                *ngIf="selectedTab === SlidingTab.TWO"
                [@slideInOutHorizontalAnimation]="{
                  value: undefined,
                  params: {
                    duration: slidingConfig.duration?.value,
                    delay: slidingConfig.delay?.value,
                    easing: slidingConfig.easing?.value,
                    translateX: slidingConfig.translateX?.value
                  }
                }"
              >
                <fl-text [weight]="FontWeight.BOLD">Tab Two</fl-text>
                <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
              </fl-bit>
            </ng-container>
            <fl-bit
              class="TabContent"
              *ngIf="selectedTab === SlidingTab.THREE"
            >
              <fl-text [weight]="FontWeight.BOLD">Tab Three</fl-text>
              <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
            </fl-bit>
            <fl-bit
              class="TabContent"
              *ngIf="selectedTab === SlidingTab.FOUR"
            >
              <fl-text [weight]="FontWeight.BOLD">Tab Four</fl-text>
              <ng-container [ngTemplateOutlet]="tabContent"></ng-container>
            </fl-bit>
            <ng-template #tabContent>
              <fl-text>
                You should choose your animation trigger carefully. In this
                particular use case,
                <code>@slideInOutHorizontalAnimation</code> and
                <code>@slideOutHorizontalAnimation</code> looks really bad.
              </fl-text>
            </ng-template>
          </app-dynamic-demo-content>
        </app-dynamic-demo>
      </fl-card>
    </fl-bit>
  `,
  styleUrls: ['../../static-pages.component.scss', './slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    blockInitialRenderAnimation,
    slideInHorizontalAnimation,
    slideInOutHorizontalAnimation,
    slideOutHorizontalAnimation,
  ],
})
export class SlideComponent {
  ButtonColor = ButtonColor;
  FontColor = FontColor;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  SlidingTab = SlidingTab;
  TagStatusColor = TagStatusColor;
  TextSize = TextSize;

  selectedTab = SlidingTab.ONE;
  slidingConfig: DynamicDemoConfig = {
    duration: new FormControl(200, { nonNullable: true }),
    delay: new FormControl(0, { nonNullable: true }),
    easing: new FormControl('ease-in', { nonNullable: true }),
    translateX: new FormControl('10%', { nonNullable: true }),
  };
  slideTriggerControl = new FormControl('@slideInHorizontalAnimation', {
    nonNullable: true,
  });
  slideTriggerOptions: readonly string[] = [
    '@slideInHorizontalAnimation',
    '@slideOutHorizontalAnimation',
    '@slideInOutHorizontalAnimation',
  ];

  handleTabSelect(tab: SlidingTab): void {
    this.selectedTab = tab;
  }
}
