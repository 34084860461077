import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FreelancerBreakpointValues } from '@freelancer/ui/breakpoints';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { HeadingColor, HeadingType } from '@freelancer/ui/heading';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { LinkColor } from '@freelancer/ui/link';
import { StickyBehaviour } from '@freelancer/ui/sticky';
import { TextSize } from '@freelancer/ui/text';
import { ViewHeaderType } from '@freelancer/ui/view-header';
import { CalloutColor } from './callout-color';
import type { CalloutSize } from './callout-size';
import type {
  CalloutContentComponentInterface,
  CalloutPosition,
} from './callout.types';

// TODO: T267853 - Need animations for fade-in-out.
// Ref: TODO
@Component({
  selector: 'fl-callout-content',
  template: `
    <div
      class="CalloutBody WebappCompatWrapper"
      #calloutBody
      (click)="handleCalloutBodyClick()"
      (mouseenter)="handleCalloutBodyMouseenter()"
      (mouseleave)="handleCalloutBodyMouseleave()"
      [ngStyle]="{ zIndex }"
      [attr.data-position]="placement"
      [attr.data-edge-to-edge]="edgeToEdge"
      [attr.data-size]="size"
      [attr.data-color]="color"
      [attr.data-hide-arrow]="!shouldShowArrow"
      [attr.data-arrow-offset]="arrowOffset"
      [attr.data-mobile-fullscreen]="mobileFullscreen"
      [attr.data-mobile-close-button]="mobileCloseButton"
      [attr.tabindex]="0"
    >
      <div
        #calloutArrow
        class="CalloutArrow"
        [flHide]="!shouldShowArrow"
      ></div>
      <fl-link
        class="CloseButton"
        *ngIf="shouldShowCloseButton"
        [color]="LinkColor.INHERIT"
        [flHideMobile]="true"
        (click)="handleCloseButtonClick()"
      >
        <fl-icon
          [name]="'ui-close'"
          [color]="
            color === CalloutColor.LIGHT
              ? IconColor.FOREGROUND
              : IconColor.WHITE
          "
          [size]="IconSize.SMALL"
          [hoverAnimation]="'spin-and-fade'"
        ></fl-icon>
      </fl-link>
      <div
        *ngIf="mobileFullscreen"
        [flShowMobile]="true"
      >
        <div
          class="CalloutHeader"
          [flSticky]="true"
          [flStickyStatic]="true"
          [flStickyBehaviour]="StickyBehaviour.ALWAYS"
        >
          <fl-view-header
            [type]="ViewHeaderType.CLOSE"
            [heading]="mobileHeaderTitle"
            (close)="handleCloseButtonClick()"
          ></fl-view-header>

          <fl-container>
            <fl-toast-alert-container></fl-toast-alert-container>
          </fl-container>
        </div>
      </div>
      <div
        class="CalloutContent"
        [class.OverflowContent]="overflowContent"
      >
        <ng-content></ng-content>
      </div>
      <div
        *ngIf="!mobileFullscreen && mobileCloseButton"
        class="WrapperCloseButtonMobile"
        [flShowMobile]="true"
      >
        <fl-button
          i18n="Close callout button"
          [color]="ButtonColor.DEFAULT"
          [size]="ButtonSize.SMALL"
          (click)="handleCloseButtonClick()"
        >
          Close
        </fl-button>
      </div>
    </div>
  `,
  styleUrls: ['./callout-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalloutContentComponent
  implements CalloutContentComponentInterface
{
  ButtonColor = ButtonColor;
  ButtonSize = ButtonSize;
  CalloutColor = CalloutColor;
  HeadingColor = HeadingColor;
  HeadingType = HeadingType;
  IconColor = IconColor;
  IconSize = IconSize;
  LinkColor = LinkColor;
  StickyBehaviour = StickyBehaviour;
  TextSize = TextSize;
  ViewHeaderType = ViewHeaderType;

  zIndex?: number;

  edgeToEdge: boolean;
  hideArrow: boolean;
  arrowOffset: number;
  hideCloseButton: boolean;
  hover: boolean;
  mobileCloseButton: boolean;
  mobileHeaderTitle: string;
  placement: string;
  size?: CalloutSize;
  overflowContent: boolean;

  isMobile: boolean;
  shouldShowArrow: boolean;
  shouldShowCloseButton: boolean;

  @HostBinding('attr.data-color') color: CalloutColor;
  @HostBinding('attr.data-mobile-fullscreen') mobileFullscreen: boolean;

  @Output() bodyClick = new EventEmitter<void>();
  @Output() bodyMouseenter = new EventEmitter<void>();
  @Output() bodyMouseleave = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  @ViewChild('calloutBody') calloutBody: ElementRef<HTMLDivElement>;

  @ViewChild('calloutArrow', { read: ElementRef })
  calloutArrow: ElementRef;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  getElementBoundingRect(): DOMRect {
    return this.calloutBody.nativeElement.getBoundingClientRect();
  }

  handleCalloutBodyClick(): void {
    this.bodyClick.emit();
  }

  handleCalloutBodyMouseenter(): void {
    if (!this.isMobile) {
      this.bodyMouseenter.emit();
    }
  }

  handleCalloutBodyMouseleave(): void {
    if (!this.isMobile) {
      this.bodyMouseleave.emit();
    }
  }

  handleCloseButtonClick(): void {
    this.close.emit();
  }

  setOptions(options: {
    color: CalloutColor;
    edgeToEdge: boolean;
    mobileCloseButton: boolean;
    mobileFullscreen: boolean;
    mobileHeaderTitle: string;
    hideArrow: boolean;
    hideCloseButton: boolean;
    hover: boolean;
    overflowContent: boolean;
    size?: CalloutSize;
  }): void {
    this.color = options.color;
    this.edgeToEdge = options.edgeToEdge;
    this.hideArrow = options.hideArrow;
    this.hideCloseButton = options.hideCloseButton;
    this.hover = options.hover;
    this.overflowContent = options.overflowContent;
    this.mobileCloseButton = options.mobileCloseButton;
    this.mobileFullscreen = options.mobileFullscreen;
    this.mobileHeaderTitle = options.mobileHeaderTitle;
    this.size = options.size;

    this.setMobileOptions();
  }

  setPlacement(placement: CalloutPosition, triggerElement: HTMLElement): void {
    const { anchor } = placement;
    const position = anchor === 'overlayX' ? 'overlayY' : 'overlayX';
    // convert into an `anchor-relative` string for css parsing
    this.placement = `${placement[anchor]}-${placement[position]}`;
    // super super hacky: we use some small offsets (4px or 8px) to make things look nice
    // any larger offset probably means we're shifting the callout across some amount
    // TODO: T267853 - maybe we can pass this via a `PanelClass` instead?
    this.arrowOffset =
      Math.abs(placement.offsetX ?? 0) > 10
        ? placement.offsetX ?? 0
        : Math.abs(placement.offsetY ?? 0) > 10
        ? placement.offsetY ?? 0
        : 0;

    this.setArrowPosition(
      placement[anchor],
      placement[position],
      triggerElement,
    );

    this.changeDetectorRef.markForCheck();
  }

  private setArrowPosition(
    anchor: string,
    position: string,
    triggerElement: HTMLElement,
  ): void {
    const { nativeElement } = this.calloutArrow;

    // Perform style cleanup before re-applying a new position
    this.renderer.removeStyle(nativeElement, 'left');
    this.renderer.removeStyle(nativeElement, 'right');
    this.renderer.removeStyle(nativeElement, 'top');
    this.renderer.removeStyle(nativeElement, 'bottom');

    // All anchor orientation that has center positioning is
    // handled in callout-content.component.scss
    if (position === 'center') {
      return;
    }

    // Dynamically center the arrow depending on
    // the triggerElement's dimension
    const { offsetHeight, offsetWidth } = triggerElement;

    let arrowOrientation = '';
    let arrowPosition = 0;

    if (anchor === 'top' || anchor === 'bottom') {
      arrowOrientation = position === 'start' ? 'left' : 'right';
      arrowPosition = offsetWidth / 2;
    }

    if (anchor === 'start' || anchor === 'end') {
      arrowOrientation = position;
      arrowPosition = offsetHeight / 2;
    }

    this.renderer.setStyle(
      nativeElement,
      arrowOrientation,
      `${arrowPosition}px`,
    );
  }

  private setMobileOptions(): void {
    this.isMobile =
      window.innerWidth < parseInt(FreelancerBreakpointValues.TABLET, 10);

    this.shouldShowArrow = !this.hideArrow;
    this.shouldShowCloseButton = !this.hideCloseButton && !this.hover;

    // Mobile overrides to standardize dialog UIs
    if (this.isMobile) {
      this.shouldShowArrow = false;
      this.shouldShowCloseButton =
        this.mobileFullscreen || this.shouldShowCloseButton;
    }
  }
}
