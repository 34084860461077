import { generateId } from '@freelancer/datastore/testing/helpers';
import { PaymentShareEntityTypeApi } from 'api-typings/users/users';
import type { PaymentShareInterest } from './payment-share-interests.model';

export interface GeneratePaymentShareInterestOptions {
  readonly entityId: number;
  readonly entityType?: PaymentShareEntityTypeApi;
}

/** Returns a payment share interest object. */
export function generatePaymentShareInterestObject({
  entityId,
  entityType = PaymentShareEntityTypeApi.USER,
}: GeneratePaymentShareInterestOptions): PaymentShareInterest {
  const now = Date.now();
  return {
    id: generateId(),
    entityId,
    entityType,
    dateAdded: now,
  };
}
