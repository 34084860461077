import { generateId } from '@freelancer/datastore/testing/helpers';
import type { SupportFeedbackIsAllowed } from './support-feedback-is-allowed.model';

export interface GenerateSupportFeedbackIsAllowedOptions {
  readonly supportTicketId?: number;
  readonly dateResolved?: number;
  readonly isAllowed?: boolean;
}

export function generateSupportFeedbackIsAllowedObject({
  isAllowed = true,
  supportTicketId = generateId(),
  dateResolved = Date.now(),
}: GenerateSupportFeedbackIsAllowedOptions): SupportFeedbackIsAllowed {
  return {
    id: generateId(),
    isAllowed,
    supportTicketId,
    dateResolved,
  };
}
