import {
  generateId,
  generateIntegersInRange,
  randomiseList,
} from '@freelancer/datastore/testing/helpers';
import type { AgentOwnerApi } from 'api-typings/support/support';
import {
  AgentOwnerTypeApi,
  AgentStateApi,
  SupportTypeApi,
} from 'api-typings/support/support';
import type { Agent } from './agents.model';

export interface GenerateAgentsOptions {
  readonly userIds?: readonly number[];
}

export interface GenerateAgentOptions {
  readonly id?: number;
  readonly userId?: number;
  readonly owner?: AgentOwnerApi;
  readonly type?: SupportTypeApi;
  readonly createTime?: number;
  readonly state?: AgentStateApi;
}

export function generateAgentObjects({
  userIds,
}: GenerateAgentsOptions): readonly Agent[] {
  const generatedUserIds = userIds ?? generateRandomUserIds();

  return generatedUserIds.map(userId =>
    generateAgentObject({
      userId,
    }),
  );
}

export function generateAgentObject({
  id = generateId(),
  userId = generateId(),
  owner = generateAgentOwner(),
  type = generateSupportType(),
  createTime = Date.now(),
  state = generateAgentState(),
}: GenerateAgentOptions = {}): Agent {
  return {
    id,
    userId,
    owner,
    type,
    createTime,
    state,
  };
}

function generateAgentOwner(
  id = generateId(),
  username = 'testusername',
  owner_type = generateAgentOwnerType(),
): AgentOwnerApi {
  return {
    id,
    username,
    owner_type,
  };
}

function generateAgentOwnerType(
  type = AgentOwnerTypeApi.FREELANCER_ADMIN,
): AgentOwnerTypeApi {
  return type;
}

export function generateSupportType(type = SupportTypeApi.EQA): SupportTypeApi {
  return type;
}

function generateAgentState(state = AgentStateApi.AVAILABLE): AgentStateApi {
  return state;
}

function generateRandomUserIds(count = 5): readonly number[] {
  return randomiseList(
    generateIntegersInRange(100, count * 100, count),
    'userIds',
  );
}
