import { animate, animation, style } from '@angular/animations';

export const fadeOut = animation(
  [
    style({
      opacity: 1,
    }),
    animate(
      '{{ duration }}ms {{ delay }}ms {{ easing }}',
      style({
        opacity: 0,
      }),
    ),
  ],
  {
    params: {
      duration: '200',
      delay: '0',
      easing: 'ease-out',
    },
  },
);
