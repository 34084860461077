import type { Banner, CreditCardVerificationBanner } from './banners.model';
import { BannerId, BannerType } from './banners.model';

export interface GenerateBannerOptions {
  readonly verificationBanner?: Banner;
}

const creditCardVerificationBanner: CreditCardVerificationBanner = {
  id: BannerId.CREDIT_CARD_VERIFICATION,
  type: BannerType.VERIFICATION,
};

export function generateBannerObjects({
  verificationBanner,
}: GenerateBannerOptions = {}): readonly Banner[] {
  return [
    verificationBanner ?? creditCardVerificationBanner,
    {
      id: BannerId.MISSING_FIRST_MILESTONE,
      type: BannerType.DASHBOARD,
      projectUrl: 'Graphic-Design/Project-for-STF-4932641',
      sellerDisplayName: 'STF2089FL',
      projectTitle: 'Project for STF2089FL',
    },
    {
      id: BannerId.NONE,
      type: BannerType.PROFILE_WIDGET_UPSELL,
    },
  ];
}
