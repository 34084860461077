import { generateId } from '@freelancer/datastore/testing/helpers';
import { TimeUnitApi } from 'api-typings/common/common';
import { DowngradeStatusApi } from 'api-typings/memberships/memberships_types';
import { generateIntroMembershipPlan } from '../membership-packages/membership-packages.seed';
import type { Package } from '../recommended-membership/recommended-membership.model';
import type { MembershipDowngrade } from './membership-downgrades.model';

export interface GenerateMembershipDowngradesOptions {
  readonly amount: number;
  readonly amountWithTax?: number;
  readonly durationType?: TimeUnitApi;
  readonly downgradePackage?: Package;
  readonly currency?: number;
}

export function generateMembershipDowngradesObject({
  amount,
  amountWithTax,
  durationType = TimeUnitApi.MONTH,
  downgradePackage = generateIntroMembershipPlan(),
  currency = 1,
}: GenerateMembershipDowngradesOptions): MembershipDowngrade {
  const now = Date.now();

  return {
    id: generateId(),
    timeCreated: now,
    status: DowngradeStatusApi.PENDING,
    packageId: downgradePackage.id,
    duration: {
      cycle: 1,
      id: 1,
      type: durationType,
    },
    failureCount: 0,
    autoRenew: false,
    periodId: 1,
    quantity: 1,
    isTrial: false,
    price: {
      id: 1,
      amount,
      amountWithTax,
      contractQuantity: 1,
      currencyId: currency,
      durationId: 1,
      durationType,
      durationCycle: 1,
      packageId: downgradePackage.id,
      timeValidStart: now,
    },
    package: downgradePackage,
  };
}
