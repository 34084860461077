/**
 * Modified version of Angular's NumberValueAccessor that returns NaN instead of null when the input
 * is empty.
 * https://github.com/angular/angular/blob/14.1.x/packages/forms/src/directives/number_value_accessor.ts
 */
import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlType } from '../input.types';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'fl-input input[type=number][formControl]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => NumberValueAccessorDirective),
      multi: true,
    },
  ],
})
export class NumberValueAccessorDirective implements ControlValueAccessor {
  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef<HTMLInputElement>,
  ) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: any): void {
    this.onChange(value);
  }

  @HostListener('blur')
  onBlur(): void {
    this.onTouched();
  }

  onChange = (_: any): void => {
    // Do nothing, registerOnChange will set this
  };

  onTouched = (): void => {
    // Do nothing, registerOnTouched will set this
  };

  writeValue(value: number): void {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = value === null ? '' : value;
    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'value',
      normalizedValue,
    );
  }

  registerOnChange(fn: (_: number) => void): void {
    const { nativeElement } = this._elementRef;

    this.onChange = value => {
      fn(
        nativeElement.getAttribute('controlType') === ControlType.STRING
          ? value
          : value === ''
          ? NaN
          : parseFloat(value),
      );
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
