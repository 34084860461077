import { cryptocurrencySkill } from '../skills';
import type {
  DeloitteFreelancerBidRestrictions,
  FreelancerBidRestrictions,
} from './bid-restrictions.model';

export interface GenerateBidRestrictionsOptions {
  readonly projectId: number;
  readonly openForBidding?: boolean;
  readonly freelancerHasBalance?: boolean;
  readonly freelancerPreferredRequirementMet?: boolean;
  readonly premiumProjectEligibleStatus?: boolean;
  readonly premiumVerifiedProjectEligibleStatus?: boolean;
  readonly premiumVerifiedJobEligibleStatus?: boolean;
  readonly featuredPremiumProjectEligibleStatus?: boolean;
  readonly premiumBidAmountEligibleStatus?: boolean;
  readonly deloitteRequirements?: DeloitteFreelancerBidRestrictions;
}

export function generateBidRestrictionsObject({
  projectId,
  openForBidding = true,
  freelancerHasBalance = true,
  freelancerPreferredRequirementMet = true,
  premiumProjectEligibleStatus = true,
  premiumVerifiedProjectEligibleStatus = true,
  premiumVerifiedJobEligibleStatus = true,
  featuredPremiumProjectEligibleStatus = true,
  premiumBidAmountEligibleStatus = true,
  deloitteRequirements,
}: GenerateBidRestrictionsOptions): FreelancerBidRestrictions {
  return {
    id: projectId,
    openForBidding,
    freelancerHasBalance,
    freelancerPreferredRequirementMet,
    premiumProjectEligible: {
      status: premiumProjectEligibleStatus,
      minimumReviewCount: 5,
      thresholdAmountUsd: 1500,
    },
    premiumVerifiedProjectEligible: {
      status: premiumVerifiedProjectEligibleStatus,
      thresholdAmountUsd: 2500,
    },
    premiumVerifiedJobEligible: {
      status: premiumVerifiedJobEligibleStatus,
      jobsRequired: [cryptocurrencySkill()],
    },
    featuredPremiumProjectEligible: {
      status: featuredPremiumProjectEligibleStatus,
      minimumReviewCount: 5,
    },
    premiumBidAmountEligible: {
      status: premiumBidAmountEligibleStatus,
      thresholdAmountUsd: 1500,
    },
    deloitteRequirements,
  };
}

export function deloitteBidRestriction({
  hasRequiredCertifications = true,
  hasRequiredPractices = true,
  hasRequiredOfferingPortfolios = true,
  hasRequiredGigWorkerLevel = true,
  isItarCompatible = true,
  hasCompatibleIndependenceRuleset = true,
  hasCompatibleProjectGroupMembership = true,
}: {
  readonly hasRequiredCertifications?: boolean;
  readonly hasRequiredPractices?: boolean;
  readonly hasRequiredOfferingPortfolios?: boolean;
  readonly hasRequiredGigWorkerLevel?: boolean;
  readonly isItarCompatible?: boolean;
  readonly hasCompatibleIndependenceRuleset?: boolean;
  readonly hasCompatibleProjectGroupMembership?: boolean;
} = {}): Pick<GenerateBidRestrictionsOptions, 'deloitteRequirements'> {
  return {
    deloitteRequirements: {
      hasRequiredCertifications,
      hasRequiredPractices,
      hasRequiredOfferingPortfolios,
      hasRequiredGigWorkerLevel,
      isItarCompatible,
      hasCompatibleIndependenceRuleset,
      hasCompatibleProjectGroupMembership,
    },
  };
}
