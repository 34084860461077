import { Injectable } from '@angular/core';
import { Datastore } from '@freelancer/datastore';
import type {
  CampaignName,
  CampaignsCollection,
  UserInteractionsCollection,
} from '@freelancer/datastore/collections';
import { ModalService } from '@freelancer/ui';
import { ModalSize } from '@freelancer/ui/modal';
import { isDefined } from '@freelancer/utils';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class UserFeedback {
  constructor(
    private modalService: ModalService,
    private datastore: Datastore,
  ) {}

  promptForFeedback(
    campaignInternalName: CampaignName,
    campaignDisplayName: string,
  ): void {
    const campaign$ = this.datastore
      .collection<CampaignsCollection>('campaigns', query =>
        query.where('internalName', '==', campaignInternalName),
      )
      .valueChanges()
      .pipe(
        map(campaign => (campaign.length > 0 ? campaign[0] : undefined)),
        filter(isDefined),
      );

    const hasSeenFeedbackCampaign$ = this.datastore
      .collection<UserInteractionsCollection>('userInteractions', query =>
        campaign$.pipe(
          map(campaign =>
            campaign
              ? query
                  .where('eventName', '==', 'campaign')
                  .where('eventId', '==', campaign.id)
              : query.null(),
          ),
        ),
      )
      .valueChanges()
      .pipe(map(events => !!events[0]));

    const isCampaignOngoing$ = campaign$.pipe(
      map(campaign => !campaign.endDate || campaign.endDate >= Date.now()),
    );

    const feedbackModalSubscription = combineLatest([
      hasSeenFeedbackCampaign$,
      isCampaignOngoing$,
      campaign$,
    ]).subscribe(([hasSeenFeedbackCampaign, isCampaignOngoing, campaign]) => {
      if (!hasSeenFeedbackCampaign && isCampaignOngoing && campaign) {
        const feedbackModal = this.modalService.open('UserFeedbackModal', {
          inputs: {
            campaignId: campaign.id,
            campaignName: campaignDisplayName,
          },
          size: ModalSize.XLARGE,
          mobileFullscreen: true,
        });

        feedbackModal.afterOpen().then(_ => {
          this.saveUserInteraction(campaign.id).then(__ => {
            feedbackModalSubscription.unsubscribe();
          });
        });
      }
    });
  }

  private async saveUserInteraction(campaignId: number): Promise<void> {
    this.datastore
      .collection<UserInteractionsCollection>('userInteractions')
      .push({
        eventName: 'campaign',
        eventId: campaignId,
      });
  }
}
