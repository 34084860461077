import type { User } from '../users/users.model';
import type { EncodedUrl } from './encoded-url.model';
import { EncodedUrlType } from './encoded-url.model';

export interface GenerateEncodedUrlOptions {
  readonly userId: number;
  readonly url: string;
  readonly type?: EncodedUrlType;
}

export function generateEncodedUrlObject({
  userId,
  url,
  type = EncodedUrlType.URL_SAFE_ENCODE,
}: GenerateEncodedUrlOptions): EncodedUrl {
  return {
    id: `${url}-${type}`,
    userId,
    inputUrl: url,
    url: btoa(url), // Not what the real backend does
    type,
  };
}

export function encodedUrlForUserProfile(
  user: User,
): Pick<GenerateEncodedUrlOptions, 'url'> {
  return {
    url: user.profileUrl,
  };
}
