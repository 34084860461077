import type { GroupActions } from './group-actions.model';

export interface GenerateGroupActionsOptions {
  readonly userId: number;
  readonly createGroup?: boolean;
}

export function generateGroupActionsObject({
  userId,
  createGroup = false,
}: GenerateGroupActionsOptions): readonly GroupActions[] {
  return [
    {
      id: userId,
      createGroup,
    },
  ];
}
