import { ChangeDetectionStrategy, Component } from '@angular/core';

// Contains custom actions, usually those that will trigger animations
// e.g adding items, toggling display, etc.
@Component({
  selector: 'app-dynamic-demo-action',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./dynamic-demo-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDemoActionComponent {}
