import type { UserRequiresPhoneVerification } from './user-requires-phone-verification.model';

export interface GenerateUserRequiresPhoneVerificationOptions {
  readonly userId: string;
  readonly userRequiresPhoneVerification: boolean;
}

export function generateUserRequiresPhoneVerificationObject({
  userId,
  userRequiresPhoneVerification,
}: GenerateUserRequiresPhoneVerificationOptions): UserRequiresPhoneVerification {
  return {
    id: userId,
    userRequiresPhoneVerification,
  };
}
