import { generateId } from '@freelancer/datastore/testing/helpers';
import { TimeUnitApi } from 'api-typings/common/common';
import type { MembershipRenewal } from './membership-renewals.model';

export interface GenerateMembershipRenewalsOptions {
  readonly userId: number;
  readonly packageId?: number;
  readonly durationType?: TimeUnitApi;
}

export function generateMembershipRenewalsObjects({
  userId,
  packageId = 7, // Intro
  durationType = TimeUnitApi.MONTH,
}: GenerateMembershipRenewalsOptions): readonly MembershipRenewal[] {
  return [
    {
      id: generateId(),
      period: {
        id: generateId(),
        packageId,
        timeStarted: Date.now(),
        userId,
      },
      timeOptedIn: Date.now(),
      duration: {
        cycle: 1,
        id: 1,
        type: durationType,
      },
      failureCount: 0,
    },
  ];
}
