import { generateId } from '@freelancer/datastore/testing/helpers';
import type { CurrencyAjax } from '../currencies/currencies.model';
import type {
  Package,
  RecommendedMembershipPackage,
} from './recommended-membership.model';

interface GeneratePackageOptions {
  readonly id?: number;
  readonly internalName?: string;
  readonly displayName?: string;
  readonly enabled?: boolean;
  readonly categoryId?: number;
  readonly order?: number;
}

export interface GenerateRecommendedMembershipOptions {
  readonly packageObject?: Package;
  readonly currencyDetails?: CurrencyAjax;
  readonly displayPrice?: number;
  readonly displayNameFirstUpper?: string;
  readonly isAnnual?: boolean;
  readonly isMonthly?: boolean;
  readonly isTrial?: boolean;
  readonly level?: number;
  readonly monthlyPrice?: string;
  readonly monthlyPriceDecimalPart?: string;
  readonly monthlyPriceIntegerPart?: number;
  readonly priceAfterTax?: number;
  readonly taxName?: string;
  readonly trialType?: string | boolean;
  readonly withTax?: boolean;
}

function generatePackageObject({
  id = generateId(),
  internalName = 'plan25',
  displayName = 'plus',
  enabled = true,
  categoryId = 1,
  order = 123,
}: GeneratePackageOptions = {}): Package {
  return {
    id,
    internalName,
    displayName,
    enabled,
    categoryId,
    order,
  };
}

export function generateRecommendedMembershipObject({
  packageObject = generatePackageObject(),
  currencyDetails = {
    id: '6',
    sign: '$',
  } as CurrencyAjax,
  displayPrice = 14,
  displayNameFirstUpper = 'Plus',
  isAnnual = false,
  isMonthly = true,
  isTrial = true,
  level = 3,
  monthlyPrice = '14.00',
  monthlyPriceDecimalPart = '00',
  monthlyPriceIntegerPart = 14,
  priceAfterTax = 14,
  taxName = '',
  trialType = 'pv',
  withTax = false,
}: GenerateRecommendedMembershipOptions = {}): RecommendedMembershipPackage {
  return {
    ...packageObject,
    currencyDetails,
    displayPrice,
    displayNameFirstUpper,
    isAnnual,
    isMonthly,
    isTrial,
    level,
    monthlyPrice,
    monthlyPriceDecimalPart,
    monthlyPriceIntegerPart,
    priceAfterTax,
    taxName,
    trialType,
    withTax,
  };
}
