import { ProjectStatusFromBids } from '@freelancer/project-status';
import type { Bid } from '../bids';
import type { Project } from '../projects';
import type { ManageViewPastBid } from './manage-view-past-bids.model';

export interface GenerateManageViewPastBidOptions {
  readonly bids: readonly Bid[];
  readonly projectStatus?:
    | ProjectStatusFromBids.COMPLETE
    | ProjectStatusFromBids.INCOMPLETE
    | ProjectStatusFromBids.CANCELED
    | ProjectStatusFromBids.IN_PROGRESS
    | ProjectStatusFromBids.EXPIRED
    | ProjectStatusFromBids.REJECTED
    | ProjectStatusFromBids.REVOKED;
  readonly project: Project;
}

export function generateManageViewPastBidObjects({
  bids = [],
  project,
  projectStatus,
}: GenerateManageViewPastBidOptions): readonly ManageViewPastBid[] {
  return bids.map(bid => {
    if (!bid.projectOwnerId) {
      throw new Error('Missing projectOwnerId');
    }

    const bidStatus = projectStatus || ProjectStatusFromBids.COMPLETE;

    return {
      id: bid.id,
      projectId: bid.projectId,
      employerId: bid.projectOwnerId,
      bidAmount: bid.amount,
      bidPlacedDate: bid.submitDate,
      recruiter: false,
      awardTime: bid.timeAwarded,
      bidStatus,
      timeAccepted: bid.timeAccepted || Date.now(),
      projectTimeSubmitted: project.timeSubmitted,
      projectDeleted: project.deleted,
      projectName: project.title,
      milestoneCount: 0,
      milestoneAmount: 0,
    };
  });
}
