import { generateId } from '@freelancer/datastore/testing/helpers';
import { assertNever } from '@freelancer/utils';
import { TaskGroupSectionStatusApi } from 'api-typings/tasklist/tasklist';
import { HPTaskSectionName } from '../hp';
import type { TaskGroupSection } from './task-group-section.model';
import { TaskSectionPreset } from './task-group-section.model';

export interface GenerateTaskSectionObjectOptions {
  readonly taskGroupId?: number;
  readonly name?: string;
  readonly status?: TaskGroupSectionStatusApi;
  readonly createdTime?: number;
  readonly isArchiveSection?: boolean;
}

export function generateTaskGroupSectionObject({
  taskGroupId = generateId(),
  name = 'To-do ',
  status = TaskGroupSectionStatusApi.OPEN,
  createdTime = Date.now(),
  isArchiveSection = false,
}: GenerateTaskSectionObjectOptions): TaskGroupSection {
  return {
    id: generateId(),
    taskGroupId,
    name,
    status,
    createdTime,
    isArchiveSection,
  };
}

export function generateTaskGroupSectionObjects(
  taskGroupSections: readonly GenerateTaskSectionObjectOptions[],
): readonly TaskGroupSection[] {
  return taskGroupSections.map(generateTaskGroupSectionObject);
}

// Get the task sections to create for a task group based on a preset
export function getTaskSectionPresets(
  taskSectionPreset: TaskSectionPreset,
  taskGroupId: number,
): readonly GenerateTaskSectionObjectOptions[] {
  switch (taskSectionPreset) {
    case TaskSectionPreset.DEFAULT:
      return ['To-do', 'In Progress', 'Done'].map(name => ({
        name,
        taskGroupId,
      }));
    case TaskSectionPreset.HP:
      return [
        HPTaskSectionName.ACCEPTED,
        HPTaskSectionName.AWAITING_DELIVERY,
        HPTaskSectionName.PARTS_RECEIVED,
        HPTaskSectionName.PARTS_CONFIRMED,
        HPTaskSectionName.CUSTOMER_RESCHEDULE,
        HPTaskSectionName.CUSTOMER_DELAY,
        HPTaskSectionName.CUSTOMER_DELAY,
        HPTaskSectionName.CONFIRM_DATE_TIME,
        HPTaskSectionName.TRAVELING,
        HPTaskSectionName.ONSITE,
        HPTaskSectionName.ONSITE_POST_REPAIR_PHOTO,
        HPTaskSectionName.PROBLEM_RESOLUTION,
        HPTaskSectionName.CUSTOMER_SIGNOFF,
        HPTaskSectionName.PARTS_RETURN,
        HPTaskSectionName.COMPLETE,
      ].map(name => ({
        name,
        taskGroupId,
      }));
    default:
      assertNever(taskSectionPreset);
  }
}
