import { generateId } from '@freelancer/datastore/testing/helpers';
// TODO: T235847 This requires deep import in order to avoid transpiling
// html-renderer.module.ts from index.ts.
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type { RichTextElement } from '@freelancer/html-renderer/html-elements.model';
// eslint-disable-next-line local-rules/validate-freelancer-imports
import { HtmlElementType } from '@freelancer/html-renderer/html-elements.model';
import {
  CustomBannerEntityTypeApi,
  CustomBannerLocationApi,
  CustomBannerTextColorApi,
  CustomCtaActionApi,
  CustomCtaContextTypeApi,
} from 'api-typings/common/common';
import type { CustomBanner, CustomCta } from './custom-banners.model';

export interface GenerateCustomBannerOptions {
  readonly id?: number;
  readonly entityId: number;
  readonly entityType?: CustomBannerEntityTypeApi;
  readonly location?: CustomBannerLocationApi;
  readonly textColor?: CustomBannerTextColorApi;
  readonly closeable?: boolean;
  readonly dateCreated?: number;
  readonly startDate?: number;
  readonly endDate?: number;
  readonly title?: string;
  readonly body?: string;
  readonly backgroundImageUuid?: string;
  readonly ctas?: readonly CustomCta[];
}

export interface GenerateCustomCtaOptions {
  readonly id?: number;
  readonly contextId: number;
  readonly contextType?: CustomCtaContextTypeApi;
  readonly ctaText?: string;
  readonly ctaAction?: CustomCtaActionApi;
  readonly ctaLink?: string;
  readonly modalTitle?: string;
  readonly modalBody?: readonly RichTextElement[];
}

export function generateCustomBannerObject({
  id = generateId(),
  entityId,
  entityType = CustomBannerEntityTypeApi.ENTERPRISE,
  location = CustomBannerLocationApi.DASHBOARD,
  textColor = CustomBannerTextColorApi.DARK,
  closeable = false,
  dateCreated = new Date(2019, 3, 15).getTime(),
  startDate = new Date(2022, 3, 15).getTime(),
  endDate = new Date(2030, 3, 15).getTime(),
  title = 'Hello World',
  body = "Tap into the world's largest marketplace of professional talent with MyGigs External!",
  backgroundImageUuid,
  ctas = [],
}: GenerateCustomBannerOptions): CustomBanner {
  return {
    id,
    entityId,
    entityType,
    location,
    textColor,
    closeable,
    dateCreated,
    startDate,
    endDate,
    title,
    body,
    backgroundImageUuid,
    ctas,
  };
}

export function generateCustomCtaObject({
  id = generateId(),
  contextId,
  contextType = CustomCtaContextTypeApi.BANNER,
  ctaText = 'Find Out More',
  ctaAction = CustomCtaActionApi.LINK,
  ctaLink,
  modalTitle,
  modalBody,
}: GenerateCustomCtaOptions): CustomCta {
  return {
    id,
    contextId,
    contextType,
    ctaText,
    ctaAction,
    ctaLink,
    modalTitle,
    modalBody,
  };
}

export function linkCustomCta(): Pick<
  GenerateCustomCtaOptions,
  'ctaAction' | 'ctaLink'
> {
  return {
    ctaAction: CustomCtaActionApi.LINK,
    ctaLink: 'www.freelancer.com',
  };
}

export function modalCustomCta(): Pick<
  GenerateCustomCtaOptions,
  'ctaAction' | 'modalTitle' | 'modalBody'
> {
  return {
    ctaAction: CustomCtaActionApi.MODAL,
    modalTitle: 'custom banner modal title',
    modalBody: [
      {
        type: HtmlElementType.TEXT,
        value: 'custom banner modal body',
      },
    ],
  };
}
