import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontType, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-design-contribution',
  template: `
    <fl-card
      class="SectionCard"
      [flMarginBottom]="'mid'"
      [edgeToEdge]="true"
    >
      <fl-text
        [size]="TextSize.SMALL"
        [flMarginBottom]="'large'"
      >
        Here we specify guidelines that designers should follow when providing
        assets for product teams to implement. There are three file types that
        will be provided by designers.
      </fl-text>
      <fl-bit
        class="Section"
        [flMarginBottom]="'large'"
      >
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'xxsmall'"
        >
          1. SVG
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          SVG files should be used when designing icons or illustrations. Make
          sure to double check the list of all available icons and illustrations
          first before adding a new one. We need to remove unnecessary tags and
          attributes to keep our markup lean. Icon size should be 24px by 24px.
          Illustrations size should be 128px by 128px.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Compressing
          <fl-text
            [weight]="FontWeight.BOLD"
            [fontType]="FontType.SPAN"
          >
            SVG
          </fl-text>
          files.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                SVG images are well suited for lossless compression. To compress
                an SVG, run it through this
                <fl-link
                  [link]="'https://jakearchibald.github.io/svgomg'"
                  [size]="TextSize.XSMALL"
                >
                  tool
                </fl-link>
              </fl-bit>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Disable 'Remove viewBox' feature from the tool.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Compare the output against the original file. Sometimes the
              minified SVG gets distorted and might need to increase the
              precision to fix the shape.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              If the tool yields a different result even after increasing the
              precision, manually cleanup the unnecessary tags and attributes.
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                SVG should have a view box of
                <span class="InlineSnippet">viewBox="0 0 width height"</span>.
              </fl-bit>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                Naming convention:
                <span class="InlineSnippet">ui-name</span>
                (ie ui-bird).
              </fl-bit>
            </fl-list-item>
          </fl-list>
        </fl-text>
      </fl-bit>
      <fl-bit
        class="Section"
        [flMarginBottom]="'large'"
      >
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'xxsmall'"
        >
          2. JPG
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          JPG format is a lossy compressed file format. This makes it useful for
          storing photographs and realistic images.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'small'"
        >
          Compressing
          <fl-text
            [weight]="FontWeight.BOLD"
            [fontType]="FontType.SPAN"
          >
            JPG
          </fl-text>
          or
          <fl-text
            [weight]="FontWeight.BOLD"
            [fontType]="FontType.SPAN"
          >
            PNG
          </fl-text>
          files.
        </fl-text>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                To compress an
                <fl-text
                  [weight]="FontWeight.BOLD"
                  [fontType]="FontType.SPAN"
                >
                  JPG
                </fl-text>
                or
                <fl-text
                  [weight]="FontWeight.BOLD"
                  [fontType]="FontType.SPAN"
                >
                  PNG </fl-text
                >, run it through this
                <fl-link
                  [link]="'https://tinypng.com/'"
                  [size]="TextSize.XSMALL"
                >
                  tool
                </fl-link>
              </fl-bit>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-bit>
                New files should follow this naming convention:
                <fl-bit>
                  <span class="InlineSnippet">lowercase</span>.
                  <span class="InlineSnippet">lowercase-lowercase</span>.
                </fl-bit>
                (ie homepage-hero)
              </fl-bit>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              Compare the output against the original file. Sometimes the
              compressed files gets distorted and might need to manually reduce
              the quality/size with photoshop or sketch.
            </fl-list-item>
          </fl-list>
        </fl-text>
      </fl-bit>
      <fl-bit
        class="Section"
        [flMarginBottom]="'large'"
      >
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'xxsmall'"
        >
          3. PNG
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [flMarginBottom]="'xxxsmall'"
        >
          PNG format is a lossless compression file format. PNG is a good choice
          for storing line drawings, line art, text-heavy images, and iconic
          graphics. To compress, follow the same instructions as JPG.
        </fl-text>
      </fl-bit>
    </fl-card>
  `,
  styleUrls: [
    '../static-pages.component.scss',
    './how-to-contribute.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignContributionComponent {
  TextSize = TextSize;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  ListItemType = ListItemType;
}
