import { generateId } from '@freelancer/datastore/testing/helpers';
import type { MarketingMobileNumber as MobileNumber } from '@freelancer/users-shared';
import { MarketingMobileNumberRestrictionCodeApi } from 'api-typings/users/users';
import { addDays } from 'date-fns';
import type {
  MarketingMobileNumber,
  MarketingMobileNumberRestriction,
} from './marketing-mobile-number.model';

export function generateMarketingMobileNumberObject({
  projectId = generateId(),
  marketingMobileNumber,
  marketingMobileNumberRestriction,
}: GenerateMarketingMobileNumberGetOptions): readonly MarketingMobileNumber[] {
  return [
    {
      id: projectId,
      marketingMobileNumber,
      marketingMobileNumberRestriction,
    },
  ];
}

export interface GenerateMarketingMobileNumberGetOptions {
  readonly projectId?: number;
  readonly marketingMobileNumber?: MobileNumber;
  readonly marketingMobileNumberRestriction?: MarketingMobileNumberRestriction;
}

export function withMobileNumber(
  phoneNumber: string = '12345678',
  countryCode: string = '61',
): Pick<GenerateMarketingMobileNumberGetOptions, 'marketingMobileNumber'> {
  return {
    marketingMobileNumber: {
      phoneNumber,
      countryCode,
    },
  };
}

export function withFirstModalWarning(): Pick<
  GenerateMarketingMobileNumberGetOptions,
  'marketingMobileNumberRestriction'
> {
  return {
    marketingMobileNumberRestriction: {
      code: MarketingMobileNumberRestrictionCodeApi.USER_HAS_FIRST_WARNING_MODAL_PENALTY,
      expiryDate: undefined,
    },
  };
}

export function withSecondModalWarning(): Pick<
  GenerateMarketingMobileNumberGetOptions,
  'marketingMobileNumberRestriction'
> {
  return {
    marketingMobileNumberRestriction: {
      code: MarketingMobileNumberRestrictionCodeApi.USER_HAS_SECOND_WARNING_MODAL_PENALTY,
      expiryDate: undefined,
    },
  };
}

export function withContactNumberRestrictionPenalty(): Pick<
  GenerateMarketingMobileNumberGetOptions,
  'marketingMobileNumberRestriction'
> {
  return {
    marketingMobileNumberRestriction: {
      code: MarketingMobileNumberRestrictionCodeApi.USER_HAS_NUMBER_RESTRICTION_PENALTY,
      expiryDate: addDays(Date.now(), 3).getTime(),
    },
  };
}

export function withFullRestrictionPenalty(): Pick<
  GenerateMarketingMobileNumberGetOptions,
  'marketingMobileNumberRestriction'
> {
  return {
    marketingMobileNumberRestriction: {
      code: MarketingMobileNumberRestrictionCodeApi.USER_HAS_FULL_RESTRICTION_PENALTY,
      expiryDate: undefined,
    },
  };
}
