import type { Skill } from '../skills/skills.model';
import type { UserSkill } from './user-skills.model';

export interface GenerateUserSkillOptions {
  readonly userId: number;
  readonly skills?: readonly number[];
}

export function userSkillsFromSkills(
  skills: readonly Skill[],
): Pick<GenerateUserSkillOptions, 'skills'> {
  return { skills: skills.map(skill => skill.id) };
}

export function generateUserSkillObject({
  userId,
  skills = [],
}: GenerateUserSkillOptions): UserSkill {
  return {
    id: userId,
    skills,
  };
}
