import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isDefined } from '@freelancer/utils';

/**
 * Clipboard service used for clipboard modification actions like copying and pasting.
 */
@Injectable({
  providedIn: 'root',
})
export class Clipboard {
  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  copy(text: string): boolean | void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.clipboardData && window.clipboardData.setData) {
        return window.clipboardData.setData('Text', text);
      }

      /**
       * Fallback if clipboardData.setData isn't supported by
       * creating a textarea and executing a copy command manually
       */
      // eslint-disable-next-line deprecation/deprecation
      if (document.queryCommandSupported?.('copy')) {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.setAttribute('readonly', 'readonly'); // Prevent zoom and focus scrolling in IOS
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);

        /**
         * Run both .select() and .setSelectionRange()
         * .select() only works for most of the browsers but not in IOS12
         * .setSelectionRange() works for IOS12 but not in other browsers
         */
        textarea.select();
        textarea.setSelectionRange(0, 999_999);

        try {
          // eslint-disable-next-line deprecation/deprecation
          return document.execCommand('copy');
        } catch (ex: any) {
          console.warn('Copy to clipboard failed.', ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    }
  }

  /**
   * Return empty array if there is no file in the clipboard
   * Return array of files if there is file in the clipboard
   */
  filePaste(event: ClipboardEvent): File[] {
    const { clipboardData } = event;
    if (!clipboardData) {
      return [];
    }

    const files = Array.from(clipboardData.items)
      .filter(item => item.kind === 'file')
      .map(item => {
        const blob = item.getAsFile();
        if (blob) {
          return new File([blob], `${blob.name}`, { type: blob.type });
        }
      })
      .filter(isDefined);

    return files;
  }

  getSelectedText(): Selection | undefined {
    if (isPlatformBrowser(this.platformId)) {
      return window.getSelection() ?? undefined;
    }
  }
}
