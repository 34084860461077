import type { ButtonColor } from '@freelancer/ui/button';

export enum CardLevel {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  NONE = 'none',
}

export enum CardBorder {
  NONE = 'none',
  SOLID = 'solid',
  SOLID_DARK = 'solid-dark',
}

export enum CardSize {
  SMALL = 'small',
  MID = 'mid',
}

export enum CardBorderRadius {
  MID = 'mid',
  LARGE = 'large',
  XLARGE = 'xlarge',
}

export interface CardHeaderButton {
  text: string;
  color?: ButtonColor;
  link?: string;
  fragment?: string;
  queryParams?: { [k: string]: any };
  linkActive?: string[] | string;
  linkActiveOptions?: { exact: boolean };
  busy?: boolean;
}
