import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ContestFile } from './contest-files.model';
import { ContestAttachmentFileType } from './contest-files.model';

export interface GenerateContestFilesOptions {
  readonly contestId: number;
  readonly names?: readonly string[];
  readonly length?: number;
}

export interface GenerateContestFileOptions {
  readonly contestId: number;
  readonly name?: string;
  readonly url?: string;
  readonly deleted?: boolean;
  readonly fileType?: ContestAttachmentFileType;
  readonly featured?: boolean;
}

export function generateContestFileObjects({
  contestId,
  names,
  length,
}: GenerateContestFilesOptions): readonly ContestFile[] {
  if (names) {
    return names.map(name => generateContestFileObject({ name, contestId }));
  }

  if (length) {
    return Array.from(new Array(length).keys()).map(() =>
      generateContestFileObject({ name: generateId().toString(), contestId }),
    );
  }

  return [];
}

/**
 * Returns a contest file object. By default, this is a contest that is "Active".
 */
export function generateContestFileObject({
  contestId,
  name = 'Contest File',
  url = 'contest-file-url',
  deleted = false,
  fileType = ContestAttachmentFileType.IMAGE,
  featured = false,
}: GenerateContestFileOptions): ContestFile {
  return {
    id: generateId(),
    contestId,
    name,
    url,
    deleted,
    fileType,
    featured,
  };
}

// --- Mixins ---
export function videoContestFile({
  name = 'Video File.mp4',
}: Partial<GenerateContestFileOptions> = {}): Pick<
  ContestFile,
  'fileType' | 'name'
> {
  return {
    name,
    fileType: ContestAttachmentFileType.VIDEO,
  };
}

export function imageContestFile({
  name = 'Image File.png',
}: Partial<GenerateContestFileOptions> = {}): Pick<
  ContestFile,
  'fileType' | 'name'
> {
  return {
    name,
    fileType: ContestAttachmentFileType.IMAGE,
  };
}
