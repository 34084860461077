import { generateId } from '@freelancer/datastore/testing/helpers';
import type { FlareRuleset } from './flare-rulesets.model';

export interface GenerateFlareRuleset {
  readonly id?: number;
  readonly flareCheckpointId: number;
  readonly name: string;
  readonly description: string;
  readonly adminName: string;
  readonly dateCreated: number;
  readonly dateUpdated?: number;
  readonly active: boolean;
  readonly reason: string;
}

/**
 * Create a Freelancer Automation Rule Engine (FLARE) ruleset object
 */
export function generateFlareRulesetObject({
  id = generateId(),
  flareCheckpointId,
  name,
  description,
  adminName,
  dateCreated,
  dateUpdated,
  active,
}: GenerateFlareRuleset): FlareRuleset {
  return {
    id,
    flareCheckpointId,
    name,
    description,
    adminName,
    dateCreated,
    dateUpdated,
    active,
  };
}
