import type { ContestEngagement } from './contest-engagement.model';
import { calculateEngagementRatio } from './contest-engagement.transformers';

export interface GenerateContestEngagementOptions {
  readonly contestId: number;
  readonly totalEngageableEntries?: number;
  readonly unratedEntries?: number;
}

export function generateContestEngagementObject({
  contestId: id,
  totalEngageableEntries = 1,
  unratedEntries = 0,
}: GenerateContestEngagementOptions): ContestEngagement {
  return {
    id,
    totalEngageableEntries,
    unratedEntries,
    engagementRatio: calculateEngagementRatio({
      totalEngageableEntries,
      unratedEntries,
    }),
  };
}
