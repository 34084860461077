import { generateId } from '@freelancer/datastore/testing/helpers';
import { CartStatusApi, DestinationApi } from 'api-typings/payments/payments';
import type { Cart, CartReturnAction } from './carts.model';

export interface GenerateCartOptions {
  readonly id: number;
  readonly description?: string;
  readonly status?: CartStatusApi;
  readonly time_created?: number;
  readonly returnAction?: CartReturnAction;
  readonly userId: number;
}

/**
 * Creates a Cart object.
 */
export function generateCartObject({
  id = generateId(),
  description = 'an awesome cart, sir.',
  status = CartStatusApi.UNPAID,
  time_created = 123_456_789,
  returnAction = {
    destination: DestinationApi.PROJECT_VIEW_PAGE,
    payload: '12345654321',
  },
  userId,
}: GenerateCartOptions): Cart {
  return {
    id,
    description,
    status,
    time_created,
    returnAction,
    userId,
  };
}

export function cartForProject(
  projectId: number,
): Pick<GenerateCartOptions, 'returnAction'> {
  return {
    returnAction: {
      destination: DestinationApi.PROJECT_VIEW_PAGE,
      payload: projectId.toString(),
    },
  };
}
