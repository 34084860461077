import type { Equipment } from '../equipment/equipment.model';
import type { EquipmentGroup } from './equipment-group.model';

export function generateVehicleTypeObjects(): readonly Equipment[] {
  return [vehicleTypeGroup(), loadingFacilitiesGroup()];
}

export function vehicleTypeGroup(): EquipmentGroup {
  return {
    id: 7,
    name: 'Vehicle Type',
    items: [
      bDoubleVehicleType(),
      bTripleVehicleType(),
      curtainSiderVehicleType(),
      uteVehicleType(),
      vanVehicleType(),
      tipperVehicleType(),
      vehicleCarrierVehicleType(),
      floatVehicleType(),
    ],
    hasUserInput: false,
    isProjectRequired: false,
    isUserRequired: false,
  };
}

export function loadingFacilitiesGroup(): EquipmentGroup {
  return {
    id: 8,
    name: 'Delivery Loading Facilities',
    items: [forkliftType(), palletJackType(), craneType(), telehandlerType()],
    hasUserInput: false,
    isProjectRequired: false,
    isUserRequired: false,
  };
}

export function bDoubleVehicleType(): Equipment {
  return {
    id: 3,
    name: 'B Double',
  };
}

export function bTripleVehicleType(): Equipment {
  return {
    id: 4,
    name: 'B Triple',
  };
}

export function curtainSiderVehicleType(): Equipment {
  return {
    id: 7,
    name: 'Curtain Sider',
  };
}

export function uteVehicleType(): Equipment {
  return {
    id: 9,
    name: 'Ute',
  };
}

export function vanVehicleType(): Equipment {
  return {
    id: 12,
    name: 'Van',
  };
}

export function tipperVehicleType(): Equipment {
  return {
    id: 13,
    name: 'Tipper',
  };
}

export function vehicleCarrierVehicleType(): Equipment {
  return {
    id: 17,
    name: 'Vehicle Carrier',
  };
}

export function floatVehicleType(): Equipment {
  return {
    id: 20,
    name: 'Float',
  };
}

export function forkliftType(): Equipment {
  return {
    id: 21,
    name: 'Forklift',
  };
}

export function palletJackType(): Equipment {
  return {
    id: 22,
    name: 'Pallet Jack',
  };
}

export function craneType(): Equipment {
  return {
    id: 23,
    name: 'Crane',
  };
}

export function telehandlerType(): Equipment {
  return {
    id: 24,
    name: 'Telehandler',
  };
}
