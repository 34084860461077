import { generateId } from '@freelancer/datastore/testing/helpers';
import { CountryCode } from '../countries';
import type {
  SecurePhone,
  SecurityPhoneRequest,
} from './security-phone-requests.model';

export type GenerateSecurityPhoneRequestOptions = Partial<SecurityPhoneRequest>;
export type GenerateSecurePhoneOptions = Partial<SecurePhone>;

/**
 * Creates a security phone request for the specified user id mapped through `id`
 */
export function generateSecurityPhoneRequestObject({
  id = generateId(), // UserId
  phoneNumber = '9123456789',
  country = CountryCode.PH, // ISO code of country
  countryCode = '63', // Phone country code
}: GenerateSecurityPhoneRequestOptions): SecurityPhoneRequest {
  return {
    id,
    phoneNumber,
    country,
    countryCode,
  };
}

export function generateSecurePhone({
  phoneNumber = '9123456789',
  country = CountryCode.PH, // ISO code of country
  countryCode = '63', // Phone country code
}: GenerateSecurePhoneOptions = {}): SecurePhone {
  return {
    phoneNumber,
    country,
    countryCode,
  };
}
