import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ContestEntryOffer } from './contest-entry-offers.model';

export interface GenerateContestEntryOfferOptions {
  readonly entryId: number;
  readonly contestId: number;
  readonly entryOwnerId: number;
  readonly contestOwnerId: number;
  readonly priceOffer: number;
  readonly accepted: boolean;
  readonly message: string;
}

export function generateContestEntryOfferObject({
  entryId,
  contestId,
  entryOwnerId,
  contestOwnerId,
  priceOffer,
  accepted,
  message,
}: GenerateContestEntryOfferOptions): ContestEntryOffer {
  const newoffer = {
    id: generateId(),
    entryId,
    contestId,
    entryOwnerId,
    contestOwnerId,
    priceOffer,
    accepted,
    timeCreated: Date.now(),
    timeAccepted: accepted ? Date.now() : undefined,
    timeModified: accepted ? Date.now() : undefined,
    extraForPostAndUpdate: { message },
  };
  return newoffer;
}
