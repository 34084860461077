import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';
import { TimeUtils } from '@freelancer/time-utils';
import type { ScrollToOptions } from './scrollable-content.type';

@Component({
  selector: 'fl-scrollable-content',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./scrollable-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollableContentComponent {
  /** To use when the content could be wider than the container */
  @HostBinding('attr.data-hide-horizontal-scroll')
  @Input()
  hideHorizontalScroll = false;

  /** To use when the content could be higher than the container */
  @HostBinding('attr.data-hide-vertical-scroll')
  @Input()
  hideVerticalScroll = false;

  /** To use when the container needs to be adjust to the height of the content, instead of push 100% of the height of the parent container */
  @HostBinding('attr.data-auto-height')
  @Input()
  autoHeight = false;

  /**
   *  To use when the scroll bar should not be displayed but can still be programmatically moved.
   *  It is not compatible with hideHorizontalScroll and hideVerticalScroll.
   */
  @HostBinding('attr.data-no-scroll-bar')
  @Input()
  noScrollBar = false;

  constructor(
    public element: ElementRef<HTMLElement>,
    private time: TimeUtils,
  ) {}

  scrollToBottom(scrollOptions: ScrollToOptions = {}): void {
    const { waitForAfterViewUpdate = true } = scrollOptions;

    if (waitForAfterViewUpdate) {
      this.time.setTimeout(
        () =>
          this.scrollTo(this.element.nativeElement.scrollHeight, {
            ...scrollOptions,
            waitForAfterViewUpdate: false,
          }),
        0,
      );
    } else {
      this.scrollTo(this.element.nativeElement.scrollHeight, scrollOptions);
    }
  }

  scrollToTop(scrollOptions?: ScrollToOptions): void {
    // 0 is the top of the scrollable content
    this.scrollTo(0, scrollOptions);
  }

  scrollTo(
    pixels: number,
    { waitForAfterViewUpdate = true, behavior }: ScrollToOptions = {},
  ): void {
    if (waitForAfterViewUpdate) {
      // Use timeout to trigger scroll to the bottom after the view is updated
      this.time.setTimeout(
        () =>
          this.element.nativeElement.scrollTo({
            top: pixels,
            behavior,
          }),
        0,
      );
    } else {
      this.element.nativeElement.scrollTo({
        top: pixels,
        behavior,
      });
    }
  }

  // scrollTop is the Y position of the current view in the entire scrollable content
  // offsetHeight is the height of the current view port
  // scrollHeight is the total height of the scrollable content.
  // offset is the height of a single message, to keep the same logic as before
  isAtBottom(offset: number = 0): boolean {
    return (
      this.element.nativeElement.scrollTop +
        this.element.nativeElement.offsetHeight +
        offset >=
      this.element.nativeElement.scrollHeight
    );
  }

  getScrollTop(): number {
    return this.element.nativeElement.scrollTop;
  }

  getScrollHeight(): number {
    const { scrollHeight } = this.element.nativeElement;
    return scrollHeight;
  }

  // Move the scroll bar to the right by the set amount of pixels
  scrollRight(pixels: number): void {
    this.element.nativeElement.scrollLeft += pixels;
  }

  // Move the scroll bar to the left by the set amount of pixels
  scrollLeft(pixels: number): void {
    this.element.nativeElement.scrollLeft -= pixels;
  }

  isAtRightMost(): boolean {
    const element = this.element.nativeElement;
    return element.scrollLeft + element.clientWidth === element.scrollWidth;
  }

  isAtLeftMost(): boolean {
    const element = this.element.nativeElement;
    return element.scrollLeft === 0;
  }

  isHorizontallyScrollable(): boolean {
    const element = this.element.nativeElement;
    return element.clientWidth < element.scrollWidth;
  }
}
