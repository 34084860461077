import { isPlatformBrowser } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isDefined } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { filter, finalize, map, take, tap } from 'rxjs/operators';
import type { AuthServiceInterface, AuthState } from '../interface';

@UntilDestroy({ className: 'AuthTesting' })
@Injectable({
  providedIn: 'root',
})
export class AuthTesting implements AuthServiceInterface {
  private _authStateSubject$: ReplaySubject<string | undefined>;

  deviceTokenPromise: Promise<string | undefined>;

  get authState$(): Observable<AuthState | undefined> {
    return this._authStateSubject$
      .asObservable()
      .pipe(map(userId => (userId ? { userId, token: 'token' } : undefined)));
  }

  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    this._authStateSubject$ = new ReplaySubject(1);

    let userId;
    if (isPlatformBrowser(this.platformId)) {
      userId = window.sessionStorage.getItem('authFakeUserId');
    }

    if (userId) {
      this._authStateSubject$.next(userId);
    } else {
      this._authStateSubject$.next(undefined);
    }

    this.deviceTokenPromise = Promise.resolve('token');
  }

  getUserId(): Observable<string> {
    return this.authState$.pipe(
      filter(isDefined),
      map(auth => auth.userId),
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.authState$.pipe(map(state => !!state));
  }

  getAuthorizationHeader(): Observable<HttpHeaders> {
    return this.authState$.pipe(map(() => new HttpHeaders()));
  }

  setSession(userId: string): void {
    this._authStateSubject$.next(userId);
    // keep auth cookies in sync with session
    window.sessionStorage.setItem('authFakeUserId', userId);
  }

  deleteSession(): void {
    this._authStateSubject$.next(undefined);
    // keep auth cookies in sync with session
    window.sessionStorage.removeItem('authFakeUserId');
  }

  switchUser(newUserId: string): Promise<string | undefined> {
    return firstValueFrom(
      this.authState$.pipe(
        tap(auth => {
          if (!auth) {
            throw new Error('no user to switch account from');
          }
        }),
        tap(() => this.setSession(newUserId)),
        map(() => `FIXME: gotoUrl`),
        untilDestroyed(this),
      ),
    );
  }

  logout(): Observable<undefined> {
    return this.authState$.pipe(
      take(1),
      tap(auth => {
        if (!auth) {
          throw new Error('no user to logout');
        }
      }),
      map(() => undefined),
      finalize(() => this.deleteSession()),
    );
  }

  getCSRFToken(): string {
    return 'CSRFToken';
  }

  refreshSession(expiryHours: number): void {
    // do nothing
  }

  getDeviceToken(): Promise<string | undefined> {
    return this.deviceTokenPromise;
  }
}
