import { generateId } from '@freelancer/datastore/testing/helpers';
import type { RichTextElement } from '@freelancer/html-renderer';
import { generateTextHtmlElementObject } from '../content-customization/content-customization.seed';
import type { ContestPrizeDetail } from './contest-prize-details.model';

export interface GenerateContestPrizeDetailObjectOptions {
  readonly id?: number;
  readonly contestId: number;
  readonly timeCreated?: number;
  readonly timeModified?: number;
  readonly contentRichText?: readonly RichTextElement[];
}

export function generateContestPrizeDetailObject({
  id = generateId(),
  contestId = generateId(),
  timeCreated = Date.now(),
  timeModified = Date.now(),
  contentRichText = [generateTextHtmlElementObject('default prize text')],
}: GenerateContestPrizeDetailObjectOptions): ContestPrizeDetail {
  return {
    id,
    contestId,
    timeCreated,
    timeModified,
    contentRichText,
  };
}
