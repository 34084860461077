import { camelCase, generateId } from '@freelancer/datastore/testing/helpers';
import { RoleApi } from 'api-typings/common/common';
import {
  OnlineOfflineStatusApi,
  UserChosenRoleApi,
  UserSupportTypeApi,
} from 'api-typings/users/users';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { CustomFieldValue } from '../custom-field-info-configurations/custom-field-info-configurations.model';
import { Enterprise, Pool } from '../enterprise/enterprise.model';
import { freightlancerEnterprise, insourceEnteprise } from '../enterprises';
import { freightlancerPool, insourceOnlyPool } from '../pools';
import type { UserStatus } from '../project-view-users/user-status.model';
import type { UsersSelf } from '../users-self/users-self.model';
import type { SupportStatus, User } from './users.model';

export interface GenerateUsersOptions {
  readonly names: readonly string[];
  readonly enterpriseIds?: readonly number[];
  readonly enterpriseInternalNames?: readonly string[];
  readonly role?: RoleApi;
  readonly chosenRole?: UserChosenRoleApi;
  readonly currencyCode?: CurrencyCode;
}

export interface GenerateUserOptions {
  readonly userId?: number;
  readonly username?: string;
  readonly displayName?: string;
  readonly enterpriseIds?: readonly number[];
  readonly enterpriseInternalNames?: readonly string[];
  readonly poolIds?: readonly number[];
  readonly role?: RoleApi;
  readonly chosenRole?: UserChosenRoleApi;
  readonly currencyCode?: CurrencyCode;
  readonly userStatus?: UserStatus;
  readonly escrowComInteractionRequired?: boolean;
  readonly hasLinkedEscrowComAccount?: boolean;
  readonly isLimitedAccount?: boolean;
  readonly customFieldValues?: readonly CustomFieldValue[];
  readonly supportStatus?: SupportStatus;
  readonly preferredFreelancer?: boolean;
  readonly isRisingStar?: boolean;
  readonly isShareholder?: boolean;
  readonly isStaff?: boolean;
}

export function generateUserObjects({
  names,
  enterpriseIds,
  enterpriseInternalNames,
  role,
  chosenRole,
  currencyCode,
}: GenerateUsersOptions): readonly User[] {
  return names.map(name =>
    generateUserObject({
      userId: generateId(),
      username: camelCase(name),
      displayName: name,
      enterpriseIds,
      enterpriseInternalNames,
      role,
      chosenRole,
      currencyCode,
    }),
  );
}

export function generateUserObject({
  userId,
  username = `testUsername${userId}`,
  displayName = 'Test Name',
  enterpriseIds = [],
  enterpriseInternalNames = [],
  poolIds = [Pool.FREELANCER],
  role = RoleApi.EMPLOYER,
  chosenRole,
  currencyCode = CurrencyCode.USD,
  userStatus = verifiedUser().userStatus,
  escrowComInteractionRequired = false,
  hasLinkedEscrowComAccount = false,
  isLimitedAccount = false,
  customFieldValues = [],
  supportStatus = undefined,
  preferredFreelancer = false,
  isRisingStar = false,
  isShareholder = false,
  isStaff = false,
}: GenerateUserOptions = {}): User {
  const id = userId !== undefined ? userId : generateId();
  const generatedChosenRole =
    chosenRole !== undefined
      ? chosenRole
      : role
      ? UserChosenRoleApi.EMPLOYER
      : UserChosenRoleApi.FREELANCER;

  return {
    id,
    avatar: generateAvatar(id),
    avatarLarge: generateAvatar(id),
    username,
    closed: false,
    displayName,
    role,
    chosenRole: generatedChosenRole,
    profileUrl: `/u/${username}`,
    onlineOfflineStatus: { status: OnlineOfflineStatusApi.OFFLINE },
    currency: generateCurrencyObject(currencyCode),
    status: userStatus,
    enterpriseIds,
    // from transformer
    isDeloitteDcUser:
      enterpriseIds && enterpriseIds.includes(Enterprise.DELOITTE_DC),
    isHpUser: !!enterpriseIds.includes(Enterprise.HP),
    poolIds,
    escrowComInteractionRequired,
    hasLinkedEscrowComAccount,
    isLimitedAccount,
    customFieldValues,
    supportStatus,
    preferredFreelancer,
    isRisingStar,
    isShareholder,
    isStaff,
  };
}

/**
 * Get the URL of an avatar image base on the given ID.
 *
 * Note:
 * - Since we only have 7 avatars available at the moment,
 *   avatars are repeatedly use when the given ID is greater than 7.
 *
 * @param id Refers to the image ID in `datastore-seeds/avatars`.
 * @returns URL of the avatar in `datastore-seeds/avatars`.
 */
export function generateAvatar(id: number): string {
  const nAvatars = 7;
  let imageId = id % nAvatars;
  imageId = imageId === 0 ? nAvatars : imageId;
  return `datastore-seeds/avatars/${imageId}.jpg`;
}

// ----- Mixins -----

export function userFromUsersSelf(
  userSelf: UsersSelf,
): Pick<
  GenerateUserOptions,
  | 'userId'
  | 'username'
  | 'displayName'
  | 'enterpriseIds'
  | 'hasLinkedEscrowComAccount'
  | 'isLimitedAccount'
> {
  return {
    userId: userSelf.id,
    username: userSelf.username,
    displayName: userSelf.displayName,
    enterpriseIds: userSelf.enterpriseIds,
    hasLinkedEscrowComAccount: userSelf.hasLinkedEscrowComAccount,
    isLimitedAccount: userSelf.isLimitedAccount,
  };
}

export function employerUser(): Pick<
  Required<GenerateUserOptions>,
  'role' | 'chosenRole'
> {
  return {
    role: RoleApi.EMPLOYER,
    chosenRole: UserChosenRoleApi.EMPLOYER,
  };
}

export function freelancerUser(): Pick<
  Required<GenerateUserOptions>,
  'role' | 'chosenRole'
> {
  return {
    role: RoleApi.FREELANCER,
    chosenRole: UserChosenRoleApi.FREELANCER,
  };
}

export function pmiUser(): Pick<
  Required<GenerateUserOptions>,
  'enterpriseIds'
> {
  return {
    enterpriseIds: [Enterprise.PMI],
  };
}

export function facebookUser(): Pick<
  Required<GenerateUserOptions>,
  'enterpriseIds'
> {
  return {
    enterpriseIds: [Enterprise.FACEBOOK],
  };
}

export function hpUser(): Pick<
  Required<GenerateUserOptions>,
  'enterpriseIds' | 'poolIds'
> {
  return {
    enterpriseIds: [Enterprise.HP],
    poolIds: [Pool.HP, Pool.FREELANCER],
  };
}

export function deloitteUser(): Pick<
  Required<GenerateUserOptions>,
  'enterpriseIds' | 'poolIds'
> {
  return {
    enterpriseIds: [Enterprise.DELOITTE_DC],
    poolIds: [Pool.DELOITTE_DC, Pool.DELOITTE_FTN],
  };
}

export function insourceUser(): Pick<
  Required<GenerateUserOptions>,
  'enterpriseIds' | 'poolIds'
> {
  return {
    poolIds: [insourceOnlyPool().id],
    enterpriseIds: [insourceEnteprise().id],
  };
}

export function loadshiftUser(): Pick<
  Required<GenerateUserOptions>,
  'enterpriseIds' | 'poolIds'
> {
  return {
    poolIds: [freightlancerPool().id],
    enterpriseIds: [freightlancerEnterprise().id],
  };
}

export function deloitteUserWithCustomFields(
  customFieldValuesWithConfiguration: readonly CustomFieldValue[],
): Pick<Required<GenerateUserOptions>, 'enterpriseIds' | 'customFieldValues'> {
  return {
    ...deloitteUser(),
    customFieldValues: customFieldValuesWithConfiguration,
  };
}

/**
 * A user that has been certified by Facebook as part of the Branded Communities
 * pilot. Mix this in to allow a user to see and bid on Facebook projects.
 */
export function facebookCertifiedUser(): Pick<
  Required<GenerateUserOptions>,
  'poolIds'
> {
  return {
    poolIds: [Pool.FACEBOOK],
  };
}

/**
 * A user in who is able to bid on Deloitte linked external projects.
 */
export function deloitteFTNUser(): Pick<
  Required<GenerateUserOptions>,
  'poolIds'
> {
  return {
    poolIds: [Pool.FREELANCER, Pool.DELOITTE_FTN],
  };
}

export function unverifiedUser(): Required<
  Pick<Required<GenerateUserOptions>, 'userStatus'>
> {
  return {
    userStatus: {
      paymentVerified: false,
      emailVerified: false,
      depositMade: false,
      profileComplete: false,
      phoneVerified: false,
      identityVerified: false,
      facebookConnected: false,
      freelancerVerifiedUser: false,
      linkedinConnected: false,
      customChargeVerified: false,
    },
  };
}

/** All verified except for KYC, Facebook and LinkedIn */
export function verifiedUser(): Required<
  Pick<Required<GenerateUserOptions>, 'userStatus'>
> {
  return {
    userStatus: {
      ...unverifiedUser().userStatus,
      ...{
        paymentVerified: true,
        emailVerified: true,
        depositMade: true,
        profileComplete: true,
        phoneVerified: true,
        freelancerVerifiedUser: true,
        identityVerified: false,
        facebookConnected: false,
        linkedinConnected: false,
      },
    },
  };
}

export function emailUnverifiedUser(): Required<
  Pick<Required<GenerateUserOptions>, 'userStatus'>
> {
  return {
    userStatus: {
      ...unverifiedUser().userStatus,
      ...{
        paymentVerified: true,
        emailVerified: false,
        depositMade: true,
        profileComplete: true,
        phoneVerified: true,
        identityVerified: false,
        facebookConnected: false,
        freelancerVerifiedUser: false,
        linkedinConnected: false,
      },
    },
  };
}

export function phoneUnverifiedUser(): Required<
  Pick<Required<GenerateUserOptions>, 'userStatus'>
> {
  return {
    userStatus: {
      ...unverifiedUser().userStatus,
      ...{
        paymentVerified: true,
        emailVerified: true,
        depositMade: true,
        profileComplete: true,
        phoneVerified: false,
        identityVerified: false,
        facebookConnected: false,
        freelancerVerifiedUser: false,
        linkedinConnected: false,
      },
    },
  };
}

export function paymentUnverifiedUser(): Required<
  Pick<Required<GenerateUserOptions>, 'userStatus'>
> {
  return {
    userStatus: {
      ...unverifiedUser().userStatus,
      ...{
        paymentVerified: false,
        emailVerified: true,
        depositMade: true,
        profileComplete: true,
        phoneVerified: true,
        identityVerified: false,
        facebookConnected: false,
        freelancerVerifiedUser: false,
        linkedinConnected: false,
      },
    },
  };
}

export function supportStaff(): Required<
  Pick<Required<GenerateUserOptions>, 'supportStatus'>
> {
  return {
    supportStatus: { type: UserSupportTypeApi.GENERAL },
  };
}

export function identityVerifiedUser(): Required<
  Pick<Required<GenerateUserOptions>, 'userStatus'>
> {
  return {
    userStatus: {
      ...unverifiedUser().userStatus,
      ...{
        paymentVerified: false,
        emailVerified: true,
        depositMade: true,
        profileComplete: true,
        phoneVerified: true,
        identityVerified: true,
        facebookConnected: false,
        freelancerVerifiedUser: false,
        linkedinConnected: false,
      },
    },
  };
}

/**
 * Californian users are subject to a different flow, where they must first link
 * their accounts to Escrow.com, provide additional profile information, and
 * possibly KYC before being allowed to create bids or milestones. See T100016
 *
 * This flow applies when either employer or freelancer are Californian, so
 * remember to check both sides.
 *
 * As of writing, only Californian users are affected, but to decouple it from
 * a specific location, the frontend never checks a user's location to determine
 * whether to activate this flow.
 */
export function californianUser(): Pick<
  Required<GenerateUserOptions>,
  'escrowComInteractionRequired'
> {
  return {
    escrowComInteractionRequired: true,
  };
}

export function supportUser(): Pick<
  Required<GenerateUserOptions>,
  'supportStatus'
> {
  return {
    supportStatus: { type: UserSupportTypeApi.GENERAL },
  };
}

export function staffUser(): Pick<Required<GenerateUserOptions>, 'isStaff'> {
  return {
    isStaff: true,
  };
}
