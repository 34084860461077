import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { OnlineIndicatorSize } from '@freelancer/ui/online-indicator';
import { PictureDisplay, PictureObjectFit } from '@freelancer/ui/picture';
import { VideoComponent, VideoObjectFit } from '@freelancer/ui/video';
import { isDefined } from '@freelancer/utils';
import { ModalColor } from '../modal/modal-color';
import { ModalSize } from '../modal/modal-size';
import { ModalService } from '../modal/modal.service';
import type { User } from './user-avatar.types';
import { AvatarSize } from './user-avatar.types';

@Component({
  selector: ' fl-user-avatar',
  template: `
    <div
      class="UserAvatar"
      *ngIf="!isMulti"
      [class.UserAvatarVideo]="showVideoAvatar || showOnboardingModal"
      [class.UserAvatar-upsell]="showOnboardingModal"
      [attr.data-disable-avatar-border]="disableAvatarBorder"
      [attr.data-size]="size"
      [attr.data-size-tablet]="sizeTablet"
      [attr.data-online-size]="onlineIndicatorSize"
      [attr.data-online-size-tablet]="onlineIndicatorSizeTablet"
      [attr.data-online-size-desktop]="onlineIndicatorSizeDesktop"
      [attr.data-online-status]="
        isOnline !== undefined && size !== AvatarSize.XXSMALL
      "
      [attr.data-background-color]="backgroundColor"
      (click)="handleClick()"
      (mouseenter)="playVideo(true)"
      (mouseleave)="playVideo(false)"
    >
      <fl-loading-text
        *ngIf="
          (!isAvatarImageReady && firstUserHasPic) || firstUser === undefined
        "
        class="Loader"
        [padded]="false"
      ></fl-loading-text>
      <ng-container *ngIf="firstUser !== undefined">
        <fl-video
          #video
          *ngIf="showVideoAvatar && videoSrc"
          class="UserAvatarPreviewVideo"
          [flShowDesktop]="true"
          [src]="videoSrc"
          [disableControls]="true"
          [objectFit]="VideoObjectFit.COVER"
          [loop]="true"
        ></fl-video>

        <fl-picture
          *ngIf="firstUserHasPic"
          class="UserAvatar-img"
          alt="User Avatar"
          i18n-alt="User avatar alternative text"
          title="{{ firstUser?.username }}"
          [class.UserAvatarVideo-img]="showVideoAvatar || showOnboardingModal"
          [src]="
            firstUser.avatarXLarge ??
            (shouldUseLargeAvatarForFirstUser
              ? firstUser.avatarLarge
              : firstUser?.avatar)
          "
          [display]="PictureDisplay.BLOCK"
          [objectFit]="PictureObjectFit.CONTAIN"
          [width]="
            shouldUseLargeAvatarForFirstUser ? AVATAR_LARGE_WIDTH : AVATAR_WIDTH
          "
          [height]="
            shouldUseLargeAvatarForFirstUser
              ? AVATAR_LARGE_HEIGHT
              : AVATAR_HEIGHT
          "
          [boundedWidth]="true"
          [boundedHeight]="true"
          [fullWidth]="true"
          (imageLoaded)="isAvatarImageReady = true"
        ></fl-picture>

        <div
          *ngIf="!firstUserHasPic"
          class="UserAvatarLetter"
          [attr.data-background-color]="backgroundColor"
        >
          {{ firstUserLetter }}
        </div>
      </ng-container>
    </div>

    <fl-icon
      *ngIf="showVideoAvatar || showOnboardingModal"
      class="UserAvatarVideo-playButton"
      [color]="IconColor.LIGHT"
      [label]="'user-avatar-play-icon'"
      [size]="IconSize.XSMALL"
      [name]="'ui-play-v2'"
      (click)="handleClick()"
    ></fl-icon>

    <fl-online-indicator
      class="OnlineStatus"
      *ngIf="
        isOnline !== undefined &&
        size !== AvatarSize.XXSMALL &&
        firstUser?.username &&
        !showVideoAvatar
      "
      [border]="!disableOnlineIndicatorBorder"
      [username]="firstUser?.username"
      [isOnline]="isOnline"
      [size]="onlineIndicatorSize"
      [sizeTablet]="onlineIndicatorSizeTablet"
      [sizeDesktop]="onlineIndicatorSizeDesktop"
    ></fl-online-indicator>

    <div
      class="UserAvatar-multi"
      *ngIf="isMulti"
      [attr.data-size]="size"
      [attr.data-size-tablet]="sizeTablet"
      [attr.data-size-desktop]="sizeDesktop"
    >
      <div class="UserAvatar-multi-row">
        <div
          class="UserAvatar-content UserAvatar-content--topLeft"
          [attr.data-background-color]="backgroundColor"
        >
          <fl-picture
            *ngIf="firstUserHasPic"
            alt="User Avatar"
            i18n-alt="User avatar alternative text"
            class="UserAvatar-multi-img"
            title="{{ firstUser?.username }}"
            [src]="firstUser?.avatarXLarge ?? firstUser?.avatar"
            [display]="PictureDisplay.BLOCK"
            [objectFit]="PictureObjectFit.CONTAIN"
            [width]="AVATAR_WIDTH"
            [height]="AVATAR_HEIGHT"
            [boundedWidth]="true"
            [boundedHeight]="true"
            [fullWidth]="true"
          >
          </fl-picture>
          <div
            class="UserAvatar-multi-letter"
            *ngIf="!firstUserHasPic"
            [attr.data-background-color]="backgroundColor"
            [attr.data-size]="size"
          >
            {{ firstUserLetter }}
          </div>
        </div>

        <div
          class="UserAvatar-content UserAvatar-content--topRight"
          [attr.data-background-color]="backgroundColor"
        >
          <fl-picture
            *ngIf="users.length > 2 && secondUserHasPic"
            alt="User Avatar"
            i18n-alt="User avatar alternative text"
            class="UserAvatar-multi-img"
            title="{{ secondUser?.username }}"
            [src]="secondUser?.avatarXLarge ?? secondUser?.avatar"
            [display]="PictureDisplay.BLOCK"
            [objectFit]="PictureObjectFit.CONTAIN"
            [width]="AVATAR_WIDTH"
            [height]="AVATAR_HEIGHT"
            [boundedWidth]="true"
            [boundedHeight]="true"
            [fullWidth]="true"
          >
          </fl-picture>
          <div
            class="UserAvatar-multi-letter"
            *ngIf="users.length > 2 && !secondUserHasPic"
            [attr.data-size]="size"
            [attr.data-background-color]="backgroundColor"
          >
            {{ secondUserLetter }}
          </div>
        </div>
      </div>

      <div class="UserAvatar-multi-row UserAvatar-multi-row--secondary">
        <div
          class="UserAvatar-content UserAvatar-content--bottomLeft"
          [attr.data-background-color]="backgroundColor"
        >
          <fl-picture
            *ngIf="users.length > 2 && thirdUserHasPic"
            alt="User Avatar"
            i18n-alt="User avatar alternative text"
            class="UserAvatar-multi-img"
            title="{{ thirdUser?.username }}"
            [src]="thirdUser?.avatarXLarge ?? thirdUser?.avatar"
            [display]="PictureDisplay.BLOCK"
            [objectFit]="PictureObjectFit.CONTAIN"
            [width]="AVATAR_WIDTH"
            [height]="AVATAR_HEIGHT"
            [boundedWidth]="true"
            [boundedHeight]="true"
            [fullWidth]="true"
          >
          </fl-picture>
          <div
            class="UserAvatar-multi-letter"
            *ngIf="users.length > 2 && !thirdUserHasPic"
            [attr.data-size]="size"
            [attr.data-background-color]="backgroundColor"
          >
            {{ thirdUserLetter }}
          </div>
        </div>

        <div
          class="UserAvatar-content UserAvatar-content--bottomRight"
          [attr.data-background-color]="backgroundColor"
        >
          <fl-picture
            *ngIf="bottomRightUser && bottomRightUserHasPic"
            alt="User Avatar"
            i18n-alt="User avatar alternative text"
            class="UserAvatar-multi-img"
            title="{{ bottomRightUser.username }}"
            [src]="bottomRightUser?.avatarXLarge ?? bottomRightUser?.avatar"
            [display]="PictureDisplay.BLOCK"
            [objectFit]="PictureObjectFit.CONTAIN"
            [width]="AVATAR_WIDTH"
            [height]="AVATAR_HEIGHT"
            [boundedWidth]="true"
            [boundedHeight]="true"
            [fullWidth]="true"
          >
          </fl-picture>
          <div
            class="UserAvatar-multi-letter"
            *ngIf="bottomRightUser && !bottomRightUserHasPic"
            [attr.data-size]="size"
            [attr.data-background-color]="backgroundColor"
          >
            {{ bottomRightUserLetter }}
          </div>
          <div
            class="UserAvatar-multi-letter"
            *ngIf="!bottomRightUser && bottomRightText"
            [attr.data-size]="size"
          >
            {{ bottomRightText }}
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent implements OnChanges, OnInit {
  AvatarSize = AvatarSize;
  IconColor = IconColor;
  IconSize = IconSize;
  ModalSize = ModalSize;
  PictureDisplay = PictureDisplay;
  PictureObjectFit = PictureObjectFit;
  VideoObjectFit = VideoObjectFit;

  @Input() users: readonly (User | undefined)[];

  @HostBinding('attr.data-size')
  @Input()
  size: AvatarSize = AvatarSize.MID;

  @HostBinding('attr.data-size-tablet')
  @Input()
  sizeTablet?: AvatarSize;

  @HostBinding('attr.data-size-desktop')
  @Input()
  sizeDesktop?: AvatarSize;

  // This input is not supported. We know everyone loves round avatars, but you can't use them.
  // There are corporate and group users who upload square logos that shouldn't be rounded.
  // Don't use custom CSS to achieve this effect. Violating diffs will be shot on sight.
  // @Input() isCircle? = false;

  @Input() isOnline?: boolean;

  firstUser?: User;
  firstUserLetter?: string;
  firstUserHasPic: boolean;
  secondUser?: User;
  secondUserLetter?: string;
  secondUserHasPic: boolean;
  thirdUser?: User;
  thirdUserLetter?: string;
  thirdUserHasPic: boolean;
  bottomRightUser?: User;
  bottomRightUserLetter?: string;
  bottomRightUserHasPic: boolean;
  bottomRightText?: string;
  isMulti: boolean;
  showVideoAvatar = false;

  @Input()
  onlineIndicatorSize: OnlineIndicatorSize = this.getOnlineIndicatorSize(
    this.size,
  );

  @Input()
  onlineIndicatorSizeTablet?: OnlineIndicatorSize;

  @Input()
  onlineIndicatorSizeDesktop?: OnlineIndicatorSize;

  @HostBinding('attr.data-is-video-avatar')
  @Input()
  isVideoAvatar?: boolean = false;

  @Input() videoSrc?: string;

  @Input() showOnboardingModal?: boolean = false;

  /** Disable the white border around the avatar. Does not apply to letter avatars, which have white backgrounds. */
  @Input() disableAvatarBorder?: boolean = false;

  /** Disable the white border around the online indicator. */
  @Input() disableOnlineIndicatorBorder?: boolean = false;

  @Input() backgroundColor?: boolean = false;

  @ViewChild('video') video: VideoComponent;

  @Output() videoModalVideoPlayed = new EventEmitter<void>();
  @Output() videoModalVideoEnded = new EventEmitter<void>();

  readonly COUNT_LIMIT = 99;
  // Regular avatars from the backend are always 100x100
  readonly AVATAR_HEIGHT = 100;
  readonly AVATAR_WIDTH = 100;
  // Large avatars from the backend are usually 280x280.
  // Some are 280x279, but we could probably ignore the 1px difference
  // for the sake of not having to change the backend to include the
  // image dimensions in its response
  readonly AVATAR_LARGE_HEIGHT = 280;
  readonly AVATAR_LARGE_WIDTH = 280;

  isAvatarImageReady = false;
  shouldUseLargeAvatarForFirstUser = false;

  constructor(private modalService: ModalService) {}

  private hasPic(user?: User): user is User & { avatar: string } {
    return (
      isDefined(user) &&
      isDefined(user.avatar ?? user.avatarLarge ?? user.avatarXLarge)
    );
  }

  private getLetter(user?: User): string | undefined {
    // falsey check is OK because empty string should be undefined too.
    return !!user && !!user.username
      ? user.username[0].toUpperCase()
      : undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('users' in changes) {
      const users = changes.users.currentValue;
      if (users) {
        // Check if the user avatar is for multiple users.
        this.isMulti = users.length > 1;
        [this.firstUser, this.secondUser, this.thirdUser] = users;
        this.firstUserHasPic = this.hasPic(this.firstUser);
        this.firstUserLetter = this.getLetter(this.firstUser);
        this.secondUserHasPic = this.hasPic(this.secondUser);
        this.secondUserLetter = this.getLetter(this.secondUser);
        this.thirdUserHasPic = this.hasPic(this.thirdUser);
        this.thirdUserLetter = this.getLetter(this.thirdUser);

        this.checkIfLargeAvatarForFirstUser();

        // Check for what to show for the bottom right user.
        if (users.length === 2) {
          [, this.bottomRightUser] = users;
          this.bottomRightText = undefined;
        } else if (users.length === 4) {
          [, , , this.bottomRightUser] = users;
          this.bottomRightText = undefined;
        } else if (users.length > 4) {
          const leftOverCount = users.length - 3;
          const count =
            leftOverCount > this.COUNT_LIMIT ? this.COUNT_LIMIT : leftOverCount;
          this.bottomRightText = `${count}+`;
          this.bottomRightUser = undefined;
        } else {
          this.bottomRightUser = undefined;
          this.bottomRightText = undefined;
        }

        this.bottomRightUserHasPic = this.hasPic(this.bottomRightUser);
        this.bottomRightUserLetter = this.getLetter(this.bottomRightUser);
      } else {
        this.isMulti = false;
        this.bottomRightUser = undefined;
        this.bottomRightText = undefined;
        this.bottomRightUserHasPic = false;
        this.bottomRightUserLetter = undefined;
      }
    }

    if ('size' in changes && !this.onlineIndicatorSize) {
      this.onlineIndicatorSize = this.getOnlineIndicatorSize(this.size);
      this.checkIfLargeAvatarForFirstUser();
    }

    if (
      'sizeTablet' in changes &&
      this.sizeTablet &&
      !this.onlineIndicatorSizeTablet
    ) {
      this.onlineIndicatorSizeTablet = this.getOnlineIndicatorSize(
        this.sizeTablet,
      );
    }

    if (
      'sizeDesktop' in changes &&
      this.sizeDesktop &&
      !this.onlineIndicatorSizeDesktop
    ) {
      this.onlineIndicatorSizeDesktop = this.getOnlineIndicatorSize(
        this.sizeDesktop,
      );
    }

    if (this.isMulti && this.isOnline !== undefined) {
      console.error(
        'Online indicator may only be displayed on a single user avatar',
      );
    }

    if ('videoSrc' in changes || 'isVideoAvatar' in changes) {
      this.showVideoAvatar = !!this.videoSrc && !!this.isVideoAvatar;
    }
  }

  ngOnInit(): void {
    this.onlineIndicatorSize =
      this.onlineIndicatorSize || this.getOnlineIndicatorSize(this.size);
  }

  private getOnlineIndicatorSize(avatarSize: AvatarSize): OnlineIndicatorSize {
    switch (avatarSize) {
      case AvatarSize.MID:
        return OnlineIndicatorSize.MID;
      case AvatarSize.LARGE:
        return OnlineIndicatorSize.LARGE;
      case AvatarSize.XLARGE:
        return OnlineIndicatorSize.XXLARGE;
      case AvatarSize.MAX:
        return OnlineIndicatorSize.XLARGE;
      default:
        return OnlineIndicatorSize.SMALL;
    }
  }

  private checkIfLargeAvatarForFirstUser(): void {
    this.shouldUseLargeAvatarForFirstUser =
      this.size === AvatarSize.MAX &&
      isDefined(this.firstUser?.avatarXLarge ?? this.firstUser?.avatarLarge);
  }

  playVideo(play: boolean): void {
    if (this.showVideoAvatar) {
      if (play) {
        this.video.play();
      } else {
        this.video.pause();
      }
    }
  }

  async openVideoModal(): Promise<void> {
    if (this.showVideoAvatar) {
      const {
        videoPlayed,
        videoEnded,
      }: {
        videoPlayed: boolean;
        videoEnded: boolean;
      } = await this.modalService
        .open('VideoModal', {
          inputs: {
            autoPlay: true,
            src: this.videoSrc,
            timeDisplay: false,
          },
          edgeToEdge: true,
          closeable: true,
          color: ModalColor.DARK,
        })
        .afterClosed();

      if (videoPlayed) {
        this.videoModalVideoPlayed.emit();
      }

      if (videoEnded) {
        this.videoModalVideoEnded.emit();
      }
    }
  }

  // FIXME: T271621 Move video bid-related logic to bid-card component
  openBidPitchOnboardingModal(): void {
    this.modalService.open('BidPitchOnboardingModal', {
      inputs: {
        isUpsell: true,
      },
      size: ModalSize.SMALL,
      closeable: true,
      mobileFullscreen: true,
    });
  }

  handleClick(): void {
    if (this.showOnboardingModal === true) {
      this.openBidPitchOnboardingModal();
    } else {
      this.openVideoModal();
    }
  }
}
