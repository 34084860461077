import type { CorporateBadgeType } from './corporate-badge-type';
import type { ExamBadgeType } from './exam-badge-type';
import type { MembershipBadgeType } from './membership-badge-type';
import type { PreferredFreelancerBadgeType } from './preferred-freelancer-badge-type';
import type { RecruiterBadgeType } from './recruiter-badge-type';
import type { RisingStarBadgeType } from './rising-star-badge-type';
import type { StaffBadgeType } from './staff-badge-type';
import type { UserBadgeType } from './user-badge-type';
import type { VerifiedBadgeType } from './verified-badge-type';

export enum BadgeSize {
  SMALL = 'small',
  MID = 'mid',
  PROFILE = 'profile',
  LARGE = 'large',
}

export enum BadgeType {
  CORPORATE = 'corporate',
  DO_GOODER = 'do-gooder',
  MEMBERSHIP = 'membership',
  EXAM = 'exam',
  PREFERRED_FREELANCER = 'preferred-freelancer',
  RECRUITER = 'recruiter',
  RISING_STAR = 'rising-star',
  STAFF = 'staff',
  USER = 'user',
  VERIFIED = 'verified',
}

export type Badge =
  | { type: BadgeType.CORPORATE; badge: CorporateBadgeType }
  | { type: BadgeType.DO_GOODER; badge: UserBadgeType }
  | { type: BadgeType.MEMBERSHIP; badge: MembershipBadgeType }
  | { type: BadgeType.EXAM; badge: ExamBadgeType }
  | {
      type: BadgeType.PREFERRED_FREELANCER;
      badge: PreferredFreelancerBadgeType;
    }
  | { type: BadgeType.RECRUITER; badge: RecruiterBadgeType }
  | { type: BadgeType.RISING_STAR; badge: RisingStarBadgeType }
  | { type: BadgeType.STAFF; badge: StaffBadgeType }
  | { type: BadgeType.USER; badge: UserBadgeType }
  | {
      type: BadgeType.VERIFIED;
      badge: VerifiedBadgeType;
    };

export interface BadgeDimensions {
  height: number;
  width: number;
}

/** These are the intrinsic width and height for the SVGs of each badge types */
export const badgeDimensionsByType: { [k in BadgeType]: BadgeDimensions } = {
  [BadgeType.CORPORATE]: { height: 101, width: 101 },
  [BadgeType.DO_GOODER]: { height: 32, width: 32 },
  [BadgeType.MEMBERSHIP]: { height: 24, width: 24 },
  [BadgeType.EXAM]: { height: 30, width: 30 },
  [BadgeType.PREFERRED_FREELANCER]: { height: 24, width: 24 },
  [BadgeType.RECRUITER]: { height: 24, width: 24 },
  [BadgeType.RISING_STAR]: { height: 24, width: 24 },
  [BadgeType.STAFF]: { height: 24, width: 24 },
  [BadgeType.USER]: { height: 32, width: 32 },
  [BadgeType.VERIFIED]: { height: 24, width: 24 },
};
