import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ProjectBudgetOption } from '../project-budget-options/project-budget-options.model';
import type {
  ContestUpgradeCardOption,
  PostJobPageFormState,
  ProjectUpgradeCardOption,
} from './post-job-page-form-state.model';

export interface GenerateDraftOptions {
  readonly id: number;
  readonly userId: number;
  readonly submitDate: number | null;
  readonly title: string;
  readonly description: string;
  readonly contestDuration: number | null;
  readonly contestDurationType: string | null;
  readonly contestPrize: number | null;
  readonly contestType: ContestUpgradeCardOption | null;
  readonly currencyId: number | null;
  readonly isLocal: boolean | null;
  readonly jobType: string | null;
  readonly projectBudgetRange: ProjectBudgetOption | null;
  readonly projectBudgetType: string | null;
  readonly projectType: ProjectUpgradeCardOption | null;
}

export function generatePjpDraftObject({
  id,
  userId = 1,
  submitDate = Date.now(),
  title,
  description,
  currencyId = null,
}: GenerateDraftOptions): PostJobPageFormState {
  return {
    id: id ?? generateId(),
    userId,
    submitDate: submitDate ?? Date.now(),
    title,
    description,
    contestDuration: null,
    contestDurationType: null,
    contestPrize: null,
    contestType: null,
    currencyId,
    isLocal: null,
    jobType: null,
    projectBudgetRange: null,
    projectBudgetType: null,
    projectType: null,
  };
}
