import { generateId } from '@freelancer/datastore/testing/helpers';
import type { LoadPriceEstimate } from './load-price-estimate.model';

export interface GenerateLoadPriceEstimateOptions {
  readonly id?: string;
  readonly currency_id?: number;
  readonly minimum_estimated_price?: number;
  readonly maximum_estimated_price?: number;
}

export function generateLoadPriceEstimateObject({
  id = generateId().toString(),
  currency_id = 3, // We currently only support AUD
  minimum_estimated_price = 900,
  maximum_estimated_price = 1000,
}: GenerateLoadPriceEstimateOptions = {}): LoadPriceEstimate {
  return {
    id,
    currency_id,
    estimated_price: {
      minimum: minimum_estimated_price,
      maximum: maximum_estimated_price,
    },
  };
}
