import type { Router } from '@angular/router';
import type { AuthServiceInterface } from '@freelancer/auth/interface';
import type { DatastoreInterface } from '@freelancer/datastore/core';
import type {
  DatastoreInitializer,
  DatastoreTestingController,
} from '@freelancer/datastore/testing';
import { publishDatastoreCollectionFactoryFunctions } from '@freelancer/datastore/testing';
import { datastoreFunctions } from './datastore-functions';
import {
  printState,
  setAuth,
  setDatastoreController,
} from './document-creators';
import { constructInitialStoreState } from './initial-states';
import { addMutationPropagators } from './mutation-propagators';
import { addPushTransformers } from './push-transformers';
import { addSearchTransformers } from './search-transformers';
import { addUpdateTransformers } from './update-transformers';

export function datastoreInitializer({
  isTesting = false,
  blankState = false,
}: {
  readonly isTesting?: boolean;
  readonly blankState?: boolean;
} = {}): DatastoreInitializer {
  return async (
    auth: AuthServiceInterface,
    datastore: DatastoreInterface,
    datastoreController: DatastoreTestingController,
    router: Router,
  ) => {
    setAuth(auth);
    setDatastoreController(datastoreController);

    publishDatastoreCollectionFactoryFunctions(
      datastoreFunctions,
      datastoreController.platformId,
    );

    addPushTransformers();
    addMutationPropagators();
    addSearchTransformers();
    addUpdateTransformers();

    /** If we're not running a UI or other type of test, set up the state */
    if (!isTesting) {
      await constructInitialStoreState(
        auth,
        datastore,
        datastoreController,
        router,
        blankState,
      );
      printState();
    }
  };
}
