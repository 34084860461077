import type { ContestEntry } from '../contest-entries';
import type { ContestViewEntryIds } from './contest-view-entry-ids.model';
import {
  EntryIdFilterOption,
  EntryIdSortOption,
} from './contest-view-entry-ids.model';

export interface GenerateContestViewEntryIdsOptions {
  readonly contestId: number;
  readonly entryIds: readonly number[];
  readonly entryOwnerIds: readonly number[];
  readonly entryNumbers?: readonly number[];
  readonly filter?: EntryIdFilterOption;
  readonly sort?: EntryIdSortOption;
  readonly quickView?: boolean;
}

export function generateContestViewEntryIdsObject({
  contestId,
  entryIds,
  entryOwnerIds,
  entryNumbers = entryIds.map((_, index) => index + 1),
  filter = EntryIdFilterOption.DEFAULT,
  sort = EntryIdSortOption.DEFAULT,
  quickView = false,
}: GenerateContestViewEntryIdsOptions): ContestViewEntryIds {
  return {
    id: `${contestId}_${filter}_${sort}_${quickView}_${JSON.stringify(
      entryOwnerIds,
    )}`, // from transformer
    contestId,
    entryIds,
    entryOwnerIds,
    entryNumbers,
    filter,
    sort,
    quickView,
  };
}

// --- Mixins ---
export function withEntriesForContestViewEntryIds(
  entries: readonly ContestEntry[],
): Pick<GenerateContestViewEntryIdsOptions, 'entryIds' | 'entryOwnerIds'> {
  return {
    entryIds: entries.map(entry => entry.id),
    entryOwnerIds: entries.map(entry => entry.ownerId),
  };
}
