import type { AfterContentInit, OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
  TrackByFunction,
} from '@angular/core';
import { CalloutPlacement, CalloutSize } from '@freelancer/ui/callout';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { LinkColor, LinkUnderline } from '@freelancer/ui/link';
import { ListItemPadding, ListItemType } from '@freelancer/ui/list-item';
import { FontColor, FontWeight, TextAlign } from '@freelancer/ui/text';
import type { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { LinkOutline } from '../link/link.types';
import type { TableColumnGridWidth, TableRow } from './table.types';
import {
  HeaderWidth,
  ResponsiveColumnPlacement,
  TableColumnPaddingSize,
  TableColumnVerticalAlign,
  TableExpandableBackgroundColor,
  TableHeaderColor,
  TableMobileStyle,
  TablePaginationPosition,
} from './table.types';

interface CellContext<T> {
  $implicit: T;
  index: number;
}

@Component({
  selector: 'fl-table-expandable-content',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableExpandableContentComponent {
  @Input() backgroundColor?: TableExpandableBackgroundColor;
  @Input() edgeToEdge = false;

  @ContentChild('content') contentTemplate: TemplateRef<any>;
}

@Component({
  selector: 'fl-table-col',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnComponent<T> implements OnChanges {
  TextAlign = TextAlign;

  /** String title for the column. Must be unique in table. */
  @Input() titleText: string;
  @Input() columnName: string;
  @Input() textAlign = TextAlign.LEFT;
  @Input() verticalAlign = TableColumnVerticalAlign.MIDDLE;
  @Input() columnPlacement: ResponsiveColumnPlacement =
    ResponsiveColumnPlacement.HIDE_COLUMN;
  @Input() gridWidth: TableColumnGridWidth | undefined = undefined;
  @Input() sortable = false;
  @Input() tooltipIconName: string;
  @Input() calloutSize = CalloutSize.MEDIUM;
  @Input() flHide = false;
  @Input() flHideMobile = false;
  @Input() flHideTablet = false;
  @Input() flHideDesktop = false;
  @Output() sortClicked = new EventEmitter<void>();

  @ContentChild('cell') cellTemplate: TemplateRef<CellContext<T>>;
  @ContentChild('calloutContent')
  calloutContent: TemplateRef<TableColumnComponent<T>>;

  ngOnChanges(): void {
    if (this.titleText && !this.columnName) {
      this.columnName = this.titleText;
    }
  }
}

@Component({
  selector: 'fl-table',
  template: `
    <table
      class="Table"
      [attr.data-fixed-layout]="fixedLayout"
      [attr.data-row-links]="rowLinks"
    >
      <ng-container *ngIf="!hideHeader">
        <thead
          class="HeaderGroup"
          [flHideMobile]="responsive"
        >
          <tr
            class="Row HeaderRow"
            [attr.data-border]="border"
            [attr.data-header-color]="headerColor"
            [attr.data-expandable-body-compact]="expandableBodyCompact"
          >
            <th
              class="Cell HeaderCell"
              *ngFor="let column of columns$ | flAsync; trackBy: trackByColumn"
              [attr.data-edge-to-edge]="edgeToEdge"
              [attr.data-row-links]="rowLinks"
              [attr.data-text-align]="column.textAlign"
              [attr.data-nowrap]="column.sortable"
              [attr.data-padding-size]="paddingSize"
              [attr.data-padding-size-tablet]="paddingSizeTablet"
              [attr.data-padding-size-desktop]="paddingSizeDesktop"
              [attr.data-grid-width]="column.gridWidth"
              [flHide]="column.flHide"
              [flHideMobile]="column.flHideMobile"
              [flHideTablet]="column.flHideTablet"
              [flHideDesktop]="column.flHideDesktop"
            >
              <fl-text
                [color]="
                  headerColor === TableHeaderColor.DARK
                    ? FontColor.LIGHT
                    : FontColor.FOREGROUND
                "
                [weight]="FontWeight.BOLD"
              >
                <ng-container *ngIf="!column.sortable">
                  {{ column.titleText }}
                </ng-container>
                <fl-link
                  *ngIf="column.sortable"
                  [color]="LinkColor.INHERIT"
                  (click)="column.sortClicked.emit()"
                >
                  {{ column.titleText }}
                  <fl-icon
                    [name]="'ui-sort'"
                    [size]="IconSize.SMALL"
                    [color]="IconColor.INHERIT"
                  ></fl-icon>
                </fl-link>
                <ng-container *ngIf="column.tooltipIconName">
                  <fl-callout
                    [edgeToEdge]="true"
                    [hover]="true"
                    [placement]="CalloutPlacement.BOTTOM"
                    [size]="column.calloutSize"
                  >
                    <fl-callout-trigger>
                      <fl-icon
                        [name]="column.tooltipIconName"
                        [size]="IconSize.SMALL"
                      ></fl-icon>
                    </fl-callout-trigger>

                    <fl-callout-content>
                      <ng-container
                        [ngTemplateOutlet]="column.calloutContent"
                      ></ng-container>
                    </fl-callout-content>
                  </fl-callout>
                </ng-container>
              </fl-text>
            </th>
            <!-- Custom expandables are declared in the parent, so omit this header row -->
            <th
              [class.HeaderColumnExpandable]="expandableBodyCompact"
              *ngIf="expandable && !customExpandable"
            ></th>
          </tr>
        </thead>
      </ng-container>
      <tbody class="BodyGroup">
        <ng-container
          *ngFor="
            let row of tableRows
              | slice
                : (currentPage - 1) * itemsPerPage
                : currentPage * itemsPerPage;
            trackBy: rowTrackByLocal;
            index as i
          "
        >
          <tr
            class="Row BodyRow"
            [attr.data-edge-to-edge]="edgeToEdge"
            [attr.data-border]="border"
            [attr.data-row-links]="rowLinks"
            [attr.data-hover]="hover"
            [attr.data-highlight]="row.highlight"
            [attr.data-expandable]="expandable"
            [attr.data-mobile-view-compact]="mobileViewCompact"
            [attr.data-expandable-body-compact]="expandableBodyCompact"
            [attr.data-mobile-view-shading]="mobileViewShading"
            [attr.data-row]="i"
          >
            <td
              *ngFor="
                let column of columns$ | flAsync;
                let first = first;
                trackBy: trackByColumn
              "
              class="Cell BodyCell"
              [attr.data-edge-to-edge]="edgeToEdge"
              [attr.data-vertical-align]="column.verticalAlign"
              [attr.data-row-links]="rowLinks"
              [attr.data-padding-size]="paddingSize"
              [attr.data-padding-size-tablet]="paddingSizeTablet"
              [attr.data-padding-size-desktop]="paddingSizeDesktop"
              [attr.data-text-align]="column.textAlign"
              [attr.data-grid-width]="column.gridWidth"
              [attr.data-column-placement]="column.columnPlacement"
              [flMarginBottom]="
                rowLinks || paddingSize === TableColumnPaddingSize.NONE
                  ? 'none'
                  : 'xxsmall'
              "
              [flMarginBottomTablet]="
                rowLinks || paddingSizeTablet === TableColumnPaddingSize.NONE
                  ? 'none'
                  : 'xxsmall'
              "
              [flMarginBottomDesktop]="
                rowLinks || paddingSizeDesktop === TableColumnPaddingSize.NONE
                  ? 'none'
                  : 'xxsmall'
              "
              [flHide]="column.flHide"
              [flHideMobile]="column.flHideMobile"
              [flHideTablet]="column.flHideTablet"
              [flHideDesktop]="column.flHideDesktop"
            >
              <!--
              we hide this text with ngIf instead of adding it with CSS :before content
              this lets us use the fl-text styling here
              FIXME: T273892 - Currently rowLinks is not supported with mobileViewCompact = true or expandable = true
              -->
              <ng-container *ngIf="!mobileViewCompact">
                <fl-link
                  *ngIf="rowLinks; else cellContent"
                  class="Cell LinkCell"
                  flTrackingLabel="TableRowLink"
                  [attr.data-border]="border"
                  [attr.data-row-links]="rowLinks"
                  [attr.data-padding-size]="paddingSize"
                  [attr.data-padding-size-tablet]="paddingSizeTablet"
                  [attr.data-padding-size-desktop]="paddingSizeDesktop"
                  [attr.data-edge-to-edge]="edgeToEdge"
                  [attr.data-text-align]="column.textAlign"
                  [attr.data-vertical-align]="column.verticalAlign"
                  [color]="LinkColor.INHERIT"
                  [link]="row?.link"
                  [newTab]="row?.newTab"
                  [outline]="LinkOutline.INHERIT"
                  [tabIndex]="first ? 0 : -1"
                  [underline]="LinkUnderline.NEVER"
                >
                  <ng-container *ngTemplateOutlet="cellContent"></ng-container>
                </fl-link>
              </ng-container>
              <ng-template #cellContent>
                <div
                  class="ContentWrapper"
                  [attr.data-row-links]="rowLinks"
                >
                  <fl-text
                    class="MobileHeader"
                    *ngIf="responsive"
                    [attr.data-header-width]="headerWidth"
                    [flShowMobile]="true"
                    [flMarginRight]="'xxsmall'"
                    [weight]="FontWeight.BOLD"
                  >
                    {{ column.titleText }}
                    <ng-container *ngIf="column.tooltipIconName">
                      <fl-callout
                        [edgeToEdge]="true"
                        [hover]="true"
                        [placement]="CalloutPlacement.BOTTOM"
                        [size]="column.calloutSize"
                      >
                        <fl-callout-trigger>
                          <fl-icon
                            [name]="column.tooltipIconName"
                            [size]="IconSize.SMALL"
                          ></fl-icon>
                        </fl-callout-trigger>

                        <fl-callout-content>
                          <ng-container
                            [ngTemplateOutlet]="column.calloutContent"
                          ></ng-container>
                        </fl-callout-content>
                      </fl-callout>
                    </ng-container>
                  </fl-text>
                  <fl-text class="CellText">
                    <ng-container
                      [ngTemplateOutlet]="column.cellTemplate"
                      [ngTemplateOutletContext]="{
                        $implicit: row.item,
                        index: i
                      }"
                    ></ng-container>
                  </fl-text>
                </div>
              </ng-template>
              <div
                *ngIf="mobileViewCompact"
                class="ContentWrapper"
              >
                <div class="CellText">
                  <ng-container
                    [ngTemplateOutlet]="column.cellTemplate"
                    [ngTemplateOutletContext]="{
                      $implicit: row.item,
                      index: i
                    }"
                  ></ng-container>
                </div>
              </div>
            </td>
            <td
              *ngIf="expandable && !customExpandable"
              class="Cell BodyCell CellIcon"
              [attr.data-edge-to-edge]="edgeToEdge"
              [attr.data-row-links]="rowLinks"
              [attr.data-padding-size]="paddingSize"
              [attr.data-padding-size-tablet]="paddingSizeTablet"
              [attr.data-padding-size-desktop]="paddingSizeDesktop"
              [flShowDesktop]="!expandableMobileEnabled"
            >
              <fl-icon
                class="ExpandIcon"
                label="Expand"
                i18n-label="Label for icon to expand table row"
                [name]="'ui-chevron-down'"
                [size]="IconSize.SMALL"
                (click)="toggleExpandedContent(i)"
              ></fl-icon>
            </td>
          </tr>

          <tr
            class="Row BodyRow IsHidden BodyRowExpandable"
            *ngIf="expandable && expandableContent"
            [attr.data-edge-to-edge]="
              expandableContent.edgeToEdge || edgeToEdge
            "
            [attr.data-border]="border"
            [attr.data-row-links]="rowLinks"
            [attr.data-hover]="hover"
            [attr.data-highlight]="row.highlight"
            [attr.data-expandable]="expandable"
            [attr.data-expandable-body-compact]="expandableBodyCompact"
            [attr.data-background-color]="expandableContent.backgroundColor"
            [attr.data-expandable-row]="i"
          >
            <td
              *ngIf="!expandableBodyCompact"
              [colSpan]="
                customExpandable
                  ? (columns$ | flAsync)?.length
                  : (columns$ | flAsync)?.length + 1
              "
            >
              <ng-container
                [ngTemplateOutlet]="expandableContent.contentTemplate"
                [ngTemplateOutletContext]="{ $implicit: row.item, index: i }"
              ></ng-container>
            </td>

            <td
              *ngIf="mobileViewCompact && expandableBodyCompact"
              class="Cell BodyCell"
              [flHideTablet]="true"
              [flHideDesktop]="true"
            >
              <div
                class="BodyCellExpandable"
                *ngFor="
                  let column of columns$ | flAsync;
                  trackBy: trackByColumn
                "
                [attr.data-column-placement]="column.columnPlacement"
                [flMarginBottom]="'xxsmall'"
              >
                <div class="ContentWrapper">
                  <fl-text
                    class="MobileHeader"
                    [attr.data-header-width]="headerWidth"
                    [flShowMobile]="true"
                    [flMarginRight]="'xxsmall'"
                    [weight]="FontWeight.BOLD"
                  >
                    {{ column.titleText }}
                  </fl-text>
                  <fl-text class="CellText">
                    <ng-container
                      [ngTemplateOutlet]="column.cellTemplate"
                      [ngTemplateOutletContext]="{
                        $implicit: row.item,
                        index: i
                      }"
                    ></ng-container>
                  </fl-text>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <fl-pagination
      class="TablePagination"
      *ngIf="dataSource.length > itemsPerPage && totalPages"
      [attr.data-pagination-position]="paginationPosition"
      [attr.data-border]="border"
      [compact]="compactPagination"
      [currentPage]="currentPage"
      [pages]="totalPages"
      (pageSelected)="onPageSelected($event)"
    ></fl-pagination>
  `,
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements AfterContentInit, OnChanges {
  CalloutPlacement = CalloutPlacement;
  FontColor = FontColor;
  FontWeight = FontWeight;
  IconSize = IconSize;
  IconColor = IconColor;
  LinkColor = LinkColor;
  LinkOutline = LinkOutline;
  LinkUnderline = LinkUnderline;
  ListItemPadding = ListItemPadding;
  ListItemType = ListItemType;
  TableColumnPaddingSize = TableColumnPaddingSize;
  TableHeaderColor = TableHeaderColor;
  TextAlign = TextAlign;

  // FIXME: T300116 Enable hydration. Currently fails due to mismatched nodes
  @HostBinding('attr.ngSkipHydration') skipHydration = 'true';

  @Input() dataSource: readonly T[];
  @Input() rowTrackBy?: TrackByFunction<T>;
  @Input() border = true;
  @Input() hover = false;
  @Input() edgeToEdge = false;
  @Input() hideHeader = false;
  @Input() expandable = false;
  /** Allows showing or hiding of the expandable toggle in mobile screens. Will only have an effect when `expandable` is true */
  @Input() expandableMobileEnabled = true;
  @Input() expandableBodyCompact = false;
  @Input() headerColor = TableHeaderColor.DEFAULT;
  @Input() headerWidth = HeaderWidth.SMALL;

  /** Allow table rows to be links. Currently not supported with `expandable`, `mobileViewCompact` and `calloutContent`. */
  @Input() rowLinks = false;

  /** Allow programmatically expanding the table without showing the chevron icon */
  @Input() customExpandable = false;
  /** Only allow expanding one row at a time */
  @Input() singleExpandable = false;
  /** Use with any width input on fl-table-col to fix table layout */
  @Input() fixedLayout = false;

  /** Set pagination component to use compact mode */
  @Input() compactPagination = false;
  @Input() itemsPerPage: number = Number.POSITIVE_INFINITY;
  /** Position applys only for tablet viewport and up */
  @Input() paginationPosition = TablePaginationPosition.CENTER;
  @Input() initialPage?: number = 1;

  @Input() paddingSize = TableColumnPaddingSize.MID;
  @Input() paddingSizeTablet?: TableColumnPaddingSize;
  @Input() paddingSizeDesktop?: TableColumnPaddingSize;

  /** The total count of items in the table. Used for when we don't retrieve all records in one go*/
  @Input() totalCount?: number;

  /** Adds a shaded color to every second row in mobile view */
  @Input() mobileViewShading = false;

  /** Hide table outer box shadow but keep the individual cell box shadow in mobile view */
  @HostBinding('attr.data-hide-box-shadow')
  @Input()
  hideBoxShadow = false;

  @HostBinding('attr.data-responsive')
  @Input()
  responsive? = true;

  @HostBinding('attr.data-mobile-view-compact')
  @Input()
  mobileViewCompact?: TableMobileStyle;

  @Output() pageSelected = new EventEmitter<number>();
  /** Emits the index of the currently expanded row - undefined if no row is expanded */
  @Output() rowExpanded = new EventEmitter<number | undefined>();

  @ContentChild(TableExpandableContentComponent)
  expandableContent: TableExpandableContentComponent;

  @ContentChildren(TableColumnComponent<T>)
  childColumns: QueryList<TableColumnComponent<T>>;

  columns$: Observable<TableColumnComponent<T>[]>;
  tableRows: readonly TableRow<T>[];

  // if we should use the grid display instead of flat rows
  showTableGrid$: Observable<boolean>;
  currentPage = 1;
  totalPages = 5;

  // indices of rows that are currently expanded
  private currentlyExpandedRows: readonly number[] = [];

  constructor(private elementRef: ElementRef) {}

  rowTrackByLocal(index: number, tableRow: TableRow<T>): any {
    return this.rowTrackBy ? this.rowTrackBy(index, tableRow.item) : undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dataSource) {
      this.tableRows = this.dataSource.map(sourceItem => ({
        item: sourceItem,
        highlight: (sourceItem as any).highlight,
        link: (sourceItem as any).link,
        newTab: (sourceItem as any).newTab,
      }));
      this.totalPages = this.totalCount
        ? Math.ceil(this.totalCount / this.itemsPerPage)
        : Math.ceil(this.dataSource.length / this.itemsPerPage);
    }
    if (this.initialPage && 'initialPage' in changes) {
      this.currentPage = this.initialPage;
    }
    // FIXME: T273892 - Currently rowLinks is not supported with `mobileViewCompact = true` or `expandable = true`
    if (
      (this.rowLinks && 'rowLinks' in changes) ||
      (this.mobileViewCompact && 'mobileViewCompact' in changes) ||
      (this.expandable && 'expandable' in changes)
    ) {
      this.rowLinks =
        this.rowLinks && !(this.mobileViewCompact || this.expandable);
    }
  }

  ngAfterContentInit(): void {
    this.columns$ = this.childColumns.changes.pipe(
      startWith(this.childColumns.toArray()),
    );
  }

  /** For use with custom expandable tables */
  toggleExpandedContent(rowIndex: number): void {
    if (
      this.singleExpandable &&
      this.currentlyExpandedRows.length > 0 &&
      !this.currentlyExpandedRows.includes(rowIndex)
    ) {
      this.toggleRow(this.currentlyExpandedRows[0]); // close old row
    }

    this.toggleRow(rowIndex);
  }

  private toggleRow(rowIndex: number): void {
    const { nativeElement } = this.elementRef;

    const row: HTMLElement = nativeElement.querySelector(
      `[data-row="${rowIndex}"]`,
    ) as HTMLElement;

    const expandableRow: HTMLElement = nativeElement.querySelector(
      `[data-expandable-row="${rowIndex}"]`,
    ) as HTMLElement;

    if (row && expandableRow) {
      row.classList.toggle('IsActive');
      expandableRow.classList.toggle('IsHidden');
      expandableRow.classList.toggle('IsActive');
    }

    // if a currently expanded row is toggled, remove it from the buffer
    if (this.currentlyExpandedRows.includes(rowIndex)) {
      this.currentlyExpandedRows = this.currentlyExpandedRows.filter(
        i => rowIndex !== i,
      );
    } else {
      this.currentlyExpandedRows = [...this.currentlyExpandedRows, rowIndex];
    }

    if (this.currentlyExpandedRows.length === 0) {
      this.rowExpanded.emit(undefined);
    } else {
      this.rowExpanded.emit(rowIndex);
    }
  }

  onPageSelected(page: number): void {
    this.currentPage = page;
    this.pageSelected.emit(this.currentPage);

    // Reset expanded rows
    if (this.expandable) {
      this.currentlyExpandedRows = [];
      this.rowExpanded.emit(undefined);
    }
  }

  trackByColumn(
    _: number,
    column: TableColumnComponent<T>,
  ): TableColumnComponent<T> {
    return column;
  }
}
