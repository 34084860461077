import type { UsState } from './us-states.model';

export function generateUsStateObjects(): readonly UsState[] {
  const supportedUsStateCodes = ['IL', 'IN', 'KS', 'KY', 'LA', 'MA'];
  return supportedUsStateCodes.map(getUsStates);
}

export function getUsStates(stateCode: string): UsState {
  if (stateCode === 'IL') {
    return { id: 15, name: 'Illinois', stateCode: 'IL', countryCode: 'US' };
  }

  if (stateCode === 'IN') {
    return { id: 16, name: 'Indiana', stateCode: 'IN', countryCode: 'US' };
  }

  if (stateCode === 'KS') {
    return { id: 17, name: 'Kansas', stateCode: 'KS', countryCode: 'US' };
  }

  if (stateCode === 'KY') {
    return { id: 18, name: 'Kentucky', stateCode: 'KY', countryCode: 'US' };
  }

  if (stateCode === 'LA') {
    return { id: 19, name: 'Louisiana', stateCode: 'LA', countryCode: 'US' };
  }

  if (stateCode === 'MA') {
    return {
      id: 20,
      name: 'Massachusetts',
      stateCode: 'MA',
      countryCode: 'US',
    };
  }

  throw new Error(`Unhandled state code: '${stateCode}'`);
}
