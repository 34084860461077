import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ContestHandoverFile } from './contest-handover-files.model';

export interface GenerateContestHandoverFilesOptions {
  readonly handoverId: number;
  readonly names?: readonly string[];
  readonly length?: number;
}

export interface GenerateContestHandoverFileOptions {
  readonly handoverId: number;
  readonly name?: string;
  readonly url?: string;
  readonly urlDownloadAll?: string;
}

export function generateContestHandoverFileObjects({
  handoverId,
  names,
  length,
}: GenerateContestHandoverFilesOptions): readonly ContestHandoverFile[] {
  if (names) {
    return names.map(name =>
      generateContestHandoverFileObject({ name, handoverId }),
    );
  }

  if (length) {
    return Array.from(new Array(length).keys()).map(() =>
      generateContestHandoverFileObject({
        name: `Contest Handover File ${generateId()}`,
        handoverId,
      }),
    );
  }

  return [];
}

/**
 * Returns a contest handover file object.
 */
export function generateContestHandoverFileObject({
  handoverId,
  name = 'Contest Handover File',
  url = 'contest-handover-file-url',
  urlDownloadAll = 'contest-handover-file-download-all-url',
}: GenerateContestHandoverFileOptions): ContestHandoverFile {
  return {
    id: generateId(),
    handoverId,
    name,
    url,
    isDeleted: false,
    size: 1000,
    submitDate: Date.now(),
    urlDownloadAll,
  };
}
