import { generateId } from '@freelancer/datastore/testing/helpers';
import { TimelineSourceTypeApi } from 'api-typings/timelines/timelines';
import type {
  Timeline,
  TimelineEvent,
  TimelineSource,
} from './timelines.model';

export interface GenerateTimelineObjectOptions {
  readonly id?: number;
  readonly source: TimelineSource;
  readonly title?: string;
  readonly events?: readonly TimelineEvent[];
  readonly createdTime?: number;
}

export interface GenerateTimelineEventObjectOptions {
  readonly id?: number;
  readonly timelineId: number;
  readonly startTime?: number;
  readonly endTime?: number;
  readonly description?: string;
  readonly createdTime?: number;
}

export function generateTimelineObject({
  id = generateId(),
  source,
  title,
  events,
  createdTime,
}: GenerateTimelineObjectOptions): Timeline {
  const currentTime = Date.now();

  return {
    id,
    source,
    title,
    events,
    createdTime: createdTime ?? currentTime,
  };
}

export function generateTimelineEventObject({
  id = generateId(),
  timelineId = generateId(),
  startTime,
  endTime,
  description,
  createdTime,
}: GenerateTimelineEventObjectOptions): TimelineEvent {
  const currentTime = Date.now();

  return {
    id,
    timelineId,
    startTime: startTime ?? currentTime,
    endTime,
    description,
    createdTime: createdTime ?? currentTime,
  };
}

export function contestSource(contestId: number): TimelineSource {
  return {
    id: contestId,
    type: TimelineSourceTypeApi.CONTEST,
  };
}
