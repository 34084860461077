import { generateId } from '@freelancer/datastore/testing/helpers';
import type { DepositMethodApi } from 'api-typings/common/common';
import { type RecurringBillingInformation } from './recurring-billing-information.model';

export interface GenerateRecurringBillingInformationObjectOptions {
  readonly subscriptionItemId?: number;
  readonly isActive?: boolean;
  readonly token?: string;
  readonly paymentMethod?: DepositMethodApi;
  readonly createDate?: number;
}

export function generateRecurringBillingInformationObject({
  subscriptionItemId = 1,
  isActive = true,
  token = generateId().toString(),
  paymentMethod,
  createDate = Date.now(),
}: GenerateRecurringBillingInformationObjectOptions = {}): RecurringBillingInformation {
  return {
    id: generateId(),
    subscriptionItemId,
    isActive,
    token,
    paymentMethod,
    createDate,
  };
}

export function generateRecurringBillingInformationObjects(
  recurringBillingInformations: readonly GenerateRecurringBillingInformationObjectOptions[],
): readonly RecurringBillingInformation[] {
  return recurringBillingInformations.map(
    generateRecurringBillingInformationObject,
  );
}
