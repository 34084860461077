import type { MapCoordinates } from '@freelancer/datastore/core';
import { generateId } from '@freelancer/datastore/testing/helpers';
import type { Country } from '../countries';
import { generateCountryObject } from '../countries';
import type { FreelancerReputation } from '../freelancer-reputations';
import { generateFreelancerReputationsObject } from '../freelancer-reputations';
import type { ProfileViewUser } from '../profile-view-users';
import { generateProfileViewUserObjects } from '../profile-view-users';
import type { Qualification } from '../project-view-projects/project-view-projects.model';
import type { Skill } from '../skills';
import type { User } from '../users';
import type { SearchUser } from './search-users.model';

export interface GenerateSearchUsersOptions {
  readonly avatar?: string;
  readonly avatarLarge?: string;
  readonly country?: Country;
  readonly hourlyRate?: number;
  readonly isOnline?: boolean;
  readonly location?: Location;
  readonly poolIds?: readonly number[];
  readonly profileDescription?: string;
  readonly qualifications?: readonly Qualification[];
  readonly rating?: number;
  readonly reputation?: FreelancerReputation;
  readonly reviews?: number;
  readonly searchCoordinates?: MapCoordinates;
  readonly skills?: readonly Skill[];
  readonly tagLine?: string;
  readonly users: readonly User[];
}

export function generateSearchUsersObjects({
  avatar,
  avatarLarge = avatar,
  country = generateCountryObject(),
  hourlyRate = 2, // HourlyRateFilterRange.MINIMUM
  isOnline = false,
  poolIds = [],
  profileDescription = 'This is my profile description.',
  qualifications = [],
  rating = 5,
  reputation = generateFreelancerReputationsObject({
    freelancerId: generateId(),
  }),
  reviews = 0,
  searchCoordinates,
  skills = [],
  tagLine = 'This is my tagline.',
  users,
}: GenerateSearchUsersOptions): readonly SearchUser[] {
  const profileViewUser = generateProfileViewUserObjects({
    users,
  });

  return profileViewUser.map(user => ({
    ...user,
    avatar,
    avatarLarge,
    countryName: country.name,
    hourlyRate,
    isOnline,
    location: { country },
    poolIds,
    profileDescription,
    qualifications,
    rating,
    reputation,
    reviews,
    searchCoordinates,
    skills,
    tagLine,
  }));
}

export interface GenerateSearchUserOptions {
  readonly avatar?: string;
  readonly country?: Country;
  readonly hourlyRate?: number;
  readonly isOnline?: boolean;
  readonly preferredFreelancer?: boolean;
  readonly qualifications?: readonly Qualification[];
  readonly rating?: number;
  readonly reputation?: FreelancerReputation;
  readonly reviews?: number;
  readonly searchCoordinates?: MapCoordinates;
  readonly skills?: readonly Skill[];
  readonly user: ProfileViewUser;
}

export function generateSearchUsersObject({
  avatar,
  country = generateCountryObject(),
  hourlyRate = 2, // HourlyRateFilterRange.MINIMUM
  isOnline = false,
  preferredFreelancer = false,
  qualifications,
  rating = 5,
  reputation = generateFreelancerReputationsObject({
    freelancerId: generateId(),
  }),
  reviews = 0,
  searchCoordinates,
  skills = [],
  user,
}: GenerateSearchUserOptions): SearchUser {
  return {
    ...user,
    avatar,
    avatarLarge: avatar,
    countryName: country.name,
    hourlyRate,
    isOnline,
    preferredFreelancer,
    qualifications,
    rating,
    reputation,
    reviews,
    searchCoordinates,
    skills,
  };
}

export function rookieSearchUser({
  userId,
}: {
  readonly userId: number;
}): Pick<GenerateSearchUsersOptions, 'reputation'> {
  return {
    reputation: generateFreelancerReputationsObject({
      freelancerId: userId,
      earningsScore: 0,
    }),
  };
}

export function nonRookieSearchUser({
  userId,
}: {
  readonly userId: number;
}): Pick<GenerateSearchUsersOptions, 'reputation'> {
  return {
    reputation: generateFreelancerReputationsObject({
      freelancerId: userId,
      earningsScore: 1,
    }),
  };
}
