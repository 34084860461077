import { generateId } from '@freelancer/datastore/testing/helpers';
import type {
  DepositPageVars,
  DepositPageVarsBanner,
} from './deposit-page-vars.model';

export interface GenerateDepositPageVarsOptions {
  readonly amount?: number;
  readonly back?: string;
  readonly cartId?: number;
  readonly contractQuantity?: string;
  readonly currency?: number;
  readonly durationCycle?: string;
  readonly durationType?: string;
  readonly packageId?: string;
  readonly redirected?: string;
}

export function generateDepositPageVarsObjects({
  amount,
  back = '/dashboard',
  cartId,
  contractQuantity,
  currency,
  durationCycle,
  durationType,
  packageId,
  redirected,
}: GenerateDepositPageVarsOptions = {}): readonly DepositPageVars[] {
  return [
    {
      amount,
      back: undefined,
      bidderId: undefined,
      cartId,
      contractQuantity,
      currency,
      dataToken: undefined,
      descr: undefined,
      durationCycle,
      durationType,
      entryId: undefined,
      hiremeInitialMilestoneTracking: undefined,
      id: generateId().toString(),
      listingId: undefined,
      membershipCoupon: undefined,
      membershipRef: undefined,
      milestoneAmount: undefined,
      milestoneRequestId: undefined,
      nextAction: undefined,
      oneTimeNotify: undefined,
      orgBackUrl: undefined,
      orgTrackingToken: undefined,
      packageId,
      paymentItem: undefined,
      pid: undefined,
      priorityRef: undefined,
      redirected,
      refAction: undefined,
      returnToProject: undefined,
      toCorporateOnboarding: undefined,
      toFinancialDashboard: undefined,
      uid: undefined,
      upgradeOptions: undefined,
      username: undefined,
      verifiedPayment: undefined,
      generatedVars: {
        trackingToken: '',
        backUrl: {
          encoded: '',
          raw: back,
        },
        banner: { ...generateDepositPageVarsBanner(redirected) },
        worldpayDdc: {
          jwt: '',
          url: '',
          originUrl: '',
        },
        cookieFreeTrialFlag: false,
      },
    },
  ];
}

function generateDepositPageVarsBanner(
  redirected?: string,
): DepositPageVarsBanner {
  let body = '';

  if (redirected === 'membership_subscribe') {
    body =
      'Please deposit funds to upgrade your membership and receive great benefits right away!';
  }

  return {
    body,
    cancelBtnText: '',
    cancelUrl: '',
  };
}
