import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from '../fade';

export const fadeInAnimation = trigger('fadeInAnimation', [
  transition(':enter', [useAnimation(fadeIn)]),
]);

export const fadeOutAnimation = trigger('fadeOutAnimation', [
  transition(':leave', [useAnimation(fadeOut)]),
]);

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
  transition(':enter', [useAnimation(fadeIn)]),
  transition(':leave', [useAnimation(fadeOut)]),
]);
