import { generateId } from '@freelancer/datastore/testing/helpers';
import { HandoverUserRoleApi } from 'api-typings/contests/contests';
import type { ContestHandover } from '../contest-handovers/contest-handovers.model';
import type { User } from '../users/users.model';
import type { ManageViewContestHandover } from './manage-view-contest-handovers.model';
import { ManageContestHandoverStatus } from './manage-view-contest-handovers.model';

export interface GenerateManageViewContestHandoversOptions {
  readonly buyer: User;
  readonly freelancers: readonly User[];
  readonly role: HandoverUserRoleApi;
  readonly contestId?: number;
  readonly entryIds?: readonly number[];
  readonly buyerSignedEntryIds?: readonly number[];
}

export function generateManageViewContestHandoverObjects({
  buyer,
  freelancers,
  role,
  contestId = generateId(),
  entryIds = [],
  buyerSignedEntryIds = [],
}: GenerateManageViewContestHandoversOptions): readonly ManageViewContestHandover[] {
  return freelancers.map((freelancer, index) => {
    let userId = buyer.id;
    let oppositeUser = freelancer;
    const entryId = entryIds[index];

    if (role === HandoverUserRoleApi.SELLER) {
      userId = freelancer.id;
      oppositeUser = buyer;
    }

    return generateManageViewContestHandoverObject({
      userId,
      oppositeUser,
      role,
      contestId,
      entryId: entryId || generateId(),
      ...(buyerSignedEntryIds.includes(entryId)
        ? buyerSignedContestHandover()
        : {}),
    });
  });
}

export interface GenerateManageViewContestHandoverOptions {
  // `userId` would be the buyer ID if the role is buyer; otherwise, it's the seller ID
  readonly userId: number;
  // `oppositeUser` is opposite party's `User`, e.g. if role is buyer, this would be the seller
  readonly oppositeUser: User;
  readonly role: HandoverUserRoleApi;
  readonly contestId?: number;
  readonly entryId?: number;
  readonly buyerSignedContract?: boolean;
  readonly buyerSignedDate?: number;
  readonly sellerSignedContract?: boolean;
  readonly sellerSignedDate?: number;
  readonly buyerConfirmed?: boolean;
  readonly buyerConfirmedDate?: number;
  readonly sellerConfirmed?: boolean;
  readonly sellerConfirmedDate?: number;
  readonly handoverStatus?: ManageContestHandoverStatus;
}

export function generateManageViewContestHandoverObject({
  userId,
  oppositeUser,
  role,
  entryId = generateId(),
  contestId = generateId(),
  buyerSignedContract = false,
  buyerSignedDate,
  sellerSignedContract = false,
  sellerSignedDate,
  buyerConfirmed = false,
  buyerConfirmedDate,
  sellerConfirmed = false,
  sellerConfirmedDate,
  handoverStatus = ManageContestHandoverStatus.IN_PROGRESS,
}: GenerateManageViewContestHandoverOptions): ManageViewContestHandover {
  const contestHandover: ContestHandover = {
    active: true,
    adminCancelled: false,
    buyerConfirmed,
    buyerConfirmedDate,
    buyerId: role === HandoverUserRoleApi.BUYER ? userId : oppositeUser.id,
    buyerSignedContract,
    buyerSignedDate,
    sellerSignedContract,
    sellerSignedDate,
    contestId,
    entryId,
    id: generateId(),
    sellerConfirmed,
    sellerConfirmedDate,
    sellerId: role === HandoverUserRoleApi.BUYER ? oppositeUser.id : userId,
  };

  if (role === HandoverUserRoleApi.BUYER) {
    return {
      ...contestHandover,
      role,
      sellerDetails: oppositeUser,
      handoverStatus,
    };
  }

  return {
    ...contestHandover,
    role,
    buyerDetails: oppositeUser,
    handoverStatus,
  };
}

// --- Mixins ---

export function buyerSignedContestHandover(): Pick<
  GenerateManageViewContestHandoverOptions,
  'buyerSignedDate' | 'buyerSignedContract'
> {
  return {
    buyerSignedContract: true,
    buyerSignedDate: Date.now(),
  };
}

export function sellerSignedContestHandover(): Pick<
  GenerateManageViewContestHandoverOptions,
  'sellerSignedDate' | 'sellerSignedContract'
> {
  return {
    sellerSignedContract: true,
    sellerSignedDate: Date.now(),
  };
}

export function buyerConfirmedContestHandover(): Pick<
  GenerateManageViewContestHandoverOptions,
  'buyerConfirmed' | 'buyerConfirmedDate'
> {
  return {
    buyerConfirmed: true,
    buyerConfirmedDate: Date.now() - 1000 * 60 * 60 * 24 * 2, // 2 days ago
  };
}

export function sellerConfirmedContestHandover(): Pick<
  GenerateManageViewContestHandoverOptions,
  'sellerConfirmed' | 'sellerConfirmedDate'
> {
  return {
    sellerConfirmed: true,
    sellerConfirmedDate: Date.now() - 1000 * 60 * 60 * 24 * 2, // 2 days ago
  };
}

export function forReviewContestHandover(): Pick<
  GenerateManageViewContestHandoverOptions,
  'handoverStatus'
> {
  return {
    handoverStatus: ManageContestHandoverStatus.FOR_REVIEW,
  };
}
