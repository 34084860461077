import {
  generateNumbersInRange,
  randomiseList,
} from '@freelancer/datastore/testing/helpers';
import { RoleApi } from 'api-typings/common/common';
import type {
  GenerateFreelancerReputationDataOption,
  GenerateFreelancerReputationDataOptions,
} from '../reputation/reputation-data.seed';
import {
  generateFreelancerReputationDataObjects,
  generateReputationDataObject,
} from '../reputation/reputation-data.seed';
import type { FreelancerReputation } from './freelancer-reputations.model';

interface Range {
  readonly min: number;
  readonly max: number;
}

export interface GenerateFreelancerReputationsOptions {
  readonly freelancerIds: readonly number[];
  readonly reputationOptions?: GenerateFreelancerReputationDataOptions;
  readonly earningsScore?: Range;
  readonly acceptRate?: number;
}

/** Keep in sync with generateFreelancerReputationsRehireRatesObjects */
export function generateFreelancerReputationsObjects({
  freelancerIds,
  reputationOptions = {},
  earningsScore = { min: 3, max: 10 },
  acceptRate,
}: GenerateFreelancerReputationsOptions): readonly FreelancerReputation[] {
  const entireHistories = generateFreelancerReputationDataObjects({
    ...reputationOptions,
    seed: 'entireHistory',
    numberToMake: freelancerIds.length,
  });

  const earningsScores = randomiseList(
    generateNumbersInRange(
      earningsScore.min,
      earningsScore.max,
      freelancerIds.length,
    ),
    'earningsScore',
  );

  return freelancerIds.map((freelancerId, i) => ({
    id: freelancerId,
    role: RoleApi.FREELANCER,
    // Set value to 0 if you've not done any work.
    earningsScore: entireHistories[i].all === 0 ? 0 : earningsScores[i],
    entireHistory: entireHistories[i],
    acceptRate,
  }));
}

export interface GenerateFreelancerReputationOptions {
  readonly freelancerId: number;
  readonly reputationOptions?: Partial<GenerateFreelancerReputationDataOption>;
  readonly earningsScore?: number;
}

export const defaultReputations: GenerateFreelancerReputationDataOption = {
  total: 75,
  completePercentage: 0.9,
  reviewsPercentage: 0.9,
  incompleteReviewsPercentage: 0.9,
  communication: 4.5,
  expertise: 4.5,
  hireAgain: 4.5,
  quality: 4.5,
  professionalism: 4.5,
  onBudgetPercentage: 0.9,
  onTimePercentage: 0.9,
};

/** Keep in sync with generateFreelancerReputationsRehireRatesObject */
export function generateFreelancerReputationsObject({
  freelancerId,
  reputationOptions = {},
  earningsScore = 7,
}: GenerateFreelancerReputationOptions): FreelancerReputation {
  return {
    id: freelancerId,
    earningsScore,
    role: RoleApi.FREELANCER,
    entireHistory: generateReputationDataObject({
      ...defaultReputations,
      ...reputationOptions,
    }),
  };
}

export function rookieUserReputation(): Pick<
  GenerateFreelancerReputationOptions,
  'earningsScore' | 'reputationOptions'
> {
  return {
    earningsScore: 0,
    reputationOptions: {
      total: 0,
      completePercentage: 0,
      reviewsPercentage: 0,
      incompleteReviewsPercentage: 0,
      communication: 0,
      expertise: 0,
      hireAgain: 0,
      quality: 0,
      professionalism: 0,
      onBudgetPercentage: 0,
      onTimePercentage: 0,
    },
  };
}
