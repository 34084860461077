import { generateId } from '@freelancer/datastore/testing/helpers';
import type { Experience } from './experiences.model';

export interface GenerateExperienceOptions {
  readonly userId: number;
  readonly title?: string;
  readonly company?: string;
  readonly description?: string;
  readonly startDate?: number;
  readonly endDate?: number;
  readonly countryCode?: string;
  readonly country?: string;
  readonly city?: string;
}

let ordering = 0;

export function generateExperienceObject({
  userId,
  title = 'Product Manager',
  company = 'Newtech Ltd.',
  description = 'Responsible for growing several products.',
  startDate = new Date(2010, 0).getTime(),
  endDate, // defaults to undefined, which means the experience is current
  countryCode,
  country,
  city,
}: GenerateExperienceOptions): Experience {
  ordering += 1;

  return {
    id: generateId(),
    userId,
    ordering,
    title,
    company,
    description,
    startDate,
    endDate,
    duration: startDate && endDate ? endDate - startDate : undefined,
    countryCode,
    country,
    city,
  };
}

export function generateExperienceObjects(
  experiences: readonly GenerateExperienceOptions[],
): readonly Experience[] {
  return experiences.map(generateExperienceObject);
}

// --- Mixins ---

/**
 * Experiences with a location should have both country and city.
 */
export function withLocation({
  countryCode = 'US',
  country = 'United States',
  city = 'Boston',
}: Pick<
  GenerateExperienceOptions,
  'countryCode' | 'country' | 'city'
> = {}): Pick<GenerateExperienceOptions, 'countryCode' | 'country' | 'city'> {
  return {
    countryCode,
    country,
    city,
  };
}
