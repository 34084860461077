import { generateId } from '@freelancer/datastore/testing/helpers';
import type { Campaign } from './campaigns.model';

export interface CampaignOptions {
  readonly internalName: string;
  readonly description?: string;
  readonly startDate?: number;
  readonly endDate?: number;
  readonly created?: number;
}

export function generateCampaignObject({
  internalName,
  description,
  startDate,
  endDate,
  created = Date.now(),
}: CampaignOptions): Campaign {
  return {
    id: generateId(),
    internalName,
    description,
    startDate,
    endDate,
    created,
  };
}
