import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ContestViewContest } from '../contest-view-contests/contest-view-contests.model';
import type { Contest } from '../contests/contests.model';
import type { ProjectViewProject } from '../project-view-projects/project-view-projects.model';
import type { SearchProjectsSelfEntry } from './search-projects-self.model';

export interface GenerateSearchProjectsSelfOptions {
  readonly type: 'project' | 'contest';
  readonly ownerId: number;
  readonly id?: number;
  readonly title?: string;
  readonly description?: string;
  readonly projectType?: 'fixed' | 'hourly';
}

export function generateSearchProjectsSelfObject({
  type,
  ownerId,
  id = generateId(),
  title = 'Build me a website',
  description = 'I need someone to make me a website for my business. It should be awesome and wiz bang.',
  projectType = 'fixed',
}: GenerateSearchProjectsSelfOptions): SearchProjectsSelfEntry {
  if (type === 'project') {
    return {
      id,
      type,
      ownerId,
      title,
      description,
      projectType,
    };
  }
  return {
    id,
    type,
    ownerId,
    title,
    description,
  };
}

export function fromProject(
  project: ProjectViewProject,
): Pick<
  GenerateSearchProjectsSelfOptions,
  'type' | 'ownerId' | 'id' | 'title' | 'description' | 'projectType'
> {
  return {
    type: 'project',
    ownerId: project.ownerId,
    id: project.id,
    title: project.title,
    description: project.description,
    projectType: project.type,
  };
}

export function fromContest(
  contest: Contest | ContestViewContest,
): Pick<
  GenerateSearchProjectsSelfOptions,
  'type' | 'ownerId' | 'id' | 'title' | 'description'
> {
  return {
    type: 'contest',
    ownerId: contest.ownerId,
    id: contest.id,
    title: contest.title,
    description: contest.description,
  };
}
