import { generateId } from '@freelancer/datastore/testing/helpers';
import { SubscriptionItemStatusApi } from 'api-typings/quotations/quotations';
import { type SubscriptionItem } from './subscription-items.model';

export interface GenerateSubscriptionItemObjectOptions {
  readonly quotationItemVersionId?: number;
  readonly createDate?: number;
  readonly status?: SubscriptionItemStatusApi;
  readonly projectId?: number;
}

export function generateSubscriptionItemObject({
  quotationItemVersionId = 1,
  createDate = Date.now(),
  status = SubscriptionItemStatusApi.ACTIVE,
  projectId = generateId(),
} = {}): SubscriptionItem {
  return {
    id: generateId(),
    quotationItemVersionId,
    createDate,
    status,
    projectId,
  };
}

export function generateSubscriptionItemObjects(
  subscriptionItems: readonly GenerateSubscriptionItemObjectOptions[],
): readonly SubscriptionItem[] {
  return subscriptionItems.map(generateSubscriptionItemObject);
}
