import type { EntryRestrictions } from './entry-restrictions.model';

export interface GenerateEntryRestrictionsOptions {
  readonly userId: number;
  readonly requirements?:
    | {
        readonly complete: true;
      }
    | {
        readonly complete: false;
        readonly profile: {
          readonly hasFullname: boolean;
          readonly hasAddress: boolean;
          readonly hasProfilePicture: boolean;
          readonly displayName: string;
          readonly username: string;
        };
        readonly trust: {
          readonly hasVerifiedEmail: boolean;
          readonly hasVerifiedPhone: boolean;
          readonly hasFacebook: boolean;
          readonly hasLinkedIn: boolean;
          readonly hasVerifiedCard: boolean;
          readonly isKycVerified: boolean;
          readonly minimumRequired: number;
          readonly points: {
            readonly email: number;
            readonly phone: number;
            readonly ccv: number;
            readonly kyc: number;
            readonly facebook: number;
            readonly linkedin: number;
          };
          readonly score: number;
          readonly targetPoints: number;
          readonly showCcv: boolean;
        };
      };
}

export function generateEntryRestrictionsObject({
  userId,
  requirements = { complete: true },
}: GenerateEntryRestrictionsOptions): EntryRestrictions {
  // TypeScript needs this to be sure both cases are covered, for some reason
  if (requirements.complete) {
    return {
      id: userId,
      requirements,
    };
  }

  return {
    id: userId,
    requirements,
  };
}

// --- Mixins ---

export function incompleteEntryRequirements(): Pick<
  GenerateEntryRestrictionsOptions,
  'requirements'
> {
  return {
    requirements: {
      complete: false,
      profile: {
        hasFullname: true,
        hasAddress: true,
        hasProfilePicture: true,
        displayName: 'Test Name',
        username: 'testUsername',
      },
      trust: {
        hasVerifiedEmail: true,
        hasVerifiedPhone: true,
        hasFacebook: true,
        hasLinkedIn: true,
        hasVerifiedCard: true,
        isKycVerified: true,
        minimumRequired: 35,
        points: {
          email: 5,
          phone: 10,
          ccv: 45,
          kyc: 35,
          facebook: 20,
          linkedin: 20,
        },
        score: 5,
        targetPoints: 30,
        showCcv: true,
      },
    },
  };
}
