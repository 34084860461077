import type { Language } from './languages.model';

export function generateLanguagesObject(): Language {
  return getLanguages('en');
}

export function generateLanguagesObjects(): readonly Language[] {
  const supportedCountryCodes = ['en', 'es', 'fil'];
  return supportedCountryCodes.map(getLanguages);
}

export function getLanguages(countryCode: string): Language {
  if (countryCode === 'en') {
    return {
      id: 'en',
      code: 'en',
      name: 'English',
      englishName: 'English',
      active: true,
    };
  }

  if (countryCode === 'es') {
    return {
      id: 'es',
      code: 'es',
      name: 'Español',
      englishName: 'Spanish',
      active: true,
    };
  }

  if (countryCode === 'fil') {
    return {
      id: 'fil',
      code: 'fil',
      name: 'Filipino',
      englishName: 'Filipino',
      active: true,
    };
  }

  throw new Error(`Unhandled country code: '${countryCode}'`);
}
