import { BenefitDuplicateRuleApi } from 'api-typings/memberships/memberships_types';
import type { MembershipBenefit } from './membership-benefits.model';

const BASIC_MEMBERSHIP_EMPLOYER_FOLLOW_LIMIT = 5;

export interface GenerateMembershipBenefitOptions {
  readonly benefitName?: string;
  /** Value of the benefit. Can be number or 1/0 for true/false */
  readonly value?: number;
  /** Shorthand for 1/0 values. Ignored if you set the `value`. */
  readonly enabled?: boolean;
  readonly aggregation?: BenefitDuplicateRuleApi;
}

// TODO: T267853 - Generate MembershipBenefit objects for different benefits, consolidate
// with membership seed data in project-view-user.seeds
export function generateMembershipBenefitObject({
  benefitName = 'bid_on_premium_projects',
  enabled,
  value,
  aggregation = BenefitDuplicateRuleApi.EXISTS,
}: GenerateMembershipBenefitOptions = {}): MembershipBenefit {
  return {
    id: benefitName,
    name: benefitName, // This field seems to be unused
    value: value ?? (enabled ? 1 : 0), // use explicit value if provided, otherwise set based on enabled boolean. Default to false
    aggregation,
  };
}

/**
 * Allows a user to bid on premium projects (see projects.seed.ts#premiumProject).
 * Part of the Plus, Professional and Premier membership plans.
 */
export function bidOnPremiumProjectBenefit(): GenerateMembershipBenefitOptions {
  return {
    benefitName: 'bid_on_premium_projects',
    enabled: true,
  };
}

/**
 * Allows a user to view the Client Engagement section of a project in PVP.
 * Part of the Plus, Professional and Premier membership plans.
 */
export function clientEngagementBenefit(
  enabled: boolean,
): GenerateMembershipBenefitOptions {
  return {
    benefitName: 'client_engagement',
    enabled,
  };
}

/**
 * Allows a user to add up to so many skills.
 */
export function skillsLimitBenefit(
  value = 8,
): GenerateMembershipBenefitOptions {
  return {
    benefitName: 'skills_limit',
    value,
  };
}

/**
 * Allows a user to view the AI feature.
 * Part of the Plus, Professional and Premier membership plans.
 */
export function aiFeaturesBenefit(
  enabled: boolean,
): GenerateMembershipBenefitOptions {
  return {
    benefitName: 'ai_features',
    enabled,
  };
}

/**
 * Allows a user to make alternative profiles used when bidding
 * Multi profile benefit
 */
export function multiProfilesBenefit(
  enabled: boolean,
  value = 5,
): GenerateMembershipBenefitOptions {
  return {
    benefitName: 'multi_profiles',
    enabled,
    value,
  };
}

/**
 * Allows a user to follow an employer
 */
export function employerFollowBenefit(
  value = BASIC_MEMBERSHIP_EMPLOYER_FOLLOW_LIMIT,
): GenerateMembershipBenefitOptions {
  return {
    benefitName: 'employer_following',
    value,
  };
}

/**
 * Allows project posted by user to be able to set a budget
 */
export function enterpriseBenefit(
  enabled: boolean,
): GenerateMembershipBenefitOptions {
  return {
    benefitName: 'auto_enterprise',
    enabled,
  };
}
