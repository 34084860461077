import { Injectable, NgZone } from '@angular/core';
import type {
  BackgroundGeolocationConfig as RawBackgroundGeolocationConfig,
  BackgroundGeolocationResponse,
} from '@awesome-cordova-plugins/background-geolocation/ngx';
import {
  BackgroundGeolocation,
  BackgroundGeolocationEvents,
} from '@awesome-cordova-plugins/background-geolocation/ngx';
import type { Position } from '@capacitor/geolocation';
import { Geolocation as capacitorGeolocation } from '@capacitor/geolocation';
import type { PositionOptions } from '@capacitor/geolocation/dist/esm/definitions';
import { Pwa } from '@freelancer/pwa';
import { TimeUtils } from '@freelancer/time-utils';
import {
  permissionReason,
  Permissions,
  PermissionType,
} from '@freelancer/ui/permissions';
import { from, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

export interface GeolocationOptions {
  /**
   * Use that if you do need a high level of precision.
   * Use this sparingly: it's slower to resolve and uses more battery. If you
   * want to find the nearest Freelancers to a user, it's unlikely that you
   * need 1-meter precision.
   */
  enableHighAccuracy?: boolean;
  /**
   * Response maximum age in milleseconds.
   * This not only returns more quickly if the user has requested the data
   * before, but it also prevents the browser from starting its geolocation
   * hardware interfaces such as Wifi triangulation or the GPS.
   */
  maximumAge?: number;
  /**
   * Request timeout in millisecond. We currently set a 10 seconds default.
   */
  timeout?: number;
}

export type GeolocationResponse =
  | GeolocationSuccessResponse
  | GeolocationErrorResponse;

export interface GeolocationSuccessResponse {
  readonly status: 'success';
  readonly position: GeolocationPosition;
}

class CurrentPositionTimeoutError extends Error {
  constructor() {
    super('Geolocation timed out');
  }
}

export interface GeolocationPosition {
  // The GPS coordinates along with the accuracy of the data
  // Most of these are optionals as they depend on the device capabilities
  coords: {
    latitude: number;
    longitude: number;
    // meters above sea level
    altitude?: number;
    // accuracy or radius of accuracy in meters
    accuracy?: number;
    // accuracy or radius of accuracy in meters
    altitudeAccuracy?: number;
    // how many degrees from true North the device is moving
    heading?: number;
    // velocity in meters/second the device is moving
    speed?: number;
  };
  // Creation timestamp for coords
  timestamp: number;
}

export interface GeolocationErrorResponse {
  readonly status: 'error';
  readonly errorCode: GeolocationErrorCode;
  readonly message?: string;
}

export enum GeolocationErrorCode {
  // User denied the request for Geolocation
  // If you are getting that, you should have a fallback UI explaining to the
  // user when then need to authorize geolocation and how to do it.
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  // Location information is unavailable, e.g. device has no sensor
  POSITION_UNAVAILABLE = 'POSITION_UNAVAILABLE',
  // The request to get user location timed out, i.e. position could not be
  // collected within the timeout interval (underground with no wifi nor cell
  // network?)
  TIMEOUT = 'TIMEOUT',
  // An unknown error occurred, so much unknown that even the device doesn't
  // know why
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export interface BackgroundGeolocationConfig {
  readonly notificationTitle: string;
  readonly notificationText: string;
}

export interface BackgroundGeolocationTask {
  readonly status$: Observable<BackgroundGeolocationStatus>;
  stop(): void;
}

export type BackgroundGeolocationStatus =
  | {
      readonly status: 'running';
    }
  | {
      readonly status: 'stopped';
    }
  | {
      readonly status: 'error';
      readonly errorCode: GeolocationErrorCode;
    };

/*
 * A service wrapping the web platform Geolocation API, and providing
 * background geolocation capabilities in native contexts (i.e. when the app is
 * installed as a native app).
 */
@Injectable({
  providedIn: 'root',
})
export class Geolocation {
  private defaultOptions = {
    enableHighAccuracy: true,
    // Unless a timeout is set, the geolocation request might never return. We
    // set a 30 seconds default timeout to prevent that.
    timeout: 30 * 1000,
  };

  constructor(
    private backgroundGeolocation: BackgroundGeolocation,
    private ngZone: NgZone,
    private permissions: Permissions,
    private timeUtils: TimeUtils,
    private pwa: Pwa,
  ) {}

  /**
   * Get the user current position
   *
   * /!\ Always request access to location on a user gesture, Never call that
   * from a lifecycle hook at the request access window won't pop up.
   */
  async getCurrentPosition(
    options: GeolocationOptions = {},
  ): Promise<GeolocationResponse> {
    const mergedOptions = {
      ...this.defaultOptions,
      ...options,
    };
    if (!this.isGeolocationAvailable()) {
      return {
        status: 'error',
        errorCode: GeolocationErrorCode.POSITION_UNAVAILABLE,
      };
    }

    const permission = await this.permissions.requestPermissions(
      PermissionType.GEOLOCATION,
      permissionReason.GEOLOCATION,
    );

    if (!permission) {
      return {
        status: 'error',
        errorCode: GeolocationErrorCode.PERMISSION_DENIED,
      };
    }
    if (this.pwa.isNative()) {
      try {
        // The following function will ask for permission when
        // the result.state above is promt.
        const position = await this.capacitorGetCurrentPositionWithTimeout(
          mergedOptions,
        );
        return {
          status: 'success' as const,
          position: this.getPositionFromRawPosition(position),
        };
      } catch (error: any) {
        if (error instanceof CurrentPositionTimeoutError) {
          return {
            status: 'error',
            errorCode: GeolocationErrorCode.TIMEOUT,
          };
        }
        return {
          status: 'error',
          errorCode: this.getErrorCodeFromRawError(error),
        };
      }
    }
    return new Promise(resolve => {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          resolve({
            status: 'success',
            position: this.getPositionFromRawPosition(position),
          });
        },
        error =>
          resolve({
            status: 'error',
            errorCode: this.getErrorCodeFromRawError(error),
          }),
        mergedOptions,
      );
    });
  }

  /**
   * Watch the user location
   *
   * Location tracking will be paused when the app goes into background. Use
   * watchPositionBackground if you want to keep tracking the user position
   * while the app is in background.
   *
   * /!\ Always request access to location on a user gesture, Never call that
   * from a lifecycle hook at the request access window won't pop up.
   */
  watchPosition(
    options: GeolocationOptions = {},
  ): Observable<GeolocationResponse> {
    const mergedOptions = {
      ...this.defaultOptions,
      ...options,
    };
    if (!this.isGeolocationAvailable()) {
      return of({
        status: 'error',
        errorCode: GeolocationErrorCode.POSITION_UNAVAILABLE,
      });
    }

    return from(
      this.permissions.requestPermissions(
        PermissionType.GEOLOCATION,
        permissionReason.GEOLOCATION,
      ),
    ).pipe(
      switchMap(permission => {
        if (!permission) {
          return of({
            status: 'error',
            errorCode: this.getErrorCodeFromRawError({
              code: 1,
              message: 'Permission denied',
              PERMISSION_DENIED: 1,
              POSITION_UNAVAILABLE: 0,
              TIMEOUT: 0,
            }),
          } as GeolocationErrorResponse);
        }
        if (this.pwa.isNative()) {
          return this.nativeGeolocationWatchPosition(mergedOptions);
        }
        return new Observable<GeolocationResponse>(observer => {
          const watchId = window.navigator.geolocation.watchPosition(
            position => {
              observer.next({
                status: 'success',
                position: this.getPositionFromRawPosition(position),
              });
            },
            error => {
              observer.next({
                status: 'error',
                errorCode: this.getErrorCodeFromRawError(error),
              });
            },
            mergedOptions,
          );
          return () => window.navigator.geolocation.clearWatch(watchId);
        });
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * This method converts the native watchPosition from promise to an observable response
   * and bundles with a preliminary permission check if the user doesn't want to give
   * permission to the location service we have to stop running rest of the code and
   * asking for permission.
   *
   * @param mergedOptions
   * @private
   */
  private nativeGeolocationWatchPosition(
    mergedOptions: GeolocationOptions,
  ): Observable<GeolocationResponse> {
    return new Observable<GeolocationResponse>(observer => {
      // Watch the location changes coninously and the following
      // function will ask for permission when the result.state above is promt.

      const callbackIdPromise = capacitorGeolocation.watchPosition(
        mergedOptions,
        (position: Position | null, error: any) => {
          // Whether get the location or report error inside of
          // the angular zone
          this.ngZone.run(() => {
            if (error || position === null) {
              observer.next({
                status: 'error',
                errorCode: this.getErrorCodeFromRawError(error),
              });
            } else {
              observer.next({
                status: 'success',
                position: this.getPositionFromRawPosition(position),
              });
            }
          });
        },
      );

      // Return with a callback which removes the watcher function by id
      return async () => {
        const id = await callbackIdPromise;
        capacitorGeolocation.clearWatch({ id });
      };
    });
  }

  /**
   * Checks if geolocation is available
   */
  isGeolocationAvailable(): boolean {
    return !!window.navigator.geolocation || this.pwa.isNative();
  }

  /**
   * Checks if background geolocation is available
   */
  canWatchPositionBackground(): boolean {
    return this.pwa.isNative();
  }

  /**
   * Watch the user location while the app is in background
   *
   * /!\  Only currently supported through Capacitor, i.e. the app must be
   * installed. Call BackgroundGeolocation::canWatchPositionBackground() must
   * to ensure this is background geotracking is supported on the device.
   *
   * For Android the concept of backgournd tracking is a bit confusing
   * see https://github.com/HaylLtd/cordova-background-geolocation-plugin/issues/19
   */
  async watchPositionBackground(
    callback: (p: GeolocationPosition) => Promise<any>,
    config: BackgroundGeolocationConfig,
  ): Promise<BackgroundGeolocationTask> {
    if (!this.canWatchPositionBackground()) {
      return {
        status$: of({
          status: 'error',
          errorCode: GeolocationErrorCode.POSITION_UNAVAILABLE,
        }),
        stop: () => {
          // noop
        },
      };
    }

    await this.permissions.requestPermissions(
      PermissionType.GEOLOCATION,
      permissionReason.GEOLOCATION,
    );

    const mergedConfig: RawBackgroundGeolocationConfig = {
      ...{
        desiredAccuracy: 10,
        stationaryRadius: 50,
        distanceFilter: 50,
        notificationTitle: config.notificationTitle,
        notificationText: config.notificationText,
        interval: 10_000,
        fastestInterval: 5000,
        activitiesInterval: 10_000,
        startForeground: true,
      },
      ...config,
    };
    const statusSubject$ = new ReplaySubject<BackgroundGeolocationStatus>(1);

    const subscriptions = new Subscription();

    this.backgroundGeolocation.configure(mergedConfig).then(() => {
      subscriptions.add(
        this.backgroundGeolocation
          .on(BackgroundGeolocationEvents.location)
          .subscribe((rawLocation: BackgroundGeolocationResponse) => {
            /*
             * provider: "gps"
             * locationProvider: 0
             * time: 1586176100350
             * latitude: 44.82024509006005
             * longitude: -0.5782134212294999
             * accuracy: 24
             * speed: 0.12992942333221436
             * altitude: 39.42696268889978
             * bearing: 112.3025131225586
             * isFromMockProvider: false
             * mockLocationsEnabled: false
             * id: 3
             */
            this.ngZone
              .run(() =>
                callback(
                  this.getPositionFromRawPosition({
                    coords: {
                      latitude: rawLocation.latitude,
                      longitude: rawLocation.longitude,
                      altitude: rawLocation.altitude,
                      accuracy: rawLocation.accuracy,
                      altitudeAccuracy: null,
                      // TODO: T267853 - compute heading from bearing?
                      heading: null,
                      speed: rawLocation.speed,
                    },
                    timestamp: rawLocation.time,
                  }),
                ),
              )
              .then(() => {
                // This must be executed to inform the native plugin that the
                // background task may be completed or iOS will crash it for spending
                // too much time in the background
                this.backgroundGeolocation.finish();
              });
          }),
      );

      subscriptions.add(
        this.backgroundGeolocation
          .on(BackgroundGeolocationEvents.error)
          .subscribe(error => {
            console.log(error);
            // TODO: T267853 - add proper error codes here
            statusSubject$.next({
              status: 'error',
              errorCode: GeolocationErrorCode.UNKNOWN_ERROR,
            });
            console.error(error);
          }),
      );
    });

    // Start recording location
    this.backgroundGeolocation.start();
    statusSubject$.next({
      status: 'running',
    });

    return {
      status$: statusSubject$.asObservable(),
      stop: () => {
        this.backgroundGeolocation.stop();
        subscriptions.unsubscribe();
        statusSubject$.next({
          status: 'stopped',
        });
      },
    };
  }

  // This converts the raw error object into error codes, allowing ensuring
  // that people can't use the (untranslated & not user friendly) default
  // platform error strings in their error handling UIs.
  private getErrorCodeFromRawError(
    error: GeolocationPositionError,
  ): GeolocationErrorCode {
    // Android does not provide error codes at all, so we'll need to translate
    // the messages into a code.
    if (this.pwa.getPlatform() === 'android') {
      // On API 30 and above, 'Error: ' precedes the actual error message.
      // This ensures we only get the error message.
      const errorMsg = error.message.split('Error: ').pop()?.toLowerCase();
      if (errorMsg?.includes('user denied location permission')) {
        return GeolocationErrorCode.PERMISSION_DENIED;
      }
      if (errorMsg?.includes('location unavailable')) {
        return GeolocationErrorCode.POSITION_UNAVAILABLE;
      }
      if (errorMsg?.includes('location disabled')) {
        return GeolocationErrorCode.POSITION_UNAVAILABLE;
      }
      return GeolocationErrorCode.UNKNOWN_ERROR;
    }
    if (this.pwa.getPlatform() === 'ios') {
      // iOS does not provide error codes properly, so we need to regex it out.
      const iosError = error.message.match(/error (\d)/);
      // Assume the error code will be the last thing in the regex array.
      if (iosError) {
        switch (iosError.pop()) {
          case '0':
            return GeolocationErrorCode.TIMEOUT;
          case '1':
            return GeolocationErrorCode.PERMISSION_DENIED;
          default:
            return GeolocationErrorCode.UNKNOWN_ERROR;
        }
      }
      return GeolocationErrorCode.UNKNOWN_ERROR;
    }
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return GeolocationErrorCode.PERMISSION_DENIED;
      case error.POSITION_UNAVAILABLE:
        return GeolocationErrorCode.POSITION_UNAVAILABLE;
      case error.TIMEOUT:
        return GeolocationErrorCode.TIMEOUT;
      default:
        return GeolocationErrorCode.UNKNOWN_ERROR;
    }
  }

  // This converts null values into undefined as we ban the use of null
  // throughout the webapp.
  private getPositionFromRawPosition(p: {
    coords: Partial<GeolocationCoordinates>;
    timestamp: number;
  }): GeolocationPosition {
    if (
      !p.coords ||
      !p.coords.latitude ||
      !p.coords.longitude ||
      !p.timestamp
    ) {
      throw new Error(`Invalid position object ${JSON.stringify(p)}`);
    }
    return {
      coords: {
        latitude: p.coords.latitude,
        longitude: p.coords.longitude,
        altitude: p.coords.altitude ?? undefined,
        accuracy: p.coords.accuracy ?? undefined,
        altitudeAccuracy: p.coords.altitudeAccuracy ?? undefined,
        heading: p.coords.heading ?? undefined,
        speed: p.coords.speed ?? undefined,
      },
      timestamp: p.timestamp,
    };
  }

  /**
   * Timeout is now ignored for getCurrentPosition() in Capacitor 4
   * Wrap it in a function to provide a timeout.
   */
  private async capacitorGetCurrentPositionWithTimeout(
    options?: PositionOptions,
  ): Promise<Position> {
    const timeout = options?.timeout;
    return new Promise(async (resolve, reject) => {
      let timeoutId;
      if (timeout && !Number.isNaN(timeout)) {
        // Set a timeout to reject the promise if it takes too long
        timeoutId = this.timeUtils.setTimeout(() => {
          reject(new CurrentPositionTimeoutError());
        }, timeout);
      }

      try {
        const position = await capacitorGeolocation.getCurrentPosition(options);
        clearTimeout(timeoutId);
        resolve(position);
      } catch (error) {
        clearTimeout(timeoutId);
        reject(error);
      }
    });
  }
}
