import { generateId } from '@freelancer/datastore/testing/helpers';
import type { VerificationAddressDocumentType } from './verification-address-document-types.model';

export interface GenerateVerificationAddressDocumentTypesOptions {
  readonly countryCode?: string;
}

export function generateVerificationAddressDocumentTypesObjects({
  countryCode = 'SG',
}: GenerateVerificationAddressDocumentTypesOptions = {}): readonly VerificationAddressDocumentType[] {
  // Return the default address doc types. See KycRequirement::getDefaultAddressNames()
  return [
    {
      id: generateId().toString(),
      backendId: 1,
      countryCode,
      name: 'Bank Statement',
    },
    {
      id: generateId().toString(),
      backendId: 2,
      countryCode,
      name: 'Utility Bill',
    },
    {
      id: generateId().toString(),
      backendId: 5,
      countryCode,
      name: 'Others',
    },
  ];
}
