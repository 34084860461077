import { generateId } from '@freelancer/datastore/testing/helpers';
import { TeamRoleApi } from 'api-typings/users/users';
import type { TeamMember, TeamMemberPermissions } from './team-members.model';

export interface GenerateTeamMembersOptions {
  readonly id?: number;
  readonly teamId?: number;
  readonly userId: number;
  readonly description?: string;
  readonly role?: TeamRoleApi;
  readonly permissions?: TeamMemberPermissions;
  readonly isDeleted?: boolean;
  readonly accessToken?: string;
  readonly lastUpdated?: number;
  readonly joined?: number;
}

export function generateTeamMemberObject({
  id = generateId(),
  teamId = generateId(),
  userId,
  description,
  role = TeamRoleApi.MEMBER,
  permissions = { payments: false, projects: false },
  isDeleted = false,
  accessToken,
  lastUpdated,
  joined,
}: GenerateTeamMembersOptions): TeamMember {
  return {
    id,
    teamId,
    userId,
    description,
    role,
    permissions,
    isDeleted,
    accessToken,
    lastUpdated,
    joined,
  };
}

export function generateTeamMemberObjects(
  members: readonly GenerateTeamMembersOptions[],
): readonly TeamMember[] {
  return members.map(generateTeamMemberObject);
}

export function withPermissions(
  projects: boolean,
  payments: boolean,
): Pick<GenerateTeamMembersOptions, 'permissions'> {
  return {
    permissions: {
      payments,
      projects,
    },
  };
}
