import type {
  SelectAcceptedType,
  SelectGroups,
  SelectItem,
} from './select.types';

export function toSelectItems<T extends Object>(
  arr: readonly T[],
): readonly SelectItem<T>[] {
  return arr.map(item => ({ displayText: item.toString(), value: item }));
}

export function isSelectGroups<T>(
  option: SelectAcceptedType<T>,
): option is SelectGroups<T> {
  return typeof option !== 'string' && 'groupName' in option;
}

export function isSelectItem<T>(
  option: SelectAcceptedType<T>,
): option is SelectItem<T> {
  return typeof option !== 'string' && 'displayText' in option;
}
