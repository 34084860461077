import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  QuotationContextTypeApi,
  QuotationStatusApi,
  QUOTATION_DESCRIPTION_MIN_LENGTHApi,
} from 'api-typings/quotations/quotations';
import { addDays } from 'date-fns';
import type { ServiceOffering } from '../service-offerings';
import type {
  QuotationAmount,
  QuotationRevision,
} from './quotation-revisions.model';

export interface GenerateQuotationRevisionOptions {
  readonly creatorId?: number;
  readonly quotationVersionId?: number; // quotation version id
  readonly quotationUid?: string;
  readonly quotationVersionUid?: string;
  readonly quotationId?: number;
  readonly contextType?: QuotationContextTypeApi;
  readonly contextId?: number;
  readonly createDate?: number;
  readonly isRequestedByRecipient?: boolean;
  readonly defaultCurrencyId?: number;
  readonly approveDate?: number;
  readonly paidAmount?: number;
  readonly fundedAmount?: number;
  readonly quotePrice?: number;
  readonly recipientId?: number;
  readonly title?: string;
  readonly description?: string;
  readonly richDescription?: string;
  readonly versionInvalidDate?: number;
  readonly parentVersionId?: number;
  readonly parentTemplateVersionId?: number;
  readonly status?: QuotationStatusApi;
  readonly estimatedStartDate?: number;
  readonly estimatedEndDate?: number;
  readonly upfrontFundRequestAmount?: number;
  readonly expiryDate?: number;
  readonly cancelDate?: number;
  readonly rejectDate?: number;
  readonly durationInDays?: number;
  readonly logoId?: number;
}

type PendingQuoteRequiredFields = Pick<
  GenerateQuotationRevisionOptions,
  | 'status'
  | 'description'
  | 'title'
  | 'estimatedStartDate'
  | 'estimatedEndDate'
  | 'expiryDate'
>;

export type QuotationStatusOptions = Pick<
  GenerateQuotationRevisionOptions,
  | 'status'
  | 'expiryDate'
  | 'cancelDate'
  | 'rejectDate'
  | 'cancelDate'
  | 'approveDate'
>;

// Returns an object of type QuotationAmount
function getQuotationAmountObject({
  currencyId,
  amount,
}: {
  readonly currencyId: number;
  readonly amount: number;
}): QuotationAmount {
  return { [currencyId]: amount };
}

function getValidDescription(): string {
  return 'a'.repeat(QUOTATION_DESCRIPTION_MIN_LENGTHApi);
}

export function generateQuotationRevisionObject({
  creatorId,
  quotationVersionId,
  quotationId,
  quotationUid,
  quotationVersionUid,
  contextType = QuotationContextTypeApi.PROJECT,
  contextId,
  createDate = Date.now(),
  isRequestedByRecipient = false,
  defaultCurrencyId = 1,
  approveDate,
  paidAmount = 0,
  fundedAmount = 0,
  quotePrice = 10,
  recipientId,
  title,
  description,
  richDescription,
  versionInvalidDate,
  parentVersionId,
  parentTemplateVersionId,
  status = QuotationStatusApi.PENDING,
  estimatedStartDate,
  estimatedEndDate,
  upfrontFundRequestAmount,
  expiryDate,
  cancelDate,
  rejectDate,
  durationInDays,
  logoId,
}: GenerateQuotationRevisionOptions = {}): QuotationRevision {
  return {
    id: quotationVersionId ?? generateId(),
    quotationId: quotationId ?? generateId(),
    quotationUid: quotationUid ?? generateUid(10),
    versionUid: quotationVersionUid ?? generateUid(11),
    creatorId: creatorId ?? generateId(),
    contextType,
    contextId: contextId ?? generateId(),
    createDate,
    isRequestedByRecipient,
    defaultCurrencyId,
    approveDate,
    paidAmount: getQuotationAmountObject({
      currencyId: defaultCurrencyId,
      amount: paidAmount,
    }),
    fundedAmount: getQuotationAmountObject({
      currencyId: defaultCurrencyId,
      amount: fundedAmount,
    }),
    quotePrice: getQuotationAmountObject({
      currencyId: defaultCurrencyId,
      amount: quotePrice,
    }),
    recipientId,
    title,
    description,
    richDescription,
    versionInvalidDate,
    parentVersionId,
    parentTemplateVersionId,
    status,
    estimatedStartDate,
    estimatedEndDate,
    upfrontFundRequestAmount,
    expiryDate,
    cancelDate,
    rejectDate,
    durationInDays,
    logoId,
  };
}

export function generateTimeline({
  estimatedStartDate,
  numDays,
}: {
  readonly estimatedStartDate: number;
  readonly numDays: number;
}): Pick<
  GenerateQuotationRevisionOptions,
  'estimatedStartDate' | 'estimatedEndDate'
> {
  const estimatedEndDate: number = addDays(
    estimatedStartDate,
    numDays,
  ).getTime();
  return {
    estimatedStartDate,
    estimatedEndDate,
  };
}

export function draftQuotation(): Pick<
  GenerateQuotationRevisionOptions,
  'status'
> {
  return { status: QuotationStatusApi.DRAFT };
}

export function currencyUSD(): Pick<
  GenerateQuotationRevisionOptions,
  'defaultCurrencyId'
> {
  return { defaultCurrencyId: 1 };
}

export function withUserContext(
  userId: number,
): Pick<GenerateQuotationRevisionOptions, 'contextType' | 'contextId'> {
  return {
    contextType: QuotationContextTypeApi.USER,
    contextId: userId,
  };
}

export function approvedQuotation(): Pick<
  QuotationStatusOptions,
  'status' | 'approveDate'
> {
  return { status: QuotationStatusApi.APPROVED, approveDate: Date.now() };
}

export function invalidQuotation(): Pick<
  GenerateQuotationRevisionOptions,
  'status' | 'versionInvalidDate'
> {
  return { status: QuotationStatusApi.INVALID, versionInvalidDate: Date.now() };
}

export function rejectedQuotation(): Pick<
  QuotationStatusOptions,
  'status' | 'rejectDate'
> {
  return { status: QuotationStatusApi.REJECTED, rejectDate: Date.now() };
}

export function cancelledQuotation(): Pick<
  QuotationStatusOptions,
  'status' | 'cancelDate'
> {
  return { status: QuotationStatusApi.CANCELLED, cancelDate: Date.now() };
}

export function expiredQuotation(
  expiryDate?: Date,
): Pick<QuotationStatusOptions, 'status' | 'expiryDate'> {
  const pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 7);
  return {
    status: QuotationStatusApi.EXPIRED,
    expiryDate: expiryDate ? expiryDate.getTime() : pastDate.getTime(),
  };
}

export function generateUid(length: number): string {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = 0; i < length; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function publishedQuotation(
  options: Pick<
    GenerateQuotationRevisionOptions,
    'status' | 'description' | 'title' | 'expiryDate'
  > = {},
): PendingQuoteRequiredFields {
  const { status, description, title, expiryDate } = options;
  const estimatedStartDate = Date.now();
  const estimatedEndDate = addDays(estimatedStartDate, 1).getTime();

  return {
    status: status ?? QuotationStatusApi.PENDING,
    description: description ?? getValidDescription(),
    title: title ?? 'A valid title',
    estimatedStartDate,
    estimatedEndDate,
    expiryDate: expiryDate ?? addDays(Date.now(), 30).getTime(),
  };
}

export function externalQuotation(
  invitationId?: number,
): Pick<GenerateQuotationRevisionOptions, 'contextType' | 'contextId'> {
  const pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 7);
  return {
    contextType: QuotationContextTypeApi.EXTERNAL,
    contextId: invitationId ?? 0,
  };
}

export function setExpiryDate(
  daysFromNow: number,
): Pick<GenerateQuotationRevisionOptions, 'expiryDate'> {
  return { expiryDate: addDays(Date.now(), daysFromNow).getTime() };
}

export function templateQuotation(): Pick<
  GenerateQuotationRevisionOptions,
  'contextType' | 'contextId'
> {
  return {
    contextType: QuotationContextTypeApi.TEMPLATE,
    contextId: 0,
  };
}

export function serviceQuotation(
  serviceId: number,
): Pick<GenerateQuotationRevisionOptions, 'contextType' | 'contextId'> {
  return {
    contextType: QuotationContextTypeApi.TEMPLATE,
    contextId: serviceId,
  };
}

export function withDuration(
  durationInDays: number,
): Pick<GenerateQuotationRevisionOptions, 'durationInDays'> {
  return {
    durationInDays,
  };
}

export function fromServiceOffering(
  serviceOffering: ServiceOffering,
): Pick<
  QuotationRevision,
  | 'title'
  | 'description'
  | 'richDescription'
  | 'contextType'
  | 'contextId'
  | 'creatorId'
  | 'createDate'
> {
  return {
    title: serviceOffering.title,
    description: serviceOffering.description,
    richDescription: serviceOffering.richDescription,
    contextType: QuotationContextTypeApi.TEMPLATE,
    contextId: serviceOffering.id,
    creatorId: serviceOffering.creatorId,
    createDate: serviceOffering.createDate ?? Date.now(),
  };
}
