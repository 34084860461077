import { generateId } from '@freelancer/datastore/testing/helpers';
import { ContextTypeApi } from 'api-typings/common/common';
import { ContestCollaborationPermissionApi } from 'api-typings/contests/contests';
import { ProjectCollaborationStatusApi } from 'api-typings/projects/projects';
import type { ContestCollaboration } from './contest-collaborations.model';

export interface GenerateContestCollaborationsOptions {
  readonly userIds: readonly number[];
  readonly contestId: number;
  readonly contestOwnerId: number;
  readonly status?: ProjectCollaborationStatusApi;
}

export interface GenerateContestCollaborationOptions {
  readonly userId: number;
  readonly contestId: number;
  readonly contestOwnerId: number;
  readonly status?: ProjectCollaborationStatusApi;
}

export function generateContestCollaborationObjects({
  userIds,
  contestOwnerId,
  contestId,
  status = ProjectCollaborationStatusApi.ACTIVE,
}: GenerateContestCollaborationsOptions): readonly ContestCollaboration[] {
  return userIds.map(userId =>
    generateContestCollaborationObject({
      userId,
      contestOwnerId,
      contestId,
    }),
  );
}

export function generateContestCollaborationObject({
  userId,
  contestOwnerId,
  contestId,
  status = ProjectCollaborationStatusApi.ACTIVE,
}: GenerateContestCollaborationOptions): ContestCollaboration {
  return {
    id: generateId(),
    userId,
    context: {
      id: contestId,
      type: ContextTypeApi.CONTEST,
    },
    contextOwnerId: contestOwnerId,
    contestCollaborationPermissions: [
      ContestCollaborationPermissionApi.ENTRY_AWARD,
      ContestCollaborationPermissionApi.ENTRY_RATE,
      ContestCollaborationPermissionApi.ENTRY_REJECT,
    ],
    status,
  };
}
