import { generateId } from '@freelancer/datastore/testing/helpers';
import { MembershipBadgeType } from '@freelancer/ui/badge';
import { generateUserBalanceObjects } from '../user-balances/user-balances.seed';
import type { UsersSelf } from '../users-self/users-self.model';
import type { User } from '../users/users.model';
import { generateAvatar } from '../users/users.seed';
import type { UserInfo, UserInfoSwitchUser } from './user-info.model';

export interface GenerateUserInfoOptions {
  readonly userId: number;
  readonly username?: string;
  readonly publicName?: string;
  readonly canSwitchToUsers?: readonly UserInfoSwitchUser[];
}

export function generateUserInfoObject({
  userId,
  username = 'testUsername',
  publicName = 'Test Name',
  canSwitchToUsers = [],
}: GenerateUserInfoOptions): UserInfo {
  return {
    id: userId.toString(),
    userId,
    username,
    publicName,
    isFreeTrialEligible: false,
    isPlusTrialEligible: false,
    isPremium: false,
    avatar: generateAvatar(userId),
    membership: 'plus',
    membershipBadge: MembershipBadgeType.MONTHLY_LEVEL_THREE,
    hideMembershipUpgradeButton: false,
    monthlyTrial: false,
    canSwitchToUsers,
    isNoCommissionEligibleReferrer: false,
    isCorporate: false,
  };
}

export function userInfoFromUser(
  user: User | UsersSelf,
): Pick<GenerateUserInfoOptions, 'userId' | 'username' | 'publicName'> {
  return {
    userId: user.id,
    username: user.username,
    publicName: user.displayName,
  };
}

export function userInfoWithSwitchUser({
  userId = generateId(),
  username = 'switchUsername',
  publicName = 'Switch Name',
}): Pick<Required<GenerateUserInfoOptions>, 'canSwitchToUsers'> {
  const [balance] = generateUserBalanceObjects();

  return {
    canSwitchToUsers: [
      {
        userId,
        username,
        publicName,
        balance,
        avatar: generateAvatar(userId),
      },
    ],
  };
}
