import { generateId } from '@freelancer/datastore/testing/helpers';
import type { PartialBy } from '@freelancer/types';
import { TextEntityContextTypeApi } from 'api-typings/common/common';
import { SourceTypeApi } from 'api-typings/messages/messages_types';
import type { TextEntity } from '../feed-posts/feed-posts.model';
import type { Comment, CommentAttachment } from './comments.model';

export interface GenerateCommentOption {
  readonly commentId?: number;
  readonly fromUser: number;
  readonly comment: string;
  readonly parentId?: number;
  readonly threadId: number;
  readonly commentSource?: SourceTypeApi;
  readonly textEntities?: readonly PartialBy<
    PartialBy<TextEntity, 'id'>,
    'contextType'
  >[];
  readonly attachments?: readonly CommentAttachment[];
}

export interface GenerateCommentOptions {
  readonly fromUser: number;
  readonly comments: readonly string[];
  readonly parentId?: number;
  readonly threadId: number;
  readonly commentSource?: SourceTypeApi;
  readonly textEntities?: readonly PartialBy<
    PartialBy<TextEntity, 'id'>,
    'contextType'
  >[];
}

export function generateCommentObject({
  commentId = generateId(),
  comment,
  fromUser,
  threadId,
  parentId,
  commentSource = SourceTypeApi.DEFAULT_MSG,
  textEntities = [],
  attachments = [],
}: GenerateCommentOption): Comment {
  return {
    id: commentId,
    comment,
    fromUser,
    threadId,
    parentId,
    commentSource,
    textEntities: textEntities.map(textEntity => ({
      id: generateId(),
      contextId: commentId,
      contextType: TextEntityContextTypeApi.COMMENT,
      ...textEntity,
    })),
    attachments,
    timeCreated: Date.now(),
    hasReplies: true,
    removeReason: undefined,
  };
}

export function generateCommentObjects({
  comments,
  fromUser,
  threadId,
  parentId,
  commentSource = SourceTypeApi.DEFAULT_MSG,
  textEntities = [],
}: GenerateCommentOptions): readonly Comment[] {
  return comments.map(comment =>
    generateCommentObject({
      comment,
      fromUser,
      threadId,
      parentId,
      commentSource,
      textEntities,
    }),
  );
}
