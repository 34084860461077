import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeadingType } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { FontType, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-about',
  template: `
    <fl-bit class="MainContainer">
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-grid>
          <fl-col [col]="8">
            <fl-heading
              [headingType]="HeadingType.H1"
              [size]="TextSize.MID"
              [flMarginBottom]="'xsmall'"
            >
              Introduction
            </fl-heading>
            <fl-text
              [size]="TextSize.XSMALL"
              [fontType]="FontType.CONTAINER"
              [flMarginBottom]="'mid'"
            >
              <fl-text
                [size]="TextSize.INHERIT"
                [flMarginBottom]="'xsmall'"
              >
                This is Freelancer's official design system, Bits. It is
                maintained by the
                <strong>Design and UI engineering teams</strong>.
              </fl-text>
              <fl-text
                [size]="TextSize.INHERIT"
                [flMarginBottom]="'xsmall'"
              >
                Our goal is to create a system that allows us to build
                consistent user experiences, with flexibility to support the
                Freelancer website. Creating a shared language between designers
                and developers to design and develop efficiently and
                effectively.
              </fl-text>
              <fl-text [size]="TextSize.INHERIT">
                This is up to date and active source of truth for all of our
                components available in the Angular front-end stack.
              </fl-text>
            </fl-text>
            <fl-heading
              [headingType]="HeadingType.H3"
              [size]="TextSize.SMALL"
              [flMarginBottom]="'xsmall'"
            >
              Why use Bits?
            </fl-heading>
            <fl-text
              [size]="TextSize.XSMALL"
              [fontType]="FontType.CONTAINER"
              [flMarginBottom]="'xsmall'"
            >
              <fl-list [type]="ListItemType.UNORDERED">
                <fl-list-item>
                  Efficiency: Instead of repeatedly building similar components
                  from scratch, Bits enables designers & developers to reuse
                  components and thereby increase efficiency.
                </fl-list-item>
                <fl-list-item>
                  Consistency: It introduces a shared set of principles and
                  rules to build components. It becomes much easier to create
                  consistent experiences across different platforms.
                </fl-list-item>
                <fl-list-item>
                  Scale: Increased efficiency and consistency lead a company to
                  build faster products at scale.
                </fl-list-item>
                <fl-list-item>
                  Collaboration: Bits is a shared language that allows
                  multidisciplinary and cross team collaboration.
                </fl-list-item>
              </fl-list>
            </fl-text>
          </fl-col>
          <fl-col [col]="4">
            <fl-picture
              [src]="'bits/bits-introduction.svg'"
              [fullWidth]="true"
            ></fl-picture>
          </fl-col>
        </fl-grid>
      </fl-card>
      <fl-card
        class="SectionCard"
        [flMarginBottom]="'mid'"
        [edgeToEdge]="true"
      >
        <fl-heading
          [headingType]="HeadingType.H3"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'xxsmall'"
        >
          Need help?
        </fl-heading>
        <fl-text
          [size]="TextSize.XSMALL"
          [fontType]="FontType.CONTAINER"
        >
          <fl-text [size]="TextSize.INHERIT">
            If you have any questions, kindly read the
            <fl-link
              [size]="TextSize.INHERIT"
              link="/info/faq"
            >
              FAQs
            </fl-link>
            and
            <fl-link
              [size]="TextSize.INHERIT"
              link="/info/how-to-contribute"
            >
              How to Contribute
            </fl-link>
            , or reach out to #UI-Engineering Rocketchat channel.
          </fl-text>
        </fl-text>
      </fl-card>
    </fl-bit>
  `,
  styleUrls: ['../static-pages.component.scss', './about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent {
  TextSize = TextSize;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  ListItemType = ListItemType;
}
