import { TaxTypeApi } from 'api-typings/common/common';
import type { TaxOptOutOption } from './tax-opt-out-options.model';

export function generateTaxOptOutOptionObjects(): readonly TaxOptOutOption[] {
  return [
    ...generateTcsOptOutOptions(),
    ...generateVatOptOutOptions(),
    ...generateTdsOptOutOptions(),
  ];
}

export function generateTcsOptOutOptions(): readonly TaxOptOutOption[] {
  return [
    {
      id: 1,
      type: TaxTypeApi.TCS,
      countryCode: 'IN',
      reason:
        'My aggregate turnover in a financial year is less than Rs 20 lakhs (Rs 10 lakhs for North Eastern and hill states)',
      order: 1,
      requestDetails: false,
    },
    {
      id: 2,
      type: TaxTypeApi.TCS,
      countryCode: 'IN',
      reason: 'My services are exempt from GST',
      order: 2,
      requestDetails: false,
    },
    {
      id: 3,
      type: TaxTypeApi.TCS,
      countryCode: 'IN',
      reason: 'Other',
      order: 3,
      requestDetails: true,
    },
  ];
}

export function generateVatOptOutOptions(): readonly TaxOptOutOption[] {
  return [
    {
      id: 4,
      type: TaxTypeApi.VAT,
      countryCode: 'EU',
      reason:
        'My aggregate annual turnover is less than VAT registration threshold',
      order: 1,
      requestDetails: false,
    },
    {
      id: 5,
      type: TaxTypeApi.VAT,
      countryCode: 'EU',
      reason: 'My services are exempt from VAT',
      order: 2,
      requestDetails: false,
    },
    {
      id: 6,
      type: TaxTypeApi.VAT,
      countryCode: 'EU',
      reason: 'Other',
      order: 3,
      requestDetails: true,
    },
  ];
}

export function generateTdsOptOutOptions(): readonly TaxOptOutOption[] {
  return [
    {
      id: 7,
      type: TaxTypeApi.TDS,
      countryCode: 'IN',
      reason: 'I will earn more than TDS threshold',
      order: 1,
      requestDetails: false,
    },
  ];
}
