import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { HorizontalAlignment, VerticalAlignment } from './grid.types';

@Component({
  selector: 'fl-grid',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent {
  @HostBinding('attr.data-horizontal-alignment')
  @Input()
  hAlign?: HorizontalAlignment;
  @HostBinding('attr.data-vertical-alignment')
  @Input()
  vAlign?: VerticalAlignment;

  /** Forces the columns into one row */
  @HostBinding('attr.data-overflow')
  @Input()
  overflow = false;
}
