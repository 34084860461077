import { generateId } from '@freelancer/datastore/testing/helpers';
import type { UserQualityDetails } from './user-quality-details.model';

export interface GenerateUserQualityDetailsOptions {
  readonly userIds: readonly number[];
}

export interface GenerateUserQualityDetailOptions {
  readonly userId?: number;
  readonly duplicateDeviceUsers?: readonly number[];
  readonly duplicateDevicesLogEntry?: boolean;
  readonly emailRating?: number;
  readonly lowEmailRating?: boolean;
  readonly hasDeposited?: boolean;
  readonly activeSellerReviewCount?: number;
  readonly recentlyRejectedProjectCount?: number;
  readonly latestPaypalEmail?: string;
}

export function generateUserQualityDetailsObjects({
  userIds,
}: GenerateUserQualityDetailsOptions): readonly UserQualityDetails[] {
  return userIds.map(userId =>
    generateUserQualityDetailsObject({
      userId,
    }),
  );
}

export function generateUserQualityDetailsObject({
  userId = generateId(),
  duplicateDeviceUsers = [],
  duplicateDevicesLogEntry = false,
  emailRating = Math.random(),
  lowEmailRating = false,
  hasDeposited = false,
  activeSellerReviewCount = generateRandomCount(),
  recentlyRejectedProjectCount = generateRandomCount(),
  latestPaypalEmail = 'hello.world@test.com',
}: GenerateUserQualityDetailOptions = {}): UserQualityDetails {
  return {
    id: userId,
    duplicateDeviceUsers,
    duplicateDevicesLogEntry,
    emailRating,
    lowEmailRating,
    hasDeposited,
    activeSellerReviewCount,
    recentlyRejectedProjectCount,
    latestPaypalEmail,
  };
}

export function generateRandomCount(count?: number): number {
  return count ?? Math.floor(Math.random() * 10);
}
