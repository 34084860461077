import { generateId } from '@freelancer/datastore/testing/helpers';
import { MilestoneRequestStatusApi } from 'api-typings/projects/projects';
import type { Bid } from '../bids';
import type { Currency } from '../currencies';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type { MilestoneRequestAction } from './milestone-requests.backend-model';
import type { MilestoneRequest } from './milestone-requests.model';

export interface GenerateMilestoneRequestsOptions {
  readonly milestoneRequests: readonly MilestoneRequest[];
}

export function generateMilestoneRequestObjects({
  milestoneRequests,
}: GenerateMilestoneRequestsOptions): readonly MilestoneRequest[] {
  return milestoneRequests.map(milestoneReq => ({
    ...generateMilestoneRequestObject({
      id: milestoneReq.id,
      bidderId: milestoneReq.bidderId,
      bidId: milestoneReq.bidId,
      projectId: milestoneReq.projectId,
      amount: milestoneReq.amount,
      status: milestoneReq.status,
      description: milestoneReq.description,
    }),
  }));
}

export interface GenerateMilestoneRequestsOption {
  readonly id?: number;
  readonly bidderId: number;
  readonly bidId: number;
  readonly projectId: number;
  readonly amount: number;
  readonly timeRequested?: number;
  readonly projectOwnerId?: number;
  readonly description?: string;
  readonly fundByDate?: number;
  readonly currency?: Currency;
  readonly status?: MilestoneRequestStatusApi;
  readonly action?: MilestoneRequestAction;
  readonly requesterId?: number;
  readonly isInitialPayment?: boolean;
}

export function generateMilestoneRequestObject({
  id = generateId(),
  bidderId = 1,
  bidId = 1,
  projectId = 1,
  amount = 20,
  description = 'Milestone Request',
  fundByDate = undefined,
  status = undefined,
  currency = generateCurrencyObject(CurrencyCode.USD),
  timeRequested = Date.now(),
}: GenerateMilestoneRequestsOption): MilestoneRequest {
  return {
    id,
    bidderId,
    bidId,
    projectId,
    amount,
    description,
    fundByDate,
    status,
    currency,
    timeRequested,
  };
}

export function milestoneRequestFromBid(
  bid: Bid,
): Pick<
  GenerateMilestoneRequestsOption,
  | 'bidderId'
  | 'bidId'
  | 'projectId'
  | 'amount'
  | 'description'
  | 'status'
  | 'projectOwnerId'
> {
  return {
    bidderId: bid.bidderId,
    bidId: bid.id,
    projectId: bid.projectId,
    amount: bid.amount,
    description: bid.description,
    status: MilestoneRequestStatusApi.PENDING,
    projectOwnerId: bid.projectOwnerId,
  };
}

export function pendingMilestoneRequest(): Pick<
  GenerateMilestoneRequestsOption,
  'status'
> {
  return {
    status: MilestoneRequestStatusApi.PENDING,
  };
}
