import {
  ContractTypeApi,
  ContractContextTypeApi,
} from 'api-typings/users/users';
import type { ContractDownloadUrl } from './contract-download-urls.model';

export interface GenerateContractDownloadOptions {
  readonly userId: number;
  readonly contractType?: ContractTypeApi;
  readonly contextType?: ContractContextTypeApi;
  readonly contextId?: number;
  readonly downloadUrl: string;
  readonly customContractId: number;
}

/**
 * Creates a ContractDownloadUrl object.
 */
export function generateContractDownloadUrlObject({
  contractType = ContractTypeApi.CLIENT_AGREEMENT,
  contextType = ContractContextTypeApi.PROJECT,
  contextId,
  userId,
  downloadUrl,
  customContractId,
}: GenerateContractDownloadOptions): ContractDownloadUrl {
  return {
    id: `${contextType}-${contextId}-${customContractId}`,
    userId,
    contractType,
    contextType,
    contextId,
    downloadUrl,
    customContractId,
  };
}
