import type {
  ProjectExperiments,
  SessionExperiments,
  UserExperiments,
} from '../experiments';

export const sessionExperiments: Partial<SessionExperiments> = {
  'T301279-pjp-omni-update-test': 'control',
  'T298055-google-one-tap': 'control',
};

export const userExperiments: Partial<UserExperiments> = {
  'T111865-logged-in-pjp-live-chat-just-enrollment': 'control',
  'T90189-image-thumbnail-service': 'control',
  webapp_playground_test: 'control',
  'T195472-web-push-notifications': 'control',
  'T281208-group-search-by-name': 'control',
  'T294922-share-revamp': 'control',
  'T296533-freelancer-onboarding-remove-payment-verification-step': 'control',
  'T298323-profile-redesign-noop': 'control',
  'T296370-profile-redesign': 'control',
  'T296347-pvp-floating-action-button': 'control',
  'T299115-activation-remarketing-newly-signed': 'control',
  'T299134-activation-remarketing-release-milestone-noop': 'control',
  'T299014-external-references-v1': 'control',
  'T301219-chain-funding': 'control',
  'T300667-paypal-passwordless-checkout-native': 'control',
  'T286144-share-revamp-v2': 'control',
  'T302255-chatbox-on-profile': 'control',
  'T302575-project-workspace-page-noop': 'control',
};
export const projectExperiments: Partial<ProjectExperiments> = {};

export const isWhitelistUser = false;

export const shouldEnrol = true;
