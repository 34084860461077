import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { trackByValue } from '@freelancer/ui/helpers';
import { IconColor, IconSize } from '@freelancer/ui/icon';
import { TextAlign, TextSize } from '@freelancer/ui/text';
import type { PaginationLabel } from './pagination.types';
import { PaginationLabelOptions } from './pagination.types';

@Component({
  selector: 'fl-pagination',
  template: `
    <ng-container *ngIf="pages > 1">
      <div class="ButtonGroup">
        <!-- first -->
        <div
          *ngIf="extremeties && shouldShowAdditionalControls && !compact"
          class="PaginationItem"
          [flHideMobile]="mobileCompact"
        >
          <fl-button
            class="PaginationButton HoverColor"
            i18n-label="Pagination first page button"
            label="First page"
            [ngClass]="{
              Disabled: currentPage === 1,
              CustomColor: outline === true
            }"
            [color]="outline ? ButtonColor.CUSTOM : ButtonColor.TEXT_FOREGROUND"
            [size]="ButtonSize.MINI"
            [disabled]="currentPage === 1"
            (click)="goToPage(1)"
          >
            <fl-icon
              i18n-label="First Page Icon"
              label="First Page Icon"
              [name]="'ui-first-page'"
              [size]="IconSize.SMALL"
            ></fl-icon>
          </fl-button>
        </div>

        <!-- Previous -->
        <div
          *ngIf="shouldShowAdditionalControls || compact || mobileCompact"
          class="PaginationItem"
          [flShowMobile]="mobileCompact && !shouldShowAdditionalControls"
        >
          <fl-button
            class="PaginationButton HoverColor"
            i18n-label="Pagination previous page button"
            label="Previous page"
            [ngClass]="{
              Disabled: currentPage === 1,
              CustomColor: outline === true
            }"
            [color]="outline ? ButtonColor.CUSTOM : ButtonColor.TEXT_FOREGROUND"
            [size]="ButtonSize.MINI"
            [disabled]="currentPage === 1"
            [attr.data-compact]="compact"
            (click)="goToPage(currentPage - 1)"
          >
            <fl-icon
              i18n-label="Previous Page Icon"
              label="Previous Page Icon"
              [name]="'ui-chevron-left'"
              [size]="IconSize.SMALL"
            ></fl-icon>
          </fl-button>
        </div>

        <!-- Numbered -->
        <div
          *ngIf="!compact"
          class="NumeralContainer"
          [flHideMobile]="mobileCompact"
        >
          <div
            class="PaginationItem"
            *ngFor="let numeral of numerals; trackBy: trackByValue"
          >
            <fl-button
              class="PaginationButton"
              [ngClass]="{
                CustomColor: outline && numeral !== currentPage,
                HoverColor:
                  numeral !== currentPage ||
                  (numeral == currentPage && outline),
                HoverColorCurrent: numeral === currentPage && outline
              }"
              [color]="
                numeral === currentPage ? buttonColorSelected : buttonColor
              "
              [size]="ButtonSize.MINI"
              (click)="goToPage(numeral)"
            >
              {{ numeral }}
            </fl-button>
          </div>
        </div>

        <fl-text
          *ngIf="(compact || mobileCompact) && label"
          class="CompactText"
          [size]="TextSize.XXSMALL"
          [textAlign]="TextAlign.CENTER"
          [flShowMobile]="mobileCompact"
          [ngClass]="{ OutlineCompact: outline === true }"
        >
          <ng-container
            *ngIf="label.start !== label.end"
            i18n="
              Pagination label consisting of range of item numbers displayed and
              total number of items
            "
          >
            {{ label.start }}-{{ label.end }} of {{ label.total }}
          </ng-container>
          <ng-container
            *ngIf="label.start === label.end"
            i18n="
              Pagination label consisting of item number displayed and total
              number of items
            "
          >
            {{ label.start }} of {{ label.total }}
          </ng-container>
        </fl-text>

        <!-- Next -->
        <div
          *ngIf="shouldShowAdditionalControls || compact || mobileCompact"
          class="PaginationItem "
          [flShowMobile]="mobileCompact && !shouldShowAdditionalControls"
        >
          <fl-button
            class="PaginationButton HoverColor"
            i18n-label="Pagination next page button"
            label="Next page"
            [class.Disabled]="currentPage === pages"
            [class.CustomColor]="outline === true"
            [color]="outline ? ButtonColor.CUSTOM : ButtonColor.TEXT_FOREGROUND"
            [size]="ButtonSize.MINI"
            [disabled]="currentPage === pages"
            [attr.data-compact]="compact"
            (click)="goToPage(currentPage + 1)"
          >
            <fl-icon
              i18n-label="Next Page Icon"
              label="Next Page Icon"
              [name]="'ui-chevron-right'"
              [size]="IconSize.SMALL"
            ></fl-icon>
          </fl-button>
        </div>

        <!-- Last -->
        <div
          *ngIf="extremeties && shouldShowAdditionalControls && !compact"
          class="PaginationItem"
          [flHideMobile]="mobileCompact"
        >
          <fl-button
            class="PaginationButton HoverColor"
            i18n-label="Pagination last page button"
            label="Last page"
            [ngClass]="{
              Disabled: currentPage === pages,
              CustomColor: outline === true
            }"
            [color]="outline ? ButtonColor.CUSTOM : ButtonColor.TEXT_FOREGROUND"
            [size]="ButtonSize.MINI"
            [disabled]="currentPage === pages"
            (click)="goToPage(pages)"
          >
            <fl-icon
              i18n-label="Last Page Icon"
              label="Last Page Icon"
              [name]="'ui-last-page'"
              [size]="IconSize.SMALL"
            ></fl-icon>
          </fl-button>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnChanges {
  ButtonColor = ButtonColor;
  ButtonSize = ButtonSize;
  TextSize = TextSize;
  IconSize = IconSize;
  IconColor = IconColor;
  TextAlign = TextAlign;
  trackByValue = trackByValue;

  numerals: readonly number[] = [];

  /** Only used on compact view */
  label?: PaginationLabel;

  /** Displays first, previous, next, last controls */
  shouldShowAdditionalControls = false;

  /** Displays page numbers within a select input */
  @HostBinding('attr.data-compact')
  @Input()
  compact = false;

  /** Automatically changes to compact on mobile */
  @Input() mobileCompact = false;

  /** Current page selected */
  @Input() currentPage: number;

  /** Displays first and last controls */
  @Input() extremeties = true;

  /** Information needed to generate labels */
  @Input() labelOptions?: PaginationLabelOptions;

  /** Total number of pages */
  @Input() pages: number;

  /** Outline style */
  @Input() outline = false;

  @Output() pageSelected = new EventEmitter<number>();

  buttonColorSelected: ButtonColor;
  buttonColor: ButtonColor;

  ngOnChanges(changes: SimpleChanges): void {
    if ('pages' in changes && this.pages) {
      this.shouldShowAdditionalControls = this.pages > 5;
    }

    if (
      ('pages' in changes || 'currentPage' in changes) &&
      this.pages &&
      this.currentPage
    ) {
      this.numerals = this.getNumerals();
    }

    if (
      ('labelOptions' in changes || 'currentPage' in changes) &&
      this.currentPage
    ) {
      this.label = this.getLabel(this.currentPage, this.labelOptions);
    }

    if ('outline' in changes && this.outline) {
      this.buttonColorSelected = ButtonColor.TRANSPARENT_SECONDARY;
      this.buttonColor = ButtonColor.TEXT_FOREGROUND;
    } else {
      this.buttonColorSelected = ButtonColor.SECONDARY;
      this.buttonColor = ButtonColor.TEXT_FOREGROUND;
    }
  }

  /**
   * Pushes a value up to conform to a minimum or down to conform to a maximum
   */
  getBoundValue(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
  }

  /**
   * Returns the pagination labels
   */
  getLabel(
    currentPage: number,
    labelOptions?: PaginationLabelOptions,
  ): PaginationLabel | undefined {
    if (currentPage && labelOptions) {
      const { start, end } = this.getPageRange(currentPage, labelOptions);

      return {
        total: labelOptions.total,
        start,
        end,
      };
    }
  }

  /**
   * Returns up to five (5) page numbers relative to the current page
   */
  getNumerals(): readonly number[] {
    const lowerBound = this.getBoundValue(
      this.currentPage - 2,
      1,
      Math.max(this.pages - 4, 1),
    );
    const upperBound = this.getBoundValue(this.currentPage + 2, 5, this.pages);

    return this.getSequenceArray(lowerBound, upperBound);
  }

  /**
   * Returns the indices of start and end items currently displayed
   * relative to current page and page size
   */
  getPageRange(
    currentPage: number,
    labelOptions: PaginationLabelOptions,
  ): { [key: string]: number } {
    const start =
      currentPage * labelOptions.pageSize - labelOptions.pageSize + 1;

    // Don't go over total number of items
    const end = Math.min(
      currentPage * labelOptions.pageSize,
      labelOptions.total,
    );

    return {
      start,
      end,
    };
  }

  /**
   * Returns an array containing numbers between start and end (inclusive)
   */
  getSequenceArray(start: number, end: number): readonly number[] {
    return Array.from(new Array(end + 1).keys()).filter(n => n >= start);
  }

  goToPage(page: number): void {
    const validPageNumber = page === this.getBoundValue(page, 1, this.pages);

    if (!validPageNumber) {
      return;
    }

    this.pageSelected.emit(page);
    this.currentPage = page;
    this.numerals = this.getNumerals();
    this.label = this.getLabel(page, this.labelOptions);
  }
}
