import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  GroupCreatorTypeApi,
  GroupStatusApi,
  GroupTypeApi,
  UNRESTRICTED_GROUPSApi,
} from 'api-typings/groups/groups';
import { Pool } from '../enterprise/enterprise.model';
import type { Group } from './groups.model';
import { UNREAD_FEED_VALUE } from './groups.model';

export interface GenerateGroupOptions {
  readonly id?: number;
  readonly name: string;
  readonly creatorType?: GroupCreatorTypeApi;
  readonly groupType?: GroupTypeApi;
  readonly description?: string;
  readonly created?: number;
  readonly isDeleted?: boolean;
  readonly updated?: number;
  readonly isFeedRead?: boolean;
  readonly lastActiveTime?: number;
  readonly creatorId?: number;
  readonly status?: GroupStatusApi;
  readonly poolIds?: readonly number[];
  readonly seoUrl?: string;
}

export function generateGroupObject({
  id = generateId(),
  name,
  creatorType = GroupCreatorTypeApi.ADMIN,
  groupType = GroupTypeApi.TALENT_POOL,
  description,
  created = Date.now(),
  updated = Date.now(),
  isDeleted = false,
  isFeedRead = false,
  lastActiveTime = Date.now(),
  creatorId = undefined,
  status = GroupStatusApi.LIVE,
  poolIds = [Pool.FREELANCER],
  seoUrl = undefined,
}: GenerateGroupOptions): Group {
  return {
    id,
    name,
    creatorId: creatorId || generateId(),
    creatorType,
    seoUrl: seoUrl || `group-${id}`,
    groupType,
    description,
    created,
    isDeleted,
    isFeedRead,
    lastActiveTimeInMs: lastActiveTime,
    orderByFeedReadLatestTimeInMs:
      (!isFeedRead ? UNREAD_FEED_VALUE : 0) + lastActiveTime,
    updated,
    isPublic: UNRESTRICTED_GROUPSApi.includes(groupType),
    status,
    poolIds,
  };
}

export function generateGroupsObject(
  groups: readonly GenerateGroupOptions[],
): readonly Group[] {
  return groups.map(generateGroupObject);
}

export function deloitteInternalGroup(): Pick<GenerateGroupOptions, 'poolIds'> {
  return {
    poolIds: [Pool.DELOITTE_DC],
  };
}

export function inReviewGroup(): Pick<GenerateGroupOptions, 'status'> {
  return {
    status: GroupStatusApi.IN_REVIEW,
  };
}
