import type { OnChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import type { BadgeDimensions } from './badge.types';
import {
  Badge,
  BadgeSize,
  BadgeType,
  badgeDimensionsByType,
} from './badge.types';
import { PreferredFreelancerBadgeType } from './preferred-freelancer-badge-type';

@Component({
  selector: 'fl-badge',
  template: `
    <fl-picture
      *ngIf="badge"
      class="BadgePicture"
      [alt]="badge.badge"
      [boundedWidth]="true"
      [boundedHeight]="true"
      [fullWidth]="true"
      [height]="dimensions?.height"
      [src]="'badges/' + badge.type + '/' + badge.badge"
      [width]="dimensions?.width"
    ></fl-picture>
  `,
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements OnChanges {
  @HostBinding('attr.data-size')
  @Input()
  size = BadgeSize.MID;

  @HostBinding('attr.data-size-tablet')
  @Input()
  sizeTablet?: BadgeSize;

  @HostBinding('attr.data-size-desktop')
  @Input()
  sizeDesktop?: BadgeSize;

  @HostBinding('attr.data-size-desktop-large')
  @Input()
  sizeDesktopLarge?: BadgeSize;

  @HostBinding('attr.data-size-desktop-xlarge')
  @Input()
  sizeDesktopXLarge?: BadgeSize;

  @HostBinding('attr.data-size-desktop-xxlarge')
  @Input()
  sizeDesktopXXLarge?: BadgeSize;

  @Input() badge: Badge;

  @HostBinding('attr.data-type') type: BadgeType;

  @HostBinding('class.IsPreferredFreelancer') isPreferredFreelancer = false;

  dimensions: BadgeDimensions;

  ngOnChanges(): void {
    if (this.badge) {
      this.type = this.badge.type;
      this.dimensions = badgeDimensionsByType[this.type];

      // TODO: T96403 - Remove hack when component is refactored in T96403
      if (
        this.badge.type === BadgeType.PREFERRED_FREELANCER &&
        this.badge.badge === PreferredFreelancerBadgeType.PREFERRED_FREELANCER
      ) {
        this.isPreferredFreelancer = true;
      }
    }
  }
}
