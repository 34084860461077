import { generateId } from '@freelancer/datastore/testing/helpers';
import { ProjectTypeApi } from 'api-typings/projects/projects';
import { CurrencyCode } from '../currencies/currencies.model';
import type { ManageViewOngoingProject } from './manage-view-ongoing-projects.model';

export interface GenerateManageViewOngoingProjectsOptions {
  readonly id?: string;
  readonly projectId?: number;
  readonly title: string;
  readonly projectType?: ProjectTypeApi;
  readonly seoUrl?: string;
  readonly recruiter?: boolean;
  readonly currencyCode?: string;
  readonly bidId?: number;
  readonly bidderId?: number;
  readonly milestoneCount?: number;
  readonly milestoneAmount?: number;
  readonly username?: string;
  readonly deadline?: number;
  readonly bidAmount?: number;
  readonly isHireMe?: boolean;
  readonly isInsourceProject?: boolean;
  readonly awardTime?: number;
}

/**
 * Creates a ManageViewOngoingProject object.
 */
export function generateManageViewOngoingProjectsObject({
  id = generateId().toString(),
  projectId = generateId(),
  title,
  projectType = ProjectTypeApi.FIXED,
  seoUrl,
  recruiter = false,
  currencyCode = CurrencyCode.USD,
  bidId = generateId(),
  bidderId = generateId(),
  milestoneCount = 0,
  milestoneAmount = 0,
  username = 'abc',
  deadline = Date.now(),
  bidAmount = 0,
  isHireMe = false,
  isInsourceProject = false,
  awardTime = Date.now(),
}: GenerateManageViewOngoingProjectsOptions): ManageViewOngoingProject {
  return {
    id,
    projectId,
    title,
    projectType,
    seoUrl: seoUrl ?? `project-${id}`,
    recruiter,
    currencyCode,
    bidId,
    bidderId,
    milestoneCount,
    milestoneAmount,
    username,
    deadline,
    bidAmount,
    isHireMe,
    isInsourceProject,
    awardTime,
  };
}
