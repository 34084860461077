import { generateId } from '@freelancer/datastore/testing/helpers';
import type { AdminProjectDescriptionUpdate } from './admin-project-description-update.model';

export interface GenerateAdminProjectDescriptionUpdateOptions {
  readonly id?: number;
  readonly description?: string;
}

export function generateAdminProjectDescriptionUpdateObject({
  id = generateId(),
  description = 'I need a website. Please contact me at not_susp@legit.com.',
}: GenerateAdminProjectDescriptionUpdateOptions = {}): AdminProjectDescriptionUpdate {
  return {
    id,
    description,
  };
}
