import type { UserPaymentControl } from './user-payment-controls.model';

export interface GenerateUserPaymentControlOptions {
  readonly id: number;
  readonly isNominatedPaymentControlled?: boolean;
  readonly isNominatedPaymentShareSetupComplete?: boolean;
}
/**
 * Generate user payment control object
 */
export function generateUserPaymentControlObject({
  id,
  isNominatedPaymentControlled = false,
  isNominatedPaymentShareSetupComplete = false,
}: GenerateUserPaymentControlOptions): UserPaymentControl {
  return {
    id,
    isNominatedPaymentControlled,
    isNominatedPaymentShareSetupComplete,
  };
}
