import { ScrollingModule } from '@angular/cdk/scrolling';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  APP_ID,
  APP_INITIALIZER,
  ErrorHandler,
  InjectionToken,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  provideClientHydration,
  withI18nSupport,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { ABTEST_WHITELIST_COOKIE, ABTest } from '@freelancer/abtest';
import { ABTestTesting } from '@freelancer/abtest/testing';
import { AUTH_CONFIG, Auth } from '@freelancer/auth';
import { AuthTesting } from '@freelancer/auth/testing';
import { ComponentsModalsModule } from '@freelancer/components/components-modals.module';
import { UserFeedbackModule } from '@freelancer/components/user-feedback';
import {
  APPS_DOMAINS_MAP,
  APP_NAME,
  ENVIRONMENT_NAME,
  SITE_NAME,
} from '@freelancer/config';
import { datastoreInitializer } from '@freelancer/datastore/collections-testing';
import { DatastoreFakeModule } from '@freelancer/datastore/testing';
import { FACEBOOK_CONFIG } from '@freelancer/facebook';
import { FileDownloadModule } from '@freelancer/file-download';
import {
  FREELANCER_HTTP_AUTH_PROVIDERS,
  FREELANCER_HTTP_CONFIG,
} from '@freelancer/freelancer-http';
import { Geolocation } from '@freelancer/geolocation';
import { GeolocationTesting } from '@freelancer/geolocation/testing';
import { GOOGLE_MAPS_CONFIG, SSR_MAPS_API_KEY } from '@freelancer/google-maps';
import { GOOGLE_SIGN_IN_CONFIG } from '@freelancer/google-sign-in';
import { InAppBrowser } from '@freelancer/in-app-browser';
import { PwaInitialState } from '@freelancer/initial-state';
import {
  NATIVE_LANGUAGE_COOKIE,
  WEBAPP_LANGUAGE_COOKIE,
} from '@freelancer/localization';
import {
  FREELANCER_LOCATION_AUTH_PROVIDER,
  FREELANCER_LOCATION_HTTP_BASE_URL_PROVIDER,
  FREELANCER_LOCATION_IN_APP_BROWSER_PROVIDER,
  FREELANCER_LOCATION_PWA_PROVIDER,
  FREELANCER_LOCATION_TRACKING_CONSENT_PROVIDER,
  LocationModule,
} from '@freelancer/location';
import { LOGIN_AUTH_SERVICE } from '@freelancer/login';
import { MESSAGING_CONFIG } from '@freelancer/messaging-chat';
import { PipesModule } from '@freelancer/pipes';
import { Pwa } from '@freelancer/pwa';
import { SEO_CONFIG, SeoModule } from '@freelancer/seo';
import { ThemeComponent } from '@freelancer/theme';
import {
  CustomErrorHandler,
  TRACKING_CONFIG,
  TrackingConsent,
  TrackingModule,
} from '@freelancer/tracking';
import { UiModule } from '@freelancer/ui';
import { FileViewerModule } from '@freelancer/ui/file-viewer';
import { ModalModule } from '@freelancer/ui/modal';
import { UiModalsModule } from '@freelancer/ui/ui-modals.module';
import { getDomainsMap } from 'applications';
import { CookieModule } from 'ngx-cookie';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { environment } from '../environments/environment';
import { AppModalsModule } from './app-modals.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CodeBlockModule } from './base/@shared/code-block/code-block.module';
import { BaseComponent } from './base/base.component';
import { NavTabsComponent } from './base/components/nav-tabs.component';
import { NavComponent } from './base/components/nav.component';
import { SearchComponent } from './base/components/search.component';
import { SidebarComponent } from './base/components/sidebar.component';
import { ThemeToggleComponent } from './base/components/theme-toggle/theme-toggle.component';
import { EmptyComponent } from './base/empty.component';
import { NotFoundComponent } from './base/not-found/not-found.component';
import { AboutComponent } from './base/static-pages/about/about.component';
import { AccessibilityComponent } from './base/static-pages/accessibility/accessibility.component';
import { BrandStyleGuideComponent } from './base/static-pages/brand/brand-style-guide.component';
import { BrandStyleGuideColoursComponent } from './base/static-pages/brand/sections/brand-style-guide-colours.component';
import { BrandStyleGuideImageryComponent } from './base/static-pages/brand/sections/brand-style-guide-imagery.component';
import { BrandStyleGuideLogoComponent } from './base/static-pages/brand/sections/brand-style-guide-logo.component';
import { BrandStyleGuidePrinciplesComponent } from './base/static-pages/brand/sections/brand-style-guide-principles.component';
import { BrandStyleGuideTaglineComponent } from './base/static-pages/brand/sections/brand-style-guide-tagline.component';
import { BrandStyleGuideTypographyComponent } from './base/static-pages/brand/sections/brand-style-guide-typography.component';
import { BrandStyleGuideVoiceComponent } from './base/static-pages/brand/sections/brand-style-guide-voice.component';
import { BugsComponent } from './base/static-pages/bugs/bugs.component';
import { ColorsComponent } from './base/static-pages/colors/colors.component';
import { ContentHierarchyComponent } from './base/static-pages/content-hierarchy/content-hierarchy.component';
import { CopywritingComponent } from './base/static-pages/copywriting/copywriting.component';
import { ErrorHandlingComponent } from './base/static-pages/error-handling/error-handling.component';
import { FAQComponent } from './base/static-pages/faq/faq.component';
import { FormsComponent } from './base/static-pages/forms/forms.component';
import { GeneralRulesComponent } from './base/static-pages/general-rules/general-rules.component';
import { GetStartedComponent } from './base/static-pages/get-started/get-started.component';
import { GridComponent } from './base/static-pages/grid/grid.component';
import { HeaderComponent } from './base/static-pages/header/header.component';
import { DesignContributionComponent } from './base/static-pages/how-to-contribute/design-contribution.component';
import { DeveloperContributionComponent } from './base/static-pages/how-to-contribute/developer-contribution.component';
import { HowToContributeComponent } from './base/static-pages/how-to-contribute/how-to-contribute.component';
import { IllustrationsListComponent } from './base/static-pages/illustrations/illustrations-list.component';
import { IllustrationsComponent } from './base/static-pages/illustrations/illustrations.component';
import { LayoutsComponent } from './base/static-pages/layouts/layouts.component';
import { ModalsComponent } from './base/static-pages/modals/modals.component';
import { AttentionSeekerComponent } from './base/static-pages/motion-system/attention-seeker/attention-seeker.component';
import { DynamicDemoActionComponent } from './base/static-pages/motion-system/dynamic-demo/dynamic-demo-action.component';
import { DynamicDemoContentComponent } from './base/static-pages/motion-system/dynamic-demo/dynamic-demo-content.component';
import { DynamicDemoComponent } from './base/static-pages/motion-system/dynamic-demo/dynamic-demo.component';
import { FadeComponent } from './base/static-pages/motion-system/fade/fade.component';
import { GettingStartedComponent } from './base/static-pages/motion-system/getting-started.component';
import { SlideComponent } from './base/static-pages/motion-system/slide/slide.component';
import { TestUserCardComponent } from './base/static-pages/motion-system/test-user-card/test-user-card.component';
import { SpacingComponent } from './base/static-pages/spacing/spacing.component';
import { TypographyComponent } from './base/static-pages/typography/typography.component';
import { HomeComponent } from './home/home.component';

// /!\ DO ADD ANYTHING IN THERE WITHOUT TALKING TO BITS TEAM FIRST /!\
// \!/                    hzhang || leo                            \!/

const PWA_INITIAL_STATE = new InjectionToken<PwaInitialState>(
  'PwaInitialState',
);

const disableAnimations =
  (globalThis.document &&
    !('animate' in (globalThis.document as any).documentElement)) ||
  (globalThis.navigator &&
    /iPhone OS (8|9|10|11|12|13)_/.test(
      (globalThis.navigator as any).userAgent,
    ));

export function initializeApp(platformId: Object): () => void {
  return () => {
    if (isPlatformBrowser(platformId)) {
      // Mark the app as bootstrapped for inline scripts
      if (window.webapp) {
        window.webapp.applicationBootstrapped = true;
      }
    }
  };
}

@NgModule({
  imports: [
    GoogleMapsModule,
    AppModalsModule,
    UiModalsModule,
    AppRoutingModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    ComponentsModalsModule,
    CookieModule.withOptions(),
    DatastoreFakeModule.initialize({
      debug: false,
      initializer: datastoreInitializer(),
    }),
    FileDownloadModule,
    FileViewerModule,
    UserFeedbackModule,
    FormsModule,
    HttpClientModule,
    ModalModule,
    PipesModule,
    LocationModule,
    SeoModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    CodeBlockModule,
    ScrollingModule,
    UiModule.initialize({
      assetsBaseUrl: environment.uiConfig.assetsBaseUrl,
      theme: environment.uiConfig.theme,
      lightGalleryLicenseKey: environment.lightGallery.licenseKey,
    }),
    TrackingModule,
    ThemeToggleComponent,
    ThemeComponent,
  ],
  declarations: [
    AboutComponent,
    AccessibilityComponent,
    AppComponent,
    BaseComponent,
    BugsComponent,
    ColorsComponent,
    ContentHierarchyComponent,
    CopywritingComponent,
    DesignContributionComponent,
    DeveloperContributionComponent,
    DynamicDemoActionComponent,
    DynamicDemoComponent,
    DynamicDemoContentComponent,
    EmptyComponent,
    ErrorHandlingComponent,
    FAQComponent,
    FormsComponent,
    GeneralRulesComponent,
    GetStartedComponent,
    GridComponent,
    HeaderComponent,
    HomeComponent,
    HowToContributeComponent,
    IllustrationsComponent,
    IllustrationsListComponent,
    LayoutsComponent,
    ModalsComponent,
    NavComponent,
    NavTabsComponent,
    NotFoundComponent,
    SidebarComponent,
    SearchComponent,
    SpacingComponent,
    TestUserCardComponent,
    TypographyComponent,
    GettingStartedComponent,
    AttentionSeekerComponent,
    FadeComponent,
    SlideComponent,
    BrandStyleGuideComponent,
    BrandStyleGuidePrinciplesComponent,
    BrandStyleGuideLogoComponent,
    BrandStyleGuideColoursComponent,
    BrandStyleGuideTypographyComponent,
    BrandStyleGuideVoiceComponent,
    BrandStyleGuideTaglineComponent,
    BrandStyleGuideImageryComponent,
  ],
  providers: [
    { provide: APP_ID, useValue: 'webapp' },
    { provide: APP_NAME, useValue: environment.appName },
    {
      provide: APPS_DOMAINS_MAP,
      useValue: getDomainsMap(environment.environmentName),
    },
    {
      provide: AUTH_CONFIG,
      useValue: {
        baseUrl: environment.authConfig.baseUrl,
        switchAccountHackyEndpoint:
          environment.authConfig.switchAccountHackyEndpoint,
        authHashCookie: environment.authConfig.authHashCookie,
        userIdCookie: environment.authConfig.userIdCookie,
        csrfCookie: environment.authConfig.csrfCookie,
        gdprCookie: environment.authConfig.gdprCookie,
        authHeaderName: environment.authConfig.authHeaderName,
        referralCookie: environment.authConfig.referralCookie,
        authIosKeychainToken: environment.authConfig.authIosKeychainToken,
      },
    },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: Auth, useClass: AuthTesting },
    { provide: ABTest, useClass: ABTestTesting },
    { provide: Geolocation, useClass: GeolocationTesting },
    {
      provide: FREELANCER_HTTP_CONFIG,
      useValue: {
        baseUrl: environment.freelancerHttpConfig.baseUrl,
        apiBaseUrl: environment.freelancerHttpConfig.apiBaseUrl,
        ajaxBaseUrl: environment.freelancerHttpConfig.ajaxBaseUrl,
        authBaseUrl: environment.freelancerHttpConfig.authBaseUrl,
        fileServiceBaseUrl: environment.freelancerHttpConfig.fileServiceBaseUrl,
      },
    },
    {
      provide: LOGIN_AUTH_SERVICE,
      useClass: AuthTesting,
    },
    {
      // ABTest -> FreelancerHttp
      provide: FREELANCER_HTTP_AUTH_PROVIDERS,
      useExisting: Auth,
      multi: true,
    },
    {
      provide: PWA_INITIAL_STATE,
      useExisting: PwaInitialState,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [DOCUMENT, PLATFORM_ID, PWA_INITIAL_STATE],
      multi: true,
    },
    { provide: SITE_NAME, useValue: environment.siteName },
    {
      provide: SEO_CONFIG,
      useValue: {
        linkTags: {
          rss: {
            rel: environment.seoConfig.linkTags.rss.rel,
            type: environment.seoConfig.linkTags.rss.type,
            title: environment.seoConfig.linkTags.rss.title,
            href: environment.seoConfig.linkTags.rss.href,
          },
        },
        metaTags: {
          robots: {
            name: environment.seoConfig.metaTags.robots.name,
            content: environment.seoConfig.metaTags.robots.content,
          },
          alexa: {
            name: environment.seoConfig.metaTags.alexa.name,
            content: environment.seoConfig.metaTags.alexa.content,
          },
          bing: {
            name: environment.seoConfig.metaTags.bing.name,
            content: environment.seoConfig.metaTags.bing.content,
          },
        },
        defaultMetaImage: environment.seoConfig.defaultMetaImage,
      },
    },
    {
      provide: GOOGLE_MAPS_CONFIG,
      useValue: {
        apiKey: environment.mapsConfig.apiKey,
      },
    },
    {
      provide: FACEBOOK_CONFIG,
      useValue: {
        appId: environment.facebookConfig.appId,
      },
    },
    {
      provide: GOOGLE_SIGN_IN_CONFIG,
      useValue: {
        clientId: environment.googleSignInConfig.clientId,
      },
    },
    {
      provide: WEBAPP_LANGUAGE_COOKIE,
      useValue: environment.languageCookies.webappCookie,
    },
    {
      provide: NATIVE_LANGUAGE_COOKIE,
      useValue: environment.languageCookies.nativeCookie,
    },
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({
        enabled: false,
      }),
    },
    { provide: ABTEST_WHITELIST_COOKIE, useValue: environment.whitelistCookie },
    {
      provide: FREELANCER_LOCATION_AUTH_PROVIDER,
      useExisting: Auth,
    },
    {
      provide: FREELANCER_LOCATION_HTTP_BASE_URL_PROVIDER,
      useValue: environment.freelancerHttpConfig.baseUrl,
    },
    {
      provide: FREELANCER_LOCATION_PWA_PROVIDER,
      useExisting: Pwa,
    },
    {
      provide: FREELANCER_LOCATION_TRACKING_CONSENT_PROVIDER,
      useExisting: TrackingConsent,
    },
    {
      provide: FREELANCER_LOCATION_IN_APP_BROWSER_PROVIDER,
      useExisting: InAppBrowser,
    },
    {
      provide: MESSAGING_CONFIG,
      useValue: {
        aiChatUserId: environment.messaging.aiChatUserId,
      },
    },
    { provide: ENVIRONMENT_NAME, useValue: environment.environmentName },
    {
      provide: SSR_MAPS_API_KEY,
      deps: [PLATFORM_ID],
      useFactory: (platformId: string) => {
        if (isPlatformServer(platformId)) {
          return process.env.SSR_MAPS_API_KEY ?? environment.mapsConfig.apiKey;
        }

        return undefined;
      },
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml'),
        },
      },
    },
    provideClientHydration(withI18nSupport()),
    {
      provide: TRACKING_CONFIG,
      useValue: {
        trackingEndpoint: environment.trackingConfig.trackingEndpoint,
        linkSessionsEndpoint: environment.trackingConfig.linkSessionsEndpoint,
        sessionCookie: environment.trackingConfig.sessionCookie,
        linkedSessionCookie: environment.trackingConfig.sessionCookie,
        clientKey: environment.trackingConfig.clientKey,
        navigationPerformanceSamplingRate:
          environment.trackingConfig.navigationPerformanceSamplingRate,
        enableErrorTracking: environment.trackingConfig.enableErrorTracking,
        sentryDsn: environment.trackingConfig.sentryDsn,
        kibanaBaseUrl: environment.trackingConfig.kibanaBaseUrl,
        gaTrackingId: environment.trackingConfig.gaTrackingId,
        ga4TrackingId: environment.trackingConfig.ga4TrackingId,
        awGoogleConversionId: environment.trackingConfig.awGoogleConversionId,
        facebookPixelId: environment.trackingConfig.facebookPixelId,
        tracesSampleRate: environment.trackingConfig.tracesSampleRate,
        gaCustomDimensionMap: {
          dimension1:
            environment.trackingConfig.gaCustomDimensionMap.dimension1,
          dimension2:
            environment.trackingConfig.gaCustomDimensionMap.dimension2,
          dimension3:
            environment.trackingConfig.gaCustomDimensionMap.dimension3,
        },
        gAdsTrackingId: environment.trackingConfig.gAdsTrackingId,
        gAdsAllowedDomain: environment.trackingConfig.gAdsAllowedDomain,
        linkedInPixelId: environment.trackingConfig.linkedInPixelId,
        quoraPixelId: environment.trackingConfig.quoraPixelId,
        tiktokPixelId: environment.trackingConfig.tiktokPixelId,
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
