import type { UserBidOnRussianProjects } from './user-bid-on-russian-projects.model';

export interface GenerateUserBidOnRussianProjects {
  readonly userId: string;
  readonly isUSFreelancerOnRussianProject: boolean;
}

export function generateUserBidOnRussianProjectsObject({
  userId,
  isUSFreelancerOnRussianProject,
}: GenerateUserBidOnRussianProjects): UserBidOnRussianProjects {
  return {
    id: userId,
    isUSFreelancerOnRussianProject,
  };
}
