import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { ModalRef } from '@freelancer/ui';
import { ModalService } from '@freelancer/ui';
import { BannerAlertType } from '@freelancer/ui/banner-alert';
import { ButtonColor, ButtonSize } from '@freelancer/ui/button';
import { HeadingType, HeadingWeight } from '@freelancer/ui/heading';
import { ListItemType } from '@freelancer/ui/list-item';
import { ModalSize } from '@freelancer/ui/modal';
import { FontType, FontWeight, TextSize } from '@freelancer/ui/text';

@Component({
  selector: 'app-static-modals',
  template: `
    <fl-bit class="MainContainer">
      <fl-card>
        <fl-heading
          [headingType]="HeadingType.H1"
          [size]="TextSize.MID"
          [flMarginBottom]="'large'"
        >
          Modals
        </fl-heading>

        <fl-heading
          [headingType]="HeadingType.H2"
          [size]="TextSize.SMALL"
          [flMarginBottom]="'xxsmall'"
        >
          How to pop a modal
        </fl-heading>

        <fl-text
          [flMarginBottom]="'xxsmall'"
          [size]="TextSize.SMALL"
        >
          Inject the modal service in your class constructor
        </fl-text>
        <app-code-block
          [code]="examplePopCodeSnippetB"
          [language]="'typescript'"
          [flMarginBottom]="'mid'"
        ></app-code-block>

        <fl-text
          [flMarginBottom]="'xsmall'"
          [size]="TextSize.SMALL"
        >
          Call the modalService's open method (copy someone else's call if you
          can!). It has 2 arguments
        </fl-text>
        <fl-list
          class="List"
          [type]="ListItemType.UNORDERED"
          [flMarginBottom]="'small'"
        >
          <fl-list-item [flMarginBottom]="'xxxsmall'">
            <fl-text [size]="TextSize.SMALL"> The modal component. </fl-text>
          </fl-list-item>
          <fl-list-item [flMarginBottom]="'xxxsmall'">
            <fl-text [size]="TextSize.SMALL">
              And a <span class="InlineSnippet">modalConfig</span> object
              (explained below)
            </fl-text>
          </fl-list-item>
        </fl-list>
        <app-code-block
          [code]="examplePopCodeSnippetC"
          [language]="'typescript'"
          [flMarginBottom]="'mid'"
        ></app-code-block>

        <fl-text
          [flMarginBottom]="'xxsmall'"
          [size]="TextSize.SMALL"
        >
          A <span class="InlineSnippet">modalConfig</span> object has six
          fields, all optional:
        </fl-text>
        <app-code-block
          [code]="modalConfigTypeDef"
          [language]="'typescript'"
          [flMarginBottom]="'xxsmall'"
        ></app-code-block>
        <fl-text
          [flMarginBottom]="'xxsmall'"
          [size]="TextSize.SMALL"
        >
          The button below uses the code above!
        </fl-text>
        <fl-bit>
          <fl-button
            [color]="ButtonColor.SECONDARY"
            [size]="ButtonSize.LARGE"
            [flMarginBottom]="'large'"
            (click)="popModal()"
          >
            Pop modal
          </fl-button>
        </fl-bit>

        <fl-bit class="Section">
          <fl-heading
            [headingType]="HeadingType.H2"
            [size]="TextSize.SMALL"
            [flMarginBottom]="'xxsmall'"
          >
            How to use the response value of a modal
          </fl-heading>
          <app-code-block
            [code]="useResponseSnippet"
            [language]="'typescript'"
            [flMarginBottom]="'xxsmall'"
          ></app-code-block>

          <fl-text
            [flMarginBottom]="'xxsmall'"
            [size]="TextSize.SMALL"
          >
            The button below uses the code above! Check your console for the
            output.
          </fl-text>
          <fl-bit>
            <fl-button
              [color]="ButtonColor.SECONDARY"
              [size]="ButtonSize.LARGE"
              [flMarginBottom]="'large'"
              (click)="popModalAndWatchResponse()"
            >
              Pop modal and log response
            </fl-button>
          </fl-bit>
        </fl-bit>

        <fl-bit class="Section">
          <fl-heading
            [headingType]="HeadingType.H2"
            [size]="TextSize.SMALL"
            [flMarginBottom]="'xxsmall'"
          >
            How to create a modal
          </fl-heading>

          <fl-banner-alert
            [type]="BannerAlertType.INFO"
            [closeable]="false"
            [flMarginBottom]="'mid'"
          >
            <fl-text
              [size]="TextSize.SMALL"
              [weight]="FontWeight.BOLD"
            >
              The process outlined below has been automated
            </fl-text>
            <fl-text [size]="TextSize.SMALL">
              Simply use
              <span class="InlineSnippet"
                >yarn generate-modal --path='src/app/path/to/dir'
                --name=yourModal</span
              >
              to generate and edit the required files.
            </fl-text>
            <fl-text [size]="TextSize.SMALL">
              Remember to check
              <span class="InlineSnippet">your-modal.component.ts</span> and
              call it to test.
            </fl-text>
          </fl-banner-alert>

          <fl-list
            class="List"
            [type]="ListItemType.UNORDERED"
            [flMarginBottom]="'small'"
          >
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-text [size]="TextSize.SMALL">
                Create three new files:
                <span class="InlineSnippet">your-modal.module.ts</span>,
                <span class="InlineSnippet">your-modal.component.ts</span>, and
                <span class="InlineSnippet">your-modal.component.scss</span>
                (optional). These can go in any folder.
              </fl-text>
            </fl-list-item>
            <fl-list-item
              class="SubItem"
              [flMarginBottom]="'xxxsmall'"
            >
              <fl-text [size]="TextSize.SMALL">
                You can call your modal
                <span class="InlineSnippet">your-overlay</span> or
                <span class="InlineSnippet">your-drawer</span> if you like,
                depending on how you expect the modal to be opened.
              </fl-text>
            </fl-list-item>
            <fl-list-item
              class="SubItem"
              [flMarginBottom]="'xxxsmall'"
            >
              <fl-text [size]="TextSize.SMALL">
                The module file needs to declare your modal component and set it
                as an entrypoint, as well as include any modules (eg.
                <span class="InlineSnippet">UiModule</span>) that you may need.
              </fl-text>
            </fl-list-item>
            <fl-list-item
              class="SubItem"
              [flMarginBottom]="'xxxsmall'"
            >
              <fl-text [size]="TextSize.SMALL">
                Check out the files in
                <span class="InlineSnippet"
                  >webapp/src/bits/base/static-pages/modals/example-modal</span
                >, or any other modal, for examples.
              </fl-text>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-text [size]="TextSize.SMALL">
                Create the UI/functionality of the modal. The modal should
                handle everything that happens while it's open, including all
                the buttons that will close it.
              </fl-text>
            </fl-list-item>
            <fl-list-item [flMarginBottom]="'xxxsmall'">
              <fl-text [size]="TextSize.SMALL">
                Call it as described above
              </fl-text>
            </fl-list-item>
          </fl-list>
        </fl-bit>
      </fl-card>
    </fl-bit>
  `,
  styleUrls: ['../static-pages.component.scss', './modals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalsComponent {
  BannerAlertType = BannerAlertType;
  ButtonColor = ButtonColor;
  ButtonSize = ButtonSize;
  TextSize = TextSize;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  ListItemType = ListItemType;

  examplePopCodeSnippetA = `
import { ExampleModalComponent } from './example-modal/example-modal.component';
import { ModalService } from '@freelancer/ui';
import { ModalSize } from '@freelancer/ui/modal';
  `;
  examplePopCodeSnippetB = `
constructor(private modalService: ModalService) {}
  `;
  examplePopCodeSnippetC = `
popModal() {
  // this returns a ModalRef
  return this.modalService.open('ExampleModal', {
    inputs: {
      name: 'Bob',
    },
    size: ModalSize.SMALL,
  });
}
  `;

  useResponseSnippet = `
popModalAndWatchResponse() {
  // popModal as defined above
  this.popModal()
    .afterClosed()
    .toPromise()
    .then(response => {
      console.log(response);
    });
  });
}
  `;

  modalConfigTypeDef = `
interface ModalConfig {
  inputs?: Object; // the @Input() values to pass to the component
  size?: ModalSize;
  closeable?: boolean;
  showBackdrop?: boolean; // TODO: remove once webapp modals created: T46290
  edgeToEdge?: boolean;
}
  `;

  constructor(private modalService: ModalService) {}

  popModal(): ModalRef<unknown> {
    return this.modalService.open('ExampleModal', {
      inputs: {
        name: 'Bob',
      },
      size: ModalSize.SMALL,
    });
  }

  popModalAndWatchResponse(): void {
    this.popModal()
      .afterClosed()
      .then(response => {
        console.log(response);
      });
  }
}
