import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { ValidationErrors } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import type { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IconColor, IconSize } from '../icon';
import { FontColor, TextSize } from '../text';

@Component({
  selector: 'fl-validation-error',
  template: `
    <div
      *ngIf="
        (status$ | flAsync) &&
        control.invalid &&
        (control.touched || control.dirty)
      "
      class="ErrorContainer"
    >
      <fl-icon
        class="IconError"
        [name]="'ui-warning-alt-v2'"
        [color]="isWarning ? IconColor.WARNING : IconColor.ERROR"
        [size]="IconSize.XSMALL"
        [flMarginRight]="'xxsmall'"
      ></fl-icon>
      <fl-text
        aria-live="polite"
        [attr.id]="id"
        [color]="isWarning ? FontColor.WARNING : FontColor.ERROR"
        [size]="TextSize.XXSMALL"
      >
        {{ status$ | flAsync }}
      </fl-text>
    </div>
  `,
  styleUrls: ['./validation-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorComponent implements OnChanges {
  IconColor = IconColor;
  IconSize = IconSize;
  FontColor = FontColor;
  TextSize = TextSize;

  status$: Observable<string>;

  @Input() id?: string;
  @Input() control: AbstractControl;
  @Input() isWarning?: boolean = false;
  /** Displays error text based on the given error key */
  @Input() errorKey?: string;

  ngOnChanges(changes: SimpleChanges): void {
    if ('control' in changes) {
      this.status$ = this.control.statusChanges.pipe(
        startWith(this.control.status),
        map(() => {
          if (this.isMatDatePickerError(this.control.errors)) {
            return $localize`Please select a valid date.`;
          }

          if (
            this.errorKey &&
            this.control.errors &&
            this.errorKey in this.control.errors
          ) {
            return this.control.errors[this.errorKey];
          }
          const [error]: string[] = Object.values(this.control.errors || {});
          return error;
        }),
      );
    }
  }

  // Hack around Material's error format
  private isMatDatePickerError(errors: ValidationErrors | null): any {
    return (
      errors &&
      (errors.matDatepickerParse ||
        errors.matStartDateInvalid ||
        errors.matEndDateInvalid)
    );
  }
}
