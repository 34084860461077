import { generateId } from '@freelancer/datastore/testing/helpers';
import { DiscoverCollectionTypeApi } from 'api-typings/users/users';
import type { DiscoverCollection } from './discover-collections.model';

export interface GenerateDiscoverCollectionOptions {
  readonly id?: number;
  readonly name?: string;
  readonly description?: string;
  readonly dateCreated?: number;
  readonly lastUpdated?: number;
  readonly ownerId?: number;
  readonly isPrivate?: boolean;
  readonly isDeleted?: boolean;
  readonly type?: DiscoverCollectionTypeApi;
  readonly previewItems?: readonly string[];
  readonly itemCount?: number;
  readonly seoUrl?: string;
}

export function generateDiscoverCollectionObject({
  id = generateId(),
  name = `Test Name ${id}`,
  description = 'Test Description',
  dateCreated = Date.now(),
  lastUpdated = Date.now(),
  ownerId = generateId(),
  isPrivate = false,
  isDeleted = false,
  type = DiscoverCollectionTypeApi.FAVORITES,
  previewItems = [],
  itemCount = 0,
  seoUrl = '',
}: GenerateDiscoverCollectionOptions = {}): DiscoverCollection {
  return {
    id,
    name,
    description,
    dateCreated,
    lastUpdated,
    ownerId,
    isPrivate,
    isDeleted,
    type,
    previewItems,
    itemCount,
    seoUrl,
  };
}
