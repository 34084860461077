export enum RadioSize {
  SMALL = 'small',
  LARGE = 'large',
}

export enum RadioOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export interface RadioOptionItem<T = string> {
  value: T;
  displayText: string;
}

export type RadioTextColor = 'foreground' | 'white';
