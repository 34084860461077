import { generateId } from '@freelancer/datastore/testing/helpers';
import {
  CreditCardGatewayApi,
  CreditCardTypeApi,
  DepositMethodApi,
} from 'api-typings/payments/payments';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import type {
  BillingAgreement,
  CreditCard,
  Paypal,
  VirtualPaymentMethod,
} from './billing-agreements.model';

export const CREDIT_CARD_NUMBER = '111111****2222';
export const CREDIT_CARD_DISPLAY = '**** 2222';
export const TEAM_BILLING_NAME = 'Team Billing Agreement Name';
export const ENTERPRISE_BILLING_NAME = 'Enterprise Billing Agreement Name';
export const PAYPAL_ACCOUNT_NAME = 'John Doe';
export const PAYPAL_ACCOUNT_EMAIL = 'johndoe@freelancer.com';

export interface GenerateBillingAgreementOptions {
  readonly currencyCode?: CurrencyCode;
  readonly token?: string;
  readonly depositMethod: DepositMethodApi;
  readonly lastSuccessTimestamp?: number;

  // for fake credit card
  readonly creditCard?: CreditCard;

  // for fake paypal account
  readonly paypal?: Paypal;

  // for fake virtual payment method
  readonly virtualPaymentMethod?: VirtualPaymentMethod;

  readonly isPreferred?: boolean;
  readonly isVerified?: boolean;
  readonly displayInfo?: string;
  readonly logoSrc?: string;
}

export function generateBillingAgreementObject({
  currencyCode = CurrencyCode.USD,
  token = generateId().toString(),
  depositMethod,
  lastSuccessTimestamp = Date.now(),
  creditCard,
  paypal,
  virtualPaymentMethod,
  isPreferred = false,
  isVerified = true,
  displayInfo,
  logoSrc,
}: GenerateBillingAgreementOptions): BillingAgreement {
  return {
    id: token,
    currencyId: generateCurrencyObject(currencyCode).id,
    token,
    depositMethod,
    lastSuccessTimestamp,
    creditCard,
    paypal,
    virtualPaymentMethod,
    isPreferred,
    isVerified,
    displayInfo,
    logoSrc,
  };
}

export function generateBillingAgreementObjects(
  options: readonly GenerateBillingAgreementOptions[],
): readonly BillingAgreement[] {
  return options.map(option => generateBillingAgreementObject(option));
}

export function generateCreditCard({
  creditCardNumber = CREDIT_CARD_NUMBER,
  cardType = CreditCardTypeApi.VISA,
  gateway = CreditCardGatewayApi.ADYEN,
}: {
  readonly creditCardNumber?: string;
  readonly cardType?: string;
  readonly gateway?: string;
} = {}): Pick<GenerateBillingAgreementOptions, 'creditCard'> {
  return {
    creditCard: {
      creditCardNumber,
      cardType,
      gateway,
    },
  };
}

export function generatePaypal({
  paypalAccountName = PAYPAL_ACCOUNT_NAME,
  paypalEmail = PAYPAL_ACCOUNT_EMAIL,
}: Paypal): Pick<GenerateBillingAgreementOptions, 'paypal'> {
  return {
    paypal: {
      paypalAccountName,
      paypalEmail,
    },
  };
}

export function generateTeamBilling(
  name = TEAM_BILLING_NAME,
  isApprovalRequired?: boolean,
): Pick<
  GenerateBillingAgreementOptions,
  'virtualPaymentMethod' | 'depositMethod'
> {
  return {
    depositMethod: DepositMethodApi.TEAM_BILLING,
    virtualPaymentMethod: {
      name,
      isApprovalRequired,
    },
  };
}

export function generateEnterpriseBilling(
  name = ENTERPRISE_BILLING_NAME,
): Pick<
  GenerateBillingAgreementOptions,
  'virtualPaymentMethod' | 'depositMethod'
> {
  return {
    depositMethod: DepositMethodApi.ENTERPRISE_BILLING,
    virtualPaymentMethod: {
      name,
    },
  };
}
