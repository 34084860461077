import type { LoginDevice } from './login-devices.model';

export type GenerateLoginDevicesOptions = Partial<LoginDevice>;

export function generateLoginDevicesObject({
  userAgent = 'Mozilla/5.0',
  platform = 'Web',
  city = 'Manila',
  country = 'Philippines',
  lastLogin = Date.now(),
  id = lastLogin,
  token = 'token',
  isActive = false,
  isCurrent = false,
  isMobile = false,
}: GenerateLoginDevicesOptions = {}): LoginDevice {
  return {
    id,
    userAgent,
    platform,
    city,
    country,
    lastLogin,
    token,
    isActive,
    isCurrent,
    isMobile,
  };
}
