import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ServiceOfferingBuyerHistories } from './service-offering-buyer-histories.model';

interface GenerateServiceOfferingBuyerOptions {
  readonly buyerId?: number;
  readonly buyerUsername?: string;
  readonly buyerPublicName?: string;
  readonly projectId?: number;
  readonly projectTitle?: string;
  readonly projectSeoUrl?: string;
  readonly purchasedDate?: number;
}

export interface GenerateServiceOfferingBuyerHistoriesOptions {
  readonly id?: number;
  readonly buyers: readonly GenerateServiceOfferingBuyerOptions[];
}

export function generateServiceOfferingBuyerHistoriesObject({
  id = generateId(),
  buyers,
}: GenerateServiceOfferingBuyerHistoriesOptions): ServiceOfferingBuyerHistories {
  return {
    id,
    buyers: buyers?.map(buyer => ({
      buyerId: buyer.buyerId || generateId(),
      buyerUsername: buyer.buyerUsername || 'testBuyer',
      buyerPublicName: buyer.buyerPublicName || 'Test B.',
      projectId: buyer.projectId || generateId(),
      projectTitle: buyer.projectTitle || 'Test Project Title',
      projectSeoUrl: buyer.projectSeoUrl || 'test-project-title',
      purchasedDate: buyer.purchasedDate || Date.now(),
    })),
  };
}
