import { generateId } from '@freelancer/datastore/testing/helpers';
import { DAY } from '@freelancer/ui/relative-time';
import { UserAvailabilityStatusApi } from 'api-typings/users/users';
import type { EquipmentItem } from '../equipment-group';
import type { Attachment } from '../feed-posts/feed-posts.model';
import type { OperatingArea } from '../operating-areas';
import {
  getProjectViewUserLocations,
  LocationPreset,
} from '../project-view-users';
import type { Location } from '../project-view-users';
import type { UserAvailability } from './user-availabilities.model';

export interface GenerateUserAvailabilityOptions {
  readonly id?: number;
  readonly seoUrl?: string;
  readonly title?: string;
  readonly description?: string;
  readonly startDate?: number;
  readonly endDate?: number;
  readonly status?: UserAvailabilityStatusApi;
  readonly userId?: number;
  readonly location?: Location;
  readonly preferredDestinationOperatingAreas?: readonly OperatingArea[];
  readonly attachments?: readonly Attachment[];
  readonly equipment?: readonly EquipmentItem[];
  readonly isDeleted?: boolean;
  readonly submitDate?: number;
}

export function generateUserAvailabilityObject({
  id = generateId(),
  seoUrl = `Semi-trailer-truck-available-${id}`,
  title = 'Semi-trailer truck available',
  description = 'Semi-trailer truck available',
  startDate = Date.now(),
  endDate,
  status = UserAvailabilityStatusApi.ACTIVE,
  userId = generateId(),
  isDeleted = false,
  submitDate = Date.now() - DAY,
  equipment = [],
  attachments = [],
  preferredDestinationOperatingAreas = [],
  location = getProjectViewUserLocations(LocationPreset.SYDNEY),
}: GenerateUserAvailabilityOptions = {}): UserAvailability {
  return {
    id,
    seoUrl,
    title,
    description,
    startDate,
    endDate,
    status,
    userId,
    isDeleted,
    submitDate,
    equipment,
    attachments,
    preferredDestinationOperatingAreas,
    location,
  };
}
