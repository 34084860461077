import { generateId } from '@freelancer/datastore/testing/helpers';
import { EnterpriseSourceApi } from 'api-typings/common/common';
import type { UserEnterpriseModel } from './admin-user-enterprises.model';

export interface GenerateUserEnterpriseOptions {
  readonly id?: number;
  readonly userId: number;
  readonly enterpriseId: number;
  readonly source?: EnterpriseSourceApi;
  readonly dateAdded?: number;
}

export function generateUserEnterpriseObject({
  id,
  userId,
  enterpriseId,
  source = EnterpriseSourceApi.FREELANCER,
  dateAdded = Date.now(),
}: GenerateUserEnterpriseOptions): UserEnterpriseModel {
  return {
    id: id ?? generateId(),
    userId,
    enterpriseId,
    source,
    dateAdded,
  };
}

export function generateUserEnterpriseObjects(
  userEnterpriseOptionsList: readonly GenerateUserEnterpriseOptions[],
): readonly UserEnterpriseModel[] {
  return userEnterpriseOptionsList.map(userEnterpriseOptions =>
    generateUserEnterpriseObject(userEnterpriseOptions),
  );
}
