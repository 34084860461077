import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IconSize } from '@freelancer/ui/icon';
import { FontType, FontWeight, TextSize } from '@freelancer/ui/text';

interface User {
  avatar: string;
  id: number;
  username: string;
}

@Component({
  selector: 'app-test-user-card',
  template: `
    <fl-bit
      class="UserCard"
      [flMarginBottom]="'xsmall'"
    >
      <fl-user-avatar
        [flMarginRight]="'small'"
        [users]="[
          {
            username: user.username,
            avatar: user.avatar
          }
        ]"
      ></fl-user-avatar>
      <fl-bit>
        <fl-username
          displayName="Willa H."
          [badges]="{ verified: true }"
          [username]="'willahartley'"
        ></fl-username>
        <fl-rating
          [control]="ratingControl"
          [size]="IconSize.SMALL"
        ></fl-rating>
      </fl-bit>
    </fl-bit>
    <fl-bit [flMarginBottom]="'xsmall'">
      <fl-text
        [fontType]="FontType.SPAN"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.SMALL"
      >
        {{ 25 | flCurrency : 'USD' }}
      </fl-text>
      <fl-text
        [fontType]="FontType.SPAN"
        [size]="TextSize.SMALL"
      >
        per hour
      </fl-text>
    </fl-bit>
    <fl-text> Exceptional images deserve an exceptional presentation. </fl-text>
  `,
  styleUrls: ['./test-user-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestUserCardComponent {
  FontType = FontType;
  FontWeight = FontWeight;
  IconSize = IconSize;
  TextSize = TextSize;

  ratingControl = new FormControl(5, { nonNullable: true });

  @Input() user: User;
}
