import type { ButtonColor } from '@freelancer/ui/button';

export enum BannerAlertType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  CRITICAL = 'critical',
}

export enum BannerAlertLevel {
  NONE = 'none',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export interface BannerAlertButton {
  text: string;
  color?: ButtonColor;
  link?: string;
  fragment?: string;
  queryParams?: { [k: string]: string | number };
  linkActive?: string[] | string;
  linkActiveOptions?: { exact: boolean };
  busy?: boolean;
}
