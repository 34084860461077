import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { RepetitiveSubscription } from '@freelancer/decorators';
import { FontColor, FontWeight, TextSize } from '@freelancer/ui/text';
import { isNumber, isString } from '@freelancer/utils';
import { Subscription } from 'rxjs';
import { IconColor, IconSize } from '../icon';
import { generateRandomString, isRadioOptionItem } from './radio.helpers';
import type { RadioOptionItem } from './radio.types';
import { RadioOrientation, RadioSize, RadioTextColor } from './radio.types';

@Component({
  selector: 'fl-radio',
  template: `
    <div
      class="RadioContainer"
      [attr.data-text-color]="textColor"
      [class.IsInvalid]="control.invalid && control.dirty"
      [class.IsDisabled]="control.disabled"
      [attr.disabled]="control.disabled ? true : null"
      [flMarginBottom]="
        control.invalid && control.dirty ? 'xxxsmall' : undefined
      "
    >
      <ng-container *ngFor="let option of options; trackBy: trackByValue">
        <ng-container *ngIf="isString(option)">
          <input
            class="NativeElement"
            #input
            type="radio"
            id="{{ radioGroupId }}--{{ stripSpaces(option) }}"
            name="{{ radioGroupId }}"
            attr.aria-errormessage="errorId-{{ radioGroupId }}--{{
              stripSpaces(option)
            }}"
            [attr.aria-invalid]="control.invalid && control.dirty"
            [attr.disabled]="control.disabled ? true : null"
            [checked]="control.value === option"
            [formControl]="control"
            [value]="option"
          />
          <label
            class="RadioLabel"
            [attr.data-text-color]="textColor"
            [attr.data-size]="size"
            [attr.data-weight]="weight"
            [attr.data-orientation]="orientation"
            [attr.data-orientation-desktop]="orientationDesktop"
            [class.InputOnly]="forListItem"
            for="{{ radioGroupId }}--{{ stripSpaces(option) }}"
          >
            <span class="RadioEffect"></span>
            <ng-container *ngIf="!forListItem"> {{ option }} </ng-container>
          </label>
        </ng-container>
        <ng-container *ngIf="isNumber(option)">
          <input
            class="NativeElement"
            #input
            type="radio"
            id="{{ radioGroupId }}--{{ option }}"
            name="{{ radioGroupId }}"
            attr.aria-errormessage="errorId-{{ radioGroupId }}--{{ option }}"
            [attr.aria-invalid]="control.invalid && control.dirty"
            [attr.disabled]="control.disabled ? true : null"
            [checked]="control.value === option"
            [formControl]="control"
            [value]="option"
          />
          <label
            class="RadioLabel"
            [attr.data-size]="size"
            [attr.data-weight]="weight"
            [attr.data-orientation]="orientation"
            [attr.data-orientation-desktop]="orientationDesktop"
            [ngClass]="{ InputOnly: forListItem }"
            for="{{ radioGroupId }}--{{ option }}"
          >
            <span class="RadioEffect"></span>
            <ng-container *ngIf="!forListItem"> {{ option }} </ng-container>
          </label>
        </ng-container>
        <ng-container *ngIf="isRadioOptionItem(option)">
          <input
            class="NativeElement"
            #input
            type="radio"
            id="{{ radioGroupId }}--{{ stripSpaces(option.value) }}"
            name="{{ radioGroupId }}"
            attr.aria-errormessage="errorId-{{ radioGroupId }}--{{
              stripSpaces(option.value)
            }}"
            [attr.aria-invalid]="control.invalid && control.dirty"
            [attr.disabled]="control.disabled ? true : null"
            [checked]="control.value === option.value"
            [formControl]="control"
            [value]="option.value"
          />
          <label
            class="RadioLabel"
            [attr.data-size]="size"
            [attr.data-weight]="weight"
            [attr.data-orientation]="orientation"
            [attr.data-orientation-desktop]="orientationDesktop"
            [ngClass]="{ InputOnly: forListItem }"
            for="{{ radioGroupId }}--{{ stripSpaces(option.value) }}"
          >
            <span class="RadioEffect"></span>
            <ng-container *ngIf="!forListItem">
              {{ option.displayText }}
            </ng-container>
          </label>
        </ng-container>
      </ng-container>
    </div>

    <fl-validation-error
      *ngIf="!forListItem"
      [id]="id"
      [control]="control"
    ></fl-validation-error>
  `,
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent<T> implements OnInit, OnChanges, OnDestroy {
  IconColor = IconColor;
  IconSize = IconSize;
  FontColor = FontColor;
  TextSize = TextSize;

  isNumber = isNumber;
  isString = isString;
  isRadioOptionItem = isRadioOptionItem;

  radioGroupId: string;
  @RepetitiveSubscription()
  private statusChangeSubscription?: Subscription;

  error: string;

  @Input() id?: string;
  @Input() control: FormControl<T>;

  // Only allow `number | string` to be specified without
  // being wrapped in a `RadioOptionItem`
  @Input() options: readonly (
    | (T extends number | string ? T : never)
    | RadioOptionItem<T>
  )[];
  @Input() forListItem = false;
  @Input() size = RadioSize.SMALL;
  @Input() weight: FontWeight = FontWeight.NORMAL;
  @Input() orientation = RadioOrientation.VERTICAL;
  @Input() orientationDesktop = RadioOrientation.VERTICAL;

  @Input() textColor: RadioTextColor = 'foreground';

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.radioGroupId = generateRandomString();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('control' in changes) {
      if (this.statusChangeSubscription) {
        this.statusChangeSubscription.unsubscribe();
      }
      this.statusChangeSubscription = this.control.statusChanges.subscribe(
        () => {
          this.cd.markForCheck();
        },
      );
    }
  }

  ngOnDestroy(): void {
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
  }

  stripSpaces(key: string | T): string {
    return String(key).split(' ').join('');
  }

  trackByValue(
    _: number,
    option: (T extends number | string ? T : never) | RadioOptionItem<T>,
  ): string {
    if (isRadioOptionItem(option)) {
      // Using `String()` as `toString` requires `T extends Object`, but using that causes
      // `T` to get inferred as `any`
      return String(option.value);
    }

    return option.toString();
  }
}
