import { generateId } from '@freelancer/datastore/testing/helpers';
import { FrontendProjectStatusApi } from 'api-typings/common/common';
import { CurrencyCode, generateCurrencyObject } from '../currencies';
import { LocationPreset, getLocationPresets } from '../project-view-users';
import { phpSkill } from '../skills';
import type {
  NearbyMapCoordinates,
  NearbyProject,
} from './nearby-projects.model';

export interface GenerateNearbyProjectOptions {
  readonly currencyCode?: CurrencyCode;
  readonly location?: NearbyMapCoordinates;
  readonly description?: string;
  readonly id?: number;
  readonly skillIds?: readonly number[];
  readonly languageCode?: string;
  readonly maxBudget?: number;
  readonly minBudget?: number;
  readonly seoUrl?: string;
  readonly status?: FrontendProjectStatusApi.OPEN;
  readonly submitdate?: number;
  readonly title?: string;
  readonly userDistance?: number;
}

export function generateNearbyProjectObject({
  currencyCode = CurrencyCode.USD,
  location = generateNearbyMapCoordinates({}),
  description = 'Nearby Project description',
  id = generateId(),
  skillIds = [phpSkill().id],
  languageCode = 'en',
  maxBudget = 750,
  minBudget = 250,
  seoUrl = 'php/Nearby-project-title',
  submitdate = Date.now(),
  title = 'Nearby Project Title',
  userDistance = 500,
}: GenerateNearbyProjectOptions): NearbyProject {
  return {
    currency: generateCurrencyObject(currencyCode),
    location,
    description,
    id,
    skillIds,
    languageCode,
    local: true,
    maxBudget,
    minBudget,
    seoUrl,
    status: FrontendProjectStatusApi.OPEN,
    submitdate,
    title,
    userDistance,
  };
}

function generateNearbyMapCoordinates({
  locationPreset = LocationPreset.SEATTLE,
}: {
  readonly locationPreset?: LocationPreset;
}): NearbyMapCoordinates {
  const location = getLocationPresets(locationPreset);
  return {
    longitude: location.mapCoordinates.longitude,
    latitude: location.mapCoordinates.latitude,
    // Backend returns a country name not a code - update if this is important
    country: location.countryCode,
    city: location.city,
  };
}
