import { generateId } from '@freelancer/datastore/testing/helpers';
import type { UserTaxOptOutReason } from './user-tax-opt-out-reasons.model';

export type GenerateUserTaxOptOutReasonOptions = Omit<
  UserTaxOptOutReason,
  'id'
>;

export function generateUserTaxOptOutReasonObject({
  reasonId = 1,
  details,
}: GenerateUserTaxOptOutReasonOptions): UserTaxOptOutReason {
  return {
    id: generateId(),
    reasonId,
    details,
  };
}
