import { generateId } from '@freelancer/datastore/testing/helpers';
import type { ContestHandover } from './contest-handovers.model';

export interface GenerateContestHandoverOptions {
  readonly active?: boolean;
  readonly adminCancelled?: boolean;
  readonly adminCancelledDate?: number;
  readonly buyerAutoConfirmDate?: number;
  readonly buyerConfirmed?: boolean;
  readonly buyerConfirmedDate?: number;
  readonly buyerSignedDate?: number;
  readonly buyerId: number;
  readonly buyerSignedContract?: boolean;
  readonly contestId: number;
  readonly entryId: number;
  readonly sellerConfirmed?: boolean;
  readonly sellerConfirmedDate?: number;
  readonly sellerId: number;
  readonly sellerSignedContract?: boolean;
  readonly sellerSignedDate?: number;
}

export function generateContestHandoverObject({
  active = true,
  adminCancelled = false,
  adminCancelledDate,
  buyerAutoConfirmDate,
  buyerConfirmed = false,
  buyerConfirmedDate,
  buyerSignedDate,
  buyerId,
  buyerSignedContract = false,
  contestId,
  entryId,
  sellerConfirmed = false,
  sellerConfirmedDate,
  sellerId,
  sellerSignedContract = false,
  sellerSignedDate,
}: GenerateContestHandoverOptions): ContestHandover {
  return {
    active,
    adminCancelled,
    adminCancelledDate,
    buyerAutoConfirmDate,
    buyerConfirmed,
    buyerConfirmedDate,
    buyerSignedDate,
    buyerId,
    buyerSignedContract,
    contestId,
    entryId,
    id: generateId(),
    sellerConfirmed,
    sellerConfirmedDate,
    sellerId,
    sellerSignedContract,
    sellerSignedDate,
  };
}

/** After an entry is awarded, a fresh handover is created */
export function handoverEntryAwarded(): Pick<
  ContestHandover,
  | 'buyerConfirmed'
  | 'buyerSignedContract'
  | 'sellerConfirmed'
  | 'sellerSignedContract'
> {
  return {
    buyerConfirmed: false,
    buyerSignedContract: false,
    sellerConfirmed: false,
    sellerSignedContract: false,
  };
}

/** When the buyer signs, but the seller does not */
export function handoverAwaitingFreelancerSignature(): Pick<
  ContestHandover,
  | 'buyerConfirmed'
  | 'buyerSignedContract'
  | 'buyerSignedDate'
  | 'sellerConfirmed'
  | 'sellerSignedContract'
> {
  return {
    buyerConfirmed: false,
    buyerSignedContract: true,
    buyerSignedDate: Date.now(),
    sellerConfirmed: false,
    sellerSignedContract: false,
  };
}

/** When the seller signs but the buyer does not */
export function handoverAwaitingBuyerSignature(): Pick<
  ContestHandover,
  | 'buyerConfirmed'
  | 'buyerSignedContract'
  | 'sellerConfirmed'
  | 'sellerSignedContract'
  | 'sellerSignedDate'
> {
  return {
    buyerConfirmed: false,
    buyerSignedContract: false,
    sellerConfirmed: false,
    sellerSignedContract: true,
    sellerSignedDate: Date.now(),
  };
}

/** When both parties have signed */
export function handoverAwaitingFreelancerUpload(): Pick<
  ContestHandover,
  | 'buyerConfirmed'
  | 'buyerSignedContract'
  | 'buyerSignedDate'
  | 'sellerConfirmed'
  | 'sellerSignedContract'
  | 'sellerSignedDate'
> {
  return {
    buyerConfirmed: false,
    buyerSignedContract: true,
    buyerSignedDate: Date.now(),
    sellerConfirmed: false,
    sellerSignedContract: true,
    sellerSignedDate: Date.now(),
  };
}

/**
 * When the seller has uploaded the files.
 * Autoconfirm date is when handover is confirmed by buyer without their input.
 * Defaults to 1 day later
 */
export function handoverAwaitingBuyerConfirmation(
  autoConfirmDate = Date.now() + 60 * 60 * 24 * 1000,
): Pick<
  ContestHandover,
  | 'buyerAutoConfirmDate'
  | 'buyerConfirmed'
  | 'buyerSignedContract'
  | 'buyerSignedDate'
  | 'sellerConfirmed'
  | 'sellerConfirmedDate'
  | 'sellerSignedContract'
  | 'sellerSignedDate'
> {
  return {
    buyerAutoConfirmDate: autoConfirmDate,
    buyerConfirmed: false,
    buyerSignedContract: true,
    buyerSignedDate: Date.now(),
    sellerConfirmed: true,
    sellerConfirmedDate: Date.now(),
    sellerSignedContract: true,
    sellerSignedDate: Date.now(),
  };
}

/** When the buyer has confirmed */
export function handoverBothConfirmed(): Pick<
  ContestHandover,
  | 'buyerAutoConfirmDate'
  | 'buyerConfirmed'
  | 'buyerConfirmedDate'
  | 'buyerSignedContract'
  | 'buyerSignedDate'
  | 'sellerConfirmed'
  | 'sellerConfirmedDate'
  | 'sellerSignedContract'
  | 'sellerSignedDate'
> {
  return {
    buyerAutoConfirmDate: Date.now(),
    buyerConfirmed: true,
    buyerConfirmedDate: Date.now(),
    buyerSignedContract: true,
    buyerSignedDate: Date.now(),
    sellerConfirmed: true,
    sellerConfirmedDate: Date.now(),
    sellerSignedContract: true,
    sellerSignedDate: Date.now(),
  };
}

export function completeHandoverByClient(): Pick<
  GenerateContestHandoverOptions,
  | 'buyerConfirmed'
  | 'buyerConfirmedDate'
  | 'buyerSignedContract'
  | 'buyerSignedDate'
> {
  return {
    buyerConfirmed: true,
    buyerConfirmedDate: Date.now(),
    buyerSignedContract: true,
    buyerSignedDate: Date.now(),
  };
}

export function completeHandoverByFreelancer(): Pick<
  GenerateContestHandoverOptions,
  | 'sellerConfirmed'
  | 'sellerConfirmedDate'
  | 'sellerSignedContract'
  | 'sellerSignedDate'
> {
  return {
    sellerConfirmed: true,
    sellerConfirmedDate: Date.now(),
    sellerSignedContract: true,
    sellerSignedDate: Date.now(),
  };
}
