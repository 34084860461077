import type { UserRequiresPaymentVerification } from './user-requires-payment-verification.model';

export interface GenerateUserRequiresPaymentVerificationOptions {
  readonly userId: string;
  readonly userRequiresPaymentVerification: boolean;
}

export function generateUserRequiresPaymentVerificationObject({
  userId,
  userRequiresPaymentVerification,
}: GenerateUserRequiresPaymentVerificationOptions): UserRequiresPaymentVerification {
  return {
    id: userId,
    userRequiresPaymentVerification,
  };
}
