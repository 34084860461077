import { generateId } from '@freelancer/datastore/testing/helpers';
import type { UserSettingApi } from 'api-typings/users/users';
import {
  UserSettingContextApi,
  UserSettingTargetApi,
  UserSettingValueApi,
} from 'api-typings/users/users';
import type { UserSetting } from './user-settings.model';
import { getUserSettingId } from './user-settings.transforms';

export interface GenerateUserSettingsOptions {
  readonly context?: UserSettingContextApi;
  readonly target?: UserSettingTargetApi;
  readonly targetId?: number;
  readonly value?: UserSettingValueApi;
  readonly userId?: number;
}

export function generateUserSettingsObject({
  context = UserSettingContextApi.NEWSFEED,
  target = UserSettingTargetApi.GROUP,
  targetId,
  userId = generateId(),
  value = UserSettingValueApi.ACTIVE,
}: GenerateUserSettingsOptions = {}): UserSetting {
  const setting: UserSettingApi = {
    id: generateId(),
    context,
    target,
    target_id: targetId,
    value,
    user_id: userId,
  };

  const id = getUserSettingId(setting);

  return {
    id,
    value,
    context,
    target,
    targetId,
    userId,
  };
}

export function withTargetId(
  targetId: number,
): Pick<GenerateUserSettingsOptions, 'targetId'> {
  return {
    targetId,
  };
}

export function activeNewsfeedGroup(): Pick<
  GenerateUserSettingsOptions,
  'context' | 'target' | 'value'
> {
  return {
    context: UserSettingContextApi.NEWSFEED,
    target: UserSettingTargetApi.GROUP,
    value: UserSettingValueApi.ACTIVE,
  };
}
export function muteNewsfeedGroup(): Pick<
  GenerateUserSettingsOptions,
  'context' | 'target' | 'value'
> {
  return {
    context: UserSettingContextApi.NEWSFEED,
    target: UserSettingTargetApi.GROUP,
    value: UserSettingValueApi.MUTE,
  };
}

export function aiAutoReplyDisabled(): Pick<
  GenerateUserSettingsOptions,
  'context' | 'target' | 'value'
> {
  return {
    context: UserSettingContextApi.AI_AUTO_REPLY,
    target: UserSettingTargetApi.THREAD,
    value: UserSettingValueApi.DISABLED,
  };
}

export function aiAutoReplyEnabled(): Pick<
  GenerateUserSettingsOptions,
  'context' | 'target' | 'value'
> {
  return {
    context: UserSettingContextApi.AI_AUTO_REPLY,
    target: UserSettingTargetApi.THREAD,
    value: UserSettingValueApi.ENABLED,
  };
}

export function aiAutoReplyUnset(): Pick<
  GenerateUserSettingsOptions,
  'context' | 'target' | 'value'
> {
  return {
    context: UserSettingContextApi.AI_AUTO_REPLY,
    target: UserSettingTargetApi.THREAD,
    value: UserSettingValueApi.UNSET,
  };
}
