import type { Exam } from './exams.model';

export function generateExamObjects(): readonly Exam[] {
  return exams;
}

const exams = [
  academicWritingLevel1Exam(),
  adobePhotoShopCs5Level1Exam(),
  androidLevel1Exam(),
  dataEntryExam(),
  javaScriptLevel1Exam(),
  phpLevel1Exam(),
  usEnglishLevel1Exam(),
] as const;

export function academicWritingLevel1Exam(): Exam {
  return {
    id: 100,
    insigniaId: 282,
    name: 'Academic Writing - Level 1',
  };
}

export function adobePhotoShopCs5Level1Exam(): Exam {
  return {
    id: 224,
    insigniaId: 373,
    name: 'Adobe Photoshop CS5 - Level 1',
  };
}

export function androidLevel1Exam(): Exam {
  return {
    id: 32,
    insigniaId: 182,
    name: 'Android - Level 1',
  };
}

export function dataEntryExam(): Exam {
  return {
    id: 534,
    insigniaId: 703,
    name: 'Data Entry',
  };
}

export function javaScriptLevel1Exam(): Exam {
  return {
    id: 5,
    insigniaId: 110,
    name: 'JavaScript - Level 1',
  };
}

export function phpLevel1Exam(): Exam {
  return {
    id: 8,
    insigniaId: 119,
    name: 'PHP - Level 1',
  };
}

export function usEnglishLevel1Exam(): Exam {
  return {
    id: 2,
    insigniaId: 100,
    name: 'US English - Level 1',
  };
}
