import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ListItemType } from '@freelancer/ui/list-item';
import {
  FontColor,
  FontStyle,
  FontType,
  FontWeight,
  TextSize,
} from '@freelancer/ui/text';

@Component({
  selector: 'app-brand-style-guide-voice',
  template: `
    <div class="Voice">
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Brand voice
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        Our brand's tone of voice is conversational and concise, aiming to
        establish a friendly and approachable connection with our audience. We
        strive to communicate in a manner that feels like a conversation between
        friends while conveying information efficiently and effectively. This
        tone allows us to engage and connect with our audience in a relatable
        and personable way, fostering trust and building lasting relationships.
      </fl-text>
      <div [flMarginBottom]="'xlarge'">
        <fl-picture
          [fullWidth]="true"
          [src]="'brand-guidelines/img-1.png'"
        ></fl-picture>
      </div>

      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Examples
      </fl-text>
      <fl-grid
        class="GridVoice"
        [flMarginBottom]="'xlarge'"
      >
        <fl-col
          class="ColVoice"
          [col]="12"
          [colTablet]="4"
          [flMarginBottom]="'xsmall'"
        >
          <div class="VoiceBoxCol">
            <fl-text
              [color]="FontColor.PRIMARY"
              [weight]="FontWeight.BOLD"
              [flMarginBottom]="'xxsmall'"
            >
              Welcome message
            </fl-text>
            <fl-text
              [color]="FontColor.LIGHT"
              [flMarginBottom]="'small'"
            >
              "Hey there! Welcome to our community. We're thrilled to have you
              on board. We'll be here to guide you every step of the way. If you
              have any questions, don't hesitate to reach out. Let's embark on
              this exciting journey together!"
            </fl-text>
            <fl-hr [flMarginBottom]="'small'"></fl-hr>
            <fl-text
              [color]="FontColor.LIGHT"
              [fontStyle]="FontStyle.ITALIC"
            >
              In this example, we greet our audience in a warm and friendly
              manner, using informal language to create a welcoming atmosphere.
              We convey our readiness to provide assistance and emphasize the
              idea of collaboration, inviting our audience to engage with us.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
          [flMarginBottom]="'xsmall'"
        >
          <div class="VoiceBoxCol">
            <fl-text
              [color]="FontColor.PRIMARY"
              [weight]="FontWeight.BOLD"
              [flMarginBottom]="'xxsmall'"
            >
              Product description
            </fl-text>
            <fl-text
              [color]="FontColor.LIGHT"
              [flMarginBottom]="'small'"
            >
              "Our new feature is a game-changer. It's compact, lightweight, and
              does wonders for your productivity. With its sleek design and
              powerful features, you'll be amazed at how it simplifies your
              daily tasks. Say goodbye to complexity and hello to efficiency!"
            </fl-text>
            <fl-hr [flMarginBottom]="'small'"></fl-hr>
            <fl-text
              [color]="FontColor.LIGHT"
              [fontStyle]="FontStyle.ITALIC"
            >
              Here, we use concise language to highlight the key benefits of our
              product. The tone remains conversational, emphasizing the positive
              impact it can have on the audience's life. We appeal to their
              desire for simplicity and efficiency, creating excitement and
              anticipation.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
        >
          <div class="VoiceBoxCol">
            <fl-text
              [color]="FontColor.PRIMARY"
              [weight]="FontWeight.BOLD"
              [flMarginBottom]="'xxsmall'"
            >
              Error message
            </fl-text>
            <fl-text
              [color]="FontColor.LIGHT"
              [flMarginBottom]="'small'"
            >
              "Oops! Looks like something went wrong. Don't worry, we've got you
              covered. Our team is on it and working hard to fix the issue. Hang
              tight, and we'll have everything back to normal in no time. Thanks
              for your patience!"
            </fl-text>
            <fl-hr [flMarginBottom]="'small'"></fl-hr>
            <fl-text
              [color]="FontColor.LIGHT"
              [fontStyle]="FontStyle.ITALIC"
            >
              Even in error messages, we maintain a conversational tone to
              reassure our audience. We acknowledge the problem, offer support,
              and provide a timeframe for resolution. By expressing gratitude
              for their patience, we convey our commitment to delivering a
              positive user experience.
            </fl-text>
          </div>
        </fl-col>
      </fl-grid>
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Write in US English
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        We've positioned ourselves as a .com, not a .com.au or .co.uk. Our users
        come from all over the world, but write to a US audience. This means no
        OUs, lots of Zs and R after E. It also means you traveled illegally
        instead of travelled illegally, forged a check instead of a cheque,
        faced judgment instead of judgement, got sent to jail instead of gaol
        and ended up in a correctional center, not a correctional centre.
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        We are Freelancer
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'xlarge'"
      >
        When writing internally, we're Freelancer. Never Freelancer.com,
        freelancer.com and never freelancer. When writing for external sites or
        publications, we're Freelancer.com.
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [weight]="FontWeight.BOLD"
        [size]="TextSize.XLARGE"
        [sizeDesktop]="TextSize.XXLARGE"
        [flMarginBottom]="'mid'"
      >
        Emails and marketing comms
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        The subject line should be no longer than 90 characters. A good subject
        line should contain at least one of the following five elements:
      </fl-text>
      <fl-grid>
        <fl-col
          [col]="12"
          [colTablet]="4"
          [flMarginBottom]="'xsmall'"
        >
          <div class="VoiceBoxCol">
            <fl-text
              [color]="FontColor.PRIMARY"
              [weight]="FontWeight.BOLD"
              [flMarginBottom]="'xxsmall'"
            >
              Self interest
            </fl-text>
            <fl-text
              [color]="FontColor.LIGHT"
              [flMarginBottom]="'small'"
            >
              "Your free project upgrade is inside"
            </fl-text>
            <fl-hr [flMarginBottom]="'small'"></fl-hr>
            <fl-text
              [color]="FontColor.LIGHT"
              [fontStyle]="FontStyle.ITALIC"
            >
              Clearly state the benefit your recipient will get from the content
              of the email.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
          [flMarginBottom]="'xsmall'"
        >
          <div class="VoiceBoxCol">
            <fl-text
              [color]="FontColor.PRIMARY"
              [weight]="FontWeight.BOLD"
              [flMarginBottom]="'xxsmall'"
            >
              Curiosity
            </fl-text>
            <fl-text
              [color]="FontColor.LIGHT"
              [flMarginBottom]="'small'"
            >
              "9 out of 10 successful startups do this"
            </fl-text>
            <fl-hr [flMarginBottom]="'small'"></fl-hr>
            <fl-text
              [color]="FontColor.LIGHT"
              [fontStyle]="FontStyle.ITALIC"
            >
              Pique the recipient's curiosity by asking a question or making a
              bold statement.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
          [flMarginBottom]="'xsmall'"
        >
          <div class="VoiceBoxCol">
            <fl-text
              [color]="FontColor.PRIMARY"
              [weight]="FontWeight.BOLD"
              [flMarginBottom]="'xxsmall'"
            >
              Fear of missing out
            </fl-text>
            <fl-text
              [color]="FontColor.LIGHT"
              [flMarginBottom]="'small'"
            >
              "Only 24 hours left to save big"
            </fl-text>
            <fl-hr [flMarginBottom]="'small'"></fl-hr>
            <fl-text
              [color]="FontColor.LIGHT"
              [fontStyle]="FontStyle.ITALIC"
            >
              Create a sense of urgency or scarcity to prompt recipients to take
              action.
            </fl-text>
          </div>
        </fl-col>
      </fl-grid>
      <fl-grid
        class="GridVoice"
        [flMarginBottom]="'xxlarge'"
      >
        <fl-col
          [col]="12"
          [colTablet]="4"
          [flMarginBottom]="'xsmall'"
        >
          <div class="VoiceBoxCol">
            <fl-text
              [color]="FontColor.PRIMARY"
              [weight]="FontWeight.BOLD"
              [flMarginBottom]="'xxsmall'"
            >
              Social proof
            </fl-text>
            <fl-text
              [color]="FontColor.LIGHT"
              [flMarginBottom]="'small'"
            >
              "Millions of happy freelancers can't be wrong"
            </fl-text>
            <fl-hr [flMarginBottom]="'small'"></fl-hr>
            <fl-text
              [color]="FontColor.LIGHT"
              [fontStyle]="FontStyle.ITALIC"
            >
              Leverage recipients inclination to follow the crowd.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          [col]="12"
          [colTablet]="4"
          [flMarginBottom]="'xsmall'"
        >
          <div class="VoiceBoxCol">
            <fl-text
              [color]="FontColor.PRIMARY"
              [weight]="FontWeight.BOLD"
              [flMarginBottom]="'xxsmall'"
            >
              Offer
            </fl-text>
            <fl-text
              [color]="FontColor.LIGHT"
              [flMarginBottom]="'small'"
            >
              "Save 50% on our top selling membership"
            </fl-text>
            <fl-hr [flMarginBottom]="'small'"></fl-hr>
            <fl-text
              [color]="FontColor.LIGHT"
              [fontStyle]="FontStyle.ITALIC"
            >
              Similar to self-interest, be clear about what your email is
              offering the recipient.
            </fl-text>
          </div>
        </fl-col>
        <fl-col
          class="ColVoice"
          [col]="12"
          [colTablet]="4"
          [flMarginBottom]="'xsmall'"
        >
          <div class="VoiceBoxColBlue">
            <fl-text
              [color]="FontColor.LIGHT"
              [flMarginBottom]="'small'"
            >
              Remember, our conversational and concise tone of voice helps us
              establish a connection with our audience while effectively
              communicating our brand's message.
            </fl-text>
          </div>
        </fl-col>
      </fl-grid>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Your email body should consist of:
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [fontType]="FontType.CONTAINER"
        [flMarginBottom]="'xlarge'"
      >
        <fl-list
          [type]="ListItemType.UNORDERED"
          [flMarginBottom]="'small'"
        >
          <fl-list-item>A header line</fl-list-item>
          <fl-list-item>Two to three paragraphs of copy</fl-list-item>
          <fl-list-item>A clear call to action </fl-list-item>
        </fl-list>
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Keep your body copy concise. Ideally, a recipient should be able to skim
        your entire email and see the CTA without scrolling down on mobile
        devices. Your call to action should be a prominent button, no more than
        two to three words long, and headline cased.
      </fl-text>
      <fl-text
        [color]="FontColor.LIGHT"
        [size]="TextSize.SMALL"
        [sizeDesktop]="TextSize.LARGE"
        [flMarginBottom]="'small'"
      >
        Your email also MUST contain a footer with our address, an unsubscribe
        link, our app store links and social media links.
      </fl-text>
      <fl-picture
        class="Emails"
        [fullWidth]="true"
        [src]="'brand-guidelines/emails.png'"
      ></fl-picture>
    </div>
  `,
  styleUrls: ['./../brand-style-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandStyleGuideVoiceComponent {
  FontColor = FontColor;
  FontStyle = FontStyle;
  FontWeight = FontWeight;
  FontType = FontType;
  ListItemType = ListItemType;
  TextSize = TextSize;
}
