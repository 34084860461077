import { generateId } from '@freelancer/datastore/testing/helpers';
import type { TimeTrackingPunch } from './time-tracking-punches.model';

export interface GenerateTimeTrackingPunchesOptions {
  readonly sessionIds: readonly number[];
  readonly bidId: number;
}

export function generateTimeTrackingPunches({
  sessionIds,
  bidId,
}: GenerateTimeTrackingPunchesOptions): readonly TimeTrackingPunch[] {
  return sessionIds.map(sessionId => ({
    id: generateId(),
    sessionId,
    timeCreated: Date.now(),
    bidId,
  }));
}
